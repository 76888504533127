import React from 'react'
import { useRouter } from 'next/router'
import { useLocalisation } from './LocalisationContext'
import { LocaleSwitcherProps } from './localisation.types'

export default function LocaleSwitcher({
  locale,
  children,
  ...props
}: LocaleSwitcherProps): React.ReactElement {
  const router = useRouter()
  const localisation = useLocalisation()
  return (
    <a
      {...props}
      href={router.pathname}
      onClick={(e) => {
        e.preventDefault()
        localisation.setLocale(locale)
      }}
    >
      {children}
    </a>
  )
}
