import { paymentFormContainerHelper } from './base'

export const PaymentFormContainer = paymentFormContainerHelper.defineMultiStyleConfig({
  baseStyle: paymentFormContainerHelper.definePartsStyle({
    cashDueValue: {
      fontWeight: 'semibold',
    },
    cashDueLabel: {
      fontWeight: 'medium',
    },
  }),
})
