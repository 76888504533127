import { helpers } from './base'

export const IntroSection = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      gap: '2rem 0px',
      marginBottom: 28,
      marginTop: 3,
      paddingX: 2,
    },
    content: {
      h3: {
        fontSize: '1.75rem',
        fontWeight: 'medium',
      },
      paddingBottom: { base: '4.5rem', md: 8 },
      paddingRight: { base: 0, md: 8 },
    },
    scroller: {
      '> *': {
        width: '100%',
        maxWidth: '100%',
      },
      '> *:first-child': {
        paddingLeft: 0,
      },
      '> *:last-child': {
        paddingRight: 0,
      },
      paddingLeft: { base: 0, md: 2 },
    },
  },
})
