import { ReactElement, useCallback, useMemo } from 'react'
import { useMultiStyleConfig, Box, chakra } from '@chakra-ui/react'
import Image from '../ChakraNextImage/index'
import { GalleryImage, ImageGalleryProps } from './ImageGallery.types'
import useGalleryArrowNavigation from './useGalleryArrowNavigation'

function ImageGallery({
  images,
  onImageClick,
  defaultSize,
  fallbackSrc,
}: ImageGalleryProps): ReactElement {
  const styles = useMultiStyleConfig('ImageGallery', {})

  const gallerySize = useMemo<number>(() => {
    if (images.length === 0) {
      return defaultSize
    }
    if (images.length < 3) {
      return 1
    }
    if (images.length < 7) {
      return 3
    }
    return 7
  }, [images, defaultSize])

  const galleryImages = useMemo<(GalleryImage | undefined)[]>(() => {
    return images.length === 0
      ? new Array<undefined>(gallerySize).fill(undefined)
      : images.slice(0, gallerySize)
  }, [images, gallerySize])

  const { onKeyDown, resetTabIndex, registerImageButton } = useGalleryArrowNavigation()

  const handleImageClick = (_index: number) => {
    let index = 0
    if (_index < gallerySize - 1) {
      index = _index
    }
    onImageClick(index)
    resetTabIndex()
  }

  const getImageLabel = useCallback(
    (i: number, title?: string | null) => {
      const label = title ? `Photo of ${title}` : ''
      if (i === galleryImages.length - 1) {
        return `${label}, click to see more photos`
      }
      return label
    },
    [galleryImages.length]
  )

  return (
    <Box
      __css={{
        ...styles.container,
        '& > :first-of-type': {
          gridColumnEnd: `span ${gallerySize < 3 ? 12 : 8}`,
          gridRowEnd: 'span 2',
        },
      }}
      aria-label="image gallery"
      tabIndex={0}
      onKeyDown={onKeyDown}
    >
      {galleryImages.map((img, index) => (
        <chakra.button
          __css={styles.button}
          type="button"
          onClick={handleImageClick.bind(null, index)}
          key={`img-${img ? img.imageUrl : index}`}
          disabled={img === undefined}
          data-remaining-images={`${images.length - index - 1}+`}
          {...registerImageButton(index)}
        >
          {img && (
            <Image
              layout="fill"
              objectFit="cover"
              src={img.imageUrl ?? fallbackSrc}
              alt={getImageLabel(index, img.title)}
              data-testid="gallery-image"
            />
          )}
        </chakra.button>
      ))}
    </Box>
  )
}

export default ImageGallery
