import { useVariable, useVariableValue } from '@devcycle/react-client-sdk'
import { FeatureFlagKey } from '@reward-platform/devcycle'

// Documentation around DevCycle usage here: https://aviosgroup.atlassian.net/wiki/spaces/RP/pages/2564981363/Feature+Flags+with+DevCycle

/**
 * Return a feature flag value, considering the partner domain
 * this hook uses the user identity to automatically set the correct partner
 * @param featureFlagKey feature flag key
 * @param defaultValue default value to serve if DevCycle is unavailable
 * @returns boolean | string | number | JSON
 */
export const useFeatureFlag = (featureFlagKey: FeatureFlagKey, defaultValue = false) =>
  useVariableValue(featureFlagKey, defaultValue)

export const useFeatureFlagVariable = (featureFlagKey: FeatureFlagKey, defaultValue = false) =>
  useVariable(featureFlagKey, defaultValue)
