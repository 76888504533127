import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const helpers = createMultiStyleConfigHelpers(['section', 'picture'])

export const AncillaryPhotos = helpers.defineMultiStyleConfig({
  baseStyle: {
    section: {
      scrollMarginTop: 16,
      position: 'relative',
      gridColumn: {
        base: 'var(--layout-column-wide)',
        sm: 'var(--layout-column-center)',
      },
      minHeight: 'xs',
      marginBottom: 8,
      outline: 'none',
    },
    picture: {
      position: 'relative',
      display: 'flex',
      w: 'full',
      h: 'full',

      '> span': {
        width: '100% !important',
      },
    },
  },
})
