import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'container',
  'breakdownContainer',
  'description',
  'progress',
  'ratings',
])

export const HotelReviewBreakdown = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      py: 6,
      px: 0,
      flexDirection: { base: 'column', md: 'row' },
    },

    breakdownContainer: {
      flex: 1,
      flexFlow: 'row wrap',
      justifyContent: 'space-between',
      rowGap: 4,
      columnGap: 6,
      mt: { base: 8, md: 0 },
      ml: { base: 0, md: 12 },
    },

    description: {
      mt: 2,
    },

    progress: {
      flex: { base: '1 1 100%', md: '1 1 40%' },
    },
  },
})
