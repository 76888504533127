/* eslint-disable sonarjs/no-duplicate-string */
import { SnackbarHelper } from './base'

export const Snackbar = SnackbarHelper.defineMultiStyleConfig({
  baseStyle: {
    container: {
      bg: 'bg.layer2.default',
      borderColor: 'border.primary.active',
    },
    icon: {
      color: 'brand.darkCityGreen',
    },
  },
  variants: {
    warning: {
      container: { borderColor: 'fg.warning.primary', bgColor: 'bg.warning.subtle.default' },
    },
    error: {
      container: { borderColor: 'fg.critical.primary', bgColor: 'bg.critical.subtle.default' },
    },
    success: {
      container: { borderColor: 'fg.success.primary', bgColor: 'bg.success.subtle.default' },
    },
    promo: {
      container: {
        bg: 'bg.layer2.default',
        borderColor: 'border.primary.active',
        '> svg': {
          color: 'fg.primary',
        },
        'p:first-child': {
          pb: '2xl',
        },
      },
    },
  },
})
