import { acceptedCardsHelper } from './base'

export const AcceptedCards = acceptedCardsHelper.defineMultiStyleConfig({
  baseStyle: {
    acceptedCardWrapper: {
      bg: 'bg.information.subtle.default',
      borderRadius: 'xs',
      width: {
        base: '100%',
        xs: 'max-content',
      },
    },
  },
})
