import { helpers } from './base'

const basegrey100 = 'var(--chakra-colors-brand-baseGray-100)'
const blue = 'brand.primary.blue'

export const PointsOfInterest = helpers.defineMultiStyleConfig({
  baseStyle: {
    bulletPoint: {
      color: blue,
    },
    list: {
      _before: {
        color: blue,
      },
      paddingLeft: '0',
    },
    listItem: {
      color: basegrey100,
    },
    expandButton: {
      color: blue,
    },
  },
})
