import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers(['link'])

export const BackTo = helpers.defineMultiStyleConfig({
  baseStyle: {
    link: {
      height: 8,
      display: 'inline-flex',
      alignItems: 'center',
      textDecor: 'none',
      fontSize: { base: 'xs', md: 'sm' },
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
})
