import { Button, chakra, useStyleConfig, Icon } from '@chakra-ui/react'
import { IconSizes } from '../../theme/iconSize'
import { convertIconSetToChakraSVG } from '../Icon/Icon.utils'

export interface ChakraCollapsibleButtonProps {
  title: string
  content?: string
  isExpanded: boolean
  setExpanded: (x: boolean) => void
  id?: string
  'aria-controls'?: string
}

function ChakraCollapsibleButton({
  title,
  content,
  isExpanded,
  setExpanded,
  id,
  'aria-controls': ariaControls,
}: ChakraCollapsibleButtonProps) {
  const iconType = isExpanded ? 'up-chevron' : 'down-chevron'
  const style = useStyleConfig('ChakraCollapsibleButton', {})
  const handleToggle = () => {
    setExpanded(!isExpanded)
  }
  return (
    <Button
      onClick={handleToggle}
      __css={style}
      aria-expanded={isExpanded}
      id={id}
      aria-controls={ariaControls}
    >
      <chakra.span>{title}</chakra.span>
      <chakra.span>
        <chakra.span>{content}</chakra.span>
        <Icon as={convertIconSetToChakraSVG(iconType)} boxSize={IconSizes.xxs} />
      </chakra.span>
    </Button>
  )
}
export default ChakraCollapsibleButton
