import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const dismissibleModalHelper = createMultiStyleConfigHelpers([
  'optionContainer',
  'wrapper',
  'image',
  'buttonsWrapper',
  'buttonContainer',
])

export const DismissibleModal = dismissibleModalHelper.defineMultiStyleConfig({
  baseStyle: dismissibleModalHelper.definePartsStyle({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: 6,
      h1: {
        marginBottom: '10',
      },
      p: {
        marginBottom: '6',
      },
    },
    optionContainer: {
      display: { base: 'flex', lg: 'grid' },
      gridTemplateColumns: '7fr 5fr',
      flexDirection: 'column-reverse',
      justifyContent: 'space-between',
      width: 'full',
      gap: 4,
      padding: { base: 2 },
      paddingTop: 0,
      paddingBottom: { base: 6 },
      '> div': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    buttonContainer: {
      button: {
        m: 0,
        w: 'full',
      },
      flex: 1,
      paddingTop: 0,
    },
    buttonsWrapper: {
      w: { base: 'full', lg: '70%' },
      m: 'auto',
      display: 'flex',
      alignItems: 'centre',
      justifyContent: 'center',
      gap: { base: 4, lg: 6 },
      fontSize: { base: '16px', lg: '18px' },
      flexDirection: { base: 'column-reverse', md: 'row' },
      marginTop: 'auto',
    },
    image: {
      display: { base: 'none', md: 'block' },
      width: 'full',
      maxHeight: { base: 14, md: 60, lg: 'full' },
      objectFit: { base: 'cover', lg: 'contain' },
      borderRadius: '16px',
    },
  }),
})
