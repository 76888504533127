import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers(['wrapper'])

export const ConfirmationModal = helpers.defineMultiStyleConfig({
  baseStyle: {
    wrapper: {
      display: 'flex',
      flexDirection: { base: 'column', md: 'row' },
      alignItems: 'center',
      w: '100%',
      m: 'auto',
      mt: 8,
      pb: 8,
      gap: 3,
      button: { width: '100%' },
    },
  },
})
