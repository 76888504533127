import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const layoutWidth = 'var(--chakra-sizes-7xl)'
const logoWidth = '24ch'
const legalWidth = `minmax(auto, calc(${layoutWidth} - ${logoWidth} - 2rem))`

export const footerHelpers = createMultiStyleConfigHelpers([
  'wrapper',
  'navList',
  'navListItem',
  'navListLink',
  'content',
  'legal',
  'logo',
  'logoWrapper',
])

export const Footer = footerHelpers.defineMultiStyleConfig({
  baseStyle: footerHelpers.definePartsStyle({
    wrapper: {
      display: 'grid',
      width: '100%',
      gridColumnGap: '1rem',
      gridTemplateColumns: `1fr minmax(auto, ${layoutWidth}) 1fr`,
      gridAutoFlow: 'row',
      gridAutoRows: 'min-content',
      marginTop: '5rem',
      borderTop: '2px solid brand.primary.midnightBlue',
      fontSize: '0.875rem',

      '> *': {
        gridColumn: '2/3',
      },

      p: {
        fontSize: 'inherit',
      },
    },
    navList: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      gap: '1rem 0',
      padding: '1.5rem 0',
    },
    navListItem: {
      display: 'flex',
      alignItems: 'center',

      '&:not(:last-child)': {
        marginRight: '1rem',

        '&::after': {
          content: '""',
          height: '1.25em',
          width: '1px',
          marginLeft: '1rem',
          backgroundColor: 'currentColor',
          opacity: '0.3',
        },
      },
    },
    navListLink: {
      textDecoration: 'none',
      color: 'purple',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    content: {
      display: 'grid',
      alignItems: 'center',
      gridGap: '1rem',
      gridColumn: '1/4',
      gridTemplateAreas: {
        base: `'. content . auto' '. logo . auto'`,
        md: `'. content logo . auto'`,
      },
      gridTemplateColumns: {
        base: `1fr minmax(auto, ${layoutWidth}) 1fr`,
        md: `1fr ${legalWidth} ${logoWidth} 1fr`,
      },
      padding: {
        base: '1rem 0',
        md: '3rem 0',
      },
    },
    legal: {
      gridArea: 'content',

      '> p': {
        margin: 0,
      },
    },
    logo: {
      gridArea: 'logo',
      gap: '1rem',
      display: 'flex',
      alignItems: 'center',

      flexDirection: { md: 'row-reverse' },
      justifyContent: { md: 'end' },
      textAlign: { md: 'right' },

      '> p': {
        margin: '0',
        maxWidth: '18ch',
      },
    },
    logoWrapper: {
      minW: '3rem',
      w: '3rem',
      h: '3rem',
      position: 'relative',
    },
  }),
})
