import { helpers } from './base'

const basegrey100 = 'var(--chakra-colors-brand-baseGray-100)'
const primaryBlue = 'var(--chakra-colors-brand-primary-blue)'

export const DayList = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      color: 'brand.black',
    },
    solidLine: {
      backgroundColor: primaryBlue,
    },
    dashedLine: {
      backgroundImage: `repeating-linear-gradient(0deg, transparent 0 4px, ${primaryBlue} 4px 12px)`,
    },
    circleIcon: {
      backgroundColor: primaryBlue,
      color: 'brand.white',
    },
    location: {
      color: basegrey100,
    },
    description: {
      color: basegrey100,
    },
    expandButton: {
      color: primaryBlue,
    },
    iconContainer: {
      color: primaryBlue,
    },
  },
})
