import { helpers } from './base'

export const CarAdditionalProductCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    wrapper: {
      borderColor: 'brand.primaryShamrockGreen',
    },
    heading: {
      color: 'brand.black',
      fontWeight: 500,
    },
    duration: {
      color: 'brand.coolgrey10',
      fontWeight: 400,
      fontSize: 'xs',
    },
    description: {
      color: 'brand.coolgrey10',
      fontWeight: 400,
      fontSize: 'sm',
    },

    iconWrapper: {
      backgroundColor: 'brand.darkCityGreen',
    },
  },
})
