/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const RoomRateSelector = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    optionIndicator: {
      backgroundColor: 'brand.primary.blue',
      color: 'brand.white',
    },
    soldOutOptionIndicator: {
      backgroundColor: 'brand.baseGray.50',
      color: 'brand.primary.midnightBlue',
    },
    optionContainerSelected: {
      borderColor: 'brand.primary.blue',
      borderRadius: 'sm',
      _disabled: {
        borderColor: 'brand.baseGray.50',
      },
    },
    optionContainerUnselected: {
      borderColor: 'brand.baseGray.50',
      borderRadius: 'sm',
      _disabled: {
        borderColor: 'brand.baseGray.50',
      },
    },
    inlineText: {
      fontWeight: 'normal',
      fontSize: 'xs',
    },
    optionInfoBox: {
      '& + &': {
        marginTop: 2.5,
      },
    },
    boldText: {
      fontWeight: 'light',
      color: 'brand.primary.blue',
      fontSize: 'xl',
      marginBottom: 1,
      letterSpacing: 'wide',
    },
    semiboldText: {
      fontSize: 'xs',
      fontWeight: 'medium',
    },
    priceContainer: {
      justifyContent: { base: 'center', md: 'unset' },
    },
  }),
})
