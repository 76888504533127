import { helpers } from './base'

export const Drawer = helpers.defineMultiStyleConfig({
  variants: {
    primary: {
      content: { backgroundColor: 'brand.cloudWhite' },
      closeButton: {
        _hover: {
          backgroundColor: 'brand.coolgrey1',
        },
      },
    },
  },
})
