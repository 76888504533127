import { helpers } from './base'

export const Filters = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      padding: { base: 0, lg: 4 },
      height: 'full',
      bg: 'brand.white',
      borderRadius: 'sm',
    },
    header: {
      boxShadow: '-2px 0px 0 0 var(--chakra-colors-brand-white)',
      bg: 'brand.white',
    },
    heading: {
      lineHeight: 'base',
      fontSize: '2xl',
      fontWeight: 'light',
    },
    title: {
      lineHeight: 'tall',
      fontSize: 'xs',
      fontWeight: 'normal',
    },
  },
})
