/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const Ratings = helpers.defineMultiStyleConfig({
  baseStyle: {
    ratingWrapper: {
      alignItems: 'center',
      svg: {
        width: '12px',
        height: '11px',
      },
      path: {
        fill: '#E97400',
      },
    },
    ratingTextWrapper: {
      marginLeft: '2xs',
    },
    ratingStar: {
      mr: '4xs',
    },
  },
})
