import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const autoCompleteHelpers = createMultiStyleConfigHelpers([
  'popup',
  'field',
  'buttonField',
  'option',
])

export const AutoComplete = autoCompleteHelpers.defineMultiStyleConfig({
  baseStyle: {
    popup: {
      position: 'absolute',
      zIndex: 10,
      padding: '0',
      top: 1,
      left: 0,
      right: 0,
      maxHeight: 'xs',
      overflow: 'auto',
      borderRadius: 'base',
      backgroundColor: 'brand.white',
      boxShadow: '0 3px 8px rgba(0, 0, 0, 0.2)',

      "[class^='chakra-modal'] &": {
        position: 'static',
        boxShadow: 'none',
        maxHeight: 'calc(100% - 1rem)',
        marginBlock: 2,
        backgroundColor: 'transparent',
      },
    },
    field: {
      "[class^='chakra-modal'] &": {
        height: 'full',
        gridTemplate:
          "'label note' 1.5rem 'value icon' minmax(1.75rem, max-content) 'error error' auto 'popup popup' minmax(auto, 100%) / 1fr max-content",
      },

      "[type='text']": {
        cursor: 'text',
      },

      input: {
        paddingRight: 8,
      },
    },
    option: {
      padding: 2,
      cursor: 'pointer',

      '& + &': {
        borderTop: '1px solid #ccc',
      },

      "&:hover, &[aria-selected='true'], &[data-highlighted='true']": {
        backgroundColor: '#eee',
      },
    },
    buttonField: {
      button: {
        display: 'block',
        textAlign: 'left',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textWrap: 'nowrap',
        paddingRight: 8,
        fontWeight: 'light',
        _hover: { background: 'transparent' },
        _focus: { background: 'transparent' },

        "&[aria-disabled='true']": {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  variants: {
    error: {
      field: {
        '> input, > input:focus': {
          border: '1px solid',
          borderColor: 'error.primary',
        },
      },
      buttonField: {
        '> button, > button:focus': {
          border: '1px solid',
          borderColor: 'error.primary',
        },
      },
    },
    label: {
      buttonField: {
        'button ~ label': {
          fontSize: '1rem !important',
          transform: 'translateY(0.75rem) !important',
        },
      },
    },
  },
})

export const autoCompleteLocationOptionHelpers = createMultiStyleConfigHelpers([
  'locationOptionWrapper',
  'autoCompleteListIcon',
  'autoCompleteListTitle',
  'autoCompleteListP',
])

export const AutoCompleteLocationOption = autoCompleteLocationOptionHelpers.defineMultiStyleConfig({
  baseStyle: {
    locationOptionWrapper: {
      display: 'flex',
      flexDirection: 'row',
      borderBottom: '1px',
      borderColor: 'brand.coolgrey1',
      alignItems: 'center',
      padding: 0,
      cursor: 'pointer',

      _hover: {
        cursor: 'pointer',
      },
    },
    autoCompleteListIcon: {
      margin: 4,
    },
    autoCompleteListTitle: {
      fontSize: 'sm',
    },
    autoCompleteListP: {
      fontSize: 'sm',
    },
  },
})
