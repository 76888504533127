import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const invalidInputStyles = {
  borderColor: 'error.primary',
}
const iconStyles = {
  position: 'absolute',
  height: '100%',
  bottom: 0,
  right: 0,
  margin: 0,
  padding: 0,
  paddingRight: 4,
  textAlign: 'right',
  alignSelf: 'center',
  alignItems: 'center',
  pointerEvents: 'none',
}
export const inputPaddingTop = 'var(--chakra-space-7)'
export const inputPaddingBottom = 'var(--chakra-space-2)'

export const formHelper = createMultiStyleConfigHelpers([
  'container',
  'helperText',
  'icon',
  'comboBox',
  'inputContainer',
  'errorIcon',
  'errorMessage',
  'errorDot',
])
export const Form = formHelper.defineMultiStyleConfig({
  variants: {
    floating: {
      container: {
        display: 'flex',
        flexDirection: 'column',
        lineHeight: 'normal',
        label: {
          gridArea: 'label',
          margin: 0,
          padding: '0.8rem 1rem 0',
          fontSize: 'md',
          transform: 'translateY(0.75rem)',
          transition:
            '0.25s transform cubic-bezier(0.23,1,0.32,1), 0.25s font-size cubic-bezier(0.23,1,0.32,1)',
          fontWeight: 'light',
          color: 'black',
          pointerEvents: 'none',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',

          '&.floating': {
            transform: 'translateY(-0.25rem)',
            fontSize: '0.75rem',
          },
        },
        input: {
          display: 'flex',
        },
        textarea: {
          resize: 'vertical',
          minHeight: 36,
        },
        'input, select, textarea, .flex-microform, button': {
          appearance: 'none',
          gridArea: '1/1 / span 2 / span 2',
          borderRadius: '0.25rem',
          background: 'transparent',
          padding: `${inputPaddingTop} 0.9375rem ${inputPaddingBottom}`,
          fontSize: '1rem',
          width: 'inherit',
          height: 'fit-content',
          color: '#000',

          _placeholder: {
            color: 'transparent',
          },

          _invalid: {
            ...invalidInputStyles,
          },
          '&.flex-microform-invalid, &.flex-microform-field-invalid': {
            ...invalidInputStyles,
          },
        },
        button: {
          display: 'flex',
          justifyContent: 'flex-start',
        },
        'label[data-invalid], .flex-microform-invalid ~ label, .flex-microform-field-invalid ~ label':
          {
            color: 'error.primary',
          },
      },
      helperText: {
        gridArea: 'note',
        fontSize: '0.75rem',
        color: 'gray.800',
        pointerEvents: 'none',
      },
      icon: {
        ...iconStyles,
      },
      errorIcon: {
        ...iconStyles,
        svg: {
          display: 'none',
        },
      },
      errorDot: {
        display: 'none',
      },
      comboBox: {
        position: 'relative',
        minW: '100%',
      },
      inputContainer: {
        position: 'relative',
        display: 'grid',
        gridTemplateColumns: '1fr min-content',
        gridTemplateAreas: "'label note' 'input note'",
        bg: 'white',

        '.fake-focus': {
          outlineWidth: 1,
          borderRadius: 'lg',
          outlineStyle: 'solid',
        },

        'input:not([type=button]):focus ~ label, input:not([type=button]):not(:placeholder-shown) ~ label, textarea:focus ~ label, textarea:not(:placeholder-shown) ~ label, input[type=button]:not([value=""]) ~ label, .flex-microform-field-nonempty ~ label, .flex-microform-focused ~ label, button ~ label, button ~ label, .fake-focus ~ label':
          {
            fontSize: '0.6875rem',
            transform: 'none',
          },
        input: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          textAlign: 'left',
        },
      },
    },
  },
  baseStyle: formHelper.definePartsStyle({}),
})
