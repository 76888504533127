import { PartnerConfig } from '~/schemas/partner'
import aviosTypographyStyles from './avios.typography'

import { PartnerStylesMap } from '../styles.types'

const typographyMap: PartnerStylesMap = {
  aer_lingus: null,
  vueling: null,
  british_airways: null,
  avios: aviosTypographyStyles,
}

export const getPartnerTypography = (partnerTheme: PartnerConfig['theme']) =>
  typographyMap[partnerTheme]
