import { getPlatformPathname } from '../partner'

export const AUTH_PATHS = {
  LOGIN: '/auth/login',
  LOGOUT: '/auth/logout',
  CALLBACK: '/auth/callback',
  PROFILE: '/auth/me',
} as const

export type AuthPathKey = keyof typeof AUTH_PATHS

export const getClientAuthPath = (type: AuthPathKey, returnToPath?: string) => {
  const authPath = getPlatformPathname(AUTH_PATHS[type])
  const returnTo = returnToPath && getPlatformPathname(returnToPath)

  const returnToQuery = returnTo ? `?returnTo=${encodeURIComponent(returnTo)}` : ''
  return `${authPath}${returnToQuery}`
}
