/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const NotificationMessage = helpers.defineMultiStyleConfig({
  baseStyle: {
    notificationWrapper: {
      borderRadius: 0,
    },
    iconWrapper: {
      px: 5,
      pb: 0.5,
    },
    notificationText: {
      padding: 3.5,
    },
    notificationButton: {
      '> button': {
        border: 'none',
      },
    },
    notificationBody: {
      strong: {
        fontWeight: 500,
      },
    },
  },
  variants: {
    ERROR: {
      notificationText: {
        color: 'brand.white',
      },
      notificationWrapper: {
        backgroundColor: 'brand.red',
        color: 'brand.white',
      },
      notificationButton: {
        '> button': {
          color: 'brand.red',
        },
      },
      closeIcon: {
        ':focus-visible': {
          outlineColor: 'brand.white',
        },
      },
    },
    INFO: {
      notificationWrapper: {
        backgroundColor: 'brand.teal5',
        color: 'brand.darkCityGreen',
        p: {
          color: 'brand.black',
        },
      },
      notificationButton: {
        '> button': {
          color: 'brand.darkCityGreen',
        },
      },
    },
    SUCCESS: {
      notificationWrapper: {
        backgroundColor: 'brand.teal5',
        color: 'brand.darkCityGreen',
        p: {
          color: 'brand.black',
        },
      },
    },
    WARNING: {
      notificationWrapper: {
        backgroundColor: 'brand.red5',
        color: 'brand.red',
        p: {
          color: 'brand.black',
        },
      },
      notificationButton: {
        '> button': {
          color: 'brand.red',
        },
      },
    },
  },
})
