/* eslint-disable sonarjs/no-duplicate-string */
import { helper } from './base'

const buttonStyle = {
  maxH: '42px',
  h: 'auto',
  minWidth: 'fit-content',
  bg: 'bg.accent.subtle.default',
  color: 'fg.accent.primary',
  _hover: {
    bg: 'bg.accent.subtle.hover',
  },
  '&:active, &:focus': {
    bg: 'bg.accent.subtle.active',
    outline: 'none',
  },
}

export const UpgradeBasketItem = helper.defineMultiStyleConfig({
  baseStyle: {
    container: {
      borderBottomRadius: '3xs',
      bg: 'bg.accent.vibrant.default',
      'label > p': {
        width: 'full',
        paddingRight: '2xs',
        fontSize: 'xs',
      },
      'div:nth-child(2)': {
        width: 'unset',
        '> div > div': {
          width: { base: 'full', md: '346px' },
          position: 'relative',

          '> input': {
            borderRadius: 'xs',
            position: 'relative',
          },
        },
      },
      'div:nth-child(2) > div > div > ul': {
        border: '1px solid',
        borderColor: 'border.accent',
        borderRadius: 'xs',

        '> li > div': {
          px: 'sm',
          py: '2xs',

          '> div': {
            '> span': {
              '> p': {
                fontSize: 'xs',
                fontWeight: 'normal',
                '> span': {
                  fontWeight: 'semibold',
                },
              },
            },
          },
        },
      },
    },
    icon: {
      color: 'fg.accent.onVibrant',
    },
    iconCircle: {
      'path:first-child': {
        fill: 'fg.accent.onVibrant',
      },
      'path:nth-child(2)': {
        fill: 'bg.accent.onVibrant.default',
      },
    },
    text: {
      fontSize: 'md',
      fontWeight: 'medium',
      color: 'fg.accent.onVibrant',
    },
  },
  variants: {
    upgrade: {
      button: {
        ...buttonStyle,
      },
    },
    confirmUpgrade: {
      button: {
        ...buttonStyle,
      },
    },
  },
})
