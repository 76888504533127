import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const flightServiceItineraryHelpers = createMultiStyleConfigHelpers([
  'container',
  'serviceBoxWrapper',
])

export const FlightServiceItineraryProp = flightServiceItineraryHelpers.defineMultiStyleConfig({
  baseStyle: flightServiceItineraryHelpers.definePartsStyle({
    container: {
      display: 'flex',
      flexDir: { base: 'column', sm: 'row' },
      justifyContent: 'space-between',
      gap: 2,
    },
    serviceBoxWrapper: {
      p: { base: 4, md: 2 },
      gap: 2,
      display: 'flex',
      flexDir: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      svg: {
        width: { base: 5, md: 4 },
        height: { base: 5, md: 4 },
      },
    },
  }),
})
