import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const borderStyles = '2px solid black'

export const helpers = createMultiStyleConfigHelpers(['arrow', 'content', 'body'])

export const ChakraPopover = helpers.defineMultiStyleConfig({
  baseStyle: {
    arrow: {
      borderTop: borderStyles,
      borderLeft: borderStyles,
    },
    content: {
      padding: '16px 10px',
      bg: 'white',
      color: 'black',
      border: borderStyles,
      borderRadius: '0.5rem',
      w: '100%',
      maxW: '200px',
    },
    body: {
      fontSize: '14px',
      p: 0,
    },
  },
})
