import React from 'react'
import { usePromotionContext } from '~/context/promotion'
import { StickyBanner } from '@reward-platform/lift/components'
import { DynamicVariableRichText } from '../shared/DynamicVariableRichText'
import { PromotionProps } from './Promotion.types'

const PromotionStickyBanner = ({ product }: PromotionProps) => {
  const { isEnabled, getProductPromotion } = usePromotionContext()
  const activePromotion = getProductPromotion(product)
  const { stickyBannerText } = activePromotion?.content || {}

  if (!isEnabled(product) || !stickyBannerText) {
    return null
  }

  return (
    <StickyBanner icon="gift" isClosable>
      <DynamicVariableRichText
        document={stickyBannerText.content}
        variableMap={activePromotion?.variableMap}
      />
    </StickyBanner>
  )
}

export default PromotionStickyBanner
