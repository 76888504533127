import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'priceBreakdownContainer',
  'definitionGroup',
  'aviosDueLabel',
  'aviosDueValue',
  'cash',
  'cashLabel',
  'cashValue',
  'cashDue',
  'cashDueLabel',
  'cashDueValue',
])

export const PriceBreakdownContainer = helpers.defineMultiStyleConfig({
  baseStyle: {
    priceBreakdownContainer: {
      dl: {
        mb: 3,
      },
    },
    cashDue: {
      '> dl': {
        alignItems: 'start',
      },
    },
    cashDueLabel: {
      display: 'block',
    },
    definitionGroup: {
      p: {
        __first: {
          fontsize: 'sm',
        },
      },
    },
  },
})
