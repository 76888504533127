import { modalAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, keyframes } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers(parts.keys)

const flyUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(6rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

export const Modal = helpers.defineMultiStyleConfig({
  baseStyle: (props) => ({
    overlay: {
      zIndex: 'modal',
      backgroundColor: 'rgba(0,0,0,0.5)',
    },
    dialogContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: props?.isCentered ? 'center' : 'flex-start',
      width: '100vw',
      height: '100vh',
      '&': {
        height: '100dvh',
      },
      overflowY: 'scroll',
      overflowX: 'hidden',
    },
    dialog: {
      bg: 'white',
      width: { base: '100vw', md: 'auto' },
      margin: { md: 8 },
      marginY: { lg: 24 },
      borderRadius: { base: 0, md: '2xl' },
      padding: { base: 0, md: 2 },
      overflowY: { base: 'scroll', md: 'auto' },
      height: { base: 'full', md: 'auto' },
      animation: { base: 'none', md: `${flyUp} ease-in-out 0.25s forwards` },
      paddingBottom: 'env(safe-area-inset-bottom)',

      '& > ul': {
        position: 'static',
        boxShadow: 'none',
        maxHeight: 'calc(100% - 6rem)',
        marginBottom: 2,
        backgroundColor: 'transparent',
      },
    },
    header: {
      padding: 0,
      mt: { base: 14, md: 0 },
      '& > *': {
        fontSize: { base: '2xl', md: '4xl' },
        px: { base: 4, md: 12 },
        py: 0,
        mb: 4,
        lineHeight: '1.4',
      },
    },
    body: {
      px: { base: 4, md: 12 },
      pt: 0,
      pb: { base: 4, md: 12 },
      mb: { base: 4, md: 0 },

      height: 'full',
      bg: 'inherit',
      minWidth: { base: 'full', md: 'auto' },
      fontSize: 'lg',
    },
    closeButton: {
      position: { base: 'fixed', md: 'relative' },
      right: 0,
      top: 0,
      my: 2,
      ml: 'auto',
      mr: 2,

      border: 'none',
      background: 'none',
      color: 'black',
      cursor: 'pointer',
      width: 10,
      height: 10,
      fontSize: 'md',
      zIndex: 'modal',
      _before: {
        display: { base: 'block', md: 'none' },
        width: 'full',
        content: '""',
        position: 'fixed',
        top: 0,
        left: 0,
        height: 14,
        bg: 'white',
        pointerEvents: 'none',
        zIndex: 'modal',
      },
      '& > svg': {
        zIndex: 'skipLink',
      },
    },
  }),
  sizes: {
    xl: {
      dialog: {
        maxWidth: { base: 'full', md: 'auto' },
      },
      body: {
        pb: { base: 4, md: 12 },
      },
    },
    md: {
      dialog: {
        maxWidth: { base: '100%', md: 'calc(100vw - 4rem)', lg: '68rem' },
      },
      body: {
        px: 0,
        pb: { base: 4, md: 12 },
        pr: { base: 4, md: 12 },
        pl: { base: 4, md: 12 },
      },
    },
    sm: {
      dialog: {
        minHeight: { lg: '50rem' },
        maxWidth: { base: '100%', md: 'calc(100vw - 4rem)', lg: '52rem' },
      },
      body: {
        pb: { base: 0, md: 0 },
        minW: { base: '50rem' },
        mb: 4,
      },
    },
  },
  variants: {
    modal: {
      dialog: {
        padding: { base: 0, md: 2 },
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
})
