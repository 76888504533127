import { z } from 'zod'
import { ContentfulRichTextDocumentSchema } from './common'
import { ContentfulAssetSchema } from './asset'

export const ContentfulModalContentSchema = z.object({
  identifier: z.string(),
  title: z.string().optional(),
  body: ContentfulRichTextDocumentSchema.optional(),
  image: ContentfulAssetSchema.optional(),
  primaryButtonText: z.string().optional(),
  secondaryButtonText: z.string().optional(),
})

export type ContentfulModalContent = z.infer<typeof ContentfulModalContentSchema>
