import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers(['control', 'label', 'container', 'icon'])
export const RadioButton = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    container: {
      w: 'fit-content',
      display: 'flex',
      alignItems: 'start',
    },
    control: {
      w: 6,
      h: 6,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,
      borderRadius: '50%',
      border: '1px solid #888b8d',
      boxShadow: 'inset 1px 3px 6px 0 rgba(0, 0, 0, 0.1)',
      bg: 'white',
      marginRight: 2,
      _checked: {
        bg: 'black',
      },
      svg: {
        fill: 'white',
      },
    },
    label: {
      fontSize: '1rem',
    },
    icon: {},
  }),
  variants: {
    filter: {
      container: {
        margin: '1rem 0',
      },
      label: {
        fontWeight: '500',
      },
    },
  },
})
