import { helpers } from './base'

const black = 'brand.black'
export const CustomerReviewList = helpers.defineMultiStyleConfig({
  baseStyle: {
    contentWrapper: { color: black },
    customerReviewsCtn: { rowGap: 4 },
    customerReviewCtn: {
      bgColor: 'brand.white',
      borderRadius: 'lg',
      boxShadow: 'var(--chakra-shadows-brand)',
    },
    title: { mt: 4, mb: 4 },
    ratingsCtn: {
      small: {
        fontSize: { base: 'xs', md: 'sm' },
      },
    },
    icons: {
      color: black,
      fontSize: { base: 'xs', lg: 'sm' },
    },
    icon: { color: black },
    reviewText: {
      fontSize: { base: 'md', lg: 'lg' },
      color: 'brand.coolgrey10',
    },
    showMoreButton: {
      fontSize: 'lg',
      fontWeight: 'normal',
    },
    readMoreButton: {
      maxWidth: '110px',
      maxHeight: '30px',
      fontSize: 'sm',
      borderRadius: '3xl',
    },
  },
})
