import { helpers } from './base'

export const InfoMessage = helpers.defineMultiStyleConfig({
  baseStyle: {
    infomessagewrapper: {
      padding: '4',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'brand.primaryTeal',
      borderRadius: '2',
      bgColor: 'brand.coolgrey2',
      color: 'brand.coolgrey10',
    },
    iconwrapper: { color: 'brand.primaryTeal' },
  },
})
