/* eslint-disable sonarjs/no-duplicate-string */
import { pricingHelpers } from './base'

export const Pricing = pricingHelpers.defineMultiStyleConfig({
  baseStyle: {
    headerLabel: {
      fontWeight: 'light',
      color: 'fg.primary',
    },
    price: {
      fontSize: 'var(--alto-sem-text-heading-sm-font-size)',
      color: 'fg.accent.primary',
      fontWeight: 'semibold',
      margin: 0,
      padding: 0,
    },
    priceNote: {
      color: 'fg.primary',
      fontSize: 'var(--alto-sem-text-body-xs-font-size)',
      lineHeight: 'var(--alto-sem-text-body-xs-line-height)',
      strong: {
        fontWeight: 'semibold',
      },
    },
    noteAlignLeft: {
      marginRight: '-20px',
    },
    expandButton: {
      w: '16px',
      h: '16px',
      bg: 'bg.accent.vibrant.default',
      color: 'fg.accent.onVibrant',
      mt: 'xs',
      svg: {
        width: '10px',
        height: '10px',
      },
    },
    pricingOptionsBefore: {
      borderRadius: 'xs',
      backgroundColor: 'bg.layer1.default',
      top: '-100px',
    },
    pricingOptionsList: {
      color: 'fg.secondary',
      fontSize: 'var(--alto-sem-text-body-sm-font-size)',
      lineHeight: 'var(--alto-sem-text-body-sm-line-height)',
    },
    originalPrice: {
      color: 'fg.critical.primary',
    },
    chip: {
      color: 'fg.accent.onSubtle',
      bgColor: 'bg.accent.subtle.default',
    },
    pricingOptionsContainer: {
      boxShadow: 'md',
      borderRadius: 'xs',
      width: 'unset',
    },
  },
  variants: {
    mapOpen: {
      price: {
        fontSize: 'var(--alto-sem-text-heading-xs-font-size)',
      },
    },
    right: {
      expandButton: {
        position: 'relative',
        right: 'unset',
      },
      promoBadge: {
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },
  },
  sizes: {
    small: {
      price: {
        fontSize: 'var(--alto-sem-text-heading-xs-font-size)',
      },
    },
  },
})
