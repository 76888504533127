import { useEffect } from 'react'

export const useClickAway = (
  wrapperRef: React.MutableRefObject<HTMLElement | undefined | null>,
  isEnabled: boolean,
  onClickAway: () => void
): void => {
  useEffect(() => {
    function onClick(evt: MouseEvent) {
      if (!isEnabled || !wrapperRef.current) {
        return
      }
      const isClickInsideContainer = wrapperRef.current.contains(evt.target as HTMLElement)
      if (!isClickInsideContainer) {
        onClickAway()
        evt.preventDefault()
        evt.stopPropagation()
      }
    }

    window.addEventListener('click', onClick, true)
    return () => {
      window.removeEventListener('click', onClick, true)
    }
  }, [wrapperRef, isEnabled, onClickAway])
}
