import { OnlyCashPaymentOption, PaymentOption, PaymentOptions } from '../basket'

export type GetPaymentOptionAndConvertParams<T> = {
  paymentOptionId: string
  paymentOptions: PaymentOptions
  convertPaymentOption: (option: PaymentOption) => T
  convertOnlyCashPaymentOption: (option: OnlyCashPaymentOption) => T
}

export const getPaymentOptionAndConvert = <T>({
  convertOnlyCashPaymentOption,
  convertPaymentOption,
  paymentOptionId,
  paymentOptions: { onlyCashPaymentOption, options },
}: GetPaymentOptionAndConvertParams<T>): T | undefined => {
  const option = options.find(({ id }) => id === paymentOptionId)
  if (option) {
    return convertPaymentOption(option)
  }

  if (onlyCashPaymentOption?.id === paymentOptionId) {
    return convertOnlyCashPaymentOption(onlyCashPaymentOption)
  }

  return undefined
}
