import { helpers } from './base'

export const Dropdown = helpers.defineMultiStyleConfig({
  baseStyle: {
    title: {
      color: 'brand.black',
    },
    subtitle: {
      color: 'brand.coolgrey10',
      fontWeight: 'normal',
    },
    titleIcon: {
      color: 'brand.primaryTeal',
    },
    buttonIcon: {
      color: 'brand.black',
    },
  },
})
