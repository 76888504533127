import { ComponentStyleConfig } from '@chakra-ui/react'

export const StepperCircle: ComponentStyleConfig = {
  baseStyle: {
    display: 'flex',
    width: { base: 6, md: 8 },
    height: { base: 6, md: 8 },
    fontSize: { base: 'sm', md: 'md' },
    padding: 3,
    flexGrow: 0,
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: '0.13rem',
    borderColor: 'red',
    borderRadius: '50%',
    borderStyle: 'solid',
    userSelect: 'none',
    _focusVisible: {
      outlineStyle: 'solid',
      outlineColor: 'brand.focusOutline',
    },
  },
  variants: {
    default: { bg: 'green' },
    completed: { bg: 'red' },
    selected: { bg: 'red' },
  },
}
