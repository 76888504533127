import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const flightItineraryHelpers = createMultiStyleConfigHelpers([
  'headerContainer',
  'detailsContainer',
  'arrow',
  'arrow_left',
  'testGrid',
  'detailsColumn',
  'flightHeader',
])

export const FlightItineraryProp = flightItineraryHelpers.defineMultiStyleConfig({
  baseStyle: flightItineraryHelpers.definePartsStyle({
    headerContainer: {
      display: 'flex',
      alignItems: 'baseline',
      pb: 4,
      span: {
        _first: {},
        _last: {
          ml: 'auto',
        },
      },
    },
    detailsContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 100px)',
    },
    flightHeader: {
      display: 'flex',
      '>span': {
        _last: {
          ml: 'auto',
        },
      },
    },
    arrowWrapper: {
      display: 'flex',
      flexDir: 'row',
      pt: 1,
      alignItems: 'center',
      w: '100%',
      '>div': {
        _first: {
          w: '100%',
        },
      },
    },
    arrow: {
      height: '1px',
      margin: '1px auto',
      border: 0,
      position: 'relative',
      overflow: 'hidden',

      top: '2%',
      _after: {
        content: '""',
        position: 'absolute',
        borderStyle: 'solid',
        borderWidth: '15px 15px 0',
        height: '10px',
        display: 'block',
        width: 0,
        right: 0,
        zIndex: 1,
      },
    },
    testGrid: {
      display: 'grid',
      gridTemplateColumns: '30% 40% 30%',
    },
  }),
})
