import countriesJSON from './countries/countries.json'
import usStatesJSON from './statesOrProvinces/US.json'
import caProvincesJSON from './statesOrProvinces/CA.json'

/**
 * These types live here due to a circular dependency between the utils and order-schemas libs, we
 * might want to refactor this some time.
 */
export type Country = { label: string; value: string }
export type Countries = Country[]

export type StateOrProvince = { label: string; value: string }
export type StatesOrProvinces = StateOrProvince[]
export type StateOrProvinceMap = Record<Country['value'], StatesOrProvinces>

export const countries: Countries = countriesJSON.map((country) => ({
  value: country.alpha2Value,
  label: country.label,
}))
export const countryFromAlpha3: (alpha3?: string) => Country | undefined = (alpha3) => {
  if (!alpha3) {
    return undefined
  }
  const result = countriesJSON.find(({ alpha3Value }) => alpha3Value === alpha3)
  if (result) {
    return { value: result.alpha2Value, label: result.label }
  }
  return undefined
}

export const stateOrProvinceMap: StateOrProvinceMap = {
  US: usStatesJSON,
  CA: caProvincesJSON,
}

export * from './forms/validation'
export * from './stateStorage/useStateStorage'
export * from './stateStorage/storageKeys'
export * from './isClientSide/isClientSide'
export * from './flights/flights'
export * from './exceptions/ErrorCodes'
