import { helpers } from './base'

export const StickyBanner = helpers.defineMultiStyleConfig({
  baseStyle: {
    wrapper: { backgroundColor: 'brand.teal5' },
    banner: {},
    icon: { color: 'brand.primaryTeal' },
    message: {
      color: 'brand.primaryTeal',
    },
    crossIcon: {},
  },
})
