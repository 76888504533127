import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'selectBoxLabel',
  'selectBoxSelect',
  'iconContainer',
  'chevronColor',
])

export const SelectBox = helpers.defineMultiStyleConfig({
  baseStyle: {
    selectBoxLabel: {
      position: 'absolute',
      fontSize: 'xs',
      paddingRight: 4,
      paddingLeft: 4,
      paddingTop: 2.5,
      paddingBottom: 0,
    },
    selectBoxSelect: {
      paddingRight: 3.5,
      paddingLeft: 3.5,
      paddingTop: 5,
      paddingBottom: 0,
      height: 14,
      width: 'full',
      border: 'solid 1px',
      outline: 'none',

      appearance: 'none',
      textIndent: '1px',
      textOverflow: '',

      _focus: {
        outline: 'none',
        border: '2px solid',
      },
    },
    iconContainer: {
      position: 'absolute',
      top: '24px',
      right: '15px',
      pointerEvents: 'none',
    },
    chevronColor: {
      color: 'black',
    },
  },
})
