import React from 'react'
import Script from 'next/script'
import { isProduction, isStaging } from '~/utils/envChecks'

const OneTrust = () => {
  const OneTrustProductionId = '018df036-17b0-7cb5-9795-6cd41b630030'
  const OneTrustStagingId = '0192435e-8dfc-7e2b-bff8-01c2b25f4712'

  const getOneTrustId = () => {
    if (isProduction()) {
      return OneTrustProductionId
    }

    if (isStaging()) {
      return OneTrustStagingId
    }

    return `${OneTrustStagingId}-test`
  }

  return (
    <>
      <Script
        id="onetrust-core"
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
        data-domain-script={getOneTrustId()}
      />
      <Script strategy="afterInteractive" id="onetrust-optanon">
        {
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          function OptanonWrapper() {} as any
        }
      </Script>
    </>
  )
}

export default OneTrust
