/* eslint-disable sonarjs/no-duplicate-string */
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helper = createMultiStyleConfigHelpers([
  'orderListTableGrid',
  'orderListTableHeader',
  'orderListTableItemGrid',
  'orderListTableFooter',
  'orderLineItem',
  'headerLabels',
  'textBold',
  'aviosCollected',
  'accented',
  'lineBalance',
  'lineName',
  'lighter',
  'lineDetails',
  'total',
  'travellersAmount',
  'divider',
  'aviosEarned',
  'zeroAvios',
  'modalSubtitle',
  'modalDescription',
])

export const OrderListTable = helper.defineMultiStyleConfig({
  baseStyle: {
    orderListTableGrid: {
      gridTemplateAreas: `"header" "content" "footer"`,
      gap: 1,
      p: 4,
      boxShadow: '0 2px 4px  rgba(0, 0, 0, 0.1)',
    },
    orderListTableHeader: {
      px: {
        base: 3,
        sm: 5,
      },
      py: 3,
    },
    orderListTableItemGrid: {
      p: {
        base: 3,
        sm: 5,
      },
    },
    orderListTableFooter: {
      px: {
        base: 3,
        sm: 5,
      },
      py: 3,
    },
    orderLineItem: {
      gridTemplateColumns: {
        base: 'repeat(2, 1fr)',
        md: 'repeat(5, minmax(100px, 1fr))',
        lg: 'repeat(7, minmax(100px, 1fr))',
      },
      gridTemplateRows: 'repeat(auto-fit, minmax(10px, 1fr))',
      gap: 2,
    },
    aviosCollected: {
      'p:first-of-type': {
        button: {
          width: 'auto',
          height: 'auto',
          ml: { base: '2px', md: 0 },
          svg: {
            w: 'auto',
          },
        },
      },
    },
    travellersAmount: {
      mt: 1,
    },
    aviosEarned: {
      display: {
        base: 'flex',
        md: 'block',
      },
      gridColumnStart: {
        lg: 6,
      },
      justifyContent: 'space-between',
      justifySelf: {
        lg: 'center',
      },
      w: {
        lg: 'max-content',
      },
    },
  },
})
