import { helpers } from './base'

export const SearchHotelForm = helpers.defineMultiStyleConfig({
  baseStyle: {
    submitBtn: {
      height: {
        base: '56px',
        md: '62px',
      },
    },
  },
})
