import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const itemCardImageWithLogoHelper = createMultiStyleConfigHelpers(['imageWrapper'])

export const ItemCardImageWithLogo = itemCardImageWithLogoHelper.defineMultiStyleConfig({
  baseStyle: itemCardImageWithLogoHelper.definePartsStyle({
    imageWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: 'full',
      padding: 4,
      img: {
        maxWidth: 'full',
        _first: {
          objectFit: 'contain',
        },
        _last: {
          height: 6,
          marginTop: 4,
          objectFit: 'scale-down',
        },
      },
    },
  }),
  sizes: {
    sm: {
      imageWrapper: {
        minHeight: 38,
        img: {
          _first: {
            h: 28,
          },
          _last: {
            height: '1rem',
            objectFit: 'scale-down',
          },
        },
      },
    },
  },
})
