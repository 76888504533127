/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const Combobox = helpers.defineMultiStyleConfig({
  baseStyle: {
    styledOption: {
      color: 'fg.primary',

      '&.disabled': {
        color: 'fg.disabled.onSubtle',
        bg: 'bg.disabled.subtle',
      },
    },
    buttonField: {
      color: 'fg.primary',
    },
    comboBoxContainer: {
      border: 'none',
      color: 'fg.primary',
      fontWeight: 'medium',
    },
  },
})
