import { helpers } from './base'

export const HotelCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    hotelCardWrapper: {
      borderRadius: 'xs',
      boxShadow: 'md',
    },
    heading: {
      fontSize: 'var(--alto-sem-text-heading-xs-font-size)',
      fontWeight: 'bold',
      lineHeight: 'var(--alto-sem-text-heading-xs-line-height)',
      letterSpacing: 'var(--alto-sem-text-heading-xs-letter-spacing)',
      mt: 'xs',
      color: 'fg.accent.primary',
    },
    duration: {
      fontSize: 'sm',
    },
    paymentOptionsHeading: {
      fontWeight: 'semibold',
    },
  },
})
