import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { clamp } from '../../utils/clamp'
import { TabContentProps, TabState } from './Tabs.interface'

const isExternalLink = (href?: string) => !!href?.startsWith('https://')

const useTabs = (
  children: ReactElement<TabContentProps>[],
  selectedTab: string,
  setSelectedTab: TabState['setSelectedTab']
) => {
  const tabs = useMemo(() => children, [children])
  const findTabIndex = useCallback(
    (slug: string) => {
      const index = tabs.findIndex((tab) => tab.props.slug === slug)
      return index >= 0 ? index : 0
    },
    [tabs]
  )
  const [currentTabIndex, setCurrentTabIndex] = useState(findTabIndex(selectedTab))

  useEffect(() => {
    if (tabs.length > 0) {
      const firstTab = tabs.find((tab) => !isExternalLink(tab.props.href)) || tabs[0]
      const defaultSlug = firstTab.props.slug
      const selectedTabChild = selectedTab ? tabs[findTabIndex(selectedTab)] : undefined
      if (selectedTabChild === undefined || isExternalLink(selectedTabChild.props.href)) {
        setSelectedTab(defaultSlug, true)
      }
    }
  }, [tabs, selectedTab, setSelectedTab, findTabIndex])

  const tabContent = useMemo(
    () => tabs.find((child) => child.props.slug === selectedTab),
    [tabs, selectedTab]
  )

  const listRef = useRef<HTMLUListElement>(null)
  const tabsRef = useRef<Array<HTMLAnchorElement>>([])

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      const getDirection = () => {
        const arrowLeft = 'ArrowLeft'
        const arrowRight = 'ArrowRight'

        if (e.key === arrowRight) {
          return 1
        }
        if (e.key === arrowLeft) {
          return -1
        }
        return undefined
      }

      const direction = getDirection()
      if (direction === undefined) {
        return
      }

      // Find the target tab index
      const targetIndex = clamp(currentTabIndex + direction, 0, tabs.length - 1)

      if (targetIndex === currentTabIndex) {
        return
      }

      e.preventDefault()

      // Find the target tab
      const targetTab = tabs[targetIndex]
      const targetEl = tabsRef.current[targetIndex]
      targetEl.focus()
      setCurrentTabIndex(targetIndex)

      const { href } = targetTab.props
      if (href && isExternalLink(href)) {
        return
      }

      setSelectedTab(targetTab.props.slug)
    },
    [tabs, currentTabIndex, setSelectedTab]
  )

  const resetTab = useCallback(
    (slug?: string) => {
      setCurrentTabIndex(findTabIndex(slug || selectedTab))
    },
    [findTabIndex, selectedTab]
  )

  return { tabContent, listRef, tabsRef, onKeyDown, resetTab }
}

export default useTabs
