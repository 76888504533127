import createPersistedState from 'use-persisted-state'
import { isClientSide } from '../isClientSide/isClientSide'
import { StorageKeys, StorageKeysEnum } from './storageKeys'

type UseStateStorageParams<T> = {
  key: StorageKeysEnum
  parser: (value: unknown) => T | undefined
}

export const clearClientStateStorage = () => {
  if (isClientSide()) {
    Object.values(StorageKeys).forEach((key) => {
      window.sessionStorage.removeItem(key)
    })
  }
}

const usePersistedState = (key: string) =>
  createPersistedState(key, isClientSide() ? window.sessionStorage : undefined)()

export const useStateStorage = <T>({ key, parser }: UseStateStorageParams<T>) => {
  const [state, setState] = usePersistedState(key)
  const setStorage = (data: unknown) => setState(data)
  const removeStorage = () => setState(undefined)

  const storage = parser(state)

  return { storage, setStorage, removeStorage } as const
}
