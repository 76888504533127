/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

const black = 'fg.primary'

export const CustomerReviewList = helpers.defineMultiStyleConfig({
  baseStyle: {
    contentWrapper: {
      color: black,
    },
    customerReviewsCtn: {
      rowGap: '2xl',
    },
    customerReviewCtn: {
      bgColor: 'bg.layer2.default',
      borderRadius: 'md',
      boxShadow: 'md',
    },
    title: {
      fontSize: 'var(--alto-sem-text-heading-xs-font-size)',
      lineHeight: 'var(--alto-sem-text-heading-xs-line-height)',
      color: 'fg.accent.secondary',
      fontWeight: 'semibold',
      mt: 'var(--alto-sem-space-sm)',
      mb: 'var(--alto-sem-space-sm)',
    },
    ratingsCtn: {
      span: {
        svg: {
          width: '16px',
          height: '16px',
        },
      },
      small: {
        fontWeight: 'normal',
        fontSize: {
          base: 'xs',
          md: 'sm',
        },
      },
    },
    icons: {
      color: black,
      fontSize: {
        base: 'xs',
        lg: 'sm',
      },
    },
    icon: {
      color: black,
    },
    reviewText: {
      fontSize: {
        base: 'sm',
        lg: 'sm',
      },
      color: 'fg.secondary',
    },
    showMoreButton: {
      fontSize: 'lg',
      fontWeight: 'normal',
    },
    readMoreButton: {
      fontSize: 'sm',
    },
  },
})
