import { createMultiStyleConfigHelpers, keyframes } from '@chakra-ui/react'

const fade = keyframes({
  '0%': { bottom: '-100px', opacity: '0', position: 'absolute' },
  '100%': { bottom: 'var(--chakra-space-3.5)', opacity: '1', position: 'sticky' },
})
const spaceBetween = 'space-between'

export const helpers = createMultiStyleConfigHelpers([
  'box',
  'flexWrapper',
  'subtotalWrapper',
  'subtotalTitle',
  'subtotalText',
  'carSizeWrapper',
  'carSize',
  'paymentButton',
  'paymentMethods',
  'iconWrapper',
])

export const CarPriceBar = helpers.defineMultiStyleConfig({
  baseStyle: {
    box: {
      bgColor: 'white',
      boxShadow: '0 var(--chakra-space-2) var(--chakra-space-8)',
      borderRadius: '2xl',
      w: 'calc(var(--chakra-sizes-full) - var(--chakra-space-8))',
      bottom: '3.5',
      margin: '0 var(--chakra-space-4)',
      position: 'sticky',
      animation: `${fade} 1s`,
      gridColumn: 'var(--layout-column-wide) !important',
      zIndex: 20,
    },
    flexWrapper: {
      p: '4',
      gap: { md: '2.5', lg: '4' },
      justifyContent: { md: spaceBetween },
      flexDirection: { base: 'column', md: 'row' },
      alignItems: { md: 'center' },
      maxWidth: 'container.xl',
      margin: 'auto',
    },
    subtotalWrapper: {
      flexDirection: { base: 'row', md: 'column', lg: 'row' },
      alignItems: { md: 'flex-start' },
      justifyContent: { base: spaceBetween, md: 'initial' },
      p: {
        base: 'var(--chakra-space-2) var(--chakra-space-5)',
        md: 'var(--chakra-space-2) var(--chakra-space-4)',
        lg: '8',
      },
      bgColor: 'grey',
      borderRadius: 'lg',
      alignSelf: { md: 'stretch', lg: 'initial' },
      mb: { base: '18px', md: 0 },
    },
    subtotalTitle: {
      fontSize: { base: 'xl', lg: '2xl' },
      fontWeight: 'semibold',
      color: 'black',
      whiteSpace: 'nowrap',
      lineHeight: '7',
      mb: { md: '3', lg: '1' },
      mr: { lg: '10' },
    },
    subtotalText: {
      fontSize: 'xs',
    },
    carSizeWrapper: {
      gap: { base: '2', lg: '2.5' },
      justifyContent: 'center',
      alignItems: 'center',
    },
    carSize: {
      fontStyle: 'normal',
      fontSize: { base: 'xs', lg: 'md' },
      fontWeight: { base: 'normal', lg: 'medium' },
      lineHeight: { md: 5, lg: 6 },
    },
    paymentButton: {
      padding: {
        base: 'var(--chakra-space-2.5) var(--chakra-space-6)',
        lg: 'var(--chakra-space-4) var(--chakra-space-8)',
      },
      width: { md: '3xs' },
      height: { md: 14 },
      fontSize: { base: 'sm', md: 'md' },
      maxWidth: { xl: '3xs' },
    },
    paymentMethods: {
      ml: { xl: 28 },
      mb: { base: 4, md: 'unset' },
    },
    iconWrapper: {
      width: 6,
      height: 6,
      background: 'black',
      padding: 1,
      borderRadius: '50%',
      color: 'white',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 2,
      fontSize: { base: 'sm', lg: 'md' },
      fontWeight: 'medium',
    },
  },
})
