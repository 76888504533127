import { Box, useMultiStyleConfig, BoxProps } from '@chakra-ui/react'

export type ChipProps = React.HTMLProps<HTMLDivElement> &
  BoxProps & {
    children: string | string[]
    variant: 'primary' | 'secondary'
  }

function Chip(props: ChipProps): JSX.Element {
  const { variant, children, ...other } = props
  const styles = useMultiStyleConfig('Chip', { variant })
  return (
    <Box __css={styles.chipWrapper} {...other}>
      {children}
    </Box>
  )
}

export default Chip
