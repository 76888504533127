import { ComponentStyleConfig } from '@chakra-ui/react'

const cloudWhite = 'brand.cloudWhite'

export const FlightJourneyHeader: ComponentStyleConfig = {
  baseStyle: {
    subheader: {
      h2: {
        fontWeight: 'normal',
        fontSize: { base: '1.75rem', lg: '2.5rem' },
      },
      h3: {
        fontWeight: 'normal',
        fontSize: 'lg',
        color: 'brand.black',
      },
    },
    chevronButton: {
      borderColor: 'brand.coolgrey2',
    },
    weekdayButton: {
      fontSize: {
        base: 'xs',
        lg: 'sm',
      },
      borderColor: 'brand.coolgrey2',
      bgColor: 'transparent',
      color: 'brand.primaryTeal',
      _active: {
        bgColor: cloudWhite,
      },
      _disabled: {
        color: 'brand.black',
      },
    },
    overlayTipContent: {
      '.chakra-icon': {
        color: 'brand.primaryShamrockGreen',
      },
    },
  },
}
