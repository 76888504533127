import { Box, BoxProps, useMultiStyleConfig } from '@chakra-ui/react'
import { useAlertContext } from './AlertProvider'

type Props = BoxProps

export const AlertDescription = (props: Props) => {
  const { status, variant } = useAlertContext()
  const styles = useMultiStyleConfig('Alert', { variant })

  return <Box data-status={status} __css={styles.description} {...props} />
}
