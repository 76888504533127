import { z } from 'zod'

const IataSchema = z.enum(['VY', 'EI', 'BA'])

export const HEADER_KEYS = {
  partnerCode: 'x-partner',
  platformType: 'x-platform-type',
  correlationId: 'x-correlation-id',
  authorization: 'authorization',
  referer: 'referer',
  forwardedHost: 'x-forwarded-host',
  countryCode: 'x-country-code',
  currencyCode: 'x-currency-code',
  host: 'host',
} as const

export type HeaderKey = keyof typeof HEADER_KEYS

export const RequestHeadersSchema = z.object({
  [HEADER_KEYS.authorization]: z
    .string()
    .regex(/^Bearer\W[A-Za-z0-9_-]{2,}(?:\.[A-Za-z0-9_-]{2,}){2}$/)
    .optional()
    .nullable(),
  [HEADER_KEYS.referer]: z.string().optional().nullable(),
  [HEADER_KEYS.correlationId]: z
    .string()
    .regex(/[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/)
    .optional()
    .nullable(),
  [HEADER_KEYS.partnerCode]: IataSchema.optional(),
  [HEADER_KEYS.platformType]: z.string().optional().default('whitelabel'),
  // TODO: validate using schemas from libs once all merged
  [HEADER_KEYS.currencyCode]: z.string().optional().nullable(),
  [HEADER_KEYS.countryCode]: z.string().optional().nullable(),
  [HEADER_KEYS.host]: z.string().optional().nullable(),
  [HEADER_KEYS.forwardedHost]: z.string().optional().nullable(),
})

export type RequestHeaders = z.infer<typeof RequestHeadersSchema>
