import { basketTravellersLabelHelper } from './base'

export const BasketTravellersLabel = basketTravellersLabelHelper.defineMultiStyleConfig({
  baseStyle: basketTravellersLabelHelper.definePartsStyle({
    totalCountContainer: {
      'div div section': {
        fontWeight: 'semibold',
      },
    },
    ageGroupsContainer: {
      '> span': {
        fontWeight: 'semibold',
        bg: 'brand.alpha.blue',
        borderRadius: 'sm',
      },
      button: {
        bg: 'brand.alpha.blue',
        color: 'brand.primary.midnightBlue',
        '> span': {
          fontWeight: 'semibold',
        },
      },
    },
  }),
})
