import { chakraDefinitionGroupHelper } from './base'

export const ChakraDefinitionGroup = chakraDefinitionGroupHelper.defineMultiStyleConfig({
  baseStyle: chakraDefinitionGroupHelper.definePartsStyle({
    chakraDefinitionGroupWrapper: {
      '>dl': {
        '>dt': {
          fontSize: 'sm',
        },
      },
    },
  }),
  variants: {
    accented: {
      chakraDefinitionGroupWrapper: {
        '>dl': {
          '>dd': {
            fontWeight: 'semibold',
          },
        },
      },
    },
  },
})
