import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const replaceHotelItemCardHelper = createMultiStyleConfigHelpers([
  'itemCardWrapper',
  'content',
  'cardImage',
  'cardBody',
  'main',
  'heading',
  'reservationPeriod',
  'icons',
  'details',
])

export const ReplaceHotelItemCard = replaceHotelItemCardHelper.defineMultiStyleConfig({
  baseStyle: replaceHotelItemCardHelper.definePartsStyle({
    cardBody: {
      display: 'flex',
      flexDir: 'column',
      flexGrow: 1,
      gap: 2,
      padding: 4,
    },
    icons: {
      fontSize: 'xs',
      pt: 2,
      display: 'flex',
      flexDirection: 'column',
      gap: '2',
    },
    heading: {
      lineHeight: 'normal',
      minHeight: 20,
      my: { base: 2, lg: 0 },
      display: 'flex',
      flexDir: 'column',
      flexGrow: 1,
    },
    details: {
      mb: 2,
    },
    reservationPeriod: {
      mt: 2,
      fontSize: 'sm',
    },
    content: {
      display: 'flex',
      flexDir: 'column',
      my: 2,
      flexGrow: 1,
    },
  }),
})
