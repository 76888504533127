import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'buttonContainer',
  'searchSummaryWrapper',
  'searchTitle',
  'searchSubTitle',
  'loadingState',
  'continueButton',
  'pageTitle',
  'primaryDriverWrapper',
  'location',
])

export const CarDetails = helpers.defineMultiStyleConfig({
  baseStyle: {
    buttonContainer: {
      justifyContent: 'flex-end',
      margin: { base: 'var(--chakra-space-8) 0 0 0', md: 'var(--chakra-space-10) 0 0 0' },
    },
    searchSummaryWrapper: {
      gridColumn: 'var(--layout-column-wide)',
      paddingY: 4,
      backgroundColor: 'brand.white',
      paddingInline: 'max(var(--layout-gutter), calc((100vw - var(--layout-width)) / 2))',
      boxShadow: '0 6px 9px 0 rgb(0 0 0 / 10%)',
    },
    searchTitle: {
      fontSize: 'lg',
      fontWeight: 'medium',
      marginTop: '0',
    },
    searchSubTitle: {
      fontSize: 'sm',
    },
    loadingState: {
      padding: 32,
      '> div': {
        height: 'inherit',
      },
    },
    continueButton: {
      width: { base: 'unset', sm: '230px' },
    },
    pageTitle: {
      mt: 0,
      mb: 5,
      fontSize: { base: '2xl', lg: '2xl' },
      fontWeight: 'semibold',
    },
    primaryDriverWrapper: {
      alignItems: 'center',
      mt: 3,
      h2: {
        fontWeight: 'medium',
        fontSize: {
          base: 'md',
          md: 'lg',
        },
      },
      p: {
        fontSize: {
          base: 'md',
          md: 'lg',
        },
        my: 0,
        ml: 2,
        lineHeight: 1,
      },
    },
    location: {
      fontWeight: 'medium',
      fontSize: {
        base: 'md',
        md: 'lg',
      },
      mt: 3,
    },
  },
})
