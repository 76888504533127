import { replaceItemModalHelper } from './base'

const cardBoxShadow = '10px 10px 5px 0 rgba(229, 239, 240, 1)'
const hoverState = {
  boxShadow: cardBoxShadow,
}

export const ReplaceItemModal = replaceItemModalHelper.defineMultiStyleConfig({
  baseStyle: replaceItemModalHelper.definePartsStyle({
    instructions: {
      p: {
        _first: {
          fontWeight: 'semibold',
        },
      },
    },
    promptText: {
      color: 'brand.secondaryTextColor',
      fontSize: 'sm',
    },
    optionLinkWrapper: {
      _hover: hoverState,
      _focusWithin: hoverState,
    },
  }),
})
