import { helpers } from './base'

export const HotelAdditionalDetails = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    hotelExtraInformationWrapper: {
      '> div': {
        '> button': {
          bg: 'brand.teal5',
          borderColor: 'rgba(var(--chakra-colors-rgb-alPrimaryTeal), 0.2)',
          span: {
            fontWeight: 'medium',
            color: 'brand.black',
          },
          svg: {
            color: 'brand.black',
          },
        },
      },
    },
    roomDetail: {
      bg: 'brand.coolgrey1',
      borderRadius: 'md',
      alignContent: 'center',
      span: {
        fontSize: 'xs',
      },
    },
  }),
})
