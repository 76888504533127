import { autoCompleteLocationOptionHelpers } from './base'

export const AutoCompleteLocationOption = autoCompleteLocationOptionHelpers.defineMultiStyleConfig({
  baseStyle: {
    locationOptionWrapper: {
      _hover: {
        backgroundColor: 'brand.coolgrey4',
      },
      '&[data-highlighted=true]': {
        backgroundColor: 'brand.coolgrey4',
      },
    },
    autoCompleteListTitle: {
      fontWeight: 'normal',
      color: 'unset',
    },
  },
})
