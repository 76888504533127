import { helpers } from './base'

export const SearchResultCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    card: {
      padding: 6,
      gap: 5,
    },
    title: {
      fontSize: '2xl',
    },
    form: {
      gap: 6,
    },
    details: {
      rowGap: 5,
    },
  },
})
