import { helpers } from './base'

export const HotelRooms = helpers.defineMultiStyleConfig({
  baseStyle: {
    header: {
      borderRadius: 'sm',
      boxShadow: 'brand.boxShadow',
      padding: 6,
      background: 'brand.white',
    },
    tripContainer: {
      paddingBottom: 0,
    },
    tripInfo: {
      alignItems: 'flex-start',
    },
    tripDetails: {
      color: 'brand.primary.midnightBlue',
    },
    heading: {
      fontSize: '1.375rem',
    },
    roomItem: {
      _first: {
        marginTop: 14,
      },
      '& + &': {
        marginTop: 10,
      },
    },
  },
})
