import { helpers } from './base'

export const RichText = helpers.defineMultiStyleConfig({
  baseStyle: {
    richText: {
      strong: {
        fontWeight: 'semibold',
      },
    },
    hyperlink: {
      color: 'fg.accent.primary',
      textDecoration: 'underline',
    },
  },
})
