import { ComponentStyleConfig } from '@chakra-ui/react'

export const Divider: ComponentStyleConfig = {
  baseStyle: {
    borderBottomWidth: '2px',
    borderColor: 'rgba(0,0,0,0.1)',
  },
  sizes: {
    sm: {
      margin: '0.5rem 0',
    },
    md: {
      margin: '1rem 0',
    },
    lg: {
      margin: '1.5rem 0',
    },
    xl: {
      margin: '2rem 0',
    },
  },
  variants: {},
  defaultProps: {
    size: 'md',
  },
}
