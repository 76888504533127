import { helpers } from './base'

export const ResultsMapView = helpers.defineMultiStyleConfig({
  baseStyle: {
    results: {
      backgroundColor: 'brand.cloudWhite',
    },

    resultItem: {
      '&[data-selected="true"]': {
        filter: { md: 'drop-shadow(0 8px 0 var(--chakra-colors-brand-cityGreen))' },
      },
    },
  },
})
