/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const RoomsBanner = helpers.defineMultiStyleConfig({
  baseStyle: {
    bedIcon: {
      color: '#2671D3',
    },
    confirmButton: {
      color: 'brand.primary.blue',
      borderColor: 'brand.primary.blue',
    },
    heading: {
      fontWeight: 400,
    },
    description: {
      fontWeight: 300,
    },
  },
  variants: {
    static: {
      container: {
        borderColor: 'brand.primary.blue',
        background: 'var(--chakra-colors-brand-baseGray-5)',
        gap: 4,
      },
    },
    floating: {
      container: {
        borderColor: 'brand.primary.blue',
        background: 'var(--chakra-colors-brand-baseGray-5)',
      },
    },
  },
})
