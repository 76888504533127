/* eslint-disable sonarjs/no-duplicate-string */
import { progressBarHelpers, progressHelpers } from './base'

export const Progress = progressHelpers.defineMultiStyleConfig({
  baseStyle: progressHelpers.definePartsStyle({
    track: {
      bg: 'brand.greyscale3',
    },
    filledTrack: {
      bgColor: 'brand.orange',
    },
  }),
})

export const ProgressBar = progressBarHelpers.defineMultiStyleConfig({
  baseStyle: progressBarHelpers.definePartsStyle({
    label: {
      color: 'brand.grey6',
    },
    valueLabel: {
      color: 'brand.grey6',
    },
  }),
})
