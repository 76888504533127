import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'container',
  'places',
  'firstItems',
  'list',
  'solidLine',
  'dashedLine',
  'listItem',
  'circleIcon',
  'location',
  'description',
  'iconContainer',
  'expandButton',
])

export const DayList = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      marginTop: '1.5rem',
      display: 'grid',
    },
    places: {
      gridArea: '1 / 1',
    },
    firstItems: {
      marginBottom: '1rem',
    },
    list: {
      marginTop: '1rem',
    },
    solidLine: {
      gridArea: '1 / 1',
      width: '2px',
      marginLeft: '1rem',
      marginTop: '1rem',
      height: 'calc(100% - 70px)',
    },
    dashedLine: {
      gridArea: '1 / 1',
      width: '1px',
      marginLeft: '0.5rem',
    },
    listItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      columnGap: '0.5rem',
      marginBottom: '1.5rem',
    },
    circleIcon: {
      minWidth: '38px',
      minHeight: '38px',
      width: '38px',
      height: '38px',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '-4px',
    },
    location: {
      fontSize: 'lg',
      lineHeight: 5,
      letterSpacing: '0.09px',
      fontWeight: 500,
    },
    description: {
      fontSize: 'lg',
      lineHeight: 3,
      letterSpacing: '0.09px',
      marginTop: 2,
      fontWeight: 400,
    },
    iconContainer: {
      width: '1.2rem',
      display: 'flex',
      mx: 2,
      ml: 1,
    },
    expandButton: {
      fontSize: 'lg',
      fontWeight: 'semibold',
      letterSpacing: '0.09px',
      lineHeight: 3,
      padding: 0,
      textDecoration: 'none',
    },
  },
})
