import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const AlertHelper = createMultiStyleConfigHelpers([
  'container',
  'title',
  'description',
  'icon',
  'button',
])

export const Alert = AlertHelper.defineMultiStyleConfig({
  baseStyle: AlertHelper.definePartsStyle({
    container: {
      display: 'grid',
      gridTemplateAreas: {
        base: `
      'icon title'
      'icon description'
      'icon button'`,
        sm: `
      'icon title button'
      'icon description button'
      `,
      },
      gridTemplateColumns: 'min-content 1fr min-content',
      borderRadius: 'lg',
      p: 4,
      '&[data-status="error"]': {
        border: '1px solid red',
        bgColor: 'pink',
      },
      '&[data-status="info"]': {
        border: '1px solid blue',
        bgColor: 'lightblue',
      },
      '&[data-status="success"]': {
        border: '1px solid green',
        bgColor: 'lightgreen',
      },
      '&[data-status="neutral"]': {
        border: '1px solid gray',
        bgColor: 'lightgray',
      },
      '&[data-status="warning"]': {
        border: '1px solid orange',
        bgColor: 'lightorange',
      },
    },
    title: {
      gridArea: 'title',
      fontWeight: 'semibold',
      fontSize: { base: 'md', sm: 'lg' },
      '&[data-status="error"]': {
        color: 'red',
      },
      '&[data-status="info"]': {
        color: 'blue',
      },
      '&[data-status="success"]': {
        color: 'green',
      },
      '&[data-status="neutral"]': {
        color: 'gray',
      },
      m: 0,
      mb: 1,
    },
    description: {
      gridArea: 'description',
      fontSize: 'sm',
    },
    icon: {
      gridArea: 'icon',
      '&[data-status="error"]': {
        color: 'red',
      },
      '&[data-status="info"]': {
        color: 'blue',
      },
      '&[data-status="success"]': {
        color: 'green',
      },
      '&[data-status="neutral"]': {
        color: 'gray',
      },
      m: 0,
      mr: 4,
      alignSelf: 'start',
    },
    button: {
      gridArea: 'button',
      w: 'min-content',
      justifySelf: 'center',
      alignSelf: 'center',
      mt: {
        base: 3,
        sm: 0,
      },
      ml: { base: 0, sm: 4 },
      '> button': {
        fontWeight: 'semibold',
        fontSize: 'lg',
        px: 4,
        h: 'fit-content',
        bgColor: 'inherit',
      },
    },

    closeButton: {
      gridArea: 'button',
      w: 'min-content',
      justifySelf: 'center',
      alignSelf: 'center',
      mt: {
        base: 3,
        sm: 0,
      },
      ml: { base: 0, sm: 4 },
      '> button': {
        fontWeight: 'semibold',
        fontSize: 'lg',
        px: 0,
        h: 'fit-content',
        bgColor: 'inherit',
        border: 'none',
        '&:hover': {
          boxShadow: 'none',
          bg: 'none',
          border: 'none',
        },
        '&:active': {
          boxShadow: 'none',
          bg: 'none',
          border: 'none',
          color: 'inherit',
        },
        '&:focus': {
          boxShadow: 'none',
          bg: 'none',
          border: 'none',
        },
      },
    },
  }),
  variants: {
    borderless: AlertHelper.definePartsStyle({
      container: {
        '&[data-status]': {
          border: 'none',
        },
      },
    }),
    minimal: AlertHelper.definePartsStyle({
      container: {
        '&[data-status]': {
          border: 'none',
          bgColor: 'inherit',
        },
      },
    }),
    thin: AlertHelper.definePartsStyle({
      container: {
        '&[data-status]': {
          py: 1.5,
          px: 2,
        },
      },
      description: {
        lineHeight: 4,
      },
      icon: {
        mr: 2,
      },
    }),
    promo: AlertHelper.definePartsStyle({
      gridTemplateAreas: `
      'icon title button'
      'icon description button'
      `,
      container: {
        '&[data-status]': {
          py: 4,
          px: 4,
          '> button': {
            gridArea: 'button',
            alignSelf: 'flex-start',
            height: 'auto',
            _hover: {
              boxShadow: 'none',
            },
            '> svg': {
              color: 'brand.black',
              margin: 0,
            },
          },
        },
        '> div': {
          lineHeight: 5,
          fontWeight: 'light',
          '> b': {
            fontWeight: 'normal',
            display: 'block',
            paddingBottom: 2,
            fontSize: 'md',
          },
        },
      },
    }),
    upgrade: AlertHelper.definePartsStyle({
      container: {
        gridTemplateAreas: `
      'icon title button'
      'icon description button'
      `,

        '&[data-status]': {
          py: 4,
          px: 4,

          '> button': {
            gridArea: 'button',
            alignSelf: 'flex-start',
            height: 'auto',

            _hover: {
              boxShadow: 'none',
            },

            '> svg': {
              color: 'brand.black',
              margin: 0,
            },
          },
        },
        '> div': {
          lineHeight: '20px',
          fontWeight: '300',

          '> b': {
            fontWeight: '400',
            display: 'block',
            paddingBottom: 2,
            fontSize: 'md',
          },
        },
      },
    }),
  },
})
