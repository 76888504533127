import { helpers } from './base'

export const LocationDetailsModalWrapper = helpers.defineMultiStyleConfig({
  baseStyle: {
    locationDetails: {
      h1: {
        fontSize: { base: '2xl', md: '4xl' },
      },
      h2: {
        fontSize: { base: 'lg', md: '2xl' },
        fontWeight: 'normal',
      },
      h3: {
        fontSize: { base: 'md', md: 'xl' },
        fontWeight: 'normal',
      },
    },
  },
})
