import { alWhite } from '@reward-platform/lift/theme/aer_lingus/variables'
import { BoundDetailsHelper } from './base'

export const BoundDetails = BoundDetailsHelper.defineMultiStyleConfig({
  baseStyle: {
    flightsIconAndText: {
      borderRightColor: alWhite,
    },
  },
})
