/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

const primary = 'var(--alto-sem-color-fg-accent-primary)'

export const DayList = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      color: 'fg.primary',
    },
    solidLine: {
      backgroundColor: 'bg.accent.vibrant.default',
    },
    dashedLine: {
      backgroundImage: `repeating-linear-gradient(0deg, transparent 0 4px, ${primary} 4px 12px)`,
    },
    circleIcon: {
      backgroundColor: 'bg.accent.vibrant.default',
      color: 'fg.accent.onVibrant',
    },
    location: {
      color: 'fg.secondary',
    },
    description: {
      color: 'fg.secondary',
    },
    expandButton: {
      color: primary,
      _focus: {
        border: 'none',
      },
    },
    iconContainer: {
      color: primary,
    },
  },
})
