/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const BasketItemSummary = helpers.defineMultiStyleConfig({
  baseStyle: {
    basketItemSummaryWrapper: {
      bg: 'bg.accent.subtle.default',
      color: 'fg.accent.secondary',
      borderRadius: '2xs',
      '> p': {
        fontWeight: 'semibold',
        fontSize: 'sm',
      },
    },
    content: {
      fontSize: 'xs',
      mt: '5xs',
    },
    heading: {
      svg: {
        color: 'bg.success.vibrant.default',
      },
    },
  },
})
