import { helpers } from './base'

export const ExperienceInformation = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      color: 'brand.black',
    },
    description: {
      color: 'brand.grey6',
    },
  },
})
