import { flightBasketItemDetailsHelper } from './base'

const alPrimaryTealAlpha20 = 'rgba(0, 98, 114, 0.20)'

export const FlightBasketItemDetails = flightBasketItemDetailsHelper.defineMultiStyleConfig({
  baseStyle: flightBasketItemDetailsHelper.definePartsStyle({
    header: {
      color: 'brand.primaryTeal',
    },
    flightInfoTable: {
      borderColor: alPrimaryTealAlpha20,
      overflow: 'overlay',
    },
    flightInfoTableHeader: {
      bg: 'brand.teal5',
      borderBottom: { base: 'none', lg: `1px solid ${alPrimaryTealAlpha20}` },
      borderRight: { base: `1px solid ${alPrimaryTealAlpha20}`, lg: 'none' },
      fontSize: { base: 'md', md: 'lg' },
    },
    flightInfoTableContent: {
      fontSize: { base: 'sm', md: 'md' },
      _last: {
        fontWeight: 'normal',
      },
    },
  }),
})
