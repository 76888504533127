/* eslint-disable sonarjs/no-duplicate-string */
import { itemCardImageHelper } from './base'

export const ItemCardImage = itemCardImageHelper.defineMultiStyleConfig({
  baseStyle: {
    imageWrapper: {
      borderRadius: 'xs',
      img: {
        borderRadius: 'xs',
      },
    },
  },
})
