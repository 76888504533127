import { priceBreakdownSummaryModalButtonHelpers } from './base'

export const PriceBreakdownSummaryModalButton =
  priceBreakdownSummaryModalButtonHelpers.defineMultiStyleConfig({
    baseStyle: priceBreakdownSummaryModalButtonHelpers.definePartsStyle({
      surchargesTextModalButton: {
        fontSize: 'xs',
      },
    }),
  })
