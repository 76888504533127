import { ChakraTheme, ComponentStyleConfig, extendTheme } from '@chakra-ui/react'
import { AutoCompleteLocationOption } from '@reward-platform/lift/components/AutoComplete/themes/aerLingus'
import { Card } from '@reward-platform/lift/components/Card/themes/aerLingus'
import { Carousel } from '@reward-platform/lift/components/Carousel/themes/aerLingus'
import { Accordion } from '@reward-platform/lift/components/ChakraAccordion/themes/aerLingus'
import { Alert } from '@reward-platform/lift/components/Alert/themes/aerLingus'
import { Button } from '@reward-platform/lift/components/ChakraButton/themes/aerLingus'
import { ChakraCollapsibleButton } from '@reward-platform/lift/components/ChakraCollapsibleButton/themes/aerLingus'
import { Drawer } from '@reward-platform/lift/components/ChakraDrawer/themes/aerLingus'
import { Form } from '@reward-platform/lift/components/ChakraField/themes/aerLingus'
import { ListBox } from '@reward-platform/lift/components/ChakraListBox/themes/aerLingus'
import { ChakraPopover } from '@reward-platform/lift/components/ChakraPopover/themes/aerLingus'
import { RichText } from '@reward-platform/lift/components/ChakraRichText/themes/aerLingus'
import { Switch } from '@reward-platform/lift/components/ChakraSwitch/themes/aerLingus'
import { Checkbox } from '@reward-platform/lift/components/Checkbox/themes/aerLingus'
import { Chip } from '@reward-platform/lift/components/Chip/themes/aerLingus'
import { Collapsible } from '@reward-platform/lift/components/Collapsible/themes/aerLingus'
import { CrossSellingCard } from '@reward-platform/lift/components/CrossSellingCard/themes/aerLingus'
import {
  DatePicker,
  DatePickerGlobalTheme,
} from '@reward-platform/lift/components/DatePicker/themes/aerLingus'
import { Divider } from '@reward-platform/lift/components/Divider/themes/aerLingus'
import { FieldGroup } from '@reward-platform/lift/components/FieldGroup/themes/aerLingus'
import { Fieldset } from '@reward-platform/lift/components/Fieldset/themes/aerLingus'
import { ImageCard } from '@reward-platform/lift/components/ImageCard/themes/aerLingus'
import { InfoMessage } from '@reward-platform/lift/components/InfoMessage/themes/aerLingus'
import { List } from '@reward-platform/lift/components/List/themes/aerLingus'
import { MaintenanceMode } from '@reward-platform/lift/components/MaintenanceMode/themes/aerLingus'
import { TextMarker } from '@reward-platform/lift/components/Map/themes/aerLingus'
import { Modal } from '@reward-platform/lift/components/Modal/themes/aerLingus'
import { NotificationMessage } from '@reward-platform/lift/components/NotificationMessage/themes/aerLingus'
import { OverlayTip } from '@reward-platform/lift/components/OverlayTip/themes/aerLingus'
import { Pagination } from '@reward-platform/lift/components/Pagination/themes/aerLingus'
import { QuantityPicker } from '@reward-platform/lift/components/QuantityPicker/themes/aerLingus'
import { RadioButton } from '@reward-platform/lift/components/RadioButton/themes/aerLingus'
import { Ratings } from '@reward-platform/lift/components/Ratings/themes/aerLingus'
import { SelectBox } from '@reward-platform/lift/components/SelectBox/themes/aerLingus'
import { Spinner } from '@reward-platform/lift/components/Spinner/themes/aerLingus'
import { StepperCircle } from '@reward-platform/lift/components/Stepper/StepperCircle/themes/aerLingus'
import { Stepper } from '@reward-platform/lift/components/Stepper/themes/aerLingus'
import { Tabs } from '@reward-platform/lift/components/Tabs/themes/aerLingus'
import { TelevisionButton } from '@reward-platform/lift/components/TelevisionButton/themes/aerLingus'
import { Tooltip } from '@reward-platform/lift/components/Tooltip/themes/aerLingus'
import { CancelOrderConfirmationWrapper } from '~/components/order-history/CancelOrderConfirmModal/themes/aerLingus'
import { WarningMessage } from '@reward-platform/lift/components/WarningMessage/themes/aerLingus'
import { typography } from '@reward-platform/lift/theme/aer_lingus/typography'
import { alBoxShadow } from '@reward-platform/lift/theme/aer_lingus/variables'
import { Combobox } from '@reward-platform/lift/components/ComboBox/themes/aerLingus'
import { RangeDatePicker } from '@reward-platform/lift/components/RangeDatePicker/themes/aerLingus'
import { ActivityMenu } from '~/components/experiences/details/ActivityMenu/themes/aerLingus'
import { CustomerReviewList } from '~/components/experiences/details/CustomerReviewList/themes/aerLingus'
import { DayList } from '~/components/experiences/details/DayList/themes/aerLingus'
import { PointsOfInterest } from '~/components/experiences/details/PointsOfInterestList/themes/aerLingus'
import { RouteInfo } from '~/components/experiences/details/RouteInfo/themes/aerLingus'
import { ItemCardImage } from '~/components/ItemCardImage/themes/aerLingus'
import { ItemCardImageWithLogo } from '~/components/ItemCardImageWithLogo/themes/aerLingus'
import { StatusBadge } from '~/components/order-history/StatusBadge/themes/aerLingus'
import { AncillaryBasketItemDetails } from '~/components/orders/AncillaryBasketItemDetails/themes/aerLingus'
import { CarBasketItemCard } from '~/components/orders/BasketItemCard/CarBasketItemCard/themes/aerLingus'
import { ExperienceBasketItemCard } from '~/components/orders/BasketItemCard/ExperienceBasketItemCard/themes/aerLingus'
import { FlightBasketItemCard } from '~/components/orders/BasketItemCard/FlightBasketItemCard/themes/aerLingus'
import { HotelBasketItemCard } from '~/components/orders/BasketItemCard/HotelBasketItemCard/themes/aerLingus'
import { BasketItemCard } from '~/components/orders/BasketItemCard/themes/aerLingus'
import { BasketItemPaymentOptionsSelect } from '~/components/orders/BasketItemPaymentOptionsSelect/themes/aerLingus'
import { BasketItemSummary } from '~/components/orders/BasketItemSummary/themes/aerLingus'
import { BookingStatus } from '~/components/orders/BookingStatus/themes/aerLingus'
import { Itinerary } from '~/components/orders/Itinerary/themes/aerLingus'
import { AcceptedCards } from '~/components/orders/PaymentForm/AcceptedCards/themes/aerLingus'
import { PaymentFormContainer } from '~/components/orders/PaymentForm/themes/aerlingus'
import { PriceBreakdownSticky } from '~/components/orders/PriceBreakdown/PriceBreakdownSticky/themes/aerLingus'
import { PriceBreakdownSummary } from '~/components/orders/PriceBreakdown/PriceBreakdownSummary/themes/aerLingus'
import { PriceBreakdownSummaryModalButton } from '~/components/orders/PriceBreakdown/PriceBreakdownSummaryModalButton/themes/aerLingus'
import { PriceBreakdownTotalDue } from '~/components/orders/PriceBreakdown/PriceBreakdownTotalDue/theme/aerLingus'
import { PriceBreakdown } from '~/components/orders/PriceBreakdown/themes/aerLingus'
import { PriceBreakdownCollapsibleContent } from '~/components/orders/shared/PriceBreakdownCollapsibleContent/themes/aerLingus'
import { PriceBreakdownCollectedAviosAmount } from '~/components/orders/shared/PriceBreakdownCollectedAviosAmount/themes/aerLingus'
import { PriceBreakdownRowItem } from '~/components/orders/shared/PriceBreakdownRowItem/theme/aerLingus'
import { TravelerInformationForm } from '~/components/orders/TravelerInformationForm/themes/base'
import { Banner } from '~/components/search-and-results/Banner/themes/aerLingus'
import { CarCard } from '~/components/search-and-results/CarCard/themes/aerLingus'
import { CarPriceBar } from '~/components/search-and-results/CarPriceBar/themes/aerLingus'
import { DetailsPageNav } from '~/components/search-and-results/DetailsPageNav/themes/aerLingus'
import { FlightJourneyHeader } from '~/components/search-and-results/FlightJourneyHeader/themes/aerLingus'
import { FlightTravellerInput } from '~/components/search-and-results/FlightTravellerInput/themes/aerLingus'
import { HotelCard } from '~/components/search-and-results/HotelCard/themes/aerLingus'
import { HotelNearbyAttractions } from '~/components/search-and-results/HotelNearbyAttractions/themes/aerLingus'
import { HotelReviewBreakdown } from '~/components/search-and-results/HotelReviewBreakdown/themes/aerLingus'
import { HotelSummary } from '~/components/search-and-results/HotelSummary/themes/aerLingus'
import { IntroSection } from '~/components/search-and-results/IntroSection/themes/aerLingus'
import { LocationDetailsModalWrapper } from '~/components/search-and-results/LocationDetailsModal/themes/aerLingus'
import { NoResults } from '~/components/search-and-results/NoResults/themes/aerLingus'
import { Pricing } from '~/components/search-and-results/Pricing/themes/aerLingus'
import { ReplaceCarItemCard } from '~/components/search-and-results/ReplaceCarItemCard/themes/aerLingus'
import { ReplaceExperienceItemCard } from '~/components/search-and-results/ReplaceExperienceItemCard/themes/aerLingus'
import { ReplaceHotelItemCard } from '~/components/search-and-results/ReplaceHotelItemCard/themes/aerLingus'
import { ReplaceItemModal } from '~/components/search-and-results/ReplaceItemModal/themes/aerLingus'
import { ResultsMapView } from '~/components/search-and-results/ResultsMapView/themes/aerLingus'
import { RoomMoreDetailsModal } from '~/components/search-and-results/RoomMoreDetailsModal/themes/aerLingus'
import { RoomRateSelector } from '~/components/search-and-results/RoomRateSelector/themes/aerLingus'
import { SearchExperienceForm } from '~/components/search-and-results/SearchExperienceForm/themes/aerLingus'
import { SearchFlightForm } from '~/components/search-and-results/SearchFlightForm/themes/aerLingus'
import { EditSearchResultsForm } from '~/components/search-and-results/SearchHotelForm/EditSearchResultsForm/themes/aerLingus'
import { SearchHotelForm } from '~/components/search-and-results/SearchHotelForm/themes/aerLingus'
import { ChildrenAgePicker } from '~/components/shared/ChildrenAgePicker/themes/aerLingus'
import { TravelerRoomPicker } from '~/components/search-and-results/SearchHotelForm/TravelerRoomPicker/themes/aerLingus'
import { TravelerRoom } from '~/components/search-and-results/SearchHotelForm/TravelerRoomPicker/TravelerRoom/themes/aerLingus'
import { AmenitiesList } from '~/components/shared/AmenitiesList/themes/aerLingus'
import { BackTo } from '~/components/shared/BackTo/themes/aerLingus'
import { CollapsibleForm } from '~/components/shared/CollapsibleForm/themes/aerLingus'
import { Filters } from '~/components/shared/Filters/themes/aerLingus'
import { Footer } from '~/components/shared/Footer/themes/aerLingus'
import { GuestRatings } from '~/components/shared/GuestRatings/themes/aerLingus'
import { Header } from '~/components/shared/Header/themes/aerlingus'
import { Menu } from '~/components/shared/HeaderMenu/themes/aerLingus'
import { Hero } from '~/components/shared/Hero/themes/aerLingus'
import { PaymentOptionsContainer } from '~/components/shared/PaymentOptionsContainer/themes/aerLingus'
import { Progress, ProgressBar } from '~/components/shared/ProgressBar/themes/aerLingus'
import { SectionNavigation } from '~/components/shared/SectionNavigation/themes/aerLingus'
import { SpinnerOnPage } from '~/components/shared/SpinnerOnPage/themes/aerLingus'
import { TravelerQuantityPickerComboBox } from '~/components/shared/TravelerQuantityPickerComboBox/themes/aerLingus'
import { CarDetails } from '~/components/cars/details/themes/aerLingus'
import { HotelDetails } from '~/components/hotels/details/themes/aerLingus'
import { ExperienceDetails } from '~/components/experiences/details/details/themes/aerLingus'
import { CancelOrderModal } from '~/components/order-history/CancelOrderModal/themes/aerLingus'
import { CancelOrderPaymentForm } from '~/components/order-history/CancelOrderPaymentForm/themes/aerLingus'
import { CarAdditionalProductCard } from '~/components/search-and-results/CarAdditionalProductCard/themes/aerLingus'
import { FlightCard } from '~/components/search-and-results/FlightCard/themes/aerLingus'
import { FlightCardBanner } from '~/components/search-and-results/FlightCardBanner/themes/aerLingus'
import { PriceInfo } from '~/components/search-and-results/FlightCard/PriceInfo/themes/aerLingus'
import { ContinueButton } from '~/components/search-and-results/ContinueButton/themes/aerLingus'
import { ShowHideButton } from '~/components/search-and-results/ShowHideButton/themes/aerLingus'
import { FlightsResults } from '~/components/search-and-results/FlightResults/themes/aerLingus'
import { FlightPriceFooter } from '~/components/search-and-results/FlightPriceFooter/themes/aerLingus'
import { FlightPaymentOptions } from '~/components/search-and-results/FlightPaymentOptions/themes/aerLingus'
import { HotelRooms } from '~/components/search-and-results/HotelRooms/themes/aerLingus'
import { PopularAmenitiesList } from '~/components/search-and-results/PopularAmenities/themes/aerLingus'
import { ResultsPageTemplate } from '~/components/search-and-results/ResultsPageTemplate/themes/aerLingus'
import { RoomDetailsCard } from '~/components/search-and-results/RoomDetailsCard/themes/aerLingus'
import { StaticMap } from '~/components/search-and-results/StaticMap/themes/aerLingus'
import { Layout } from '~/components/shared/Layout/themes/aerLingus'
import { PageTitle } from '~/components/shared/PageTitle/themes/aerLingus'
import { SpinnerOverlay } from '~/components/shared/SpinnerOverlay/themes/aerLingus'
import { FlightBasketItemDetails } from '~/components/orders/FlightBasketItemDetails/themes/aerLingus'
import { PageContainer } from '~/components/shared/PageContainer/themes/aerLingus'
import { ExperienceInformation } from '~/components/experiences/details/ExperienceInformation/themes/aerLingus'
import { Label } from '~/components/search-and-results/Label/themes/aerLingus'
import { SearchResultCard } from '~/components/experiences/details/SearchResultCard/themes/aerLingus'
import { FeaturesList } from '~/components/experiences/details/FeaturesList/themes/aerLingus'
import { ExperienceTitle } from '~/components/experiences/details/ExperienceTitle/themes/aerLingus'
import { PricingAndSearchCard } from '~/components/experiences/details/PricingAndSearchCard/themes/aerLingus'
import { TicketOptions } from '~/components/experiences/details/TicketOptions/themes/aerLingus'
import { FlightItineraryProp } from '~/components/orders/Itinerary/FlightItineraryProp/themes/aerLingus'
import { FlightServiceItineraryProp } from '~/components/orders/Itinerary/FlightServiceItineraryProp/themes/aerLingus'
import { CarItemExtrasPopover } from '~/components/shared/CarItemExtrasPopover/themes/aerLingus'
import { FlightEditSearch } from '~/components/search-and-results/FlightEditSearch/themes/aerLingus'
import { BoundDetails } from '~/components/search-and-results/FlightPriceFooter/BoundDetails/themes/aerLingus'
import { Badge } from '~/components/search-and-results/Badge/themes/aerLingus'
import { SearchFieldSwapButton } from '~/components/search-and-results/SearchFieldSwapButton/themes/aerLingus'
import { MeetingPoints } from '~/components/experiences/details/MeetingPoints/themes/aerLingus'
import { ExternalLink } from '~/components/shared/ExternalLink/themes/aerlingus'
import { FlightBreakdown } from '~/components/search-and-results/FlightPriceFooter/FlightBreakdown/themes/aerLingus'
import { TravelerQuantityPicker } from '~/components/shared/TravelerQuantityPicker/theme/aerLingus'
import { FlightDetailsModal } from '~/components/search-and-results/FlightDetailsModal/themes/aerLingus'
import { FlightSelect } from '~/components/search-and-results/FlightSelect/themes/aerLingus'
import { Snackbar } from '@reward-platform/lift/components/Snackbar/themes/aerLingus'
import { RoomsBanner } from '~/components/search-and-results/RoomsBanner/themes/aerLingus'
import { BasketTravellersLabel } from '~/components/orders/BasketItemCard/BasketTravellersLabel/themes/aerLingus'
import { IconValuePair } from '~/components/shared/IconValuePair/theme/aerLingus'
import { HotelAdditionalDetails } from '~/components/orders/AdditionalDetailsForm/HotelAdditionalDetails/themes/aerLingus'
import { WhatToExpect } from '~/components/experiences/details/WhatToExpect/themes/aerLingus'
import { AtolEligible } from '~/components/orders/AtolEligible/themes/aerLingus'
import { ShareButton } from '~/components/shared/ShareButton/themes/aerLingus'
import { StickyBanner } from '@reward-platform/lift/components/StickyBanner/themes/aerLingus'
import { OrderListTable } from '~/components/order-history/OrderListTable/themes/aerLingus'
import { CarHireAdditionalDetails } from '~/components/orders/AdditionalDetailsForm/AccountHolderContactDetails/CarHireAdditionalDetails/themes/aerLingus'
import { ContentBadge } from '@reward-platform/lift/components/ContentBadge/themes/aerLingus'
import { DismissibleModal } from '@reward-platform/lift/components/DismissibleModal/themes/aerLingus'
import { RecentSearches } from '~/components/search-and-results/RecentSearches/themes/aerLingus'
import { PopularDestinations } from '~/components/search-and-results/PopularDestinations/themes/aerLingus'
import { CardBanner } from '@reward-platform/lift/components/CardBanner/themes/aerLingus'
import { Dropdown } from '~/components/shared/Dropdown/themes/aerLingus'
import { UpgradeBasketItem } from '~/components/orders/UpgradeBasketItem/themes/aerLingus'
import { ChatWidgetMock } from '~/components/shared/ChatWidgetMock/themes/aerLingus'
import { FlightAviosOnlyBanner } from '~/components/search-and-results/FlightAviosOnlyBanner/themes/aerLingus'
import { AddressLookupOption } from '~/components/AddressForm/themes/aerLingus'
import { PriceBreakdownContainer } from '~/components/orders/PaymentForm/PaymentOrderPriceBreakdown/themes/aerLingus'
import { baseTheme } from './base'

const primaryTeal = 'rgb(var(--chakra-colors-rgb-alPrimaryTeal))'
const outlineColor = primaryTeal

const focusOutline = {
  outlineColor,
  outlineWidth: 1,
  borderRadius: 'lg',
  outlineStyle: 'solid',
}

const Link: ComponentStyleConfig = {
  baseStyle: {
    _focusVisible: {
      boxShadow: 'none',
      ...focusOutline,
    },
  },
}

export const aerLingusTheme = extendTheme(
  {
    styles: {
      global: {
        'html, body, div#__next': {
          color: 'brand.black',
        },
        body: {
          input: {
            _placeholder: {
              color: 'brand.coolgrey10',
            },
          },
        },
        '*:focus-visible': {
          ...focusOutline,
        },
        a: {
          color: 'brand.primaryTeal',
        },
        ...DatePickerGlobalTheme,
      },
    },
    shadows: {
      brand: alBoxShadow,
      floating: '0rem 0.5rem 2rem 0rem rgba(0, 0, 0, 0.29)',
      light: '0rem 0.125rem 0.25rem 0rem rgba(0, 0, 0, 0.08)',
    },
    fonts: {
      heading: typography.families.heading,
      body: typography.families.body,
    },
    fontSizes: {
      '2xl-5': '1.375rem',
      '4xl-5': '2.5rem',
    },
    space: {
      '4-5': '1.125rem',
    },
    colors: {
      rgb: {
        alPrimaryTeal: '0, 98, 114',
        alTealDarker: '0, 78, 91',
        alTeal10: '229, 239, 240',
        alCloudWhite: '249, 249, 249',
        alBlack: '44, 42, 41',
        alRed: '197, 80, 80',
        alWhite: '	255, 255, 255',
        alCoastalJetty: '0, 110, 128',
        alCoolGrey4: '187, 188, 188',
        alCoolGrey10: '99, 102, 106',
        alCoolGrey2: '208, 208, 206',
        alBackgroundGrey: '247, 247, 247',
        alTeal: '0, 120, 140',
        alPrimaryShamrockGreen: '132, 189, 0',
        alCityGreen: '18, 173, 158',
        alCityGreen5: '231, 247, 245',
        alDarkCityGreen: '0, 133, 120',
        alSkyBlue: '0, 149, 203',
        alExtraLegroom: '77, 208, 225',
        alSeaBlue: '0, 103, 185',
        alOrange: '231, 114, 0',
        alCoolGrey1: '241, 241, 241',
        alCoolGrey8: '136, 139, 141',
        alTeal5: '242, 247, 248',
        alOrange5: '254, 248, 242',
        alOrange30: '248, 209, 178',
        alRed5: '252, 246, 246',
        alShamrock5: '249, 251, 242',
        alSkyBlue5: '242, 249, 252',
        alBGTeal10: '229, 239, 240',
        alYellow: '242, 204, 6',
        alTeal15: '0, 76, 89',
        alHighlightOrange: '231, 114, 0',
      },
      brand: {
        white: 'rgb(var(--chakra-colors-rgb-alWhite))',
        primaryTeal,
        primaryShamrockGreen: 'rgb(var(--chakra-colors-rgb-alPrimaryShamrockGreen))',
        shamrockGreen10: 'rgba(var(--chakra-colors-rgb-alPrimaryShamrockGreen), 0.1)',
        coolgrey1: 'rgb(var(--chakra-colors-rgb-alCoolGrey1))',
        coolgrey2: 'rgb(var(--chakra-colors-rgb-alCoolGrey2))',
        coolgrey4: 'rgb(var(--chakra-colors-rgb-alCoolGrey4))',
        coolgrey8: 'rgb(var(--chakra-colors-rgb-alCoolGrey8)) ',
        coolgrey10: 'rgb(var(--chakra-colors-rgb-alCoolGrey10))',
        backgroundGrey: 'rgb(var(--chakra-colors-rgb-alBackgroundGrey))',
        coastalJetty: 'rgb(var(--chakra-colors-rgb-alCoastalJetty))',
        black: 'rgb(var(--chakra-colors-rgb-alBlack))',
        teal: 'rgb(var(--chakra-colors-rgb-alTeal))',
        tealDarker: 'rgb(var(--chakra-colors-rgb-alTealDarker))',
        teal5: 'rgb(var(--chakra-colors-rgb-alTeal5))',
        teal15: 'rgb(var(--chakra-colors-rgb-alTeal15))',
        red: 'rgb(var(--chakra-colors-rgb-alRed))',
        red5: 'rgb(var(--chakra-colors-rgb-alRed5))',
        teal10: 'rgb(var(--chakra-colors-rgb-alTeal10))',
        cloudWhite: 'rgb(var(--chakra-colors-rgb-alCloudWhite))',
        cityGreen: 'rgb(var(--chakra-colors-rgb-alCityGreen))',
        cityGreen5: 'rgb(var(--chakra-colors-rgb-alCityGreen5))',
        darkCityGreen: 'rgb(var(--chakra-colors-rgb-alDarkCityGreen))',
        shamrock5: 'rgb(var(--chakra-colors-rgb-alShamrock5))',
        orange: 'rgb(var(--chakra-colors-rgb-alOrange))',
        orange5: 'rgb(var(--chakra-colors-rgb-alOrange5))',
        orange30: 'rgb(var(--chakra-colors-rgb-alOrange30))',
        bgteal10: 'rgb(var(--chakra-colors-rgb-alBGTeal10))',
        icon: 'rgb(var(--chakra-colors-rgb-alBlack))',
        inputIcon: 'rgb(var(--chakra-colors-rgb-alPrimaryTeal))',
        disabledIcon: 'rgb(var(--chakra-colors-rgb-alCoolGrey4))',
        highlightOrange: 'rgb(var(--chakra-colors-rgb-alHighlightOrange))',
        focusOutline: outlineColor,
      },
      error: {
        primary: 'rgb(var(--chakra-colors-rgb-alRed))',
      },
    },
    components: {
      Link,
      BasketItemCard,
      AncillaryBasketItemDetails,
      Button,
      Card,
      Form,
      ListBox,
      StatusBadge,
      OrderListTable,
      Drawer,
      PriceBreakdownSticky,
      PriceBreakdownSummaryModalButton,
      PriceBreakdownCollectedAviosAmount,
      PriceInfo,
      Itinerary,
      BasketItemPaymentOptionsSelect,
      BasketItemSummary,
      LocationDetailsModalWrapper,
      PriceBreakdown,
      PriceBreakdownTotalDue,
      PriceBreakdownSummary,
      BookingStatus,
      PriceBreakdownRowItem,
      PointsOfInterest,
      DayList,
      Stepper,
      StepperCircle,
      Pagination,
      ChakraPopover,
      Accordion,
      RouteInfo,
      CarBasketItemCard,
      PriceBreakdownCollapsibleContent,
      ChakraCollapsibleButton,
      Chip,
      ActivityMenu,
      List,
      ImageCard,
      DatePicker,
      Checkbox,
      RichText,
      Menu,
      PaymentOptionsContainer,
      Alert,
      FieldGroup,
      Footer,
      IntroSection,
      TelevisionButton,
      Divider,
      Hero,
      CancelOrderConfirmationWrapper,
      FlightsResults,
      Spinner,
      SpinnerOverlay,
      SpinnerOnPage,
      RadioButton,
      Tooltip,
      CrossSellingCard,
      Switch,
      ProgressBar,
      Progress,
      AmenitiesList,
      CarPriceBar,
      NoResults,
      HotelNearbyAttractions,
      SectionNavigation,
      ReplaceExperienceItemCard,
      MaintenanceMode,
      NotificationMessage,
      InfoMessage,
      Header,
      Carousel,
      HotelSummary,
      GuestRatings,
      QuantityPicker,
      BackTo,
      Pricing,
      Filters,
      Ratings,
      HotelCard,
      CardBanner,
      WarningMessage,
      ChildrenAgePicker,
      Tabs,
      HotelBasketItemCard,
      ItemCardImage,
      ExperienceBasketItemCard,
      Modal,
      Collapsible,
      TravelerRoomPicker,
      TravelerRoom,
      TextMarker,
      ReplaceItemModal,
      ReplaceHotelItemCard,
      ReplaceCarItemCard,
      HotelReviewBreakdown,
      CollapsibleForm,
      ItemCardImageWithLogo,
      Banner,
      CustomerReviewList,
      FlightJourneyHeader,
      DetailsPageNav,
      FlightCard,
      FlightCardBanner,
      CarCard,
      Fieldset,
      RoomMoreDetailsModal,
      AcceptedCards,
      PaymentFormContainer,
      SelectBox,
      RoomRateSelector,
      FlightTravellerInput,
      SearchHotelForm,
      EditSearchResultsForm,
      SearchExperienceForm,
      SearchFlightForm,
      ResultsMapView,
      TravelerInformationForm,
      AutoCompleteLocationOption,
      AddressLookupOption,
      ResultsPageTemplate,
      TravelerQuantityPickerComboBox,
      HotelRooms,
      RoomDetailsCard,
      CarAdditionalProductCard,
      Layout,
      HotelDetails,
      ExperienceDetails,
      PopularAmenitiesList,
      CancelOrderModal,
      ContinueButton,
      ShowHideButton,
      FlightPriceFooter,
      FlightPaymentOptions,
      CancelOrderPaymentForm,
      PageTitle,
      StaticMap,
      FlightBasketItemCard,
      CarDetails,
      FlightBasketItemDetails,
      PageContainer,
      ExperienceInformation,
      Label,
      FlightItineraryProp,
      FlightServiceItineraryProp,
      SearchResultCard,
      FeaturesList,
      ExperienceTitle,
      PricingAndSearchCard,
      Combobox,
      CarItemExtrasPopover,
      TicketOptions,
      RangeDatePicker,
      FlightEditSearch,
      BoundDetails,
      SearchFieldSwapButton,
      MeetingPoints,
      ExternalLink,
      FlightBreakdown,
      TravelerQuantityPicker,
      FlightDetailsModal,
      FlightSelect,
      Badge,
      OverlayTip,
      Snackbar,
      RoomsBanner,
      BasketTravellersLabel,
      IconValuePair,
      StickyBanner,
      HotelAdditionalDetails,
      WhatToExpect,
      AtolEligible,
      ShareButton,
      CarHireAdditionalDetails,
      ContentBadge,
      DismissibleModal,
      RecentSearches,
      PopularDestinations,
      Dropdown,
      UpgradeBasketItem,
      ChatWidgetMock,
      FlightAviosOnlyBanner,
      PriceBreakdownContainer,
    },
  },
  baseTheme
) as ChakraTheme
