import { Box, FlexProps, Heading, Image, useMultiStyleConfig } from '@chakra-ui/react'
import { PropsWithChildren } from 'react'

type CardBannerProps = {
  imageUrl?: string
}

function Headline({ children, ...rest }: PropsWithChildren & FlexProps) {
  const styles = useMultiStyleConfig('CardBanner', {})

  return (
    <Heading sx={styles.textHeading} {...rest}>
      {children}
    </Heading>
  )
}

function MainContent({ children, ...rest }: PropsWithChildren & FlexProps) {
  const styles = useMultiStyleConfig('CardBanner', {})
  return (
    <Box sx={styles.mainContent} {...rest}>
      <Box sx={styles.overlayContainer}>{children}</Box>
    </Box>
  )
}

function Body({ children, ...rest }: PropsWithChildren & FlexProps) {
  const styles = useMultiStyleConfig('CardBanner', {})
  return (
    <Box sx={styles.textContent} {...rest}>
      {children}
    </Box>
  )
}

function Note({ children, ...rest }: PropsWithChildren & FlexProps) {
  const styles = useMultiStyleConfig('CardBanner', {})
  return (
    <Box sx={styles.noteContent} {...rest}>
      {children}
    </Box>
  )
}

export default function CardBanner({
  imageUrl,
  children,
  ...rest
}: PropsWithChildren & CardBannerProps & FlexProps) {
  const styles = useMultiStyleConfig('CardBanner', {})

  return (
    <Box sx={styles.container} {...rest}>
      <Image src={imageUrl} alt="Banner image" objectFit="cover" width="full" height="full" />
      <Box sx={styles.overlayContainer}>
        <Box sx={styles.contentContainer}>{children}</Box>
      </Box>
    </Box>
  )
}

CardBanner.Headline = Headline
CardBanner.Body = Body
CardBanner.Note = Note
CardBanner.MainContent = MainContent
