import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const addressLookupOptionHelpers = createMultiStyleConfigHelpers([
  'addressLookupOptionWrapper',
  'autoCompleteListIcon',
  'autoCompleteListTitle',
  'autoCompleteListP',
])

export const AddressLookupOption = addressLookupOptionHelpers.defineMultiStyleConfig({
  baseStyle: {
    addressLookupOptionWrapper: {
      display: 'flex',
      flexDirection: 'row',
      borderBottom: '1px',
      borderColor: 'brand.coolgrey1',
      alignItems: 'center',
      padding: 0,
      cursor: 'pointer',

      _hover: {
        cursor: 'pointer',
      },
    },
    autoCompleteListIcon: {
      margin: 4,
    },
    autoCompleteListTitle: {
      fontSize: 'sm',
    },
    autoCompleteListP: {
      fontSize: 'sm',
    },
  },
})
