import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'header',
  'passBy',
  'duration',
  'durationAdmissionContainer',
  'durationContainer',
  'optionalText',
  'whatToExpectTitle',
  'whatToExpectContainer',
])

export const WhatToExpect = helpers.defineMultiStyleConfig({
  baseStyle: {
    header: {
      display: 'flex',
      flexDirection: 'column',
    },
    duration: {
      fontSize: 'md',
      fontWeight: 'medium',
    },
    durationAdmissionContainer: {
      display: 'flex',
      gap: 2.5,
      mt: 4,
      flexDirection: { base: 'column', sm: 'row' },
    },
    durationContainer: {
      display: 'flex',
      gap: 2,
      alignItems: 'center',
      width: 'fit-content',
    },
    whatToExpectContainer: {
      '> ul': {
        mt: 10,
      },
      '> ul li+li': {
        mt: 10,
      },
    },
  },
})
