import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { skeletonFade } from '@reward-platform/lift/components/SkeletonCard/themes/base'

export const helpers = createMultiStyleConfigHelpers([
  'container',
  'about',
  'nearby',
  'heading',
  'address',
  'expandButton',
])

export const HotelSummary = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      scrollMarginTop: 16,
      position: 'relative',
      display: 'grid',
      padding: 6,
      boxShadow: '0px 2px 4px rgba(0,0,0, 0.1)',
      borderRadius: 'lg',
      gridTemplate: {
        base: "'about' auto 'nearby' auto / 1fr",
        sm: "'about . nearby' auto / minmax(36ch, 72ch) 1fr minmax(24ch, 40ch)",
      },
      outline: 'none',
    },
    about: {
      gridArea: 'about',
    },
    nearby: {
      gridArea: 'nearby',
      display: 'grid',
      gridTemplate: "'map' 15.5rem 'suggestions' auto / 1fr",
      alignItems: 'center',
      justifyItems: 'center',
      ':hover': {
        transform: 'translate(180)',
      },
    },
    heading: {
      marginTop: 0,
      marginBottom: 2,

      _empty: {
        minHeight: '1.2em',
        backgroundColor: '#eee',
        borderRadius: 'xl',
        cursor: 'default',
        animation: `${skeletonFade} 2s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955)`,
      },
    },
    address: {
      margin: '0.85rem 0 1.15rem 0',
      fontSize: 'xl',
    },
  },
})
