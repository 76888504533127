import { helpers } from './base'

export const Drawer = helpers.defineMultiStyleConfig({
  variants: {
    primary: {
      content: {
        bgColor: 'bg.layer1.default',
      },
      closeButton: {
        border: 'none',
        _hover: {
          bgColor: 'bg.layer1.default',
        },
      },
    },
  },
})
