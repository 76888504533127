import { useRouter } from 'next/router'
import { FeatureFlagKey } from '@reward-platform/devcycle'
import { sanitizeBaseUrl } from '~/utils/url'
import { useFeatureFlag } from '../useFeatureFlag/useFeatureFlag'

// TODO: switch to use new implementation and move to feature flag folder?

type RedirectOptions = {
  isEnabled?: boolean
  // @param {string} redirectPath - The path to redirect to
  redirectTo?: string
  // @param {boolean} checkValue - The hook will redirect away from page if the feature flag value equals the check value
  checkValue?: boolean
}

/**
 * This hook will redirect away from the page if the feature flag value is equal to the check value
 * @param {string} featureFlagKey - The key of the feature flag to check
 * @param {boolean} defaultValue - The default value of the feature flag
 * defaults to false
 * @param {object} options
 * */
const useRedirectOnFeatureFlag = (
  featureFlagKey: FeatureFlagKey,
  defaultValue = false,
  { redirectTo = '/', checkValue = false, isEnabled = true }: RedirectOptions = {}
) => {
  const router = useRouter()
  const featureFlagValue = useFeatureFlag(featureFlagKey, defaultValue)
  const shouldRedirect = !!isEnabled && featureFlagValue === checkValue

  if (!shouldRedirect) {
    return
  }

  if (/https?:\/\//.test(redirectTo)) {
    router.replace(sanitizeBaseUrl(redirectTo))
  } else {
    router.push({
      pathname: redirectTo ?? '/',
    })
  }
}

export default useRedirectOnFeatureFlag
