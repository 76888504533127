import { itineraryHelpers } from './base'

export const Itinerary = itineraryHelpers.defineMultiStyleConfig({
  baseStyle: itineraryHelpers.definePartsStyle({
    property: {
      p: {
        fontSize: 'xs',

        _first: {
          fontWeight: 'normal',
        },
      },
    },
    title: {
      fontSize: '2xl',
    },
    itemHeader: {
      h3: {
        fontSize: 'xl',
        fontWeight: 'unset',
      },
    },
  }),
})
