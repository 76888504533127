import { carItemExtrasPopoverHelper } from './base'

export const CarItemExtrasPopover = carItemExtrasPopoverHelper.defineMultiStyleConfig({
  baseStyle: carItemExtrasPopoverHelper.definePartsStyle({
    extrasIcon: {
      backgroundColor: 'brand.highlight.blue',
    },
    carExtraWrapper: {
      '> div > section > div': {
        borderRadius: 'sm',
      },
      li: {
        bg: 'brand.baseGray.5',
        borderRadius: 'md',
      },
    },
  }),
})
