import { datadogLogs, LoggerConfiguration } from '@datadog/browser-logs'
import {
  DATADOG_LOG_META_KEY,
  DatadogLogErrorContext,
  DatadogLogMetaContext,
} from '@reward-platform/logger/utils'

type LoggerContext = {
  error?: DatadogLogErrorContext
  [DATADOG_LOG_META_KEY]?: Partial<DatadogLogMetaContext>
  [key: string]: any
}

type LoggerFunction<T> = (message: string, context?: T, error?: Error) => void

export const createDatadogLogger = <Ctx extends LoggerContext = LoggerContext>(
  name: string,
  config: LoggerConfiguration = {}
) => {
  if (!name) {
    return datadogLogs.logger
  }

  const customLogger = datadogLogs.createLogger(name, config)
  const info: LoggerFunction<Ctx> = (...args) => customLogger.info(...args)
  const warn: LoggerFunction<Ctx> = (...args) => customLogger.warn(...args)
  const error: LoggerFunction<Ctx> = (...args) => customLogger.error(...args)
  const debug: LoggerFunction<Ctx> = (...args) => customLogger.debug(...args)
  return { info, warn, error, debug }
}
