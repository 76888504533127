import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const acceptedCardsHelper = createMultiStyleConfigHelpers([
  'acceptedCardWrapper',
  'header',
  'cardImage',
])

export const AcceptedCards = acceptedCardsHelper.defineMultiStyleConfig({
  baseStyle: acceptedCardsHelper.definePartsStyle({
    acceptedCardWrapper: {
      display: 'flex',
      alignItems: 'center',
      height: 14,
      width: '100%',
      padding: 4,
      mt: { base: 2, md: 0 },
    },
    header: {
      lineHeight: 'normal',
      fontSize: 'xs',
      mt: 0,
      whiteSpace: 'nowrap',
      pr: 4,
    },
    cardImage: {
      h: '100%',
    },
  }),
})
