import { helpers } from './base'

export const BasketItemPaymentOptionsSelect = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    basketItemContainer: {
      hr: {
        borderColor: 'brand.baseGray.25',
        opacity: 1,
        borderBottomWidth: 1,
        marginTop: 2,
        marginBottom: 2,
      },
    },
    andCollectAvios: {
      fontSize: 'xs',
      '> span': {
        fontWeight: 'normal!important',
      },
    },
    label: {
      mt: '1px',
    },
  }),
})
