import { replaceHotelItemCardHelper } from './base'

export const ReplaceHotelItemCard = replaceHotelItemCardHelper.defineMultiStyleConfig({
  baseStyle: replaceHotelItemCardHelper.definePartsStyle({
    content: {
      img: {
        borderRadius: 'lg',
      },
    },
    heading: {
      h4: {
        fontSize: 'md',
      },
    },
  }),
})
