import { helpers } from './base'

const baseGray10 = 'brand.baseGray.10'
const primaryBlue = 'brand.primary.blue'

export const ShareButton = helpers.defineMultiStyleConfig({
  baseStyle: {
    button: {
      borderRadius: 'sm',
      color: primaryBlue,
      minWidth: '44',
    },
    icon: {},
  },
  variants: {
    ghost: {
      button: {
        _focus: {
          background: 'brand.white',
        },
        _hover: {
          background: 'brand.backgroundBlue500',
        },
      },
    },
    secondary: {
      button: {
        _focus: {
          background: baseGray10,
          color: primaryBlue,
          borderColor: primaryBlue,
        },
      },
    },
  },
})
