import { ComponentSingleStyleConfig } from '@chakra-ui/react'

export const PageTitle: ComponentSingleStyleConfig = {
  baseStyle: {
    h1: {
      fontWeight: 'normal',
      marginY: { base: 6, md: 8 },
    },
    span: {
      marginY: 12,
    },
  },
}
