import { helpers } from './base'

export const Dropdown = helpers.defineMultiStyleConfig({
  baseStyle: {
    title: {
      color: 'brand.primary.midnightBlue',
      fontWeight: 'normal',
    },
    subtitle: {
      color: 'brand.primary.midnightBlue',
      fontWeight: 'light',
    },
  },
})
