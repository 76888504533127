import { gridColumn, helpers } from './base'

export const SearchCarHireForm = helpers.defineMultiStyleConfig({
  baseStyle: {
    under30Checkbox: {
      gridColumn: {
        base: gridColumn(1, 2),
        md: gridColumn(1, 9),
        lg: gridColumn(1, 4),
      },
    },
    birthDatePicker: {
      gridColumn: {
        base: gridColumn(1, 2),
        md: gridColumn(1, 9),
        lg: gridColumn(5, 3),
      },
    },
    isAvisPreferred: {
      gridColumn: {
        base: gridColumn(1, 2),
        md: gridColumn(10, 9),
        lg: gridColumn(8, 5),
      },
    },
    membershipCode: {
      gridColumn: {
        base: gridColumn(1, 2),
        md: gridColumn(10, 9),
        lg: gridColumn(13, 3),
      },
    },
    membershipCodeLarge: {
      gridColumn: gridColumn(10, 4),
    },
    isAvisPreferredAlign: {
      gridColumn: gridColumn(5, 5),
    },
    submitButton: {
      height: '62px',
    },
  },
})
