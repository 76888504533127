import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const basketItemCardHelpers = createMultiStyleConfigHelpers([
  'cardWrapper',
  'removeButton',
  'buttonContainer',
  'paymentOptions',
  'modalCardWrapper',
  'optionInfoBox',
])

export const BasketItemCard = basketItemCardHelpers.defineMultiStyleConfig({
  baseStyle: {
    cardWrapper: {
      display: 'grid',
    },
    modalCardWrapper: {
      display: 'flex',
      flexDirection: { base: 'column', lg: 'row' },
    },
    removeButton: {
      height: '2.75rem',
      display: 'flex',
      flexDirection: 'row',
      position: 'absolute',
      top: 0,
      outline: 'none',
      transform: 'translateY(-100%)',
      alignItems: 'center',
      columnGap: 1,
      right: 0,
      border: 0,
      bg: 'transparent',
      fontSize: 'xs',
      cursor: 'pointer',
      _focusVisible: {
        outlineStyle: 'solid',
        outlineColor: 'brand.focusOutline',
        outlineWidth: 1,
      },
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: { base: 'column', md: 'row' },
      alignItems: 'center',
      w: { base: 'auto', lg: '70%' },
      m: 'auto',
      mt: 12,
      mb: 0,
      gap: 3,
      flexBasis: { base: 'calc(100% / 3)', md: 'unset' },
    },
    paymentOptions: {
      gridArea: 'payment-options',
      flex: 2,
      m: { base: 4, lg: 6 },
      ml: { xl: 0 },
      mr: { xl: 8 },
    },
  },
  variants: {
    ancillary: {
      cardWrapper: {
        gridTemplateRows: { base: '11rem 1fr', lg: '1fr' },
        gridTemplateColumns: { base: '1fr', lg: '18.375rem 1fr', xl: '18.375rem 1fr 14.125rem' },
        gridTemplateAreas: {
          base: `'image'
        'details'
        'payment-options'`,
          lg: `'image details'
      'image payment-options'`,
          xl: `'image details payment-options'`,
        },
      },
    },
    flight: {
      cardWrapper: {
        gridTemplateRows: 'auto',
        gridTemplateColumns: { base: '1fr', lg: '1fr 14.125rem' },
        gridTemplateAreas: {
          base: `'details' 'payment-options'`,
          lg: `'details payment-options'`,
        },
      },
      paymentOptions: {
        mt: { lg: '3.75rem' },
      },
    },
  },
})
