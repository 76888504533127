import { helpers } from './base'

export const EditSearchResultsForm = helpers.defineMultiStyleConfig({
  baseStyle: {
    submitBtn: {
      maxHeight: '62px',
      height: '100%',
    },
  },
})
