import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helper = createMultiStyleConfigHelpers([
  'priceTotalSummaryWrapper',
  'priceTotalWrapper',
  'heading',
  'currencyPair',
  'collectedAvios',
])

export const PriceBreakdownTotalDue = helper.defineMultiStyleConfig({
  baseStyle: helper.definePartsStyle({
    priceTotalSummaryWrapper: {
      'p > span:nth-of-type(2)': {
        p: 1,
        pl: 2,
        pr: 2,
      },
      button: {
        w: '100%',
        h: 14,
        mt: 3,
      },
    },
    priceTotalWrapper: {
      display: 'flex',
      flexDirection: 'row',
      py: 4,
    },
    heading: {
      m: 0,
      textAlign: 'right',
      fontSize: '2xl',
    },
    currencyPair: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      textAlign: 'right',
    },
    collectedAvios: {
      fontSize: 'xs',
      textAlign: 'center',
      mt: 2,
      mb: 2,
    },
  }),
  variants: {},
})
