import { createLogger, format, transports } from 'winston'

function buildProdLogger(useFileTransport = true) {
  return createLogger({
    format: format.combine(
      format.timestamp({
        format: 'YYYY-MM-DD HH:mm:ss',
      }),
      format.errors({ stack: true }),
      format.splat(),
      format.json()
    ),
    defaultMeta: { service: 'reward-platform' },
    transports: [
      new transports.Console(),
      ...(useFileTransport
        ? [
            new transports.File({ filename: 'apps/api/error.log', level: 'error' }),
            new transports.File({ filename: 'apps/api/combined.log' }),
          ]
        : []),
    ],
    exceptionHandlers: [
      new transports.Console(),
      ...(useFileTransport ? [new transports.File({ filename: 'apps/api/exceptions.log' })] : []),
    ],
  })
}

export default buildProdLogger
