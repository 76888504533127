/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const WhatToExpect = helpers.defineMultiStyleConfig({
  baseStyle: {
    passBy: {
      fontWeight: 'normal',
    },
    header: {
      color: 'fg.primary',
    },
    optionalText: {
      fontSize: 'sm',
      fontWeight: 'normal',
    },
    durationContainer: {
      backgroundColor: 'bg.accent.subtle.default',
      color: 'fg.accent.onSubtle',
      fontWeight: 'medium',
      padding: 'var(--alto-sem-space-4xs) var(--alto-sem-space-2xs)',
      borderRadius: 'xs',
    },
    whatToExpectContainer: {
      '> ul': {
        mt: 10,
      },
      '> ul b': {
        fontWeight: 'normal',
      },
      '> ul li+li': {
        mt: 10,
      },
    },
  },
})
