import { z } from 'zod'
import {
  CarHireItemType,
  DateSchema,
  ExperienceItemType,
  FlightDetailsSchema,
  FlightItemType,
  HotelItemType,
  ISODateStringSchema,
  MonetaryAmountSchema,
  OrderLineStatusSchema,
} from '../common'
import { OrderStatusSchema } from './order'

export const OrderPriceSchema = z.object({
  aviosSpent: z.number().optional(),
  aviosCollect: z.number().optional(),
  cashSpent: MonetaryAmountSchema.optional(),
  refundAvios: z.number().optional(),
  refundCash: MonetaryAmountSchema.optional(),
  cancellationFee: MonetaryAmountSchema.optional(),
  totalAviosRefunded: z.number().optional(),
  totalCashRefund: MonetaryAmountSchema.optional(),
})
export type OrderPrice = z.infer<typeof OrderPriceSchema>

export const OrderListItemLineTypeSchema = z.enum([
  HotelItemType,
  ExperienceItemType,
  CarHireItemType,
])
export type OrderListItemLineType = z.infer<typeof OrderListItemLineTypeSchema>

export const OrderListItemLineBaseSchema = z.object({
  id: z.string(),
  name: z.string().optional(),
  status: OrderLineStatusSchema,
  startDate: ISODateStringSchema,
  endDate: ISODateStringSchema.optional(),
  balance: OrderPriceSchema,
  type: OrderListItemLineTypeSchema,
  detail: z.string().optional(),
  bundleRefs: z.string().array(),
})
export type OrderListItemLineBase = z.infer<typeof OrderListItemLineBaseSchema>

export const OrderListLineHotelItemSchema = OrderListItemLineBaseSchema.extend({
  type: z.literal(HotelItemType),
  room: z.object({
    name: z.string(),
  }),
  numberOfRooms: z.number(),
  duration: z.number().optional(),
  amountTravellers: z.number().optional(),
})
export type OrderListLineHotelItem = z.infer<typeof OrderListLineHotelItemSchema>

const OrderLineCarHireExtraSchema = z.object({
  name: z.string(),
  selectedQuantity: z.number(),
  serviceSubType: z.string(),
})
export type OrderLineCarHireExtra = z.infer<typeof OrderLineCarHireExtraSchema>

export const OrderListLineCarHireItemSchema = OrderListItemLineBaseSchema.extend({
  type: z.literal(CarHireItemType),
  vehicleName: z.string().optional(),
  pickUpLocation: z.string().optional(),
  dropOffLocation: z.string().optional(),
  duration: z.number().optional(),
  extras: z.array(OrderLineCarHireExtraSchema).optional(),
})
export type OrderListLineCarHireItem = z.infer<typeof OrderListLineCarHireItemSchema>

export const OrderListLineExperienceItemSchema = OrderListItemLineBaseSchema.extend({
  name: z.string(),
  type: z.literal(ExperienceItemType),
  amountTravellers: z.number(),
})
export type OrderListLineExperienceItem = z.infer<typeof OrderListLineExperienceItemSchema>

export const OrderListLineFlightItemSchema = OrderListItemLineBaseSchema.extend({
  type: z.literal(FlightItemType),
  recordLocator: z.string(),
  outboundFlightDetails: FlightDetailsSchema,
  inboundFlightDetails: FlightDetailsSchema.optional(),
  amountTravellers: z.number(),
})
export type OrderListLineFlightItem = z.infer<typeof OrderListLineFlightItemSchema>

export const OrderListLineItemSchema = z.discriminatedUnion('type', [
  OrderListLineHotelItemSchema,
  OrderListLineCarHireItemSchema,
  OrderListLineExperienceItemSchema,
  OrderListLineFlightItemSchema,
])
export type OrderListLineItem = z.infer<typeof OrderListLineItemSchema>

export const OrderListItemSchema = z.object({
  date: DateSchema,
  orderNumber: z.string(),
  status: OrderStatusSchema,
  balance: OrderPriceSchema,
  lines: z.array(OrderListLineItemSchema),
})
export type OrderListItem = z.infer<typeof OrderListItemSchema>

export const PaginationSchema = z.object({
  pageNumber: z.number(),
  pageSize: z.number(),
  numberOfRecords: z.number(),
  isNextPageAvailable: z.boolean(),
})

export const OrderListSchema = z.object({
  correlationId: z.string(),
  items: z.array(OrderListItemSchema),
  pagination: PaginationSchema,
})
export type OrderList = z.infer<typeof OrderListSchema>

export const PageNumberQuerySchema = z.object({
  page: z.number().positive().int(),
})
