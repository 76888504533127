import { datadogLogs } from '@datadog/browser-logs'
import { isLocal } from '../envChecks'

export const initDatadogLogging = async () => {
  if (
    process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN &&
    process.env.NEXT_PUBLIC_DD_SITE &&
    process.env.NEXT_PUBLIC_DD_SERVICE &&
    process.env.NEXT_PUBLIC_DEPLOYMENT
  ) {
    datadogLogs.init({
      clientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN,
      site: process.env.NEXT_PUBLIC_DD_SITE,
      service: process.env.NEXT_PUBLIC_DD_SERVICE,
      env: process.env.NEXT_PUBLIC_DEPLOYMENT,
      version: '1.0',
      // forwardErrorsToLogs: true, testing noise reduction
      sessionSampleRate: 100,
      trackingConsent: 'granted',
    })
  } else if (!isLocal()) {
    // eslint-disable-next-line no-console
    console.warn('DatadogLogs is missing configuration!')
  }
}

export const { logger } = datadogLogs
