import { createContext } from 'react'
import { pushEventToDataLayer as gtmPushEventToDataLayer } from '~/utils/googleTagManager'

export type PushEventToDataLayer = typeof gtmPushEventToDataLayer

export type TagManagerContextValue = {
  pushEventToDataLayer: PushEventToDataLayer
}

export const TagManagerContext = createContext<TagManagerContextValue | undefined>(undefined)
