import { Locale } from 'date-fns'
import * as Locales from 'date-fns/locale'

const isDateFnsSupportedLocale = (locale: string): locale is keyof typeof Locales =>
  Object.keys(Locales).includes(locale)

export const getDateFnsLocaleByLocaleString = (rawLocale?: string): Locale | undefined => {
  if (!rawLocale) {
    return undefined
  }
  const locale = rawLocale.replace('-', '')
  const isSupportedLocale = isDateFnsSupportedLocale(locale)

  return isSupportedLocale ? Locales[locale] : Locales.enUS
}
