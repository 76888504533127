import { helpers } from './base'

export const TravelerQuantityPickerComboBox = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    travelerQuantityContainer: {
      '@media (min-width: md)': {
        padding: 'lg',
      },
    },
    maxNumberOfTravelers: {
      fontSize: 'xs',
      padding: '1.5',
      marginBottom: '5',
      backgroundColor: 'brand.teal10',
    },
    comboBoxContainer: {
      border: 'none',
      color: 'brand.black',
      fontWeight: 'medium',
    },
  }),
})
