import { Box, chakra, useMultiStyleConfig } from '@chakra-ui/react'
import formatPrimaryDriversName from '~/utils/formatPrimaryDriversName'
import useIntl from '~/hooks/useIntl/useIntl'

export const HeaderUserDetails = ({
  user,
  accountBalanceAmount,
}: {
  user: { title?: string; firstName: string; familyName: string } | undefined
  accountBalanceAmount: number | undefined
}) => {
  const styles = useMultiStyleConfig('Header', {})
  const intl = useIntl()

  const t = {
    welcomeBack: intl.formatMessage({
      id: 'header-welcome-back',
      defaultMessage: 'Welcome back',
    }),
    aviosBalance: intl.formatMessage({
      id: 'header-avios-balance',
      defaultMessage: 'Avios Balance',
    }),
  }

  return (
    <>
      <Box __css={styles.userDetails} data-testid="user-details">
        <chakra.span>{t.welcomeBack}</chakra.span>
        <chakra.span>
          {user ? formatPrimaryDriversName(user.title, user.firstName, user.familyName) : '—'}
        </chakra.span>
      </Box>
      <Box __css={styles.aviosDetails} data-testid="avios-details">
        <chakra.span>{t.aviosBalance}</chakra.span>
        <chakra.span>
          {accountBalanceAmount != null && Number.isInteger(accountBalanceAmount)
            ? intl.formatNumber(accountBalanceAmount, {
                maximumFractionDigits: 0,
              })
            : '—'}
        </chakra.span>
      </Box>
    </>
  )
}
