import { ancillaryBasketItemDetailsHelpers } from './base'

export const AncillaryBasketItemDetails = ancillaryBasketItemDetailsHelpers.defineMultiStyleConfig({
  baseStyle: {
    header: {
      '> div:first-of-type': {
        h4: {
          color: 'fg.accent.primary',
          fontWeight: 'semibold',
          fontSize: 'var(--alto-sem-text-heading-sm-font-size)',
          lineHeight: 'var(--alto-sem-text-heading-sm-line-height)',
        },
        h5: {
          letterSpacing: 0,
          fontSize: 'sm',
          fontWeight: 'normal',
          mt: 0,
        },
      },
    },
  },
})
