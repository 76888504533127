export const sanitizeBaseUrl = (host: string, useHttp = false) => {
  const targetProtocol = useHttp ? 'http' : 'https'
  const value = String(host)
  if (value.startsWith('http://')) {
    return value.replace('http', targetProtocol)
  }
  if (value.startsWith('https://')) {
    return value.replace('https', targetProtocol)
  }
  if (!value.startsWith(`${targetProtocol}://`)) {
    return `${targetProtocol}://${value}`
  }
  return value
}
