import { z } from 'zod'

export const CustomerReviewSchema = z.object({
  description: z.string().optional(),
  title: z.string().optional(),
  name: z.string(),
  date: z.string(),
  overallGuestRating: z.number(),
  customerReviewVendor: z.string(),
})

export type CustomerReview = z.infer<typeof CustomerReviewSchema>
