import { helpers } from './base'

export const FlightAviosOnlyBanner = helpers.defineMultiStyleConfig({
  baseStyle: {
    promoBanner: {
      color: 'white',
    },
    aviosShape: {
      svg: {
        fill: 'brand.primaryTeal',
      },
    },
  },
})
