import { footerHelpers } from './base'

const white = 'brand.white'

export const Footer = footerHelpers.defineMultiStyleConfig({
  baseStyle: footerHelpers.definePartsStyle({
    wrapper: {
      backgroundColor: 'brand.primary.midnightBlue',
      color: white,
    },
    navListLink: {
      color: white,
      _focusVisible: {
        outlineWidth: 1,
        outlineColor: white,
      },
    },
  }),
})
