import { headerHelpers } from './base'

export const Header = headerHelpers.defineMultiStyleConfig({
  baseStyle: headerHelpers.definePartsStyle({
    headerWrapper: {
      boxShadow: 'brand.boxShadow',
    },
    userDetails: {
      '> span': {
        _first: { fontSize: 'xs' },
        _last: { fontSize: 'xl' },
      },
    },
    aviosDetails: {
      '> span': {
        _first: { fontSize: 'xs' },
        _last: { fontSize: { base: 'lg', lg: 'xl' } },
      },
    },
    cartWrapper: {
      color: 'brand.primary.midnightBlue',
    },
  }),
})
