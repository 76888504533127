import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const experienceBasketItemHelper = createMultiStyleConfigHelpers(['content', 'icons'])

export const ExperienceBasketItemCard = experienceBasketItemHelper.defineMultiStyleConfig({
  baseStyle: experienceBasketItemHelper.definePartsStyle({
    content: {
      display: 'flex',
      flexDir: 'column',
      height: '100%',
      justifyContent: 'flex-end',
    },
    icons: {
      display: 'flex',
      flexDir: 'row',
      marginTop: 4,
      columnGap: 4,
      rowGap: 1,
      flexWrap: 'wrap',
      fontSize: 'xs',
    },
  }),
})
