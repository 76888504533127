import { ComponentMultiStyleConfig } from '@chakra-ui/react'
import merge from 'lodash/merge'
import { Switch as base } from './base'

export const Switch: ComponentMultiStyleConfig = merge({}, base, {
  baseStyle: {
    switchButton: { color: 'brand.black' },
    switchIcon: { bgColor: 'brand.coolgrey10' },
  },
  variants: {
    isActive: {
      switchOffValue: { fontWeight: 'normal' },
      switchOnValue: { fontWeight: 'medium' },
      switchIcon: {
        _before: { bgColor: 'brand.primaryTeal' },
        _after: { bgColor: 'white' },
      },
    },
    notActive: {
      switchOffValue: { fontWeight: 'medium' },
      switchOnValue: { fontWeight: 'normal' },
      switchIcon: {
        _before: { bgColor: 'brand.primaryTeal' },
        _after: { bgColor: 'white' },
      },
    },
  },
})
