import { z } from 'zod'

export const ContentfulCancellationPoliciesRequestParamsSchema = z.object({
  locale: z.string(),
  preview: z.coerce.boolean().optional(),
})

export type ContentfulCancellationPoliciesRequestParams = z.infer<
  typeof ContentfulCancellationPoliciesRequestParamsSchema
>

export const CancellationPoliciesSchema = z.object({
  hotelNightlyPenalty: z.string(),
  hotelAmountPenalty: z.string(),
  hotelPercentPenalty: z.string(),
  hotelAmountNightlyPenalty: z.string(),
  hotelAmountPercentPenalty: z.string(),
  hotelNonRefundableDateRange: z.string(),
  hotelNonRefundableDateRangeNightlyPenalty: z.string(),
  hotelNonRefundableDateRangeAmountPenalty: z.string(),
  hotelNonRefundableDateRangePercentPenalty: z.string(),
  hotelTieredCancellation: z.string(),
  hotelRefundable: z.string(),
  hotelNonRefundable: z.string(),
  hotelPartiallyRefundableAmountPenalty: z.string(),
  hotelPartiallyRefundableNightlyPenalty: z.string(),
  hotelPartiallyRefundablePercentPenalty: z.string(),
  carHireDefault: z.string(),
  experienceDefault: z.string(),
  experienceBadWeather: z.string(),
  experienceInsufficientTravelers: z.string(),
  flightDefault: z.string(),
  noRefundAfterDate: z.string(),
  duringStayCancellation: z.string(),
  fullyRefundableBefore: z.string(),
  conditionalNonRefundable: z.string(),
  partialPenalty: z.string(),
})

export type CancellationPolicies = z.infer<typeof CancellationPoliciesSchema>
