import { basketItemCardHelpers } from './base'

export const BasketItemCard = basketItemCardHelpers.defineMultiStyleConfig({
  baseStyle: {
    cardWrapper: {
      borderRadius: 'xs',
      bg: 'bg.layer2.default',
      '> div:first-of-type': {
        m: 0,
        borderRadius: 0,
        img: {
          borderRadius: 0,
          borderTopLeftRadius: 'xs',
        },
      },
    },
    removeButton: {
      fontSize: 'sm',
      color: 'fg.secondary',
    },
  },
})
