import { cardHelpers } from './base'

export const Card = cardHelpers.defineMultiStyleConfig({
  baseStyle: cardHelpers.definePartsStyle({
    container: {
      border: 'none',
      borderRadius: 'sm',
      boxShadow: 'brand.boxShadow',
    },
  }),
  variants: {
    elevated: {
      container: {
        border: 'none',
        borderRadius: 'sm',
        boxShadow: 'brand.boxShadow',
        backgroundColor: 'brand.white',
      },
    },
  },
  sizes: {
    md: {
      container: { w: '340px', minHeight: '600px' },
    },
    sm: {
      container: { w: '340px', minHeight: '525px' },
    },
  },
})
