import { itemCardImageHelper } from './base'

export const ItemCardImage = itemCardImageHelper.defineMultiStyleConfig({
  baseStyle: itemCardImageHelper.definePartsStyle({}),
  sizes: {
    sm: {},
    md: {},
    lg: {
      imageWrapper: {
        img: {
          _first: {
            borderRadius: 'sm',
          },
        },
      },
    },
  },
})
