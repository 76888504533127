import { helpers } from './base'

const midnightBlue = 'brand.primary.midnightBlue'
const primaryRed = 'brand.primary.red'

export const StatusBadge = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    container: {
      borderRadius: 'sm',
      color: 'white',
    },
    label: {
      lineHeight: 'normal',
      fontSize: 'xs',
      fontWeight: 'normal',
    },
  }),
  variants: {
    READY_TO_PROCEED: { container: { bg: 'brand.confirmationGreen' } },
    REMOVED: {
      container: {
        bg: 'brand.baseGray.25',
        color: midnightBlue,
        svg: {
          w: 3,
        },
      },
    },
    DELIVERED: { container: { bg: midnightBlue } },
    SUSPENDED: { container: { bg: primaryRed } },
    UNABLE_TO_DELIVER: { container: { bg: primaryRed } },
    IN_PROGRESS: { container: { bg: primaryRed } },
    UNKNOWN: { container: { bg: midnightBlue } },
  },
})
