import { z } from 'zod'

export const PaginationSchema = z.object({
  pageNumber: z.number(),
  pageSize: z.number(),
  numberOfRecords: z.number(),
  isNextPageAvailable: z.boolean(),
})

export const PAGINATION_PAGE_AMOUNT = 50
