/* eslint-disable sonarjs/no-duplicate-string */
import { SnackbarHelper } from './base'

export const Snackbar = SnackbarHelper.defineMultiStyleConfig({
  baseStyle: SnackbarHelper.definePartsStyle({
    container: {
      bgColor: 'brand.cloudWhite',
      borderColor: 'brand.primaryBlue',
    },
  }),
  variants: {
    promo: SnackbarHelper.definePartsStyle({
      container: {
        borderColor: 'brand.highlight.blue',
        svg: {
          color: 'brand.highlight.blue',
        },
        'p:first-child': {
          fontWeight: 'normal',
          pb: 2,
        },
        strong: {
          fontWeight: 'normal',
        },
      },
    }),
  },
})
