import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
  useMultiStyleConfig,
} from '@chakra-ui/react'
import React, { PropsWithChildren } from 'react'

type ChakraDrawerProps = {
  title: string
  openButtonText?: string
  isOpen?: boolean
  onOpen?: () => void
  onClose?: () => void
  placement?: 'top' | 'right' | 'bottom' | 'left'
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full'
}

const ChakraDrawer = ({
  children,
  title,
  openButtonText,
  isOpen,
  onOpen,
  onClose,
  placement = 'right',
  size = 'md',
}: PropsWithChildren<ChakraDrawerProps>) => {
  const styles = useMultiStyleConfig('Drawer', { variant: 'primary' })
  const { isOpen: defaultIsOpen, onOpen: defaultOnOpen, onClose: defaultOnClose } = useDisclosure()

  return (
    <>
      {openButtonText && <Button onClick={onOpen ?? defaultOnOpen}>{openButtonText}</Button>}
      <Drawer
        isOpen={isOpen ?? defaultIsOpen}
        placement={placement}
        onClose={onClose ?? defaultOnClose}
        size={size}
      >
        <DrawerOverlay />
        <DrawerContent sx={styles.content}>
          <DrawerCloseButton __css={styles.closeButton} />
          <DrawerHeader>{title}</DrawerHeader>
          <DrawerBody bg="inherit">{children}</DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default ChakraDrawer
