import { helpers } from './base'

export const FeaturesList = helpers.defineMultiStyleConfig({
  baseStyle: {
    listContainer: {
      gap: 8,
      marginTop: 10,
    },
    listItem: {
      columnGap: 5,
      fontSize: 'lg',
      color: 'brand.coolgrey10',
    },
    list: {
      'li + li': {
        marginTop: 5,
      },
    },
    includeIcon: {
      color: 'brand.primaryShamrockGreen',
    },
    excludeIcon: {
      color: 'brand.red',
    },
    title: {
      fontSize: '2xl-5',
      fontWeight: 'semibold',
    },
  },
})
