import { ComponentMultiStyleConfig } from '@chakra-ui/react'
import { merge } from 'lodash'
import { Menu as base } from './base'

export const Menu: ComponentMultiStyleConfig = merge({}, base, {
  variants: {
    header: {
      button: {
        color: 'white',
      },
    },
  },
})
