import { ComponentStyleConfig } from '@chakra-ui/react'

const primaryTeal = 'brand.primaryTeal'
const cloudWhite = 'brand.cloudWhite'

export const StepperCircle: ComponentStyleConfig = {
  baseStyle: {
    borderColor: primaryTeal,
    '> p': {
      fontWeight: 'medium',
      fontSize: { base: 'md', md: 'lg' },
    },
    '> svg': {
      fontSize: { base: 'md', md: 'lg' },
    },
  },
  variants: {
    default: { bg: cloudWhite, color: primaryTeal },
    completed: { bg: primaryTeal, color: cloudWhite },
    selected: { bg: primaryTeal, color: cloudWhite },
  },
}
