import React from 'react'
import { chakra } from '@chakra-ui/react'
import { AuthPathKey, getClientAuthPath } from '~/utils/auth'

type AuthLinkProps = {
  returnTo?: string
}

export const AuthLink = ({
  children,
  returnTo,
  type,
}: React.PropsWithChildren<AuthLinkProps & { type: AuthPathKey }>) => {
  return <chakra.a href={getClientAuthPath(type, returnTo)}>{children}</chakra.a>
}

export const LoginLink = (props: React.PropsWithChildren<AuthLinkProps>) => {
  return <AuthLink type="LOGIN" {...props} />
}

export const LogoutLink = (props: React.PropsWithChildren<AuthLinkProps>) => {
  return <AuthLink type="LOGOUT" {...props} />
}
