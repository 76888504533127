import { iconValuePairHelpers } from './base'

export const IconValuePair = iconValuePairHelpers.defineMultiStyleConfig({
  baseStyle: iconValuePairHelpers.definePartsStyle({
    wrapper: {
      columnGap: 1,
      span: {
        fontSize: 'xs',
      },
    },
    value: {
      fontSize: 'sm',
    },
  }),
  variants: {},
})
