export const addQueryToUrlPath = (
  url: string,
  queryParams: Record<string, string | number | boolean>
): string => {
  const urlObj = new URL(url, 'https://temp-base')
  const searchParams = new URLSearchParams(urlObj.search)

  // Append the new query parameters
  Object.entries(queryParams).forEach(([key, value]) => {
    searchParams.set(key, String(value))
  })

  urlObj.search = searchParams.toString()

  return urlObj.pathname + (urlObj.search ?? '')
}
