import { ComponentMultiStyleConfig } from '@chakra-ui/react'

export const Accordion: ComponentMultiStyleConfig = {
  parts: ['root', 'container', 'button', 'panel', 'icon'],
  baseStyle: {
    container: {
      px: 0,
      borderBottom: '1px solid',
    },
    panel: {
      px: 0,
    },
    button: {
      px: 0,
      w: '100%',
      bg: 'none',
      border: 'none',
      _hover: {
        bg: 'none',
        cursor: 'pointer',
      },
    },
  },
}
