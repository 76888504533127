import { z } from 'zod'
import { PackageRulesSchema } from '@reward-platform/shared-schemas'
import { CurrencyCodesSchema } from '../country-currency-codes'
import { AmenitiesGroupSchema } from './amenities'
import { MediaSchema } from './media'
import { PriceLadderSchema } from './price'

const DimensionSchema = z.object({
  sqFeet: z.number(),
  sqMeters: z.number(),
})

export const RoomRateOptionSchema = z.object({
  id: z.string(),
  cashPrice: z.number(),
  aviosPrice: z.number(),
  baseAmount: z.number().optional(),
  collectAmount: z.number(),
  currencyCode: CurrencyCodesSchema,
  available: z.boolean(),
  pricingLadder: z.array(PriceLadderSchema).optional(),
  optionInfo: z.array(z.string()).optional(),
  packageRules: PackageRulesSchema,
})

export const RoomSchema = z.object({
  name: z.string(),
  roomId: z.string(),
  maxOccupants: z.number().optional(),
  dimensions: DimensionSchema.optional(),
  roomRates: z.array(RoomRateOptionSchema),
  roomAmenities: z.array(AmenitiesGroupSchema),
  beds: z.array(z.object({ title: z.string(), bedGroupId: z.string() })).nonempty(),
  media: z.array(MediaSchema),
})

export type Room = z.infer<typeof RoomSchema>
export type RoomRateOption = z.infer<typeof RoomRateOptionSchema>
