import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers(['imageWrapper', 'markerColor'])

export const StaticMap = helpers.defineMultiStyleConfig({
  baseStyle: {
    imageWrapper: {
      w: 'full',
      h: 'full',
      position: 'relative',
    },
    markerColor: {
      color: '#3f51b5',
    },
  },
})
