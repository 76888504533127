import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'card',
  'paymentMethodsContainer',
  'title',
  'form',
  'details',
  'buttonContainer',
  'paymentMethodStyle',
  'showButton',
  'laterTimesToggle',
])

export const SearchResultCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    card: {
      display: 'grid',
      gridTemplateAreas: {
        base: `'details' 'payment-methods' 'form' 'submit-button'`,
        md: `'details payment-methods' 'form submit-button'`,
      },
    },
    paymentMethodsContainer: {
      display: 'flex',
      gridArea: 'payment-methods',
      alignItems: 'flex-start',
      justifyContent: {
        base: 'unset',
        md: 'flex-end',
      },
    },
    form: {
      gridArea: 'form',
      display: 'flex',
      flexDirection: 'column',
    },
    details: {
      gridArea: 'details',
      display: 'flex',
      flexDirection: 'column',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    showButton: {
      fontSize: 'md',
      fontWeight: 'medium',
      lineHeight: 6,
      textDecoration: 'none',
    },
    laterTimesToggle: {
      display: { base: 'block', sm: 'none' },
    },
  },
})
