import { helpers } from './base'

export const CarPriceBar = helpers.defineMultiStyleConfig({
  baseStyle: {
    box: {
      bgColor: 'brand.white',
    },
    subtotalWrapper: {
      bgColor: 'brand.baseGray.10',
    },
    subtotalTitle: {
      color: 'brand.primary.midnightBlue',
    },
  },
})
