import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers(['continueButton', 'buttonIcon', 'container'])

export const ContinueButton = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      display: 'flex',
      borderRadius: '1rem',
      alignSelf: 'stretch',
    },
    continueButton: {
      gap: '1rem',
      width: '100%',
      _focus: {
        outline: 'brand.focusOutline',
      },
    },
  },
})
