import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const BoundDetailsHelper = createMultiStyleConfigHelpers([
  'boundsDetails',
  'boundTypeText',
  'boundsContainer',
  'cabinCountText',
  'boundPriceText',
  'flightsIconAndText',
])

export const BoundDetails = BoundDetailsHelper.defineMultiStyleConfig({
  baseStyle: {
    boundsContainer: {
      display: 'flex',
      alignSelf: 'normal',
      alignItems: 'center',
      padding: {
        base: '0.625rem',
        xl: 0,
      },
    },
    boundsDetails: {
      display: {
        base: 'unset',
        xl: 'flex',
      },
      paddingLeft: '1rem',
    },
    boundTypeText: {
      width: '5.125rem',
      fontWeight: {
        md: 400,
      },
      fontSize: {
        base: '1rem',
        md: '1.125rem',
        xl: '0.875rem',
      },
    },
    flightsIconAndText: {
      display: 'flex',
      alignItems: 'center',
      padding: '1.25rem 1rem 1.25rem 0',
      borderRight: '1px solid',
      height: {
        base: 'unset',
        xl: '4.0625rem',
      },
      gap: {
        base: '0.25rem',
      },

      '& img': {
        minWidth: '1.5rem',
        minHeight: '1.5rem',
      },
    },
    cabinCountText: {
      fontWeight: 600,
      fontSize: {
        base: '1rem',
        md: '1.125rem',
        xl: '0.875rem',
      },
    },
  },
})
