import { css } from '@emotion/react'
import { getPublicAssetSrc } from '~/utils/assets'

const fontsDir = getPublicAssetSrc('/fonts')

const headingFontFace = css`
  @font-face {
    font-family: 'Bw Mitga';
    src: url('${fontsDir}/bw-mitga/BwMitga-Thin.woff2') format('woff2'),
      url('${fontsDir}/bw-mitga/BwMitga-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'Bw Mitga';
    src: url('${fontsDir}/bw-mitga/BwMitga-Light.woff2') format('woff2'),
      url('${fontsDir}/bw-mitga/BwMitga-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Bw Mitga';
    src: url('${fontsDir}/bw-mitga/BwMitga-Regular.woff2') format('woff2'),
      url('${fontsDir}/bw-mitga/BwMitga-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Bw Mitga';
    src: url('${fontsDir}/bw-mitga/BwMitga-Medium.woff2') format('woff2'),
      url('${fontsDir}/bw-mitga/BwMitga-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Bw Mitga';
    src: url('${fontsDir}/bw-mitga/BwMitga-Bold.woff2') format('woff2'),
      url('${fontsDir}/bw-mitga/BwMitga-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Bw Mitga';
    src: url('${fontsDir}/bw-mitga/BwMitga-ExtraBold.woff2') format('woff2'),
      url('${fontsDir}/bw-mitga/BwMitga-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'Bw Mitga';
    src: url('${fontsDir}/bw-mitga/BwMitga-Black.woff2') format('woff2'),
      url('${fontsDir}/bw-mitga/BwMitga-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
  }
`

const bodyFontFace = css`
  @font-face {
    font-family: 'Poppins';
    src: url('${fontsDir}/poppins/Poppins-Thin.woff2') format('woff2'),
      url('${fontsDir}/poppins/Poppins-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('${fontsDir}/poppins/Poppins-ExtraLight.woff2') format('woff2'),
      url('${fontsDir}/poppins/Poppins-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('${fontsDir}/poppins/Poppins-Light.woff2') format('woff2'),
      url('${fontsDir}/poppins/Poppins-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('${fontsDir}/poppins/Poppins-Regular.woff2') format('woff2'),
      url('${fontsDir}/poppins/Poppins-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('${fontsDir}/poppins/Poppins-Medium.woff2') format('woff2'),
      url('${fontsDir}/poppins/Poppins-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('${fontsDir}/poppins/Poppins-SemiBold.woff2') format('woff2'),
      url('${fontsDir}/poppins/Poppins-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('${fontsDir}/poppins/Poppins-Bold.woff2') format('woff2'),
      url('${fontsDir}/poppins/Poppins-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('${fontsDir}/poppins/Poppins-ExtraBold.woff2') format('woff2'),
      url('${fontsDir}/poppins/Poppins-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('${fontsDir}/poppins/Poppins-Black.woff2') format('woff2'),
      url('${fontsDir}/poppins/Poppins-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
  }
`

const aviosFontCss = css`
  ${headingFontFace}
  ${bodyFontFace}
`

export default aviosFontCss
