import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const FlightBreakdownHelper = createMultiStyleConfigHelpers([
  'flightBreakdown',
  'boundContainer',
  'boundAndPassengerCount',
  'boundsDetails',
  'boundTypeText',
  'boundPriceText',
  'boundPriceContainer',
  'passengerBoundPriceText',
  'hideElementOnTabletMobile',
  'showElementOnTabletMobile',
])

export const FlightBreakdown = FlightBreakdownHelper.defineMultiStyleConfig({
  baseStyle: {
    flightBreakdown: {
      alignSelf: 'normal',
      padding: {
        base: '0.5rem 0.625rem',
        xl: 0,
      },
      display: { base: 'none', xl: 'flex' },
      alignItems: { base: 'flex-start', xl: 'center' },
      flexDirection: {
        base: 'column',
        xl: 'row',
      },
    },
    boundContainer: {
      display: {
        base: 'unset',
        xl: 'flex',
      },
      gap: {
        base: '0.625rem',
        lg: '1.5rem',
      },
      padding: {
        xl: 'unset',
      },
    },
    flightsIconAndText: {
      display: 'flex',
      alignItems: 'center',
      padding: { base: '0.5rem 1rem 1.25rem 0', xl: '0.5rem 1rem 0.5rem 0' },
      borderRight: {
        xl: '1px solid',
      },
      height: {
        base: 'unset',
        xl: '4.0625rem',
      },
      gap: {
        base: '0.625rem',
      },

      '& img': {
        minWidth: '1.5rem',
        minHeight: '1.5rem',
      },
    },
    boundAndPassengerCount: {
      padding: '0rem 2rem',
      fontSize: '0.75rem',
      display: 'flex',
      flexDirection: {
        base: 'column',
        xl: 'row',
      },
      alignItems: {
        base: 'flex-start',
        xl: 'unset',
      },
      gap: {
        base: '0.6rem',
        xl: '1.5rem',
      },
    },
    boundPriceContainer: {
      display: 'flex',
      flexDirection: { base: 'row', xl: 'column' },
      gap: { base: '1.5rem', xl: 'unset' },
    },
    boundTypeText: {
      width: '5.125rem',
      fontWeight: {
        md: 400,
      },
      fontSize: {
        base: '1rem',
        md: '1.125rem',
        xl: '0.875rem',
      },
    },
    boundPriceText: {
      display: 'flex',
      gap: '0.25rem',
      alignItems: 'baseline',
      whiteSpace: 'nowrap',
      fontWeight: {
        base: 500,
        md: 400,
      },
      fontSize: {
        base: '0.75rem',
        md: '0.875rem',
        xl: '0.75rem',
      },
    },
    passengerBoundPriceText: {
      fontWeight: 600,
      fontSize: {
        base: '1rem',
        md: '1.125rem',
      },
    },
    hideElementOnTabletMobile: {
      display: {
        base: 'none',
        xl: 'unset',
      },
    },
    showElementOnTabletMobile: {
      display: {
        base: 'unset',
        xl: 'none',
      },
    },
  },
  variants: {
    mobile: {
      flightBreakdown: {
        display: {
          base: 'flex',
          xl: 'none',
        },
      },
    },
  },
})
