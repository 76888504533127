import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'container',
  'header',
  'heading',
  'tripContainer',
  'tripInfo',
  'tripTitle',
  'tripDetails',
  'roomList',
  'roomItem',
])

export const HotelRooms = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      scrollMarginTop: 14,
      outline: 'none',
      gridColumn: '2/3',
      position: 'relative',
      marginTop: 6,
      marginBottom: 6,
    },
    header: {
      flexDirection: { base: 'column', md: 'row' }, //  need to be checked if it is applied correctly
      justifyContent: 'space-between',
      paddingTop: 3,
    },
    heading: {
      marginTop: 0,
      marginBottom: 6, //  tokenize, need to be checked
    },
    tripContainer: {
      flexDirection: 'row',
      gap: 8,
      paddingBottom: 8,
    },
    tripInfo: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: { base: 'flex-start', md: 'flex-end' },
    },
    tripTitle: {
      fontWeight: 'medium',
      margin: 0,
    },
    roomList: {
      padding: '0',
    },
    roomItem: {
      '& + &': {
        marginTop: 6,
      },
    },
  },
})
