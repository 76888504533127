/* eslint-disable sonarjs/no-duplicate-string */
import { progressAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const progressHelpers = createMultiStyleConfigHelpers(progressAnatomy.keys)
export const progressBarHelpers = createMultiStyleConfigHelpers(['header', 'label', 'valueLabel'])

export const Progress = progressHelpers.defineMultiStyleConfig({
  baseStyle: progressHelpers.definePartsStyle({
    track: {
      width: 'full',
      borderRadius: 'base',
    },
  }),
})

export const ProgressBar = progressBarHelpers.defineMultiStyleConfig({
  baseStyle: progressBarHelpers.definePartsStyle({
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 0.5,
    },
    label: {
      textTransform: 'capitalize',
      fontSize: 'sm',
    },
    valueLabel: {
      fontSize: 'sm',
    },
  }),
})
