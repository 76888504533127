import { helpers } from './base'

export const ChatWidgetMock = helpers.defineMultiStyleConfig({
  baseStyle: {
    chatIcon: {
      path: {
        '&:first-of-type': {
          fill: 'brand.primary.blue',
        },
      },
      _hover: {
        path: {
          '&:first-of-type': {
            fill: 'brand.highlight.blue',
          },
        },
      },
    },
    popoverContent: {
      bg: '#E9F1FB',
    },
  },
})
