import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const SnackbarHelper = createMultiStyleConfigHelpers(['container'])

const brandBlack = 'brand.black'

export const Snackbar = SnackbarHelper.defineMultiStyleConfig({
  baseStyle: SnackbarHelper.definePartsStyle({
    container: {
      border: '1px solid',
      borderRadius: 8,
      boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.16)',
      columnGap: 4,
      display: 'grid',
      fontSize: 'sm',
      gridTemplateAreas: {
        base: `
          'icon title close'
          'icon description close'  
          'icon button close'`,
      },
      left: { base: 0, sm: 'unset' },
      right: { base: 'unset', sm: 1, md: 2 },
      margin: 2,
      maxWidth: { base: '100%', sm: 'lg' },
      p: 4,
      position: 'absolute',
    },
    title: {
      gridArea: 'title',
      fontWeight: 'medium',
      mb: 2,
    },
    description: {
      gridArea: 'description',
      fontWeight: 'normal',
    },
    icon: {
      mt: 2,
      mx: 2,
    },
    button: {
      gridArea: 'button',
      bg: 'transparent',
      w: 'min-content',
      mt: 2,
      p: 4,
      color: brandBlack,
      borderColor: brandBlack,
    },
    closeButton: {
      gridArea: 'close',
      alignSelf: 'start',
      bg: 'transparent',
      border: 'none',
      cursor: 'pointer',
      paddingRight: 0,
      paddingTop: 0,
      color: brandBlack,
    },
  }),
})
