import { helpers } from './base'

export const FlightTravellerInput = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    pickerContainer: {
      bgColor: 'brand.white',
    },
    maxTravellerBadge: {
      backgroundColor: 'brand.teal10',
    },
    doneButton: {
      background: 'brand.primaryTeal',
      color: 'brand.white',
    },
  }),
  variants: {
    error: {
      pickerContainer: {
        border: { base: 'unset', md: '1px solid' },
        borderColor: { base: 'unset', md: 'brand.red' },
      },
    },
  },
})
