import { helper } from './base'

export const Chip = helper.defineMultiStyleConfig({
  baseStyle: {
    chipWrapper: {
      borderRadius: 'sm',
      padding: 'var(--chakra-sizes-1) var(--chakra-sizes-5)',
      fontWeight: 'light',
      fontSize: 'xs',
    },
  },
  variants: {
    secondary: {
      chipWrapper: {
        color: 'brand.primary.midnightBlue',
        bgColor: 'brand.baseGray.10',
        lineHeight: '5',
        padding: '0.125rem 0.5rem',
      },
    },
    primary: {
      chipWrapper: {
        color: 'brand.white',
        bgColor: 'brand.primary.red',
      },
    },
  },
})
