import { ReactElement } from 'react'
import { Box, chakra, useMultiStyleConfig } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import TabTitle from './TabTitle'
import { TabsProps } from './Tabs.interface'
import useTabs from './useTabs'

const MotionBox = chakra(motion.ul)

function Tabs({ label, selectedTab, setSelectedTab, children }: TabsProps): ReactElement {
  const featuredChildren = children.filter(
    (child) => child.props.isVisible || child.props.isVisible === undefined
  )

  const { listRef, tabsRef, onKeyDown, tabContent, resetTab } = useTabs(
    featuredChildren,
    selectedTab,
    setSelectedTab
  )
  const styles = useMultiStyleConfig('Tabs', {})

  return (
    <Box __css={styles.tabWrap}>
      <MotionBox __css={styles.tabList} role="tablist" aria-label={label} ref={listRef}>
        {featuredChildren.length > 1 &&
          featuredChildren.map((item, index) => (
            <TabTitle
              key={item.props.slug}
              slug={item.props.slug}
              title={item.props.title}
              icon={item.props.icon}
              href={item.props.href}
              selectedTab={selectedTab}
              setSelectedTab={(slug) => {
                setSelectedTab(slug)
                resetTab(slug)
              }}
              variant={item.props.variant}
              onKeyDown={onKeyDown}
              ref={(el) => {
                if (el) {
                  tabsRef.current[index] = el
                }
              }}
            />
          ))}
      </MotionBox>
      {tabContent}
    </Box>
  )
}

export default Tabs
