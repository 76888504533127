import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers(['hotelExtraInformationWrapper', 'roomDetail'])

export const HotelAdditionalDetails = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    hotelExtraInformationWrapper: {
      '> div': {
        flexDirection: { base: 'column', md: 'row' },
        alignItems: { base: 'flex-start', md: 'center' },
        '> button': {
          display: 'flex',
          gap: 2,
          minWidth: 20,
          px: 2,
          height: 8,
        },
        '> div > section': {
          borderRadius: 'md',
          maxWidth: 'max-content',
          '> div': {
            padding: 0,
            '.chakra-popover__body': {
              mr: 0,
            },
          },
        },
      },
    },
    roomDetail: {
      display: 'flex',
      alignItems: 'center',
      px: 2,
      py: 2,
    },
  }),
})
