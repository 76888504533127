/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const HotelSummary = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      bg: 'bg.layer2.default',
      borderRadius: 'md',
      a: {
        textDecoration: 'none',
      },
    },
    heading: {
      mt: 'lg',
      fontSize: 'var(--alto-sem-text-heading-lg-font-size)',
      lineHeight: 'var(--alto-sem-text-heading-lg-line-height)',
      letterSpacing: 'var(--alto-sem-text-heading-lg-letter-spacing)',
      fontWeight: 'medium',
      color: 'fg.accent.primary',

      _empty: {
        borderRadius: '2xs',
        background: 'bg.layer1.default',
      },
    },
    address: {
      fontSize: 'var(--alto-sem-text-body-sm-font-size)',
      lineHeight: 'var(--alto-sem-text-body-sm-line-height)',
      letterSpacing: 'var(--alto-sem-text-body-sm-letter-spacing)',
    },
    expandButton: {
      height: '36px',
      px: 'sm',
      py: '2xs',
      borderRadius: '2xs',
    },
  },
})
