import { progressHelpers, progressBarHelpers } from './base'

export const Progress = progressHelpers.defineMultiStyleConfig({
  baseStyle: progressHelpers.definePartsStyle({
    track: {
      bg: 'brand.baseGray.25',
    },
    filledTrack: {
      bgColor: 'brand.primary.blue',
    },
  }),
})

export const ProgressBar = progressBarHelpers.defineMultiStyleConfig({
  baseStyle: progressBarHelpers.definePartsStyle({
    header: {
      fontWeight: 'normal',
      lineHeight: '5',
    },
    label: {
      fontSize: 'xs',
    },
    valueLabel: {
      fontSize: 'xs',
    },
  }),
})
