import { UnauthorizedException } from '@reward-platform/shared-api/exceptions/UnauthorizedException'
import type { AuthError } from '@auth0/nextjs-auth0'

export class NextAuth0Error extends UnauthorizedException {
  constructor(error: AuthError) {
    super({
      ...error,
      httpStatus: error.status,
      errorDescription: error.code,
      businessMessage: 'Something went wrong with authentication',
      developerMessage: error.message,
    })
  }
}
