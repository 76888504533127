import { differenceInDays, differenceInMinutes } from 'date-fns'
import { BasketItem, BasketItemType, isCarBasketItem, isFlightBasketItem } from '../basket'
import { ExperienceItemType, HotelItemType } from '../common'

/**
 * Defines the expiration hours for each basket item.
 * For example, if the start date of the hotel booking is within 28 hours (calculated from the
 * user's local time), we consider the hotel item expired and it will be removed from the basket.
 *
 * For any of the items we don't know the time zone of the start time so the calculation is not
 * really accurate.
 */
const basketItemExpirationHoursByType: Record<BasketItemType, number> = {
  HOTEL: 28,
  CAR_HIRE: 0,
  EXPERIENCE: 72,
  FLIGHT: 0,
}

const ONE_SECOND_IN_MS = 1000
const ONE_MINUTE_IN_MS = 60 * ONE_SECOND_IN_MS
const ONE_HOUR_IN_MS = 60 * ONE_MINUTE_IN_MS

const getLocalDateInUTCTimeZone = (dateStr: string) =>
  new Date(new Date(dateStr).getTime() + new Date().getTimezoneOffset() * ONE_MINUTE_IN_MS)

export const getIsItemExpired = (item: BasketItem) => {
  const now = new Date()

  const expirationPoint = new Date(
    now.getTime() + basketItemExpirationHoursByType[item.type] * ONE_HOUR_IN_MS
  )

  // For hotels and experiences we need to check if the day changes when we add expiration hours
  // to current local time
  if ([HotelItemType, ExperienceItemType].includes(item.type)) {
    const startDate = getLocalDateInUTCTimeZone(item.startDate)

    const dayDiff = differenceInDays(startDate, expirationPoint)
    return dayDiff < 0
  }

  // For car hires and flights we check if the start time is in the past when comparing the minutes
  // of the start time and the current local time.
  const getStartDate = () => {
    if (isCarBasketItem(item)) {
      return new Date(item.startDate)
    }
    if (isFlightBasketItem(item)) {
      return new Date(item.outboundFlightDetails.departureDateTime)
    }

    throw new Error(`Unknown item type ${item.type}`)
  }

  const startDate = getStartDate()
  const minutesDiff = differenceInMinutes(startDate, expirationPoint)
  return minutesDiff <= 0
}
