import { z } from 'zod'

export const ContentfulAssetFileSchema = z.object({
  url: z.string(),
  details: z.object({
    size: z.number(),
    image: z
      .object({
        width: z.number(),
        height: z.number(),
      })
      .optional(),
  }),
  fileName: z.string(),
  contentType: z.string(),
})

export type ContentfulAssetFile = z.infer<typeof ContentfulAssetFileSchema>

export const ContentfulAssetSchema = z.object({
  title: z.string().optional(),
  description: z.string().optional(),
  file: ContentfulAssetFileSchema.optional(),
})

export type ContentfulAsset = z.infer<typeof ContentfulAssetSchema>
