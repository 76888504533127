import { helpers } from './base'

const baseGray100 = 'brand.baseGray.100'

export const SelectBox = helpers.defineMultiStyleConfig({
  baseStyle: {
    selectBoxLabel: {
      color: `${baseGray100}`,
      fontSize: 'xs',
    },
    selectBoxSelect: {
      color: 'brand.primary.midnightBlue',
      fontSize: 'md',
      border: 'none',
      borderBottom: '1px',
      borderColor: `${baseGray100}`,
      borderRadius: '0',
      _focus: {
        border: 'none',
        borderBottom: '1px',
        borderColor: `${baseGray100}`,
      },
      _hover: {
        borderColor: 'gray.300',
      },
    },
    chevronColor: {
      color: 'brand.primary.midnightBlue',
    },
  },
  variants: {
    error: {
      selectBoxLabel: {
        color: 'red',
      },
      selectBoxSelect: {
        borderColor: 'red',
        boxShadow: '0 0 2px 0 red',
      },
    },
  },
})
