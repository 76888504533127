import { Box, Button, useMultiStyleConfig, Image } from '@chakra-ui/react'
import { useState } from 'react'
import { DismissibleModalProps } from './DismissibleModal.types'
import Modal from '../Modal/Modal'

const LOCAL_STORAGE_ONE_TIME_MODAL = 'show_dismissible_modal'

const DismissibleModal = ({
  primaryAction,
  secondaryAction,
  primaryActionLabel,
  secondaryActionLabel,
  imageUrl,
  children,
  title,
  shouldShowOnce = false,
  id = 'default',
}: DismissibleModalProps) => {
  const styles = useMultiStyleConfig('DismissibleModal')

  const [isOpen, setIsOpen] = useState(true)

  const showOnceStorageKey = `${LOCAL_STORAGE_ONE_TIME_MODAL}_${id}`

  const handleOneTimeClose = () => {
    if (shouldShowOnce) {
      localStorage.setItem(showOnceStorageKey, 'true')
    }
  }

  const handleClose = () => {
    handleOneTimeClose()
    setIsOpen(false)
  }

  const hasModalBeenShown = localStorage.getItem(showOnceStorageKey)

  if (!isOpen || (shouldShowOnce && hasModalBeenShown)) {
    return null
  }

  return (
    <Modal
      onClose={handleClose}
      title={title}
      isOpen={isOpen}
      titleTextAlign="left"
      data-testid="dismissible-modal"
    >
      <Box __css={styles.optionContainer} gridTemplateColumns={imageUrl ? undefined : 'auto'}>
        <Box __css={styles.wrapper} width="full">
          {children}
        </Box>
        {imageUrl ? (
          <Box>
            <Image alt="Dismissible modal image" src={imageUrl} __css={styles.image} />
          </Box>
        ) : null}
      </Box>
      <Box __css={styles.buttonsWrapper}>
        {secondaryActionLabel ? (
          <Box __css={styles.buttonContainer}>
            <Button
              variant="secondary"
              onClick={(e) => {
                e.stopPropagation()
                if (secondaryAction) {
                  handleOneTimeClose()
                  secondaryAction()
                } else {
                  handleClose()
                }
              }}
              data-testid="secondary-button"
              width="full"
            >
              {secondaryActionLabel}
            </Button>
          </Box>
        ) : null}
        {primaryActionLabel ? (
          <Box __css={styles.buttonContainer}>
            <Button
              variant="primary"
              onClick={(e) => {
                e.stopPropagation()
                if (primaryAction) {
                  handleOneTimeClose()
                  primaryAction()
                } else {
                  handleClose()
                }
              }}
              data-testid="primary-button"
              width="full"
            >
              {primaryActionLabel}
            </Button>
          </Box>
        ) : null}
      </Box>
    </Modal>
  )
}

export default DismissibleModal
