import { z } from 'zod'

export const VendorsEnum = z.nativeEnum({
  EXPEDIA: '1',
  VIATOR: '2',
  AVIS: '3',
  BUDGET: '4',
})

export type VendorType = z.infer<typeof VendorsEnum>
