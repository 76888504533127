import { Box, Icon, chakra, useMultiStyleConfig } from '@chakra-ui/react'
import { useComboboxOption } from './AutoComplete'
import { AutoCompleteItemAttributes } from './AutoComplete.types'
import { IconSizes } from '../../../theme/iconSize'
import { convertIconSetToChakraSVG } from '../../Icon/Icon.utils'
import { IconName } from '../../Icon/Icon.types'

export type LocationOptionProps = AutoCompleteItemAttributes & {
  key?: string
  title: string
  subtitle: string
  icon: IconName
}

export const AutoCompleteLocationOption = ({
  item,
  index,
}: {
  item: LocationOptionProps
  index: number
}) => {
  const option = useComboboxOption(item, index)
  const styles = useMultiStyleConfig('AutoCompleteLocationOption')

  return (
    <chakra.li
      __css={styles.locationOptionWrapper}
      {...option.props}
      data-highlighted={option.isHighlighted}
      data-testid={option.props.id}
      tabIndex={0}
    >
      <Box __css={styles.autoCompleteListIcon}>
        <Icon
          as={convertIconSetToChakraSVG(item.icon)}
          boxSize={IconSizes.md}
          color="brand.icon"
          className={`${item.icon}-icon`}
        />
      </Box>
      <Box paddingY={2}>
        <chakra.p __css={styles.autoCompleteListTitle}>{item.title}</chakra.p>
        <chakra.p __css={styles.autoCompleteListP}>{item.subtitle}</chakra.p>
      </Box>
    </chakra.li>
  )
}
