import {
  alDarkCityGreen,
  alPrimaryTeal,
  alWhite,
} from '@reward-platform/lift/theme/aer_lingus/variables'
import { helpers } from './base'

export const FlightPriceFooter = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    footerContainer: {
      color: alWhite,
      backgroundColor: alPrimaryTeal,
    },
    flightsContainer: {
      backgroundColor: alDarkCityGreen,
    },
    paymentOptionsCheckoutSummary: {
      backgroundColor: 'rgba(var(--chakra-colors-rgb-alPrimaryTeal), 0.3)',
    },
    flightTotalText: {
      borderRightColor: alWhite,
    },
  }),
})
