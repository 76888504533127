/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const CarAdditionalProductCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    wrapper: {
      border: 'none',
      '.chakra-card': {
        columnGap: 0,
      },
    },
    headingWrapper: {
      gridColumn: '1 / span 3',
    },
    heading: {
      color: 'fg.accent.primary',
      fontWeight: 'bold',
      fontSize: 'var(--alto-sem-text-heading-xs-font-size)',
      lineHeight: 'var(--alto-sem-text-heading-xs-line-height)',
    },
    headingIcon: {
      color: 'fg.accent.primary',
    },
    duration: {
      color: 'fg.primary',
      fontWeight: 'normal',
      fontSize: 'xs',
    },
    description: {
      color: 'fg.primary',
      fontWeight: 'normal',
      fontSize: 'sm',
      mr: '2xl',
    },
    button: {
      border: 'var(--alto-sem-border-width-sm) solid',
    },
    selected: {
      borderBottom: 'var(--alto-sem-border-width-md) solid var(--alto-sem-color-border-accent)',
    },
    confirmation: {
      fontSize: 'md',
      color: 'fg.primary',
    },
    iconWrapper: {
      bg: 'bg.success.vibrant.default',
    },
  },
})
