import { helpers } from './base'

const selectedItem = {
  background: 'brand.white',
  color: 'brand.highlight.blue',
}

export const ListBox = helpers.defineMultiStyleConfig({
  baseStyle: {
    icon: {
      color: 'brand.primary.midnightBlue',
    },
    list: {
      _focusVisible: {
        outline: '1px solid',
        outlineColor: 'brand.primary.blue',
      },
      border: 'none',
      borderRadius: '0.3125rem',
      boxShadow: '0 6px 9px 0 rgb(0 0 0 / 10%)',
    },
    item: {
      color: 'brand.primary.midnightBlue',
      fontWeight: 'light',
      _hover: selectedItem,
      _focus: selectedItem,
      _selected: selectedItem,
      _disabled: {
        color: 'brand.baseGray.50',
      },
    },
  },
})
