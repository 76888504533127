import { helpers } from './base'

const primary = 'fg.accent.primary'

export const PointsOfInterest = helpers.defineMultiStyleConfig({
  baseStyle: {
    bulletPoint: {
      color: primary,
    },
    list: {
      _before: {
        color: primary,
      },
      paddingLeft: 0,
    },
    listItem: {
      color: 'fg.secondary',
    },
    expandButton: {
      color: primary,
    },
  },
})
