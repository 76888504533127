import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers(['button', 'icon'])

export const ShareButton = helpers.defineMultiStyleConfig({
  baseStyle: {
    button: {
      zIndex: 2,
    },
    icon: {},
  },
  variants: {
    ghost: {
      button: {
        backgroundColor: 'brand.white',
      },
    },
  },
})
