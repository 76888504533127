import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'mainContainer',
  'listContainer',
  'title',
  'listItem',
  'list',
  'iconWrapper',
  'includeIcon',
  'excludeIcon',
])

export const FeaturesList = helpers.defineMultiStyleConfig({
  baseStyle: {
    listContainer: {
      flexDirection: {
        base: 'column',
        md: 'row',
      },
      display: 'flex',
    },
    listItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline',
    },
    list: {
      padding: 0,
      maxWidth: {
        base: 'unset',
        md: '50%',
      },
    },
    iconWrapper: {
      paddingX: 1,
      paddingY: 0,
      width: 4,
      display: 'flex',
      justifyContent: 'center',
      alignSelf: 'baseline',
    },
  },
})
