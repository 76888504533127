import { helpers } from './base'
import { iconToDataImage } from '../../Icon/Icon.utils'

const iconUrl = `url('${iconToDataImage('image', 'white')}')`

export const ImageGallery = helpers.defineMultiStyleConfig({
  baseStyle: {
    button: {
      _after: {
        backgroundImage: `${iconUrl}`,
        fontSize: 'xs',
      },
    },
  },
})
