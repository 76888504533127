import { Typography } from '../typography.types'
import { vuBlack, vuGrey6 } from './variables'

export const typography: Typography = {
  families: {
    heading: 'Nunito Sans, sans-serif',
    body: 'Nunito Sans, sans-serif',
  },
  sizes: {
    xsmall: '0.75rem',
    small: '0.875rem',
    medium: '1rem',
    large: '1.25rem',
    xlarge: '1.5rem',
    xxlarge: '2rem',
  },
  weights: {
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  headings: {
    h1: {
      size: '2.5rem',
      weight: 'bold',
      margin: 'xlarge',
    },
    h2: {
      size: '2rem',
      weight: 'bold',
      margin: 'large',
    },
    h3: {
      size: '1.5rem',
      weight: 'bold',
      margin: 'large',
    },
    h4: {
      size: '1.25rem',
      weight: 'bold',
      margin: 'medium',
    },
    h5: {
      size: '1rem',
      weight: 'bold',
      margin: 'medium',
    },
    h6: {
      weight: 'bold',
    },
  },
  lists: {
    ordered: {
      listStyleColor: vuGrey6,
    },
    unordered: {
      listStyleColor: vuGrey6,
    },
  },
  colors: {
    base: vuGrey6,
    accent: vuBlack,
    heading: vuGrey6,
    body: vuGrey6,
  },
}

export default typography
