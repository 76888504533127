import { ComponentMultiStyleConfig } from '@chakra-ui/react'

export const SpinnerOnPage: ComponentMultiStyleConfig = {
  parts: ['wrapper', 'title'],
  baseStyle: {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100vh',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      fontWeight: 500,
      marginBottom: 5,
    },
  },
}
