import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'basketItemSummaryWrapper',
  'heading',
  'content',
])

export const BasketItemSummary = helpers.defineMultiStyleConfig({
  baseStyle: {
    basketItemSummaryWrapper: {
      display: 'flex',
      flexDirection: 'column',
      padding: 4,
      bg: 'green',
      borderRadius: 1,
    },
    heading: {
      lineHeight: 'normal',
      fontSize: 'sm',
      fontWeight: 'medium',
      mt: 0,
    },
    content: {
      mt: 2,
      fontSize: 'sm',
    },
  },
  sizes: {
    md: {
      basketItemSummaryWrapper: {
        minHeight: 24,
      },
    },
  },
})
