import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'wrapper',
  'body',
  'icons',
  'heading',
  'date',
  'image',
  'content',
])
export const ReplaceExperienceItemCard = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    wrapper: {
      position: 'relative',
      width: 'full',
      border: 'none',
      borderRadius: 'lg',
      backgroundColor: 'white',
      flexGrow: 1,
    },
    icons: {
      my: 4,
      fontSize: 'sm',
      display: 'grid',
      gap: 3,
    },
    heading: {
      lineHeight: 'normal',
      fontSize: 'lg',
      marginBottom: 0,
      marginTop: { base: 0, xl: 1 },
      fontWeight: { base: 'medium', lg: 'semibold' },
    },
    date: {
      fontSize: 'sm',
      marginTop: 2,
    },
    body: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      flexGrow: 1,
      margin: { base: 4, xl: 6 },
    },
    image: {
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: '10rem',
      flexShrink: 0,
    },
    content: {
      flexDirection: 'column',
      width: 'full',
      borderRadius: 'inherit',
      color: 'black',
      overflow: 'hidden',
    },
  }),
})
