import React, { useContext } from 'react'
import { Box, Grid } from '@chakra-ui/react'
import NotificationMessage from '@reward-platform/lift/components/NotificationMessage/NotificationMessage'

import { PlatformError } from '~/utils/errors'
import { NotificationContext } from './NotificationContext'

export const NotificationBar: React.FC = () => {
  const context = useContext(NotificationContext)
  if (!context) {
    throw new PlatformError('NotificationBar must be used within a NotificationContext')
  }

  const { notifications, removeNotification } = context

  return (
    <Box position="sticky" zIndex="sticky" top={0}>
      <Grid role="alert" aria-atomic="true">
        {notifications.map((notification) => (
          <NotificationMessage
            key={notification.id}
            richText={notification.richText}
            errorType={notification.errorType}
            text={notification.message}
            buttonLabel={notification.buttonLabel}
            onButtonClick={notification.onButtonClick}
            onCloseClick={() => removeNotification(notification.id)}
          />
        ))}
      </Grid>
    </Box>
  )
}

export default NotificationBar
