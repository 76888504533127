import {
  EXPERIMENTS,
  ExperimentFlag,
  ExperimentMetricEvent,
  ExperimentFeatureFlagKey,
} from '@reward-platform/devcycle'
import { useDevCycleClient, useVariable } from '@devcycle/react-client-sdk'
import { isClient, isCypress, isProduction, isStorybook } from '~/utils/envChecks'

const getExperimentFlagDetails = (key: ExperimentFeatureFlagKey) => {
  return EXPERIMENTS.find((config) => config.key === key)
}

/**
 * Return an experiment flag object, with the enabled state and the flag value
 * @param experimentFlagKey experiment flag key
 * @returns { enabled: boolean; value: boolean }
 * */
export const useExperimentFlag = (
  experimentFlagKey: ExperimentFeatureFlagKey
): ExperimentFlag<boolean> => {
  const variable = useVariable(experimentFlagKey, false)
  const details = getExperimentFlagDetails(experimentFlagKey)

  if (!details) {
    throw new Error(
      `No experiment matching flag key: ${experimentFlagKey}. Make sure the corresponding entry has been added to the EXPERIMENTS config`
    )
  }

  // To test a/b experiment flag in Storybook, add the key string to the window.activeExperiments array
  if (isClient() && window.activeExperiments && !isProduction()) {
    return {
      ...details,
      enabled: true,
      value: window.activeExperiments.includes(experimentFlagKey),
    }
  }

  // ignore flags if running in Cypress
  if (isCypress()) {
    return { ...details, value: false }
  }

  return { ...details, enabled: !variable.isDefaulted, value: variable.value }
}

export const useExperimentTracking = (type: ExperimentMetricEvent) => {
  const dvcClient = useDevCycleClient()
  return () =>
    dvcClient.track({
      type,
      value: 1,
      date: Date.now(),
    })
}
