import { helpers } from './base'

export const BasketItemPaymentOptionsSelect = helpers.defineMultiStyleConfig({
  baseStyle: {
    andCollectAvios: {
      strong: {
        fontWeight: 'bold',
      },
    },
  },
})
