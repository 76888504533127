/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const NotificationMessage = helpers.defineMultiStyleConfig({
  baseStyle: {
    notificationWrapper: {
      borderRadius: 0,
      padding: 3.5,
    },
    iconWrapper: {
      padding: 3.5,
    },
    notificationText: {
      padding: 3.5,
    },
  },
  variants: {
    ERROR: {
      notificationWrapper: {
        backgroundColor: 'brand.secondary4Dark',
        color: 'brand.white',
      },
      iconWrapper: {
        '.chakra-icon': {
          color: 'brand.white',
        },
      },
    },
    INFO: {
      notificationWrapper: {
        backgroundColor: 'brand.secondary1Light',
        color: 'brand.secondary1',
      },
      iconWrapper: {
        '.chakra-icon': {
          color: 'brand.secondary1',
        },
      },
    },
    SUCCESS: {
      notificationWrapper: {
        backgroundColor: 'brand.secondary1',
        color: 'brand.white',
      },
      iconWrapper: {
        '.chakra-icon': {
          color: 'brand.white',
        },
      },
    },
    WARNING: {
      notificationWrapper: {
        backgroundColor: 'brand.secondary5',
        color: 'brand.white',
      },
      iconWrapper: {
        '.chakra-icon': {
          color: 'brand.white',
        },
      },
    },
  },
})
