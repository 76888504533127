import { Typography } from '../typography.types'
import { alBlack, alCoolGrey10, alPrimaryTeal } from './variables'

export const typography: Typography = {
  families: {
    heading: 'Diodrum, sans-serif',
    body: 'Diodrum, sans-serif',
  },
  sizes: {
    xsmall: '0.75rem',
    small: '0.875rem',
    medium: '1.125rem',
    large: '1.25rem',
    xlarge: '1.5rem',
    xxlarge: '2rem',
  },
  weights: {
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  headings: {
    h1: {
      size: '3rem',
      weight: 'bold',
      margin: 'xlarge',
    },
    h2: {
      size: '2.5rem',
      weight: 'bold',
      margin: 'large',
    },
    h3: {
      size: '2rem',
      weight: 'bold',
      margin: 'large',
    },
    h4: {
      size: '1.5rem',
      weight: 'bold',
      margin: 'large',
    },
    h5: {
      size: '1.1875rem',
      weight: 'bold',
      margin: 'medium',
    },
    h6: {
      size: '1rem',
      weight: 'bold',
      margin: 'medium',
    },
  },
  lists: {
    ordered: {
      listStyleColor: alPrimaryTeal,
    },
    unordered: {
      listStyleColor: alPrimaryTeal,
    },
  },
  colors: {
    base: alBlack,
    accent: alPrimaryTeal,
    heading: alBlack,
    body: alCoolGrey10,
  },
}

export default typography
