import { createIcon } from '@chakra-ui/react'
import { IconNameType } from '@reward-platform/ancillaries-schemas/common'

import { iconSet } from '@reward-platform/lift/components/Icon'

// NOTE: these functions are duplicated in libs/shared/ui/lift/src/components/Icon/Icon.utils.tsx
export const convertIconSetToChakraSVG = (iconName: IconNameType) => {
  const selectedIcon = iconSet.icons.find((icon) =>
    icon.properties.name.split(', ').includes(iconName)
  )

  const { height } = iconSet
  const width = selectedIcon?.icon.width || height

  const pathsMap = selectedIcon?.icon.paths.map((path) => {
    return <path key={path} d={path} fill="currentColor" />
  }, '')

  return createIcon({
    viewBox: `0 0 ${width} ${height}`,
    path: pathsMap,
    defaultProps: { 'aria-hidden': true },
  })
}

export declare const iconList: (iconSet: unknown) => string[]

export function iconToSVG(iconName: IconNameType) {
  const selectedIcon = iconSet.icons.find((icon) =>
    icon.properties.name.split(', ').includes(iconName)
  )
  const paths = selectedIcon?.icon.paths.reduce((svg, path) => {
    return `${svg}<path d="${path}" fill="white" />`
  }, '')
  const { height } = iconSet
  const width = selectedIcon?.icon.width || height
  return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}">${paths}</svg>`
}

export function iconToDataImage(iconName: IconNameType) {
  return `data:image/svg+xml;utf8,${iconToSVG(iconName)}`
}
