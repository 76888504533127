import { helpers } from './base'

export const HotelReviewBreakdown = helpers.defineMultiStyleConfig({
  baseStyle: {
    description: {
      lineHeight: { base: '5', lg: '6' },
      fontSize: { base: 'xs', lg: 'md' },
    },
  },
})
