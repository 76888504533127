import { helpers } from './base'

export const WarningMessage = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    wrapper: {
      border: '2px solid var(--chakra-colors-brand-red)',
      gap: 4,
      padding: 4,
      bgColor: 'brand.red5',
      color: 'brand.red',
    },
    content: {
      rowGap: 2,
    },
    title: {
      fontSize: 'sm',
    },
    body: {
      fontSize: 'sm',
      mt: 4,
    },
    iconColor: {
      color: 'brand.red',
    },
  }),
})
