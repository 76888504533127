import { helpers } from './base'

const midnightBlue = 'brand.primary.midnightBlue'

export const Banner = helpers.defineMultiStyleConfig({
  baseStyle: {
    anchorWrapper: {
      backgroundColor: 'brand.white',
    },

    anchorIcon: {
      color: midnightBlue,
    },

    anchorText: {
      color: 'brand.highlight.blue',
      fontWeight: 'semibold',
    },

    anchorIconText: {
      color: midnightBlue,
    },
  },
})
