import { Basket } from '@reward-platform/order-schemas/basket'
import { useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query'
import { useAuthContext } from '~/context/auth'
import { useCallback, useMemo } from 'react'
import { useUser } from '~/context/user'
import {
  addCarHireToBasket,
  addExperienceToBasket,
  addFlightToBasket,
  addHotelToBasket,
  clearItemsFromBasket,
  getBasket,
  removeItemFromBasket,
} from '~/services/basketService'

export const BASKET_KEY = 'basket'

type UseBasketReturnType = {
  basket: UseQueryResult<Basket>
  addCarHire: typeof addCarHireToBasket
  addHotel: typeof addHotelToBasket
  addExperience: typeof addExperienceToBasket
  addFlight: typeof addFlightToBasket
  removeItem: typeof removeItemFromBasket
  clearItems: typeof clearItemsFromBasket
}

type UseBasketParams = {
  isEnabled?: boolean
}

export const useBasketQuery = ({ isEnabled = true }: UseBasketParams) => {
  const { isAuthenticated } = useAuthContext()
  const { user } = useUser()
  return useQuery({
    queryKey: [BASKET_KEY],
    queryFn: () => getBasket(),
    staleTime: 0,
    refetchOnWindowFocus: true,
    refetchOnMount: false,
    enabled: isEnabled && !!isAuthenticated && !!user,
    meta: {
      notifyUser: true,
      errorMessage: 'Basket Error - could not get basket',
    },
  })
}

const useBasket = ({ isEnabled }: UseBasketParams = {}): UseBasketReturnType => {
  const queryClient = useQueryClient()

  const invalidateBasketQuery = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey: [BASKET_KEY],
      }),
    [queryClient]
  )

  const clearItems = useCallback(async () => {
    await clearItemsFromBasket()
    await invalidateBasketQuery()
  }, [invalidateBasketQuery])

  const basket = useBasketQuery({ isEnabled })

  return useMemo(
    () => ({
      basket,
      addCarHire: async (offer) => {
        await addCarHireToBasket(offer)
        await invalidateBasketQuery()
      },
      addHotel: async (offer) => {
        await addHotelToBasket(offer)
        await invalidateBasketQuery()
      },
      addExperience: async (offer) => {
        await addExperienceToBasket(offer)
        await invalidateBasketQuery()
      },
      addFlight: async (offer) => {
        await addFlightToBasket(offer)
        await invalidateBasketQuery()
      },
      clearItems,
      removeItem: async (recommendationId: string) => {
        await removeItemFromBasket(recommendationId)
        await invalidateBasketQuery()
      },
    }),
    [basket, clearItems, invalidateBasketQuery]
  )
}

export const useBasketItemsCount = (): number => {
  const {
    basket: { data: basket },
  } = useBasket()
  const items = basket?.items

  if (items) {
    return Object.values(items).reduce((sum, itemCategory) => sum + itemCategory.length, 0)
  }

  return 0
}

export default useBasket
