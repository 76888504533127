import { Button, Icon, useMultiStyleConfig, chakra, Box, Text, Flex } from '@chakra-ui/react'
import { RichText } from '..'
import { NotificationMessageProps, NotificationMessageType } from './NotificationMessage.types'
import { IconSizes } from '../../theme/iconSize'
import { convertIconSetToChakraSVG } from '../Icon/Icon.utils'
import { IconName } from '../Icon/Icon.types'

const IconType: Record<NotificationMessageType, IconName> = {
  ERROR: 'warning',
  INFO: 'info',
  SUCCESS: 'check-circle',
  WARNING: 'info',
}

const NotificationMessage = ({
  errorType,
  richText,
  text,
  buttonLabel,
  onButtonClick,
  onCloseClick,
}: NotificationMessageProps) => {
  const styles = useMultiStyleConfig('NotificationMessage', { variant: errorType })
  const closeIconColor = errorType === 'ERROR' ? 'brand.white' : 'brand.black'

  return (
    <chakra.article role="alert" __css={styles.notificationWrapper}>
      <Flex maxWidth="container.xl" w="full">
        <Box __css={styles.iconWrapper}>
          <Icon as={convertIconSetToChakraSVG(IconType[errorType])} boxSize={IconSizes.lg} />
        </Box>

        <Box __css={styles.notificationBody}>
          {richText ? (
            <RichText document={richText} />
          ) : (
            <Text __css={styles.notificationText}>{text}</Text>
          )}

          <Box __css={styles.notificationButton}>
            {buttonLabel && onButtonClick && (
              <Button variant="secondary" size="sm" onClick={onButtonClick}>
                {buttonLabel}
              </Button>
            )}
          </Box>
        </Box>

        <Button
          aria-label="Close"
          __css={styles.iconWrapper}
          sx={styles.closeIcon}
          onClick={onCloseClick}
        >
          <Icon
            as={convertIconSetToChakraSVG('cross')}
            boxSize={IconSizes.md}
            color={closeIconColor}
          />
        </Button>
      </Flex>
    </chakra.article>
  )
}

export default NotificationMessage
