import { helpers } from './base'

export const CarPriceBar = helpers.defineMultiStyleConfig({
  baseStyle: {
    box: {
      bgColor: 'brand.white',
    },
    subtotalWrapper: {
      bgColor: 'brand.coolgrey1',
    },
    subtotalTitle: {
      color: 'brand.black',
    },
    subtotalText: {
      color: 'brand.black',
    },
    paymentButton: {
      bgColor: 'brand.primaryTeal',
    },
    iconWrapper: {
      backgroundColor: 'brand.primaryShamrockGreen',
    },
  },
})
