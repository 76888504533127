import { format, isSameMonth, compareAsc, compareDesc, parseISO } from 'date-fns'
import { format as formatLocal } from 'date-fns-tz'
import { IntlShape } from 'react-intl'
import { Duration as DurationWithMinMax } from '@reward-platform/ancillaries-schemas/common'
import { ItineraryExperienceItem } from '~/components/orders/Itinerary/types'
import { LocalisationContextValue } from '../context/localisation/localisation.types'
import { getDateFnsLocaleByLocaleString } from './getDateFnsLocaleByLocaleString'
import { PlatformError } from './errors'

export const ONE_SECOND = 1000
export const ONE_MINUTE = ONE_SECOND * 60
export const ONE_HOUR = ONE_MINUTE * 60
export const ONE_DAY = ONE_HOUR * 24
export const TWO_DAYS = ONE_DAY * 2
export const tomorrowDate = () => new Date(Date.now() + ONE_DAY)

const DEFAULT_DATE_TIME_FORMAT = 'do MMMM, h:mma'

const LOCALISED_DATE_FORMAT = 'eee, MMM dd, yyyy'
const LOCALISED_DATE_TIME_FORMAT_12HR = 'eee, MMM dd, yyyy, hh:mm a'
const LOCALISED_DATE_TIME_FORMAT_24HR = 'eee, MMM dd, yyyy, HH:mm'
const LOCALISED_TIME_FORMAT = 'hh:mm a'

type DateString = Date | string | undefined
type DateFormatterFunc<D = DateString> = <T extends D>(
  rawDate?: T,
  dateFormat?: string,
  locale?: string
) => T extends undefined ? null : string
type LocalDateFormatterFunc<T = Date | string> = (
  date: T,
  localisation: LocalisationContextValue
) => string

export const formatDate = ((rawDate, dateFormat, locale) => {
  if (!rawDate) {
    return null
  }
  const formatString = dateFormat ?? DEFAULT_DATE_TIME_FORMAT
  const options = {
    locale: getDateFnsLocaleByLocaleString(locale),
  }
  return typeof rawDate === 'string'
    ? format(new Date(rawDate), formatString, options)
    : format(rawDate, formatString, options)
}) as DateFormatterFunc

export const formatLocalDate = ((rawDate, dateFormat, locale) => {
  if (!rawDate) {
    return null
  }
  return formatLocal(rawDate, dateFormat ?? DEFAULT_DATE_TIME_FORMAT, {
    locale: getDateFnsLocaleByLocaleString(locale),
  })
}) as DateFormatterFunc<Date>

export const formatLocalISODateString = ((rawDate, dateFormat, locale) => {
  if (!rawDate) {
    return null
  }
  const date = parseISO(rawDate)
  return formatLocalDate(date, dateFormat, locale)
}) as DateFormatterFunc<string>

const createLocalFormatterFunc =
  (dateFormat: string): LocalDateFormatterFunc =>
  (date, { locale }) =>
    typeof date === 'string'
      ? formatLocalISODateString(date, dateFormat, locale)
      : formatLocalDate(date, dateFormat, locale)

export const formatLocalisedLocalDate = createLocalFormatterFunc(LOCALISED_DATE_FORMAT)
export const formatLocalisedLocalDateTime = createLocalFormatterFunc(
  LOCALISED_DATE_TIME_FORMAT_12HR
)
export const formatLocalisedLocalDateTime24hr = createLocalFormatterFunc(
  LOCALISED_DATE_TIME_FORMAT_24HR
)
export const formatLocalisedLocalTime = createLocalFormatterFunc(LOCALISED_TIME_FORMAT)

export const formatLocalisedDateShort: LocalDateFormatterFunc<Date> = (date, { locale }) =>
  date.toLocaleDateString(locale, {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  })

interface Duration {
  days?: number
  hours?: number
  minutes?: number
  seconds?: number
}

const splitAmountToUnits = (amount: number, units: number[]) => {
  const { acc: splitted } = units
    .slice()
    .sort((a, b) => b - a)
    .reduce(
      ({ acc, amountLeft }, unit) => {
        return {
          acc: [...acc, Math.floor(amountLeft / unit)],
          amountLeft: amountLeft % unit,
        }
      },
      {
        acc: new Array<number>(),
        amountLeft: amount,
      }
    )

  return splitted
}

export const minutesToDuration = (minutes: number): Duration => {
  if (minutes <= 0) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    }
  }

  const min = 1
  const hourInMin = 60 * min
  const dayInMin = 24 * hourInMin

  const [days, hours, mins] = splitAmountToUnits(minutes, [dayInMin, hourInMin, min])

  return {
    days,
    hours,
    minutes: mins,
    seconds: 0,
  }
}

// Compares min and max duration if only minutes or hours returns number, else returns duration object
const compareMinMaxDuration = (minDuration: Duration, maxDuration: Duration) => {
  if (
    minDuration.hours !== 0 &&
    maxDuration.hours !== 0 &&
    minDuration.minutes !== 0 &&
    maxDuration.minutes !== 0
  ) {
    return minDuration
  }
  if (
    minDuration.hours !== 0 &&
    maxDuration.hours !== 0 &&
    minDuration.minutes === 0 &&
    maxDuration.minutes === 0 &&
    minDuration.hours !== undefined
  ) {
    return minDuration.hours
  }
  if (
    minDuration.hours === 0 &&
    maxDuration.hours === 0 &&
    minDuration.minutes !== 0 &&
    maxDuration.minutes !== 0 &&
    minDuration.minutes !== undefined
  ) {
    return minDuration.minutes
  }
  return minDuration
}

export const timePeriodToDuration = (start: Date, end: Date): Duration => {
  const diffMillis = end.getTime() - start.getTime()
  if (diffMillis <= 0) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    }
  }

  const secondMs = 1000
  const minuteMs = 60 * secondMs
  const hourMs = 60 * minuteMs
  const dayMs = 24 * hourMs

  const [days, hours, minutes, seconds] = splitAmountToUnits(diffMillis, [
    dayMs,
    hourMs,
    minuteMs,
    secondMs,
  ])

  return {
    days,
    hours,
    minutes,
    seconds,
  }
}

type FormatFunc = (intl: IntlShape, count: number | undefined) => string | false

const formatDays: FormatFunc = (intl, count) =>
  Boolean(count) &&
  intl.formatMessage(
    { id: 'days', defaultMessage: `{count} {count, plural, one {day} other {days}}` },
    { count }
  )
const formatHours: FormatFunc = (intl, count) =>
  Boolean(count) &&
  intl.formatMessage(
    { id: 'hours', defaultMessage: `{count} {count, plural, one {hour} other {hours}}` },
    { count }
  )
const formatMinutes: FormatFunc = (intl, count) =>
  Boolean(count) &&
  intl.formatMessage(
    { id: 'minutes', defaultMessage: `{count} {count, plural, one {minute} other {minutes}}` },
    { count }
  )

export const formatTime = (intl: IntlShape, dateTime: string) =>
  intl.formatTime(dateTime, {
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: 'h23',
  })

export const formatDuration = (intl: IntlShape, duration: Duration) =>
  [
    formatDays(intl, duration.days),
    formatHours(intl, duration.hours),
    formatMinutes(intl, duration.minutes),
  ]
    .filter((v) => v)
    .join(', ')

export const formatDurationWithMinMax = (intl: IntlShape, duration: DurationWithMinMax) => {
  if (duration?.minDuration !== undefined && duration.maxDuration !== undefined) {
    const minDurationObject = minutesToDuration(duration.minDuration)
    const maxDurationObject = minutesToDuration(duration.maxDuration)

    const minValue = compareMinMaxDuration(minDurationObject, maxDurationObject)
    const minDuration =
      typeof minValue === 'number' ? minValue : formatDuration(intl, minDurationObject)
    return `${minDuration} to ${formatDuration(intl, maxDurationObject)}`
  }
  if (duration?.duration) {
    return formatDuration(intl, minutesToDuration(duration.duration))
  }
  return duration?.freeText
}

export const formatMinutesToHours = (minutes: number): string => {
  const hours = Math.floor(minutes / 60)
  const mins = minutes % 60
  if (hours === 0) {
    return `${mins}m`
  }
  if (mins === 0) {
    return `${hours}h`
  }
  return `${hours}h ${mins}m`
}

export const dateArrayToDayString = (datesArr: Date[]) => {
  const regReplace = ' & $1'
  return datesArr
    .map((d) => format(d, 'do'))
    .join(', ')
    .replace(/, ([^,]*)$/, regReplace)
}

// Format dates to strings for intl display
export const formatDatesStringList = (datesArr: Date[]) => {
  if (!datesArr.length) {
    return ``
  }

  const month1: Date[] = []
  const month2: Date[] = []

  datesArr.forEach((dt, index, arr) => (isSameMonth(dt, arr[0]) ? month1 : month2).push(dt))

  if (!month2.length) {
    // For one date, display as 13th March
    if (datesArr.length === 1) {
      return `${format(datesArr[0], 'do MMMM')}`
    }
    // For two dates, display as 13th March & 15th March
    if (datesArr.length === 2) {
      return `${format(datesArr[0], 'do MMMM')} & ${format(datesArr[1], 'do MMMM')}`
    }
    // For multiple dates, display as 13, 14, 15 & 16 March
    return `${dateArrayToDayString(datesArr)} ${format(datesArr[0], 'MMMM')}`
  }

  /* For multiple dates in different months, display as 29, 30 & 31 March & 01, 02 & 03 April */
  return `${dateArrayToDayString(month1)} ${format(month1[0], 'MMMM')} & ${dateArrayToDayString(
    month2
  )} ${format(month2[0], 'MMMM')}`
}

export const compareDates = (date1?: Date, date2?: Date, order?: 'ASC' | 'DESC'): number => {
  const isDesc = order === 'DESC'
  if (!date1 && !date2) {
    return 0
  }
  if (!date1) {
    return isDesc ? 1 : -1
  }
  if (!date2) {
    return isDesc ? -1 : 1
  }

  return isDesc ? compareDesc(date1, date2) : compareAsc(date1, date2)
}

export const getMinDate = (dates: Array<Date | string>): Date =>
  new Date(Math.min(...dates.filter(Boolean).map((d) => new Date(d).getTime())))

export const setTimeToDate = (startDate: string, startTime?: string) => {
  if (!startTime) {
    return new Date(startDate)
  }

  const parsedDate = parseISO(startDate)
  const [hours, minutes] = startTime.split(':').map((x) => parseInt(x, 10))

  const dateTime = new Date(parsedDate)
  dateTime.setHours(hours)
  dateTime.setMinutes(minutes)

  return dateTime
}

// Adjust datetime if start date is beyond a threshold in hours, set time to user time
export const adjustDateObjectTimeToUserTime = (
  selectedDate: Date,
  thresholdInHours: number
): Date => {
  const currentDate = new Date(Date.now())

  if (!Number.isInteger(thresholdInHours) || thresholdInHours < 1) {
    throw new PlatformError('thresholdInHours should be positive integer')
  }

  // Create a new Date object with adjusted time for threshold
  const threshold = new Date(currentDate.getTime() + thresholdInHours * 60 * 60 * 1000)

  let adjustedDate = selectedDate

  // Check if time adjustment is needed
  if (selectedDate > threshold) {
    const year = selectedDate.getFullYear()
    const month = selectedDate.getMonth()
    const day = selectedDate.getDate()
    const hours = currentDate.getHours()
    const minutes = currentDate.getMinutes()
    const seconds = currentDate.getSeconds()
    const milliseconds = currentDate.getMilliseconds()

    // Create a new Date object with selected date and current time components
    adjustedDate = new Date(year, month, day, hours, minutes, seconds, milliseconds)
  }

  return adjustedDate
}

export const getExperienceDuration = (intl: IntlShape, experience: ItineraryExperienceItem) => {
  if (!experience) {
    return ''
  }

  if (
    'experienceType' in experience &&
    experience.experienceType === 'MULTI_DAY_TOUR' &&
    experience.days
  ) {
    return `${experience.days} days`
  }

  return formatDurationWithMinMax(intl, experience.duration ?? {}) || ''
}
