import { helpers } from './base'

const primaryTeal = 'brand.primaryTeal'

export const PointsOfInterest = helpers.defineMultiStyleConfig({
  baseStyle: {
    bulletPoint: {
      color: primaryTeal,
    },
    list: {
      _before: {
        color: primaryTeal,
      },
      paddingLeft: '0',
    },
    listItem: {
      color: 'brand.coolgrey10',
    },
    expandButton: {
      color: primaryTeal,
    },
  },
})
