import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'amenitiesList',
  'preformattedContent',
  'amenitiesSectionTitle',
  'amenitiesListItem',
  'amenitiesSection',
  'amenityWrapperListItem',
  'imageTags',
])

const space2 = 2

const listItemStyles = {
  paddingBottom: space2,
  paddingLeft: '5',
  breakInside: 'avoid-column',
  position: 'relative',

  '&::before': {
    content: '""',
    position: 'absolute',
    width: space2,
    height: space2,
    marginLeft: -4,
    marginTop: 2,
    borderRadius: '50%',
  },
}

export const AmenitiesList = helpers.defineMultiStyleConfig({
  baseStyle: {
    amenitiesList: {
      listStyle: 'none',
      columnCount: { base: 1, md: 2, lg: 3 },
      columnGap: '12',
      columnRule: '1px solid',
      columnRuleColor: 'var(--chakra-colors-brand-coolgrey2)',
      pb: 7,
    },
    amenitiesSectionTitle: {
      paddingLeft: '0',
      paddingTop: '8',
      paddingBottom: '4',
      position: 'relative',

      '&:first-of-type': {
        paddingTop: '0',
      },
    },
    amenitiesListItem: listItemStyles,
    imageTags: {
      marginTop: 4,
      listStyleType: 'none',
      display: 'flex',
      li: {
        '&:first-of-type': {
          paddingLeft: 0,
        },
        _before: {
          display: 'none',
        },
      },
    },
    amenitiesSection: {
      breakInside: 'avoid',
      '&:not(:first-child)': {
        paddingTop: '4',
      },
    },
    preformattedContent: {
      li: listItemStyles,
    },
  },
  variants: {
    information: {
      amenityWrapperListItem: {
        '&:nth-of-type(3)': {
          h5: {
            breakBefore: { lg: 'column' },
          },
        },
      },
    },
  },
})
