import { Box, chakra, Icon, Link, Skeleton, useMultiStyleConfig } from '@chakra-ui/react'
import NextLink from 'next/link'
import { pushEventToDataLayer } from '~/utils/googleTagManager'
import { convertIconSetToChakraSVG } from '~/utils/Icon.utils'
import { IconName } from '@reward-platform/ancillaries-schemas/common'
import { IconSizes } from '@reward-platform/lift/theme/iconSize'
import { HeaderBasketItem } from '~/components/shared/Header/HeaderBasketItem'
import { useAuthContext } from '~/context/auth'
import dynamic from 'next/dynamic'

const SharedHeader = dynamic(
  () => import('@channel/shared-header-footer').then((module) => module.Header),
  { loading: () => <Skeleton margin="0" width="100%" height="100%" />, ssr: false }
)

export type HeaderItemProps = {
  basketItemsCount: number
  isAviosTheme?: boolean
  isLoading?: boolean
  userDetails: {
    name: string
    aviosBalance: number
    membershipNumber: number
  }
  skipContentSelector: string
}

export const AviosHeader = ({
  basketItemsCount,
  isAviosTheme,
  isLoading,
  userDetails,
  skipContentSelector,
}: HeaderItemProps) => {
  const styles = useMultiStyleConfig('Header', {})
  const { isAuthenticated: userIsAuthenticated, handleLogin, handleLogout } = useAuthContext()

  return (
    <>
      <Box __css={{ zIndex: 90 }}>
        <SharedHeader
          auth={{
            loginProps: { onClick: () => handleLogin() },
            logoutProps: { onClick: () => handleLogout() },
          }}
          userDetails={userIsAuthenticated ? userDetails : undefined}
          isLoading={isLoading}
          skipTo={`#${skipContentSelector}`}
        />
      </Box>

      <Box __css={styles.aviosHeaderWrapper} role="navigation">
        <Box __css={styles.aviosHeader}>
          <Link
            href="/order-history"
            as={NextLink}
            tabIndex={0}
            onClick={() => {
              return pushEventToDataLayer('navigation', {
                nav_element: 'header navigation',
                nav_type: 'cta',
                nav_action: `header order history`,
                menu_level: `1`,
              })
            }}
          >
            <Box __css={styles.headerItem}>
              <chakra.span mr="2xs">Order history</chakra.span>
              <Icon as={convertIconSetToChakraSVG(IconName.Enum.history)} boxSize={IconSizes.sm} />
            </Box>
          </Link>

          <HeaderBasketItem basketItemsCount={basketItemsCount} isAviosTheme={isAviosTheme} />
        </Box>
      </Box>
    </>
  )
}
