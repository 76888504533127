import { helpers } from './base'

export const HotelCard = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    hotelCardWrapper: { borderRadius: 'sm' },
    hotelCardBadge: {
      backgroundColor: 'brand.primary.red',
      fontSize: 'var(--chakra-fontSizes-xs)',
      fontWeight: 'light',
      textTransform: 'capitalise',
    },
    duration: {
      color: 'brand.primary.midnightBlue',
      fontWeight: 'light',
      fontSize: { base: 'xs', md: 'sm' },
    },
    paymentOptionsHeading: {
      fontWeight: { base: 'light', md: 'semibold' },
      fontSize: { base: 'xs', md: 'sm' },
    },
    image: {
      borderRadius: 'sm',
    },
    heading: {
      fontSize: '1.5rem',
    },
  }),
})
