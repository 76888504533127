/* eslint-disable sonarjs/no-duplicate-string */
import { helper } from './base'

export const OrderListTable = helper.defineMultiStyleConfig({
  baseStyle: helper.definePartsStyle({
    orderListTableGrid: {
      bg: 'brand.white',
      hr: {
        borderColor: 'brand.white',
      },
      h3: {
        fontWeight: 'light',
      },
    },
    orderListTableItemGrid: {
      bg: 'brand.alpha.gray10',
    },
    accented: {
      fontWeight: 'light',
      color: 'brand.primary.blue',
      svg: {
        color: 'brand.primary.blue',
      },
    },
    lineBalance: {
      p: {
        fontWeight: 'normal',
      },
    },
    textBold: {
      fontWeight: 'normal',
    },
    lineDetails: {
      p: {
        fontWeight: 'normal',
      },
    },
    lighter: {
      p: {
        fontWeight: 'light',
      },
    },
  }),
})
