import { helpers } from './base'

const primaryColor = 'brand.primary.midnightBlue'

export const SectionNavigation = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    navLink: {
      color: primaryColor,
      _active: {
        color: primaryColor,
      },
    },
    navItem: {
      '& + &::before': {
        borderColor: 'brand.baseGray.10',
      },
    },

    navItemReviews: {
      padding: 'var(--chakra-space-2-5) 0 var(--chakra-space-2-5) var(--chakra-space-12)',
    },

    navLinkReviews: {
      color: 'brand.white',
      backgroundColor: 'brand.primary.blue',
      padding: 'var(--chakra-space-2-5) var(--chakra-space-10)',

      _active: {
        backgroundColor: primaryColor,
      },
    },
  }),
  variants: {
    experiment: {
      navLink: {
        fontWeight: 'semibold',

        _active: {
          color: 'brand.highlight.blue',
        },
      },
      navBar: {
        paddingLeft: 4,
        paddingRight: 4,
      },
    },
  },
})
