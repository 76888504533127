import { helpers } from './base'

export const TravelerQuantityPickerComboBox = helpers.defineMultiStyleConfig({
  baseStyle: {
    comboBoxContainer: {
      border: 'none',
      color: 'brand.primary.midnightBlue',
      fontWeight: 'medium',
    },
    travelerQuantityContainer: {
      button: {
        padding: 0,
        height: 'unset',
        border: 'none',
        _hover: {
          border: 'none',
        },
      },
    },
  },
})
