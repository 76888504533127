import { defineStyleConfig } from '@chakra-ui/react'

export const AtolEligible = defineStyleConfig({
  baseStyle: {
    display: 'flex',
    flexDirection: 'row',
    px: 4,
    py: 2,
    bgColor: 'blue',
    color: 'white',
    alignItems: 'center',
    columnGap: 4,
    fontSize: 'xs',
    maxWidth: '40rem',
    '> img': {
      maxWidth: '2rem',
    },
  },
})
