import { vuGrey6, vuGreyscale4 } from '../../../theme/vueling/variables'
import { helpers } from './base'

export const Combobox = helpers.defineMultiStyleConfig({
  baseStyle: {
    styledOption: {
      color: vuGrey6,

      '&.disabled': {
        color: vuGreyscale4,
      },
    },
    buttonField: {
      color: 'brand.orange',
    },
    comboBoxContainer: {
      border: 'solid 1px black',
      color: 'brand.grey6',
      fontWeight: 'medium',
    },
  },
})
