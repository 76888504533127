import { ComponentMultiStyleConfig } from '@chakra-ui/react'
import { merge } from 'lodash'
import { ImageCard as base } from './base'

export const ImageCard: ComponentMultiStyleConfig = merge({}, base, {
  baseStyle: {
    figure: {
      img: {
        borderRadius: 'lg',
      },
    },
    figcaption: {
      color: 'brand.coolgrey10',
    },
    heading: {
      fontSize: 'lg',
      fontWeight: 'semibold',
      marginTop: '1.5rem',
    },
    subHeading: {
      fontSize: 'lg',
      fontWeight: 'medium',
    },
    caption: {
      marginTop: '0.75rem',
    },
  },
})
