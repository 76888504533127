import { helpers } from './base'

export const Drawer = helpers.defineMultiStyleConfig({
  variants: {
    primary: {
      content: { backgroundColor: 'brand.cloudWhite' },
      closeButton: {
        border: 'none',

        _hover: {
          backgroundColor: 'brand.white',
        },
      },
    },
  },
})
