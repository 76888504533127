import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'chatButton',
  'closeButton',
  'chatIcon',
  'popoverContent',
])

export const ChatWidgetMock = helpers.defineMultiStyleConfig({
  baseStyle: {
    chatButton: {
      zIndex: 1000,
      isolation: 'isolate',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      border: 0,
      margin: 0,
      padding: '0 !important',
      userSelect: 'none',
      flex: '0 0 auto',
      borderRadius: '50%',
      overflow: 'visible',
      color: 'rgba(0, 0, 0, 0.54)',
      transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      animation: '400ms ease 0s 1 alternate none running animation-13j0eht',
      position: 'fixed',
      right: {
        base: '24px',
        md: '33px',
      },
      bottom: {
        base: '24px',
        md: '31px',
      },
      transform: 'none',
      _hover: {
        border: 'none',
      },
      _focus: {
        border: 'none',
      },
    },
    chatIcon: {
      width: '55px',
      height: '55px',

      path: {
        '&:last-of-type': {
          fill: 'rgb(242, 249, 252)',
        },
      },
    },
    closeButton: {
      _hover: {
        boxShadow: 'none',
      },
    },
    popoverContent: {
      borderRadius: 'lg',
      border: 'none',
      padding: 4,
      boxShadow: '0px 6px 9px 0px #0000001A',
      width: 'unset',
      userSelect: 'none',
      '> div': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '> div': {
          display: 'flex',
          alignItems: 'center',

          p: {
            fontSize: 'sm',
          },
          button: {
            margin: '0 var(--chakra-sizes-0.5)',
            fontSize: 'sm',
          },
        },
        '> button': {
          height: 'unset',
        },
      },
    },
  },
})
