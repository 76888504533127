import { UseComboboxProps } from 'downshift'
import { IconSizes } from '../../../theme/iconSize'
import { ElementStyles, VariantElementStyles } from '../../../theme/theme.types'
import { IconName } from '../../Icon/Icon.types'

export interface AutoCompleteItemAttributes {
  label: string
  value: string
}

export type AutoCompleteProps<
  TItem extends AutoCompleteItemAttributes = AutoCompleteItemAttributes
> = Omit<
  UseComboboxProps<TItem>,
  | 'inputValue'
  | 'defaultInputValue'
  | 'initialInputValue'
  | 'initialSelectedItem'
  | 'initialActiveIndex'
  | 'id'
  | 'labelId'
  | 'menuId'
  | 'toggleButtonId'
  | 'inputId'
  | 'getItemId'
> & {
  name: string
  label: string
  value?: string
  defaultValue?: string
  note?: string
  error?: string
  children?: React.ReactNode | undefined
  gridArea?: string
  onInputFocus?: (e: React.FocusEvent<HTMLInputElement, Element>, openMenu: () => void) => void
  disabled?: boolean | undefined
  helperMessage?: JSX.Element | undefined
}

export interface AutoCompleteContextValue {
  getOptionProps: (item: any) => any
  highlightedIndex: number
}

export interface AutoCompleteTheme {
  popup: ElementStyles
  option: VariantElementStyles<'highlighted' | 'separator'>
  iconColor: string
}

export const AUTOCOMPLETE_FIELD_ICON_PROPS = {
  icon: 'search' as IconName,
  iconSize: IconSizes.sm,
  iconColor: 'brand.inputIcon',
}
