import { Box, useMediaQuery } from '@chakra-ui/react'

import React, { useMemo } from 'react'
import { deviceSizes } from '../../theme/deviceSizes'

import { ChakraCustomListBox } from './ChakraCustomListBox'
import { ChakraSelect } from './ChakraSelect'
import { ChakraListBoxProps } from './common'

const getTabIndexes = (shouldUseMobileSelect: boolean) => ({
  listBox: shouldUseMobileSelect ? -1 : undefined,
  select: shouldUseMobileSelect ? undefined : -1,
})

export const ChakraListBox = React.forwardRef<HTMLInputElement, ChakraListBoxProps>(
  (props, ref) => {
    const [isTabletOrBigger] = useMediaQuery(`(min-width: ${deviceSizes.small})`)
    const [isHover] = useMediaQuery('(hover: hover)')

    const shouldUseMobileSelect = !isHover || !isTabletOrBigger
    const { listBox, select } = getTabIndexes(shouldUseMobileSelect)

    const ChakraSelectBox = useMemo(
      () => (
        <Box position="absolute" top={0} left={0} opacity={0} w="100%">
          <ChakraSelect {...props} tabIndex={select} />
        </Box>
      ),
      [props, select]
    )

    return (
      <Box position="relative" w="100%">
        {!shouldUseMobileSelect && ChakraSelectBox}
        <ChakraCustomListBox ref={ref} {...props} tabIndex={listBox} />
        {shouldUseMobileSelect && ChakraSelectBox}
      </Box>
    )
  }
)
