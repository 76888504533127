import { addHours, isToday } from 'date-fns'
import { Availability } from '../components/TimePicker/interface'

export const addHoursToTime = (time: string, hoursToAdd: number) => {
  const [hours, minutes] = time.split(':').map(Number)

  const totalMinutes = hours * 60 + minutes
  const newTotalMinutes = totalMinutes + hoursToAdd * 60

  const newHours = Math.floor(newTotalMinutes / 60)
  const newMinutes = newTotalMinutes % 60

  const formattedHours = newHours.toString().padStart(2, '0')
  const formattedMinutes = newMinutes.toString().padStart(2, '0')

  return `${formattedHours}:${formattedMinutes}`
}

export const getDateTime = (time: string, date: Date) => {
  const [hours, minutes] = time.split(':')
  return new Date(new Date(date).setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0))
}

const WEEK_DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

/**
 * Since the hours array can contain ending hours past midnight,
 * we can correct the array to contain hours just inside a 24h framework
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
const correctAvailableHours = (_availability: Availability[]): Availability[] => {
  const availability: Availability[] = _availability.map((x) => ({
    day: x.day,
    hours: [...x.hours],
  }))

  for (let index = 0; index < availability.length; index++) {
    const prevDayPickIndex = index - 1 >= 0 ? index - 1 : availability.length - 1
    const prev = availability[prevDayPickIndex]
    const current = availability[index]

    const afterMidnightEntry = prev?.hours.find((entry) => {
      const [start, end] = entry.split(' — ')
      return start < '24:00' && end >= '00:00' && end <= '06:00'
    })
    const currentDayIndex = WEEK_DAYS.indexOf(current?.day)
    const prevDayIndex = WEEK_DAYS.indexOf(prev?.day)
    const consecutiveDays =
      currentDayIndex - prevDayIndex === 1 ||
      (currentDayIndex === 0 && prevDayIndex === WEEK_DAYS.length - 1)

    if (afterMidnightEntry && consecutiveDays) {
      const [start, end] = afterMidnightEntry.split(' — ')
      const newInterval = `00:00 — ${end}`
      if (end && current.hours.indexOf(newInterval) === -1) {
        current.hours.push(newInterval)
      }
      const afterMidnightEntryIndex = prev.hours.indexOf(afterMidnightEntry)
      if (start !== '00:00') {
        prev.hours[afterMidnightEntryIndex] = `${start} — 24:00`
      }
    }
  }
  return availability
}

export const isBefore = (date1: Date, date2: Date) => {
  return date1.getTime() <= date2.getTime()
}

/**
 * Util function to check if a given time is between available hours.
 * If a minHour is provided, it will also check if the time is greater than the minHour.
 */
export const checkDisabledTime = (
  _locale: string,
  _chosenTime: string,
  _chosenDate?: Date,
  _availability?: Availability[],
  _minDateTime?: Date,
  _isLastAvailableRentalDay?: boolean
) => {
  if (!_chosenDate || !_availability) {
    return false
  }

  const availability = correctAvailableHours(_availability)

  const now = new Date()
  const chosenDayName = _chosenDate.toLocaleDateString(_locale, { weekday: 'long' })
  const chosenDay = availability.find((x) => x.day === chosenDayName)

  const chosenDateTime = getDateTime(_chosenTime, _chosenDate)

  const isInAvailableHours = !!chosenDay?.hours?.some((x) => {
    const [startTime, endTime] = x.split(' — ')

    if (!startTime || !endTime) {
      return false
    }

    const minDateTime = `${_minDateTime?.getHours()}:${_minDateTime?.getMinutes()}`
    const startDateTime = getDateTime(startTime, _chosenDate)
    const endDateTime = _isLastAvailableRentalDay
      ? getDateTime(minDateTime, _chosenDate)
      : getDateTime(endTime, _chosenDate)

    return isBefore(startDateTime, chosenDateTime) && isBefore(chosenDateTime, endDateTime)
  })

  let minDateTime
  if (_minDateTime) {
    // It must be at least 1 hour later than the minimum time
    minDateTime = addHours(new Date(_minDateTime), 1)
  }

  return !(
    isInAvailableHours &&
    (minDateTime ? isBefore(minDateTime, chosenDateTime) : true) &&
    (isToday(_chosenDate as Date) ? isBefore(now, chosenDateTime) : true)
  )
}
