import { helpers } from './base'

export const FlightsResults = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    backLinkContainer: {
      paddingY: 6,
      backgroundColor: 'transparent',
    },
  }),
})
