import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'bulletPoint',
  'list',
  'listItem',
  'expandButton',
])

export const PointsOfInterest = helpers.defineMultiStyleConfig({
  baseStyle: {
    bulletPoint: {
      color: 'black',
      width: '13px',
      margin: 'auto 0',
      marginRight: '1rem',
      top: '0',
      bottom: '0',
    },
    list: {
      _before: {
        content: '""',
        borderLeft: '1px solid',
        position: 'absolute',
        height: 'calc(100% - 0.5rem)',
        left: '6px',
      },
      position: 'relative',
    },
    listItem: {
      color: 'black',
      fontSize: 'large',
      fontWeight: 'medium',
      marginTop: 0,
      marginBottom: 7,
    },
    expandButton: {
      marginTop: 0,
      marginLeft: '-7px',
      fontSize: '18px',
      fontWeight: 'semibold',
      display: 'inline-flex',
      alignItems: 'center',
    },
  },
})
