/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const CancelOrderConfirmationWrapper = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    orderDetailsContainer: {
      background: 'bg.layer1.default',
    },
  }),
})
