import { helpers } from './base'

export const CarPriceBar = helpers.defineMultiStyleConfig({
  baseStyle: {
    box: {
      bgColor: 'brand.white',
    },
    subtotalWrapper: {
      bgColor: 'brand.greyscale3',
    },
    subtotalTitle: {
      color: 'brand.black',
    },
    paymentButton: {
      bgColor: 'brand.orange',
      _hover: {
        bgColor: 'brand.orange',
      },
    },
    paymentMethods: {
      'div > div > div:first-child': {
        bg: { base: 'none', md: 'brand.coolgrey2' },
      },
    },
  },
})
