import { ComponentStyleConfig } from '@chakra-ui/react'

export const Button: ComponentStyleConfig = {
  baseStyle: {
    border: 0,
    boxShadow: 'none',
    color: 'white',
    transition: 'all 0.2s ease',
    cursor: 'pointer',
    lineHeight: '21px',
    _hover: {
      boxShadow: '0 4px 4px -2px rgb(0 0 0 / 40%)',
    },
    _focus: {
      boxShadow: 'none',
    },
    _active: {
      boxShadow: 'none',
    },
    _visited: {
      bg: 'red',
    },
    _disabled: {
      pointerEvents: 'none',
    },
  },
  variants: {
    primary: {},
    secondary: {
      bg: 'white',
      color: 'red',
      _focus: {
        boxShadow: 'none',
      },
      _active: {
        color: 'white',
        bg: 'brown',
        boxShadow: 'none',
      },
      '&[data-status]': {
        py: 2,
      },
      '&[data-status="error"]': {
        bg: 'pink',
        color: 'red',
        borderColor: 'red',
        _active: {
          color: 'pink',
          bg: 'red',
        },
      },
      '&[data-status="success"]': {
        bg: 'lightgreen',
        color: 'green',
        borderColor: 'green',
        _active: {
          color: 'lightgreen',
          bg: 'green',
        },
      },
      '&[data-status="neutral"]': {
        bg: 'lightgray',
        color: 'gray',
        borderColor: 'gray',
        _active: {
          color: 'lightgray',
          bg: 'gray',
        },
      },
    },
    link: {
      bg: 'transparent',
      fontSize: '0.9rem',
      textDecoration: 'underline',
      _hover: {
        boxShadow: 'none',
      },
      _active: {
        bg: 'transparent',
        boxShadow: 'none',
      },
    },
  },
  sizes: {
    sm: {
      h: '1.875rem',
      paddingTop: 2.5,
      paddingBottom: 2.5,
      paddingInlineStart: 5,
      paddingInlineEnd: 5,
    },
    md: {
      h: 14,
      paddingTop: 2.5,
      paddingBottom: 2.5,
      paddingInlineStart: 5,
      paddingInlineEnd: 5,
    },
  },
  defaultProps: {
    variant: 'primary',
    size: 'md',
  },
}
