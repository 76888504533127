import { helpers } from './base'

export const BackTo = helpers.defineMultiStyleConfig({
  baseStyle: {
    link: {
      color: 'brand.primary.midnightBlue',
      fontSize: { base: 'xs', md: 'sm' },
      lineHeight: 'tall',
    },
  },
})
