/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const CancelOrderModal = helpers.defineMultiStyleConfig({
  baseStyle: {
    orderDetailsContainer: {
      p: {
        _first: {
          fontWeight: 'normal',
        },
        '> span:first-child': {
          fontWeight: 'semibold',
        },
        '> span:last-child': {
          fontWeight: 'medium',
        },

        _last: {
          fontSize: 'md',
        },
      },
    },
    label: {
      textTransform: 'none',
    },
    rowItem: {
      bg: 'bg.layer1.default',
      borderRadius: 'xs',
      color: 'fg.accent.secondary',
      fontWeight: 'normal',
    },
    breakdownWrapper: {
      bg: 'bg.layer1.default',
      borderRadius: 'xs',
      '> div > div > p': {
        color: 'fg.secondary',
        fontSize: 'md',
        fontWeight: 'normal',
      },
    },
    totalIncludingChargesWrapper: {
      '> p': {
        _first: {
          fontSize: 'var(--alto-sem-text-heading-xs-font-size)',
          fontWeight: 'semibold',
          color: 'fg.accent.secondary',
          pb: 'sm',
        },
        _last: {
          fontSize: 'sm',
        },
      },
      '> div > div': {
        p: 'lg',
      },
    },
  },
  variants: {
    heading: {
      rowItem: {
        '> div': {
          fontSize: 'md',
          fontWeight: 'normal',
        },
        '> div:first-child': {
          fontSize: 'var(--alto-sem-text-heading-xs-font-size)',
          p: {
            fontWeight: 'semibold',
          },
        },
        bg: 'none',
      },
    },
    rowAccented: {
      rowItem: {
        bg: 'bg.accent.subtle.default',
        color: 'fg.accent.primary',
        p: {
          fontWeight: 'semibold',
          fontSize: 'md',
        },
      },
    },
  },
})
