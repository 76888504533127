import { helper } from './base'

export const Chip = helper.defineMultiStyleConfig({
  variants: {
    primary: {
      chipWrapper: {
        color: 'brand.white',
        bgColor: 'brand.teal',
      },
    },
    secondary: {
      chipWrapper: {
        color: 'brand.black',
        bgColor: 'brand.teal10',
      },
    },
  },
})
