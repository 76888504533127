import { CurrencyCodes } from '@reward-platform/ancillaries-schemas/country-currency-codes'
import { ContentfulAmount, ContentfulPromotionComponents } from '@reward-platform/shared-schemas'
import { createCurrencyFormatter, getAmountForCurrencyCode } from '~/utils/formatAmount'
import {
  Promotion,
  PromotionData,
  PromotionProductMap,
  PromotionValidationFunction,
} from './PromotionContext.types'
import {
  isItemValidForBonusAviosPromotion,
  isItemValidForEnhancedEarnRatesPromotion,
} from './Promotion.validation'

export const getPromotionByData = (
  data?: PromotionData,
  contentList?: ContentfulPromotionComponents[]
): Promotion | undefined => {
  if (!data) {
    return undefined
  }
  const maybeContent = contentList?.find((item) => item.promotionId === data.id)
  if (maybeContent && 'promotionData' in maybeContent) {
    const { promotionData, ...content } = maybeContent
    return { data, content }
  }
  return { data, content: maybeContent }
}

export const mapPromotionsToProduct = (
  dataList?: PromotionData[],
  contentList?: ContentfulPromotionComponents[]
): PromotionProductMap => {
  const map: PromotionProductMap = {}
  dataList?.forEach((data) => {
    const { product } = data
    if (!map[product]) {
      map[product] = getPromotionByData(data, contentList)
    }
  })
  return map
}

export const getFormattedAmountFromCurrencyCode = (
  amounts: ContentfulAmount[] = [],
  currencyCode: CurrencyCodes = 'GBP',
  userLocale = 'en-GB'
): string | undefined => {
  const currencyAmount = getAmountForCurrencyCode(amounts, currencyCode, true)

  if (!currencyAmount) {
    return undefined
  }
  if (currencyAmount.currencyCode === '999') {
    return `${currencyAmount.amount} Avios`
  }

  const currencyFormatter = createCurrencyFormatter(currencyAmount.currencyCode, userLocale)

  return currencyFormatter.format(currencyAmount.amount)
}

// Promotion type dependant generators
export const createItemValidatorForPromotionType = (
  data: PromotionData
): PromotionValidationFunction => {
  const defaultFunction = () => true
  switch (data.type) {
    case 'BONUS_AVIOS':
      return (item, options) =>
        isItemValidForBonusAviosPromotion(item, data.minimumSpend, data.fulfilmentEndDate, options)
    case 'ENHANCED_EARN_RATE':
      return (item, options) =>
        isItemValidForEnhancedEarnRatesPromotion(item, data.fulfilmentEndDate, options)
    default:
      return defaultFunction
  }
}

export const createVariableMapForPromotionType = (
  data?: PromotionData,
  userCurrencyCode?: CurrencyCodes,
  userLocale?: string
) => {
  if (data?.type === 'BONUS_AVIOS') {
    return {
      promotionMinimumSpend: getFormattedAmountFromCurrencyCode(
        data?.minimumSpend,
        userCurrencyCode,
        userLocale
      ),
    }
  }
  return {}
}
