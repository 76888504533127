import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'container',
  'description',
  'dates',
  'submitBtn',
  'travellerRoomPicker',
  'autocompleteWithMobileModal',
  'twoColumns',
  'maxHeightUnset',
  'maxHeightLarge',
])

export const SearchHotelForm = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      display: 'grid',
      gap: { base: 4, lg: 6 },
      gridTemplate: {
        base: `'destination' auto
              'dates' auto
              'travellers' auto
              'search' auto
              / 1fr`,
        sm: `'destination destination destination travellers travellers travellers' auto
            'dates dates dates dates search search' auto
            / 1fr 1fr 1fr 1fr 1fr 1fr`,
        md: 'repeat(6, 1fr) repeat(2, 1fr)',
        lg: `'destination destination destination destination' minmax(3.5rem, auto)
        'dates dates travellers search' minmax(3.5rem, auto)
        / 1fr 1fr 1fr 1fr`,
      },
      '> *': {
        position: 'relative',
      },
    },
    destination: {
      borderRadius: 'lg',
      boxShadow: { base: 'none', md: '0 6px 9px 0 rgba(0, 0, 0, 0.1)' },
      padding: '6',
      color: 'rgba(99, 102, 106, 1)',
      cursor: 'default',
    },
    dates: {
      gridArea: {
        base: 'dates',
        md: '2 / 1 / 3 / 5',
        lg: 'dates',
      },
    },
    submitBtn: {
      height: 14,
      gridArea: {
        base: 'search',
        md: '2 / 5 / 3 / 7',
        lg: 'search',
      },
    },
    travellerRoomPicker: {
      gridArea: {
        base: 'travellers',
        md: '1 / 4 / 2 / 7',
        lg: 'travellers',
      },
    },
    autocompleteWithMobileModal: {
      gridArea: {
        base: 'destination',
        md: '1 / 1 / 2 / 4',
        lg: 'destination',
      },
    },
    twoColumns: {
      display: {
        base: 'flex',
        md: 'grid',
      },
      gridTemplateColumns: {
        base: 'unset',
        lg: '1fr 1fr',
      },
      flexDirection: {
        md: 'unset',
        base: 'column',
      },
      '> div': {
        margin: {
          base: 'unset',
          md: '0 var(--chakra-sizes-6)',
          lg: 'unset',
        },
      },
    },
    maxHeightUnset: {
      '#field-destination-menu': {
        maxHeight: 'unset',
      },
    },
    maxHeightLarge: {
      '#field-destination-menu': {
        maxHeight: 'lg',
      },
    },
  },
})
