/* eslint-disable sonarjs/no-duplicate-string */
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'container',
  'navigationButton',
  'gallery',
  'imageCounter',
  'leftButton',
  'rightButton',
  'navigationIcon',
])

export const Carousel = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      position: 'relative',
      width: '100%',
      height: '100%',
      outlineOffset: 2,
    },
    navigationButton: {
      position: 'absolute',
      margin: 2,
      border: 'none',
      borderRadius: '50%',
      padding: 0,
      cursor: 'pointer',
      _hover: {
        boxShadow: 'unset',
        filter: 'brightness(1.5)',
      },
    },
    gallery: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      display: 'grid',
      gridTemplateRows: '100%',
      gridAutoColumns: '100%',
      gridAutoFlow: 'column',
      overflow: 'scroll',
      scrollBehavior: 'smooth',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '::-webkit-scrollbar': {
        display: 'none',
      },
      scrollSnapType: 'x mandatory',
      '& > *': {
        scrollSnapAlign: 'start',
        scrollSnapStop: 'always',
        overflow: 'hidden',
      },
    },
    imageCounter: {
      color: 'white',
      position: 'absolute',
      bottom: '1.5rem',
      display: 'grid',
      placeItems: 'center',
      width: '100%',
      gridTemplate: `'a b .'`,
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    rightButton: {
      right: 0,
    },
    navigationIcon: {
      color: 'brand.white',
      boxSize: 3,
    },
  },
})
