import { ComponentSingleStyleConfig } from '@chakra-ui/react'

export const TextMarker: ComponentSingleStyleConfig = {
  baseStyle: {
    wrapper: {
      background: 'brand.yellow',
      color: 'brand.black',

      _before: {
        borderTopColor: 'brand.black',
      },

      _after: {
        borderTopColor: 'brand.yellow',
      },
    },
  },
}
