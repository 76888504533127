import { isLocal, isDevelopment, isTest, isStaging, isProduction } from './envChecks'

export const getSharedSpacesEnvironment = () => {
  enum Environment {
    PROD = 'master',
    STAGING = 'staging',
    DEV = 'development',
    TESTING = 'testing',
  }

  if (isLocal() || isDevelopment()) {
    return Environment.DEV
  }
  if (isTest()) {
    return Environment.TESTING
  }
  if (isStaging()) {
    return Environment.STAGING
  }
  if (isProduction()) {
    return Environment.PROD
  }
  return Environment.DEV
}
