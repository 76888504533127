import { formHelper } from './base'

const focusedLabelStyles = {
  color: 'brand.coastalJetty',
}

const focusedInputStyles = {
  borderColor: 'brand.coastalJetty',
  outline: 'none',
  boxShadow: '0 0 2px var(--chakra-colors-brand-coastalJetty)',
}

const errorStyle = {
  color: 'error.primary',
}

export const Form = formHelper.defineMultiStyleConfig({
  variants: {
    floating: {
      container: {
        _focusWithin: {
          label: {
            ...focusedLabelStyles,
          },
        },
        '.flex-microform-focused ~ label': {
          ...focusedLabelStyles,
        },
        label: {
          color: 'brand.coolgrey10',
          fontWeight: 'normal',
          span: {
            color: 'brand.coolgrey10',
          },
        },
        'input, select, textarea, .flex-microform, button': {
          border: 'var(--chakra-borders-1px)',
          borderColor: 'brand.coolgrey2',
          _invalid: {
            '~label': {
              ...errorStyle,
            },
            boxShadow: '0 0 2px var(--chakra-colors-error-primary)',
            '~ div svg': {
              ...errorStyle,
            },
            _focus: {
              boxShadow: '0 0 2px var(--chakra-colors-brand-black)',
              borderColor: 'brand.black',
              '~label[data-invalid]': {
                ...errorStyle,
              },
            },
          },
          _hover: {
            borderColor: 'brand.coolgrey8',
            '~ label': {
              color: 'brand.coolgrey8',
            },
          },
          _focus: {
            ...focusedInputStyles,
          },
          '&.flex-microform-focused': {
            ...focusedInputStyles,
          },
          _disabled: {
            opacity: 1, // chakra makes 0.4 opaque when disabled
            borderColor: 'rgba(var(--chakra-colors-rgb-alCoolGrey2), 0.5)',
            color: 'rgba(var(--chakra-colors-rgb-alBlack), 0.6)',
            background: 'var(--chakra-colors-rgb-alCoolGrey2)',
            '~label': {
              color: 'rgba(var(--chakra-colors-rgb-alCoolGrey10), 0.75)',
            },
          },
        },
        'label[data-invalid], label[data-invalid] span, .flex-microform-invalid ~ label, .flex-microform-field-invalid ~ label, .flex-microform-field-invalid ~ label span':
          {
            ...errorStyle,
          },
        '.flex-microform, input, input[type="button"], select, textarea, button': {
          height: '58px',
        },
      },
      inputContainer: {
        '.fake-focus': {
          ...focusedInputStyles,
        },
      },
      errorIcon: {
        svg: {
          ...errorStyle,
          display: 'block',
        },
      },
    },
  },
})
