import { helpers } from './base'

export const LocationDetailsModalWrapper = helpers.defineMultiStyleConfig({
  baseStyle: {
    locationDetails: {
      h1: {
        fontSize: {
          md: 'var(--alto-sem-text-heading-lg-font-size)',
          base: 'var(--alto-sem-text-heading-md-font-size)',
        },
        lineHeight: {
          md: 'var(--alto-sem-text-heading-lg-line-height)',
          base: 'var(--alto-sem-text-heading-md-line-height)',
        },
        fontWeight: 'medium',
      },
      h2: {
        fontSize: {
          md: 'var(--alto-sem-text-heading-md-font-size)',
          base: 'var(--alto-sem-text-heading-sm-font-size)',
        },
        lineHeight: {
          md: 'var(--alto-sem-text-heading-md-line-height)',
          base: 'var(--alto-sem-text-heading-sm-line-height)',
        },
      },
      h3: {
        fontSize: {
          md: 'var(--alto-sem-text-heading-sm-font-size)',
          base: 'var(--alto-sem-text-heading-xs-font-size)',
        },
        lineHeight: {
          md: 'var(--alto-sem-text-heading-sm-line-height)',
          base: 'var(--alto-sem-text-heading-xs-line-height)',
        },
      },
      'p, address, th, td': {
        color: 'fg.secondary',
        fontWeight: 'normal',
        fontSize: 'md',
      },
      'h1, h2, h3': {
        mb: 'sm',
      },
    },
  },
})
