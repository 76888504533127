import { helpers } from './base'

export const TravelerInformationForm = helpers.defineMultiStyleConfig({
  baseStyle: {
    fillInstructions: {
      fontSize: 'md',
      fontWeight: 'medium',
      mb: 'lg',
    },
  },
})
