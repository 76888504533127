import { helpers } from './base'

const orange = 'brand.orange'
const grey = 'brand.grey'

export const RouteInfo = helpers.defineMultiStyleConfig({
  baseStyle: {
    bulletPoint: {
      color: orange,
    },
    title: {
      color: grey,
      bg: orange,
    },
    infoTitle: {
      color: grey,
    },
    infoText: {
      color: grey,
    },
    contentTitle: {
      color: 'brand.black',
    },
    contentBody: {
      color: grey,
    },
  },
})
