/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

const secondary1 = 'var(--chakra-colors-brand-secondary1)'

export const DayList = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      color: 'brand.grey6',
    },
    solidLine: {
      backgroundColor: 'brand.secondary1',
    },
    dashedLine: {
      backgroundImage: `repeating-linear-gradient(0deg, transparent 0 4px, ${secondary1} 4px 12px)`,
    },
    circleIcon: {
      backgroundColor: 'brand.secondary1',
      color: 'brand.white',
    },
    location: {
      color: 'brand.grey6',
    },
    description: {
      color: 'brand.grey6',
    },
  },
})
