import { ChakraSelectField } from '../ChakraField'
import { ChakraListBoxProps, defaultIconProps } from './common'

export const ChakraSelect = ({
  name,
  children: options,
  error,
  label,
  onChange,
  note,
  value,
  isDisabled,
  tabIndex,
  autoComplete,
}: Omit<ChakraListBoxProps, 'displayValue'>) => {
  const itemToString = (v: string | null) => v ?? ''

  return (
    <ChakraSelectField
      isDisabled={isDisabled}
      name={name}
      label={label?.toString()}
      note={note}
      error={error}
      value={itemToString(value ?? null)}
      data-selected={Boolean(value)}
      aria-invalid={Boolean(error)}
      onChange={(e) => onChange(e.target.value)}
      tabIndex={tabIndex}
      autoComplete={autoComplete}
      {...defaultIconProps}
    >
      <option style={{ display: 'none' }} aria-hidden disabled value="" />
      {options.map((o) => (
        <option key={o.value} value={o.value} disabled={o.disabled}>
          {o.label}
        </option>
      ))}
    </ChakraSelectField>
  )
}
