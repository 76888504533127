import { helpers } from './base'

export const CarAdditionalProductCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    heading: {
      color: 'brand.black',
      fontWeight: 600,
      fontSize: 'xl',
    },
    duration: {
      color: 'brand.coolgrey10',
      fontWeight: 400,
      fontSize: 'xs',
    },
    description: {
      color: 'brand.coolgrey10',
      fontWeight: 400,
      fontSize: 'md',
    },
  },
})
