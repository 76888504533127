import { helpers } from './base'

export const HotelNearbyAttractions = helpers.defineMultiStyleConfig({
  baseStyle: {
    placeName: {
      fontSize: 'var(--alto-sem-text-body-sm-font-size)',
    },
    placeDistance: {
      fontSize: 'var(--alto-sem-text-body-xs-font-size)',
    },
  },
})
