import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'listItem',
  'locationGroup',
  'secondaryHeader',
  'mainTitle',
  'pointsContainer',
  'icon',
  'mainContainer',
])

export const MeetingPoints = helpers.defineMultiStyleConfig({
  baseStyle: {
    listItem: {
      marginTop: 3,
      display: 'flex',
      gap: 2,
    },
    listItemBox: {
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
    },
    locationGroup: {
      marginTop: 6,
    },
    pointsContainer: {
      display: 'flex',
      flexDirection: { base: 'column', md: 'row' },
      gap: 4,
    },
  },
})
