import { helpers } from './base'

export const SearchFieldSwapButton = helpers.defineMultiStyleConfig({
  baseStyle: {
    swapButton: {
      border: 'var(--alto-sem-border-width-sm) solid',
      borderColor: 'transparent',
      bgColor: 'bg.accent.subtle.default',
      borderRadius: 'md',
      '.chakra-icon': {
        color: 'fg.accent.primary',
      },

      _hover: {
        bgColor: 'bg.accent.subtle.hover',
      },

      _active: {
        bgColor: 'bg.accent.subtle.active',
      },

      _focus: {
        borderColor: 'border.accent',
        bgColor: 'bg.accent.subtle.hover',
      },

      _disabled: {
        borderColor: 'transparent',
        bgColor: 'bg.disabled.subtle',
        '.chakra-icon': {
          color: 'fg.disabled.primary',
        },
      },
    },
  },
})
