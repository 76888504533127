import { AlertHelper } from './base'

const midnightBlue = 'var(--chakra-colors-brand-primary-midnightBlue)'
const primaryBlue = 'var(--chakra-colors-brand-primary-blue)'
const baseGray10 = 'var(--chakra-colors-brand-baseGray-10)'
const baseGray5 = 'var(--chakra-colors-brand-baseGray-5)'
const alertPink = 'var(--chakra-colors-brand-alertPink)'

export const Alert = AlertHelper.defineMultiStyleConfig({
  baseStyle: AlertHelper.definePartsStyle({
    container: {
      borderRadius: 0,
      paddingInline: 6,
      '&[data-status="info"]': {
        border: 0,
        borderLeft: '4px solid',
        borderLeftColor: primaryBlue,
        bgColor: baseGray10,
      },
      '&[data-status="error"]': {
        bgColor: baseGray5,
        borderColor: alertPink,
      },
    },
    title: {
      fontSize: { base: 'md', sm: 'xl' },
      '&[data-status="info"]': {
        color: midnightBlue,
      },
      '&[data-status="error"]': {
        color: alertPink,
      },
    },
    description: {
      '&[data-status="info"]': {
        color: midnightBlue,
      },
      '&[data-status="error"]': {
        color: alertPink,
      },
    },
    icon: {
      alignSelf: 'start',
      '&[data-status="info"]': {
        color: midnightBlue,
      },
      '&[data-status="error"]': {
        color: alertPink,
      },
    },
    button: {},
  }),
  variants: {
    borderless: {
      container: {
        '&[data-status]': {
          border: 0,
          borderLeft: '4px solid',
          borderLeftColor: primaryBlue,
        },
      },
    },
    promo: AlertHelper.definePartsStyle({
      container: {
        '&[data-status]': {
          borderColor: 'brand.highlight.blue',
          bgColor: 'brand.alpha.blue',
          borderWidth: 1,
          borderRadius: 'base',
        },
      },
      description: {
        fontWeight: 'semibold',
        fontSize: 'md',
        lineHeight: 1.2,
      },
      icon: {
        '&[data-status="neutral"]': { color: 'brand.highlight.blue' },
      },
    }),
  },
})
