import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { iconToDataImage } from '../../Icon/Icon.utils'
import { skeletonFade } from '../../SkeletonCard/themes/base'

const iconUrl = `url('${iconToDataImage('image')}')`

export const helpers = createMultiStyleConfigHelpers(['container', 'image', 'button'])

export const ImageGallery = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      display: 'grid',
      gridGap: '1.5rem',
      gridTemplateColumns: 'repeat(12, 1fr)',
      gridTemplateRows: 'repeat(2, 225px)',
      gridAutoRows: '165px',
      outlineOffset: 2,

      '& > :not(:first-of-type)': {
        display: {
          base: 'none',
          sm: 'initial',
        },
      },

      '& > :nth-of-type(n + 4)': {
        display: {
          base: 'none',
          lg: 'initial',
        },
        gridColumnEnd: 'span 3',
      },

      '& > :first-of-type': {
        gridRowEnd: 'span 2',
      },

      '& > :nth-of-type(2)': {
        gridColumnEnd: 'span 4',
      },

      '& > :nth-of-type(3)': {
        gridColumnEnd: 'span 4',
      },
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    button: {
      border: '0',
      padding: '0',
      position: 'relative',
      borderRadius: '4px',
      overflow: 'hidden',
      cursor: 'pointer',
      outlineOffset: 2,

      _empty: {
        backgroundColor: '#eee',
        borderRadius: '3px',
        cursor: 'default',
        animation: `${skeletonFade} 2s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955)`,
      },

      _before: {
        content: {
          base: 'none',
          sm: "''",
        },
        position: 'absolute',
        inset: '0',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        zIndex: 1,
      },

      _after: {
        content: {
          base: 'none',
          sm: 'attr(data-remaining-images)',
        },
        position: 'absolute',
        top: '50%',
        left: '50%',
        paddingLeft: '1.5rem',
        backgroundImage: `${iconUrl}`,
        backgroundSize: '1rem',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center left',
        color: 'white',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
      },

      '&[disabled]': {
        _before: {
          display: 'none',
        },

        _after: {
          display: 'none',
        },
      },

      '&:not(:nth-of-type(3))': {
        _before: {
          content: {
            lg: "''",
            base: 'none',
          },
        },
        _after: {
          content: {
            base: 'none',
            lg: 'attr(data-remaining-images)',
          },
        },
      },

      '&:not(:nth-of-type(7))': {
        _before: {
          opacity: {
            base: 'initial',
            lg: '0',
          },
          visibility: {
            base: 'initial',
            lg: 'hidden',
          },
        },
        _after: {
          opacity: {
            base: 'initial',
            lg: '0',
          },
          visibility: {
            base: 'initial',
            lg: 'hidden',
          },
        },
      },
    },
  },
})
