import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers(['swapButton'])

export const SearchFieldSwapButton = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    swapButton: {
      p: 4,
      alignSelf: 'center',
      border: 'none',
      w: { base: 'full', md: 'auto' },
    },
  }),
})
