import { helpers } from './base'

export const IntroSection = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      gridTemplateColumns: { md: '1.1fr 1fr' },
    },
    content: {
      borderRadius: 'sm',
      boxShadow: 'brand.boxShadow',
      opacity: 1,
      backgroundColor: 'brand.white',
      padding: 6,
      marginBottom: { base: 4, md: 0 },
      p: {
        fontWeight: 'normal',
        fontSize: 'lg',
        lineHeight: 'base',
      },
      h2: {
        fontSize: '5xl',
        marginBottom: 4,
        fontWeight: 'light',
      },
      ol: {
        fontSize: 'md',
        li: {
          paddingLeft: { base: 0, lg: 3 },
        },
      },
    },
    scroller: {
      gridGap: { sm: '1rem' },
      overflow: 'visible',
      '> *:first-child': {
        paddingLeft: 0,
      },

      '> *:last-child': {
        paddingRight: 0,
      },
      '> *': {
        maxWidth: '100%',
        marginBottom: { base: 4, md: 0 },
        opacity: 1,
        width: '100%',
      },
    },
  },
})
