/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const FlightCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    flightCardContainer: {
      border: 'none',
      borderRadius: 'lg',
      boxShadow: 'red',
      backgroundColor: 'red',
    },
    offPeak: {
      borderTopLeftRadius: 'base',
      borderTopRightRadius: 'base',
      backgroundColor: 'red',
      color: 'red',
      fontSize: 'xs',
    },
    flightInfoContainer: {
      color: 'red',
      fontSize: 'xs',
    },
    pricesContainer: {
      color: 'red',

      '> *': {
        borderColor: 'red',
      },
    },
    topCardWrapper: {
      fontSize: '2xl',
    },
    departureTime: {
      color: 'red',
      fontWeight: 'medium',
    },
    arrowLine: {
      backgroundColor: 'red',
    },
    arrowPointer: {
      backgroundColor: 'red',
    },
    duration: {
      fontSize: 'xs',
    },
    arrivalTime: {
      color: 'red',
      fontWeight: 'medium',
    },
  },
})
