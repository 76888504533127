import { helpers } from './base'

const unselectedBorderColor = '1px solid var(--chakra-colors-brand-coolgrey2)'
export const RoomRateSelector = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    optionIndicator: {
      backgroundColor: 'brand.orange',
      color: 'brand.black',
    },
    soldOutOptionIndicator: {
      backgroundColor: 'brand.coolgrey2',
      color: 'brand.black',
    },
    nonRefundable: {
      color: 'brand.red',
    },
    partiallyRefundable: {
      color: 'brand.orange',
    },
    themedSemiBold: {
      color: 'brand.grey6',
    },
    optionContainerSelected: {
      border: '1px solid var(--chakra-colors-brand-orange)',
      _disabled: {
        border: unselectedBorderColor,
      },
    },
    optionContainerUnselected: {
      border: unselectedBorderColor,
      _disabled: {
        border: unselectedBorderColor,
      },
    },
  }),
})
