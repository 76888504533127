/* eslint-disable sonarjs/no-duplicate-string */
import { basketTravellersLabelHelper } from './base'

export const BasketTravellersLabel = basketTravellersLabelHelper.defineMultiStyleConfig({
  baseStyle: {
    wrapper: {
      flexDir: 'column',
      alignItems: 'flex-start',
      color: 'fg.accent.secondary',
      rowGap: 'sm',
    },
    totalCountContainer: {
      '> div > div:first-of-type': {
        borderRadius: 'xs',
        bg: 'bg.accent.vibrant.default',
        color: 'fg.accent.onVibrant',
        padding: '5xs',
      },
    },
    ageGroupsContainer: {
      '> span': {
        bg: 'bg.inverse.subtle.default',
        color: 'fg.primary',
        padding: '2xs',
        borderRadius: '3xs',
        fontWeight: 'normal',
      },
      button: {
        bg: 'bg.layer2.default',
        color: 'fg.primary',
        fontWeight: 'normal',
        borderColor: 'border.tertiary',
        borderRadius: 'xs',
        padding: '2xs',
        boxShadow: 'md',
        _hover: {
          bg: 'bg.layer2.hover',
          color: 'fg.primary',
          borderColor: 'border.tertiary',
        },
        '&:active, &:focus': {
          outline: 'none',
          bg: 'bg.layer2.active',
          color: 'fg.primary',
          borderColor: 'border.tertiary',
        },
      },
    },
  },
})
