import { z } from 'zod'
import { ContentfulRichTextDocumentSchema } from './common'

const ContentfulStaticContentTypeSchema = z.enum(['staticContent', 'staticTextContent'])

export const ContentfulStaticContentSchema = z.object({
  identifier: z.string().optional(),
  content: ContentfulRichTextDocumentSchema,
  contentType: ContentfulStaticContentTypeSchema.optional(),
})

export type ContentfulStaticContent = z.infer<typeof ContentfulStaticContentSchema>
