import { helpers } from './base'

export const Filters = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      bg: 'bg.layer2.default',
      borderRadius: 'xs',
      padding: {
        base: 0,
        lg: 'sm',
      },
    },
    header: {
      pb: {
        base: 8,
        md: 4,
      },
    },
    title: {
      fontSize: 'var(--alto-sem-text-body-sm-font-size)',
      lineHeight: 'var(--alto-sem-text-body-sm-line-height)',
      letterSpacing: 'var(--alto-sem-text-body-sm-letter-spacing)',
      fontWeight: 'semibold',
      color: 'fg.accent.secondary',
    },
    heading: {
      fontSize: 'var(--alto-sem-text-heading-xs-font-size)',
      lineHeight: 'var(--alto-sem-text-heading-xs-line-height)',
      letterSpacing: 'var(--alto-sem-text-heading-xs-letter-spacing)',
      fontWeight: 'medium',
      color: 'fg.accent.secondary',
    },
  },
})
