import React, { PropsWithChildren } from 'react'
import { Image, chakra, useMultiStyleConfig, Box, FlexProps } from '@chakra-ui/react'
import { HeroProps } from './Hero.types'

export const sizes = [
  {
    width: 1440,
    height: 400,
    media: '(min-width: 1280px)',
    densities: [1, 2],
  },
  {
    width: 1280,
    height: 400,
    media: '(min-width: 960px)',
    densities: [1, 2],
  },
  {
    width: 960,
    height: 400,
    media: '(min-width: 768px)',
    densities: [1, 2],
  },
  {
    width: 768,
    height: 376,
    media: '(min-width: 650px)',
    densities: [1, 2],
  },
  {
    width: 650,
    height: 228,
    media: '(min-width: 475px)',
    densities: [1, 2],
  },
  {
    width: 475,
    height: 228,
    media: '(max-width: 474px)',
    densities: [1, 2],
  },
]

const smallestSize = sizes[sizes.length - 1]

function formatSrc(
  url: string | undefined,
  width: number,
  height: number,
  format = 'jpg',
  densities: number[] | undefined = undefined
): string {
  if (densities) {
    return densities
      .map((dpi) => `${formatSrc(url, width * dpi, height * dpi, format)} ${dpi}x`)
      .join(', ')
  }
  const fm = format === 'jpg' ? `fm=jpg&q=90&fl=progressive` : `fm=${format}`
  return `${url}?${fm}&fit=fill&w=${width}&h=${height}`
}

function Headline({ children, ...rest }: PropsWithChildren & FlexProps) {
  const styles = useMultiStyleConfig('Hero', {})
  return (
    <chakra.h2 key={`${children}-text`} sx={styles.heading} {...rest}>
      {children}
    </chakra.h2>
  )
}

function Tagline({ children, ...rest }: PropsWithChildren & FlexProps) {
  const styles = useMultiStyleConfig('Hero', {})
  return (
    <Box sx={styles.tagline} {...rest}>
      {children}
    </Box>
  )
}

/**
 * Animation implementation details should not be tested with jest
 * these will be marked with istanbul ignore comments
 * */

export default function Hero({
  src,
  alt,
  children,
  ...rest
}: React.PropsWithChildren<HeroProps>): JSX.Element {
  const styles = useMultiStyleConfig('Hero', {})

  return React.useMemo(
    () => (
      <Box
        id="Hero"
        data-testid="hero-component"
        __css={styles.wrapper}
        {...rest}
        style={{ gridColumn: 'var(--layout-column-wide)' }}
      >
        <chakra.picture data-testid="hero-image" key={`${alt}-image`} sx={styles.picture}>
          {src
            ? sizes.map(({ width, height, media, densities }) => (
                <React.Fragment key={media}>
                  <source
                    media={media}
                    type="image/webp"
                    srcSet={formatSrc(src, width, height, 'webp', densities)}
                  />
                  <source
                    media={media}
                    type="image/jpeg"
                    srcSet={formatSrc(src, width, height, 'jpg', densities)}
                  />
                </React.Fragment>
              ))
            : null}
          {src ? (
            <Image
              src={formatSrc(src, smallestSize.width, smallestSize.height)}
              alt={alt}
              sx={styles.image}
            />
          ) : null}
        </chakra.picture>
        <Box sx={styles.body}>{children}</Box>
      </Box>
    ),
    [alt, children, rest, src, styles.body, styles.image, styles.picture, styles.wrapper]
  )
}

Hero.Headline = Headline
Hero.Tagline = Tagline
