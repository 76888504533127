import { defineStyleConfig } from '@chakra-ui/react'

export const ChakraCollapsibleButton = defineStyleConfig({
  baseStyle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0,
    padding: 0,
  },
})
