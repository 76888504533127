import { z } from 'zod'
import { ContentfulHeroSectionSchema } from './hero'

const ContentfulDetailsPageTypeSchema = z.enum(['detailsPage'])

export const ContentfulDetailsPageSchema = z.object({
  name: z.string().optional(),
  identifier: z.string().optional(),
  hero: ContentfulHeroSectionSchema.optional(),
  contentType: ContentfulDetailsPageTypeSchema.optional(),
})

export type ContentfulDetailsPage = z.infer<typeof ContentfulDetailsPageSchema>
