import { Box, Button, ButtonProps, useMultiStyleConfig } from '@chakra-ui/react'
import { useAlertContext } from './AlertProvider'

type Props = ButtonProps

export const AlertButton = (props: Props) => {
  const { status } = useAlertContext()
  const styles = useMultiStyleConfig('Alert')

  return (
    <Box __css={styles.button}>
      <Button variant="secondary" size="md" data-status={status} {...props} />
    </Box>
  )
}
