import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'container',
  'header',
  'heading',
  'filterGroup',
  'title',
  'filtersContainer',
])

export const Filters = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      position: 'relative',
      border: 0,
      outline: 0,
      padding: 0,
      mx: { base: '-4', md: 0 },
      my: 0,
      display: 'grid',
      width: { base: 'calc(var(--chakra-sizes-full) + 2rem)', md: 'full' },
      height: { base: 'full', md: 'unset' },
      alignContent: { lg: 'start' },
      placeItems: { lg: 'unset' },
      background: 'inherit',
    },

    heading: {
      fontWeight: 'medium',
      fontSize: 'lg',
      m: 0,
      display: { base: 'none', md: 'unset' },
      whiteSpace: 'nowrap',
    },

    header: {
      display: { base: 'flex', md: 'grid' },
      background: 'inherit',
      position: 'sticky',
      bottom: 0,
      zIndex: 1,
      placeItems: 'center',
      gap: 2,
      mx: '0',
      width: { base: '100%', md: 'auto' },
      paddingX: { base: 4, md: 'unset' },
      pt: 4,
      gridTemplateColumns: { md: '2fr 1fr 1fr' },
      justifyItems: { md: 'start' },
      height: { md: 'unset' },
      top: { md: '-4', lg: 0 },
      pb: {
        base: 8,
        md: 0,
      },
      '& > :first-child': {
        flex: 1,
      },
    },

    filtersContainer: {
      order: { base: 0, md: 1 },
    },

    filterGroup: {
      width: { base: 'full', md: 'max-content' },
      px: { base: 8, md: 0 },
      py: 0,
      '& + &': {
        marginTop: 4,
      },
      '&:last-of-type': {
        mb: 8,
      },
    },

    title: {
      pt: 8,
      m: 0,
      fontSize: 'sm',
      fontWeight: 'semibold',
    },
  },
})
