/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const NotificationMessage = helpers.defineMultiStyleConfig({
  baseStyle: {
    notificationWrapper: {
      borderRadius: 0,
      backgroundColor: 'brand.baseGray.10',
      borderLeft: 2.5,
      color: 'brand.primary.midnightBlue',
    },
    iconWrapper: {
      pr: 3,
    },
    notificationButton: {
      button: {
        border: 'none',
        color: 'brand.highlight.blue',
        background: 'none',
        textDecoration: 'underline',
        pl: { base: 0, md: 2 },
        pt: 0,
        fontSize: { md: 'sm' },
        _hover: {
          textDecoration: 'none',
          background: 'none',
          color: 'brand.highlight.blue',
        },
        _focus: {
          background: 'none',
          color: 'brand.highlight.blue',
          borderColor: 'brand.primary.midnightBlue',
          border: 'px',
        },
      },
      alignSelf: 'start',
    },
    closeIcon: {
      '.chakra-icon': {
        color: 'brand.baseGray.100',
      },
    },
    notificationText: {
      padding: 3,
    },
    notificationBody: {
      h4: {
        fontSize: { base: 'lg', md: '22px' },
        lineHeight: 1,
      },
    },
  },
  variants: {
    ERROR: {
      notificationWrapper: {
        borderColor: 'brand.alertPink',
      },
      iconWrapper: {
        '.chakra-icon': {
          color: 'brand.alertPink',
        },
      },
    },
    INFO: {
      notificationWrapper: {
        borderColor: 'brand.highlight.blue',
      },
      iconWrapper: {
        '.chakra-icon': {
          color: 'brand.primary.midnightBlue',
        },
      },
    },
    SUCCESS: {
      notificationWrapper: {
        borderColor: 'brand.confirmationGreen',
      },
      iconWrapper: {
        '.chakra-icon': {
          color: 'brand.confirmationGreen',
        },
      },
    },
    WARNING: {
      notificationWrapper: {
        borderColor: 'brand.primary.red',
      },
      iconWrapper: {
        '.chakra-icon': {
          color: 'brand.primary.red',
        },
      },
    },
  },
})
