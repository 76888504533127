import { helpers } from './base'

export const WarningMessage = helpers.defineMultiStyleConfig({
  baseStyle: {
    wrapper: {
      border: 'var(--alto-sem-border-width-sm) solid var(--alto-sem-color-border-critical)',
      gap: 'var(--alto-sem-space-sm)',
      padding: 'var(--alto-sem-space-sm)',
      color: 'fg.critical.primary',
      borderRadius: 'sm',
    },
    content: {
      rowGap: 'var(--alto-sem-space-2xs)',
    },
    title: {
      fontSize: 'xs',
    },
    body: {
      fontSize: 'md',
    },
    iconColor: {
      color: 'fg.critical.primary',
    },
  },
})
