import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { isClient, isLocal } from '~/utils/envChecks'
import {
  getBrowserFromUserAgent,
  getDomain,
  getPageTitle,
  getPlatformFromUserAgent,
} from '~/utils/windowNavigator'
import { NEXT_API_URL } from '~/utils/apiConfig'
import { usePartner } from '~/context/partner'
import { useAuthContext } from '~/context/auth'

const CHATBOT_CONTAINER_ID = 'chatbot'
const userAgent = isClient() ? window.navigator.userAgent : 'SSR'

const isChatWidgetEnabled = () => isClient() && !!window.AmazonCustomChatWidget

const useChatWidget = () => {
  const partner = usePartner()
  const router = useRouter()
  const url = process.env.NEXT_PUBLIC_CHAT_WIDGET_URL
  const widget = window.AmazonCustomChatWidget?.ChatInterface
  const { isAuthenticated } = useAuthContext()

  useEffect(() => {
    let chatWidget: any

    if (url && widget && isChatWidgetEnabled() && isAuthenticated) {
      try {
        chatWidget = widget.init({
          containerId: CHATBOT_CONTAINER_ID,
          serviceProvider: partner.provider,
          additionalData: {
            channel: 'Webchat',
            platform: getPlatformFromUserAgent(userAgent),
            browser: getBrowserFromUserAgent(userAgent),
          },
          startTime: new Date().toISOString(),
          pageTitle: getPageTitle(router.asPath),
          language: 'en',
          opCo: partner.code,
          apiUrl: `${getDomain()}${NEXT_API_URL}/chatbot/startChat`,
        })

        const openChat = window.sessionStorage.getItem('openChat') === 'true'
        if (openChat) {
          setTimeout(() => {
            const chatBot = document.getElementById('chatbot')?.childNodes[0] as HTMLButtonElement
            chatBot?.click()
          }, 0)
          window.sessionStorage.removeItem('openChat')
        }
      } catch (error) {
        if (!isLocal()) {
          // eslint-disable-next-line no-console
          console.warn('Chat widget is missing configuration!', error)
        }
      }
    }
    return () => {
      if (chatWidget?.detach) {
        chatWidget.detach()
      }
    }
  }, [partner, router.asPath, widget, router, isAuthenticated, url])
}

const CHATBOT_QUERY_KEY = 'chatbot'
const CHATBOT_OPEN_VALUE = 'open'

export const useChatbotOpen = () => {
  const router = useRouter()
  return () =>
    router.push({
      pathname: router.pathname,
      query: { ...router.query, [CHATBOT_QUERY_KEY]: CHATBOT_OPEN_VALUE },
    })
}

export default useChatWidget
