import { helpers } from './base'

export const SearchFieldSwapButton = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    swapButton: {
      bgColor: 'brand.teal10',
      borderRadius: 'base',
      _disabled: {
        bgColor: 'brand.coolgrey1',
        '.chakra-icon': {
          color: 'brand.coolgrey4',
        },
        _hover: {
          '.chakra-icon': {
            color: 'brand.coolgrey4',
          },
          bgColor: 'brand.coolgrey1',
        },
      },
      _hover: {
        '.chakra-icon': {
          color: 'brand.darkCityGreen',
        },
        bgColor: 'rgba(0, 133, 120, 0.1)',
      },
      '.chakra-icon': {
        color: 'brand.primaryTeal',
      },
    },
  }),
})
