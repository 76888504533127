import { carBasketItemCardHelpers } from './base'

export const CarBasketItemCard = carBasketItemCardHelpers.defineMultiStyleConfig({
  baseStyle: {
    carFeatures: {
      color: 'fg.secondary',
      rowGap: 'xs',
    },
    includedSummary: {
      rowGap: '4xs',
    },
  },
})
