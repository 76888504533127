import { ProductType } from '@reward-platform/ancillaries-schemas/common'

export const GTMChannels = {
  FLIGHTS: 'flights',
  EXPERIENCES: 'experiences',
  HOTELS: 'hotels',
  CAR_HIRE: 'car_hire',
}

export const getChannelByProductType = (productType: ProductType) => {
  switch (productType) {
    case 'HOTEL':
      return 'hotels'
    case 'CAR_HIRE':
      return 'car_hire'
    case 'EXPERIENCE':
      return 'experiences'
    default:
      return 'flights'
  }
}
