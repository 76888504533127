import { helpers } from './base'

export const SearchExperienceForm = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {},
    destination: {},
    form: {
      rowGap: 5,
      columnGap: {
        base: 5,
        lg: 6,
      },
    },
  },
})
