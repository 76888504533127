import { resultsPageTemplate } from './base'

export const ResultsPageTemplate = resultsPageTemplate.defineMultiStyleConfig({
  baseStyle: resultsPageTemplate.definePartsStyle({
    searchForm: {
      boxShadow: 'none',
      borderBottom: '1px',
      borderColor: 'brand.baseGray.10',
    },
    searchTitle: {
      frontWeight: 'bold',
      color: 'brand.primary.midnightBlue',
    },
    resultsPagination: {
      justifyContent: 'flex-end',
    },
    sortListBox: {
      'div:first-child': {
        background: 'unset',
      },
      ul: {
        background: 'brand.white',
      },
    },
    mapViewWrapper: {
      borderRadius: 'sm',
    },
    sidebar: {
      background: 'brand.white',
    },
    skipLink: {
      borderRadius: 'sm',
      outlineColor: 'brand.primary.blue',
    },
    shareButtonContainer: {
      alignItems: { base: 'flex-start', lg: 'flex-end' },
    },
  }),
})
