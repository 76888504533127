import { headerHelpers } from './base'

export const Header = headerHelpers.defineMultiStyleConfig({
  baseStyle: headerHelpers.definePartsStyle({
    userDetails: {
      '> span': {
        _first: { fontSize: 'sm' },
        _last: { fontSize: 'lg', fontWeight: 'medium' },
      },
    },
    aviosDetails: {
      '> span': {
        _first: { fontSize: { base: 'xs', lg: 'sm' } },
        _last: { fontSize: { base: 'md', lg: 'lg' }, fontWeight: 'medium' },
      },
    },
    cartWrapper: {
      color: 'brand.primaryTeal',
    },
  }),
})
