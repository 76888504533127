import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useMultiStyleConfig,
  Button,
} from '@chakra-ui/react'
import React from 'react'

export type ChakraPopoverPlacement = 'top' | 'right' | 'bottom' | 'left'

export type ChakraPopoverProps = {
  testId?: string
  trigger: React.ReactElement
  actionText?: string
  action?: () => void
  children: React.ReactElement
  placement: ChakraPopoverPlacement
}

export function ChakraPopover({
  testId,
  trigger,
  actionText,
  action,
  children,
  placement,
}: ChakraPopoverProps) {
  const styles = useMultiStyleConfig('ChakraPopover', {})

  return (
    <Popover data-testid={testId} trigger="hover" placement={placement} arrowSize={12}>
      {({ onClose }) => (
        <>
          <PopoverTrigger>{trigger}</PopoverTrigger>
          <PopoverContent sx={styles.content}>
            <PopoverArrow sx={styles.arrow} />
            <PopoverBody sx={styles.body}>{children}</PopoverBody>
            {actionText ? (
              <Button marginTop="16px" variant="secondary" size="sm" onClick={action || onClose}>
                {actionText}
              </Button>
            ) : null}
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}

export default ChakraPopover
