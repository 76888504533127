import { helpers } from './base'

export const TravelerRoomPicker = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    maxTravellerBadge: {
      backgroundColor: 'brand.baseGray.10',
      fontSize: 'xs',
      color: 'brand.midnightBlue',
      borderRadius: 'sm',
    },
    input: {
      svg: {
        color: 'brand.primary.midnightBlue',
      },
    },
    popOverContent: {
      boxShadow: 'brand.boxShadow',
      borderRadius: 'sm',
    },
  }),
})
