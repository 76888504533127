/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const PopularAmenities = helpers.defineMultiStyleConfig({
  baseStyle: {
    amenityText: {
      fontWeight: 'normal',
    },
    amenitiesList: {
      color: 'fg.secondary',
    },
    amenityTitle: {
      fontWeight: 'bold',
      fontSize: 'var(--alto-sem-text-heading-xs-font-size)',
      lineHeight: 'var(--alto-sem-text-heading-xs-line-height)',
      letterSpacing: 'var(--alto-sem-text-heading-xs-letter-spacing)',
    },
  },
  variants: {
    results: {
      amenityText: {
        fontSize: 'sm',
      },
      popularAmenitiesWrapper: {
        mb: 'auto',
        mt: 'lg',
      },
    },
  },
})

export const PopularAmenitiesList = helpers.defineMultiStyleConfig({
  baseStyle: {},
})
