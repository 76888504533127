import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'popularAmenitiesWrapper',
  'amenitiesList',
  'amenityTitle',
  'amenity',
  'iconWrapper',
  'amenityText',
  'iconColor',
])

export const PopularAmenities = helpers.defineMultiStyleConfig({
  baseStyle: {
    popularAmenitiesWrapper: {
      marginTop: { base: 1, xl: 3 },
    },
    amenitiesList: {
      marginInlineStart: 'unset',
      color: 'brand.coolgrey10',
      padding: '0',
      display: 'grid',
      columnGap: {
        base: 6,
        md: 4,
        xl: 2,
      },
    },
    amenityTitle: {
      fontSize: 'xl',
      margin: '1.65rem 0',
    },
    amenity: {
      columnGap: 0,
      display: 'flex',
      justifyContent: 'flex-start',
    },
    iconWrapper: {
      marginRight: 2,
      alignSelf: 'center',
    },
    amenityText: {
      alignSelf: 'center',
    },
  },
  variants: {
    details: {
      amenitiesList: {
        gridTemplateColumns: {
          base: 'repeat(2, minmax(auto, 2fr))',
          md: 'auto 1fr',
          xl: 'repeat(3, minmax(auto, 3fr))',
        },
        rowGap: 5,
        marginBottom: 2,
      },
      amenityText: {
        fontSize: { base: 'md', xl: 'lg' },
      },
    },
    results: {
      amenitiesList: {
        gridTemplateColumns: 'repeat(2, minmax(auto, 2fr))',
        rowGap: 1,
        marginBottom: 0,
      },
      amenityText: {
        fontSize: { base: 'xs', xl: 'sm' },
      },
    },
  },
})
