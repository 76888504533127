import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const helpers = createMultiStyleConfigHelpers(['wrapper', 'header'])

export const AdditionalDetailsForm = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      fieldset: {
        mb: { base: 4, md: 6 },
      },
      'fieldset:nth-of-type(2)': {
        mb: 0,
      },
      'fieldset:nth-of-type(2) > div:nth-of-type(1)': {
        p: { fontSize: 'lg' },
        mt: 10,
      },
      'button[type=submit]': {
        base: {
          mt: 10,
          mb: 10,
          alignSelf: 'flex-end',
          w: '100%',
          px: 12,
          display: 'none',
        },
        md: {
          display: 'flex',
        },
        lg: {
          w: 'fit-content',
        },
      },
    },
    header: {
      mb: 6,
      p: { fontSize: 'xl', fontWeight: 'bold', mb: { base: 4, md: 6 } },
      span: {
        fontSize: 'sm',
      },
    },
  }),
})
