import { z } from 'zod'
import { MonetaryAmountSchema } from '../common'
import { createExtendedPaymentInformationSchema } from '../form-data'

// TODO: Define which terms and conditions should be required
export const CancelOrderPaymentInformationSchema = createExtendedPaymentInformationSchema({
  showCashPaymentForm: true,
  isFeesPoliciesAndTermsAndConditionsExternalRequired: false,
  isFeesPoliciesAndTermsAndConditionsRequired: false,
  isPTLTAStatementRequired: false,
}).extend({
  cash: MonetaryAmountSchema,
})

export type CancelOrderPaymentInformation = z.infer<typeof CancelOrderPaymentInformationSchema>

export const CancelOrderParamsSchema = z.object({
  orderId: z.string(),
  recommendationId: z.string(),
  bundleId: z.string(),
  bundleRefs: z.array(z.string()),
  paymentInformation: CancelOrderPaymentInformationSchema.optional(),
})

export type CancelOrderParams = z.infer<typeof CancelOrderParamsSchema>
