import { fieldGroupHelper } from './base'

export const FieldGroup = fieldGroupHelper.defineMultiStyleConfig({
  baseStyle: {},
  variants: {
    lighter: {
      wrapper: {
        legend: {
          fontWeight: 'normal',
          fontSize: 'md',
        },
      },
    },
  },
})
