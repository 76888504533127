import { helpers } from './base'

const darkCityGreen = 'brand.darkCityGreen'
const red = 'brand.red'
const cloudWhite = 'brand.cloudWhite'

export const BookingStatus = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    container: {
      '>span:first-of-type': {
        fontSize: { base: 'md', lg: 'lg' },
        fontWeight: 'medium',
      },
    },
    childContainer: {
      '>*': {
        fontSize: { base: 'md', lg: 'lg' },
        lineHeight: 'base',
      },
      '>p a': {
        '&:link, &:visited': { color: 'brand.primaryTeal' },
        '&:active, &:hover, &:focus': { textDecoration: 'none' },
        '&:active': { color: darkCityGreen },
      },
    },
    title: {
      fontWeight: 'medium',
      fontSize: 'md',
    },
    collectedAvios: {
      p: {
        fontSize: 'xs',
      },
    },
    priceBreakdownRowWrapper: {
      bg: 'brand.teal10',
    },
    successColor: {
      bg: darkCityGreen,
      color: cloudWhite,
    },
    failColor: {
      bg: red,
      color: cloudWhite,
    },
    summaryTotal: {
      div: {
        _first: {
          fontSize: 'sm',
        },
        _last: {
          fontSize: '2xl',
          fontWeight: 'medium',
        },
      },
    },
  }),
})
