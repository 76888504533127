/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const HotelSummary = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      gridGap: 5,
      paddingBottom: 6,
    },
    heading: {
      color: 'brand.black',
    },
    address: {
      color: 'brand.black',
    },
  },
})
