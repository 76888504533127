import { defineStyleConfig } from '@chakra-ui/react'

export const PriceBreakdownCollectedAviosAmount = defineStyleConfig({
  baseStyle: {
    bg: 'brand.teal5',
    fontSize: 'md',
    borderRadius: 'md',
    p: 2,
  },
})
