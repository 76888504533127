import { getMessageClusters } from '~/services/contentService'
import { ONE_DAY } from '~/utils/datetime'
import { PartnerTag } from '@reward-platform/shared-schemas'
import { CachedClusterValue, Messages } from './localisation.types'

// Save a cluster into local storage, with a timestamp for managing cache expiry
export function saveClusterInCache(key: string, locale: string, messages: Messages) {
  localStorage.setItem(
    `cluster_${key}_${locale}`,
    JSON.stringify({ lastUpdatedAt: Date.now(), messages })
  )
}

// Read a cluster from the cache and parse it as the correct type
export function getClusterFromCache(key: string, locale: string): CachedClusterValue | undefined {
  const storageKey = `cluster_${key}_${locale}`
  const items = localStorage.getItem(storageKey)

  return items ? (JSON.parse(items) as CachedClusterValue) : undefined
}

// Retrieve a cluster dynamically
// Loads from cache if possible, if uncached or stale, retrieves from API
export async function getCluster(partner: PartnerTag, key: string, locale: string) {
  let messagesFromCluster: Messages

  // Read from cache if possible
  const cached = getClusterFromCache(key, locale)

  // Check if cached message exists and is not stale
  if (cached && cached.lastUpdatedAt > Date.now() - ONE_DAY) {
    messagesFromCluster = cached.messages
  } else {
    // Retrieve fresh message cluster from data source
    const cluster = await getMessageClusters(partner, [key], locale, false)
    messagesFromCluster = cluster[key]

    // Save cluster in cache
    saveClusterInCache(key, locale, messagesFromCluster)
  }

  return messagesFromCluster
}
