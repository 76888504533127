import { helpers } from './base'

const backgroundColor = 'brand.primary.blue'

export const Carousel = helpers.defineMultiStyleConfig({
  baseStyle: {
    navigationButton: {
      height: '30px',
      width: '30px',
      backgroundColor,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      _hover: {
        backgroundColor,
      },
      _focus: {
        backgroundColor,
      },
      _active: {
        backgroundColor,
      },
    },
    navigationIcon: {
      boxSize: 4,
    },
  },
})
