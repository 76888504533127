import { footerHelpers } from './base'

export const Footer = footerHelpers.defineMultiStyleConfig({
  baseStyle: footerHelpers.definePartsStyle({
    wrapper: {
      borderTop: '2px solid',
      borderTopColor: 'brand.coolgrey2',
    },
    navListLink: {
      color: 'brand.black',
      fontSize: { base: 'xs', md: 'sm' },
    },
    content: {
      borderTop: '1px solid',
      borderTopColor: 'brand.coolgrey2',
      backgroundColor: 'brand.backgroundGrey',
    },
  }),
})
