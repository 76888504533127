import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers(['title', 'container', 'header', 'content'])

export const Collapsible = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    container: {
      width: '100%',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    content: {
      transition: 'height 0.3s ease',
      overflow: 'scroll',
      mb: 4,
    },
  }),
})
