import { createLogger, format, transports } from 'winston'
import { formatDevLogs } from '../utils'

function buildDevLogger(useFileTransport = true) {
  return createLogger({
    format: format.combine(
      format.label({ label: 'reward-platform' }),
      format.timestamp({
        format: 'HH:mm:ss',
      }),
      format.errors({ stack: true }),
      format.printf(({ timestamp, label, level, message, stack, ...extraData }) =>
        formatDevLogs({ timestamp, label, level, message, stack, ...extraData }, true)
      )
    ),
    exceptionHandlers: [
      new transports.Console(),
      ...(useFileTransport ? [new transports.File({ filename: 'apps/api/exceptions.log' })] : []),
    ],

    transports: [
      new transports.Console({
        level: 'debug',
      }),
    ],
  })
}

export default buildDevLogger
