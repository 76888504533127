import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers(['wrapper', 'icon', 'text', 'button'])

export const ContentBadge = helpers.defineMultiStyleConfig({
  baseStyle: {
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: 'white',
      border: '1px solid',
      borderRadius: 'base',
      gap: 2,
      padding: 2,
      alignItems: 'center',
      my: 2,
      width: 'fit-content',
      ' > span': {
        fontSize: 'sm',
        fontWeight: 'medium',
        lineHeight: '5',
      },
    },
  },
  variants: {
    fullWidth: {
      wrapper: {
        width: '100%',
      },
    },
  },
})
