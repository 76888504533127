/* eslint-disable sonarjs/no-duplicate-string */
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'aviosOnlyContainer',
  'titleContainer',
  'icon',
  'textContainer',
])

export const FlightCardBanner = helpers.defineMultiStyleConfig({
  baseStyle: {
    aviosOnlyContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      padding: 'var(--chakra-space-6) var(--chakra-space-4)',
      border: '1px solid',
      borderBottom: 'none',
      borderRadius: 'var(--chakra-radii-2xl) var(--chakra-radii-2xl) 0 0',
      boxShadow: '0px 2px 4px 0px #00000014',
    },
    titleContainer: {
      display: 'flex',
      fontWeight: 'semibold',
      lineHeight: 'tall',
    },
    icon: {
      marginRight: 1.5,
    },
    textContainer: {
      lineHeight: '1.6875rem',
      paddingLeft: 8,
    },
  },
})
