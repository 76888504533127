import { helpers } from './base'

export const PricingAndSearchCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      boxShadow: 'md',
      backgroundColor: 'bg.layer2.default',
      borderRadius: 'sm',
      gap: 'md',
      padding: 'md',
      div: {
        _before: {
          top: '-128px',
        },
      },
    },
  },
})
