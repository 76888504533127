import React, { createContext, forwardRef, PropsWithChildren, useContext } from 'react'
import {
  Box,
  chakra,
  useRadio,
  useMultiStyleConfig,
  useRadioGroup,
  BoxProps,
  UseRadioGroupReturn,
  Icon,
} from '@chakra-ui/react'
import { convertIconSetToChakraSVG } from '../Icon/Icon.utils'
import { IconSizes } from '../../theme/iconSize'

const RadioButtonGroupContext = createContext<UseRadioGroupReturn['getRadioProps'] | undefined>(
  undefined
)

export const useRadioButtonGroupContext = () => {
  const context = useContext(RadioButtonGroupContext)

  if (!context) {
    throw new Error(
      'useRadioButtonGroupContext must be used within RadioButtonGroupContext.Provider'
    )
  }

  return context
}

type RadioProps = PropsWithChildren<{ value: string }>

export const RadioButton = forwardRef<HTMLInputElement, RadioProps>(
  ({ children, value }: RadioProps, ref) => {
    const getRadioProps = useRadioButtonGroupContext()

    const { state, getInputProps, getCheckboxProps, getLabelProps, getRootProps } = useRadio(
      getRadioProps({ value })
    )
    const inputProps = getInputProps()
    const checkboxProps = getCheckboxProps()
    const labelProps = getLabelProps()
    const styles = useMultiStyleConfig('RadioButton')
    return (
      <chakra.label {...getRootProps()} cursor="pointer" __css={styles.container}>
        <input ref={ref} {...inputProps} />
        <Box {...checkboxProps} __css={styles.control}>
          {state.isChecked && (
            <Icon
              as={convertIconSetToChakraSVG('check')}
              boxSize={IconSizes.sm}
              className="check-icon"
              sx={styles.icon}
            />
          )}
        </Box>
        <chakra.span {...labelProps} __css={styles.label}>
          {children}
        </chakra.span>
      </chakra.label>
    )
  }
)

type RadioButtonGroupProps = Omit<BoxProps, 'onChange'> & {
  name: string
  value?: string
  defaultValue?: string
  onChange?: (value: string) => void
}

export const RadioButtonGroup = forwardRef<HTMLDivElement, RadioButtonGroupProps>(
  ({ name, value, defaultValue, onChange, children, ...rest }, ref): JSX.Element => {
    const { getRootProps, getRadioProps } = useRadioGroup({
      name,
      value,
      defaultValue,
      onChange,
    })

    return (
      <RadioButtonGroupContext.Provider value={getRadioProps}>
        <Box ref={ref} {...rest} {...getRootProps()}>
          {children}
        </Box>
      </RadioButtonGroupContext.Provider>
    )
  }
)
