import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers(['closeButton'])

const commonPseudoStyles = {
  content: '""',
  position: 'absolute',
  top: '50%',
  height: '0.875rem',
  left: '50%',
  width: '2px',
  marginLeft: '-1px',
  marginTop: 'calc(0.875rem * -0.5)',
  backgroundColor: 'currentColor',
  transition: 'transform 0.25s ease',
}

export const Drawer = helpers.defineMultiStyleConfig({
  baseStyle: {
    closeButton: {
      border: 'none',
      background: 'none',
      cursor: 'pointer',
      position: 'absolute',
      top: '0.5rem',
      right: '0.75rem',
      transition: 'background-color 0.25s ease',
      _before: {
        ...commonPseudoStyles,
        transform: 'rotate(45deg)',
      },
      _after: {
        ...commonPseudoStyles,
        transform: 'rotate(-45deg)',
      },
      _hover: {
        backgroundColor: 'lightgrey',
        _before: {
          transform: 'rotate(45deg) scaleY(1.5)',
        },
        _after: {
          transform: 'rotate(-45deg) scaleY(1.5)',
        },
      },
    },
  },
  variants: {
    primary: {},
  },
})
