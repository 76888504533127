import { crossSellingCardHelper } from './base'

export const CrossSellingCard = crossSellingCardHelper.defineMultiStyleConfig({
  baseStyle: crossSellingCardHelper.definePartsStyle({
    cardBody: {
      _hover: {
        backgroundColor: 'brand.teal5',
      },
    },
    content: {
      '*': {
        fontSize: 'sm',
      },

      h2: {
        fontSize: 'xl',
      },
    },
    image: {
      borderRadius: 'md',
    },
  }),
})
