import { addDays, isValid as isValidDate, isWithinInterval, set, subYears, toDate } from 'date-fns'
import { Basket } from '../basket'
import { getStartDateFromBasketItems } from '../helpers'

type DateRestrictions = {
  intervalStart: Date
  intervalEnd: Date
}

export const getBirthDateRestrictionIntervalFromAgeRange = (
  startAge?: number,
  endAge?: number,
  referenceDate?: Date
): DateRestrictions | undefined => {
  if (typeof startAge !== 'number' || typeof endAge !== 'number' || startAge > endAge) {
    return undefined
  }
  const dateToBeUsed =
    referenceDate && isValidDate(referenceDate) ? toDate(referenceDate).valueOf() : Date.now()

  // With endAge being n:
  // Should NOT allow anyone that has their nth birthday on the ref date or older
  // e.g.: you're turning 19 on the day and the endAge is 18
  const intervalStart = addDays(
    set(subYears(dateToBeUsed, endAge + 1), {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    }),
    1
  )

  // With startAge being m:
  // Should allow everyone older than one that has their mth birthday on the ref date
  // e.g.: you're turning 18 on the day and the start age is 18
  const intervalEnd = set(subYears(dateToBeUsed, startAge), {
    hours: 23,
    minutes: 59,
    seconds: 59,
    milliseconds: 999,
  })

  return {
    intervalStart,
    intervalEnd,
  }
}

type isDateOfBirthWithinIntervalParams = {
  basket: Basket
  dateOfBirth: string
  startAge: number | undefined
  endAge: number | undefined
}

export const isDateOfBirthWithinInterval = ({
  basket,
  dateOfBirth,
  startAge,
  endAge,
}: isDateOfBirthWithinIntervalParams) => {
  const { experienceStartDate } = getStartDateFromBasketItems(basket)

  const startDate = new Date(experienceStartDate ?? '')

  const dateOfBirthRestrictions = getBirthDateRestrictionIntervalFromAgeRange(
    startAge,
    endAge,
    isValidDate(startDate) ? startDate : undefined
  )

  if (!dateOfBirthRestrictions) {
    return { isValid: true }
  }

  const { intervalEnd, intervalStart } = dateOfBirthRestrictions

  const isValid = isWithinInterval(dateOfBirth, { start: intervalStart, end: intervalEnd })

  return {
    isValid,
  }
}
