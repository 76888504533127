import { pricingHelpers } from './base'

export const Pricing = pricingHelpers.defineMultiStyleConfig({
  baseStyle: {
    price: {
      fontSize: '1.625rem',
      fontWeight: 'medium',
    },
    expandButton: {
      bgColor: 'brand.teal10',
    },
    pricingOptionsBefore: {
      backgroundColor: 'brand.cloudWhite',
      boxShadow: 'brand',
    },
    pricingOptionsList: {
      color: 'brand.coolgrey10',
    },
    originalPrice: {
      color: 'brand.red',
    },
  },
})
