import { helpers } from './base'

export const Hero = helpers.defineMultiStyleConfig({
  baseStyle: {
    heading: {
      color: 'brand.white',
      fontSize: { base: '32px', md: '5xl' },
      fontWeight: 'normal',
      lineHeight: '120%',
      paddingLeft: { base: 0, sm: 1, lg: 2 },
    },
    tagline: {
      color: 'brand.white',
      h2: {
        fontSize: { base: '28px', lg: '2.5rem' },
        fontWeight: { base: 'regular', lg: 'normal' },
        lineHeight: '120%',
        marginBottom: 0,
      },
      h3: {
        fontSize: { base: '22px', lg: 'xl' },
        fontWeight: { base: 'regular', lg: 'normal' },
        lineHeight: 'normal',
      },
      h4: { fontSize: 'md', fontWeight: 'normal', lineHeight: '150%' },
      p: { fontSize: 'md', marginBottom: 0, color: 'var(--brand-white)' },
    },
  },
})
