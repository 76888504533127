import { useRouter } from 'next/router'
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  useRef,
  useEffect,
} from 'react'
import { IntlProvider, IntlConfig } from 'react-intl'
import { Box, Spinner } from '@chakra-ui/react'
import { createDatadogLogger } from '~/utils/logger'
import { PlatformError } from '~/utils/errors'
import {
  LocalisationContextValue,
  MessageClusterState,
  LocalisationProviderProps,
} from './localisation.types'
import { getCluster } from './Localisation.utils'
import { usePartner } from '../partner'

const LocalisationContext = createContext<LocalisationContextValue | undefined>(undefined)

LocalisationContext.displayName = 'LocalisationContext'

export function LocalisationProvider({
  initialClusterKeys,
  onIntlProviderError,
  shouldLogErrorsToDatadog,
  defaultLocale = 'en-GB',
  ...props
}: LocalisationProviderProps): React.ReactElement {
  // Extract current locale from router
  const router = useRouter()
  const locale = router?.locale ?? defaultLocale
  // Store current currency preference in state
  // TODO: In future this will be driven by user prefs
  const [currency, setCurrency] = useState('GBP')

  // Track initial render
  const isInitialRender = useRef(true)

  // create store for dynamically requested clusters and messages
  const [clusterState, setClusterState] = useState<MessageClusterState>({
    messages: {},
    clusterKeys: new Set(),
  })

  const partner = usePartner()

  // Helper function to switch locale while preserving route
  const setLocale = useCallback(
    (nextLocale: string) => {
      const { pathname, asPath, query } = router
      router.push({ pathname, query }, asPath, { locale: nextLocale })
    },
    [router]
  )

  // Helper for requesting an additional cluster dynamically
  const requestCollection = useCallback(
    async (clusterKey: string) => {
      // Don't request clusters already in state
      if (clusterState.clusterKeys.has(clusterKey)) {
        return
      }

      const messagesFromCluster = await getCluster(partner.tag, clusterKey, locale)

      // Update dynamic collection with new clusters
      setClusterState(({ clusterKeys, messages: currentMessages }) => ({
        clusterKeys: clusterKeys.add(clusterKey),
        messages: {
          ...currentMessages,
          ...messagesFromCluster,
        },
      }))
    },
    [partner, clusterState, locale]
  )

  const handleErrors: IntlConfig['onError'] = (error) => {
    if (onIntlProviderError) {
      onIntlProviderError(error)
    }
    if (shouldLogErrorsToDatadog) {
      const logger = createDatadogLogger('intl-messages-error', { level: 'warn' })
      const description = 'IntlError'
      logger.warn(`${description}: ${error.code}`, { error: { ...error, description } }, error)
    }
  }

  const contextValue = useMemo<LocalisationContextValue>(
    () => ({
      locale,
      setLocale,
      currency,
      setCurrency,
      requestCollection,
      messages: clusterState.messages,
    }),
    [locale, setLocale, currency, setCurrency, requestCollection, clusterState]
  )

  // Load initial clusters on initial render
  useEffect(() => {
    if (isInitialRender && !clusterState.clusterKeys.size) {
      initialClusterKeys?.forEach(requestCollection)
    }
    isInitialRender.current = false
  }, [initialClusterKeys, requestCollection, clusterState])

  // If no clusters are currently loaded or initially requested, display loading spinner
  if (!clusterState.clusterKeys.size && initialClusterKeys) {
    return (
      <Box position="fixed" inset="0" display="grid" alignItems="center" justifyContent="center">
        <Spinner />
      </Box>
    )
  }

  return (
    <IntlProvider locale={locale} messages={clusterState.messages} onError={handleErrors}>
      <LocalisationContext.Provider value={contextValue} {...props} />
    </IntlProvider>
  )
}

export function useLocalisation(): LocalisationContextValue {
  const context = useContext(LocalisationContext)
  if (!context) {
    throw new PlatformError('useLocalisation must be used within a LocalisationProvider')
  }
  return context
}
