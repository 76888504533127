import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'stepperContainer',
  'stepsContainer',
  'stepText',
  'stepTextSmall',
  'completedLine',
  'backgroundLine',
  'completedLine',
  'stepText',
])

const flexStartAlign = 'flex-start'

export const Stepper = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    stepperContainer: {
      display: 'grid',
      justifyItems: 'center',
      paddingBottom: { base: 0, md: 6 },
      width: { base: '100%', lg: '60%' },
    },
    stepsContainer: {
      gridArea: '1 / 1',
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'space-between',
      alignItems: flexStartAlign,
      width: '100%',
      marginTop: { base: 3, md: 4 },
    },
    stepContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignContent: flexStartAlign,
      justifyContent: flexStartAlign,
      alignItems: 'center',
      flexGrow: 1,
      flexBasis: 0,
    },
    lineContainer: {
      display: 'grid',
      gridArea: '1 / 1',
      height: '0.13rem',
      marginTop: { base: 6, md: 8 },
    },
    backgroundLine: {
      gridArea: '1 / 1',
      transition: '1s width cubic-bezier(0.23,1,0.32,1)',
      backgroundImage: 'repeating-linear-gradient( 90deg, transparent 0 4px, red 4px 12px )',
    },
    completedLine: {
      gridArea: '1 / 1',
      transition: '1s width cubic-bezier(0.23, 1, 0.32, 1)',
      background: 'red',
    },
    stepText: {
      color: 'red',
      textAlign: 'center',
      marginTop: 2,
      fontSize: { base: 'xs', md: 'sm' },
      wordWrap: 'normal',
    },
  }),
})
