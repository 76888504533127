import { helpers } from './base'

export const SelectBox = helpers.defineMultiStyleConfig({
  baseStyle: {
    selectBoxLabel: {
      color: 'brand.grey6',
    },
    selectBoxSelect: {
      borderRadius: '0',
    },
  },
  variants: {
    error: {
      selectBoxLabel: {
        color: 'error.primary',
      },
      selectBoxSelect: {
        borderColor: 'error.primary',
        boxShadow: '0 0 2px 0 var(--chakra-colors-error-primary)',
      },
    },
  },
})
