import { Icon, IconProps, useMultiStyleConfig } from '@chakra-ui/react'
import { convertIconSetToChakraSVG } from '../Icon/Icon.utils'
import { IconSize, IconSizes } from '../../theme/iconSize'
import { IconName } from '../Icon/Icon.types'

type SnackbarIconProps = IconProps & {
  iconName: IconName
  iconSize: IconSize
}

export const SnackbarIcon = (props: SnackbarIconProps) => {
  const { iconName, iconSize } = props
  const styles = useMultiStyleConfig('Snackbar')

  return (
    <Icon
      as={convertIconSetToChakraSVG(iconName)}
      boxSize={IconSizes[iconSize]}
      __css={styles.icon}
    />
  )
}
