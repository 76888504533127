import { crossSellingCardHelper } from './base'

export const CrossSellingCard = crossSellingCardHelper.defineMultiStyleConfig({
  baseStyle: crossSellingCardHelper.definePartsStyle({
    cardBody: {
      _hover: {
        backgroundColor: 'brand.baseGray.5',
      },
    },
    content: {
      h2: {
        fontSize: { base: 'lg', lg: 'xl' },
        fontWeight: 'light',
        color: 'brand.primary.midnightBlue',
        lineHeight: 'short',
      },
      p: {
        color: 'brand.primary.midnightBlue',
        fontSize: 'sm',
      },
      button: {
        fontSize: 'xs',
      },
    },
  }),
})
