import { ComponentStyleConfig } from '@chakra-ui/react'

const fgAccentPrimary = 'fg.accent.primary'

export const Button: ComponentStyleConfig = {
  baseStyle: {
    color: 'fg.accent.onVibrant',
    border: 'none',
    fontWeight: 'medium',
    borderRadius: 'full',
  },
  variants: {
    primary: {
      bg: 'bg.accent.vibrant.default',
      py: {
        base: 'var(--alto-sem-space-2xs)',
        md: 'var(--alto-sem-space-xs)',
        lg: 'var(--alto-sem-space-sm)',
      },
      px: {
        base: '(--alto-sem-space-sm)',
        md: 'var(--alto-sem-space-md)',
        lg: 'var(--alto-sem-space-lg)',
      },
      _active: {
        bg: 'bg.accent.vibrant.active',
      },
      _hover: {
        bg: 'bg.accent.vibrant.hover',
        boxShadow: 'none',
      },
      _focus: {
        bg: 'bg.accent.vibrant.hover',
        outlineOffset: 'var(--alto-sem-border-width-md)',
        outline: 'var(--alto-sem-border-width-md) solid var(--alto-sem-color-border-accent)',
      },
      _pressed: {
        bg: 'bg.accent.vibrant.active',
      },
      _disabled: {
        bg: 'bg.disabled.vibrant',
        color: 'fg.disabled.onVibrant',
      },
    },
    secondary: {
      bg: 'none',
      color: fgAccentPrimary,
      border: 'var(--alto-sem-border-width-sm) solid var(--alto-sem-color-border-accent)',
      _active: {
        color: fgAccentPrimary,
        bg: 'bg.accent.subtle.active',
      },
      _pressed: {
        bg: 'bg.accent.subtle.active',
      },
      _focus: {
        bg: 'bg.accent.subtle.hover',
        outlineOffset: 'var(--alto-sem-border-width-md)',
        outline: 'var(--alto-sem-border-width-md) solid var(--alto-sem-color-border-accent)',
      },
      _hover: {
        bg: 'bg.accent.subtle.hover',
        boxShadow: 'none',
        borderColor: 'border.accent',
      },
      _disabled: {
        bg: 'none',
        border: '1px solid var(--alto-sem-color-border-disabled-vibrant)',
        color: 'fg.disabled.primary',
      },
      '&[data-status="error"]': {
        bg: 'none',
        color: 'fg.critical.primary',
        borderColor: 'border.critical',
        _active: {
          color: 'fg.critical.primary',
          bg: 'bg.critical.subtle.active',
        },
        _focus: {
          outlineColor: 'border.critical',
        },
      },
      '&[data-status="success"]': {
        bg: 'none',
        color: 'fg.success.primary',
        borderColor: 'border.success',
        _active: {
          color: 'fg.success.primary',
          bg: 'bg.success.subtle.default',
        },
        _focus: {
          outlineColor: 'border.success',
        },
      },
      '&[data-status="neutral"]': {
        bg: 'none',
        color: 'fg.inverse.onSubtle',
        borderColor: 'border.inverse',
        _active: {
          color: 'fg.inverse.onSubtle',
          bg: 'bg.inverse.subtle.active',
        },
        _focus: {
          bg: 'bg.inverse.subtle.active',
          outlineColor: 'border.inverse',
        },
      },
    },
    link: {
      color: fgAccentPrimary,
      fontWeight: 'semibold',
      padding: '4xs',

      _hover: {
        color: 'fg.accent.secondary',
        textDecoration: 'none',
      },
      _active: {
        color: 'fg.accent.secondary',
      },
      _focus: {
        border: 'var(--alto-sem-border-width-sm) solid var(--alto-sem-color-border-accent)',
        borderRadius: '4xs',
        textDecoration: 'none',
      },
      _focusVisible: {
        outline: 'solid',
        outlineColor: 'var(--alto-sem-color-border-accent)',
        boxShadow: 'none',
        borderRadius: '4xs',
        textDecoration: 'none',
      },
      _disabled: {
        color: 'fg.disabled.primary',
      },
    },
  },
}
