import { helpers } from './base'

export const TravelerQuantityPickerComboBox = helpers.defineMultiStyleConfig({
  baseStyle: {
    maxNumberOfTravelers: {
      fontSize: 'sm',
      fontWeight: 'normal',
      bg: 'bg.accent.subtle.default',
      color: 'fg.accent.onSubtle',
      borderRadius: '3xs',
      padding: 'var(--alto-sem-space-2xs) var(--alto-sem-space-xs)',
      mb: 'sm',
    },
    comboBoxContainer: {
      p: 'sm',
      borderRadius: 'xs',
      boxShadow: 'md',
    },
    travelerQuantityContainer: {
      button: {
        color: 'fg.accent.secondary',
        bg: 'transparent',
        border: 'none',
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        h: 'auto',
        _disabled: {
          bg: 'bg.inverse.subtle.default',
          color: 'fg.disabled.primary',
          opacity: 0.3,
        },
        _active: {
          background: 'none',
        },
        _focus: {
          background: 'none',
        },
      },
    },
  },
})
