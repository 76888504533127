import { Box, Center, Heading, Image, useStyleConfig } from '@chakra-ui/react'

export type MaintenanceModeProps = {
  logoSrc: string
}

const MaintenanceMode = ({ logoSrc }: MaintenanceModeProps) => {
  const style = useStyleConfig('MaintenanceMode', {})

  return (
    <Center sx={style}>
      <Image src={logoSrc} />
      <Box>
        <Heading as="h1">Site is down for maintenance</Heading>
        <Heading as="h2">
          We are working hard to be up and running soon, please try again later.
        </Heading>
      </Box>
    </Center>
  )
}

export default MaintenanceMode
