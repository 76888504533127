import { ComponentSingleStyleConfig } from '@chakra-ui/react'

export const PageTitle: ComponentSingleStyleConfig = {
  baseStyle: {
    h1: {
      fontSize: { base: '3xl', lg: '5xl' },
    },
    span: {
      fontSize: { base: 'md', lg: 'lg' },
    },
  },
}
