import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers(['orderDetailsContainer', 'buttonContainer'])

export const CancelOrderConfirmationWrapper = helpers.defineMultiStyleConfig({
  baseStyle: {
    orderDetailsContainer: {
      marginBottom: 2.5,
      padding: 4,
      p: {
        _first: {
          fontWeight: 'semibold',
          lineHeight: 'normal',
          fontSize: 'md',
        },
        _last: {
          lineHeight: 'normal',
          fontSize: 'sm',
        },
      },
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: { base: 'column', md: 'row' },
      alignItems: 'center',
      w: { base: 'auto', md: '25%' },
      m: 'auto',
      mt: 8,
      mb: 0,
      gap: 3,
      flexBasis: { base: 'calc(100% / 4)', md: 'unset' },
      button: { width: '100%' },
    },
  },
})
