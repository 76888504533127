export enum ErrorCodes {
  FATAL = 'FATAL',
  UNAUTHORIZED = 'UNAUTHORIZED',
  DENIED = 'DENIED',
  BAD_REQUEST = 'BAD_REQUEST',
  MISSING_REQUIRED_PARAMETERS = 'MISSING_REQUIRED_PARAMETERS',
  MISSING_REQUIRED_PROPERTIES = 'MISSING_REQUIRED_PROPERTIES',
  UNPROCESSABLE_ENTITY = 'UNPROCESSABLE_ENTITY',
  PROVIDER_FAILED_UNRECOVERABLE = 'PROVIDER_FAILED_UNRECOVERABLE',
  MISSING_ENV_VARIABLE = 'MISSING_ENV_VARIABLE',
  CONTENTFUL_ERROR = 'CONTENTFUL_ERROR',
  INVALID_BASKET_CONTENTS = 'INVALID_BASKET_CONTENTS',
  NOT_FOUND = 'NOT_FOUND',
  FLIGHT_RESULTS_ERROR = 'FLIGHT_RESULTS_ERROR',
  ORDER_NEEDS_TO_BE_RESHOPPED = 'ORDER_NEEDS_TO_BE_RESHOPPED',
}

export type ErrorCode = {
  httpStatus: number
  code: string
  errorDescription: string
  businessMessage: string
  developerMessage: string
  unrecoverable?: boolean
}

const fatalErrorMessage =
  'Fatal error has occurred, please refresh the application and contact support if error persists'

const defaultBusinessMessage = 'Something went wrong, please check the details and try again'

// Codes comply with GLP API codes as much as possible, see https://docs.iagloyalty.com/docs/
export const ERROR_CODES: { [key in ErrorCodes]: ErrorCode } = {
  FATAL: {
    httpStatus: 500,
    code: 'RP_001',
    errorDescription: 'INTERNAL_SERVER_ERROR',
    businessMessage: fatalErrorMessage,
    developerMessage: fatalErrorMessage,
    unrecoverable: true,
  },
  UNAUTHORIZED: {
    httpStatus: 401,
    code: 'RP_002',
    errorDescription: 'REQUEST_UNAUTHORIZED',
    businessMessage: 'You are not authorized to perform this action',
    developerMessage: 'You are not authorized to perform this action',
  },
  DENIED: {
    httpStatus: 403,
    code: 'RP_003',
    errorDescription: 'REQUEST_DENIED',
    businessMessage: 'You are not allowed to perform this action',
    developerMessage: 'You are not allowed to perform this action',
  },
  BAD_REQUEST: {
    httpStatus: 400,
    code: 'RP_004',
    errorDescription: 'REQUEST_INVALID',

    businessMessage: defaultBusinessMessage,
    developerMessage: 'Bad request, some form data might be invalid',
  },
  MISSING_REQUIRED_PARAMETERS: {
    httpStatus: 400,
    code: 'RP_005',
    errorDescription: 'MISSING_REQUIRED_PARAMETERS',
    businessMessage: defaultBusinessMessage,
    developerMessage: 'Missing some required parameters',
  },
  MISSING_REQUIRED_PROPERTIES: {
    httpStatus: 400,
    code: 'RP_006',
    errorDescription: 'MISSING_REQUIRED_PROPERTIES',
    businessMessage: defaultBusinessMessage,
    developerMessage: 'Missing some required response properties',
  },
  UNPROCESSABLE_ENTITY: {
    httpStatus: 422,
    code: 'RP_007',
    errorDescription: 'UNPROCESSABLE_ENTITY',
    businessMessage:
      'Something is wrong with the submitted data, please check your form details and try again',
    developerMessage:
      'Submitted data did not pass validation, client should not repeat this request without modification. Look into validationErrors object for more details',
  },
  NOT_FOUND: {
    httpStatus: 404,
    code: 'RP_012',
    errorDescription: 'RESOURCE_NOT_FOUND',
    businessMessage: 'The requested resource was not found',
    developerMessage: 'The requested resource was not found',
  },
  // Same as fatal unless provider passed a more meaningful error
  PROVIDER_FAILED_UNRECOVERABLE: {
    httpStatus: 500,
    code: 'RP_008',
    errorDescription: 'PROVIDER_FAILED_UNRECOVERABLE',
    businessMessage: fatalErrorMessage,
    developerMessage: fatalErrorMessage,
    unrecoverable: true,
  },
  MISSING_ENV_VARIABLE: {
    httpStatus: 500,
    code: 'RP_009',
    errorDescription: 'MISSING_ENV_VARIABLE',
    businessMessage: defaultBusinessMessage,
    developerMessage: 'Missing environment variable',
    unrecoverable: true,
  },
  CONTENTFUL_ERROR: {
    httpStatus: 500,
    code: 'RP_010',
    errorDescription: 'CONTENTFUL_ERROR',
    businessMessage:
      'Content failed to load, please refresh the application and contact support if error persists',
    developerMessage: 'Contentful request failed. Look into originalError message for more details',
    unrecoverable: true,
  },
  INVALID_BASKET_CONTENTS: {
    httpStatus: 400,
    code: 'RP_011',
    errorDescription: 'INVALID_BASKET_CONTENTS',
    businessMessage:
      'The items in your basket are not valid, please clear your basket and try again',
    developerMessage: 'The basket contains items that are expired',
    unrecoverable: true,
  },
  FLIGHT_RESULTS_ERROR: {
    httpStatus: 500,
    code: 'RP_013',
    errorDescription: 'FLIGHT_SEARCH_ERROR',
    businessMessage: 'An error occurred while searching for flights',
    developerMessage: 'An error occurred while searching for flights.',
    unrecoverable: false,
  },
  ORDER_NEEDS_TO_BE_RESHOPPED: {
    httpStatus: 500,
    code: 'RP_014',
    errorDescription: 'ORDER_NEEDS_TO_BE_RESHOPPED',
    businessMessage:
      "We're sorry but the items in your basket have changed price or are no longer available.",
    developerMessage:
      'The basket contains items that have changed price or are no longer available.',
    unrecoverable: true,
  },
}

export const getErrorByCode = (status: number) => {
  return Object.values(ERROR_CODES).find(({ httpStatus }) => status === httpStatus)
}
