import { ComponentStyleConfig } from '@chakra-ui/react'

const orange = 'brand.orange'
const orangeDark = 'brand.orangeDark'
const orangeLight = 'brand.orangeLight'
const grey = 'brand.grey'
const white = 'brand.white'
const greyscale2 = 'brand.greyscale2'
const greyscale4 = 'brand.greyscale4'
const linkButton = 'brand.linkButton'

export const Button: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: 'full',
    color: white,
    fontWeight: 'normal',

    '&:focusVisible, &:hover': {
      boxShadow: 'none',
    },

    _disabled: {
      opacity: 1,
    },
  },
  variants: {
    primary: {
      color: grey,
      border: 'none',
      bg: orange,
      py: { base: 2, md: 2.5, lg: 3 },
      px: { base: 5, md: 8, lg: 8 },

      '&:active, &:hover, &:focus': {
        bg: orangeDark,
      },

      _disabled: {
        bg: orangeLight,
      },
    },

    secondary: {
      color: grey,
      borderColor: grey,
      bg: white,
      py: { base: 2, md: 2.5, lg: 3 },
      px: { base: 5, md: 8, lg: 8 },

      '&:active, &:hover, &:focus': {
        bg: greyscale2,
        boxShadow: 'none',
      },

      _active: {
        color: grey,
        borderColor: grey,
      },

      _disabled: {
        borderColor: greyscale4,
      },
    },

    link: {
      color: linkButton,
      border: 'none',

      '&:active, &:hover, &:focus': {
        textDecoration: 'none',
      },

      _active: {
        color: linkButton,
      },
    },
  },
  sizes: {
    lg: {
      fontSize: 'xl',
      lineHeight: 7,
    },
    md: {
      fontSize: 'md',
      lineHeight: 'short',
      height: 'unset',
    },
    sm: {
      fontSize: 'xs',
      lineHeight: 'none',
    },
  },
}
