import { Basket } from '../basket'

type IsPassportExpirationDateValidParams = {
  basket: Basket
  passportExpiry: string
}

export const isPassportExpirationDateValid = ({
  basket,
  passportExpiry,
}: IsPassportExpirationDateValidParams) => {
  const dateRegex = /^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])$/
  const experienceItem = basket.items.EXPERIENCE.at(0)

  if (!experienceItem || !passportExpiry) {
    return true
  }

  const experienceStartDate = new Date(experienceItem.startDate.slice(0, 10))
  const passportExpiryDateStr = passportExpiry.slice(0, 10)

  if (!passportExpiryDateStr.match(dateRegex)) {
    throw new Error('Invalid date')
  }
  const passportExpiryDate = new Date(passportExpiryDateStr)

  return passportExpiryDate.getTime() >= experienceStartDate.getTime()
}
