import { tooltipHelper } from './base'

const primaryTeal = 'brand.primaryTeal'
const shamRock5 = 'brand.shamrock5'
const primaryShamrockGreen = 'brand.primaryShamrockGreen'
const white = 'brand.white'
const black = 'brand.black'
const alOrange = 'brand.orange'

export const Tooltip = tooltipHelper.defineMultiStyleConfig({
  baseStyle: tooltipHelper.definePartsStyle({
    containerBg: {
      bg: shamRock5,
    },
    containerBorder: {
      borderColor: primaryShamrockGreen,
    },

    iconButton: {
      svg: {
        _hover: {
          fill: primaryTeal,
        },
      },
    },
    wrapper: {
      '> div': {
        p: {
          color: black,
        },
      },
      '> button': {
        border: '1px solid',
        borderColor: primaryTeal,
        color: primaryTeal,
        background: white,
        _hover: {
          background: white,
          boxShadow: ' 0 4px 4px -2px rgb(0 0 0 / 40%)',
        },
      },
    },
  }),
  variants: {
    warning: {
      containerBg: {
        bg: 'brand.orange5',
      },
      containerBorder: {
        borderColor: alOrange,
      },
      '> button': {
        borderColor: alOrange,
      },
      wrapper: {
        '> button': {
          borderColor: alOrange,
          color: black,
          background: white,
          _hover: {
            background: white,
          },
        },
      },
    },
  },
})
