import { helpers } from './base'

export const Combobox = helpers.defineMultiStyleConfig({
  baseStyle: {
    styledOption: {
      color: 'red',

      '&.disabled': {
        color: 'red',
      },
    },
    buttonField: {
      color: 'brand.primary.midnightBlue',
      fontWeight: 'light',
      display: 'flex',
      justifyContent: 'flex-start',
      button: {
        _hover: {
          boxShadow: 'none',
        },
        _focus: {
          boxShadow: 'none',
        },
      },
    },
    comboBoxContainer: {
      border: 'none',
      color: 'brand.primary.midnightBlue',
      fontWeight: 'medium',
    },
  },
})
