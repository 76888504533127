/* eslint-disable sonarjs/no-duplicate-string */
import { ComponentStyleConfig } from '@chakra-ui/react'

export const ExternalLink: ComponentStyleConfig = {
  baseStyle: {
    fontSize: 'sm',
  },
  variants: {
    lineItemLink: {
      borderRadius: 'lg',
      color: 'fg.accent.primary',
      fontWeight: 'medium',
      padding: '2xs',
      fontSize: 'xs',
    },
  },
}
