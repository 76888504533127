import { dismissibleModalHelper } from './base'

const baseGray5 = 'brand.baseGray.5'

export const DismissibleModal = dismissibleModalHelper.defineMultiStyleConfig({
  baseStyle: dismissibleModalHelper.definePartsStyle({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: 6,
    },
    button: {
      _first: {
        _hover: {
          backgroundColor: baseGray5,
        },
      },
    },

    buttonsWrapper: {
      marginTop: { base: 6, md: 0 },
    },

    buttonContainer: {
      '> button': {
        fontSize: { base: '18px' },
        py: 4,
      },
    },
  }),
})
