import { helpers } from './base'

const orange = 'brand.orange'

export const Tabs = helpers.defineMultiStyleConfig({
  baseStyle: {
    tabListButton: {
      borderRadius: '0',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      height: 14,
      p: '0 2rem',
      border: 'none',
      bgColor: 'brand.white',
      color: 'brand.grey',
      _active: {
        height: 14,
        p: '1.5rem 2rem',
        border: 'none',
        bgColor: orange,
        color: 'brand.grey',
      },
    },
    tabWrap: {
      _before: { bgColor: orange },
    },
    tabContent: {
      backgroundColor: orange,
    },
  },
})
