import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'contentWrapper',
  'customerReviewsCtn',
  'customerReviewCtn',
  'ratingsCtn',
  'title',
  'icons',
  'vendorLogo',
  'icon',
  'reviewText',
  'showMoreButton',
  'readMoreButton',
])

export const CustomerReviewList = helpers.defineMultiStyleConfig({
  baseStyle: {
    contentWrapper: { pt: 1 },
    customerReviewsCtn: { flexDirection: 'column' },
    customerReviewCtn: {
      gridTemplate: {
        base: `'ratingsCtn vendor'
        'reviewText reviewText'
        / 1fr 1fr`,
        lg: `'ratingsCtn . vendor reviewText'
        /20% 5% 10% 3fr`,
      },
      gap: 5,
      p: 6,
      border: 'none',
    },
    ratingsCtn: {
      gridArea: 'ratingsCtn',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
    title: {
      _expanded: {
        display: '-webkit-box',
        WebKitBoxOrient: 'vertical',
        overflow: 'hidden',
      },
    },
    icons: {
      flexDirection: 'column',
      flexWrap: 'wrap',
      mt: 6,
      columnGap: 4,
      rowGap: 4,
    },
    vendorLogo: {
      gridArea: 'vendor',
      justifySelf: 'end',
      maxWidth: 24,
      width: '100%',
      pos: 'relative',
    },
    icon: {},
    reviewText: {
      m: '0 0 var(--chakra-space-2) 0',
    },
    showMoreButton: {
      lineHeight: '7',
      padding: '0',
      textDecoration: 'none',
      letterSpacing: '0.09px',
    },
    readMoreButton: {
      borderWidth: '1px',
    },
  },
})
