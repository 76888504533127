/* eslint-disable sonarjs/no-duplicate-string */
import { ComponentSingleStyleConfig } from '@chakra-ui/react'

export const PageTitle: ComponentSingleStyleConfig = {
  baseStyle: {
    h1: {
      fontSize: {
        base: 'var(--alto-sem-text-heading-lg-font-size)',
        lg: 'var(--alto-sem-text-heading-xl-font-size)',
      },
      fontWeight: 'medium',
      color: 'fg.accent.primary',
    },
    span: {
      fontSize: { base: 'md ', lg: 'lg' },
    },
  },
}
