import { ComponentMultiStyleConfig } from '@chakra-ui/react'
import merge from 'lodash/merge'
import { SpinnerOverlay as base } from './base'

export const SpinnerOverlay: ComponentMultiStyleConfig = merge({}, base, {
  baseStyle: {
    header: {
      marginBottom: '1rem',
      color: 'brand.black',
    },
    subheader: {
      color: 'brand.black',
    },
  },
})
