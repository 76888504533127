import { helpers } from './base'

export const MeetingPoints = helpers.defineMultiStyleConfig({
  baseStyle: {
    secondaryHeader: {
      fontSize: 'lg',
      fontWeight: 'semibold',
      mb: 4,
      color: 'brand.coolgrey10',
    },
    listItem: {
      fontSize: 'lg',
    },
    mainTitle: {
      fontSize: '2xl-5',
      fontWeight: 'semibold',
    },
    locationGroup: {
      mt: 4,
      p: {
        color: 'brand.coolgrey10',
      },
    },
    icon: {
      color: 'brand.black',
    },
  },
})
