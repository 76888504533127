/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

const accentPrimary = 'fg.accent.primary'

export const Banner = helpers.defineMultiStyleConfig({
  baseStyle: {
    anchorWrapper: {
      backgroundColor: 'bg.layer2.default',
    },

    anchorIcon: {
      color: accentPrimary,
    },

    anchorText: {
      color: 'fg.accent.secondary',
      fontWeight: 'semibold',
    },

    anchorIconText: {
      color: accentPrimary,
    },

    nav: {
      a: {
        _hover: {
          color: 'fg.accent.onVibrant',
        },
        _active: {
          color: 'fg.accent.onVibrant',
        },
        _focus: {
          borderColor: 'fg.accent.onVibrant',
        },
      },
    },
  },
})
