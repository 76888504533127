import { isValid, min } from 'date-fns'
import {
  Basket,
  DATE_OF_BIRTH_BOOKING_QUESTION_ID,
  ExperienceBasketItem,
  FREETEXT_UNIT,
  LOCATION_REFERENCE_UNIT,
  PICKUP_POINT_BOOKING_QUESTION_ID,
  HEIGHT_BOOKING_QUESTION_ID,
  SPECIAL_REQUIREMENTS_BOOKING_QUESTION_ID,
  WEIGHT_BOOKING_QUESTION_ID,
  PASSPORT_NUMBER_BOOKING_QUESTION_ID,
  PASSPORT_NATIONALITY_BOOKING_QUESTION_ID,
  PASSPORT_EXPIRY_BOOKING_QUESTION_ID,
} from '../basket'
import { AgeGroup, AgeGroupSchema } from '../common'

type BaseTraveler = {
  id: string
  ageGroup: AgeGroup
} & (
  | {
      // If collectName is false, we shouldn't collect any other details either
      collectName: false
      isTitleRequired: false
      collectDateOfBirth: false
      collectHeight: false
      collectWeight: false
      collectGender: false
      collectPassportNumber: false
      collectPassportNationality: false
      collectPassportExpiry: false
      heightUnits: []
      weightUnits: []
    }
  | {
      // If collectName is true, we might collect some other details
      collectName: true
      isTitleRequired: boolean
      collectDateOfBirth: boolean
      collectHeight: boolean
      collectWeight: boolean
      collectGender: boolean
      collectPassportNumber: boolean
      collectPassportNationality: boolean
      collectPassportExpiry: boolean
      heightUnits: string[]
      weightUnits: string[]
    }
)

export enum TravelerType {
  FLIGHT_TRAVELER = 'FLIGHT_TRAVELER',
  HOTEL_TRAVELER = 'HOTEL_TRAVELER',
  EXPERIENCE_TRAVELER = 'EXPERIENCE_TRAVELER',
}

export type FlightTraveler = BaseTraveler & {
  type: TravelerType.FLIGHT_TRAVELER
  age?: number
}

export type HotelTraveler = BaseTraveler & {
  type: TravelerType.HOTEL_TRAVELER
  age?: number
}

export type ExperienceTraveler = BaseTraveler & {
  type: TravelerType.EXPERIENCE_TRAVELER
  startAge?: number
  endAge?: number
  height?: string
  weight?: string
}

export type BasketTraveler = FlightTraveler | HotelTraveler | ExperienceTraveler

export const isFlightTraveler = (traveler: BasketTraveler): traveler is FlightTraveler =>
  traveler.type === TravelerType.FLIGHT_TRAVELER

export const isHotelTraveler = (traveler: BasketTraveler): traveler is HotelTraveler =>
  traveler.type === TravelerType.HOTEL_TRAVELER

export const isHotelOrFlightTraveler = (
  traveler: BasketTraveler
): traveler is HotelTraveler | FlightTraveler =>
  isHotelTraveler(traveler) || isFlightTraveler(traveler)

export const isExperienceTraveler = (traveler: BasketTraveler): traveler is ExperienceTraveler =>
  traveler.type === TravelerType.EXPERIENCE_TRAVELER

export enum PickupPointBookingQuestionTypeEnum {
  LOCATION_REFERENCE = 'LOCATION_REFERENCE',
  FREETEXT = 'FREETEXT',
  LOCATION_REFERENCE_OR_FREETEXT = 'LOCATION_REFERENCE_OR_FREETEXT',
}

export const getPickupPointBookingQuestionType = (
  experience: ExperienceBasketItem | undefined
): PickupPointBookingQuestionTypeEnum | undefined => {
  const pickupPointBookingQuestion = experience?.bookingQuestions.find(
    ({ id }) => id === PICKUP_POINT_BOOKING_QUESTION_ID
  )

  if (!pickupPointBookingQuestion) {
    return undefined
  }

  const hasLocationReferenceUnit =
    pickupPointBookingQuestion.units?.includes(LOCATION_REFERENCE_UNIT)

  const hasFreeTextUnit = pickupPointBookingQuestion.units?.includes(FREETEXT_UNIT)
  if (hasLocationReferenceUnit && hasFreeTextUnit) {
    return PickupPointBookingQuestionTypeEnum.LOCATION_REFERENCE_OR_FREETEXT
  }

  if (hasLocationReferenceUnit) {
    return PickupPointBookingQuestionTypeEnum.LOCATION_REFERENCE
  }

  return PickupPointBookingQuestionTypeEnum.FREETEXT
}

export const hasPassportNumberQuestion = (experience: ExperienceBasketItem | undefined) =>
  Boolean(experience?.bookingQuestions.some(({ id }) => id === PASSPORT_NUMBER_BOOKING_QUESTION_ID))

export const hasPassportNationalityQuestion = (experience: ExperienceBasketItem | undefined) =>
  Boolean(
    experience?.bookingQuestions.some(({ id }) => id === PASSPORT_NATIONALITY_BOOKING_QUESTION_ID)
  )

export const hasPassportExpiryQuestion = (experience: ExperienceBasketItem | undefined) =>
  Boolean(experience?.bookingQuestions.some(({ id }) => id === PASSPORT_EXPIRY_BOOKING_QUESTION_ID))

export const hasDateOfBirthBookingQuestion = (experience: ExperienceBasketItem | undefined) =>
  Boolean(experience?.bookingQuestions.some(({ id }) => id === DATE_OF_BIRTH_BOOKING_QUESTION_ID))

export const hasHeightQuestion = (experience: ExperienceBasketItem | undefined) =>
  Boolean(experience?.bookingQuestions.some(({ id }) => id === HEIGHT_BOOKING_QUESTION_ID))

export const hasWeightQuestion = (experience: ExperienceBasketItem | undefined) =>
  Boolean(experience?.bookingQuestions.some(({ id }) => id === WEIGHT_BOOKING_QUESTION_ID))

export const getHeightUnits = (experience: ExperienceBasketItem | undefined) =>
  experience?.bookingQuestions.find((item) => item.id === HEIGHT_BOOKING_QUESTION_ID)?.units || []

export const getWeightUnits = (experience: ExperienceBasketItem | undefined) =>
  experience?.bookingQuestions.find((item) => item.id === WEIGHT_BOOKING_QUESTION_ID)?.units || []

export const hasSpecialRequirementsBookingQuestion = (
  experience: ExperienceBasketItem | undefined
) =>
  Boolean(
    experience?.bookingQuestions.some(({ id }) => id === SPECIAL_REQUIREMENTS_BOOKING_QUESTION_ID)
  )

const getTravelers = (basket: Basket): BasketTraveler[] => {
  const flightItem = basket.items.FLIGHT?.at(0)
  if (flightItem) {
    return flightItem.travelers.map((traveler) => ({
      ...traveler,
      type: TravelerType.FLIGHT_TRAVELER,
      collectName: true,
      collectDateOfBirth: flightItem.isSecure || (traveler.age != null && traveler.age <= 16),
      collectHeight: false,
      heightUnits: [],
      collectWeight: false,
      weightUnits: [],
      collectGender: Boolean(flightItem.isSecure),
      isTitleRequired: true,
      collectPassportNumber: false,
      collectPassportNationality: false,
      collectPassportExpiry: false,
    }))
  }

  const hotelItem = basket.items.HOTEL?.at(0)
  if (hotelItem) {
    return hotelItem.travelers.flatMap((group) => {
      // Find the first adult in the room and collect the name only for them
      const firstAdultInRoomIndex = group.findIndex(
        (traveler) => traveler.ageGroup === AgeGroupSchema.Enum.ADULT
      )
      return group.map((traveler, index) => ({
        ...traveler,
        type: TravelerType.HOTEL_TRAVELER,
        collectName: firstAdultInRoomIndex === index,
        collectDateOfBirth: false,
        collectHeight: false,
        heightUnits: [],
        collectWeight: false,
        weightUnits: [],
        collectGender: false,
        isTitleRequired: false,
        collectPassportNumber: false,
        collectPassportNationality: false,
        collectPassportExpiry: false,
      }))
    })
  }
  const experienceItem = basket.items.EXPERIENCE?.at(0)
  if (experienceItem) {
    return experienceItem.travelers
      .map(({ ageGroup, startAge, endAge, quantity }, i) =>
        Array.from({ length: quantity }).map(
          (_, j): ExperienceTraveler => ({
            id: `${i}-${j}`,
            ageGroup,
            startAge,
            endAge,
            type: TravelerType.EXPERIENCE_TRAVELER,
            collectName: true as const,
            collectDateOfBirth: hasDateOfBirthBookingQuestion(experienceItem),
            collectHeight: hasHeightQuestion(experienceItem),
            heightUnits: getHeightUnits(experienceItem),
            collectWeight: hasWeightQuestion(experienceItem),
            weightUnits: getWeightUnits(experienceItem),
            collectPassportNumber: hasPassportNumberQuestion(experienceItem),
            collectPassportNationality: hasPassportNationalityQuestion(experienceItem),
            collectPassportExpiry: hasPassportExpiryQuestion(experienceItem),
            collectGender: false,
            isTitleRequired: false,
          })
        )
      )
      .flat()
  }

  return []
}

export const getTravelersByBasketItems = (basket: Basket): BasketTraveler[] => {
  const sortOrder: AgeGroup[] = [
    AgeGroupSchema.Enum.ADULT,
    AgeGroupSchema.Enum.SENIOR,
    AgeGroupSchema.Enum.YOUTH,
    AgeGroupSchema.Enum.TRAVELER,
    AgeGroupSchema.Enum.CHILD,
    AgeGroupSchema.Enum.INFANT,
  ]

  return getTravelers(basket).sort(
    ({ ageGroup: ageGroupA }, { ageGroup: ageGroupB }) =>
      sortOrder.indexOf(ageGroupA) - sortOrder.indexOf(ageGroupB)
  )
}

type GetMainAndAdditionalTravelersByBasketParams = {
  basket: Basket
  throwIfMainTravelerDoesNotExist?: boolean
}

export const getMainAndAdditionalTravelersByBasket = ({
  basket,
  throwIfMainTravelerDoesNotExist = true,
}: GetMainAndAdditionalTravelersByBasketParams) => {
  const basketTravelers = getTravelersByBasketItems(basket)
  const mainTraveler = basketTravelers.find((traveler) => {
    if (isHotelOrFlightTraveler(traveler)) {
      const { ageGroup, age } = traveler
      return ageGroup === AgeGroupSchema.Enum.ADULT && age === undefined
    }

    if (isExperienceTraveler(traveler)) {
      const mainTravelerAgeGroups: AgeGroup[] = [
        AgeGroupSchema.Enum.ADULT,
        AgeGroupSchema.Enum.SENIOR,
        AgeGroupSchema.Enum.YOUTH,
        AgeGroupSchema.Enum.TRAVELER,
      ]

      return mainTravelerAgeGroups.includes(traveler.ageGroup)
    }

    return undefined
  })

  if (!mainTraveler) {
    if (throwIfMainTravelerDoesNotExist) {
      throw new Error('Could not find main traveler')
    } else {
      return undefined
    }
  }

  const additionalTravelers = basketTravelers.filter(({ id }) => id !== mainTraveler.id) ?? []

  return { mainTraveler, additionalTravelers }
}

export type ItemsStartDate = {
  experienceStartDate?: string
  hotelStartDate?: string
  flightStartDate?: string
  carHireStartDate?: string
  earliestStartDate?: Date
  hasMultipleStartDates: boolean
}
export const getStartDateFromBasketItems = (basket: Basket): ItemsStartDate => {
  const hotelStartDate = basket.items.HOTEL?.at(0)?.startDate
  const flightStartDate = basket.items.FLIGHT?.at(0)?.startDate
  const experienceStartDate = basket.items.EXPERIENCE?.at(0)?.startDate
  const carHireStartDate = basket.items.CAR_HIRE?.at(0)?.startDate

  const existentDates = [
    hotelStartDate,
    flightStartDate,
    experienceStartDate,
    carHireStartDate,
  ].flatMap((date) => (date && isValid(new Date(date)) ? [new Date(date)] : []))

  const hasMultipleStartDates: boolean = existentDates.length > 1

  const earliestStartDate = existentDates.length ? min(existentDates) : undefined

  return {
    experienceStartDate,
    hotelStartDate,
    flightStartDate,
    carHireStartDate,
    hasMultipleStartDates,
    earliestStartDate,
  }
}
