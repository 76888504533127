import { helpers } from './base'

const cloudWhite = 'brand.cloudWhite'

export const Modal = helpers.defineMultiStyleConfig({
  baseStyle: {
    header: {
      bg: cloudWhite,
    },
    dialog: {
      bg: cloudWhite,
    },
    closeButton: {
      _before: {
        bg: cloudWhite,
      },
    },
  },
})
