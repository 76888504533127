import {
  Box,
  BoxProps,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputProps,
  Textarea,
  TextareaProps,
  Button,
  ButtonProps,
  useMultiStyleConfig,
  Icon,
  ChakraProps,
  chakra,
} from '@chakra-ui/react'
import React, { PropsWithChildren, forwardRef, useRef, useState, useEffect } from 'react'
import { useId } from 'react-id-generator'
import { convertIconSetToChakraSVG } from '../Icon/Icon.utils'
import { IconName } from '../Icon/Icon.types'
import { IconSizes } from '../../theme/iconSize'

type ChakraFieldControlProps = {
  label: string
  placeholder?: string
  error?: string
  comboBox?: React.ReactNode
  labelProps?: Record<string, unknown>
  isRequired?: boolean
}

export type ChakraFieldProps<TControlProps> = PropsWithChildren<
  ChakraFieldControlProps &
    TControlProps &
    Pick<ChakraProps, 'sx'> & {
      id?: string
      dataTestId?: string
      name: string
      icon?: IconName
      errorIcon?: IconName
      iconSize?: number | IconSizes
      iconColor?: string
      note?: string
      isDisabled?: boolean
      labelProps?: Record<string, unknown>
      style?: React.CSSProperties
      popup?: React.ReactNode
      isRequired?: boolean
    }
>

export type ChakraControlComponentProps<TElement extends HTMLElement> = {
  id: string
  name: string
  label: string
  placeholder?: string
  error?: string
  isDisabled?: boolean
} & React.RefAttributes<TElement> &
  Pick<React.HTMLAttributes<TElement>, 'id'>

const ChakraField = forwardRef<HTMLDivElement, ChakraFieldProps<unknown>>((props, ref) => {
  const {
    id,
    note,
    label,
    children,
    error,
    errorIcon,
    icon,
    iconColor,
    iconSize,
    placeholder,
    comboBox,
    isDisabled,
    labelProps = {},
    popup,
    isRequired,
    ...wrapperProps
  } = props

  const styles = useMultiStyleConfig('Form', { variant: 'floating' })

  const hasValue = !!((children as React.ReactElement)?.props?.value ?? '')

  const getCorrectFormatIconSize = (value: number | IconSizes) =>
    typeof value === 'number' ? `${iconSize}px` : iconSize

  // eslint-disable-next-line no-underscore-dangle
  const _iconSize = iconSize ? getCorrectFormatIconSize(iconSize) : '19px'

  return (
    <FormControl
      isDisabled={isDisabled}
      variant="floating"
      isInvalid={Boolean(error)}
      {...wrapperProps}
      ref={ref}
    >
      <Box __css={styles.inputContainer}>
        {error ? <chakra.div __css={styles.errorDot} /> : null}
        {children}
        {label && (
          <FormLabel {...labelProps} htmlFor={id} className={hasValue ? 'floating' : ''}>
            {label}
            {isRequired && ' *'}
          </FormLabel>
        )}
        {note && <FormHelperText margin={2}>{note}</FormHelperText>}
        {icon && (
          <Box display="flex" alignSelf="flex-end" justifyContent="flex-end" __css={styles.icon}>
            <Icon
              as={convertIconSetToChakraSVG(icon, true)}
              boxSize={_iconSize}
              color={iconColor || '#2c2a29'}
              data-testid="/icon-component/i"
            />
          </Box>
        )}
        {error && errorIcon && (
          <Box display="flex" __css={styles.errorIcon}>
            <Icon
              as={convertIconSetToChakraSVG(errorIcon)}
              boxSize={_iconSize}
              color={iconColor || 'red'}
              data-testid="/icon-component/i"
            />
          </Box>
        )}
      </Box>
      {comboBox && <Box __css={styles.comboBox}>{comboBox}</Box>}
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
      {popup && (
        <Box gridArea="popup" position="relative">
          {popup}
        </Box>
      )}
    </FormControl>
  )
})

export const getElementId = (name: string, element: string) => `field-${name}-${element}`
export const getLabelId = (name: string) => `field-${name}-label`
export const getControlId = (name: string) => `field-${name}-control`
export const getPopupId = (name: string) => `field-${name}-popup`

export const asField = <TControlProps, TControlElement extends HTMLElement>(
  ControlComponent: React.ComponentType<ChakraControlComponentProps<TControlElement>>
) => {
  return forwardRef<TControlElement, ChakraFieldProps<TControlProps>>(
    (
      {
        id,
        dataTestId,
        name,
        label,
        error,
        errorIcon,
        icon,
        iconColor,
        iconSize,
        placeholder,
        note,
        comboBox,
        isDisabled,
        labelProps,
        style,
        popup,
        isRequired,
        sx,
        ...props
      },
      ref
    ) => {
      const [generatedId] = useId(1)
      const idToUse = id ?? generatedId

      return (
        <ChakraField
          id={idToUse}
          name={name}
          iconColor={iconColor}
          iconSize={iconSize}
          note={note}
          placeholder={placeholder ?? label}
          label={label}
          aria-label={label}
          error={error}
          errorIcon={errorIcon}
          icon={icon}
          comboBox={comboBox}
          isDisabled={isDisabled}
          labelProps={labelProps}
          style={style}
          popup={popup}
          isRequired={isRequired}
          sx={sx}
        >
          <ControlComponent
            data-testid={dataTestId}
            id={idToUse}
            ref={ref}
            label={label}
            name={name}
            placeholder={placeholder ?? label}
            error={error}
            isDisabled={isDisabled}
            {...props}
          />
        </ChakraField>
      )
    }
  )
}

export type ChakraSelectFieldProps = JSX.IntrinsicElements['select'] &
  React.RefAttributes<HTMLSelectElement> & { isDisabled?: boolean }

const Select = (props: ChakraSelectFieldProps) => {
  const { isDisabled, ...rest } = props
  return <select disabled={isDisabled} {...rest} />
}

export type ChakraFlexMicroformFieldProps = BoxProps & { isDisabled?: boolean; error?: string }

const FlexMicroformField = (props: ChakraFlexMicroformFieldProps) => {
  const { isDisabled, error, ...rest } = props

  const [isFocused, setIsFocused] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const focusedClassName = 'flex-microform-field-focused'
  const invalidClassName = 'flex-microform-field-invalid'

  useEffect(() => {
    if (isFocused) {
      ref.current?.classList.add(focusedClassName)
    } else {
      ref.current?.classList.remove(focusedClassName)
    }
  }, [isFocused])

  useEffect(() => {
    if (error) {
      ref.current?.classList.add(invalidClassName)
    } else {
      ref.current?.classList.remove(invalidClassName)
    }
  }, [error])

  return (
    <Box
      ref={ref}
      {...rest}
      onFocus={(e) => {
        setIsFocused(true)
        rest.onFocus?.(e)
      }}
      onBlur={(e) => {
        setIsFocused(false)
        rest.onBlur?.(e)
      }}
    />
  )
}

export const ChakraFlexMicroformField = asField<BoxProps, HTMLDivElement>(FlexMicroformField)
export const ChakraInputField = asField<InputProps, HTMLInputElement>(Input)
export const ChakraTextAreaField = asField<TextareaProps, HTMLTextAreaElement>(Textarea)
export const ChakraSelectField = asField<ChakraSelectFieldProps, HTMLSelectElement>(Select)
export const ChakraButtonField = asField<ButtonProps, HTMLButtonElement>(Button)
