import { helpers } from './base'

export const Ratings = helpers.defineMultiStyleConfig({
  baseStyle: {
    ratingWrapper: {
      alignItems: 'center',
    },
    ratingTextWrapper: {
      marginLeft: 2,
    },
    ratingStar: {
      mr: 1,
    },
  },
})
