import type { NextApiRequest, NextApiResponse } from 'next'
import { AuthError } from '@auth0/nextjs-auth0'
import { AxiosError } from 'axios'
import { ZodError } from 'zod'

import { ZODException } from '@reward-platform/shared-api/exceptions/ZODException'
import { NextServerError } from './NextServerError'
import { NextAuth0Error } from './NextAuthError'
import { NextAxiosError } from './NextAxiosError'
import { logRequestToDatadog } from '../logger'

export const handleApiRouteError = (err: unknown, req: NextApiRequest, res: NextApiResponse) => {
  const error = err instanceof Error ? err : undefined
  let serverError: NextServerError = new NextServerError({ ...error })

  if (error instanceof AxiosError) {
    serverError = new NextAxiosError(error)
  }

  if (error instanceof AuthError) {
    serverError = new NextAuth0Error(error)
  }

  if (error instanceof ZodError) {
    serverError = new ZODException(error)
  }

  // Log error to datadog
  logRequestToDatadog(req, serverError)

  // Send response
  const errorResponse = serverError.getErrorResponse()
  res.status(serverError.httpStatus).send(errorResponse)
}
