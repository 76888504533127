import { helpers } from './base'

export const TravelerQuantityPickerComboBox = helpers.defineMultiStyleConfig({
  baseStyle: {
    comboBoxContainer: {
      border: 'solid 1px black',
      color: 'brand.grey6',
      fontWeight: 'medium',
    },
  },
})
