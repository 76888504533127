import { helpers } from './base'

export const HotelReviewBreakdown = helpers.defineMultiStyleConfig({
  baseStyle: {
    ratings: {
      span: {
        svg: {
          width: '16px',
          height: '16px',
        },
      },
    },
  },
})
