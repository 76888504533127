import { helpers } from './base'

const listItemStyles = {
  fontWeight: 'light',
  fontSize: { base: 'sm', lg: 'md' },
  lineHeight: 6,
  paddingLeft: 6,
  _before: {
    background: 'brand.primary.midnightBlue',
    marginLeft: -5,
  },
}

export const AmenitiesList = helpers.defineMultiStyleConfig({
  baseStyle: {
    amenitiesSectionTitle: {
      fontSize: { base: 'lg', lg: '1.375rem' },
      lineHeight: { base: 7, lg: 9 },
      fontWeight: 'light',
    },
    amenitiesListItem: listItemStyles,
    preformattedContent: {
      li: listItemStyles,
      ul: {
        fontSize: { base: 'sm', lg: 'md' },
      },
      p: {
        fontSize: { base: 'sm', lg: 'md' },
      },
      lineHeight: '6',
    },
  },
})
