/* eslint-disable sonarjs/no-duplicate-string */
import { ComponentMultiStyleConfig } from '@chakra-ui/react'
import merge from 'lodash/merge'
import { Switch as base } from './base'

const outline = {
  outlineOffset: 'var(--alto-sem-space-5xs)',
  outline: 'var(--alto-sem-border-width-md) solid',
  outlineColor: 'border.accent',
}

export const Switch: ComponentMultiStyleConfig = merge({}, base, {
  baseStyle: {
    switchButton: {
      color: 'fg.primary',
      fontSize: 'var(--alto-sem-text-body-md-font-size)',
    },
    switchIcon: {
      border: 'var(--alto-sem-border-width-sm) solid',
      borderColor: 'border.primary.default',
      bgColor: 'bg.layer2.default',
      height: '32px',
      width: '52px',
      _after: {
        top: '7px',
        left: '8px',
        width: '16px',
        height: '16px',
      },
    },
  },
  variants: {
    isActive: {
      switchIcon: {
        borderColor: 'bg.accent.vibrant.default',
        bgColor: 'bg.accent.vibrant.default',
        _after: {
          bgColor: 'fg.accent.onVibrant',
          width: '24px',
          height: '24px',
          top: '3px',
          left: '6px',
        },
        _hover: {
          borderColor: 'bg.accent.vibrant.hover',
          bgColor: 'bg.accent.vibrant.hover',
        },
        _active: {
          borderColor: 'bg.accent.vibrant.active',
          bgColor: 'bg.accent.vibrant.active',
        },
        _focus: {
          borderColor: 'bg.accent.vibrant.hover',
          bgColor: 'bg.accent.vibrant.hover',
          ...outline,
        },
      },
      switchButton: {
        _disabled: {
          color: 'fg.disabled.primary',
          '> div:nth-child(2)': {
            bgColor: 'bg.disabled.vibrant',
            borderColor: 'transparent',
            _after: {
              bgColor: 'fg.disabled.onVibrant',
            },
          },
        },
      },
    },
    notActive: {
      switchIcon: {
        bgColor: 'bg.layer2.default',
        _after: {
          bgColor: 'fg.secondary',
        },
        _hover: {
          bgColor: 'bg.layer1.default',
        },
        _active: {
          bgColor: 'bg.layer1.default',
        },
        _focus: {
          bgColor: 'bg.layer1.default',
          ...outline,
        },
      },
      switchButton: {
        _disabled: {
          color: 'fg.disabled.primary',
          '> div:nth-child(2)': {
            bgColor: 'bg.disabled.subtle',
            borderColor: 'border.disabled.subtle',
            _after: {
              bgColor: 'fg.disabled.primary',
            },
          },
        },
      },
    },
  },
})
