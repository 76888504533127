import { pricingHelpers } from './base'

const baBlue = 'brand.primary.blue'
const baMidnightBlue = 'brand.primary.midnightBlue'

export const Pricing = pricingHelpers.defineMultiStyleConfig({
  baseStyle: {
    headerLabel: {
      fontWeight: 'light',
      color: baMidnightBlue,
    },
    price: {
      fontSize: { base: 'xl', md: '4xl' },
      color: baBlue,
      fontWeight: 'light',
      margin: 0,
      padding: 0,
    },
    priceNote: {
      color: baMidnightBlue,
      strong: { fontWeight: 'semibold' },
    },
    expandButton: {
      color: baBlue,
      bgColor: 'brand.baseGray.10',
    },
    pricingOptionsBefore: {
      backgroundColor: 'brand.baseGray.5',
      boxShadow: 'brand.boxShadow',
    },
    pricingOptionsList: {
      color: baBlue,
    },
    originalPrice: {
      color: 'brand.primary.red',
    },
  },
  variants: {
    mapOpen: {
      price: {
        fontSize: 'xl',
      },
    },
  },
  sizes: {
    small: {
      price: {
        fontSize: 'xl',
      },
    },
  },
})
