import React from 'react'
import { Box, BoxProps } from '@chakra-ui/react'
import Image, { ImageProps } from 'next/legacy/image'

type ChakraNextImageProps = Pick<BoxProps, '__css' | 'as' | 'h' | 'w'> & ImageProps

function ChakraNextImage(props: ChakraNextImageProps) {
  const { __css, as, h, w, ...imageProps } = props
  return (
    <Box __css={__css} as={as} h={h} w={w}>
      <Image {...imageProps} unoptimized />
    </Box>
  )
}

export default ChakraNextImage
