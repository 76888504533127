import { ComponentMultiStyleConfig } from '@chakra-ui/react'
import merge from 'lodash/merge'
import { Switch as base } from './base'

export const Switch: ComponentMultiStyleConfig = merge({}, base, {
  baseStyle: {
    switchvalue: { color: 'black' },
    switchicon: { bgColor: 'brand.coolgrey10' },
  },
  variants: {
    isActive: {
      switchoffvalue: { fontWeight: 'normal' },
      switchonvalue: { fontWeight: 'medium' },
      switchicon: {
        _before: { bgColor: 'brand.yellow' },
        _after: { bgColor: 'white' },
      },
    },
    notActive: {
      switchoffvalue: { fontWeight: 'medium' },
      switchonvalue: { fontWeight: 'normal' },
      switchicon: {
        _before: { bgColor: 'brand.yellow' },
        _after: { bgColor: 'white' },
      },
    },
  },
})
