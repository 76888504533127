import { alCloudWhite, alWhite } from '@reward-platform/lift/theme/aer_lingus/variables'
import { helpers } from './base'

const darkCityGreen = 'brand.darkCityGreen'

export const Badge = helpers.defineMultiStyleConfig({
  variants: {
    primaryLight: {
      container: {
        backgroundColor: alWhite,
        border: '1px solid',
        borderColor: darkCityGreen,
        fontSize: 'sm',
        fontWeight: '500',
        textColor: darkCityGreen,
        transition: 'all 0.3s ease',
        _hover: {
          backgroundColor: darkCityGreen,
          borderColor: darkCityGreen,
          boxShadow: 'var(--chakra-shadows-brand)',
          textColor: alWhite,
        },
      },
    },
    neutral: {
      container: {
        backgroundColor: alCloudWhite,
      },
    },
    highlight: {
      container: {
        backgroundColor: 'rgba(var(--primary-shamrock-green, 132, 189, 0), 0.1)',
      },
    },
  },
  defaultProps: {
    variant: 'neutral',
  },
})
