import { helper } from './base'

export const PriceBreakdown = helper.defineMultiStyleConfig({
  baseStyle: {
    styledPriceBreakdownSummaryWrapper: {
      borderRadius: 'sm',
      bg: 'brand.white',
      boxShadow: 'brand.boxShadow',
    },
  },
  variants: {
    noBg: {
      styledPriceBreakdownSummaryWrapper: {
        bg: 'none',
        boxShadow: 'none',
      },
    },
  },
})
