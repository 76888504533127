import { isProduction, isStaging } from '../envChecks'
import { sanitizeBaseUrl } from '../url'

const AVIOS_BASE_PATH = process.env.NEXT_PUBLIC_AVIOS_BASE_PATH
export const AVIOS_DOMAIN = 'avios.com'

export const AVIOS_HOSTS = {
  prd: 'avios.com',
  stg: 'stg.avios.com',
  tst: 'tst.avios.com',
}

export const getAviosHost = () => {
  if (isStaging()) {
    return AVIOS_HOSTS.stg
  }
  if (isProduction()) {
    return AVIOS_HOSTS.prd
  }
  return AVIOS_HOSTS.tst
}

export const getAviosBaseUrl = () => {
  const host = getAviosHost()
  const basePath = AVIOS_BASE_PATH
  return sanitizeBaseUrl(host + basePath)
}
