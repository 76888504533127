/* eslint-disable sonarjs/no-duplicate-string */
import { replaceHotelItemCardHelper } from './base'

export const ReplaceHotelItemCard = replaceHotelItemCardHelper.defineMultiStyleConfig({
  baseStyle: {
    details: {
      borderRadius: 'xs',
    },
    heading: {
      h4: {
        color: 'fg.accent.primary',
        fontSize: 'var(--alto-sem-text-heading-xs-font-size)',
        lineHeight: 'var(--alto-sem-text-heading-xs-line-height)',
        letterSpacing: 'var(--alto-sem-text-heading-xs-letter-spacing)',
        fontWeight: 'bold',
      },
    },
  },
})
