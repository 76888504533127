import useIntl from '~/hooks/useIntl/useIntl'

const useTranslations = () => {
  const intl = useIntl()

  return {
    label: intl.formatMessage({ id: 'footer-label', defaultMessage: 'Footer' }),
    provider: intl.formatMessage({
      id: 'footer-service-provider',
      defaultMessage: 'Service provided by Avios Group Ltd',
    }),
    legalCopyrightLineBritishAirways: intl.formatMessage({
      id: 'footer-legal-copyright-line-ba',
      defaultMessage:
        'Copyright © Avios, British Airways Group DAC and British Airways Ltd. All rights reserved.',
    }),
    legalCopyrightLineAerLingus: intl.formatMessage({
      id: 'footer-legal-copyright-line-ae',
      defaultMessage:
        'Copyright © Avios, Aer Lingus Group DAC and Aer Lingus Ltd. All rights reserved.',
    }),
    contactUs: intl.formatMessage({
      id: 'footer-contact-us',
      defaultMessage: 'Contact Us',
    }),
    privacy: intl.formatMessage({
      id: 'footer-privacy',
      defaultMessage: 'Privacy',
    }),
    security: intl.formatMessage({
      id: 'footer-security',
      defaultMessage: 'Security',
    }),
    accessibility: intl.formatMessage({
      id: 'footer-accessibility',
      defaultMessage: 'Accessibility',
    }),
    termsAndConditions: intl.formatMessage({
      id: 'footer-terms-and-conditions',
      defaultMessage: 'Terms & Conditions',
    }),
    cookiePolicy: intl.formatMessage({
      id: 'footer-cookie-policy',
      defaultMessage: 'Cookie Policy',
    }),
    modernSlaveryAct: intl.formatMessage({
      id: 'footer-modern-slavery-act',
      defaultMessage: 'Modern Slavery Act',
    }),
    atolProtection: intl.formatMessage({
      id: 'footer-atol-protection',
      defaultMessage: 'ATOL Protection',
    }),
    privacySettings: intl.formatMessage({
      id: 'footer-privacy-settings',
      defaultMessage: 'Privacy Settings',
    }),
  }
}

export default useTranslations
