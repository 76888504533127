import { helpers } from './base'

export const Stepper = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    backgroundLine: {
      backgroundImage: `repeating-linear-gradient( 90deg, transparent 0 4px, var(--chakra-colors-brand-baseGray-50) 4px 12px )`,
    },
    completedLine: {
      background: 'brand.primary.midnightBlue',
    },
    stepText: {
      maxWidth: { base: '70px', md: '85px' },
      color: 'brand.primary.midnightBlue',
      fontWeight: 'light',
    },
  }),
})
