import { helper } from './base'

const basketTotal = {
  flexDirection: { base: 'row', md: 'column' },
}

const insufficientFunds = {
  color: 'fg.critical.primary',
}

export const PriceBreakdownTotalDue = helper.defineMultiStyleConfig({
  baseStyle: {
    priceTotalSummaryWrapper: {
      color: 'fg.accent.secondary',
    },
    priceTotalWrapper: {
      flexDirection: 'column',
      '&[data-value="basketTotal"]': {
        ...basketTotal,
      },
    },
    currencyPair: {
      span: {
        _first: {
          fontSize: 'sm',
          color: 'fg.accent.secondary',
        },
        _last: {
          fontSize: 'var(--alto-sem-text-heading-xl-font-size)',
          lineHeight: 'var(--alto-sem-text-heading-xl-line-height)',
          fontWeight: 500,
          color: 'fg.accent.primary',
        },
      },
      _invalid: {
        span: {
          ...insufficientFunds,
        },
      },
    },
    heading: {
      fontSize: 'var(--alto-sem-text-heading-sm-font-size)',
      lineHeight: 'var(--alto-sem-text-heading-sm-line-height)',
      fontWeight: 700,
    },
    collectedAvios: {
      textAlign: 'right',
    },
  },
})
