import React from 'react'
import { Box, chakra, useMultiStyleConfig, Spinner } from '@chakra-ui/react'
import useIntl from '~/hooks/useIntl/useIntl'

function SpinnerOverlay(): JSX.Element {
  const styles = useMultiStyleConfig('SpinnerOverlay', {})
  const intl = useIntl()

  const t = {
    header: intl.formatMessage({
      id: 'spinner-header',
      defaultMessage: 'One moment please!',
    }),
    subheader: intl.formatMessage({ id: 'spinner-subheader', defaultMessage: 'Loading…' }),
  }

  return (
    <Box __css={styles.wrapper}>
      <chakra.h2 __css={styles.header}>{t.header}</chakra.h2>
      <chakra.h5 __css={styles.subheader}>{t.subheader}</chakra.h5>
      <Spinner role="progressbar" title="Spinner" />
    </Box>
  )
}

export default SpinnerOverlay
