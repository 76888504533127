import { useState, useRef } from 'react'
import { Box, useMediaQuery, useMultiStyleConfig } from '@chakra-ui/react'
import { addDays } from 'date-fns'
import MobileModal from '../MobileModal'
import { RangeDatePickerProps } from './RangeDatePicker.types'
import Calendar from '../DatePicker/Calendar'
import { ChakraInputField } from '../ChakraField'
import { useClickAway } from '../../hooks/useClickAway'

const ONE_DAY = 24 * 60 * 60 * 1000

const RangeDatePicker = ({
  startLabel = 'Start',
  endLabel = 'End',
  minDate = new Date(Date.now()),
  maxDate = new Date(Date.now() + 365 * ONE_DAY),
  values = {
    startDateTime: null,
    endDateTime: null,
  },
  onChange = () => null,
  singleDay = false,
  allowSameDay = false,
  maxEndDate,
  locale = 'en-GB',
  endDateSelectable = false,
  errors,
}: RangeDatePickerProps): JSX.Element => {
  const [showCalendar, setShowCalendar] = useState(false)
  const [showEndCalendar, setShowEndCalendar] = useState(false)
  const [dateFakeFocus, setDateFakeFocus] = useState<'start' | 'end' | undefined>()
  const [isTabletOrSmaller] = useMediaQuery(`(max-width: 767px)`)

  const calendarRef = useRef<HTMLInputElement>(null)

  const styles = useMultiStyleConfig('RangeDatePicker', {})

  useClickAway(calendarRef, showCalendar, () => {
    setShowCalendar(false)
    setShowEndCalendar(false)
    setDateFakeFocus(undefined)
  })

  const onChangeCalendar = (dates: Date | [Date, Date]) => {
    if (!dates) {
      return
    }

    if (endDateSelectable && !Array.isArray(dates) && !allowSameDay) {
      onChange({ endDateTime: dates })
      setShowCalendar(false)
      return
    }

    const [start, end] = singleDay ? [dates as Date, undefined] : (dates as [Date, Date])

    if (!allowSameDay && end?.getTime() === start.getTime()) {
      end.setDate(start.getDate() + 1)
    }

    if (start && !end) {
      setDateFakeFocus('end')
    }

    onChange({ startDateTime: start, endDateTime: end })

    if (singleDay || end != null) {
      setShowCalendar(false)
    }
  }

  const selectingEndDate =
    endDateSelectable && showEndCalendar && values.startDateTime !== undefined

  const onClickStartField = () => {
    setDateFakeFocus('start')
    setShowCalendar(!showCalendar)
    setShowEndCalendar(false)
    setTimeout(() => calendarRef.current?.focus(), 100)
  }
  const onClickEndField = () => {
    setDateFakeFocus('end')
    setShowCalendar(!showCalendar)
    setShowEndCalendar(endDateSelectable && !!values.startDateTime)
    setTimeout(() => calendarRef.current?.focus(), 100)
  }

  return (
    <Box __css={styles.equallySpacedDiv}>
      <ChakraInputField
        name="start-range"
        className={dateFakeFocus === 'start' ? 'fake-focus' : ''}
        label={startLabel}
        isReadOnly
        value={values.startDateTime?.toLocaleDateString(locale) || ''}
        onClick={onClickStartField}
        onKeyDown={(keyEvent) => {
          if (keyEvent.key === 'Enter') {
            keyEvent.preventDefault()
            onClickStartField()
          }
        }}
        iconColor="brand.inputIcon"
        icon="calendar"
        error={errors && errors.getFieldError('startDateTime')}
      />

      {showCalendar && (
        <Box position="absolute" bottom={0} left={0} width="100%">
          <MobileModal
            id="datePickerRange"
            isModalVisible={showCalendar && isTabletOrSmaller}
            handleModalClose={() => {
              setShowCalendar(false)
              setShowEndCalendar(false)
            }}
            allowScroll
          >
            <Calendar
              ref={calendarRef}
              tabIndex={-1}
              selectsRange={!singleDay && !selectingEndDate}
              selectsEnd={selectingEndDate}
              monthsShown={2}
              selected={values.startDateTime}
              startDate={values.startDateTime}
              endDate={values.endDateTime}
              onChange={onChangeCalendar}
              onCalendarClose={() => setDateFakeFocus(undefined)}
              minDate={
                selectingEndDate && !!values.startDateTime
                  ? addDays(values.startDateTime, 1)
                  : minDate
              }
              maxDate={selectingEndDate || !values.endDateTime ? maxEndDate ?? maxDate : maxDate}
            />
          </MobileModal>
        </Box>
      )}
      <ChakraInputField
        name="end-range"
        className={dateFakeFocus === 'end' ? 'fake-focus' : ''}
        label={endLabel}
        isReadOnly
        value={values.endDateTime?.toLocaleDateString(locale) || ''}
        onClick={onClickEndField}
        onKeyDown={(keyEvent) => {
          if (keyEvent.key === 'Enter') {
            keyEvent.preventDefault()
            onClickEndField()
          }
          if (keyEvent.key === 'Tab') {
            setShowCalendar(false)
            setDateFakeFocus(undefined)
          }
        }}
        iconColor="brand.inputIcon"
        icon="calendar"
        isDisabled={singleDay}
        error={errors && errors.getFieldError('endDateTime')}
      />
    </Box>
  )
}

export default RangeDatePicker
