/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const HotelSummary = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      gridGap: 4,
      paddingBottom: 6,
      borderBottom: '1px solid',
      borderBottomColor: 'brand.coolgrey2',
    },
    heading: {
      color: 'brand.grey6',
    },
    address: {
      color: 'brand.grey6',
    },
  },
})
