import { helper } from './base'

export const Chip = helper.defineMultiStyleConfig({
  variants: {
    primary: {
      chipWrapper: {
        color: 'brand.black',
        bgColor: 'brand.orange',
      },
    },
    secondary: {
      chipWrapper: {
        color: 'brand.black',
        bgColor: 'brand.greyscale3',
      },
    },
  },
})
