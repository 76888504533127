/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const BookingStatus = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      '>span:first-of-type': {
        fontSize: { base: 'md', lg: 'lg' },
        fontWeight: 'medium',
      },
      h3: {
        color: 'fg.accent.secondary',
        fontWeight: 'medium',
      },
      h4: {
        color: 'fg.accent.secondary',
        fontWeight: 'bold',
      },
    },
    statusTooltip: {
      svg: {
        marginTop: '3xs',
      },
      '> * > h4': {
        fontWeight: 'bold',
        fontSize: 'var(--alto-sem-text-heading-sm-font-size)',
      },
    },
    childContainer: {
      '>*': {
        fontSize: { base: 'md', lg: 'lg' },
        lineHeight: 'base',
      },
      '>p a': {
        '&:link, &:visited': { color: 'fg.accent.primary' },
        '&:active, &:hover, &:focus': { textDecoration: 'none' },
        '&:active': { color: 'fg.accent.secondary' },
      },
    },
    title: {
      color: 'fg.accent.primary',
      fontWeight: 'semibold',
      fontSize: 'var(--alto-sem-text-heading-sm-font-size)',
    },
    collectedAvios: {
      p: {
        fontSize: 'xs',
        color: 'fg.accent.secondary',
      },
    },
    priceBreakdownRowWrapper: {
      bg: 'bg.layer2.default',
      borderRadius: 'sm',
      border: '1px solid',
      borderColor: 'border.secondary',
      '.chakra-button': {
        bg: 'bg.layer1.default',
      },
    },
    successColor: {
      bg: 'bg.accent.vibrant.default',
      color: 'fg.accent.onVibrant',
    },
    failColor: {
      bg: 'bg.critical.vibrant.default',
      color: 'fg.accent.onVibrant',
    },
    summaryTotal: {
      div: {
        _first: {
          fontSize: 'sm',
          color: 'fg.accent.secondary',
        },
        _last: {
          color: 'fg.accent.primary',
          fontSize: 'var(--alto-sem-text-heading-sm-font-size)',
          fontWeight: 'medium',
        },
      },
    },
  },
})
