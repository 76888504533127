import { helpers } from './base'

const midnightBlue = 'brand.primary.midnightBlue'

export const CarAdditionalProductCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    heading: {
      color: midnightBlue,
      fontWeight: 'light',
      fontSize: '2xl',
      lineHeight: { base: '6', lg: '8' },
    },
    duration: {
      color: midnightBlue,
      fontWeight: 'light',
      fontSize: '2xs',
    },
    description: {
      color: midnightBlue,
      fontWeight: 'light',
      fontSize: 'md',
    },
    button: {
      border: '1px solid',
      _hover: {
        borderColor: midnightBlue,
        bg: midnightBlue,
      },
    },
  },
})
