import { omit } from 'lodash'

import { PartnerConfig } from '~/schemas/partner'
import { isClient, isLocal, isProduction } from './envChecks'
import { gtmGA3, gtmGA4 } from './googleTagManager/googleTagManager.types'

export const NO_DATALAYER_VALUE = 'no_datalayer_value'

export const defaultBaseDataLayerObject = {
  domain_environment: isProduction() ? 'prod' : 'non_prod',
}

export const getGtmId = (partner?: PartnerConfig) => {
  if (!partner) {
    return process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID
  }
  switch (partner.code) {
    case 'BA':
      return process.env.NEXT_PUBLIC_BA_GOOGLE_TAG_MANAGER_ID
    case 'EI':
      return process.env.NEXT_PUBLIC_EI_GOOGLE_TAG_MANAGER_ID
    default:
      return process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID
  }
}

declare global {
  interface Window {
    google_tag_manager: {
      [CTM_ID: string]: {
        dataLayer: {
          reset: () => void
        }
      }
    }
  }
}

export type Channel = 'flights' | 'hotels' | 'car_hire' | 'experiences'
export type SearchState = 'initial' | 'refined'

export type BaseDataLayerObject = {
  domain_environment?: string
  event?: string
  channel?: Channel
  balance?: number | string
  pageLocation?: string
  booking_currency?: string
  currency?: string
  memberID?: string
}

export type SearchDataLayerObject = {
  booking_start_location?: string
  booking_end_location?: string
  from_date?: string
  to_date?: string
  adults?: number
  children?: number
  traveller_count?: number
  total_result_pages?: number
  shown_results?: number
  result_count?: number
  search_state?: SearchState
  hotel?: HotelDataLayerObject
  filters?: Record<string, string | string[]>
  mapDisplayMode?: string
} & BaseDataLayerObject

type FlightSearchDataLayerObject = {
  referrer?: string
  departure_date?: string
  arrival_date?: string
  flight_route?: string
  filter_flight_type_outbound?: string
  filter_flight_type_inbound?: string
  from_month?: string
} & SearchDataLayerObject

type FlightResultsDataLayerObject = {
  origin: string
  destination: string
  departure_date: string
  arrival_date: string
  flightsNumber: number
  adults: number
  children: number
  childrenAges: Array<{ child: string; age: number }>
} & BaseDataLayerObject

type FlightResultsDataLayerObject2 = {
  booking_start_location: string
  from_date: string
  to_date?: string
  traveller_count: number
  search_state: string
  result_count: number
}

type FlightDetailsDataLayerObject = {
  flight_from: string
  flight_to: string
  flight_code: string
  flight_time: string
  flight_route: string
}

type FlightDetailsDataLayerObject2 = {
  collected_avios: number
  avios_percentage: number
  items: FlightDetailsItemDataLayerObject[]
}

type FlightSelectionDataLayerObject = {
  event: string
  currency: string
  items: FlightSelectionDataLayerObject2
}

type FlightSelectionDataLayerObject2 = {
  item_id: string
  item_name: string
  item_brand: string
  item_category: string
  avios_value: number
  cash_value: number
  avios_percentage: number
  quantity: number
}

type FlightContinueToBasketDataLayerObject = {
  event: string
  currency?: string
  value: number
  avios_value: number
  cash_value: number
  avios_percentage: number
  items: FlightContinueToBasketDataLayerItemObject[]
}

type FlightContinueToBasketDataLayerItemObject = {
  item_id: string
  item_name: string
  item_brand?: string
  item_category: string
  avios_value: number
  cash_value: number
  collected_avios?: number
  price: number
  avios_percentage: number
  quantity: number
}

type FlightDetailsItemDataLayerObject = {
  index: number
  item_name: string
  item_id: string
  item_brand: string
  item_category: string
  item_list_name: string
  item_list_id: string
  price: number
  quantity: number
}

type HotelSearchDataLayerObject = {
  room_capacity: number
  amenities: string
  refundable: string
  bed_size: string
} & SearchDataLayerObject

export type HotelShareDataLayerObject = {
  channel: string
  nav_element: string
  nav_type: string
  nav_action?: string
  pageLocation: string
  hotel_name: string
  traveller_count: number
  checkInDate: string
  checkOutDate: string
  guest_rating: string
  star_rating: string
}

type HotelDataLayerObject = {
  name?: string
  propertyRating?: number
  guestRating?: number
  amenities?: Array<{ title: string; amenities?: Array<string> }>
}

type CarHireDetailsDataLayerObject = {
  carHire?: {
    name?: string
    vendorName?: string
    seats?: string
    aircon?: string
    doors?: string
    vehicleClassName?: string
    vehicleTransmission?: string
  }
}

export type CarHireSearchDataLayerObject = {
  different_drop_off?: string
  driver_under_30?: string
  driver_dob?: string
  seats?: number
  transmission?: string
  mileage_allowance?: string
  door_count?: number
  luggage_capacity?: string
  insurance_cover?: boolean
  additional_driver?: boolean
  child_seat?: boolean
  air_con?: string
} & SearchDataLayerObject

export type ExperienceDetailsLoadObject = {
  ecommerce: {
    currency: string
    avios_value: number
    cash_value: number
    collected_avios: number
    value: number
    items: [
      {
        item_name?: string
        item_id?: string
        item_brand?: string
        item_category: string
        item_variant: string | null
        item_list_name: string
        item_list_id: string
        duration: string
        traveler_count: number
        price: number
        cash_value: number
        avios_value: number
        collected_avios: number
        quantity: number
        index: number
      }
    ]
  }
}

export type ExperienceSearchDataLayerObject = {
  duration?: string
  destination: string
  programmeCode: string
  page_location: string
  referrer: string
} & SearchDataLayerObject

export type ExperienceDetailDataLayerObject = {
  nav_type: string
  nav_action: string
  avios_value: number
  cash_value: number
  ticket_option: string
  traveller_count: number
  from_date: string
  to_date: string
  destination: string
  programmeCode: string
  memberID?: string
  booking_currency?: string
  currency?: string
  balance?: number | string
  channel: string
  page_location: string
  referrer: string
  domain_environment: string
}

export type ExperienceReplaceModalObject = {
  nav_type: string
  nav_action: string
  avios_value: string
  cash_value: string
  ticket_option: string
  traveller_count: number
  specials?: string | string[]
  budget_avios?: string | string[]
  budget_cash?: string | string[]
  duration?: string | string[]
  accessibility?: string | string[]
  from_date: string
  to_date: string
  destination: string
  programmeCode: string
  memberID?: string
  booking_currency?: string
  currency?: string
  balance?: number | string
  channel: string
  page_location: string
  referrer: string
  domain_environment: string
}

export type ExperienceEditSearchObject = {
  nav_type: string
  nav_action: string
  specials?: string | string[]
  budget_avios?: string | string[]
  budget_cash?: string | string[]
  duration?: string | string[]
  accessibility?: string | string[]
  from_date: string
  to_date: string
  destination: string
  programmeCode: string
  memberID?: string
  booking_currency?: string
  currency?: string
  balance?: number | string
  channel: string
  page_location: string
  referrer: string
  domain_environment: string
}

export type ExperienceSortByFilter = {
  nav_type: string
  nav_action: string
  sort_setting: string
  sort_state: string
  specials?: string | string[]
  budget_avios?: string | string[]
  budget_cash?: string | string[]
  duration?: string | string[]
  accessibility?: string | string[]
  from_date: string
  to_date: string
  destination: string
  programmeCode: string
  memberID?: string
  booking_currency?: string
  currency?: string
  balance?: number | string
  channel: string
  page_location: string
  referrer: string
  domain_environment: string
}

export type ExperiencePagination = {
  nav_type: string
  nav_action: string
  from_date: string
  to_date: string
  destination: string
  total_result_pages?: number
  shown_results?: number
  result_count?: number
  search_state: string
}

type ExperienceResultsDataLayerObject = {
  experience?: ExperienceSearchDataLayerObject
  shownResults?: number
}

type PurchaseItemGA4 = {
  item_id: string
  item_name: string
  item_variant: string
  item_brand?: string
  quantity: number
  item_category: string
  price?: number
  cash_value?: number
  value?: number
  collected_avios?: number
  index?: number
}

export type HotelPurchaseItemGA4 = PurchaseItemGA4 & {
  room_capacity?: number
  bed_size?: string
  refundable?: string
}

export type CarHirePurchaseItemGA4 = PurchaseItemGA4 & {
  seats?: number
  transmission?: string
  mileage_allowance?: string
  door_count?: number
  air_con?: string
  luggage_capacity?: string
}

export type FlightPurchaseItemGA4 = PurchaseItemGA4 & {
  flight_route?: string
  filter_flight_type_outbound?: string
  filter_flight_type_inbound?: string
  adults?: number
  children?: number
}

export type PurchaseConfirmationGA4 = {
  ecommerce: {
    transaction_id: string
    currency: string
    value?: number
    tax?: number
    payment_type?: string
    items: (CarHirePurchaseItemGA4 | HotelPurchaseItemGA4 | FlightPurchaseItemGA4)[]
  }
}

export type HotelBasketAnalyticsItem = {
  free_wifi?: string
  front_desk_24h?: string
  express_checkout?: string
  laundry_service?: string
  spa?: string
  smoke_free?: string
  refundable?: string
  breakfast?: string
  bed_size?: string
  room_capacity?: number
  amenities?: string[]
  room_size?: number
}

export type ExperiencesBasketAnalyticsItem = {
  duration: string
  traveller_count: number
  item_list_name: string
  item_list_id: string
  price: number
}

export type CarHireBasketAnalyticsItem = {
  seats?: string
  transmission?: string
  mileage_allowance?: string
  door_count?: string
  air_con?: string
  luggage_capacity?: string
  insurance_cover?: string
  additional_driver?: string
  child_seat?: string
}

export type FlightBasketAnalyticsItem = {
  flight_route?: string
  filter_flight_type_outbound?: string
  filter_flight_type_inbound?: string
  adults?: string
  children?: string
}

export type BaseBasketAnalyticsItemGA4 = {
  item_id: string
  item_name: string
  item_variant?: string
  item_category?: string
  item_category2?: string
  item_category3?: string
  item_category4?: string
  item_brand?: string
  price?: number
  currency?: string
  quantity?: number | string
  avios_value?: number
  cash_value?: number
  collected_avios?: number
  taxes_and_fees?: number
  value?: number
  index?: number
}

export type HotelBasketAnalyticsItemGA4 = BaseBasketAnalyticsItemGA4 & HotelBasketAnalyticsItem
export type CarHireBasketAnalyticsItemGA4 = BaseBasketAnalyticsItemGA4 & CarHireBasketAnalyticsItem
export type FlightBasketAnalyticsItemGA4 = BaseBasketAnalyticsItemGA4 & FlightBasketAnalyticsItem
export type ExperiencesBasketAnalyticsItemGA4 = BaseBasketAnalyticsItemGA4 &
  ExperiencesBasketAnalyticsItem

export type BasketAnalyticsItemGA4 =
  | HotelBasketAnalyticsItemGA4
  | CarHireBasketAnalyticsItemGA4
  | FlightBasketAnalyticsItemGA4
  | ExperiencesBasketAnalyticsItemGA4

export type BasketContentsGA4 = {
  ecommerce: {
    currency?: string
    value?: number
    avios_value?: number
    cash_value?: number
    collected_avios?: number
    items: (
      | HotelBasketAnalyticsItemGA4
      | CarHireBasketAnalyticsItemGA4
      | FlightBasketAnalyticsItemGA4
      | ExperiencesBasketAnalyticsItemGA4
    )[]
  }
}

export type AddPaymentInfo = {
  ecommerce: {
    payment_type: string
    items: (HotelBasketAnalyticsItemGA4 | CarHireBasketAnalyticsItemGA4)[]
    // | ExperiencesBasketAnalyticsItemGA4
  }
}

type NewPageView = {
  channel?: string
}

export type Navigation = {
  nav_type: 'cta' | 'text link' | 'notification' | 'footer link' | 'section link' | 'banner'
  nav_action: string
  nav_element?: string
  menu_level?: string
  cancellation_policy?: string
  page_location?: string
  referrer?: string
  domain_env?: string
  programmeCode?: string
  channel?: string
}

export type PopularDestinationNavigation = Navigation & {
  destination?: string
  departure_date?: string
  arrival_date?: string
  origin?: string
}

export type SecureFlightNavigation = Navigation & {
  gender_provided: string
  dob_provided: string
}

export type ViewTerms = {
  terms_type: string
}

export type SortResults = {
  sort_setting: string
  sort_state: string
}

export type SerpSortDataLayerObject = {
  channel: string
  product: string
  search_state: string
  sort_setting: string
}

export type Experiment = {
  experiment_id: string
  experiment_name: string
  experiment_variant: string
  experiment_variant_id?: string
  experiment_campaign?: string
}

export type SerpFilterDataLayerObject = {
  channel?: Channel
  product?: string
  search_state?: SearchState
  accessibility?: string
}

export type HotelsFilterDataLayerObject = {
  property_type?: string
  guest_rating?: string
  star_rating?: string
  meal_plans?: string
  amenities?: string
} & SerpFilterDataLayerObject

export type CarHireFilterDataLayerObject = {
  fuel_type?: string
  gearbox?: string
  vendor?: string
  size?: string
} & SerpFilterDataLayerObject

export type ExperiencesFilterDataLayerObject = {
  specials?: string
  budget_avios?: string
  budget_cash?: string
  duration?: string
} & SerpFilterDataLayerObject

export type HotelVariant = {
  hotel_name: string
  variant_name: string
  variant_context: string
}

export type OrderHistory = {
  total_bookings: number
}

export type PromotionLayerObject = {
  promoCode: string
  promoName: string
  promoType: string
  promoProduct: string
}

export type DataLayerObject =
  | SearchDataLayerObject
  | gtmGA3
  | gtmGA4
  | FlightSearchDataLayerObject
  | FlightResultsDataLayerObject
  | FlightResultsDataLayerObject2
  | FlightSelectionDataLayerObject
  | FlightSelectionDataLayerObject2
  | FlightContinueToBasketDataLayerObject
  | FlightContinueToBasketDataLayerItemObject
  | FlightDetailsDataLayerObject
  | FlightDetailsDataLayerObject2
  | HotelSearchDataLayerObject
  | CarHireDetailsDataLayerObject
  | CarHireSearchDataLayerObject
  | PurchaseConfirmationGA4
  | ExperienceSearchDataLayerObject
  | ExperienceResultsDataLayerObject
  | ExperienceEditSearchObject
  | ExperienceSortByFilter
  | ExperienceDetailDataLayerObject
  | ExperienceReplaceModalObject
  | ExperienceDetailsLoadObject
  | ExperiencePagination
  | BasketContentsGA4
  | AddPaymentInfo
  | Navigation
  | NewPageView
  | ViewTerms
  | SortResults
  | Experiment
  | HotelShareDataLayerObject
  | HotelVariant
  | OrderHistory
  | SecureFlightNavigation
  | PopularDestinationNavigation
  | PromotionLayerObject
  | SerpSortDataLayerObject
  | SerpFilterDataLayerObject
  | HotelsFilterDataLayerObject
  | CarHireFilterDataLayerObject
  | ExperiencesFilterDataLayerObject

export const getGoogleTagManagerScript = (partner?: PartnerConfig) => {
  const emptyFunction = `(function(){});`
  const id = getGtmId(partner)

  if (!id && !isLocal()) {
    // eslint-disable-next-line no-console
    console.warn('Google tag manager is missing configuration')
    return emptyFunction
  }

  return `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({
        isLoggedIn: false,
        Iso: 'GB',
        product: 'reward_platform',
        programmeCode: null,
        memberID: null,
        balance: null,
        domain_environment: null,
        channel: null,
      });w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer', '${id}');
    `
}
const createDataLayerObject = (data?: DataLayerObject) => {
  return {
    ...data,
  }
}

export const pushToDataLayer = (data?: DataLayerObject): void => {
  if (isClient()) {
    window.dataLayer = window.dataLayer ?? []
    window.dataLayer.push(createDataLayerObject(data))
  }
}

export const pushEventToDataLayer = (eventName: string, data?: DataLayerObject): void => {
  if (isClient()) {
    window.dataLayer = window.dataLayer ?? []
    window.dataLayer.push({ event: eventName, ...createDataLayerObject(data) })
  }
}

export const updateEventInDataLayer = (eventName: string, data?: DataLayerObject): void => {
  if (!isClient) {
    return
  }
  window.dataLayer = window.dataLayer ?? []
  const eventIndex = window.dataLayer.findIndex((layer) => layer.event === eventName)
  if (eventIndex > -1) {
    const layer = window.dataLayer[eventIndex]
    window.dataLayer[eventIndex] = { ...layer, ...createDataLayerObject(data) }
  } else {
    pushEventToDataLayer(eventName, data)
  }
}

export const compareAndPushEventInDataLayer = (eventName: string, data?: DataLayerObject): void => {
  if (!isClient) {
    return
  }
  window.dataLayer = window.dataLayer ?? []

  const eventIndex = window.dataLayer.findIndex((layer) => layer.event === eventName)

  if (eventIndex > -1) {
    const layer = window.dataLayer[eventIndex] as DataLayerObject
    const compareLayer = omit(layer, ['event', 'gtm.uniqueEventId'])
    if (JSON.stringify(data) !== JSON.stringify(compareLayer)) {
      pushEventToDataLayer(eventName, data)
    }
  } else {
    pushEventToDataLayer(eventName, data)
  }
}

export const resetAndPushNewPageView = (data?: DataLayerObject): void => {
  if (isClient()) {
    pushEventToDataLayer('new_page_view', data)
  }
}
