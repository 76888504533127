import { ComponentMultiStyleConfig } from '@chakra-ui/react'

export const SpinnerOverlay: ComponentMultiStyleConfig = {
  parts: ['wrapper', 'header', 'subheader'],
  baseStyle: {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100vh',
      alignItems: 'center',
      justifyContent: 'center',
    },
    header: {
      fontWeight: 500,
      display: 'flex',
      textAlign: 'center',
    },
    subheader: {
      fontWeight: 500,
      display: 'flex',
      textAlign: 'center',
      marginBottom: '2rem',
      marginTop: 0,
    },
  },
}
