import React from 'react'
import { Alert, AlertDescription, AlertIcon } from '@reward-platform/lift/components'
import { usePromotionContext } from '~/context/promotion'
import { DynamicVariableRichText } from '../shared/DynamicVariableRichText'
import { PromotionProps, PromotionItemProps } from './Promotion.types'

const PromotionBanner = ({ children, product }: React.PropsWithChildren<PromotionProps>) => {
  const { isEnabled } = usePromotionContext()

  if (!isEnabled(product)) {
    return null
  }

  return (
    <Alert status="neutral" variant="promo" marginTop={8} marginBottom={10}>
      <AlertIcon iconName="gift" iconSize="lg" />
      <AlertDescription fontSize="sm" fontWeight="400">
        {children}
      </AlertDescription>
    </Alert>
  )
}

export const PromotionInlineBanner = ({ product }: PromotionProps) => {
  const { getProductPromotion } = usePromotionContext()
  const activePromotion = getProductPromotion(product)
  const { inlineBannerText } = activePromotion?.content || {}

  if (!inlineBannerText) {
    return null
  }

  return (
    <PromotionBanner product={product}>
      <DynamicVariableRichText
        document={inlineBannerText.content}
        variableMap={activePromotion?.variableMap}
      />
    </PromotionBanner>
  )
}

export const PromotionConfirmationBanner = ({ product, item }: PromotionItemProps) => {
  const { getProductPromotion } = usePromotionContext()
  const { content, variableMap, isItemValidForPromotion } = getProductPromotion(product) || {}
  const { confirmationBannerText } = content || {}
  const isItemValid = isItemValidForPromotion?.(item)

  if (!confirmationBannerText || !isItemValid) {
    return null
  }

  return (
    <PromotionBanner product={product}>
      <DynamicVariableRichText
        document={confirmationBannerText.content}
        variableMap={variableMap}
      />
    </PromotionBanner>
  )
}
