import { Icon, IconProps, useMultiStyleConfig } from '@chakra-ui/react'
import { convertIconSetToChakraSVG } from '../Icon/Icon.utils'
import { IconName } from '../Icon/Icon.types'
import { IconSize, IconSizes } from '../../theme/iconSize'
import { useAlertContext } from './AlertProvider'

type AlertIconProps = IconProps & {
  iconName: IconName
  iconSize: IconSize
}

export const AlertIcon = (props: AlertIconProps) => {
  const { iconName, iconSize } = props
  const { status, variant } = useAlertContext()
  const styles = useMultiStyleConfig('Alert', { variant })

  return (
    <Icon
      data-status={status}
      as={convertIconSetToChakraSVG(iconName)}
      boxSize={IconSizes[iconSize]}
      __css={styles.icon}
    />
  )
}
