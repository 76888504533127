/* eslint-disable sonarjs/no-duplicate-string */
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'flightCardContainer',
  'offPeak',
  'aviosOnlyContainer',
  'titleContainer',
  'icon',
  'textContainer',
  'flightInfoContainer',
  'pricesContainer',
  'topCardWrapper',
  'departureTime',
  'arrowLine',
  'arrowPointer',
  'iconWrapper',
  'duration',
  'arrowWrapper',
  'lineWrapper',
  'arrivalTime',
  'departureLocation',
  'stops',
  'arrivalLocation',
  'flightDetailsButton',
  'flightNumber',
  'flightCarrier',
  'operatedBy',
  'operatedByLogo',
  'bottomCardWrapper',
])

export const FlightCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    flightCardContainer: {
      display: 'flex',
      flexDirection: {
        base: 'column',
        lg: 'row',
      },
      overflow: 'hidden',
    },
    offPeak: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 10,
      marginBottom: 0,
      padding:
        'var(--chakra-space-1) var(--chakra-space-1) var(--chakra-space-1) var(--chakra-space-2)',
      width: 'fit-content',
      borderTopLeftRadius: 'base',
      borderTopRightRadius: 'base',
      color: 'brand.white',
      fontSize: 'sm',

      button: {
        marginLeft: 'var(--chakra-space-1)',
      },
    },
    aviosOnly: {
      borderRadius: '0 0 var(--chakra-radii-2xl) var(--chakra-radii-2xl)',
    },
    flightInfoContainer: {
      display: 'grid',
      gridTemplateAreas:
        "'departure-time line duration arrow arrival-time' 'departure-location . stops . arrival-location' 'button button . flight-carrier flight-carrier'",
      minWidth: {
        base: 'unset',
        // Make sure the flight info doesn't get squeezed by the pricing info
        // This is related to PricesContainer flex:1, which makes the cabin classes
        // take all space available
        lg: '25em',
      },
      padding: 6,
      gap: 1,
      color: 'brand.coolgrey10',
      fontSize: 'sm',
    },
    pricesContainer: {
      display: 'flex',
      flexDirection: {
        base: 'column',
        md: 'row',
      },
      flex: 1,
      color: 'brand.coolgrey10',

      '> *': {
        // Make sure each price class has the same size
        flex: 1,
        borderWidth: '1px',
        borderColor: 'brand.coolgrey2',
      },
    },
    topCardWrapper: {
      gridArea: '1 / 1 / 2 / 6',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      fontSize: 'xl',
    },
    departureTime: {
      gridArea: 'departure-time',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      color: 'brand.black',
      fontWeight: 'medium',
    },
    arrowLine: {
      width: 'full',
      height: 'full',
      clipPath: 'polygon(0% 48%, 100% 48%, 100% 52%, 0% 52%)',
    },
    arrowPointer: {
      alignSelf: 'center',
      clipPath: 'polygon(0% 0%, 100% 50%, 0% 100%)',
      height: '1ch',
      width: '0.866ch',
    },
    iconWrapper: {
      opacity: 0.5,
    },
    duration: {
      gap: 2,
      gridArea: 'duration',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      fontSize: 'sm',
    },
    arrowWrapper: {
      margin: 4,
      gridArea: 'arrow',
      display: 'flex',
      width: 'full',
      height: 'full',
    },
    lineWrapper: {
      margin: 4,
      gridArea: 'line',
      height: 'full',
      width: 'full',
    },
    arrivalTime: {
      gridArea: 'arrival-time',
      justifyContent: 'end',
      color: 'brand.black',
      fontWeight: 'medium',
    },
    departureLocation: {
      gridArea: 'departure-location',
      textAlign: 'left',
    },
    stops: {
      gridArea: 'stops',
      textAlign: 'center',
    },
    arrivalLocation: {
      gridArea: 'arrival-location',
      textAlign: 'right',
    },
    flightCarrier: {
      gridArea: 'flight-carrier',
      display: 'grid',
      gap: 1,
      gridTemplateColumns: '1fr auto',
      gridTemplateAreas: {
        base: "'flight-number operated-by-logo' 'operated-by operated-by'",
        sm: "'flight-number operated-by operated-by-logo'",
      },
    },
    flightNumber: {
      gridArea: 'flight-number',
      color: 'brand.black',
      fontWeight: 'medium',
      justifySelf: 'end',
    },
    operatedBy: {
      gridArea: 'operated-by',
      justifySelf: 'end',
      textAlign: 'right',
      textWrap: 'balance',
    },
    operatedByLogo: {
      gridArea: 'operated-by-logo',
    },
    bottomCardWrapper: {
      gridArea: '3 / 1 / 4 / 6',
      display: 'flex',
      alignItems: 'end',
      justifyContent: 'space-between',
      marginTop: 2,
    },
    soldOut: {
      border: '1px solid',
      borderRadius: 'md',
      fontSize: 'sm',
      minHeight: { base: '3.5rem', md: '8.5rem', lg: 'auto' },
    },
  },
  variants: {
    aviosOnlyHighlightAllSoldOut: {
      flightInfoContainer: {
        opacity: 0.7,
        pointerEvents: 'none',
      },
      flightCardContainer: {
        borderTopRadius: 0,
        borderTop: 'none',
      },
    },
    soldOut: {
      flightInfoContainer: {
        opacity: 0.7,
        pointerEvents: 'none',
      },
    },
    aviosOnlyHighlight: {
      flightCardContainer: {
        borderTopRadius: 0,
        borderTop: 'none',
      },
    },
  },
})
