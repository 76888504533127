import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const fieldSetHelper = createMultiStyleConfigHelpers([
  'fieldset',
  'hiddenLegend',
  'visibleLegend',
])

export const Fieldset = fieldSetHelper.defineMultiStyleConfig({
  baseStyle: fieldSetHelper.definePartsStyle({
    fieldset: {
      padding: 0,
      border: 'none',
    },
    hiddenLegend: {
      /* only available for a11y */
      position: ' absolute',
      overflow: 'hidden',
      width: 1,
      height: 1,
      whiteSpace: 'nowrap',
      clip: 'rect(0 0 0 0)',
      clipPath: 'inset(50%)',
    },
    visibleLegend: {
      mb: 4,
    },
  }),
})
