import { helpers } from './base'

const border = 'var(--chakra-space-px) solid var(--chakra-colors-brand-baseGray-100)'
const boxShadow = 'brand.boxShadow'
const highlightBlue = 'brand.highlight.blue'
const primaryBlue = 'brand.primary.blue'
const midnightBlue = 'brand.primary.midnightBlue'
const whiteBorder = '6px solid var(--chakra-colors-brand-white)'
const midnightBlueDisabled = 'rgba(var(--chakra-colors-rgb-midnightBlue), 0.7)'
const greyDisabled = 'rgba(var(--chakra-colors-rgb-gray100), 0.7)'

const checkedDisabled = {
  bg: greyDisabled,
  border: whiteBorder,
  color: 'rgba(var(--chakra-colors-rgb-white), 0.7)',
  outline: border,
}

const checkedHovered = {
  bg: primaryBlue,
  border: whiteBorder,
  outline: 'var(--chakra-space-px) solid var(--chakra-colors-brand-primary-blue)',
}

export const RadioButton = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    control: {
      border,
      boxShadow,
      bg: 'brand.white',
      _checked: {
        bg: 'brand.primary.midnightBlue',
        border: whiteBorder,
        outline: border,
        outlineOffset: '-1px',
        _disabled: {
          bg: greyDisabled,
          borderColor: 'brand.white',
          _hover: {
            '> div': {
              ...checkedDisabled,
            },
          },
        },
      },
      _pressed: {
        borderColor: highlightBlue,
      },
      _disabled: {
        borderColor: greyDisabled,
      },
      _focus: {
        borderColor: midnightBlue,
      },
    },
    icon: {
      display: 'none',
    },
    container: {
      color: midnightBlue,
      'span > span': {
        fontWeight: 'var(--chakra-fontWeights-light)',
      },
      _checked: {
        _hover: {
          '> div': {
            ...checkedHovered,
            _disabled: {
              ...checkedDisabled,
            },
          },
        },
        _focusWithin: {
          outlineColor: { base: 'none', md: primaryBlue },
          '> div': {
            ...checkedHovered,
            boxShadow,
          },
        },
      },
      _focusWithin: {
        outline: {
          base: 0,
          md: 'var(--chakra-space-px) solid var(--chakra-colors-brand-primary-midnightBlue)',
        },
        outlineOffset: { base: 0, md: 5 },
        borderRadius: 1,
      },
      _hover: {
        '> div': {
          borderColor: midnightBlue,
        },
      },
      _disabled: {
        color: midnightBlueDisabled,
        _hover: {
          '> div': {
            borderColor: greyDisabled,
          },
        },
      },
    },
  }),
})
