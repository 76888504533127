import { checkboxHelper } from './base'

const borderColor = 'var(--chakra-colors-brand-baseGray-100)'
const border = `var(--chakra-space-px) solid ${borderColor}`
const boxShadow = 'brand.boxShadow'
const highlightBlue = 'brand.highlight.blue'
const primaryBlue = 'brand.primary.blue'
const midnightBlue = 'brand.primary.midnightBlue'
const midnightBlueDisabled = 'rgba(var(--chakra-colors-rgb-midnightBlue), 0.7)'
const greyDisabled = 'rgba(var(--chakra-colors-rgb-gray100),0.7)'
const checkedDisabled = {
  bg: midnightBlueDisabled,
  color: 'rgba(var(--chakra-colors-rgb-white), 0.7)',
  borderColor: greyDisabled,
}
export const Checkbox = checkboxHelper.defineMultiStyleConfig({
  baseStyle: checkboxHelper.definePartsStyle({
    control: {
      border,
      borderColor,
      boxShadow,
      borderRadius: 2,
      svg: {
        h: `${2.5} !important`,
        w: `${2.5} !important`,
      },
      _checked: {
        bg: midnightBlue,
        borderColor: midnightBlue,
        _disabled: {
          ...checkedDisabled,
        },
      },
      _pressed: {
        bg: highlightBlue,
      },
      _disabled: {
        borderColor: greyDisabled,
        bg: 'brand.white',
      },
      _focus: {
        borderColor: midnightBlue,
      },
    },
    container: {
      '.chakra-checkbox__label span': {
        fontWeight: 'var(--chakra-fontWeights-light)',
      },
      _checked: {
        _hover: {
          '.chakra-checkbox__control': {
            bg: primaryBlue,
            borderColor: primaryBlue,
            _disabled: {
              ...checkedDisabled,
            },
          },
        },
        _focusWithin: {
          outlineColor: { base: 'none', md: primaryBlue },
          '.chakra-checkbox__control': {
            bg: primaryBlue,
            boxShadow,
          },
        },
      },
      _focusWithin: {
        outline: {
          base: 0,
          md: 'var(--chakra-space-px) solid var(--chakra-colors-brand-primary-blue)',
        },
        outlineOffset: { base: 0, md: 5 },
        borderRadius: 1,
      },
      _hover: {
        '.chakra-checkbox__control': {
          borderColor: primaryBlue,
        },
      },
      _disabled: {
        color: midnightBlueDisabled,
        _hover: {
          '.chakra-checkbox__control': {
            borderColor: greyDisabled,
          },
        },
      },
    },
  }),
})
