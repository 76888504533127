import { ancillaryBasketItemDetailsHelpers } from './base'

const midnightBlue = 'brand.primary.midnightBlue'

export const AncillaryBasketItemDetails = ancillaryBasketItemDetailsHelpers.defineMultiStyleConfig({
  baseStyle: ancillaryBasketItemDetailsHelpers.definePartsStyle({
    content: {
      color: midnightBlue,
    },
    header: {
      '> div:first-of-type': {
        '> * ': {
          color: midnightBlue,
        },
        h4: {
          fontSize: 'xl',
          color: midnightBlue,
        },
        h5: {
          mt: 1,
          color: midnightBlue,
        },
        'div, p,': {
          fontSize: 'sm',
          color: midnightBlue,
        },
      },
    },
  }),
})
