import { overlayTipHelper } from './base'

const primaryBlue = 'brand.primary.blue'

export const OverlayTip = overlayTipHelper.defineMultiStyleConfig({
  baseStyle: overlayTipHelper.definePartsStyle({
    containerBorder: {
      borderColor: primaryBlue,
      borderRadius: 8,
    },
  }),
})
