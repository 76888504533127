import React, { PropsWithChildren, useState } from 'react'
import { Box, FlexProps, Icon, useMultiStyleConfig } from '@chakra-ui/react'
import { convertIconSetToChakraSVG } from '../Icon/Icon.utils'
import { IconName } from '../Icon/Icon.types'
import { IconSizes } from '../../theme/iconSize'

export type StickyBannerProps = {
  icon?: IconName
  isClosable?: boolean
}

function StickyContainer({ children, ...rest }: PropsWithChildren & FlexProps) {
  const styles = useMultiStyleConfig('StickyBanner', {})
  return (
    <Box sx={styles.container} {...rest}>
      {children}
    </Box>
  )
}

export default function StickyBanner({
  children,
  icon,
  isClosable,
}: PropsWithChildren<StickyBannerProps>) {
  const styles = useMultiStyleConfig('StickyBanner')
  const [isOpen, setIsOpen] = useState(true)

  if (!isOpen) {
    return null
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.banner} data-testid="sticky-banner">
        {icon ? (
          <Icon
            aria-hidden
            boxSize={IconSizes.lg}
            sx={styles.icon}
            as={convertIconSetToChakraSVG(icon)}
          />
        ) : null}
        <Box sx={styles.message}>{children}</Box>
        {isClosable ? (
          <Icon
            boxSize={IconSizes.md}
            aria-label="close banner"
            onClick={handleClose}
            sx={styles.crossIcon}
            as={convertIconSetToChakraSVG('cross')}
          />
        ) : null}
      </Box>
    </Box>
  )
}

StickyBanner.StickyContainer = StickyContainer
