import { helpers } from './base'

const unselectedBorder = '1px solid var(--chakra-colors-brand-coolgrey2)'
export const RoomRateSelector = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    optionContainerUnselected: {
      border: unselectedBorder,
      _disables: {
        border: unselectedBorder,
      },
    },
    optionContainerSelected: {
      border: '1px solid var(--chakra-colors-brand-darkCityGreen)',
      _disabled: {
        border: unselectedBorder,
      },
    },
    optionIndicator: {
      background: 'brand.primaryTeal',
      color: 'brand.white',
    },
    soldOutOptionIndicator: {
      backgroundColor: 'brand.coolgrey2',
      color: 'brand.black',
    },
    inlineText: { fontSize: 'sm' },
    boldText: { fontSize: 'sm' },
    semiboldText: { fontSize: 'sm' },
  }),
})
