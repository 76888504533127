import { helpers } from './base'

export const QuantityPicker = helpers.defineMultiStyleConfig({
  baseStyle: {
    quantityPickerContainer: {
      display: 'flex',
      alignItems: 'center',
      button: {
        border: 'none',
        padding: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        _active: {
          background: 'none',
        },
        _focus: {
          background: 'none',
        },
      },
    },
    quantityPickerButton: {
      color: 'brand.primaryTeal',
      _disabled: {
        color: 'brand.coolgrey2',
      },
    },
  },
  variants: {
    success: {
      quantityPickerButton: {
        color: 'none',
      },
    },
    error: {
      quantityPickerButton: {
        color: 'brand.red',
      },
    },
  },
  defaultProps: {
    variant: 'success',
  },
})
