import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'experienceTitleContainer',
  'ratingsAndChips',
  'address',
  'icons',
  'experienceTitle',
])

export const ExperienceTitle = helpers.defineMultiStyleConfig({
  baseStyle: {
    ratingsAndChips: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    address: {
      display: 'block',
      fontSize: 'sm',
    },
    icons: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  },
})
