import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useNotification } from '~/components/shared/Notifications'
import { usePartner } from '~/context/partner'
import { useUser } from '~/context/user'
import { getDisruptionMessages } from '~/services/contentService'

const useDisruptionMessages = ({ enabled = true } = {}) => {
  const { userLocale } = useUser()
  const partner = usePartner()
  const { addNotification } = useNotification()

  const { data: disruptionMessages, isFetched: disruptionMessageFetched } = useQuery({
    queryKey: ['disruptionMessage'],
    queryFn: () => getDisruptionMessages(partner.tag, userLocale),
    enabled,
    meta: {
      notifyUser: false,
      errorMessage: 'Content Error - could not get disruption messages',
    },
  })

  useEffect(() => {
    if (disruptionMessageFetched && disruptionMessages) {
      disruptionMessages.forEach(({ message, notificationMessage }) => {
        if (message) {
          addNotification(message, 'INFO', undefined, notificationMessage)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disruptionMessageFetched, disruptionMessages])
}

export default useDisruptionMessages
