/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const PriceInfo = helpers.defineMultiStyleConfig({
  baseStyle: {
    iconWrapper: {
      '.chakra-popover__content': {
        color: 'brand.black',
        '.chakra-icon': {
          color: 'brand.primaryShamrockGreen',
        },
      },
    },
    priceInfoContainer: {
      '.chakra-icon': {
        color: 'brand.darkCityGreen',
      },
      _focusVisible: {
        borderColor: 'brand.darkCityGreen',
        outline: '2px solid',
        outlineColor: 'brand.darkCityGreen',
        outlineOffset: '1px',
      },
      _hover: {
        backgroundColor: { base: 'brand.teal5', lg: 'brand.darkCityGreen' },
        borderColor: { base: 'transparent', lg: 'brand.darkCityGreen' },
        color: { base: 'unset', lg: 'brand.white' },
      },
      _selected: {
        backgroundColor: 'brand.darkCityGreen',
        color: 'brand.white',

        '.chakra-icon:not(.popover)': {
          color: 'brand.cityGreen',
        },

        _focusVisible: {
          backgroundColor: 'brand.primaryTeal',
          borderColor: 'brand.primaryTeal',
        },

        _hover: {
          backgroundColor: 'brand.cityGreen',
          borderColor: 'brand.cityGreen',
        },
      },
      backgroundColor: 'brand.teal5',
      border: '1px solid transparent',
      borderRadius: '2xl',
      color: 'brand.black',
      transition: 'background-color 0.25s ease, outline 0.25s ease, color 0.25s ease',
    },
    labelWrapper: {
      '> div': {
        backgroundColor: 'brand.white',
        fontSize: 'xs',
        lineHeight: '1.5rem',
      },
    },
    baggageIconContainer: {
      borderColor: 'brand.cityGreen',
      backgroundColor: 'brand.white',
      transition: 'background-color 0.25s ease, outline 0.25s ease, color 0.25s ease',

      '.chakra-icon:not(.popover)': {
        borderColor: 'brand.cityGreen',
        color: 'brand.cityGreen',
      },

      _hover: {
        backgroundColor: 'brand.cityGreen',

        '.chakra-icon:not(.popover)': {
          borderColor: 'brand.white',
          color: 'brand.white',
        },
      },

      _disabled: {
        borderColor: 'brand.coolgrey4',
        backgroundColor: 'brand.coolgrey1',
        boxShadow: 'none',

        '.chakra-icon:not(.popover)': {
          borderColor: 'brand.coolgrey4',
          color: 'brand.coolgrey4',
          boxShadow: 'none',
        },
      },
    },
  },
  variants: {
    selected: {
      baggageIconContainer: {
        _hover: {
          backgroundColor: 'brand.darkCityGreen',
          border: 'none',
          color: 'brand.white',
        },
      },
    },
  },
})
