import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'container',
  'bedIcon',
  'closeButton',
  'confirmButton',
  'heading',
  'description',
])

export const RoomsBanner = helpers.defineMultiStyleConfig({
  baseStyle: {
    heading: {
      fontSize: 'sm',
      lineHeight: 'xl',
      marginBottom: 2,
    },
    description: {
      fontSize: 'sm',
      lineHeight: '5',
      letterSpacing: 'normal',
    },
    container: {
      w: 'full',
      display: 'flex',
      padding: 4,
      borderRadius: 'sm',
      border: '1px solid',
      gap: 4,
    },
    closeButton: {
      marginLeft: 'auto',
      background: 'transparent',
      border: 'none',
      display: 'flex',
      cursor: 'pointer',
    },
    confirmButton: {
      fontSize: 'xs',
      fontWeight: 400,
      cursor: 'pointer',
      marginTop: 2.5,
      padding: 'var(--chakra-space-2) var(--chakra-space-5)',
      border: '1px solid',
      borderRadius: 'sm',
      background: 'brand.white',
    },
  },
  variants: {
    static: {
      container: {
        maxW: 'full',
        margin: 'var(--chakra-space-16) 0',
      },
    },
    floating: {
      container: {
        maxW: {
          base: 'full',
          md: '456px',
        },
        top: '74px',
        right: 0,
        pos: 'absolute',
        zIndex: 'docked',
        boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.16)',
      },
    },
  },
})
