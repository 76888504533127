import { helpers } from './base'

const black = 'brand.black'

export const Banner = helpers.defineMultiStyleConfig({
  baseStyle: {
    anchorWrapper: {
      backgroundColor: 'brand.yellow',
      color: black,
    },

    anchorIcon: {
      color: black,
    },

    anchorText: {
      color: black,
    },

    anchorIconText: {
      color: black,
    },
  },
})
