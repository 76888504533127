import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'container',
  'route',
  'airports',
  'oneWay',
  'datePicker',
  'submitButton',
])

export const SearchFlightForm = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      display: 'grid',
      gap: { base: 6 },
      gridTemplate: {
        base: `
          'route'
          'dates'
          'travelers'
          'search'`,
        lg: `'route route route route'
          'dates dates travelers search'`,
      },
      gridTemplateColumns: { base: `'1fr 1fr'`, lg: `1fr 1fr 1fr 1fr` },
    },
    route: {
      display: 'flex',
      gridArea: 'route',
      gap: 4,
      justifyContent: 'flex-end',
      flexDirection: { base: 'column-reverse', lg: 'row' },
    },
    airports: {
      display: 'flex',
      flexDirection: { base: 'column', md: 'row' },
      alignItems: 'baseline',
      width: 'full',
      gap: { base: 4, md: 6 },

      '> div': {
        width: 'full',
      },

      '> button': {
        alignSelf: 'flex-start',
      },
    },
    oneWay: {
      whiteSpace: 'nowrap',
      order: { base: 0, lg: 1 },
      padding: { base: '0 0 var(--chakra-space-2)', lg: 'var(--chakra-space-4) 0' },
    },
    equallySpacedDiv: {
      display: { base: 'contents', md: 'flex', lg: 'contents' },
      gap: { base: 4, md: 6 },
      '> *': {
        flex: 1,
      },
    },
    datePickers: {
      '.chakra-form__label': {
        paddingRight: '2.5rem',
        textOverflow: 'ellipsis',
      },
    },
    submitButton: {
      width: '100%',

      _disabled: {
        opacity: 0.5,
        pointerEvents: 'none',
      },
    },
  },
})
