import { helper } from './base'

export const Chip = helper.defineMultiStyleConfig({
  baseStyle: {
    chipWrapper: {
      borderRadius: 'xs',
      px: 'xs',
      fontWeight: 'medium',
    },
  },
  variants: {
    primary: {
      chipWrapper: {
        color: 'fg.accent.onSubtle',
        bgColor: 'bg.accent.subtle.default',
      },
    },
    secondary: {
      chipWrapper: {
        color: 'fg.inverse.onSubtle',
        bgColor: 'bg.inverse.subtle.default',
      },
    },
  },
})
