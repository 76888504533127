/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

const primaryTeal = 'var(--chakra-colors-brand-primaryTeal)'

export const DayList = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      color: 'brand.black',
    },
    solidLine: {
      backgroundColor: 'brand.primaryTeal',
    },
    dashedLine: {
      backgroundImage: `repeating-linear-gradient(0deg, transparent 0 4px, ${primaryTeal} 4px 12px)`,
    },
    circleIcon: {
      backgroundColor: 'brand.primaryTeal',
      color: 'brand.white',
    },
    location: {
      color: 'brand.coolgrey10',
    },
    description: {
      color: 'brand.coolgrey10',
    },
    iconContainer: {
      color: 'brand.primaryTeal',
    },
  },
})
