import { autoCompleteLocationOptionHelpers, autoCompleteHelpers } from './base'

const highlightBlue = 'var(--chakra-colors-brand-highlight-blue)'
const midnightBlue = 'var(--chakra-colors-brand-primary-midnightBlue)'

export const AutoCompleteLocationOption = autoCompleteLocationOptionHelpers.defineMultiStyleConfig({
  baseStyle: {
    locationOptionWrapper: {
      color: midnightBlue,
      borderColor: 'brand.baseGray.10',
      _hover: {
        color: `${highlightBlue} !important`,
        svg: {
          path: {
            fill: highlightBlue,
          },
        },
      },
      '&[data-highlighted=true]': {
        color: `${highlightBlue} !important`,
        svg: {
          path: {
            fill: highlightBlue,
          },
        },
      },
    },
    autoCompleteListTitle: {
      fontWeight: 'semibold',
    },
    autoCompleteListIcon: {
      svg: {
        path: {
          fill: midnightBlue,
        },
      },
    },
  },
})

export const AutoComplete = autoCompleteHelpers.defineMultiStyleConfig({
  baseStyle: {
    popup: {
      borderRadius: 'sm',
      boxShadow: 'brand.boxShadow',
    },
    buttonField: {
      button: {
        _hover: {
          border: 'unset',
          borderBottom: 'solid',
          borderColor: 'brand.highlight.blue',
          borderWidth: 'var(--chakra-borders-1px)',
        },
      },
    },
  },
})
