import { hotelBasketItemCardHelper } from './base'

export const HotelBasketItemCard = hotelBasketItemCardHelper.defineMultiStyleConfig({
  baseStyle: {
    content: {
      '> section': {
        color: 'fg.accent.secondary',
        fontWeight: 'normal',
        fontSize: 'sm',
        '> p': {
          fontWeight: 'semibold',
        },
        '> div': {
          mt: 0,
        },
      },
      '> div:first-of-type': {
        color: 'fg.secondary',
        '> div': {
          mb: '2xs',
        },
      },
    },
  },
})
