import { useCallback, useMemo } from 'react'
import { pushEventToDataLayer, Navigation, DataLayerObject } from '~/utils/googleTagManager'

type NavigationData = {
  navType: Navigation['nav_type']
  navAction: Navigation['nav_action']
  navElement?: Navigation['nav_element']
  extra?: Record<string, unknown>
}

const useNavigationAnalytics = () => {
  const onNavigate = useCallback(({ navType, navAction, navElement, extra }: NavigationData) => {
    try {
      pushEventToDataLayer('navigation', {
        nav_type: navType,
        nav_action: navAction,
        nav_element: navElement,
        ...extra,
      } as DataLayerObject)
    } catch (error: unknown) {
      // No op
    }
  }, [])

  return useMemo(
    () => ({
      onNavigate,
    }),
    [onNavigate]
  )
}

export default useNavigationAnalytics
