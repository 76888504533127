import { useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import { Button, Box, Center, useMultiStyleConfig, Skeleton } from '@chakra-ui/react'
import { SkipNavContent, SkipNavLink } from '@chakra-ui/skip-nav'
import Head from 'next/head'
import Footer from '~/components/shared/Footer/Footer'
import Header, { HeaderProps } from '~/components/shared/Header'
import { NotificationBar } from '~/components/shared/Notifications'
import SpinnerOverlay from '~/components/shared/SpinnerOverlay'
import { useIsPartnerAvios, usePartner } from '~/context/partner'
import { useAccountQuery, useUser, useUserQuery } from '~/context/user'
import { useAuthContext } from '~/context/auth'
import { TagManagerProvider } from '~/context/analytics'
import { useFeatureFlag } from '~/hooks/useFeatureFlag/useFeatureFlag'
import { selectTotalAccount } from '~/services/accountsService'
import { mapPathToPromotionProduct, PromotionStickyBanner } from '~/components/promotions'
import { StickyBanner } from '@reward-platform/lift/components'
import useIntl from '~/hooks/useIntl/useIntl'
import { useDisruptionMessages } from '~/hooks/useDisruptionMessages'
import useRedirectOnFeatureFlag from '~/hooks/useRedirectOnFeatureFlag/useRedirectOnFeatureFlag'
import { getAviosBaseUrl } from '~/utils/partner'
import PromotionFirstVisitModal from '~/components/promotions/PromotionFirstVisitModal'
import { getSharedSpacesEnvironment } from '~/utils/getSharedSpacesEnvironment'
import dynamic from 'next/dynamic'
import { LayoutProps } from './Layout.types'
import ChatWidgetMock from '../ChatWidgetMock'
import BARCMemberLayout from './BARCMemberLayout'

const SKIP_TO_CONTENT_ID = 'skip-to-content'
const SKIP_TO_FOOTER_ID = 'skip-to-footer'
const sharedSpacesAccessToken = process.env.NEXT_PUBLIC_SHARED_SPACES_CMS_ACCESS_TOKEN ?? ''

const SharedComponentsProvider = dynamic(
  () => import('@channel/shared-header-footer').then((module) => module.SharedComponentsProvider),
  { loading: () => <Skeleton margin="0" width="100%" height="100%" />, ssr: false }
)

type BaseLayoutProps = LayoutProps & {
  isLoading?: boolean
}

const useRedirectToAvios = (isAvios: boolean) => {
  const redirectUrl = getAviosBaseUrl()
  useRedirectOnFeatureFlag('redirect-rewards-to-avios', false, {
    redirectTo: redirectUrl,
    checkValue: true,
    isEnabled: !isAvios,
  })
}

const BaseLayout = ({
  children,
  title,
  sparse = false,
  variant = 'base',
  isLoading,
}: BaseLayoutProps): JSX.Element => {
  const { data: user, isLoading: isLoadingUser, failureCount: failureCountUser } = useUserQuery()
  const { data: accountsResponse, isLoading: isLoadingAccount } = useAccountQuery()
  const intl = useIntl()
  const orderHistoryEnabled = useFeatureFlag('order-history')

  const partner = usePartner()
  const router = useRouter()

  const isAvios = useIsPartnerAvios()
  useRedirectToAvios(isAvios)

  const totalAccount = accountsResponse && selectTotalAccount(accountsResponse)
  const { isAuthenticated } = useAuthContext()

  const isLoadingUserData = !user && (isLoadingUser || isLoadingAccount) && failureCountUser <= 1

  const isUserAuthenticated = isAuthenticated && !!user

  useEffect(() => {
    sessionStorage.setItem('authenticated_user', isUserAuthenticated ? 'true' : 'false')
  }, [isUserAuthenticated])

  const headerMenuOptions: HeaderProps['menuOptions'] = [
    {
      icon: 'file-text',
      label: intl.formatMessage({
        id: 'order-history',
        defaultMessage: 'Order History',
      }),
      href: '/order-history',
      opensInNewTab: false,
      isVisible: isUserAuthenticated && Boolean(orderHistoryEnabled),
    },
  ]
  const t = {
    skipToContentLabel: intl.formatMessage({
      id: 'app-skip-to-content-link',
      defaultMessage: 'Skip to main content',
    }),
    skipToFooterLabel: intl.formatMessage({
      id: 'app-skip-to-footer-link',
      defaultMessage: 'Skip to footer',
    }),
  }

  const styles = useMultiStyleConfig('Layout', { variant })

  const pageTitle = title ? `${partner.name} - ${title}` : `Avios - ${partner.name}`

  const promotionComponents = (
    <>
      <PromotionStickyBanner product={mapPathToPromotionProduct(router.asPath)} />
      <PromotionFirstVisitModal product={mapPathToPromotionProduct(router.asPath)} />
    </>
  )

  return (
    <TagManagerProvider>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>{pageTitle}</title>
        <meta
          name="description"
          content="Turn your everyday spending into exciting travel rewards - discover more &amp; shop with Avios."
        />
      </Head>
      <StickyBanner.StickyContainer>
        {promotionComponents}
        <NotificationBar />
      </StickyBanner.StickyContainer>
      <Box __css={styles.wrapper}>
        {!isAvios && (
          <SkipNavLink id={SKIP_TO_CONTENT_ID} sx={styles.skipLink}>
            <Button variant="link" tabIndex={-1}>
              {t.skipToContentLabel}
            </Button>
          </SkipNavLink>
        )}
        {!sparse && !isAvios ? (
          <SkipNavLink id={SKIP_TO_FOOTER_ID} sx={styles.skipLink}>
            <Button variant="link" tabIndex={-1}>
              {t.skipToFooterLabel}
            </Button>
          </SkipNavLink>
        ) : null}
        <>
          {sparse || (
            <Header
              user={
                user?.person?.personName && {
                  title: user.person.personName.title,
                  firstName: user.person.personName.firstName,
                  familyName: user.person.personName.familyName,
                  memberIdentifier: user.memberIdentifier?.identifier,
                }
              }
              accountBalanceAmount={totalAccount?.balance.amount}
              isLoading={isLoadingUserData}
              menuOptions={headerMenuOptions}
              skipContentSelector={SKIP_TO_CONTENT_ID}
            />
          )}
          <Box as="main" id="content" __css={styles.content}>
            <SkipNavContent id={SKIP_TO_CONTENT_ID} />
            {isLoading || (user === undefined && isAuthenticated) ? <SpinnerOverlay /> : children}
          </Box>
          {!isUserAuthenticated ? <ChatWidgetMock /> : null}
          {sparse || (
            <>
              <SkipNavContent id={SKIP_TO_FOOTER_ID} />
              <Footer />
            </>
          )}
        </>
      </Box>
    </TagManagerProvider>
  )
}

const Layout = (props: LayoutProps) => {
  const { user } = useUser()
  const environment = useMemo(() => getSharedSpacesEnvironment(), [])
  const isAvios = useIsPartnerAvios()

  useDisruptionMessages()

  const isBARCmember = user?.memberIdentifier?.identifier.startsWith('OWVE')

  if (isBARCmember) {
    return <BARCMemberLayout />
  }

  if (isAvios) {
    return (
      <SharedComponentsProvider
        cms={{
          accessToken: sharedSpacesAccessToken,
          environment,
        }}
      >
        <BaseLayout {...props} isLoading={false} />
      </SharedComponentsProvider>
    )
  }

  return <BaseLayout {...props} isLoading={false} />
}

export const AuthenticatedEmptyPageLayout = ({ children }: LayoutProps) => {
  const { user, userDataIsLoading } = useUser()
  const userIsLoaded = user && !userDataIsLoading

  return userIsLoaded ? (
    <Center h="100vh" p="6" flexDir="column">
      {children}
    </Center>
  ) : null
}

export default Layout
