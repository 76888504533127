import { helpers } from './base'

export const Layout = helpers.defineMultiStyleConfig({
  baseStyle: {
    wrapper: {
      bg: 'brand.baseGray.5',
    },
    skipLink: {
      outlineColor: 'brand.primary.blue',
      borderRadius: 'sm',
    },
    textBg: {
      backgroundColor: ' rgba(var(--chakra-colors-rgb-blue), 0.1)',
    },
    barcLayoutWrapper: {
      'a:visited': {
        background: 'brand.primary.blue',
      },
    },
  },
})
