import { Amount } from '@reward-platform/ancillaries-schemas/common'
import { CurrencyCodes } from '@reward-platform/ancillaries-schemas/country-currency-codes'

export const createCurrencyFormatter = (
  currencyCode = 'GBP',
  locale = 'en-GB',
  options: Intl.NumberFormatOptions = {}
) =>
  new Intl.NumberFormat(locale, {
    ...(currencyCode ? { style: 'currency', currency: currencyCode } : {}),
    minimumFractionDigits: 0,
    ...options,
  })

export const getAmountForCurrencyCode = (
  amounts: Amount[],
  currencyCode: CurrencyCodes,
  fallbackToFirst?: boolean
): Amount | undefined => {
  const currencyAmount = amounts.find((amount) => amount.currencyCode === currencyCode)
  if (currencyAmount) {
    return currencyAmount
  }
  if (fallbackToFirst) {
    return amounts[0]
  }
  return undefined
}
