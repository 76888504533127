/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const NotificationMessage = helpers.defineMultiStyleConfig({
  baseStyle: {
    iconWrapper: {
      px: 'xs',
    },
    notificationButton: {
      button: {
        textDecoration: 'underline',
        border: 'none',
        py: 0,
        height: 'unset',
        _hover: {
          bg: 'none',
          textDecoration: 'none',
        },
        _focusVisible: {
          boxShadow: 'none',
        },
        _focus: {
          outline: 'none',
          bg: 'none',
          boxShadow: 'none',
        },
        _active: {
          boxShadow: 'none',
          color: 'unset',
          bg: 'none',
        },
      },
    },
    notificationBody: {
      strong: {
        fontWeight: 'semibold',
      },
    },
  },
  variants: {
    ERROR: {
      notificationWrapper: {
        backgroundColor: 'bg.critical.subtle.default',
        color: 'fg.critical.onSubtle',
        svg: {
          color: 'fg.critical.onSubtle',
        },
      },
    },
    INFO: {
      notificationWrapper: {
        backgroundColor: 'bg.information.subtle.default',
        color: 'fg.information.primary',
        svg: {
          color: 'fg.information.primary',
        },
      },
    },
    SUCCESS: {
      notificationWrapper: {
        backgroundColor: 'bg.success.subtle.default',
        color: 'fg.success.primary',
        svg: {
          color: 'fg.success.primary',
        },
      },
    },
    WARNING: {
      notificationWrapper: {
        backgroundColor: 'bg.warning.subtle.default',
        color: 'fg.warning.onSubtle',
        svg: {
          color: 'fg.warning.onSubtle',
        },
      },
    },
  },
})
