import { useContext } from 'react'
import { PlatformError } from '~/utils/errors'
import { NotificationContext } from './NotificationContext'

export { NotificationBar } from './NotificationBar'
export { NotificationProvider } from './NotificationProvider'
export { notificationReducer } from './notificationReducer'

export const useNotification = (): NotificationContext => {
  const context = useContext(NotificationContext)
  if (!context) {
    throw new PlatformError('useNotification must be used within a NotificationContext')
  }

  return context
}
