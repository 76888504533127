import { z } from 'zod'

export const MediaListSchema = z.object({
  type: z.string(),
  method: z.string().optional(),
  href: z.string(),
})

export const MediaSchema = z.object({
  title: z.string().optional(),
  mediaSequence: z.number(),
  mediaList: z.array(MediaListSchema),
})

export type MediaList = z.infer<typeof MediaListSchema>
export type Media = z.infer<typeof MediaSchema>
