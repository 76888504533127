/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

const fontBodyXs = {
  fontSize: 'var(--alto-sem-text-body-xs-font-size)',
  lineHeight: 'var(--alto-sem-text-body-xs-line-height)',
  letterSpacing: 'var(--alto-sem-text-body-xs-letter-spacing)',
}

export const RoomRateSelector = helpers.defineMultiStyleConfig({
  baseStyle: {
    optionContainerUnselected: {
      borderRadius: 'sm',
      overflow: 'hidden',
      border: 'var(--alto-sem-border-width-sm) solid',
      borderColor: 'border.primary.default',
      _disabled: {
        border: 'border.disabled.vibrant',
      },
    },
    optionContainerSelected: {
      borderRadius: 'sm',
      overflow: 'hidden',
      border: 'var(--alto-sem-border-width-sm) solid',
      borderColor: 'border.accent',
      _disabled: {
        border: 'border.disabled.vibrant',
      },
    },
    optionIndicator: {
      background: 'bg.accent.vibrant.default',
      color: 'fg.accent.onVibrant',
      ...fontBodyXs,
    },
    soldOutOptionIndicator: {
      backgroundColor: 'bg.disabled.vibrant',
      color: 'fg.disabled.onVibrant',
    },
    inlineText: {
      ...fontBodyXs,
    },
    or: {
      color: 'fg.accent.secondary',
    },
    boldText: {
      fontSize: 'var(--alto-sem-text-heading-2xs-font-size)',
      lineHeight: 'var(--alto-sem-text-heading-2xs-line-height)',
      letterSpacing: 'var(--alto-sem-text-heading-2xs-letter-spacing)',
      fontWeight: 'semibold',
      color: 'fg.accent.primary',
    },
    semiboldText: {
      ...fontBodyXs,
      fontWeight: 'normal',
    },
    hidableIcon: {
      width: 'sm',
      height: 'sm',
      mr: {
        base: '2xs',
        sm: '2xs',
      },
    },
  },
})
