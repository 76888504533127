/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const RoomDetailsCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    title: {
      fontWeight: 'light',
      fontSize: '2xl',
      lineHeight: 9,
      color: 'brand.primary.midnightBlue',
    },
    container: {
      padding: {
        base: 0,
        md: 6,
      },
      borderRadius: 'sm',
      boxShadow: 'brand.boxShadow',
      background: 'brand.white',
      color: 'brand.primary.midnightBlue',
    },
    infoContainer: {
      padding: {
        base: 4,
        md: 0,
      },
      paddingTop: 0,
    },
    optionsContainer: {
      padding: {
        base: 4,
        md: 0,
      },
      paddingTop: 0,
    },
    infoListItem: {
      color: 'brand.primary.midnightBlue',
      fontSize: 'md',
      fontWeight: 'light',
    },
    duration: {
      fontSize: 'xs',
      lineHeight: 'shorter',
      marginBottom: 3.5,
      strong: {
        fontWeight: 'semibold',
      },
    },
    bookButton: {
      width: {
        base: 'full',
        md: 'initial',
      },
    },
    iconColor: {
      color: 'brand.primary.midnightBlue',
    },
    detailsButton: {
      alignSelf: 'flex-start',
    },
  },
})
