import { checkboxHelper } from './base'

const borderColor = 'var(--chakra-colors-brand-coolgrey8)'
const border = `var(--chakra-space-px) solid ${borderColor}`
const darkCityGreen = 'brand.darkCityGreen'
const primaryTeal = 'brand.primaryTeal'
const boxShadow = '0px 4px 4px 0 rgba(0, 0, 0, 0.4)'
const greyDisabled = 'rgba(var(--chakra-colors-rgb-alCoolGrey8), 0.7)'
const checkedDisabled = {
  bg: 'rgba(var(--chakra-colors-rgb-alDarkCityGreen), 0.7)',
  color: 'rgba(var(--chakra-colors-rgb-alWhite), 0.7)',
  borderColor: 'rgba(var(--chakra-colors-rgb-alDarkCityGreen), 0.7)',
}

export const Checkbox = checkboxHelper.defineMultiStyleConfig({
  baseStyle: checkboxHelper.definePartsStyle({
    control: {
      border,
      borderColor,
      borderRadius: 'base',
      svg: {
        w: `${4} !important`,
      },
      _checked: {
        bg: darkCityGreen,
        borderColor: darkCityGreen,
        _disabled: {
          ...checkedDisabled,
        },
      },
      _pressed: {
        borderColor: 'brand.coolgrey10',
      },
      _disabled: {
        borderColor: greyDisabled,
        bg: 'brand.white',
      },
      _focus: {
        borderColor: 'brand.primaryTeal',
      },
    },
    container: {
      _checked: {
        _hover: {
          '.chakra-checkbox__control': {
            bg: primaryTeal,
            borderColor: primaryTeal,
            boxShadow,
            _disabled: {
              boxShadow: 'none',
              ...checkedDisabled,
            },
          },
        },
        _focusWithin: {
          outlineColor: {
            base: 'none',
            md: primaryTeal,
          },
          '.chakra-checkbox__control': {
            bg: primaryTeal,
            boxShadow,
          },
        },
      },
      _focusWithin: {
        outline: {
          base: 0,
          md: 'var(--chakra-space-px) solid var(--chakra-colors-brand-coolgrey10)',
        },
        outlineOffset: { base: 0, md: 5 },
        borderRadius: 1,
      },
      _hover: {
        '.chakra-checkbox__control': {
          borderColor: 'brand.coolgrey10',
        },
      },
      _disabled: {
        color: greyDisabled,
        _hover: {
          '.chakra-checkbox__control': {
            borderColor: greyDisabled,
          },
        },
      },
    },
  }),
})
