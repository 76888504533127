import { helpers } from './base'

const headingStyle = {
  fontSize: 'var(--alto-sem-text-heading-lg-font-size)',
  lineHeight: 'var(--alto-sem-text-heading-lg-line-height)',
  letterSpacing: 'var(--alto-sem-text-heading-lg-letter-spacing)',
  fontWeight: 'medium',
}
export const Modal = helpers.defineMultiStyleConfig({
  baseStyle: {
    dialog: {
      borderRadius: { base: 0, md: 'xs' },
      bg: 'bg.base',
      h2: {
        textAlign: 'left',
      },
    },
    header: {
      color: 'fg.accent.secondary',
      ...headingStyle,
      h2: {
        ...headingStyle,
      },
    },
    overlay: {
      bgColor: 'var(--alto-sem-color-overlay-scrim)',
    },
  },
})
