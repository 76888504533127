import { helpers } from './base'

export const ShareButton = helpers.defineMultiStyleConfig({
  baseStyle: {
    button: {
      borderRadius: 'full',
      color: 'brand.primaryTeal',
      minWidth: '40',
    },
    icon: {},
  },
  variants: {
    ghost: {
      button: {
        _hover: {
          boxShadow: 'none',
          bgColor: 'brand.teal10',
        },

        _focus: {
          boxShadow: 'none',
          bgColor: 'brand.teal10',
        },
      },
    },
  },
})
