import { helpers } from './base'

export const SearchExperienceForm = helpers.defineMultiStyleConfig({
  baseStyle: {
    form: {
      rowGap: 'var(--alto-sem-space-md)',
      columnGap: 'var(--alto-sem-space-md)',
    },
    submitBtn: {
      maxHeight: '62px',
      height: '100%',
    },
  },
})
