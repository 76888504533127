import { helpers } from './base'

export const PopularDestinations = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      background: {
        base: 'none',
        lg: 'brand.cloudWhite',
      },
      '> h4': {
        fontSize: 'lg',
        padding: {
          base: 'var(--chakra-sizes-6) 0',
          lg: 'var(--chakra-sizes-6) var(--chakra-sizes-6) 0 var(--chakra-sizes-6)',
        },
        fontWeight: 'medium',
      },
    },
    destinationCard: {
      boxShadow: '0px 6px 9px 0px #0000001A',
      _hover: {
        background: 'brand.teal10',
      },
    },
  },
})
