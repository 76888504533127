import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'container',
  'group',
  'groupTitle',
  'attraction',
  'attractionIcon',
  'placeName',
  'placeDistance',
])

export const HotelNearbyAttractions = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      columnCount: {
        base: '1',
        md: '2',
        lg: '3',
      },
      columnGap: '3rem',
      columnRule: '1px solid var(--chakra-colors-brand-coolgrey2)',
    },
    group: {
      padding: 0,
    },
    groupTitle: {
      paddingTop: 8,
      paddingBottom: 4,

      '&:first-of-type': {
        paddingTop: 0,
      },
    },
    attraction: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: 'var(--chakra-space-3) 0',
      breakInside: 'avoid-column',
    },
    placeName: {
      flex: '1',
      margin: '0 var(--chakra-space-2)',
    },
  },
})
