import { helpers } from './base'

export const HotelRooms = helpers.defineMultiStyleConfig({
  baseStyle: {
    header: {
      borderRadius: 'xs',
      boxShadow: 'md',
      bg: 'bg.layer2.default',
      p: 'lg',
    },
    heading: {
      fontWeight: 'semibold',
      fontSize: 'var(--alto-sem-text-heading-xs-font-size)',
      lineHeight: 'var(--alto-sem-text-heading-xs-line-height)',
      letterSpacing: 'var(--alto-sem-text-heading-xs-letter-spacing)',
    },
    tripContainer: {
      pb: 0,
    },
    tripInfo: {
      strong: {
        fontSize: 'var(--alto-sem-text-body-md-font-size)',
        lineHeight: 'var(--alto-sem-text-body-md-line-height)',
        letterSpacing: 'var(--alto-sem-text-body-md-letter-spacing)',
        fontWeight: 'normal',
      },
    },
    tripDetails: {
      fontSize: 'var(--alto-sem-text-heading-2xs-font-size)',
      lineHeight: 'var(--alto-sem-text-heading-2xs-line-height)',
      letterSpacing: 'var(--alto-sem-text-heading-2xs-letter-spacing)',
      fontWeight: 'semibold',
    },
    roomItem: {
      mt: 'lg',
    },
  },
})
