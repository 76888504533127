import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const priceBreakdownSummaryModalButtonHelpers = createMultiStyleConfigHelpers([
  'surchargesTextModalButton',
  'summaryModalButton',
  'flightsTC',
])
export const PriceBreakdownSummaryModalButton =
  priceBreakdownSummaryModalButtonHelpers.defineMultiStyleConfig({
    baseStyle: priceBreakdownSummaryModalButtonHelpers.definePartsStyle({
      surchargesTextModalButton: {
        mr: 2,
        fontSize: 'sm',
      },
      summaryModalButton: {
        padding: 0,
        textDecoration: 'none',
        color: 'inherit',
      },
    }),
  })
