import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const hotelBasketItemCardHelper = createMultiStyleConfigHelpers([
  'content',
  'image',
  'icons',
  'infoButton',
  'amenityIcon',
  'inlineText',
  'inlineTextNotIncluded',
  'modalIcon',
  'optionInfoBox',
])
export const HotelBasketItemCard = hotelBasketItemCardHelper.defineMultiStyleConfig({
  baseStyle: hotelBasketItemCardHelper.definePartsStyle({
    content: {
      display: 'flex',
      flexDir: 'column',
      height: '100%',
      // justifyContent: 'space-between',
      rowGap: 6,
      paddingTop: 4,
    },
    icons: {
      fontSize: 'xs',
      display: 'grid',
      rowGap: 1,
      mt: 'auto',
    },
    infoButton: {
      border: 0,
      padding: 0,
      cursor: 'pointer',
      backgroundColor: 'unset',
      marginLeft: 1,
      display: 'inline-flex',
    },
    amenityIcon: {
      width: 4,
      marginRight: 2,
    },
    optionInfoBox: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 2,
    },
    inlineText: {
      fontSize: 'sm',
      lineHeight: 'shorter',
      fontHeight: 'light',
    },
    inlineTextNotIncluded: {
      color: 'brand.red',
    },
    modalIcon: {
      width: 4,
      marginLeft: 2,
    },
  }),
})
