import React, { PropsWithChildren } from 'react'
import { useMultiStyleConfig, Flex } from '@chakra-ui/react'
import { ContentBadgeProps } from './ContentBadge.types'

const ContentBadge = (props: PropsWithChildren<ContentBadgeProps>): JSX.Element => {
  const { children, variant, onClickEvent, ...rest } = props
  const styles = useMultiStyleConfig('ContentBadge', { variant })
  return (
    <Flex
      data-testid="content-badge"
      __css={styles.wrapper}
      {...rest}
      onClick={onClickEvent ? () => onClickEvent() : undefined}
    >
      {children}
    </Flex>
  )
}
export default ContentBadge
