import { helper } from './base'

const alPrimaryTealAlpha20 = 'rgba(0, 98, 114, 0.20)'
const brandBlack = 'brand.black'
const brandPrimaryTeal = 'brand.primaryTeal'

export const UpgradeBasketItem = helper.defineMultiStyleConfig({
  baseStyle: {
    container: {
      bg: 'brand.teal5',
      'div:nth-child(2) > div > div': {
        borderRadius: 'lg',
        '> input': {
          borderColor: alPrimaryTealAlpha20,
          borderRadius: 'lg',
        },
        '> label': {
          fontWeight: '500',
          color: brandBlack,
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0 1rem',
          fontSize: '12px',
          alignItems: 'center',
          gap: '1rem',
          '> p': {
            display: 'flex',
            justifyContent: 'space-between',
            gap: '1rem',
            width: '100%',
            '> span': {
              color: brandBlack,
            },
          },
        },
        'ul > li': {
          fontSize: '12px',
          color: brandBlack,
        },
      },
      'div:nth-child(3) > ul > li': {
        fontSize: '12px',
        color: brandBlack,
      },
    },
    icon: {
      color: brandPrimaryTeal,
    },
    iconCircle: {
      'path:first-child': {
        fill: 'brand.darkCityGreen',
      },
      'path:nth-child(2)': {
        fill: 'brand.white',
      },
    },
    text: {
      fontSize: '18px',
      fontWeight: '600',
      color: brandPrimaryTeal,
    },
    optionTextPrice: {
      fontWeight: '600',
    },
  },
  variants: {
    upgrade: {
      button: {
        backgroundColor: 'brand.darkCityGreen',
      },
    },
    confirmUpgrade: {
      button: {
        backgroundColor: brandPrimaryTeal,
      },
    },
  },
})
