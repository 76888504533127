import { ComponentStyleConfig } from '@chakra-ui/react'
import { alPrimaryTeal } from '@reward-platform/lift/theme/aer_lingus/variables'

export const ExternalLink: ComponentStyleConfig = {
  baseStyle: {
    color: alPrimaryTeal,
    fontSize: 'sm',
  },
  variants: {
    lineItemLink: {
      borderColor: 'brand.primaryTeal',
      borderRadius: 'lg',
      color: 'brand.primaryTeal',
      fontWeight: 'medium',
      padding: 2,
      bg: 'rgba(var(--chakra-colors-rgb-alPrimaryTeal), 0.1)',
      fontSize: 'xs',
    },
  },
}
