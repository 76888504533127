import { PartnerConfig } from '~/schemas/partner'
import aviosVariables from './avios.variables'

import { PartnerStylesMap } from '../styles.types'

const variablesMap: PartnerStylesMap = {
  aer_lingus: null,
  vueling: null,
  british_airways: null,
  avios: aviosVariables,
}

export const getPartnerVariables = (partnerTheme: PartnerConfig['theme']) =>
  variablesMap[partnerTheme]
