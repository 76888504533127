import { z } from 'zod'
import { ContentfulRichTextDocumentSchema } from './common'

const ContentfulTermsAndConditionsTypeSchema = z.enum(['termsAndConditions'])

export const ContentfulTermsAndConditionsSchema = z.object({
  identifier: z.string().optional(),
  content: ContentfulRichTextDocumentSchema.optional(),
  contentType: ContentfulTermsAndConditionsTypeSchema.optional(),
})

export type ContentfulTermsAndConditions = z.infer<typeof ContentfulTermsAndConditionsSchema>
