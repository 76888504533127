import { PropsWithChildren, useMemo } from 'react'
import {
  QueryCache,
  QueryClient,
  QueryClientProvider as TanStackQueryProvider,
} from '@tanstack/react-query'
import { useNotification } from '~/components/shared/Notifications'
import { isProduction, isStaging } from '~/utils/envChecks'
import { GenericClientError } from '~/utils/errors'

const useQueryClient = () => {
  const { addNotification } = useNotification()

  return useMemo(() => {
    const queryCache = new QueryCache({
      onError: (error, query) => {
        // add meta data to error notification in lower envs
        if (!isProduction() && !isStaging()) {
          const devMessage =
            error instanceof GenericClientError ? error.developerMessage : error.message

          // TODO: Use meta field in query hooks to display useful error messages to user in production
          // type useQuery hook and add meta object for ease of use
          return query.meta?.errorMessage
            ? addNotification(`${query.meta.errorMessage}: ${devMessage}`)
            : addNotification(devMessage)
        }

        const message = error instanceof GenericClientError ? error.businessMessage : error.message

        // retain same error messaging in STG and PRD
        return addNotification(message)
      },
    })
    return new QueryClient({
      queryCache,
      defaultOptions: {
        queries: {
          staleTime: Infinity,
          refetchOnWindowFocus: false,
        },
      },
    })
  }, [addNotification])
}

const QueryClientProvider = ({ children }: PropsWithChildren) => {
  const queryClient = useQueryClient()
  return <TanStackQueryProvider client={queryClient}>{children}</TanStackQueryProvider>
}

export default QueryClientProvider
