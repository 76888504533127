import { flightItineraryHelpers } from './base'

const primaryTeal = 'var(--chakra-colors-brand-primaryTeal)'
const coolgrey10 = 'brand.coolgrey10'

const mediumText = {
  fontSize: 'sm',
  fontWeight: 'medium',
  color: coolgrey10,
}

export const FlightItineraryProp = flightItineraryHelpers.defineMultiStyleConfig({
  baseStyle: flightItineraryHelpers.definePartsStyle({
    headerContainer: {
      span: {
        _first: {
          color: primaryTeal,
          fontSize: 'lg',
        },
        _last: { fontSize: 'sm' },
      },
    },
    flightHeader: {
      '>span': {
        ...mediumText,
      },
    },
    testGrid: {
      '>span': {
        fontSize: 'lg',
        fontWeight: 'semibold',
        '&[data-label="md-text"]': {
          ...mediumText,
        },
        '&[data-align="end"]': {
          textAlign: 'end',
        },
        '&[data-label="time-text"]': {
          fontSize: 'xs',
          fontWeight: 'medium',
          color: primaryTeal,
        },
      },
      '>div': {
        ...mediumText,
        marginLeft: 'auto',
      },
    },
    detailsColumn: {
      display: 'flex',
      flexDir: 'column',
      '&[data-align="end"]': {
        alignItems: 'end',
      },
      '>span': {
        fontSize: 'lg',
        fontWeight: 'semibold',
        '&[data-label="md-text"]': {
          fontSize: 'sm',
          fontWeight: 'medium',
          color: coolgrey10,
        },
        '&[data-label="time-text"]': {
          fontSize: 'xs',
          fontWeight: 'medium',
          color: primaryTeal,
        },
      },
    },
    arrow: {
      backgroundColor: 'brand.primaryTeal',
    },
    arrow_left: {
      borderTop: '5px solid transparent',
      borderBottom: '5px solid transparent',
      borderLeft: `5px solid ${primaryTeal}`,
    },
  }),
})
