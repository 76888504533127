/* eslint-disable sonarjs/no-duplicate-string */
import { SnackbarHelper } from './base'

export const Snackbar = SnackbarHelper.defineMultiStyleConfig({
  baseStyle: SnackbarHelper.definePartsStyle({
    container: {
      bg: 'brand.cityGreen5',
      borderColor: 'brand.darkCityGreen',
    },
    icon: {
      color: 'brand.darkCityGreen',
    },
  }),
  variants: {
    warning: SnackbarHelper.definePartsStyle({
      container: { borderColor: 'brand.orange30', bgColor: 'brand.orange5' },
    }),
    error: SnackbarHelper.definePartsStyle({
      container: { borderColor: 'error.primary', bgColor: 'brand.red5' },
    }),
    success: SnackbarHelper.definePartsStyle({
      container: { borderColor: 'brand.primaryTeal', bgColor: 'brand.shamrockGreen10' },
    }),
    promo: SnackbarHelper.definePartsStyle({
      container: {
        bg: 'brand.cityGreen5',
        borderColor: 'brand.cityGreen',
        '> svg': {
          color: 'brand.cityGreen',
        },
        'p:first-child': {
          fontWeight: 'medium',
          pb: 2,
        },
        strong: {
          fontWeight: 'medium',
        },
      },
    }),
  },
})
