/* eslint-disable sonarjs/no-duplicate-string */
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers(['wrapper', 'icon'])

const getYearNavigationArrow = (direction: 'previous' | 'next') => ({
  display: 'grid',
  width: '10px',
  height: '100%',
  overflow: 'hidden',
  placeContent: 'center',
  _before: {
    position: 'absolute',
    display: 'block',
    content: '""',
    transform: `rotate(${direction === 'previous' ? '45' : '225'}deg)`,
    borderStyle: 'solid',
    borderWidth: '2px 2px 0 0',
    height: '15px',
    width: '15px',
    borderColor: 'red',
    ...(direction === 'previous' ? { left: '-10px' } : { right: '-10px' }),
  },
})

export const DatePicker = helpers.defineMultiStyleConfig({
  baseStyle: {
    wrapper: {
      width: '100%',
      outline: 'none',
    },
    icon: {
      color: 'black',
    },
  },
})

export const DatePickerGlobalTheme = {
  '.react-datepicker-popper': {
    zIndex: 'modal',
  },
  '.react-datepicker': {
    position: 'relative',
    fontSize: {
      base: '0.9rem',
      md: '0.8rem',
    },
    width: {
      base: 'full',
      md: 'unset',
    },
    maxWidth: {
      base: 'sm',
      md: 'unset',
    },
    display: {
      base: 'block',
      md: 'inline-flex',
    },
    justifyContent: 'space-around',
    borderRadius: '0.5rem',
    top: '8px',
    backgroundColor: '#fff',
    color: '#000',
    margin: 'auto',
    boxShadow: 'none',
  },
  '.react-datepicker__navigation': {
    overflow: 'hidden',
    alignItems: 'center',
    background: 'none',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    position: 'absolute',
    top: '2px',
    padding: '0',
    border: 'none',
    zIndex: 1,
    height: '32px',
    width: '32px',
    textIndent: '-999em',
  },
  '.react-datepicker__navigation-icon': {
    width: '100%',
    _before: {
      borderStyle: 'solid',
      borderWidth: '2px 2px 0 0',
      content: "''",
      display: 'block',
      height: '18px',
      position: 'absolute',
      top: '6px',
      width: '15px',
    },
    position: 'relative',
    top: '-1px',
    fontSize: '20px',
  },
  '.react-datepicker__navigation-icon--next::before': {
    right: '10px',
    left: 'auto',
  },
  '.react-datepicker__input-container': {
    width: '100%',
    position: 'relative',
    display: 'inline-block',
    '.date-picker-from__label, .date-picker-to__label': {
      position: 'absolute',
      top: '12px',
      left: '16px',
      opacity: 1,
      transition: 'opacity 0.3s ease',
    },
    '.datepicker-icon': {
      position: 'absolute',
      bottom: '12px',
      right: '12px',
    },
  },
  '.react-datepicker__year-read-view--down-arrow': {
    borderStyle: 'solid',
    borderWidth: '2px 2px 0 0',
    content: "''",
    display: 'block',
    height: '10px',
    position: 'absolute',
    top: ['6px', '0'],
    width: '10px',
    transform: 'rotate(135deg)',
    right: '-16px',
  },
  '.react-datepicker__month-read-view--down-arrow': {
    borderColor: '#ccc',
    borderStyle: 'solid',
    borderWidth: '3px 3px 0 0',
    content: "''",
    display: 'block',
    height: '9px',
    position: 'absolute',
    top: ['6px', '0'],
    width: '9px',
    transform: 'rotate(135deg)',
    right: '-16px',
  },
  '.react-datepicker__month-year-read-view--down-arrow': {
    borderColor: '#ccc',
    borderStyle: 'solid',
    borderWidth: '3px 3px 0 0',
    content: "''",
    display: 'block',
    height: '9px',
    position: 'absolute',
    top: ['6px', '0'],
    width: '9px',
    transform: 'rotate(135deg)',
    right: '-16px',
  },
  '.react-datepicker-wrapper': {
    display: 'inline-block',
    padding: '0',
    border: '0',
    width: '100%',
  },
  '.react-datepicker--time-only': {
    '.react-datepicker__triangle': {
      left: '35px',
    },
    '.react-datepicker__time-container': {
      borderLeft: '0',
    },
    '.react-datepicker__time': {
      borderBottomLeftRadius: '0.3rem',
      borderBottomRightRadius: '0.3rem',
    },
    '.react-datepicker__time-box': {
      borderBottomLeftRadius: '0.3rem',
      borderBottomRightRadius: '0.3rem',
    },
  },
  '.react-datepicker__triangle': {
    display: 'none',
  },
  ".react-datepicker-popper[data-placement^='right']": {
    paddingLeft: '8px',
  },
  ".react-datepicker-popper[data-placement^='left']": {
    paddingRight: '8px',
  },
  '.react-datepicker__header': {
    textAlign: 'center',
    borderBottom: '1px solid #f1f1f1',
    borderTopLeftRadius: '0.3rem',
    padding: '8px 0',
    position: 'relative',
    width: {
      base: '100%',
      md: 'unset',
    },
    '&:not(.react-datepicker__header--has-time-select)': {
      borderTopRightRadius: '0.3rem',
    },
  },
  '.react-datepicker__header--time': {
    paddingBottom: '8px',
    paddingLeft: '5px',
    paddingRight: '5px',
    '&:not(.react-datepicker__header--time--only)': {
      borderTopLeftRadius: '0',
    },
  },
  '.react-datepicker__year-dropdown-container': {
    cursor: 'pointer',
  },
  '.react-datepicker__year-dropdown-container--select': {
    display: 'inline-block',
    margin: '0 2px',
  },
  '.react-datepicker__month-dropdown-container--select': {
    display: 'inline-block',
    margin: '0 2px',
  },
  '.react-datepicker__month-year-dropdown-container--select': {
    display: 'inline-block',
    margin: '0 2px',
  },
  '.react-datepicker__year-dropdown-container--scroll': {
    display: 'inline-block',
    margin: '0 2px',
  },
  '.react-datepicker__month-dropdown-container--scroll': {
    display: 'inline-block',
    margin: '0 2px',
  },
  '.react-datepicker__month-year-dropdown-container--scroll': {
    display: 'inline-block',
    margin: '0 2px',
  },
  '.react-datepicker__current-month': {
    margin: '10px 0 15px',
    color: '#000',
    fontSize: {
      base: '1.125rem',
      md: '0.944rem',
    },
    fontWeight: 500,
  },
  '.react-datepicker-time__header': {
    marginTop: '0',
    color: '#000',
    fontWeight: 'bold',
    fontSize: '0.944rem',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  '.react-datepicker-year-header': {
    marginTop: '0',
    color: '#000',
    fontWeight: 'bold',
    fontSize: '0.944rem',
  },
  '.react-datepicker__navigation--previous': {
    left: {
      base: '47px',
      md: '2px',
    },
    top: '15px',
  },
  '.react-datepicker__navigation--next': {
    right: {
      base: '47px',
      md: '2px',
    },
    top: {
      base: '20px',
      md: '15px',
    },
  },
  '.react-datepicker__navigation--next--with-time': {
    '&:not(.react-datepicker__navigation--next--with-today-button)': {
      right: '85px',
    },
  },
  '.react-datepicker__navigation--years': {
    position: 'relative',
    top: '0',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  '.react-datepicker__navigation--years-previous': getYearNavigationArrow('previous'),
  '.react-datepicker__navigation--years-upcoming': getYearNavigationArrow('next'),
  '.react-datepicker__navigation-icon--next': {
    left: '-2px',
    _before: {
      transform: 'rotate(45deg)',
      left: '-7px',
    },
  },
  '.react-datepicker__navigation-icon--previous': {
    right: '-2px',
    _before: {
      transform: 'rotate(225deg)',
      right: '-7px',
    },
  },
  '.react-datepicker__month-container': {
    padding: { base: 0, md: '4px 16px' },
  },
  '.react-datepicker__year': {
    margin: '0.4rem',
    textAlign: 'center',
    '.react-datepicker__year-text': {
      display: 'inline-block',
      width: '4rem',
      margin: '2px',
    },
  },
  '.react-datepicker__year-wrapper': {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '180px',
  },
  '.react-datepicker__month': {
    margin: '0.4rem',
    textAlign: 'center',
    '.react-datepicker__month-text': {
      display: 'inline-block',
      width: '4rem',
      margin: '2px',
    },
    '.react-datepicker__quarter-text': {
      display: 'inline-block',
      width: '4rem',
      margin: '2px',
    },
  },
  '.react-datepicker__input-time-container': {
    clear: 'both',
    width: '100%',
    cssFloat: 'left',
    margin: '5px 0 10px 15px',
    textAlign: 'left',
    '.react-datepicker-time__caption': {
      display: 'inline-block',
    },
    '.react-datepicker-time__input-container': {
      display: 'inline-block',
      border: 'solid 1px #bbbcbc',
      borderRadius: '5px',
      '.react-datepicker-time__input': {
        display: 'inline-block',
        marginLeft: '10px',
        input: {
          width: 'auto',
        },
        "input[type='time']": {
          '&::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: '0',
          },
          '&::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
            margin: '0',
          },
          MozAppearance: 'textfield',
        },
      },
      '.react-datepicker-time__delimiter': {
        marginLeft: '5px',
        display: 'inline-block',
      },
    },
  },
  '.react-datepicker__time-container': {
    cssFloat: 'right',
    borderLeft: '1px solid #aeaeae',
    width: '85px',

    '.react-datepicker__time': {
      position: 'relative',
      background: 'white',
      borderBottomRightRadius: '0.3rem',

      '.react-datepicker__time-box': {
        width: '85px',
        overflowX: 'hidden',
        margin: '0 auto',
        textAlign: 'center',
        borderBottomRightRadius: '0.3rem',

        'ul.react-datepicker__time-list': {
          listStyle: 'none',
          margin: '0',
          height: 'calc(195px + (1.7rem / 2))',
          overflowY: 'scroll',
          paddingRight: '0',
          paddingLeft: '0',
          width: '100%',
          boxSizing: 'content-box',

          'li.react-datepicker__time-list-item': {
            height: '30px',
            padding: '5px 10px',
            whiteSpace: 'nowrap',

            _hover: {
              cursor: 'pointer',
              backgroundColor: '#f0f0f0',
            },
          },

          'li.react-datepicker__time-list-item--selected': {
            backgroundColor: '#216ba5',
            color: 'white',
            fontWeight: 'bold',

            _hover: {
              backgroundColor: '#216ba5',
            },
          },

          'li.react-datepicker__time-list-item--disabled': {
            color: '#ccc',

            _hover: {
              cursor: 'default',
              backgroundColor: 'transparent',
            },
          },
        },
      },
    },
  },
  '.react-datepicker__time-container--with-today-button': {
    display: 'inline',
    position: 'absolute',
    right: '-72px',
    top: '0',
  },
  '.react-datepicker__week-number': {
    color: '#ccc',
    display: 'inline-block',
    width: '1.7rem',
    lineHeight: '1.7rem',
    textAlign: 'center',
    margin: '0.166rem',
  },
  '.react-datepicker__week-number.react-datepicker__week-number--clickable': {
    cursor: 'pointer',

    _hover: {
      backgroundColor: '#f0f0f0',
    },
  },
  '.react-datepicker__day-names': {
    whiteSpace: 'nowrap',
    marginBottom: '-8px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    paddingLeft: '0.4rem',
    paddingRight: '0.4rem',
  },
  '.react-datepicker__week': {
    whiteSpace: 'nowrap',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
  },
  '.react-datepicker__day-name': {
    color: '#000',
    display: 'inline-block',
    width: '100%',
    maxWidth: {
      base: 'calc(100% / 7)',
      md: '2rem',
      lg: 'calc(100% / 7)',
    },
    lineHeight: {
      base: '2.7rem',
      md: '1.7rem',
    },
    textAlign: 'center',
    padding: '0.166rem',
    fontWeight: 500,
  },
  '.react-datepicker__day': {
    color: '#000',
    display: 'inline-block',
    minWidth: '2rem',
    textAlign: 'center',
    padding: '0.166rem',
    cursor: 'pointer',
    width: '100%',
    maxWidth: {
      base: 'calc(100% / 7)',
      md: 'unset',
      lg: 'calc(100% / 7)',
    },
    lineHeight: {
      base: '2.7rem',
      md: '1.7rem',
    },

    _hover: {
      color: '#fff',
    },

    _focus: {
      outline: 'none',
      outlineOffset: '1px',
    },
  },
  '.react-datepicker__time-name': {
    color: '#000',
    display: 'inline-block',
    width: '1.7rem',
    lineHeight: '1.7rem',
    textAlign: 'center',
    margin: '0.166rem',
  },
  '.react-datepicker__day--outside-month': {
    visibility: 'hidden',
  },
  '.react-datepicker__month--disabled': {
    color: '#ccc',
    pointerEvents: 'none',

    _hover: {
      cursor: 'default',
      backgroundColor: 'transparent',
    },
  },
  '.react-datepicker__quarter--disabled': {
    color: '#ccc',
    pointerEvents: 'none',

    _hover: {
      cursor: 'default',
      backgroundColor: 'transparent',
    },
  },
  '.react-datepicker__month-text': {
    cursor: 'pointer',

    _hover: {
      backgroundColor: '#f0f0f0',
    },
  },
  '.react-datepicker__quarter-text': {
    cursor: 'pointer',

    _hover: {
      backgroundColor: '#f0f0f0',
    },
  },
  '.react-datepicker__year-text': {
    cursor: 'pointer',

    _hover: {
      backgroundColor: '#f0f0f0',
    },
  },
  '.react-datepicker__month-text--today': {
    fontWeight: 'bold',
  },
  '.react-datepicker__quarter-text--today': {
    fontWeight: 'bold',
  },
  '.react-datepicker__year-text--today': {
    fontWeight: 'bold',
  },
  '.react-datepicker__day--selected': {
    color: '#fff !important',
  },
  '.react-datepicker__day--in-selecting-range': {
    color: '#000',
    _hover: {
      color: '#fff',
    },
  },
  '.react-datepicker__day--in-range': {
    color: '#000',
  },
  '.react-datepicker__month-text--selected': {
    color: '#fff',
  },
  '.react-datepicker__month-text--in-selecting-range': {
    color: '#fff',
  },
  '.react-datepicker__month-text--in-range': {
    color: '#fff',
  },
  '.react-datepicker__quarter-text--selected': {
    color: '#fff',
  },
  '.react-datepicker__quarter-text--in-selecting-range': {
    color: '#fff',
  },
  '.react-datepicker__quarter-text--in-range': {
    color: '#fff',
  },
  '.react-datepicker__year-text--selected': {
    color: '#fff',
  },
  '.react-datepicker__day--keyboard-selected': {
    color: '#fff',
  },
  '.react-datepicker__day--range-start': {
    color: '#fff',
  },
  '.react-datepicker__day--range-end': {
    color: '#fff',
  },
  '.react-datepicker__day--disabled': {
    cursor: 'default',
    color: '#ccc',

    _hover: {
      color: '#ccc',
      backgroundColor: 'transparent',
    },
  },
  '.react-datepicker__month-text--disabled': {
    cursor: 'default',
    color: '#ccc',

    _hover: {
      color: '#ccc',
      backgroundColor: 'transparent',
    },
  },
  '.react-datepicker__quarter-text--disabled': {
    cursor: 'default',
    color: '#ccc',

    _hover: {
      backgroundColor: 'transparent',
    },
  },
  '.react-datepicker__year-text--disabled': {
    cursor: 'default',
    color: '#ccc',

    _hover: {
      backgroundColor: 'transparent',
    },
  },
  '.react-datepicker__year-read-view': {
    border: '1px solid transparent',
    position: 'relative',
  },
  '.react-datepicker__month-read-view': {
    border: '1px solid transparent',
    position: 'relative',

    _hover: {
      cursor: 'pointer',

      '.react-datepicker__year-read-view--down-arrow': {
        borderTopColor: '#b3b3b3',
      },

      '.react-datepicker__month-read-view--down-arrow': {
        borderTopColor: '#b3b3b3',
      },
    },
  },
  '.react-datepicker__month-year-read-view': {
    border: '1px solid transparent',
    borderRadius: '0.3rem',
    position: 'relative',

    _hover: {
      cursor: 'pointer',

      '.react-datepicker__year-read-view--down-arrow': {
        borderTopColor: '#b3b3b3',
      },

      '.react-datepicker__month-read-view--down-arrow': {
        borderTopColor: '#b3b3b3',
      },
    },
  },
  '.react-datepicker__header__dropdown': {
    marginBottom: '15px',
    fontSize: '0.9rem',
  },
  '.react-datepicker__year-dropdown': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    backgroundColor: '#f1f1f1',
    gridGap: '1px',
    position: 'absolute',
    width: '100%',
    left: '0%',
    top: '40px',
    zIndex: 1,
    textAlign: 'center',
    borderRadius: '5px',
    border: '1px solid #f1f1f1',
    _hover: {
      cursor: 'pointer',
    },
  },
  '.react-datepicker__month-dropdown': {
    backgroundColor: '#f0f0f0',
    position: 'absolute',
    width: '50%',
    left: '25%',
    top: '30px',
    zIndex: 1,
    textAlign: 'center',
    border: '1px solid #aeaeae',

    _hover: {
      cursor: 'pointer',
    },
  },
  '.react-datepicker__month-year-dropdown': {
    backgroundColor: '#f0f0f0',
    position: 'absolute',
    width: '50%',
    left: '25%',
    top: '30px',
    zIndex: 1,
    textAlign: 'center',
    border: '1px solid #aeaeae',

    _hover: {
      cursor: 'pointer',
    },
  },
  '.react-datepicker__year-dropdown--scrollable': {
    height: '150px',
    overflowY: 'scroll',
  },
  '.react-datepicker__month-dropdown--scrollable': {
    height: '150px',
    overflowY: 'scroll',
  },
  '.react-datepicker__month-year-dropdown--scrollable': {
    height: '150px',
    overflowY: 'scroll',
  },
  '.react-datepicker__year-option': {
    background: 'white',
    display: 'block',
    padding: '10px',
    fontSize: '0.9rem',
    lineHeight: {
      base: '45px',
      md: '20px',
    },

    _hover: {
      backgroundColor: '#f1f1f1',
    },

    '&:nth-child(1)': {
      borderTopLeftRadius: '5px',
    },
    '&:nth-child(3)': {
      borderTopRightRadius: '5px',
    },
    '&:nth-child(13)': {
      borderBottomLeftRadius: '5px',
    },
  },
  '.react-datepicker__month-option': {
    lineHeight: '20px',
    width: '100%',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',

    _first: {
      borderTopLeftRadius: '0.3rem',
      borderTopRightRadius: '0.3rem',
    },

    _last: {
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      msUserSelect: 'none',
      userSelect: 'none',
      borderBottomLeftRadius: '0.3rem',
      borderBottomRightRadius: '0.3rem',
    },

    _hover: {
      backgroundColor: '#ccc',

      '.react-datepicker__navigation--years-upcoming': {
        borderBottomColor: '#b3b3b3',
      },

      '.react-datepicker__navigation--years-previous': {
        borderTopColor: '#b3b3b3',
      },
    },
  },
  '.react-datepicker__month-year-option': {
    lineHeight: '20px',
    width: '100%',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',

    _first: {
      borderTopLeftRadius: '0.3rem',
      borderTopRightRadius: '0.3rem',
    },

    _last: {
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      msUserSelect: 'none',
      userSelect: 'none',
      borderBottomLeftRadius: '0.3rem',
      borderBottomRightRadius: '0.3rem',
    },

    _hover: {
      backgroundColor: '#ccc',

      '.react-datepicker__navigation--years-upcoming': {
        borderBottomColor: '#b3b3b3',
      },

      '.react-datepicker__navigation--years-previous': {
        borderTopColor: '#b3b3b3',
      },
    },
  },
  '.react-datepicker__year-option--selected': {
    position: 'absolute',
    left: '15px',
    display: 'none',
  },
  '.react-datepicker__month-option--selected': {
    position: 'absolute',
    left: '15px',
  },
  '.react-datepicker__month-year-option--selected': {
    position: 'absolute',
    left: '15px',
  },
  '.react-datepicker__today-button': {
    background: '#f0f0f0',
    borderTop: '1px solid #aeaeae',
    cursor: 'pointer',
    textAlign: 'center',
    fontWeight: 'bold',
    padding: '5px 0',
    clear: 'left',
  },
  '.react-datepicker__portal': {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    left: '0',
    top: '0',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    zIndex: 2147483647,

    '.react-datepicker__day-name': {
      width: '3rem',
      lineHeight: '3rem',
    },

    '.react-datepicker__day': {
      width: '3rem',
      lineHeight: '3rem',
    },

    '.react-datepicker__time-name': {
      width: '3rem',
      lineHeight: '3rem',
    },

    '.react-datepicker__current-month': {
      fontSize: '1.44rem',
    },

    '.react-datepicker-time__header': {
      fontSize: '1.44rem',
    },
  },
  '.react-datepicker__aria-live': {
    display: 'none',
  },
}
