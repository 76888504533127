import { helpers } from './base'

const primaryColor = 'fg.accent.primary'
const secondaryColor = 'fg.accent.secondary'

export const TelevisionButton = helpers.defineMultiStyleConfig({
  baseStyle: {
    control: {
      borderRadius: 'xs',
      color: primaryColor,
      borderColor: primaryColor,
      _checked: {
        bg: secondaryColor,
        borderColor: secondaryColor,
      },
    },
  },
})
