import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helper = createMultiStyleConfigHelpers([
  'styledPriceBreakdownSummaryWrapper',
  'styledPriceBreakdownStickyWrapper',
])

export const PriceBreakdown = helper.defineMultiStyleConfig({
  baseStyle: {
    styledPriceBreakdownSummaryWrapper: {
      marginTop: { md: 8 },
      marginBottom: 4,
      borderRadius: 1,
      flexDirection: 'column',
      padding: 6,
    },
    styledPriceBreakdownStickyWrapper: {
      display: { base: 'initial', md: 'none' },
    },
  },
  variants: {
    noBg: {
      styledPriceBreakdownSummaryWrapper: {
        bg: 'none',
        paddingTop: 0,
        paddingRight: 0,
        marginBottom: 4,
        borderRadius: 0,
        p: {
          textAlign: 'right',
        },
      },
    },
  },
})
