import { Box, chakra, useMultiStyleConfig } from '@chakra-ui/react'
import StepperCircle, { getStatus } from './StepperCircle/StepperCircle'
import { StepperProps } from './Stepper.types'

function Stepper({ steps, currentStep, onCurrentStepChange }: StepperProps) {
  const style = useMultiStyleConfig('Stepper', {})

  // calculates the width of the line based on the number of steps
  const FULL_WIDTH_PERCENTAGE = 100

  const calcLineWidth = (stepCount: number) => (FULL_WIDTH_PERCENTAGE / stepCount) * (stepCount - 1)
  const setLineWidth = `${calcLineWidth(steps.length)}%`

  const calcCompletedLineWidth = (stepCount: number, _stepIndex: number) => {
    const lineOffset = 1
    const totalLines = stepCount - lineOffset
    const stepIndex = currentStep

    return (stepIndex / totalLines) * FULL_WIDTH_PERCENTAGE
  }
  const setCompletedLineWidth = `${calcCompletedLineWidth(steps.length, currentStep)}%`

  return (
    <Box __css={style.stepperContainer}>
      <Box __css={style.lineContainer} width={setLineWidth}>
        <Box __css={style.backgroundLine} />
        <Box __css={style.completedLine} width={setCompletedLineWidth} />
      </Box>

      <Box __css={style.stepsContainer} role="list" aria-label="stepper">
        {steps.map((step, index) => {
          const stepNumberOffset = 1
          const stepNumber = index + stepNumberOffset
          const isSelected = index === currentStep
          const isCompleted = index < currentStep
          const status = getStatus(isCompleted, isSelected)
          const { title } = step

          return (
            <Box
              __css={style.stepContainer}
              key={title}
              data-status={status}
              role="listitem"
              aria-label={`step ${stepNumber}`}
            >
              <StepperCircle
                editable={step.editable}
                selectStep={onCurrentStepChange}
                title={title}
                number={stepNumber}
                status={status}
              />
              <chakra.p
                __css={style.stepText}
                visibility={{
                  base: status === 'selected' ? 'visible' : 'hidden',
                  sm: 'visible',
                }}
                aria-hidden
              >
                {title}
              </chakra.p>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export default Stepper
