import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const paymentFormContainerHelper = createMultiStyleConfigHelpers([
  'paymentForm',
  'buttonContainer',
  'creditCardFieldsContainer',
  'termsAndConditionsWrapper',
  'paymentFormParentWrapper',
  'cashDueLabel',
  'cashDueValue',
])

export const PaymentFormContainer = paymentFormContainerHelper.defineMultiStyleConfig({
  baseStyle: paymentFormContainerHelper.definePartsStyle({
    paymentFormParentWrapper: {},

    termsAndConditionsWrapper: {
      'div[role="group"] > label': {
        alignItems: 'start',
      },
      button: {
        display: 'inline',
        textAlign: 'left',
      },
      'button, a': {
        pr: 1,
      },
      'button > span, a > span': {
        whiteSpace: 'normal',
        pr: 1,
      },
    },
    paymentForm: {
      '> * + *': {
        mt: 'lg',
      },
    },
    creditCardFieldsContainer: {
      display: 'grid',
      gridTemplateColumns: {
        base: '1fr',
        md: '1fr 1fr',
        lg: '1fr 1fr 1fr',
      },
      gap: 4,

      '> *:nth-child(2n + 1):last-child': {
        md: {
          gridColumn: 'span 2',
        },
        lg: {
          gridColumn: 'revert',
        },
      },
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: 4,
      mt: 10,
      button: {
        display: { base: 'none', md: 'flex' },
        w: { base: '100%', md: '30%' },
        pl: 4,
        pr: 4,
      },
    },
  }),
})
