import { vuGreyscale3 } from '../../../theme/vueling/variables'
import { cardHelpers } from './base'

export const Card = cardHelpers.defineMultiStyleConfig({
  baseStyle: cardHelpers.definePartsStyle({
    container: {
      border: `solid 1px ${vuGreyscale3}`,
      boxShadow: 'none',
    },
  }),
  variants: {},
})
