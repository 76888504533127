/* eslint-disable sonarjs/no-duplicate-string */
import { checkboxHelper } from './base'

const checkedDisabled = {
  bg: 'bg.disabled.subtle',
  color: 'fg.disabled.onSubtle',
  borderColor: 'border.disabled.subtle',
}

export const Checkbox = checkboxHelper.defineMultiStyleConfig({
  baseStyle: checkboxHelper.definePartsStyle({
    label: {
      marginInlineStart: 'sm',
      span: {
        fontWeight: 'var(--alto-sem-text-body-regular-font-weight)',
      },
    },
    control: {
      svg: {
        w: 'var(--alto-sem-space-sm) !important',
      },
      border: 'var(--alto-sem-border-width-sm) solid var(--alto-sem-color-border-primary-default)',
      borderColor: '#9593A0',
      borderRadius: 'var(--alto-sem-radius-6xs)',
      boxShadow: 'none',
      bg: 'bg.layer2.default',
      _checked: {
        bg: 'bg.accent.vibrant.default',
        borderColor: 'bg.accent.vibrant.default',
        _disabled: {
          ...checkedDisabled,
        },
      },
      _hover: {
        boxShadow: 'none',
        borderColor: 'border.primary.hover',
      },
      _pressed: {
        borderColor: 'border.primary.active',
      },
      _disabled: {
        ...checkedDisabled,
      },
      _focus: {
        borderColor: 'border.primary.hover',
        outline:
          'var(--alto-sem-border-width-md) solid var(--alto-sem-color-bg-accent-vibrant-hover)',
        outlineOffset: '2px',
      },
    },
    container: {
      _checked: {
        _hover: {
          '.chakra-checkbox__control': {
            bg: 'bg.accent.vibrant.hover',
            borderColor: 'border.primary.hover',
            boxShadow: 'none',
            _disabled: {
              boxShadow: 'none',
              ...checkedDisabled,
            },
          },
        },
        _pressed: {
          '.chakra-checkbox__control': {
            bg: 'bg.accent.vibrant.active',
            borderColor: 'bg.accent.vibrant.active',
            _disabled: {
              ...checkedDisabled,
            },
          },
        },
      },
      _hover: {
        '.chakra-checkbox__control': {
          boxShadow: 'none',
          borderColor: 'border.primary.hover',
        },
      },
      _pressed: {
        '.chakra-checkbox__control': {
          borderColor: 'border.primary.active',
        },
      },
      _disabled: {
        color: checkedDisabled.color,
        _hover: {
          '.chakra-checkbox__control': {
            borderColor: checkedDisabled.borderColor,
          },
        },
      },
    },
  }),
})
