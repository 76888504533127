import { basketTravellersLabelHelper } from './base'

export const BasketTravellersLabel = basketTravellersLabelHelper.defineMultiStyleConfig({
  baseStyle: basketTravellersLabelHelper.definePartsStyle({
    wrapper: {
      span: {
        fontSize: 'xs',
        lineHeight: 1,
      },
    },
    totalCountContainer: {
      'div div span': {
        bg: 'brand.darkCityGreen',
        borderRadius: '50%',
        svg: {
          color: 'brand.white',
        },
      },
    },
    ageGroupsContainer: {
      '> span': {
        fontWeight: 'semibold',
        bg: 'brand.coolgrey1',
        borderRadius: 'md',
      },
      button: {
        borderRadius: 'md',
        bg: 'brand.teal5',
        borderColor: 'rgba(var(--chakra-colors-rgb-alPrimaryTeal), 0.2)',
        '> span': {
          fontWeight: 'semibold',
          color: 'brand.black',
        },
        svg: {
          color: 'brand.black',
        },
      },
      '> div > section': {
        borderRadius: 'md',
      },
    },
  }),
})
