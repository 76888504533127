import { PartnerConfig } from '~/schemas/partner'
import Usercentrics from './Usercentrics'
import OneTrust from './OneTrust'

const CookieConsent = ({ partner }: { partner: PartnerConfig }) => {
  const usercentricsBaId = 'iMO9-XcLWV5uu5'
  const usercentricsEiId = 'okXP7mPh3airbn'

  const getCookieBanner = () => {
    switch (partner.domain) {
      case 'britishairways':
        return <Usercentrics id={usercentricsBaId} />
      case 'aerlingus':
        return <Usercentrics id={usercentricsEiId} />
      case 'avios':
        return <OneTrust />
      default:
        return <Usercentrics id={usercentricsBaId} />
    }
  }

  return getCookieBanner()
}

export default CookieConsent
