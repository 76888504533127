import { helpers } from './base'

export const ExperienceTitle = helpers.defineMultiStyleConfig({
  baseStyle: {
    experienceTitleContainer: {
      color: 'brand.black',
    },
    ratingsAndChips: {
      columnGap: 3.5,
    },
    address: {
      paddingTop: 4,
    },
    icons: {
      marginTop: 5,
      marginBottom: 5,
      columnGap: 5,
      rowGap: 3.5,
      color: 'brand.grey6',
    },
  },
})
