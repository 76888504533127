/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const HotelNearbyAttractions = helpers.defineMultiStyleConfig({
  baseStyle: {
    groupTitle: {
      fontSize: 'lg',
      fontWeight: 'light',
      color: 'brand.primary.midnightBlue',
    },
    attraction: {
      lineHeight: '5',
    },
    attractionIcon: {
      color: 'brand.primary.midnightBlue',
    },
    placeName: {
      color: 'brand.primary.midnightBlue',
      fontSize: 'xs',
      fontWeight: 'light',
    },
    placeDistance: {
      color: 'brand.primary.midnightBlue',
      fontSize: 'xs',
      fontWeight: 'light',
    },
  },
})
