/* eslint-disable sonarjs/no-duplicate-string */
import { helper } from './base'

export const OrderListTable = helper.defineMultiStyleConfig({
  baseStyle: {
    orderListTableGrid: {
      bg: 'bg.layer2.default',
      boxShadow: 'md',
      borderRadius: 'xs',
      padding: 'lg',
      hr: {
        borderColor: 'border.primary.default',
      },
    },
    orderListTableHeader: {
      px: 0,
    },
    orderListTableItemGrid: {
      bg: 'transparent',
      borderRadius: 0,
      p: {
        base: 0,
        sm: 0,
      },
    },
    orderLineItem: {
      borderRadius: 'xs',
      marginBottom: '2xs',
      padding: 'lg',
      bg: 'bg.layer1.default',
      '> div:first-of-type': {
        color: 'fg.accent.secondary',
      },
    },
    accented: {
      fontSize: 'var(--alto-sem-text-heading-sm-font-size)',
      fontWeight: 'medium',
      color: 'fg.accent.secondary',
      svg: {
        color: 'border.success',
      },
    },
    lineBalance: {
      p: {
        fontWeight: 'normal',
      },
    },
    textBold: {
      fontWeight: 'medium',
    },
    lineDetails: {
      fontWeight: 'medium',
    },
    lighter: {
      p: {
        fontWeight: 'normal',
      },
    },
    lineType: {
      fontSize: 'var(--alto-sem-text-heading-sm-font-size)',
      fontWeight: 'semibold',
    },
    travellersAmount: {
      marginTop: 'sm',
      fontWeight: 400,
    },
    headerLabels: {
      fontSize: 'sm',
    },
    divider: {
      display: 'none',
    },
    aviosEarned: {
      gridColumnEnd: {
        lg: 8,
      },
    },
    zeroAvios: {
      gridColumnEnd: {
        lg: 'unset',
      },
    },
    modalSubtitle: {
      mb: 'xs',
      fontSize: 'var(--alto-sem-text-heading-xs-font-size)',
      letterSpacing: 'var(--alto-sem-text-heading-sm-letter-spacing)',
      fontWeight: 'medium',
    },
    modalDescription: {
      fontSize: 'md',
    },
  },
})
