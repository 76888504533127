import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers(['container', 'title', 'paymentMethodStyle'])

export const PricingAndSearchCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      minWidth: 'xs',
    },
    title: {
      fontWeight: 'medium',
    },
    paymentMethodStyle: {
      position: 'relative',
    },
  },
})
