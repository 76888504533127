import { ReactElement, ReactNode, useEffect } from 'react'
import type { NextPage } from 'next'
import type { AppContext, AppProps } from 'next/app'
import App from 'next/app'
import { ChakraProvider } from '@chakra-ui/react'
import ReactModal from 'react-modal'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { CookieConsent } from '~/components/shared/Scripts'
import Layout from '~/components/shared/Layout'
import { NotificationProvider } from '~/components/shared/Notifications'
import { LocalisationProvider } from '~/context/localisation'
import { AuthProvider } from '~/context/auth'
import QueryClientProvider from '~/context/queryClient'
import { PartnerProvider } from '~/context/partner'
import { UserProvider } from '~/context/user'
import { PromotionProvider } from '~/context/promotion'
import { DevCycleProvider, IdentifyDevCycleUser } from '~/context/devcycle'
import useCorrelationId from '~/hooks/useCorrelationId/useCorrelationId'
import useHotjar from '~/hooks/useHotjar/useHotjar'
import { getPartnerFromPageContext } from '~/utils/partner'
import { isClient } from '~/utils/envChecks'
import { PartnerConfig } from '~/schemas/partner'
import { chakraThemeMap } from '~/themes/chakra'
import { NextPageError } from '~/server/errors'
import useRemoveExpiredBasketItems from '~/hooks/useRemoveExpiredBasketItems'
import { ErrorBoundary } from '~/components/shared/ErrorBoundary'
import { logRequestToDatadog } from '~/server/logger'
import GlobalStyles from '~/styles/globalStyles'
import { setPartnerHeader, setCorrelationIdHeader } from '~/services/clients/nextApiClient'
import { MaintenanceModeBoundary } from '../components/shared/MaintenanceModeBoundary'
import { initDatadogLogging, initDatadogRum } from '../utils/datadog'

import '../styles/global.css'

ReactModal.setAppElement('#__next')

export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

interface MyAppProps extends AppProps {
  partner: PartnerConfig
  Component: NextPageWithLayout
}

const DEFAULT_MESSAGE_CLUSTER = ['reward-platform']
const initialClusters =
  process.env.NEXT_PUBLIC_MESSAGE_CLUSTERS?.split(',') ?? DEFAULT_MESSAGE_CLUSTER

const ClearExpiredBasketItems = () => {
  useRemoveExpiredBasketItems()

  return null
}

function MyApp(props: MyAppProps): JSX.Element {
  const { Component, pageProps, partner } = props
  const { correlationId, resetCorrelationId } = useCorrelationId()

  useHotjar()

  useEffect(() => {
    if (isClient()) {
      initDatadogRum()
      initDatadogLogging()
    }
  }, [])

  useEffect(() => {
    setPartnerHeader(partner.code)
  }, [partner])

  useEffect(() => {
    if (correlationId == null) {
      resetCorrelationId()
      return
    }
    setCorrelationIdHeader(correlationId)
  }, [correlationId, resetCorrelationId])

  const getLayout = Component.getLayout ?? ((page) => <Layout variant="search">{page}</Layout>)

  return (
    <ErrorBoundary partner={partner}>
      <PartnerProvider partner={partner}>
        <DevCycleProvider partner={partner}>
          {({ isInitialized: isDevCycleInitialized }) => (
            <MaintenanceModeBoundary partner={partner}>
              <ChakraProvider theme={chakraThemeMap[partner.theme]} resetCSS={false}>
                <GlobalStyles partner={partner.theme} />
                <LocalisationProvider initialClusterKeys={initialClusters}>
                  <NotificationProvider>
                    <QueryClientProvider>
                      <CookieConsent partner={partner} />
                      <AuthProvider isLoading={!isDevCycleInitialized}>
                        <ClearExpiredBasketItems />
                        <IdentifyDevCycleUser />
                        {/* disabled in prod by default */}
                        <ReactQueryDevtools />
                        <UserProvider>
                          <PromotionProvider>
                            {getLayout(<Component {...pageProps} />)}
                          </PromotionProvider>
                        </UserProvider>
                      </AuthProvider>
                    </QueryClientProvider>
                  </NotificationProvider>
                </LocalisationProvider>
              </ChakraProvider>
            </MaintenanceModeBoundary>
          )}
        </DevCycleProvider>
      </PartnerProvider>
    </ErrorBoundary>
  )
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext)
  // nextjs's internal error page
  const partner = getPartnerFromPageContext(appContext.ctx)

  // Handle error response
  // TODO: improve to include generic error page
  if (appContext.ctx.err && appContext.ctx.req) {
    const error = new NextPageError(appContext.ctx.err)
    logRequestToDatadog(appContext.ctx.req, error)
  }

  const props = { ...appProps, partner }
  if (appContext.router.pathname.includes('_error')) {
    return { ...props }
  }

  return { ...props }
}

export default MyApp
