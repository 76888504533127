import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const FlightSelectHelper = createMultiStyleConfigHelpers([
  'journeyContainer',
  'flightsContainer',
])

const wideGridColumn = 'var(--layout-column-wide)'
const pageInlinePadding = 'max(var(--layout-gutter), calc((100vw - var(--layout-width)) / 2))'

export const FlightSelect = FlightSelectHelper.defineMultiStyleConfig({
  baseStyle: {
    journeyContainer: {
      gridColumn: wideGridColumn,
      '> *': {
        paddingInline: pageInlinePadding,
      },
    },
    flightsContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gridColumn: wideGridColumn,
      paddingInline: pageInlinePadding,
      borderBottom: '1px solid',
    },
    noScheduleBox: {
      width: '100%',
      borderRadius: '2xl',
      backgroundColor: 'white',
      border: '1px solid',
      borderColor: 'brand.coolgrey1',
      paddingY: 8,
      gap: 4,
    },
    noFlightsScheduleTitle: {
      color: 'black',
      fontSize: 'lg',
      fontWeight: 'bold',
      padding: '5',
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'center',
    },
    noFlightsScheduleDescription: {
      color: 'black',
      fontSize: 'lg',
      paddingTop: '0',
      padding: '5',
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'center',
    },
  },
})
