import { helpers } from './base'

export const SearchExperienceForm = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {},
    destination: {},
    form: {
      rowGap: 5,
      columnGap: 5,
    },
    spinner: {
      ':disabled': {
        opacity: 1,
      },

      '> div > div ': {
        borderLeftColor: 'transparent',
        borderBottomColor: 'brand.white',
        color: 'brand.white',
        width: 7,
        height: 7,
      },
    },
  },
})
