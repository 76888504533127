import React, { createContext, useContext, useMemo } from 'react'
import { useRouter } from 'next/router'
import { UserProvider, useUser } from '@auth0/nextjs-auth0/client'

import SpinnerOverlay from '~/components/shared/SpinnerOverlay'
import { AuthPathKey, getClientAuthPath } from '~/utils/auth'
import { PlatformError } from '~/utils/errors'
import { isClient } from '~/utils/envChecks'

import { AuthContextValue, AuthProviderProps } from './Auth.types'

const AuthContext = createContext<AuthContextValue | undefined>(undefined)

const handleClientAuthRoute = (type: AuthPathKey, returnToPath?: string) => {
  if (isClient()) {
    const pathname = `${getClientAuthPath(type, returnToPath)}`
    window.location.assign(pathname)
  }
}

const AuthContextProvider = ({ children }: React.PropsWithChildren) => {
  const userContext = useUser()

  const isAuthenticated = !!userContext.user?.sub

  const contextValue = useMemo<AuthContextValue>(() => {
    return {
      ...userContext,
      isAuthenticated,
      handleLogin: (returnTo = '/') => handleClientAuthRoute('LOGIN', returnTo),
      handleLogout: () => handleClientAuthRoute('LOGOUT'),
    }
  }, [isAuthenticated, userContext])

  if (userContext.isLoading) {
    return <SpinnerOverlay />
  }

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}

export const AuthProvider = ({
  isLoading,
  ...rest
}: React.PropsWithChildren<AuthProviderProps>) => {
  const router = useRouter()

  if (isLoading) {
    return <SpinnerOverlay />
  }

  return (
    <UserProvider
      loginUrl={getClientAuthPath('LOGIN', router.asPath)}
      profileUrl={getClientAuthPath('PROFILE')}
    >
      <AuthContextProvider {...rest} />
    </UserProvider>
  )
}

export const useAuthContext = (): AuthContextValue => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new PlatformError('useAuthContext must be used within an AuthProvider')
  }
  return context ?? {}
}

export default AuthContext
