import { helpers } from './base'

export const HotelRooms = helpers.defineMultiStyleConfig({
  baseStyle: {
    tripDetails: {
      color: 'brand.coolgrey10',
    },
    heading: {
      fontSize: '2xl',
    },
  },
})
