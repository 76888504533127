import { helpers } from './base'

export const ExperienceTitle = helpers.defineMultiStyleConfig({
  baseStyle: {
    experienceTitleContainer: {
      fontSize: 'md',
      bg: 'bg.layer2.default',
      borderRadius: 'xs',
      boxShadow: 'md',
      p: 'lg',
    },
    ratingsAndChips: {
      color: 'fg.secondary',
      columnGap: 'sm',
      small: {
        fontSize: 'sm',
      },
    },
    address: {
      paddingTop: 'md',
    },
    icons: {
      marginTop: 'md',
      columnGap: 'lg',
      rowGap: 'md',
      color: 'fg.primary',
      '> div': {
        columnGap: 'sm',
      },
      '> div > span': {
        fontSize: 'var(--alto-sem-text-heading-2xs-font-size)',
        fontWeight: 'normal',
      },
    },
    experienceTitle: {
      fontSize: 'var(--alto-sem-text-heading-lg-font-size)',
      lineHeight: 'var(--alto-sem-text-heading-lg-line-height)',
      letterSpacing: 'var(--alto-sem-text-heading-lg-letter-spacing)',
      fontWeight: 'medium',
      color: 'fg.accent.primary',
      mt: 'lg',
    },
  },
})
