import { helpers } from './base'

export const List = helpers.defineMultiStyleConfig({
  baseStyle: {
    item: {
      fontSize: '1.125rem',
      color: 'brand.coolgrey10',
    },
    bullet: {
      backgroundColor: 'brand.primaryTeal',
    },
  },
})
