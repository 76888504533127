/* eslint-disable sonarjs/no-duplicate-string */
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const headerHelpers = createMultiStyleConfigHelpers([
  'headerWrapper',
  'header',
  'logo',
  'userDetails',
  'aviosDetails',
  'cartWrapper',
  'basketItemsCount',
  'smallGap',
  'aviosHeaderWrapper',
  'headerItem',
  'userDetailsContainer',
  'aviosHeader',
])

export const Header = headerHelpers.defineMultiStyleConfig({
  baseStyle: headerHelpers.definePartsStyle({
    headerWrapper: {
      display: 'flex',
      height: { base: '60px', md: '100px' },
      justifyContent: 'center',
      backgroundColor: 'white',
      zIndex: 90,
      boxShadow: '0 6px 9px 0 rgba(0, 0, 0, 0.1)',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      width: '100%',
      maxWidth: '1280px',
      p: '0 1rem',
      gap: { base: 3, sm: 3, md: 12 },
      '> a:first-of-type': {
        width: 'full',
      },
    },
    logo: {
      height: { base: '1rem', md: '2rem', lg: '3rem' },
      width: 'full',
      mr: 'auto',
      cursor: 'pointer',
      position: 'relative',
      span: { overflow: 'visible !important' },
    },
    userDetails: {
      whiteSpace: 'nowrap',
      flexDirection: 'column',
      alignItems: 'flex-end',
      textAlign: 'end',
      display: { base: 'none', lg: 'flex' },
      '> span': {
        whiteSpace: 'nowrap',
        _first: {},
        _last: {},
      },
    },
    aviosDetails: {
      whiteSpace: 'nowrap',
      display: 'flex',
      flexDir: 'column',
      alignItems: 'flex-end',
      textAlign: 'end',
      '> span': {
        _first: {},
        _last: {},
      },
    },
    cartWrapper: {
      cursor: 'pointer',
      p: '3',
      pos: 'relative',
    },
    basketItemsCount: {
      position: 'absolute',
      zIndex: 1,
      top: '-0.125rem',
      left: '50%',
      color: 'white',
      transform: 'translate(-25%, 100%)',
      fontSize: 'xs',
      fontWeight: 'semibold',
    },
    smallGap: {
      gap: {
        base: 2.5,
        md: 4,
      },
    },
  }),
})
