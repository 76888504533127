import { tooltipHelper } from './base'

const primaryBlue = 'brand.primary.blue'
const white = 'brand.white'
const boxShadow = 'brand.offsetShadow'

export const Tooltip = tooltipHelper.defineMultiStyleConfig({
  baseStyle: tooltipHelper.definePartsStyle({
    containerBg: {
      bg: 'brand.baseGray.10',
    },
    containerBorder: {
      border: '1px solid',
      borderColor: primaryBlue,
    },
    wrapper: {
      '> div': {
        p: {
          fontSize: 'sm',
        },
      },
      '> button': {
        border: '1px solid',
        borderColor: primaryBlue,
        color: primaryBlue,
        borderRadius: 'sm',
        background: white,
        _hover: {
          background: white,
          boxShadow,
        },
      },
    },
  }),
})
