import { AxiosError } from 'axios'
import { GenericAPIException } from '@reward-platform/shared-api/exceptions/GenericAPIException'
import { ErrorResponseSchema } from '@reward-platform/shared-schemas'

export class NextAxiosError extends GenericAPIException {
  constructor(error: AxiosError<any>) {
    super(error)

    if (error.response) {
      this.httpStatus = error.response.status
      this.responseData = error.response.data

      const parsedResponse = ErrorResponseSchema.safeParse(error.response.data)
      if (parsedResponse.success) {
        this.errorDescription = 'INTERNAL_BFF_ERROR'
        this.code = parsedResponse.data.code
        this.businessMessage = parsedResponse.data.businessMessage
        this.developerMessage = parsedResponse.data.developerMessage
        this.validationErrors = parsedResponse.data.validationErrors
        this.unrecoverable = parsedResponse.data.unrecoverable
      }
    }
  }
}
