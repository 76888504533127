import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers(['infomessagewrapper', 'iconwrapper'])

export const InfoMessage = helpers.defineMultiStyleConfig({
  baseStyle: {
    infomessagewrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'center',
    },
    iconwrapper: {
      width: '5',
      mr: '3',
    },
  },
})
