import { helpers } from './base'

export const ExperienceTitle = helpers.defineMultiStyleConfig({
  baseStyle: {
    experienceTitleContainer: {
      color: 'brand.black',
    },
    ratingsAndChips: {
      columnGap: 3.5,
    },
    address: {
      paddingTop: 5,
    },
    icons: {
      marginTop: 5,
      columnGap: 6,
      rowGap: 3.5,
      color: 'brand.black',
      '> div': {
        columnGap: '4-5',
      },
      '> div > span': {
        fontSize: 'lg',
        fontWeight: 'normal',
      },
    },
    experienceTitle: {
      fontWeight: 'normal',
      fontSize: '5xl',
    },
  },
})
