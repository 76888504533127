import { helpers } from './base'

export const List = helpers.defineMultiStyleConfig({
  baseStyle: {
    item: {
      alignItems: 'baseline',
    },
    bullet: {
      backgroundColor: 'bg.accent.deep.default',
    },
  },
})
