import { Typography } from '../typography.types'

export const typography: Typography = {
  families: {
    heading: 'Mylius Modern',
    body: 'Open Sans',
  },
  sizes: {
    xsmall: '0.625rem',
    small: '0.75rem',
    medium: '1.375rem',
    large: '1.5rem',
    xlarge: '2.75rem',
    xxlarge: '3.5rem',
  },
  weights: {
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  headings: {
    // TODO: update with the mobile font size this will require a object rework
    h1: {
      size: '3.5rem',
      weight: 'light',
    },
    h2: {
      size: '2.75rem',
      weight: 'light',
    },
    h3: {
      size: '1.5rem',
      weight: 'light',
    },
    h4: {
      size: '1.375rem',
      weight: 'light',
    },
    h5: {},
    h6: {},
  },
  lists: {
    ordered: {},
    unordered: {},
  },
  colors: {
    base: '#021B41',
    accent: 'red',
    heading: '#021B41',
    body: '#021B41',
  },
}

export default typography
