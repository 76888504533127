import { helpers } from './base'

export const CustomerReviewList = helpers.defineMultiStyleConfig({
  baseStyle: {
    contentWrapper: { pt: 1, color: 'brand.black' },
    customerReviewsCtn: { rowGap: 4 },
    customerReviewCtn: {
      bgColor: 'brand.white',
      p: 6,
      border: 'solid var(--chakra-space-px) var(--chakra-colors-brand-greyscale3)',
      borderRadius: 'lg',
      boxShadow: 'none',
      gap: 4,
    },
    title: { mt: 4, mb: 4 },
    icons: { color: 'brand.coolgrey10', mt: 6, mb: 6, columnGap: 4, rowGap: 4 },
    icon: { color: 'brand.grey6' },
  },
})
