import { helpers } from './base'

export const BackTo = helpers.defineMultiStyleConfig({
  baseStyle: {
    link: {
      color: 'fg.accent.primary',
      fontSize: 'var(--alto-sem-text-label-sm-font-size)',
      fontWeight: 'semibold',
      textDecoration: 'underline',

      _hover: {
        color: 'fg.accent.secondary',
        textDecoration: 'none',
      },
      _active: {
        color: 'fg.accent.secondary',
      },
      _focus: {
        border: 'var(--alto-sem-border-width-sm) solid var(--alto-sem-color-border-accent)',
        borderRadius: '4xs',
        textDecoration: 'none',
      },
      _focusVisible: {
        outline: 'solid',
        outlineColor: 'var(--alto-sem-color-border-accent)',
        boxShadow: 'none',
        borderRadius: '4xs',
        textDecoration: 'none',
      },
      _disabled: {
        color: 'fg.disabled.primary',
      },
    },
  },
})
