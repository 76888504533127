import { helpers } from './base'

export const ChildrenAgePicker = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    agesOfChildrenLabel: {
      fontWeight: 'medium',
      fontSize: 'var(--alto-sem-text-body-sm-font-size)',
    },
    ageSelectBoxContainer: {
      select: {
        paddingLeft: '0',
        borderRadius: 'var(--alto-text-field-radius)',
        background: 'var(--chakra-colors-bg-layer2-default)',
        padding: 'var(--alto-sem-space-lg) var(--alto-sem-space-sm) var(--alto-sem-space-2xs)',
        fontSize: 'var(--alto-sem-text-body-md-font-size)',
        color: 'var(--chakra-colors-fg-primary)',
        borderWidth: 'var(--alto-sem-border-width-sm)',
        borderColor: 'border.primary.active',
        lineHeight: 'var(--alto-sem-text-body-md-line-height)',
        letterSpacing: 'var(--alto-sem-text-body-md-letter-spacing)',
      },
    },
  }),
})
