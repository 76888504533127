import { ComponentMultiStyleConfig } from '@chakra-ui/react'

export const FlightJourneyHeader: ComponentMultiStyleConfig = {
  parts: ['subheader, infoMessage, chevronButton, weekdayButton, scrollableWrapper'],
  baseStyle: {
    subheader: {
      flexDirection: { base: 'column', sm: 'row' },
      alignItems: { base: 'flex-start', sm: 'end' },
      justifyContent: 'space-between',
      gap: { base: 6, sm: 2, md: 4 },
      py: { base: 8, lg: 12 },
      h3: {
        mt: 2,
      },
    },
    infoMessage: {
      flexDirection: 'row',
      gap: 2,
      '.chakra-popover__body': {
        padding: 0,
      },
      '.chakra-popover__popper': {
        zIndex: 91,
      },
      '.chakra-popover__content': {
        width: 'auto',
      },
    },
    chevronButton: {
      py: { base: 4, md: 4 },
      pl: { base: 1, xs: 2, md: 4 },
      pr: { base: 1 },
      background: 'transparent',
      cursor: 'pointer',
      borderBottomWidth: '1px',
      borderStyle: 'none none solid none',

      '&:last-of-type': {
        pr: { base: 1, xs: 2, md: 4 },
        pl: { base: 1 },
      },
    },
    weekdayButton: {
      width: '100%',
      whiteSpace: 'nowrap',
      borderWidth: 'var(--chakra-space-px)',
      minWidth: { lg: '8.5em' },
      fontWeight: 'normal',
      borderStyle: 'none none solid none',
      cursor: 'pointer',
      opacity: '1',
      pl: { base: 2, md: 5, lg: 6 },
      pr: { base: 2, md: 5, lg: 6 },
      borderTopRightRadius: '10px',
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px',
      _active: {
        fontWeight: 'bold',
        borderStyle: 'solid solid none solid',
      },
      _disabled: {
        cursor: 'default',
        opacity: '0.4',
      },
    },
    scrollableWrapper: {
      listStyle: 'none',
      // Nice scrolling
      scrollBehavior: 'smooth',
      scrollSnapType: 'x mandatory',
      // Hide scroll bars
      scrollbarWidth: 'none',
      '-ms-overflow-style': 'none',
      '::-webkit-scrollbar': {
        display: 'none' /* Chrome, Safari and Opera */,
      },
      '& > * ': {
        scrollSnapAlign: 'start',
        scrollSnapStop: 'always',
      },

      paddingInline: {
        base: 0,
        md: 'max(var(--layout-gutter), calc((100vw - var(--layout-width)) / 2))',
      },
    },
    overlayTipContent: {
      maxWidth: { base: 72, md: 96 },
      h4: {
        fontWeight: 'medium',
        fontSize: 'sm',
      },
      '.chakra-icon': {
        color: 'brand.primaryShamrockGreen',
      },
      display: 'flex',
      flexDirection: 'column',
      gap: 3,
      li: {
        display: 'flex',
        alignItems: 'start',
        gap: 4,
        fontSize: 'sm',
      },
    },
    pricePerAdult: {
      fontSize: {
        base: 'xs',
        lg: 'sm',
      },
    },
  },
}
