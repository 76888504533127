import { helpers } from './base'

export const TicketOptions = helpers.defineMultiStyleConfig({
  baseStyle: {
    ticketOptions: {
      gap: 'xs',
      marginBottom: '2xl',
      '> h4': {
        fontSize: 'var(--alto-sem-text-heading-sm-font-size)',
        lineHeight: 'var(--alto-sem-text-heading-sm-line-height)',
        color: 'fg.accent.secondary',
        fontWeight: 'medium',
      },
    },
    ticketOptionsResults: {
      gap: 'xs',
    },
  },
})
