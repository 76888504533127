import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'container',
  'infoContainer',
  'imageContainer',
  'locationContainer',
  'iconContainer',
  'flightDetailsButtonContainer',
  'flightDetailsButton',
  'flightInfoText',
  'destinationText',
])

export const FlightDetailsModal = helpers.defineMultiStyleConfig({
  baseStyle: {
    body: {
      px: { base: 4, md: 4, xl: 'unset' },
      pl: { xl: 0 },
      pr: { xl: 14 },
      pb: { base: 4, md: 2, xl: 4 },
      minWidth: { md: '2xl' },
    },
    h1: {
      fontSize: {
        base: '3xl',
        lg: '4xl',
      },
      fontWeight: 500,
      marginBottom: '2rem',
    },
    container: {
      display: 'flex',
      alignItems: { base: 'baseline', xl: 'center' },
      flexDirection: {
        base: 'column',
        xl: 'row',
      },
    },
    infoContainer: {
      display: 'flex',
      gap: '1rem',
    },
    textContainer: {
      pl: { base: 0, md: 12 },
      pr: { base: 4, md: 12 },
      pt: { base: 10, md: 12, xl: 0 },
      pb: { base: 14, md: 16, xl: 0 },
    },
    timeContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      p: {
        fontSize: 'sm',
      },
      strong: {
        fontSize: {
          base: 'xl',
        },
      },
    },
    locationContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      justifyContent: 'space-between',
    },
    iconContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0.25rem 0',
      hr: {
        margin: 0,
        borderStyle: 'dashed',
      },
    },
    imageContainer: {
      maxWidth: { xl: '30rem' },
      position: 'relative',
      height: { base: 'auto', xl: '35rem' },
      width: { base: '100%', xl: 'auto' },
      aspectRatio: { base: '1', md: '37.4 / 13.625', xl: '21/35' },
      overflow: 'hidden',
      img: {
        borderRadius: 'var(--chakra-radii-2xl)',
      },
    },
    flightDetailsButtonContainer: {
      gridArea: 'button',
    },
    flightDetailsButton: {
      height: '2.5rem',
      width: '8.125rem',
      padding: '1rem 1.5rem',
      gap: '1rem',
    },
    flightInfoText: {
      fontSize: {
        base: 'md',
        md: 'xl',
      },
      lineHeight: 7,
      letterSpacing: 'normal',
    },
    destinationText: {
      lineHeight: 6,
      fontSize: {
        base: 'md',
        md: 'xl',
      },
      fontWeight: 'semibold',
    },
    operatedBy: {
      fontSize: { base: 'sm', md: 'md' },
      color: 'brand.coolgrey10',
    },
  },
})
