import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const flightBasketItemCardHelper = createMultiStyleConfigHelpers([
  'content',
  'flightDetails',
])

export const FlightBasketItemCard = flightBasketItemCardHelper.defineMultiStyleConfig({
  baseStyle: {
    content: {
      height: '100%',
      rowGap: 6,
    },
    flightDetails: {
      flexDir: 'column',
      gridArea: 'details',
      gap: 12,
      p: 6,
      display: 'grid',
    },
  },
})
