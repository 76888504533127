import { createMultiStyleConfigHelpers, keyframes } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers(['closeButton', 'modal'])

const flyUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`

export const ImageModal = helpers.defineMultiStyleConfig({
  baseStyle: {
    closeButton: {
      position: 'absolute',
      border: 'none',
      background: 'none',
      cursor: 'pointer',
      zIndex: 1001,
      right: 0,
      margin: 4,
      borderRadius: '50%',
      backgroundColor: 'rgba(44, 42, 41, 0.7)',
      height: '2.5rem',
      width: '2.5rem',
    },
    modal: {
      zIndex: 1000,
      backgroundColor: 'rgba(0, 0, 0, 0.9)',
      width: '100%',
      height: '100%',
      animation: {
        base: `${flyUp} ease-in-out 0.3s forwards`,
        md: 'none',
      },
    },
  },
})
