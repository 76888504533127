/* eslint-disable sonarjs/no-duplicate-string */
import { progressBarHelpers, progressHelpers } from './base'

export const Progress = progressHelpers.defineMultiStyleConfig({
  baseStyle: {
    track: {
      bg: 'border.primary.default',
    },
    filledTrack: {
      bgColor: 'border.accent',
    },
  },
})

export const ProgressBar = progressBarHelpers.defineMultiStyleConfig({
  baseStyle: {
    label: {
      color: 'fg.primary',
    },
    valueLabel: {
      color: 'fg.primary',
    },
  },
})
