import { helpers } from './base'

export const Combobox = helpers.defineMultiStyleConfig({
  baseStyle: {
    styledOption: {
      color: 'brand.black',

      '&.disabled': {
        color: 'brand.coolgrey4',
      },
    },
    buttonField: {
      color: 'brand.primaryTeal',
    },
    comboBoxContainer: {
      border: 'none',
      color: 'brand.black',
      fontWeight: 'medium',
    },
  },
})
