import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const basketTravellersLabelHelper = createMultiStyleConfigHelpers([
  'wrapper',
  'content',
  'contentWrapper',
  'totalCountContainer',
  'ageGroupsContainer',
])

export const BasketTravellersLabel = basketTravellersLabelHelper.defineMultiStyleConfig({
  baseStyle: basketTravellersLabelHelper.definePartsStyle({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      columnGap: 2,
      rowGap: 2,
      span: {
        display: 'flex',
        alignItems: 'center',
      },
    },
    totalCountContainer: {
      'div div span': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 7,
        height: 7,
        margin: 0,
        svg: {
          w: 4,
          h: 4,
        },
      },
    },
    ageGroupsContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      columnGap: 2,
      '> span': {
        pr: 2,
        pl: 2,
        height: 8,
      },
      button: {
        pr: 2,
        pl: 2,
        height: 8,
        svg: {
          w: 2,
          h: 2,
          ml: 1,
        },
      },
      '> div > section': {
        w: '100%',
      },
      '> div > section > div': {
        padding: 0,
      },
      _invalid: {
        'button, > div > section': {
          borderColor: 'error.primary',
        },
      },
    },
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 2,
      pt: 2,
      pb: 2,
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 2,
      alignContent: 'space-between',
    },
  }),
})
