import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'container',
  'column',
  'item',
  'heading',
  'title',
  'type',
  'body',
])

export const ActivityMenu = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      pt: { base: '18px', md: '4.5px', xl: '18px' },
      pb: { base: '18px', md: '15.5px', xl: '28px' },
    },
    column: {
      flexDirection: 'column',
      gap: { base: '16px', xl: '24px' },
      flex: 1,
    },
    heading: {
      mb: { base: '40px', md: '46px', xl: '40px' },
      fontSize: { base: '1.25rem', xl: '1.375rem' },
    },
    title: {
      fontWeight: '600',
      mr: '8px',
    },
    type: {
      mb: '12px',
      fontSize: { base: '1rem', xl: '1.125rem' },
    },
    body: {
      fontSize: { base: '1rem', xl: '1.125rem' },
      lineHeight: 1.5,
    },
  },
})
