import { helpers } from './base'

const cloudWhite = 'brand.cloudWhite'

export const Modal = helpers.defineMultiStyleConfig({
  baseStyle: {
    header: {
      bg: cloudWhite,
      h2: {
        textAlign: 'left',
        fontWeight: 'medium',
      },
    },
    dialog: {
      bg: cloudWhite,
      p: {
        mb: 3,
      },
    },
    closeButton: {
      _before: {
        bg: cloudWhite,
      },
    },
  },
})
