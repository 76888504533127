import { ComponentMultiStyleConfig } from '@chakra-ui/react'

const commonStyles = {
  position: 'relative',
  overflow: 'hidden',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '1.5rem',
  height: '1.5rem',
  textAlign: 'center',
  borderRadius: '50%',
  fontSize: '0.75rem',
  textDecoration: 'none',
}

export const Pagination: ComponentMultiStyleConfig = {
  parts: ['list', 'navItem', 'ellipses', 'arrow', 'pip'],
  baseStyle: {
    list: {
      display: 'flex',
      alignItems: 'center',
    },
    navItem: commonStyles,
    ellipses: commonStyles,
    arrow: commonStyles,
    pip: {
      ...commonStyles,
      transition: 'all 0.2s ease',
      marginInline: '0.25rem',
    },
  },
}
