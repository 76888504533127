import { chakra, useStyleConfig, Button, VisuallyHidden } from '@chakra-ui/react'
import { CheckIcon } from '@chakra-ui/icons'
import React, { MouseEvent } from 'react'
import { CircleProps, StepStatus } from '../Stepper.types'

export const getStatus = (isCompleted: boolean, isSelected: boolean): StepStatus => {
  if (isCompleted) {
    return 'completed'
  }

  if (isSelected) {
    return 'selected'
  }

  return 'default'
}

function StepperCircle({ number, status, editable, selectStep, title, ...rest }: CircleProps) {
  const style = useStyleConfig('StepperCircle', { variant: status })

  const indexOffset = 1
  const selectableIndex = number - indexOffset
  const onSelectStep = (e: MouseEvent) => {
    e.preventDefault()

    if (!editable) {
      return
    }
    selectStep(selectableIndex)
  }

  return (
    <Button
      __css={style}
      type="button"
      onClick={onSelectStep}
      isDisabled={!editable}
      title={title}
      {...rest}
      data-testid="circle"
      cursor={editable ? 'pointer' : 'default'}
      tabIndex={editable ? 0 : -1}
    >
      {/* to do: change the check icon to be according to opco */}
      {status === 'completed' ? (
        <>
          <VisuallyHidden>{status}</VisuallyHidden>
          <CheckIcon />
        </>
      ) : (
        <chakra.p>{number}</chakra.p>
      )}
      <VisuallyHidden>{title}</VisuallyHidden>
    </Button>
  )
}

export default StepperCircle
