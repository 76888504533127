import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'tabWrap',
  'tabList',
  'tabListButton',
  'tabContent',
])

export const Tabs = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    tabWrap: {
      display: 'grid',
      gridTemplate: {
        base: `'tabs tabs' auto
        'content content' auto
        / minmax(0, max-content) 1fr`,
        lg: `'tabs .' auto
        'content content' auto
        / minmax(0, max-content) 1fr`,
      },
      _before: { content: '""', gridArea: 'content-start', marginBottom: 8 },
    },
    tabList: {
      gridArea: 'tabs',
      padding: '0',
      listStyle: 'none',
      display: 'flex',
      alignItems: 'flex-end',
      overflow: 'auto',
      ariaOrientation: 'horizontal',
      margin: 0,
    },
    tabListButton: {
      display: 'flex',
      alignItems: 'center',
      borderBottom: 'none',
      fontSize: 'md',
      textDecoration: 'none',
      whiteSpace: 'nowrap',
      cursor: 'pointer',
    },
    tabContent: {
      gridArea: 'content',
      padding: 6,
      minHeight: 10,
      boxShadow: '0 6px 9px 0 rgba(0, 0, 0, 0.1)',
      borderRadius: {
        base: '0',
        lg: '10px',
      },
    },
  }),
})
