import { helpers } from './base'

export const CardBanner = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    container: {
      borderRadius: 'lg',
      color: 'brand.coolgrey1',
    },
    overlayContainer: {
      borderRadius: 'lg',
    },
  }),
})
