import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'addRoomButtonContainer',
  'maxTravellerBadge',
  'modalCloseButton',
  'modalHeader',
  'popOverContent',
  'popOverButton',
  'input',
])

export const TravelerRoomPicker = helpers.defineMultiStyleConfig({
  baseStyle: {
    addRoomButtonContainer: {
      display: 'grid',
      justifyItems: 'end',
    },
    maxTravellerBadge: {
      fontSize: 'xs',
      fontWeight: 'normal',
      borderRadius: 'base',
      marginBottom: 4,
      textAlign: 'center',
      padding: 'var(--chakra-space-1-5) 0',
    },
    modalCloseButton: {
      width: 10,
      height: 10,
      alignSelf: 'end',
      padding: 2,
      marginBottom: 2,
      background: 'inherit',
      border: 'inherit',
    },
    modalHeader: {
      fontWeight: 'semibold',
      marginTop: { base: '14 !important', md: '6 !important' },
      marginBottom: '10 !important',
      fontSize: '2xl',
      textAlign: 'center',
    },
    popOverContent: {
      border: 'none',
      shadow: 'brand',
      maxWidth: '17.375rem',
      position: 'relative',
    },
    popOverButton: {
      marginTop: 8,
      width: 'full',
      padding: 4,
      fontSize: 'xs',
    },
  },
})
