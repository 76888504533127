import { resultsPageTemplate } from './base'

export const ResultsPageTemplate = resultsPageTemplate.defineMultiStyleConfig({
  baseStyle: {
    searchForm: {
      boxShadow: '0 6px 9px 0 rgba(0,0,0, 0.1)',
      backgroundColor: 'bg.layer2.default',
    },
    searchTitle: {
      fontSize: 'var(--alto-sem-text-heading-xs-font-size)',
      lineHeight: 'var(--alto-sem-text-heading-xs-line-height)',
      letterSpacing: 'var(--alto-sem-text-heading-xs-letter-spacing)',
      fontWeight: 'medium',
      maxW: {
        base: 'unset',
        lg: '80%',
      },
      color: 'fg.accent.secondary',
    },
    searchSubtitle: {
      fontSize: 'var(--alto-sem-text-body-md-font-size);',
    },
    resultsPagination: {
      justifyContent: 'flex-end',
    },
    sortListBox: {
      'div:first-child': {
        background: 'unset',
      },
      ul: {
        background: 'bg.white.vibrant.default',
        paddingLeft: 'sm',
      },
    },
    mapViewWrapper: {
      borderRadius: 'xs',
    },
    sidebar: {
      background: 'bg.layer2.default',
      borderRadius: 'xs',
    },
    skipLink: {
      borderRadius: 'sm',
      outlineColor: 'bg.accent.deep.default',
    },
    shareButtonContainer: {
      alignItems: { base: 'flex-start', lg: 'flex-end' },
    },
    cardWrapper: {
      '> div': {
        borderRadius: 'xs',
      },
    },
  },
})
