import { roomMoreDetailsModal } from './base'

export const RoomMoreDetailsModal = roomMoreDetailsModal.defineMultiStyleConfig({
  baseStyle: {
    heading: {
      mb: 'var(--alto-sem-space-lg)',
    },
    infoListItem: {
      color: 'fg.secondary',
    },
  },
})
