import { helpers } from './base'

export const CarCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    carCardWrapper: {
      border: 'none',
      boxShadow: '0 6px 9px 0 rgba(0,0,0,0.1)',
      backgroundColor: 'brand.white',
    },
    heading: {
      fontSize: 'xl',
    },
    name: {
      fontSize: 'xs',
    },
    attributeListItem: {
      fontSize: 'sm',
    },
    footerContainer: {
      gap: 3,
    },
    cancellationFeesAndPoliciesContainer: {
      h1: {
        marginTop: 2,
        fontSize: 'lg',
        color: 'brand.coolgrey10',
      },
    },
    policyLink: {
      color: 'brand.tealDarker',
      fontSize: 'lg',
    },
    secondaryAttributeWrapper: {
      backgroundColor: 'brand.teal5',
    },

    secondaryAttributeIcon: {
      color: 'brand.primaryShamrockGreen',
    },

    secondaryAttributeHeading: {
      color: 'brand.coolgrey10',
    },

    fuelBadge: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 5,
      backgroundColor: 'brand.shamrock5',
      marginBottom: 2,

      svg: {
        width: 6,
        height: 6,
      },
    },

    electricBadge: {
      svg: {
        color: 'brand.darkCityGreen',
      },
    },

    hybridBadge: {
      svg: {
        color: 'brand.highlightOrange',
      },
    },
  },
})
