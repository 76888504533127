import React, { ReactElement } from 'react'
import { IconName } from '../Icon/Icon.types'

export interface TabState {
  variant?: string
  selectedTab: string
  setSelectedTab(slug: string, includeQuery?: boolean): void
}

export interface TabContentProps {
  slug: string
  title: string
  icon?: IconName
  href?: string
  isVisible?: boolean
  variant?: string
}

export interface TabsProps extends TabState {
  label: string
  children: Array<ReactElement<TabContentProps>>
}

export interface ITabTitleProps extends TabContentProps, TabState {
  onKeyDown(e: React.KeyboardEvent): void
}
