import { helpers } from './base'

export const PopularDestinations = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      '> h4': {
        fontWeight: 'bold',
        fontSize: 'var(--alto-sem-text-heading-xs-font-size)',
        lineHeight: 'var(--alto-sem-text-heading-xs-line-height)',
        letterSpacing: 'var(--alto-sem-text-heading-xs-letter-spacing)',
        color: 'fg.accent.primary',
        marginBottom: {
          base: 'lg',
          lg: 0,
        },
      },
    },
    destinationCard: {
      borderRadius: 'xs',
      h4: {
        fontSize: 'var(--alto-sem-text-heading-2xs-font-size)',
        lineHeight: 'var(--alto-sem-text-heading-2xs-line-height)',
        letterSpacing: 'var(--alto-sem-text-heading-2xs-letter-spacing)',
        fontWeight: 'semibold',
        color: 'fg.accent.secondary',
      },
      p: {
        fontWeight: 'light',
      },
      boxShadow: 'md',
      background: 'bg.layer2.default',
      _hover: {
        background: 'bg.layer1.default',
        h4: {
          color: 'fg.accent.primary',
        },
      },
    },
    thumbnail: {
      borderRadius: 'xs',
    },
  },
})
