import { helpers } from './base'

export const PageContainer = helpers.defineMultiStyleConfig({
  baseStyle: {
    title: {
      h1: {
        fontSize: { base: '3xl', md: '5xl' },
        fontWeight: 'medium',
      },
    },
  },
})
