import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'notificationWrapper',
  'notificationBody',
  'notificationText',
  'notificationButton',
  'iconWrapper',
  'closeIcon',
])

export const NotificationMessage = helpers.defineMultiStyleConfig({
  baseStyle: {
    notificationWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      padding: 3.5,
    },
    iconWrapper: {
      display: 'flex',
      border: 'none',
      bg: 'none',
    },
    closeIcon: {
      cursor: 'pointer',
    },
    notificationBody: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: {
        base: 'column',
        md: 'row',
      },
      justifyContent: 'space-between',
    },
    notificationText: {
      flexGrow: 1,
    },
    notificationButton: {
      alignSelf: {
        base: 'unset',
        md: 'start',
      },
      '> button': {
        width: {
          base: 'full',
          md: 'max-content',
        },
      },
    },
  },
})
