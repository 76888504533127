import { helpers } from './base'

export const RichText = helpers.defineMultiStyleConfig({
  baseStyle: {
    hyperlink: { color: 'brand.tealDarker' },
    heading: {
      fontWeight: 'medium',
    },
  },
})
