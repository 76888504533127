import { helpers } from './base'

export const SearchResultCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    card: {
      padding: {
        base: 5,
        md: 6,
      },
      gap: 5,
    },
    title: {
      fontSize: {
        base: 'xl',
        md: '2xl',
      },
    },
    form: {
      gap: 5,
    },
    details: {
      rowGap: 5,
    },
  },
})
