import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers(['container', 'control', 'label'])

export const TelevisionButton = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    control: {
      cursor: 'pointer',
      px: 4,
      py: 2,
      border: '2px solid',
      borderColor: 'blue.500',
      borderRadius: 'base',
      color: 'blue.500',
      display: 'grid',
      placeContent: 'center',
      _checked: {
        color: '#fff',
        bg: 'blue.500',
      },
      transition: '0.3s ease',
    },
    label: {
      pointerEvents: 'none',
    },
  }),
})
