import { AxiosError } from 'axios'
import { ERROR_CODES } from './ErrorCodes'
import { GenericException } from './GenericException'

export class GenericAPIException extends GenericException {
  constructor(error: AxiosError<any>) {
    super({
      ...ERROR_CODES.PROVIDER_FAILED_UNRECOVERABLE,
      developerMessage:
        'Upstream API Failed due to unknown error, please look for the error in server logs',
      originalError: error,
      responseData: error.response?.data,
    })
  }
}
