import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const roomMoreDetailsModal = createMultiStyleConfigHelpers([
  'heading',
  'roomDetailsContainer',
  'images',
  'info',
  'infoList',
  'infoListItem',
  'options',
  'picture',
  'title',
  'bookButton',
  'book',
  'offerChip',
  'infoContainer',
  'optionsTitle',
  'imageWrapper',
])

export const RoomMoreDetailsModal = roomMoreDetailsModal.defineMultiStyleConfig({
  baseStyle: roomMoreDetailsModal.definePartsStyle({
    heading: {
      mt: 4,
    },
    roomDetailsContainer: {
      display: 'grid',
      gridGap: { base: 4, lg: 6 },
      gridTemplate: {
        base: `'media' auto
        'info' auto
        'options' auto
        'book' auto / 1fr`,
        md: `'media   info    info' auto
        'options options options' auto
        '....... ....... book   ' auto / minmax(max-content, 300px) 1fr auto`,
        lg: `'media   info    info' auto
        'media   options options' auto
        '....... ....... book   ' auto / minmax(max-content, 300px) 1fr auto`,
      },
    },
    images: {
      gridArea: 'media',
      h: '220px',
      m: { base: '-1rem -1rem 0 -1rem', md: '0' },
      borderRadius: { base: '8px 8px 0 0', md: '0' },
    },
    info: {
      display: 'grid',
      gridTemplate: `"features details" auto / 2fr 1fr`,
    },
    infoList: {
      display: { base: 'block', lg: 'grid' },
      gridTemplateColumns: 'repeat(2, minmax(5ch, 1fr))',
      gridGap: 2,
      m: '0',
      p: '0',
      listStyle: 'none',
    },
    infoListItem: {
      fontSize: { base: 'sm', lg: 'lg' },
      mb: 4,
      _last: { mb: '0' },
    },
    options: {
      gridArea: 'options',
    },
    optionsTitle: {
      fontWeight: 'medium',
      m: '0 0 0.5rem 0',
      fontSize: 'sm',
    },
    picture: {
      borderRadius: { base: '8px 8px 0 0', md: '0' },
    },
    title: {
      fontWeight: 'medium',
      m: '0 0 1rem 0',
    },
    bookButton: {
      gridArea: 'book',
      alignSelf: 'end',
      width: 'max-content',
    },
    offerChip: {
      gridArea: 'offerChip',
      display: { base: 'none', md: 'block' },
      mb: { base: '', md: 4 },
    },
    infoContainer: {
      gridArea: 'info',
    },
    imageWrapper: {
      position: 'relative',
    },
  }),
})
