import React from 'react'
import { RichText } from '@reward-platform/lift/components'
import { Document } from '@contentful/rich-text-types'
import {
  ContentfulDynamicVariable,
  ContentfulDynamicVariableSchema,
} from '@reward-platform/shared-schemas'

type DynamicVariableRichTextProps = {
  document: Document
  variableMap?: Record<string, any>
}

const renderDynamicVariables = (
  linkedEntry: ContentfulDynamicVariable,
  valueMap: DynamicVariableRichTextProps['variableMap'] = {}
) => {
  const { key, defaultValue = null } = linkedEntry || {}
  return valueMap[key] ?? defaultValue
}

const renderInlineEntry = (
  linkedEntry?: any,
  valueMap: DynamicVariableRichTextProps['variableMap'] = {}
) => {
  if (!linkedEntry) {
    return null
  }
  const dynamicVariableParse = ContentfulDynamicVariableSchema.safeParse(linkedEntry)
  if (dynamicVariableParse.success) {
    return renderDynamicVariables(dynamicVariableParse.data, valueMap)
  }
  return null
}

const DynamicVariableRichText: React.FC<DynamicVariableRichTextProps> = ({
  document,
  variableMap,
}) => {
  return (
    <RichText
      document={document}
      inlineEntryRenderer={(entry) => renderInlineEntry(entry, variableMap)}
    />
  )
}

export default DynamicVariableRichText
