import { ComponentStyleConfig } from '@chakra-ui/react'

export const Spinner: ComponentStyleConfig = {
  baseStyle: {
    animationDuration: '1.1s',
  },
  sizes: {
    xxl: {
      width: 20,
      height: 20,
    },
  },
  variants: {
    boldCentered: {
      display: 'flex',
      margin: '0 auto',
      borderWidth: '10px',
      size: 'xxl',
    },
    buttonSpinner: {
      width: 3,
      height: 3,
      borderWidth: '2px',
    },
  },
  defaultProps: {
    variant: 'boldCentered',
    size: 'xxl',
  },
}
