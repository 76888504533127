import { helpers } from './base'

export const SelectBox = helpers.defineMultiStyleConfig({
  baseStyle: {
    selectBoxLabel: {
      color: 'fg.primary',
    },
    selectBoxSelect: {
      borderColor: 'border.primary.active',
      borderRadius: 'var(--alto-text-field-radius)',
    },
    chevronColor: {
      color: 'fg.secondary',
    },
  },
  variants: {
    error: {
      selectBoxLabel: {
        color: 'bg.critical.vibrant.default',
      },
      selectBoxSelect: {
        borderColor: 'var(--alto-sem-color-bg-critical-vibrant-default)',
      },
    },
  },
})
