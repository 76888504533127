import { helpers } from './base'

export const PriceBreakdownCollapsibleContent = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    collapsibleFees: {
      span: {
        lineHeight: 1.8,
      },
    },
    collapsibleDescription: {
      fontWeight: 'normal',
    },
  }),
})
