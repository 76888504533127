import React, { useEffect, useMemo, useState } from 'react'
import { DismissibleModal } from '@reward-platform/lift/components'
import { usePromotionContext } from '~/context/promotion'
import { useUser } from '~/context/user'
import { CurrencyCodes } from '@reward-platform/ancillaries-schemas/country-currency-codes'
import { createCurrencyFormatter, getAmountForCurrencyCode } from '~/utils/formatAmount'
import { PromotionItemProps, PromotionModalProps } from './Promotion.types'
import { DynamicVariableRichText } from '../shared/DynamicVariableRichText'
import PromotionBadge from './PromotionBadge'

const PromotionCheckoutModal = ({
  product,
  item,
  isIncentive = true,
  primaryAction,
  secondaryAction,
}: PromotionItemProps & PromotionModalProps) => {
  const { isEnabled, getProductPromotion } = usePromotionContext()
  const { data, content, variableMap, isItemValidForPromotion } = getProductPromotion(product) ?? {}
  const { checkoutModal } = content ?? {}
  const isItemValid = isItemValidForPromotion?.(item, { isIncentive })
  const isCheckoutModalEnabled = isEnabled(product) && checkoutModal && isItemValid
  const { user, userLocale } = useUser()
  const [modalKey, setModalKey] = useState(0)

  const itemDifference = useMemo(() => {
    const userCurrencyCode = user?.person?.currencyCode as CurrencyCodes
    const currencyFormatter = createCurrencyFormatter(userCurrencyCode, userLocale)

    const minimumSpend =
      data?.minimumSpend && getAmountForCurrencyCode(data.minimumSpend, userCurrencyCode)

    if (!minimumSpend || !item?.cashAmount) {
      return ''
    }
    return currencyFormatter.format(minimumSpend.amount - item.cashAmount)
  }, [data, item, user, userLocale])

  useEffect(() => {
    setModalKey(Date.now())
  }, [item])

  if (!isCheckoutModalEnabled) {
    return null
  }

  const customVariableMap = { ...variableMap, promotionAmountDifference: itemDifference }

  return (
    <DismissibleModal
      title={checkoutModal.title}
      imageUrl={checkoutModal.image?.file?.url}
      primaryActionLabel={checkoutModal.primaryButtonText}
      secondaryActionLabel={checkoutModal.secondaryButtonText}
      primaryAction={primaryAction}
      secondaryAction={secondaryAction}
      key={modalKey}
    >
      <PromotionBadge product={product} item={item} isIncentive />
      {checkoutModal.body ? (
        <DynamicVariableRichText document={checkoutModal.body} variableMap={customVariableMap} />
      ) : null}
    </DismissibleModal>
  )
}

export default PromotionCheckoutModal
