import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'container',
  'infoContainer',
  'images',
  'info',
  'infoList',
  'infoListItem',
  'optionsContainer',
  'options',
  'title',
  'duration',
  'detailsButton',
  'bookButton',
  'offerChip',
  'iconColor',
  'imageWrapper',
])

export const RoomDetailsCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      display: 'grid',
      gridTemplate: `
        'imagesContainer' 200px
        'detailsContainer' auto
        'optionsContainer' auto`,
      borderRadius: 'lg',
      gridGap: { base: 4, lg: 6 },
      padding: 6,
      marginBottom: 6,
      gridTemplateAreas: {
        lg: `'imagesContainer infoContainer'
             '............... optionsContainer'`,
        md: `
          'imagesContainer infoContainer'
          'optionsContainer optionsContainer'`,
        base: `
          'imagesContainer' 'infoContainer' 'optionsContainer'`,
      },
      gridTemplateColumns: { md: '300px auto' },
      gridTemplateRows: { md: '200px auto', lg: '100px 100px auto' },
    },
    infoContainer: {
      gridArea: 'infoContainer',
    },
    images: {
      gridArea: 'imagesContainer',
      gridRow: { lg: '1/3' },
      height: { lg: '200px' },
      margin: {
        base: '- var(--chakra-space-6) - var(--chakra-space-6) 0 - var(--chakra-space-6)',
        md: 0,
      },

      img: {
        borderRadius: { base: 'var(--chakra-radii-sm) var(--chakra-radii-sm) 0 0', md: 0 },
        objectFit: 'cover',
      },
    },
    info: {
      gridTemplate: `'features details' auto / 2fr 0.5fr`,
      marginBottom: 6,
    },
    infoList: {
      display: { base: 'block', lg: 'grid' },
      gridTemplateColumns: 'repeat(2, minmax(5ch, 1fr))',
      margin: 0,
      listStyle: 'none',
      padding: 0,

      svg: {
        marginRight: 2,
      },
    },
    infoListItem: {
      fontSize: { base: 'sm', lg: 'lg' },
      marginBottom: 4,
      display: 'flex',
      alignItems: 'center',

      '&:last-child': {
        marginBottom: { base: 0, md: 4 },
      },
    },
    optionsContainer: {
      gridArea: 'optionsContainer',
      gridRow: { lg: '2/4' },
    },
    options: {
      h6: {
        fontWeight: 'medium',
        margin: '0 0 var(--chakra-space-2) 0',
        fontSize: 'sm',
      },
    },
    title: {
      fontWeight: 'medium',
      margin: 0,
      marginBottom: 4,
    },
    duration: {
      fontSize: 'sm',
      fontWeight: 'normal',
      whiteSpace: 'nowrap',
      textAlign: 'end',
      marginBottom: 2,
    },
    detailsButton: {
      gridArea: 'details',
      justifySelf: 'end',
      width: 'max-content',
      fontSize: 'sm',
    },
    bookButton: {
      gridArea: 'book',
      alignSelf: 'end',
      width: 'max-content',
      paddingLeft: 5,
      paddingRight: 5,
    },
    offerChip: {
      display: { base: 'none', md: 'block' },
      margin: 4,
    },
    iconColor: {
      color: 'brand.black',
    },
    imageWrapper: {
      position: 'relative',
    },
  },
})
