import { helper } from './base'

const brandPrimaryBlue = 'brand.primary.blue'

export const UpgradeBasketItem = helper.defineMultiStyleConfig({
  baseStyle: {
    container: {
      bg: 'brand.backgroundBlue500',
      'label > p': {
        width: 'full',
        paddingRight: '10px',
        fontSize: 'xs',
        fontWeight: { base: '400', md: '500' },
        color: 'brand.primary.midnighBlue',
        lineHeight: '4',
      },
      'div:nth-child(2) > div > div': {
        '> input': {
          borderRadius: '4px',
        },
      },
      ul: {
        '> li > div': {
          '> div': {
            '> span': {
              '> p': {
                fontSize: 'xs',
                fontWeight: '400',
                color: 'brand.primary.midnighBlue',
                lineHeight: '15px',
              },
            },
          },
        },
      },
    },
    icon: {
      color: brandPrimaryBlue,
    },
    iconCircle: {
      'path:first-child': {
        fill: brandPrimaryBlue,
      },
      'path:nth-child(2)': {
        fill: 'brand.white',
      },
    },
    text: {
      fontSize: 'md',
      fontWeight: { base: '700', md: '500' },
      color: brandPrimaryBlue,
      lineHeight: '5',
    },
  },
  variants: {
    upgrade: {
      button: {
        backgroundColor: 'brand.highlight.blue',
      },
    },
    confirmUpgrade: {
      button: {
        backgroundColor: brandPrimaryBlue,
      },
    },
  },
})
