import { helpers } from './base'

export const RoomDetailsCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      boxShadow: 'md',
      bg: 'bg.layer2.default',
      borderRadius: 'xs',
      p: 'lg',
    },
    title: {
      fontSize: 'var(--alto-sem-text-heading-xs-font-size)',
      lineHeight: 'var(--alto-sem-text-heading-xs-line-height)',
      letterSpacing: 'var(--alto-sem-text-heading-xs-letter-spacing)',
      fontWeight: 'bold',
      mb: 'lg',
      color: 'fg.accent.secondary',
    },
    infoListItem: {
      color: 'fg.secondary',
      fontSize: 'var(--alto-sem-text-body-md-font-size)',
      lineHeight: 'var(--alto-sem-text-body-md-line-height)',
      letterSpacing: 'var(--alto-sem-text-body-md-letter-spacing)',
      fontWeight: 'normal',
    },
    iconColor: {
      color: 'fg.secondary',
    },
    duration: {
      strong: {
        fontWeight: 'semibold',
      },
      mb: 'sm',
    },
  },
})
