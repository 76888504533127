import { ComponentSingleStyleConfig } from '@chakra-ui/react'

const accentDeep = 'bg.accent.deep.default'
const accentVibrant = 'bg.accent.vibrant.default'

export const TextMarker: ComponentSingleStyleConfig = {
  baseStyle: {
    wrapper: {
      background: accentDeep,
      color: 'fg.accent.onVibrant',
      transitionDelay: '0.3s',
      '&[data-selected="true"]': {
        background: accentVibrant,
        _after: {
          borderTopColor: accentVibrant,
        },
      },
      _before: {
        borderTopColor: 'fg.accent.onVibrant',
      },

      _after: {
        borderTopColor: accentDeep,
      },

      _hover: {
        background: accentVibrant,
        _after: {
          borderTopColor: accentVibrant,
        },
      },
    },
  },
}
