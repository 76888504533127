// TODO: Check if all options have the same currency

import Big from 'big.js'
import { Basket, BasketItem } from '../basket'
import { CarHireItemType, ExperienceItemType, FlightItemType, HotelItemType } from '../common'
import { SelectedPaymentOptions } from '../form-data'
import { getBalance, getSurchargesTotal } from './balance'
import { getCurrencyCodeFromBasket } from './findCurrencyCode'
import { Balance, PriceBreakdownTotals, TotalBalance } from './types'

const EMPTY_BALANCE: Balance = {
  aviosCollect: 0,
  aviosSpent: 0,
  cashTotal: new Big(0),
  cashBase: new Big(0),
  cashTaxes: new Big(0),
  cashTotalWithoutTaxes: new Big(0),
  isSalesTaxType: true,
  surcharges: { total: new Big(0), surchargesBreakdown: [] },
}
const EMPTY_TOTAL_BALANCE: TotalBalance = {
  aviosCollect: 0,
  aviosSpent: 0,
  cashTotal: new Big(0),
  cashBase: new Big(0),
  cashTaxes: new Big(0),
  cashTotalWithoutTaxes: new Big(0),
  surcharges: new Big(0),
}

const isEmptyBalance = (balance: Balance): boolean =>
  balance.aviosCollect === 0 && balance.aviosSpent === 0 && balance.cashTotal.eq(0)

export const calculatePriceBreakdown = (
  basketItems: Basket['items'],
  selectedPaymentOptions: SelectedPaymentOptions
): PriceBreakdownTotals => {
  // Take only the first items of each type because that's what we support currently
  const getFirstItem = (items: BasketItem[]): BasketItem[] => (items[0] ? [items[0]] : [])

  const hotel = getFirstItem(basketItems.HOTEL)
  const carHire = getFirstItem(basketItems.CAR_HIRE)
  const experience = getFirstItem(basketItems.EXPERIENCE)
  const flight = getFirstItem(basketItems.FLIGHT)
  const currencyCode = getCurrencyCodeFromBasket(basketItems)

  const getSurchargesBreakdown = (currentBasketItem: BasketItem) =>
    currentBasketItem.surcharges.map((surcharge) => ({
      feeCode: surcharge.feeCode,
      feeName: surcharge.feeName,
      amount: new Big(surcharge.amount),
    }))

  const processBasketItem = (acc: Balance, currentBasketItem: BasketItem): Balance => {
    const paymentOption = selectedPaymentOptions[currentBasketItem.type]
    const selectedPaymentOptionId =
      paymentOption?.basketItemId === currentBasketItem.id && paymentOption.paymentOptionId

    if (!selectedPaymentOptionId) {
      return acc
    }

    const balance = getBalance(currentBasketItem.paymentOptions, selectedPaymentOptionId)
    if (!balance) {
      return acc
    }

    const {
      aviosCollect,
      aviosSpent,
      cashTotal,
      cashBase,
      cashTaxes,
      isSalesTaxType,
      taxDetails,
      grandTotal,
    } = balance

    const surchargesTotal: Big = getSurchargesTotal(currentBasketItem)

    return {
      aviosCollect: acc.aviosCollect + aviosCollect,
      aviosSpent: acc.aviosSpent + aviosSpent,
      cashTotal: grandTotal || acc.cashTotal.plus(cashTotal),
      cashTotalWithoutTaxes: acc.cashTotal.plus(cashTotal),
      cashBase: acc.cashBase.plus(cashBase),
      cashTaxes: acc.cashTaxes.plus(cashTaxes),
      isSalesTaxType,
      surcharges: {
        total: acc.surcharges.total.plus(surchargesTotal),
        surchargesBreakdown: getSurchargesBreakdown(currentBasketItem),
      },
      taxDetails,
    }
  }

  const getCategoryResult = (items: BasketItem[]): Balance =>
    items.reduce(processBasketItem, EMPTY_BALANCE)

  const hotelResult = getCategoryResult(hotel)
  const carHireResult = getCategoryResult(carHire)
  const experienceResult = getCategoryResult(experience)
  const flightResult = getCategoryResult(flight)

  const totalSurcharges = hotelResult.surcharges.total.plus(experienceResult.surcharges.total)

  const total: TotalBalance = [hotelResult, carHireResult, experienceResult, flightResult].reduce(
    (acc, curr) => ({
      aviosCollect: acc.aviosCollect + curr.aviosCollect,
      aviosSpent: acc.aviosSpent + curr.aviosSpent,
      cashTotal: acc.cashTotal.plus(curr.cashTotal),
      cashTotalWithoutTaxes: acc.cashTotalWithoutTaxes.plus(curr.cashTotalWithoutTaxes),
      cashBase: acc.cashBase.plus(curr.cashBase),
      cashTaxes: acc.cashTaxes.plus(curr.cashTaxes),
      surcharges: totalSurcharges,
    }),
    EMPTY_TOTAL_BALANCE
  )

  return {
    balance: {
      ...(!isEmptyBalance(hotelResult) && { [HotelItemType]: hotelResult }),
      ...(!isEmptyBalance(carHireResult) && { [CarHireItemType]: carHireResult }),
      ...(!isEmptyBalance(experienceResult) && { [ExperienceItemType]: experienceResult }),
      ...(!isEmptyBalance(flightResult) && { [FlightItemType]: flightResult }),
    },
    total,
    currencyCode,
  }
}
