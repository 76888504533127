import { z } from 'zod'

const FeeAmountSchema = z.object({
  currency: z.string(),
  amount: z.number(),
})

const PackageRuleSchema = z.object({
  packageRuleId: z.string().optional(),
  packageType: z.string().optional(),
  ruleTypeInd: z.boolean().optional(),
  startDateTime: z.object({ formattedDateTime: z.string() }).optional(),
  endDateTime: z.object({ formattedDateTime: z.string() }).optional(),
  chargeableUnit: z.number().int().optional(),
  percent: z.number().optional(),
  feeAmount: z
    .preprocess((val) => {
      const response = FeeAmountSchema.safeParse(val)

      if (!response.success) {
        return null
      }

      return val
    }, FeeAmountSchema)
    .optional(),
})

export const PackageRulesSchema = z.array(PackageRuleSchema)
export type PackageRules = z.infer<typeof PackageRulesSchema>
