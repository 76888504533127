/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const CarPriceBar = helpers.defineMultiStyleConfig({
  baseStyle: {
    box: {
      bgColor: 'bg.layer2.default',
    },
    subtotalWrapper: {
      bgColor: 'bg.layer1.default',
    },
    subtotalTitle: {
      color: 'fg.accent.primary',
      fontWeight: 'medium',
      fontSize: 'var(--alto-sem-text-heading-sm-font-size)',
    },
    carSize: {
      fontSize: 'sm',
      fontWeight: 'normal',
    },
    iconWrapper: {
      bg: 'bg.success.vibrant.default',
    },
  },
})
