import React from 'react'
import Script from 'next/script'
import { isProduction } from '~/utils/envChecks'

const Usercentrics = ({ id }: { id: string }) => {
  const usercentricsDataVersion = isProduction() ? undefined : 'preview'

  return (
    <Script
      id="usercentrics-cmp"
      src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
      data-settings-id={id}
      async
      data-version={usercentricsDataVersion}
    />
  )
}

export default Usercentrics
