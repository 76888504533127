import { Box, BoxProps, useMultiStyleConfig } from '@chakra-ui/react'
import { AlertProvider, AlertStatus, AlertVariant } from './AlertProvider'

export type AlertProps = BoxProps & {
  status: AlertStatus
  variant?: AlertVariant
}

export const Alert = (props: AlertProps) => {
  const { status, variant = 'standard' } = props
  const styles = useMultiStyleConfig('Alert', { variant })
  return (
    <AlertProvider status={status} variant={variant}>
      <Box data-status={status} role="alert" sx={styles.container} {...props} />
    </AlertProvider>
  )
}
