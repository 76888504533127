import React from 'react'
import { usePromotionContext } from '~/context/promotion'
import { CardBanner, RichText } from '@reward-platform/lift/components'
import { PromotionProps } from './Promotion.types'

const PromotionResultsPageBanner = ({ product }: PromotionProps) => {
  const { getProductPromotion } = usePromotionContext()
  const activePromotion = getProductPromotion(product)
  const { resultsPageBanner } = activePromotion?.content || {}

  if (!resultsPageBanner) {
    return null
  }

  return (
    <CardBanner imageUrl={resultsPageBanner.image.file?.url}>
      <CardBanner.MainContent>
        <CardBanner.Headline>{resultsPageBanner.heading}</CardBanner.Headline>
        <CardBanner.Body>
          {resultsPageBanner.text?.content ? <RichText document={resultsPageBanner.text} /> : null}
        </CardBanner.Body>
      </CardBanner.MainContent>
      <CardBanner.Note>
        {resultsPageBanner.note?.content ? <RichText document={resultsPageBanner.note} /> : null}
      </CardBanner.Note>
    </CardBanner>
  )
}

export default PromotionResultsPageBanner
