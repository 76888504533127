/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const HotelAdditionalDetails = helpers.defineMultiStyleConfig({
  baseStyle: {
    hotelExtraInformationWrapper: {
      '> div': {
        '> button': {
          bg: 'bg.accent.subtle.default',
          borderColor: 'border.accent',
          borderRadius: 'xs',
          span: {
            fontWeight: 'medium',
            color: 'fg.accent.onSubtle',
          },
          svg: {
            color: 'fg.accent.onSubtle',
          },
        },
        '> div > section': {
          borderRadius: 'xs',
        },
      },
    },
    roomDetail: {
      bg: 'bg.accent.subtle.default',
      color: 'fg.accent.onSubtle',
      borderRadius: 'xs',
      alignContent: 'center',
      span: {
        fontSize: 'xs',
      },
    },
  },
})
