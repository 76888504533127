import { helpers } from './base'

const primaryMidnightBlue = 'brand.primary.midnightBlue'

export const ExperienceDetails = helpers.defineMultiStyleConfig({
  baseStyle: {
    firstPart: {
      gap: {
        base: 6,
        md: 14,
      },
      marginTop: 10,
    },
    divider: {
      borderColor: 'transparent',
    },
    experienceOverviewTitle: {
      fontSize: '4xl-5',
      color: primaryMidnightBlue,
    },
    experienceOverviewContainer: {
      padding: 0,
      borderRadius: 0,
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
    sectionTitle: {
      marginBottom: 4,
      fontSize: '2xl',
    },
    showMoreButton: {
      border: '1px solid brand.primary.midnightBlue',
      borderColor: primaryMidnightBlue,
      _hover: {
        bg: primaryMidnightBlue,
      },
    },
    experienceOverviewDescription: {
      mt: 4,
    },
    experienceInformationTitle: {
      color: primaryMidnightBlue,
    },
    experienceInformationContainer: {
      color: primaryMidnightBlue,

      '> ul': {
        mt: 6,
      },
    },
  },
})
