import {
  GenericException,
  GenericExceptionError,
} from '@reward-platform/shared-api/exceptions/GenericException'

export class NextServerError extends GenericException {
  constructor(error: GenericExceptionError) {
    super({ errorDescription: 'NEXT_SERVER_ERROR', ...error })
  }
}
