import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers(['container', 'text'])

export const Badge = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      display: 'flex',
      height: { base: 14, md: 16 },
      padding: '0.625rem 1rem',
      alignItems: 'center',
      gap: '0.875rem',
      borderRadius: '1rem',
      boxShadow: 'var(--chakra-shadows-light)',
    },
    text: {
      fontWeight: '400',
      fontSize: 'sm',
    },
  },
})
