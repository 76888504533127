import { helpers } from './base'

const headingFont = 'var(--chakra-fonts-heading)'
const darkCityGreen = 'brand.confirmationGreen'
const red = 'brand.primary.red'
const cloudWhite = 'brand.cloudWhite'

export const BookingStatus = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    container: {
      '>span:first-of-type': {
        fontSize: { base: 'lg', lg: 'xl' },
        fontFamily: headingFont,
      },
    },
    childContainer: {
      '>*': {
        fontSize: { base: 'sm', lg: 'md' },
        lineHeight: { base: 'tall', lg: 'base' },
      },
      '>p a': {
        '&:link, &:visited': { color: 'brand.highlight.blue', fontWeight: 'medium' },
        '&:active, &:hover, &:focus': { textDecoration: 'none' },
        '&:active': { color: 'brand.primary.blue' },
      },
    },
    title: {
      fontSize: { base: 'lg', lg: 'xl' },
      fontFamily: headingFont,
    },
    collectedAvios: {
      p: {
        fontSize: 'xs',
      },
    },
    priceBreakdownRowWrapper: {
      bg: 'brand.white',
      boxShadow: 'brand.boxShadow',
      borderRadius: 2,
    },
    successColor: {
      bg: darkCityGreen,
      color: cloudWhite,
    },
    failColor: {
      bg: red,
      color: cloudWhite,
    },
    summaryTotal: {
      div: {
        _first: {
          fontSize: 'xs',
        },
        _last: {
          fontSize: { base: 'xl', lg: '3xl', xl: '4xl' },
          fontFamily: headingFont,
          color: 'brand.primary.blue',
        },
      },
    },
  }),
})
