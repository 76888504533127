import { addressLookupOptionHelpers } from './base'

export const AddressLookupOption = addressLookupOptionHelpers.defineMultiStyleConfig({
  baseStyle: {
    addressLookupOptionWrapper: {
      borderColor: 'brand.coolgrey2',
      _hover: {
        backgroundColor: 'brand.teal10',
      },
      '&[data-highlighted=true]': {
        backgroundColor: 'brand.teal10',
      },
    },
    autoCompleteListTitle: {
      fontWeight: 'bold',
      color: 'brand.primaryTeal',
    },
  },
})
