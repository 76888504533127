import { helpers } from './base'

export const List = helpers.defineMultiStyleConfig({
  baseStyle: {
    item: {
      fontSize: '1rem',
      color: 'brand.grey6',
    },
    bullet: {
      backgroundColor: 'brand.grey6',
    },
  },
})
