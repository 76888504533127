import { helper } from './base'

export const OrderListTable = helper.defineMultiStyleConfig({
  baseStyle: helper.definePartsStyle({
    orderListTableGrid: {
      bg: 'brand.teal5',
      borderRadius: 'lg',
      h3: {
        fontWeight: 'semibold',
      },
    },
    orderListTableItemGrid: {
      bg: 'brand.white',
      borderRadius: 'lg',
    },
    accented: {
      fontWeight: 'semibold',
      svg: {
        color: 'brand.primaryTeal',
      },
    },
    lineBalance: {
      p: {
        fontWeight: 'medium',
      },
    },
    lineName: {
      p: {
        fontWeight: 'medium',
      },
    },
    textBold: {
      fontWeight: 'semibold',
    },
    lineDetails: {
      color: 'brand.coolgrey10',
      fontWeight: 'medium',
    },
    lighter: {
      fontWeight: 'normal',
    },
  }),
})
