import { helpers } from './base'

export const ExperienceInformation = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      borderRadius: 'sm',
      backgroundColor: 'bg.layer2.default',
      boxShadow: 'md',
      padding: 'lg',
      p: {
        fontSize: 'md',
      },
    },
    title: {
      fontSize: 'var(--alto-sem-text-heading-sm-font-size)',
      lineHeight: 'var(--alto-sem-text-heading-sm-line-height)',
      fontWeight: 'medium',
      color: 'fg.accent.secondary',
      mb: 'sm',
    },
    description: {
      color: 'fg.primary',
      fontSize: 'md',
    },
  },
})
