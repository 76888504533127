import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'firstPart',
  'title',
  'overview',
  'pricingAndSearch',
  'showMoreContainer',
  'showMoreButton',
  'experienceOverviewTitle',
  'experienceOverviewDescription',
  'experienceOverviewContainer',
  'divider',
  'sectionTitle',
  'experienceInformationTitle',
  'experienceInformationContainer',
  'reviewCount',
  'skeletonContainer',
])

export const ExperienceDetails = helpers.defineMultiStyleConfig({
  baseStyle: {
    firstPart: {
      display: 'grid',
      gridTemplateAreas: {
        base: `'title' 'overview' 'pricing-and-search'`,
        md: `'title title' 'overview pricing-and-search'`,
        lg: `'title pricing-and-search' 'overview pricing-and-search'`,
      },
    },
    title: {
      gridArea: 'title',
    },
    overview: {
      gridArea: 'overview',
    },
    pricingAndSearch: {
      gridArea: 'pricing-and-search',
    },
    showMoreContainer: {
      marginTop: 4,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    showMoreButton: {
      width: 'auto',
      height: 7,
      px: 2.5,
      py: 6,
      fontSize: 'sm',
    },
    experienceOverviewDescription: {
      fontSize: 'md',
    },
    experienceInformationContainer: {
      '> ul': {
        mt: 10,
      },
      '> ul > li+li': {
        mt: 6,
      },
    },
    reviewCount: {
      my: 10,
    },
    skeletonContainer: {
      padding: 6,
      boxShadow: '0px 2px 4px rgba(0,0,0, 0.1)',
      backgroundColor: 'brand.white',
    },
  },
})
