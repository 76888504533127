import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const ancillaryBasketItemDetailsHelpers = createMultiStyleConfigHelpers([
  'content',
  'details',
  'header',
])

export const AncillaryBasketItemDetails = ancillaryBasketItemDetailsHelpers.defineMultiStyleConfig({
  baseStyle: {
    content: {
      borderRadius: 'inherit',
      overflow: 'hidden',
      w: '100%',
      gridArea: 'details',
    },
    details: {
      display: 'flex',
      flexDir: { base: 'column', xl: 'row' },
      p: { base: 4, lg: 6 },
      gap: 6,
      minH: '100%',
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      flex: '3',
      '> div:first-of-type': {
        h4: {
          mt: 0,
          fontSize: 'lg',
          lineHeight: 'normal',
          fontWeight: 'medium',
        },
        h5: {
          mt: 1,
          fontSize: 'xs',
          fontWeight: 'normal',
        },
        'div, p': {
          mt: 4,
          fontSize: 'sm',
        },
      },
    },
  },
})
