import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const refundabilityButtonHelpers = createMultiStyleConfigHelpers([
  'cardWrapper',
  'removeButton',
  'buttonContainer',
  'paymentOptions',
  'modalCardWrapper',
  'optionInfoBox',
])

export const RefundabilityButton = refundabilityButtonHelpers.defineMultiStyleConfig({
  baseStyle: {
    optionInfoBox: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 2,
    },
    amenityIcon: {
      width: 4,
      marginRight: 2,
    },
    inlineText: {
      fontSize: 'sm',
      lineHeight: 'shorter',
      fontHeight: 'light',
    },
    modalIcon: {
      width: 4,
      marginLeft: 2,
    },
    infoButton: {
      border: 0,
      padding: 0,
      cursor: 'pointer',
      backgroundColor: 'unset',
      marginLeft: 1,
      display: 'inline-flex',
    },
  },
})
