import {
  Children,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  KeyboardEvent,
  isValidElement,
} from 'react'
import NextLink from 'next/link'
import { Box, Text, Button, chakra, useMultiStyleConfig, ChakraProps, Icon } from '@chakra-ui/react'
import { clamp } from '../../utils/clamp'
import { CarouselProps } from './Carousel.types'
import { convertIconSetToChakraSVG } from '../Icon/Icon.utils'

// TODO: Add aria-active states to child elements
export default function Carousel({
  gridArea,
  href,
  children,
  overlay,
  objectFit,
  buttonPosition,
  label,
  showCounter,
  activeSlide: _activeSlide,
}: CarouselProps): ReactElement {
  const styles = useMultiStyleConfig('Carousel')
  const activeSlide = useRef<number>(_activeSlide ?? 0)
  const galleryRef = useRef<HTMLAnchorElement | null>(null)
  const observer = useRef<IntersectionObserver | null>(null)

  // IntersectionObservers are incredibly difficult to test without a browser
  // Change this when e2e testing is added
  /* istanbul ignore next */
  if (observer.current === null && typeof window !== 'undefined') {
    observer.current = new IntersectionObserver(
      (entries) => {
        /* istanbul ignore next */
        entries.forEach((entry) => {
          if (!entry.isIntersecting || galleryRef.current === null) {
            return
          }
          activeSlide.current = Array.prototype.indexOf.call(
            galleryRef.current.children,
            entry.target
          )
        })
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 1,
      }
    )
  }
  const galleryRefCallback = useCallback(
    (el: HTMLAnchorElement) => {
      galleryRef.current = el
      observer.current?.disconnect()
      if (el === null) {
        return
      }
      for (let i = 0; i < el.children.length; i++) {
        observer.current?.observe(el.children[i])
      }
    },
    // We want the callback to re-run when the children change, so we can observe them
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [children]
  )

  // This method is fairly predictable and shouldn't neeed to be covered
  /* istanbul ignore next */
  const childCount = () => galleryRef.current?.childElementCount ?? 0
  const scrollToActiveSlide = () => {
    galleryRef.current?.children[activeSlide.current].scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest',
    })
  }
  const scrollRight = () => {
    activeSlide.current = clamp(activeSlide.current + 1, 0, childCount() - 1)
    scrollToActiveSlide()
  }
  const scrollLeft = () => {
    activeSlide.current = clamp(activeSlide.current - 1, 0, childCount() - 1)
    scrollToActiveSlide()
  }
  const handleKeyboardEvent = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'ArrowLeft') {
      scrollLeft()
    }
    if (e.key === 'ArrowRight') {
      scrollRight()
    }
  }
  const images = useMemo(
    () =>
      showCounter
        ? Children.map(children, (child, index) => {
            const showImgTitle = isValidElement(child) && child?.props.alt
            return (
              <chakra.figure>
                {child}
                <chakra.figcaption __css={styles.imageCounter}>
                  {showImgTitle && (
                    <Text gridArea="a" ml={5} justifySelf="start">
                      {(child as ReactElement).props.alt}
                    </Text>
                  )}
                  <Text gridArea="b">
                    {`${index + 1} of
                  ${Children.count(children)}`}
                  </Text>
                </chakra.figcaption>
              </chakra.figure>
            )
          })
        : children,
    [children, showCounter, styles.imageCounter]
  )

  const buttonPositioning: ChakraProps = {
    bottom: buttonPosition === 'middle' ? '50%' : '0',
    ...(buttonPosition === 'middle' ? { transform: 'translate(0, 50%)' } : {}),
  }

  useEffect(() => {
    if (typeof _activeSlide === 'number') {
      activeSlide.current = _activeSlide
      scrollToActiveSlide()
    }
  }, [_activeSlide])

  return (
    <Box
      __css={styles.container}
      gridArea={gridArea}
      onKeyDown={handleKeyboardEvent}
      tabIndex={0}
      role="region"
      aria-label={label}
    >
      {href ? (
        <NextLink href={href} passHref>
          <chakra.a
            __css={{
              ...styles.gallery,
              '& img': {
                height: '100%',
                width: '100%',
                objectFit,
              },
            }}
            ref={galleryRefCallback}
          >
            {images}
          </chakra.a>
        </NextLink>
      ) : (
        <chakra.a
          __css={{
            ...styles.gallery,
            '& img': {
              height: '100%',
              width: '100%',
              objectFit,
            },
          }}
          as="div"
          ref={galleryRefCallback}
        >
          {images}
        </chakra.a>
      )}
      <Button
        __css={{ ...styles.navigationButton, ...styles.leftButton }}
        {...buttonPositioning}
        title="previous"
        aria-label="Previous"
        onClick={scrollLeft}
      >
        <Icon as={convertIconSetToChakraSVG('left-chevron')} sx={styles.navigationIcon} />
      </Button>
      <Button
        __css={{ ...styles.navigationButton, ...styles.rightButton }}
        {...buttonPositioning}
        title="next"
        aria-label="Next"
        onClick={scrollRight}
      >
        <Icon as={convertIconSetToChakraSVG('right-chevron')} sx={styles.navigationIcon} />
      </Button>
      {overlay || null}
    </Box>
  )
}
