import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers(['title', 'titleSpacer', 'primary', 'side'])

export const PageContainer = helpers.defineMultiStyleConfig({
  baseStyle: {
    title: {
      px: {
        base: 'auto',
        lg: 6,
      },
      mt: 6,
      mb: 6,
      h1: {
        lineHeight: 1.25,
      },
    },
    titleSpacer: {
      w: 'full',
      mt: 6,
    },
    primary: {
      w: 'full',
      flex: {
        base: 'unset',
        md: 3,
        lg: 4,
      },
      paddingLeft: {
        base: 'auto',
        lg: 6,
      },
    },
    side: {
      h: 'full',
      flex: {
        base: 'unset',
        md: 1.5,
      },
      paddingRight: {
        base: 'auto',
        lg: 6,
      },
    },
  },
})
