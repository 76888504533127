import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const priceBreakdownStickyHelpers = createMultiStyleConfigHelpers([
  'buttonContainer',
  'stickyContainer',
  'priceContainer',
  'buttonContainer',
  'priceLabel',
])

export const PriceBreakdownSticky = priceBreakdownStickyHelpers.defineMultiStyleConfig({
  baseStyle: priceBreakdownStickyHelpers.definePartsStyle({
    stickyWrapper: {
      width: '100%',
      position: 'sticky',
      bottom: '-1px',
      zIndex: 2,

      display: {
        md: 'none',
      },

      pb: 4,
    },
    stickyContainer: {
      padding: 4,
      borderRadius: 'inherit',
      transition: 'all 0.2s ease-in-out',
    },
    priceContainer: {
      display: 'flex',
      flexDirection: 'column',
      pb: 4,
    },
    buttonContainer: {
      button: {
        width: '100%',
        pt: 6,
        pb: 6,
      },
    },
  }),
})
