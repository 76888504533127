import { helpers } from './base'

export const SearchResultCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    card: {
      padding: {
        base: 'md',
        md: 'lg',
      },
      gap: 'md',
    },
    title: {
      fontSize: 'var(--alto-sem-text-heading-xs-font-size)',
      lineHeight: 'var(--alto-sem-text-heading-xs-line-height)',
      color: 'fg.accent.secondary',
      fontWeight: 'medium',
    },
    form: {
      gap: 'md',
    },
    details: {
      rowGap: 'md',
    },
  },
})
