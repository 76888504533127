import { carItemExtrasPopoverHelper } from './base'

export const CarItemExtrasPopover = carItemExtrasPopoverHelper.defineMultiStyleConfig({
  baseStyle: carItemExtrasPopoverHelper.definePartsStyle({
    extrasIcon: {
      backgroundColor: 'brand.primaryShamrockGreen',
    },
    carExtraWrapper: {
      button: {
        bg: 'brand.teal5',
        borderColor: 'rgba(var(--chakra-colors-rgb-alPrimaryTeal), 0.2)',
      },
      li: {
        bg: 'brand.teal5',
        borderRadius: 'lg',
        span: {
          fontWeight: 'medium',
          lineHeight: 1.25,
        },
      },
    },
  }),
})
