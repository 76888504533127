import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers(['collapsibleFees', 'collapsibleDescription'])
export const PriceBreakdownCollapsibleContent = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    collapsibleFees: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      '> span:first-of-type:first-letter': {
        textTransform: 'capitalize',
      },
      marginRight: '5',
    },
    collapsibleDescription: {
      pt: 1,
    },
  }),
})
