/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */

// TODO - fix lint issues relating for above

import { useRef, useEffect, useState, useCallback, PropsWithChildren } from 'react'
import { Icon, Button, chakra, useMediaQuery, useMultiStyleConfig } from '@chakra-ui/react'
import { createPortal } from 'react-dom'

import { deviceSizes } from '../../theme/deviceSizes'
import { MobileModalProps } from './MobileModal.types'
import { IconSizes } from '../../theme/iconSize'
import { convertIconSetToChakraSVG } from '../Icon/Icon.utils'

// Implementation based on Next.js documentation https://github.com/vercel/next.js/tree/canary/examples/with-portals.

type ClientOnlyPortalProps = {
  selector: string
  children?: any
}

function ClientOnlyPortal({ children, selector }: PropsWithChildren<ClientOnlyPortalProps>) {
  const ref = useRef<Element | null>(null)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    ref.current = document.querySelector(selector)
    setMounted(true)
  }, [selector])

  return mounted && ref.current ? createPortal(children, ref.current) : null
}

function MobileModal({
  id,
  heading,
  children,
  isModalVisible,
  handleModalClose,
  disablePlaceholder,
  allowScroll,
  placeholder,
}: MobileModalProps): JSX.Element {
  const styles = useMultiStyleConfig('MobileModal', {})

  const [isTabletOrSmaller] = useMediaQuery(`(max-width: ${deviceSizes.medium})`)

  useEffect(() => {
    if (isModalVisible && !allowScroll) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }, [isModalVisible, allowScroll])

  const handleKeyUp = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleModalClose()
      }
    },
    [handleModalClose]
  )

  useEffect(() => {
    if (isModalVisible) {
      window.addEventListener('keyup', handleKeyUp)

      return () => {
        window.removeEventListener('keyup', handleKeyUp)
      }
    }

    return undefined
  }, [isModalVisible, handleKeyUp])

  if (!isTabletOrSmaller || !isModalVisible) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{placeholder || children}</>
  }

  return (
    <>
      {!disablePlaceholder && (placeholder || children)}
      <ClientOnlyPortal selector="#modal">
        <chakra.section
          __css={styles.mobileModal}
          id={id}
          data-testid="mobile-modal"
          className="site-MobileModal"
          role="dialog"
        >
          <Button __css={styles.closeButton} aria-label="Close" onClick={handleModalClose}>
            <Icon as={convertIconSetToChakraSVG('cross')} boxSize={IconSizes.sm} />
          </Button>
          {heading && <chakra.h4 __css={styles.heading}>{heading}</chakra.h4>}
          {children}
        </chakra.section>
      </ClientOnlyPortal>
    </>
  )
}

export default MobileModal
