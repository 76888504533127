import { helpers } from './base'

export const ContinueButton = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      backgroundColor: 'brand.primaryTeal',
    },
    buttonIcon: {
      textColor: 'brand.primaryShamrockGreen',
    },
  },
})
