import { z } from 'zod'

export const RatingBreakdownSchema = z.object({
  cleanliness: z.number().optional(),
  service: z.number().optional(),
  comfort: z.number().optional(),
  condition: z.number().optional(),
  location: z.number().optional(),
  neighborhood: z.number().optional(),
  quality: z.number().optional(),
  value: z.number().optional(),
  amenities: z.number().optional(),
})

export const RatingSchema = z.object({
  source: z.string().optional(),
  overall: z.number(),
  count: z.number().optional(),
  type: z.string().optional(),
  ratingBreakdown: RatingBreakdownSchema.optional(),
})

export type Rating = z.infer<typeof RatingSchema>
