import { helpers } from './base'

export const ExperienceDetails = helpers.defineMultiStyleConfig({
  baseStyle: {
    firstPart: {
      gap: {
        base: 5,
        md: 8,
      },
      marginTop: 6,
    },
    experienceOverviewTitle: {
      fontSize: '4xl-5',
      fontWeight: 'medium',
    },
    experienceOverviewDescription: {
      fontSize: 'lg',
    },
    experienceInformationTitle: {
      fontSize: '2xl-5',
      fontWeight: 'semibold',
    },
    experienceInformationContainer: {
      '> ul': {
        mt: 6,
      },
    },
  },
})
