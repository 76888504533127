import { ComponentStyleConfig } from '@chakra-ui/react'

const brandWhite = 'brand.white'
const midnightBlue = 'brand.primary.midnightBlue'
const highlightBlue = 'brand.highlight.blue'

export const StepperCircle: ComponentStyleConfig = {
  baseStyle: {
    borderColor: midnightBlue,
    _focusVisible: {
      background: highlightBlue,
      color: brandWhite,
      outline: 'unset',
    },
  },
  variants: {
    default: { bg: brandWhite, color: midnightBlue },
    completed: { bg: midnightBlue, color: brandWhite },
    selected: {
      bg: midnightBlue,
      color: brandWhite,
      '>p': {
        fontWeight: 'semibold',
      },
    },
  },
}
