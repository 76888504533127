import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const rangeDatePickerHelpers = createMultiStyleConfigHelpers(['equallySpacedDiv'])

export const RangeDatePicker = rangeDatePickerHelpers.defineMultiStyleConfig({
  baseStyle: {
    equallySpacedDiv: {
      position: 'relative',
      display: 'flex',
      whiteSpace: 'nowrap',
      gap: {
        base: '1rem',
        md: '1.5rem',
      },
      textWrap: 'wrap',
      '> *': {
        flex: 1,
      },
    },
  },
})
