import React, { MouseEventHandler } from 'react'
import { usePromotionContext } from '~/context/promotion'
import { ContentBadge, Modal } from '@reward-platform/lift/components'
import useIntl from '~/hooks/useIntl/useIntl'
import { convertIconSetToChakraSVG } from '~/utils/Icon.utils'
import { FlexProps, Icon, ThemingProps, chakra, useDisclosure } from '@chakra-ui/react'
import { IconName } from '@reward-platform/lift/components/Icon/Icon.types'
import { IconSizes } from '@reward-platform/lift/theme/iconSize'
import { PromotionItemProps } from './Promotion.types'
import { DynamicVariableRichText } from '../shared/DynamicVariableRichText'

export interface PromotionBadgeProps extends FlexProps {
  variant?: ThemingProps['variant']
  hasModal?: boolean
  prefix?: string
  isIncentive?: boolean
  alwaysShow?: boolean
}

const PromotionBadge = ({
  product,
  item,
  variant = '',
  hasModal = false,
  prefix,
  isIncentive = false,
  alwaysShow = false,
  ...rest
}: PromotionItemProps & PromotionBadgeProps) => {
  const { isEnabled, getProductPromotion } = usePromotionContext()
  const { content, variableMap, isItemValidForPromotion } = getProductPromotion(product) ?? {}
  const { badgeText, termsAndConditions } = content || {}
  const isItemValid = isItemValidForPromotion?.(item, { isIncentive, isAlwaysTrue: alwaysShow })
  const isBadgeEnabled = isEnabled(product) && badgeText && isItemValid

  const { isOpen, onToggle } = useDisclosure()

  const intl = useIntl()
  const t = {
    promoModalTermsTitle: intl.formatMessage({
      id: 'promo-modal-terms-title',
      defaultMessage: 'Bonus Avios Terms and conditions',
    }),
  }

  if (!isBadgeEnabled) {
    return null
  }

  const hasTermsModal = hasModal && termsAndConditions

  const clickTrigger = (): MouseEventHandler<HTMLDivElement> | undefined => {
    onToggle()
    return undefined
  }

  return (
    <>
      {prefix ? (
        <chakra.span fontSize={{ base: 'xs', lg: 'sm' }} fontStyle="normal" fontWeight="normal">
          {prefix}
        </chakra.span>
      ) : null}
      <ContentBadge
        variant={variant}
        onClickEvent={hasTermsModal ? clickTrigger : undefined}
        cursor={hasTermsModal ? 'pointer' : 'text'}
        {...rest}
      >
        <Icon as={convertIconSetToChakraSVG('gift' as IconName)} boxSize={IconSizes.sm} />
        <chakra.span>{badgeText}</chakra.span>
        {hasTermsModal ? (
          <>
            <Icon as={convertIconSetToChakraSVG('help')} boxSize={IconSizes.xxs} />
            <Modal title={t.promoModalTermsTitle} isOpen={isOpen} onClose={onToggle}>
              <DynamicVariableRichText
                document={termsAndConditions.content}
                variableMap={variableMap}
              />
            </Modal>
          </>
        ) : null}
      </ContentBadge>
    </>
  )
}

export default PromotionBadge
