import { IncomingMessage } from 'node:http'
import edgeLogger from '@reward-platform/logger/edge'
import { createServerLoggerMeta } from '@reward-platform/logger/utils'
import { GenericClientError } from './GenericClientError'
import { ERROR_CODES } from './clientErrors'
import { isClient } from '../envChecks'

const BUSINESS_MESSAGE = 'Fatal error has occurred, please refresh the application.'

export class PlatformError extends GenericClientError {
  constructor(message?: string) {
    const error = new Error(message)
    error.name = 'PlatformError'
    super(ERROR_CODES.PLATFORM, error)

    this.developerMessage = message ?? BUSINESS_MESSAGE
    this.businessMessage = BUSINESS_MESSAGE
    this.fatal = true
  }

  public logRequestToDatadog(req?: IncomingMessage) {
    const loggerRequestContext = createServerLoggerMeta(req)
    const loggerContext = { ...loggerRequestContext, error: this.getErrorContext() }

    if (!isClient()) {
      edgeLogger.error(this.developerMessage, loggerContext)
    } else {
      this.logToDatadog()
    }
  }
}
