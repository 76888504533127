import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const fieldGroupHelper = createMultiStyleConfigHelpers(['wrapper', 'fields'])

export const FieldGroup = fieldGroupHelper.defineMultiStyleConfig({
  baseStyle: fieldGroupHelper.definePartsStyle({
    wrapper: {
      display: 'grid',
      gridTemplateColumns: { base: '1fr', sm: '18ch 1fr' },
      '& + &': {
        marginTop: '2.5rem',
      },
      legend: {
        fontSize: 'sm',
        fontWeight: '500',
        paddingBottom: '1rem',
        paddingRight: { base: '0', sm: '1rem' },
      },
    },
    fields: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridGap: '1rem',
      '> *': {
        gridRow: 'auto',
        gridColumn: 'span 2',
      },
    },
  }),
})
