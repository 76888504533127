/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const ContentBadge = helpers.defineMultiStyleConfig({
  baseStyle: {
    wrapper: {
      background: 'bg.success.subtle.default',
      borderColor: 'bg.success.subtle.default',
      borderRadius: 'xs',
      px: 'xs',
      ' > span': {
        fontSize: 'var(--alto-sem-text-label-xs-font-size)',
        fontWeight: 'medium',
        color: 'fg.success.onSubtle',
      },
      ' > svg': {
        color: 'fg.success.onSubtle',
      },
    },
  },
})
