import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { typography } from '@reward-platform/lift/theme/aer_lingus/typography'

export const helpers = createMultiStyleConfigHelpers(['labelContainer'])

export const Label = helpers.defineMultiStyleConfig({
  baseStyle: {
    labelContainer: {
      size: typography.sizes.xsmall,
      fontWeight: typography.weights.medium,
      padding: '0 8px',
      borderRadius: '4px',
      display: 'inline-block',
    },
  },
})
