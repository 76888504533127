import { helpers } from './base'

const grayColor = 'brand.coolgrey8'
const darkCityGreen = 'brand.darkCityGreen'
const primaryTeal = 'brand.primaryTeal'
const boxShadow = '0px 4px 4px 0 rgba(0, 0, 0, 0.4)'
const greyDisabled = 'rgba(var(--chakra-colors-rgb-alCoolGrey8), 0.7)'
const checkedDisabled = {
  bg: 'rgba(var(--chakra-colors-rgb-alDarkCityGreen), 0.7)',
  color: 'rgba(var(--chakra-colors-rgb-alWhite), 0.7)',
  borderColor: 'rgba(var(--chakra-colors-rgb-alDarkCityGreen), 0.7)',
}

export const RadioButton = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    control: {
      borderColor: grayColor,
      svg: {
        w: `${3} !important`,
      },
      _checked: {
        bg: darkCityGreen,
        borderColor: darkCityGreen,
        _disabled: {
          ...checkedDisabled,
        },
      },
      _pressed: {
        borderColor: 'brand.coolgrey10',
      },
      _disabled: {
        borderColor: greyDisabled,
        bg: 'brand.white',
      },
      _focus: {
        borderColor: 'brand.black',
      },
    },
    icon: {
      color: 'brand.white',
      position: 'absolute',
      margin: '0 4px',
    },
    container: {
      _checked: {
        _hover: {
          '> div': {
            bg: primaryTeal,
            borderColor: primaryTeal,
            boxShadow,
            _disabled: {
              boxShadow: 'none',
              ...checkedDisabled,
            },
          },
        },
        _focusWithin: {
          outlineColor: {
            base: 'none',
            md: primaryTeal,
          },
          '> div': {
            bg: primaryTeal,
            boxShadow,
          },
        },
      },
      _focusWithin: {
        outline: {
          base: 0,
          md: 'var(--chakra-space-px) solid var(--chakra-colors-brand-coolgrey10)',
        },
        outlineOffset: { base: 0, md: 5 },
        borderRadius: 1,
      },
      _hover: {
        '> div': {
          borderColor: 'brand.coolgrey10',
        },
      },
      _disabled: {
        color: greyDisabled,
        _hover: {
          '> div': {
            borderColor: greyDisabled,
          },
        },
      },
    },
  }),
})
