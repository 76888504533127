import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers(['container', 'icon', 'label'])

export const StatusBadge = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      textTransform: 'capitalize',
      fontSize: 'xs',
      color: 'black',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 'light',
      textAlign: 'center',
      px: { base: 2, lg: 8 },
      py: 1,
      width: 28,
    },
    label: {
      lineHeight: 'normal',
    },
    icon: {
      mr: 2,
      w: 4,
      h: 4,
    },
  },
  variants: {
    READY_TO_PROCEED: {},
    REMOVED: {
      icon: {
        svg: {
          w: 3,
        },
      },
    },
    DELIVERED: {},
    SUSPENDED: {},
    UNABLE_TO_DELIVER: {},
    IN_PROGRESS: {},
    UNKNOWN: {},
  },
  defaultProps: {
    variant: 'READY_TO_PROCEED',
  },
  sizes: {
    sm: {
      container: {
        width: 'auto',
      },
    },
  },
})
