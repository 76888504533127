/* eslint-disable sonarjs/no-duplicate-string */
import { formHelper } from './base'

const focusedInputStyles = {
  borderColor: 'border.accent',
  borderWidth: 'var(--alto-sem-border-width-md)',
  borderRadius: 'var(--alto-text-field-radius)',
  boxShadow: 'none',
  _hover: {
    borderColor: 'border.accent',
  },
}

const errorStyle = {
  color: 'fg.critical.primary',
}

export const Form = formHelper.defineMultiStyleConfig({
  variants: {
    floating: {
      container: {
        label: {
          zIndex: 1,
          color: 'fg.primary',
        },
        'input, select, textarea, .flex-microform, button': {
          bg: 'bg.layer2.default',
          borderWidth: 'var(--alto-sem-border-width-sm)',
          borderStyle: 'solid',
          borderColor: 'border.primary.default',
          borderRadius: 'var(--alto-text-field-radius)',
          color: 'fg.primary',
          fontSize: 'var(--alto-sem-text-body-md-font-size)',
          lineHeight: 'var(--alto-sem-text-body-md-line-height)',
          letterSpacing: 'var(--alto-sem-text-body-md-letter-spacing)',
          '~label': {
            color: 'fg.primary',
          },
          '~ div svg': {
            color: 'fg.primary',
          },
          _hover: {
            borderColor: 'border.primary.hover',
          },
          '&.flex-microform-focused': {
            ...focusedInputStyles,
          },
          _focus: {
            ...focusedInputStyles,
          },
          _active: {
            borderColor: 'border.primary.active',
          },
          _invalid: {
            '~label': {
              ...errorStyle,
            },
            borderWidth: 'var(--alto-sem-border-width-md)',
            borderColor: 'border.critical',
            boxShadow: 'none',
            '~ div svg': {
              ...errorStyle,
            },
            _hover: {
              borderColor: 'border.critical',
            },
          },
          _disabled: {
            opacity: 1,
            borderRadius: 'var(--alto-text-field-radius)',
            bg: 'bg.disabled.subtle',
            borderColor: 'border.disabled.subtle',
            color: 'fg.disabled.onSubtle',
            borderWidth: 'var(--alto-sem-border-width-sm)',
            '~label': {
              color: 'fg.disabled.onSubtle',
            },
            '~ div svg': {
              color: 'border.primary.default',
            },
          },
        },
        'label[data-invalid], label[data-invalid] span, .flex-microform-invalid ~ label, .flex-microform-field-invalid ~ label, .flex-microform-field-invalid ~ label span':
          {
            ...errorStyle,
          },
        '.flex-microform, input, input[type="button"], select, textarea, button': {
          height: '63px',
        },
      },
      inputContainer: {
        background: 'transparent',
        '.fake-focus': {
          ...focusedInputStyles,
        },
      },
      icon: {
        paddingTop: 'xs',
      },
      errorIcon: {
        svg: {
          ...errorStyle,
          display: 'block',
        },
      },
    },
  },
})
