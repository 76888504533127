import {
  Basket,
  BasketItem,
  CarHireBasketItem,
  ExperienceBasketItem,
  FlightBasketItem,
  HotelBasketItem,
  isCarBasketItem,
  isExperienceBasketItem,
  isFlightBasketItem,
  isHotelBasketItem,
  OnlyCashPaymentOption,
  PaymentOption,
} from '@reward-platform/order-schemas/basket'
import { SelectedPaymentOptions } from '@reward-platform/order-schemas/form-data'
import { calculatePriceBreakdown } from '@reward-platform/order-schemas/pricebreakdown'
import { getPaymentOptionAndConvert } from '@reward-platform/order-schemas/util'
import { getCheckoutAnalyticsChannel } from '~/hooks/useCheckoutAnalyticsChannel/useCheckoutAnalyticsChannel'
import { AgeGroupSchema } from '@reward-platform/order-schemas/common'
import { getIsRefundable } from '@iagl/reward-platform-cancellation-policies'
import { BasketAnalyticsItemGA4 } from './googleTagManager'
import { getPaymentOptionWithHighestCashAmount } from './getPaymentOptionWithHighestCashAmount'

type Price = {
  priceAvios?: number
  priceCash?: {
    amount: number
    currency: string
  }
  collectedAvios?: number
}

export type AnalyticsPaymentOption = {
  cashValue?: {
    currency: string
    amount: number
  }
  cashTaxValue?: {
    currency: string
    amount: number
  }
  aviosValue?: number
  aviosCollectValue?: number
}

export const getPaymentOption = (
  basketItem: BasketItem,
  selectedPaymentOptions: SelectedPaymentOptions | undefined
) => {
  const paymentOptionId = selectedPaymentOptions?.[basketItem.type]?.paymentOptionId

  if (!paymentOptionId) {
    return undefined
  }

  return getPaymentOptionAndConvert<AnalyticsPaymentOption>({
    convertOnlyCashPaymentOption: ({ aviosCollectValue, cashTotalValue, cashTaxValue }) => ({
      aviosCollectValue,
      cashValue: cashTotalValue,
      cashTaxValue,
      aviosValue: undefined,
    }),
    convertPaymentOption: ({ cashValue, aviosValue, cashTaxValue }) => ({
      cashValue,
      aviosValue,
      cashTaxValue,
      aviosCollectValue: undefined,
    }),
    paymentOptionId,
    paymentOptions: basketItem.paymentOptions,
  })
}

export const getBasketTotalPrice = (
  { items, currency }: Basket,
  selectedPaymentOptions: SelectedPaymentOptions
): Price | undefined => {
  const priceBreakdown = selectedPaymentOptions
    ? calculatePriceBreakdown(items, selectedPaymentOptions)
    : undefined

  if (!priceBreakdown) {
    return undefined
  }

  return {
    collectedAvios: priceBreakdown?.total.aviosCollect,
    priceAvios: priceBreakdown?.total.aviosSpent,
    priceCash: {
      amount: priceBreakdown?.total.cashTotal.toNumber(),
      currency,
    },
  }
}

export const getIntPrefix = (value: string | undefined) => {
  const prefix = (value?.match(/^\d+/) ?? [])[0]

  return prefix?.length ? Number(prefix) : undefined
}

export const convertNameToId = (name?: string) => {
  return name?.replace(/[^0-9a-z]/gi, '-').toLowerCase() ?? ''
}

type AnyPaymentOption = PaymentOption | OnlyCashPaymentOption

function isOnlyCashPaymentOption(option: AnyPaymentOption): option is OnlyCashPaymentOption {
  return 'cashTotalValue' in option && 'aviosCollectValue' in option
}

function isPaymentOption(option: AnyPaymentOption): option is PaymentOption {
  return 'cashValue' in option && 'aviosValue' in option
}

function getPriceFromAnyPaymentOption(option: AnyPaymentOption | undefined): number {
  if (!option) {
    return 0
  }

  if (isOnlyCashPaymentOption(option)) {
    return option.cashBaseValue.amount
  }

  if (isPaymentOption(option)) {
    return option.cashBaseValue?.amount ?? 0
  }

  return 0
}

const getHotelItemAnalytics = (
  basketItem: HotelBasketItem,
  paymentOption: AnalyticsPaymentOption | undefined,
  paymentOptionWithHighestCashAmount: AnyPaymentOption | undefined
) => {
  const shared = {
    cash_value: paymentOption?.cashValue?.amount ?? 0,
    avios_value: paymentOption?.aviosValue ?? 0,
    collected_avios: paymentOption?.aviosCollectValue ?? 0,
    // We don't receive brand information for hotels in basket response
    brand: undefined,
    bed_size: basketItem.room.beds,
    room_capacity: basketItem.room.maxOccupants,
    price: getPriceFromAnyPaymentOption(paymentOptionWithHighestCashAmount),
  }

  const isRefundable = getIsRefundable(basketItem.packageRules)

  const analyticsObject = {
    item_id: basketItem.vendorName?.replace(/[^0-9a-z]/gi, '-').toLowerCase() ?? '',
    item_name: basketItem.name,
    item_variant: basketItem.room.name,
    item_brand: basketItem.vendorName,
    item_category: 'Hotel',
    quantity: 1,
    refundable: isRefundable ? 'Refundable' : 'Non-Refundable',
    ...shared,
  }

  if ('amenities' in basketItem) {
    const getFormattedAmenities = () => {
      const { amenities } = basketItem
      const formattedAmenities: { [key: string]: string } = {}

      amenities?.forEach((amenity: string, i) => {
        formattedAmenities[`amenities.${i}`] = amenity
      })

      return formattedAmenities
    }

    return { ...analyticsObject, ...getFormattedAmenities() }
  }

  return analyticsObject
}

const getCarHireItemAnalytics = (
  basketItem: CarHireBasketItem,
  paymentOption: AnalyticsPaymentOption | undefined,
  paymentOptionWithHighestCashAmount: AnyPaymentOption | undefined
) => {
  const shared = {
    cash_value: paymentOption?.cashValue?.amount ?? 0,
    avios_value: paymentOption?.aviosValue ?? 0,
    collected_avios: paymentOption?.aviosCollectValue ?? 0,
    seats: getIntPrefix(basketItem.seats),
    transmission: basketItem.transmission,
    mileage_allowance: basketItem.unlimitedMileage ? 'unlimited' : 'limited',
    door_count: getIntPrefix(basketItem.doors),
    air_con: basketItem.airCondition,
    luggage_capacity: basketItem.bags,
    price: getPriceFromAnyPaymentOption(paymentOptionWithHighestCashAmount),
  }
  return {
    item_id: convertNameToId(basketItem.vehicle.name),
    item_name: basketItem.vehicle.name,
    item_variant: basketItem.vehicle.vehicleClassName,
    item_brand: basketItem.vendorName,
    item_category: 'Car Hire',
    quantity: 1,
    ...shared,
  }
}

const getFlightItemAnalytics = (
  basketItem: FlightBasketItem,
  paymentOption: AnalyticsPaymentOption | undefined,
  paymentOptionWithHighestCashAmount: AnyPaymentOption | undefined
) => {
  const { outboundFlightDetails, inboundFlightDetails, travelers } = basketItem
  const flightDetails = [outboundFlightDetails, inboundFlightDetails]

  const variant = flightDetails
    .map((details) => details?.flightClass)
    .filter(Boolean)
    .join('-')
  const itemName = flightDetails
    .map((details) => details?.flightSegmentId)
    .filter(Boolean)
    .join('-')

  const flightRoute = flightDetails
    .map((details) => details && `${details.departureIataCode}-${details.arrivalIataCode}`)
    .filter(Boolean)
    .join(', ')

  const adultsCount = travelers.filter(
    ({ ageGroup, age }) => ageGroup === AgeGroupSchema.Enum.ADULT && (age == null || age >= 18)
  ).length

  const childrenCount = travelers.length - adultsCount

  const shared = {
    cash_value: paymentOption?.cashValue?.amount ?? 0,
    avios_value: paymentOption?.aviosValue ?? 0,
    collected_avios: paymentOption?.aviosCollectValue ?? 0,
    price: getPriceFromAnyPaymentOption(paymentOptionWithHighestCashAmount),
  }
  return {
    item_id: basketItem.id,
    item_name: itemName,
    item_variant: variant,
    item_brand: basketItem.vendorName,
    item_category: 'Flight',
    quantity: 1,
    flight_route: flightRoute,
    filter_flight_type_outbound: outboundFlightDetails.flightClass,
    filter_flight_type_inbound: inboundFlightDetails?.flightClass,
    adults: adultsCount,
    children: childrenCount,
    ...shared,
  }
}

const getExperienceItemAnalytics = (
  basketItem: ExperienceBasketItem,
  paymentOption: AnalyticsPaymentOption | undefined,
  paymentOptionWithHighestCashAmount: AnyPaymentOption | undefined
) => {
  const formatExperienceDuration = () => {
    if (basketItem.experienceType === 'MULTI_DAY_TOUR' && basketItem.days) {
      return `${basketItem.days} days`
    }
    if (basketItem.duration?.duration) {
      return `${basketItem.duration.duration} ${basketItem.duration.uom ?? ''}`
    }
    return basketItem.duration?.freeText ?? ''
  }

  const getTravellersCount = () => {
    return basketItem.travelers.reduce((prev, crt) => prev + crt.quantity, 0)
  }

  return {
    item_id: convertNameToId(basketItem.name),
    item_name: basketItem.name,
    item_brand: basketItem.vendorName,
    item_category: 'Experience',
    item_variant: basketItem.experienceType
      ?.replace(/_/g, ' ')
      .replace(/\b\w+\b/g, (match) => match[0].toUpperCase() + match.slice(1).toLowerCase()),
    price: getPriceFromAnyPaymentOption(paymentOptionWithHighestCashAmount),
    quantity: 1,
    avios_value: paymentOption?.aviosValue ?? 0,
    cash_value: paymentOption?.cashValue?.amount ?? 0,
    collected_avios: paymentOption?.aviosCollectValue ?? 0,
    duration: formatExperienceDuration(),
    traveller_count: getTravellersCount(),
  }
}

export const getBasketItemAnalytics = (
  basketItem: BasketItem,
  paymentOption: AnalyticsPaymentOption | undefined,
  paymentOptionWithHighestCashAmount: AnyPaymentOption | undefined
): BasketAnalyticsItemGA4 | undefined => {
  if (isHotelBasketItem(basketItem)) {
    return getHotelItemAnalytics(basketItem, paymentOption, paymentOptionWithHighestCashAmount)
  }

  if (isCarBasketItem(basketItem)) {
    return getCarHireItemAnalytics(basketItem, paymentOption, paymentOptionWithHighestCashAmount)
  }

  if (isFlightBasketItem(basketItem)) {
    return getFlightItemAnalytics(basketItem, paymentOption, paymentOptionWithHighestCashAmount)
  }

  if (isExperienceBasketItem(basketItem)) {
    return getExperienceItemAnalytics(basketItem, paymentOption, paymentOptionWithHighestCashAmount)
  }

  return undefined
}

export const getAllBasketItemsAnalytics = (
  basket: Basket,
  paymentOptions: SelectedPaymentOptions | undefined
) => {
  const allBasketItems = [
    ...basket.items.HOTEL,
    ...basket.items.CAR_HIRE,
    ...basket.items.EXPERIENCE,
    ...basket.items.FLIGHT,
  ]

  const priceBreakdown = paymentOptions && calculatePriceBreakdown(basket.items, paymentOptions)
  const channel = getCheckoutAnalyticsChannel(basket.items)

  return {
    channel,
    priceBreakdown,
    items: allBasketItems.flatMap((item, index) => {
      const paymentOption = getPaymentOption(item, paymentOptions)
      const paymentOptionWithHighestCashAmount = getPaymentOptionWithHighestCashAmount(item)
      const analytics = getBasketItemAnalytics(
        item,
        paymentOption,
        paymentOptionWithHighestCashAmount
      )
      if (!analytics) {
        return []
      }

      return [{ ...analytics, index }]
    }),
  }
}
