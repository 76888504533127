import React from 'react'
import {
  Modal as ChakraModal,
  Heading,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SystemStyleObject,
} from '@chakra-ui/react'
import type * as CSS from 'csstype'

export type ModalTheme = {
  borderRadius: string
  backgroundColor: string
  iconColor: string
}

export type ModalProps = {
  isOpen: boolean
  closeable?: boolean
  preventScroll?: boolean
  onClose: () => void
  title?: string
  titleTextAlign?: string
  shouldFocusAfterRender?: boolean
  size?: string
  sx?: SystemStyleObject
  isCentered?: boolean
}

function Modal({
  children,
  isOpen = false,
  closeable = true,
  preventScroll = false,
  onClose,
  title,
  titleTextAlign,
  shouldFocusAfterRender,
  size,
  sx,
  ...props
}: React.PropsWithChildren<ModalProps>) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = children && (children as any).ref
  return (
    <ChakraModal
      blockScrollOnMount={preventScroll}
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={shouldFocusAfterRender && ref}
      size={size}
      {...props}
    >
      <ModalOverlay />
      <ModalContent data-testid="modal" role="dialog">
        {closeable ? (
          <ModalCloseButton aria-label="Close modal" data-testid="modal_close_button" />
        ) : null}
        <ModalHeader>
          {title && (
            <Heading as="h2" textAlign={(titleTextAlign as CSS.Property.TextAlign) || 'center'}>
              {title}
            </Heading>
          )}
        </ModalHeader>
        <ModalBody sx={sx} data-testid="modal_content">
          {children}
        </ModalBody>
      </ModalContent>
    </ChakraModal>
  )
}

export default Modal
