import { FC, ComponentType } from 'react'
import { Icon, IconProps } from '@chakra-ui/react'
import { IconSet, IconName } from './Icon.types'
import { iconSet } from './index'

interface ExtendedIconProps extends IconProps {
  paths: string[]
  fills: string[]
}
// NOTE: these functions are duplicated in apps/client/utils/Icon.utils.tsx
export const ExtendedIcon: FC<ExtendedIconProps> = ({ paths, fills, ...props }) => {
  return (
    <Icon {...props} role="img">
      {paths.map((path, index) => (
        <path key={path} d={path} fill={fills[index] ?? 'currentColor'} />
      ))}
    </Icon>
  )
}
export const convertIconSetToChakraSVG = (
  iconName: IconName,
  cascadeFillColor = false
): ComponentType<IconProps> => {
  const selectedIcon = iconSet.icons.find((icon) =>
    icon.properties.name.split(', ').includes(iconName)
  )

  const { height } = iconSet
  const width = selectedIcon?.icon.width || height

  const paths = selectedIcon?.icon.paths || []
  const fills = (
    selectedIcon?.attrs.map((attr) => ('fill' in attr ? attr.fill : undefined)) || []
  ).filter((fill): fill is string => !!fill)

  return (props: IconProps) => (
    <ExtendedIcon
      paths={paths}
      fills={cascadeFillColor ? [] : fills}
      viewBox={`0 0 ${width} ${height}`}
      aria-hidden
      {...props}
    />
  )
}

export declare const iconList: (iconSet: IconSet) => string[]

export function iconToSVG(iconName: IconName, fill = 'currentColor') {
  const selectedIcon = iconSet.icons.find((icon) =>
    icon.properties.name.split(', ').includes(iconName)
  )
  const paths = selectedIcon?.icon.paths.reduce((svg, path) => {
    return `${svg}<path d="${path}" fill="${fill}" />`
  }, '')
  const { height } = iconSet
  const width = selectedIcon?.icon.width || height
  return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}">${paths}</svg>`
}

export function iconToDataImage(iconName: IconName, fill = 'currentColor') {
  return `data:image/svg+xml;utf8,${iconToSVG(iconName, fill)}`
}
