import { helpers } from './base'

export const StickyBanner = helpers.defineMultiStyleConfig({
  baseStyle: {
    wrapper: { backgroundColor: '#FAFAFA' },
    banner: {},
    icon: { color: 'brand.highlight.blue' },
    message: {
      color: 'brand.primary.midnightBlue',
    },
    crossIcon: {},
  },
})
