import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helper = createMultiStyleConfigHelpers(['chipWrapper'])

export const Chip = helper.defineMultiStyleConfig({
  baseStyle: {
    chipWrapper: {
      borderRadius: 'base',
      p: 'var(--chakra-sizes-1) var(--chakra-sizes-2)',
      w: 'fit-content',
      h: 'fit-content',
      fontSize: { base: '0.625rem', md: 'xs' },
    },
  },
})
