import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const iconValuePairHelpers = createMultiStyleConfigHelpers(['wrapper', 'value'])

export const IconValuePair = iconValuePairHelpers.defineMultiStyleConfig({
  baseStyle: iconValuePairHelpers.definePartsStyle({
    wrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      columnGap: 2,
      _invalid: {
        color: 'error.primary',
        span: {
          fontWeight: 'semibold',
        },
      },
    },
  }),
  variants: {
    sm: {
      wrapper: {
        svg: {
          width: 4,
          height: 4,
        },
      },
      value: {
        fontSize: 'sm',
        lineHeight: 1,
        verticalAlign: 'middle',
      },
    },
  },
})
