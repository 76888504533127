import { autoCompleteHelpers, autoCompleteLocationOptionHelpers } from './base'

export const AutoCompleteLocationOption = autoCompleteLocationOptionHelpers.defineMultiStyleConfig({
  baseStyle: {
    locationOptionWrapper: {
      borderColor: 'var(--alto-sem-color-border-secondary)',
      _hover: {
        backgroundColor: 'bg.accent.subtle.default',
      },
      '&[data-highlighted=true]': {
        backgroundColor: 'bg.accent.subtle.default',
      },
    },
    autoCompleteListTitle: {
      color: 'fg.accent.onSubtle',
      fontWeight: 'semibold',
    },
  },
})

export const AutoComplete = autoCompleteHelpers.defineMultiStyleConfig({
  baseStyle: {
    popup: {
      borderRadius: 'var(--alto-text-field-radius)',
      boxShadow: 'md',
    },
  },
})
