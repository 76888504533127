import { ERROR_CODES } from './ErrorCodes'
import { GenericException, GenericExceptionError } from './GenericException'

export class UnauthorizedException extends GenericException {
  constructor(error?: GenericExceptionError) {
    super({
      ...ERROR_CODES.UNAUTHORIZED,
      ...(error || {}),
    })
  }
}
