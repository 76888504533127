import { helpers } from './base'

export const Stepper = helpers.defineMultiStyleConfig({
  baseStyle: {
    backgroundLine: {
      backgroundImage: `repeating-linear-gradient(90deg, transparent 0 4px, var(--alto-sem-color-bg-inverse-subtle-active) 4px 12px)`,
    },
    completedLine: {
      background: 'bg.accent.vibrant.default',
    },
    stepContainer: {
      '&[data-status="default"] > p ': {
        fontWeight: 'normal',
        color: 'fg.accent.secondary',
      },
      '&[data-status="selected"] > p ': {
        fontWeight: 'normal',
        color: 'fg.accent.primary',
      },
    },
    stepText: {
      color: 'fg.accent.secondary',
      fontWeight: 'normal',
    },
  },
})
