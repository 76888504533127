import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers(['ticketOptions', 'ticketOptionsResults'])

export const TicketOptions = helpers.defineMultiStyleConfig({
  baseStyle: {
    ticketOptions: {
      zIndex: 0,
      display: 'flex',
      flexDirection: 'column',
    },
    ticketOptionsResults: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
})
