import { helpers } from './base'

export const QuantityPicker = helpers.defineMultiStyleConfig({
  baseStyle: {
    quantityPickerContainer: {
      display: 'flex',
      alignItems: 'center',
      button: {
        border: 'none',
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        _active: {
          background: 'none',
        },
        _focus: {
          background: 'none',
        },
      },
    },
    quantityPickerButton: {
      color: 'fg.accent.secondary',
      borderRadius: 'xl',
    },
  },
  variants: {
    success: {
      quantityPickerButton: {
        color: 'fg.accent.secondary',
        bg: 'transparent',
        _disabled: {
          bg: 'bg.inverse.subtle.default',
          color: 'fg.disabled.primary',
        },
      },
    },
    error: {
      quantityPickerButton: {
        bg: 'bg.critical.subtle.default',
        color: 'bg.critical.vibrant.default',
      },
    },
  },
  defaultProps: {
    variant: 'success',
  },
})
