import { resultsPageTemplate } from './base'

export const ResultsPageTemplate = resultsPageTemplate.defineMultiStyleConfig({
  baseStyle: resultsPageTemplate.definePartsStyle({
    searchTitle: {
      fontWeight: 'bold',
      color: 'brand.grey6',
    },
  }),
})
