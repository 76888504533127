import { helpers } from './base'

export const CardBanner = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    container: {
      borderRadius: 'sm',
      color: 'brand.coolgrey2',
    },
    overlayContainer: {
      borderRadius: 'sm',
    },
  }),
})
