import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const overlayTipHelper = createMultiStyleConfigHelpers([
  'infoButton',
  'wrapper',
  'containerBg',
  'containerBorder',
  'containerMaxWidth',
  'topPos',
])

export const OverlayTip = overlayTipHelper.defineMultiStyleConfig({
  baseStyle: overlayTipHelper.definePartsStyle({
    containerBg: {
      bg: 'white',
    },
    containerBorder: {
      border: '1px solid',
    },
    containerMaxWidth: {
      maxW: '100%',
    },
    wrapper: {
      borderRadius: 16,
      display: 'flex',
      gap: 4,
      px: {
        base: 4,
        md: 8,
      },
      py: {
        base: 4,
        md: 6,
      },
      '.chakra-popover__body': {
        mr: { base: 3, md: 6 },
      },
      '> button': {
        color: 'brand.black',
        cursor: 'pointer',
        border: 'none',
        pt: { base: 4, md: 8 },
        pr: { base: 4, md: 6 },
        _hover: {
          bg: 'none',
        },
      },
    },
  }),
})
