/* eslint-disable sonarjs/no-duplicate-string */
import { itemCardImageWithLogoHelper } from './base'

export const ItemCardImageWithLogo = itemCardImageWithLogoHelper.defineMultiStyleConfig({
  baseStyle: itemCardImageWithLogoHelper.definePartsStyle({}),
  sizes: {
    sm: {
      imageWrapper: {
        img: {
          _first: {
            borderRadius: 'sm',
          },
        },
      },
    },
  },
})
