import { helpers } from './base'

const cashLabel = {
  color: 'fg.primary',
  fontWeight: 'medium',
}

export const PriceBreakdownContainer = helpers.defineMultiStyleConfig({
  baseStyle: {
    cash: {
      ...cashLabel,
    },
    cashDue: {
      ...cashLabel,
      '> dl > dd': {
        fontSize: 'var(--alto-sem-text-heading-md-font-size)',
      },
    },
  },
})
