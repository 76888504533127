/* eslint-disable sonarjs/no-duplicate-string */
import { AlertHelper } from './base'

const bg = 'rgba(var(--chakra-colors-rgb-alPrimaryTeal), 0.05)'
const shamrockGreen10 = 'var(--chakra-colors-brand-shamrockGreen10)'
const coolgrey1 = 'var(--chakra-colors-brand-coolgrey1)'
const coolgrey10 = 'var(--chakra-colors-brand-coolgrey10)'

export const Alert = AlertHelper.defineMultiStyleConfig({
  baseStyle: AlertHelper.definePartsStyle({
    container: {
      '&[data-status="error"]': {
        borderColor: 'error.primary',
        bgColor: 'brand.red5',
      },
      '&[data-status="info"]': {
        borderColor: 'brand.primaryTeal',
        bgColor: bg,
      },
      '&[data-status="success"]': {
        borderColor: 'brand.primaryTeal',
        bgColor: shamrockGreen10,
      },
      '&[data-status="neutral"]': {
        borderColor: coolgrey10,
        bgColor: coolgrey1,
      },
      '&[data-status="warning"]': {
        borderColor: 'brand.orange30',
        bgColor: 'brand.orange5',
      },
    },
    title: {
      '&[data-status="error"]': {
        color: 'error.primary',
      },
      '&[data-status="info"]': {
        color: 'brand.primaryTeal',
      },
      '&[data-status="success"]': {
        color: 'brand.primaryTeal',
      },
      '&[data-status="neutral"]': {
        color: coolgrey10,
      },
      '&[data-status="warning"]': {
        color: 'brand.orange',
      },
    },
    description: {
      '&[data-status="info"]': {
        color: 'brand.primaryTeal',
      },
    },
    icon: {
      '&[data-status="error"]': {
        color: 'error.primary',
      },
      '&[data-status="info"]': {
        color: 'brand.primaryTeal',
      },
      '&[data-status="success"]': {
        color: 'brand.primaryTeal',
      },
      '&[data-status="neutral"]': {
        color: coolgrey10,
      },
      '&[data-status="warning"]': {
        color: 'brand.orange',
      },
    },
  }),
  variants: {
    promo: AlertHelper.definePartsStyle({
      container: {
        '&[data-status]': {
          borderColor: 'brand.cityGreen',
          bgColor: 'brand.cityGreen5',
          borderWidth: 1,
        },
      },
      description: {
        fontWeight: 'medium',
        fontSize: 'md',
        lineHeight: 1.2,
      },
      icon: {
        '&[data-status="neutral"]': { color: 'brand.cityGreen' },
      },
    }),
    upgrade: AlertHelper.definePartsStyle({
      container: {
        '&[data-status]': {
          borderColor: 'brand.cityGreen',
          bgColor: 'brand.cityGreen5',
          borderWidth: 1,
        },
      },
      description: {
        fontWeight: 'medium',
        fontSize: 'md',
        lineHeight: 1.2,
      },
      icon: {
        '&[data-status="neutral"]': { color: 'brand.cityGreen' },
      },
    }),
  },
})
