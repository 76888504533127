import { Box, Link, useMultiStyleConfig, useMediaQuery } from '@chakra-ui/react'
import { Image } from '@reward-platform/lift/components'
import { deviceSizes } from '@reward-platform/lift/theme/deviceSizes'
import NextLink from 'next/link'
import { useIsPartnerAvios, usePartner } from '~/context/partner'
import { useBasketItemsCount } from '~/hooks/useBasket/useBasket'
import { getPartnerLogoUrl } from '~/utils/assets'
import { HeaderBasketItem } from '~/components/shared/Header/HeaderBasketItem'
import { HeaderUserDetails } from '~/components/shared/Header/HeaderUserDetails'
import formatPrimaryDriversName from '~/utils/formatPrimaryDriversName'
import { useFeatureFlag } from '~/hooks/useFeatureFlag'
import HeaderMenu from '../HeaderMenu'
import { AviosHeader } from './AviosHeader'
import { MenuLinkOption } from '../HeaderMenu/HeaderMenuItem'

type MenuOption = MenuLinkOption & { isVisible: boolean }

export type HeaderProps = {
  user?: {
    title?: string
    firstName: string
    familyName: string
    memberIdentifier?: string
  }
  accountBalanceAmount?: number
  isLoading?: boolean
  menuOptions: MenuOption[]
  skipContentSelector: string
}
const Header = ({
  user,
  accountBalanceAmount,
  isLoading,
  menuOptions,
  skipContentSelector,
}: HeaderProps) => {
  const partner = usePartner()
  const [isTabletOrSmaller] = useMediaQuery(`(max-width: ${deviceSizes.medium})`)
  const basketItemsCount = useBasketItemsCount()

  const getLogoSrc = (): string => {
    const shouldUseMobileLogo = partner.theme === 'british_airways' && isTabletOrSmaller
    return getPartnerLogoUrl(partner, shouldUseMobileLogo)
  }

  const isLoggedIn = Boolean(user)
  const styles = useMultiStyleConfig('Header', {})
  const redirectHref = useFeatureFlag('flights') ? '/?tab=flights' : '/'
  const isAvios = useIsPartnerAvios()

  const userDetails = {
    name: formatPrimaryDriversName(undefined, user?.firstName, user?.familyName),
    aviosBalance: accountBalanceAmount ?? 0,
    membershipNumber: parseInt(user?.memberIdentifier ?? '0', 10),
  }
  const visibleMenuItems = menuOptions.filter(({ isVisible }) => isVisible)

  if (isAvios) {
    return (
      <AviosHeader
        isLoading={isLoading}
        userDetails={userDetails}
        basketItemsCount={basketItemsCount}
        skipContentSelector={skipContentSelector}
      />
    )
  }

  return (
    <Box __css={styles.headerWrapper} role="banner">
      <Box __css={styles.header}>
        <Link as={NextLink} href={redirectHref} tabIndex={0} aria-label="home link">
          <Image
            __css={styles.logo}
            src={getLogoSrc()}
            alt={`${partner.name} Logo`}
            layout="fill"
            objectFit="contain"
            objectPosition="left"
          />
        </Link>
        {isLoggedIn && (
          <HeaderUserDetails user={user} accountBalanceAmount={accountBalanceAmount} />
        )}
        {!isLoading && (
          <>
            <HeaderBasketItem basketItemsCount={basketItemsCount} />
            <HeaderMenu links={visibleMenuItems} />
          </>
        )}
      </Box>
    </Box>
  )
}

export default Header
