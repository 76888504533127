import { useContext } from 'react'
import { PlatformError } from '~/utils/errors'
import { TagManagerContext } from './TagManagerContext'

export const useTagManagerContext = () => {
  const context = useContext(TagManagerContext)

  if (!context) {
    throw new PlatformError('useTagManagerContext must be used within TagManagerProvider')
  }

  return context
}
