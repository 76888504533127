/* eslint-disable sonarjs/no-duplicate-string */
import { roomMoreDetailsModal } from './base'

export const RoomMoreDetailsModal = roomMoreDetailsModal.defineMultiStyleConfig({
  baseStyle: roomMoreDetailsModal.definePartsStyle({
    heading: {
      mb: 6,
      fontSize: '2xl',
      lineHeight: 9,
    },
    infoListItem: {
      color: 'brand.primary.midnightBlue',
    },
    book: {
      height: { base: 12, sm: 'unset' },
      width: { base: 'full', sm: 'unset' },
    },
    bookButton: {
      width: { base: 'full', md: 'max-content' },
    },
  }),
})
