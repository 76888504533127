import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const chakraDefinitionGroupHelper = createMultiStyleConfigHelpers([
  'chakraDefinitionGroupWrapper',
])

export const ChakraDefinitionGroup = chakraDefinitionGroupHelper.defineMultiStyleConfig({
  baseStyle: chakraDefinitionGroupHelper.definePartsStyle({
    chakraDefinitionGroupWrapper: {
      '>dl': {
        w: '100%',
        display: { base: 'flex', md: 'grid' },
        gridTemplateRows: '1fr',
        gridTemplateColumns: { base: 'none', md: '20ch 1fr' },
        alignItems: 'baseline',
        justifyContent: { base: 'space-between', md: 'initial' },
        '>dt': {
          fontSize: 'sm',
        },
      },
      mb: 6,
    },
  }),
})
