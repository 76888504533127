import { ZodError } from 'zod'
import { AxiosError } from 'axios'
import { ERROR_CODES } from './ErrorCodes'
import { GenericException } from './GenericException'

export class ZODException extends GenericException {
  constructor(zodError: ZodError) {
    const zodGeneratedError = {
      ...ERROR_CODES.UNPROCESSABLE_ENTITY,
      validationErrors: zodError instanceof ZodError ? zodError.issues : undefined,
      originalError: zodError,
    } as unknown

    super(zodGeneratedError as AxiosError)
  }
}
