import { NextPageContext } from 'next'
import { PartnerConfig } from '~/schemas/partner'
import { isClient } from '../envChecks'
import { isString } from '../typeChecks'
import { PlatformError } from '../errors'
import { isAviosPlatform } from './platformType'

const parseWhitelist = (value: unknown) =>
  isString(value) ? value.split(',').map((domain) => domain.trim()) : []

const aerLingusPartnerConfig: PartnerConfig = {
  code: 'EI',
  tag: 'aer_lingus',
  slug: 'AER_LINGUS',
  provider: 'RP_EI',
  name: 'Aer Lingus',
  domain: 'aerlingus',
  whitelist: parseWhitelist(process.env.NEXT_PUBLIC_EI_DOMAIN_WHITELIST),
  theme: 'aer_lingus',
}

const britishAirwaysPartnerConfig: PartnerConfig = {
  code: 'BA',
  tag: 'british_airways',
  slug: 'BRITISH_AIRWAYS',
  provider: 'RP_BA',
  name: 'British Airways',
  domain: 'britishairways',
  whitelist: parseWhitelist(process.env.NEXT_PUBLIC_BA_DOMAIN_WHITELIST),
  theme: 'british_airways',
}

const aviosPartnerConfig: PartnerConfig = {
  ...britishAirwaysPartnerConfig,
  name: 'Avios',
  whitelist: parseWhitelist(process.env.NEXT_PUBLIC_AVIOS_DOMAIN_WHITELIST),
  theme: 'avios',
  domain: 'avios',
}

export const partnerConfigs: PartnerConfig[] = [
  aerLingusPartnerConfig,
  britishAirwaysPartnerConfig,
  aviosPartnerConfig,
]

/**
 * @returns partner configuration from host if server or window.location if client
 *
 * should be used in getInitialProps or server code
 *
 * should not be used in components, you can retrieve the partner information from usePartner()
 */
export const getPartner = (host?: string): PartnerConfig => {
  // Check environment for Avios server
  if (isAviosPlatform()) {
    return aviosPartnerConfig
  }

  // Use host or window to check
  const maybeHost = isClient() && !host ? window?.location?.host : host
  if (!maybeHost) {
    const from = isClient() ? 'window.location (client)' : 'headers (server)'
    throw new PlatformError(`No host found from ${from}`)
  }

  const maybePartner = partnerConfigs.find((partner) => partner.whitelist.includes(maybeHost))
  if (!maybePartner) {
    throw new PlatformError(`No partner found that matches host: ${maybeHost}`)
  }

  return maybePartner
}

export const getPartnerFromPageContext = (context: NextPageContext) => {
  const requestHost = context.req?.headers.host
  try {
    return getPartner(requestHost)
  } catch (error) {
    if (error instanceof PlatformError) {
      error.logRequestToDatadog(context.req)
    }

    throw error
  }
}
