import { PartnerConfig } from '~/schemas/partner'
import { getPublicAssetSrc } from './publicAssets'

export const getPartnerFilePrefix = (partner: PartnerConfig) =>
  partner.name.toLocaleLowerCase().replace(' ', '-')

export const getPartnerLogoUrl = (partner: PartnerConfig, shouldUseMobileLogo?: boolean) => {
  const partnerName = getPartnerFilePrefix(partner)
  const filenameSuffix = shouldUseMobileLogo ? 'logo-mobile' : 'logo'
  const filePath = `/assets/${partnerName}-${filenameSuffix}.svg`
  return getPublicAssetSrc(filePath)
}

export const getPartnerIconUrl = (partner: PartnerConfig) =>
  getPublicAssetSrc(`/assets/favicon/${partner.theme.toUpperCase()}.ico`)

export const getPartnerFallbackImageUrl = (partner: PartnerConfig) =>
  getPublicAssetSrc(`/assets/default-brand-images/${partner.theme.toLowerCase()}.svg`)
