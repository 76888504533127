import { PropsWithChildren } from 'react'
import { Card as ChakraCard, CardProps, useMultiStyleConfig } from '@chakra-ui/react'

export const Card = (props: PropsWithChildren<CardProps>) => {
  const { children, ...other } = props
  const styles = useMultiStyleConfig('Card', {})

  return (
    <ChakraCard data-testid="chakra-card" __css={styles.container} {...other}>
      {children}
    </ChakraCard>
  )
}

export default Card
