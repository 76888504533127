import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { checkboxAnatomy } from '@chakra-ui/anatomy'

export const checkboxHelper = createMultiStyleConfigHelpers(checkboxAnatomy.keys)

export const Checkbox = checkboxHelper.defineMultiStyleConfig({
  baseStyle: checkboxHelper.definePartsStyle({
    container: {},
    control: {
      boxShadow: 'inset 1px 3px 6px 0 rgba(0, 0, 0, 0.1)',
      boxSizing: 'border-box',
      rounded: 4,
      border: 'var(--chakra-space-px) solid',
      h: 26,
      w: 26,
      _checked: {
        color: 'white',
        _hover: {
          color: 'white',
        },
      },
      transition: 'all 0.2s',
    },
    label: {},
  }),
})
