import { Box, BoxProps, Icon, chakra, useMultiStyleConfig } from '@chakra-ui/react'
import { convertIconSetToChakraSVG } from '../Icon/Icon.utils'
import { IconSizes } from '../../theme/iconSize'

export type SnackbarVariant = 'info' | 'success' | 'error' | 'warning' | 'promo'

export type SnackbarProps = BoxProps & {
  variant?: SnackbarVariant
  onClose?: () => void
}

export const Snackbar = (props: SnackbarProps) => {
  const { children, variant = 'info', onClose } = props
  const styles = useMultiStyleConfig('Snackbar', { variant })
  return (
    <Box sx={styles.container} {...props}>
      {children}
      <chakra.button sx={styles.closeButton} onClick={onClose}>
        <Icon as={convertIconSetToChakraSVG('cross')} boxSize={IconSizes.xxs} />
      </chakra.button>
    </Box>
  )
}
