import chalk from 'chalk'
import { IncomingHttpHeaders, IncomingMessage } from 'node:http'

export const DATADOG_LOG_META_KEY = 'reward_platform_microservice_request'
export const DATADOG_SERVICE_NAME = 'reward-platform'

export type DatadogLogErrorContext = {
  message: string
  stack?: string
  code?: string
  description: string
  httpStatus?: number
  [key: string]: any
}

export type DatadogLogMetaContext = {
  partner: 'EI' | 'BA' | 'VY'
  platformType: 'avios' | 'whitelabel'
  correlationId?: string | null
  [key: string]: any
}

export type DatadogLogHttpContext = {
  method?: string
  status_code?: number
  url?: string
}

type DatadogLogBaseContext = {
  dd: { service: typeof DATADOG_SERVICE_NAME }
  service: typeof DATADOG_SERVICE_NAME
  timestamp: number
  [key: string]: any
}

type DatadogLogContext = DatadogLogBaseContext & {
  [DATADOG_LOG_META_KEY]: DatadogLogMetaContext
  level?: 'info' | 'warn' | 'error' | 'critical'
  http?: DatadogLogHttpContext
  error?: DatadogLogErrorContext
  headers?: IncomingHttpHeaders
}

type LoggerInput = { [key: string]: any }

type LevelType = 'error' | 'info' | 'debug' | 'warn' | 'critical' | 'alert'

export const isProductionLogger = () => {
  const env = process.env.NODE_ENV ?? 'production'
  return !['development', 'test'].includes(env)
}

const colouriseLevel = (level: LevelType): string => {
  switch (level) {
    case 'info':
      return chalk.blue(level)
    case 'warn':
      return chalk.yellow(level)
    case 'error':
      return chalk.red(level)
    default:
      return chalk.whiteBright(level)
  }
}

const formatStack = (stack: string): string => {
  return stack ? `\n  ${chalk.redBright(stack)}` : ''
}
const formatDebugJson = (error: object): string => {
  return error ? `\n  ${chalk.redBright(JSON.stringify(error, null, 2))}` : ''
}

export const formatDevLogs = (obj: LoggerInput, debugLog = false) => {
  const { timestamp, label, level, message, stack } = obj
  const formattedError = debugLog ? formatDebugJson(obj.error) : ''
  return `${chalk.gray(timestamp)} [${chalk.yellow(
    obj.reward_platform_microservice_request?.correlationId ?? 'NO_CORRELATION_ID'
  )}] [${chalk.blueBright(label)}] ${chalk.underline(colouriseLevel(level))}: ${chalk.whiteBright(
    message
  )} ${formatStack(stack)} ${formattedError}`
}

export const createDatadogMetaContext = (meta: DatadogLogMetaContext) => ({
  [DATADOG_LOG_META_KEY]: meta,
})

export const getDatadogLoggerBaseMeta = (
  obj: { [key: string]: any } = {}
): DatadogLogBaseContext => ({
  dd: { service: DATADOG_SERVICE_NAME },
  service: DATADOG_SERVICE_NAME,
  timestamp: Date.now(),
  ...obj,
})

export const createServerLoggerMeta = (
  req?: IncomingMessage,
  additionalMeta?: Record<string, any> | null
): DatadogLogContext => ({
  ...getDatadogLoggerBaseMeta(),
  ...createDatadogMetaContext({
    partner: req?.headers['x-partner'],
    platformType: req?.headers['x-platform-type'],
    correlationId: req?.headers['x-correlation-id'],
    ...additionalMeta,
  } as DatadogLogMetaContext),
  http: {
    status_code: req?.statusCode,
    method: req?.method,
    url: req?.url,
  },
  headers: {
    ...req?.headers,
  },
})
