import { z } from 'zod'
import { ContentfulRichTextDocumentSchema } from './common'
import { ContentfulAssetSchema } from './asset'

export const ContentfulHeroSectionSchema = z.object({
  entryId: z.string().optional(),
  heading: z.string().optional(),
  image: ContentfulAssetSchema.optional(),
  body: ContentfulRichTextDocumentSchema.optional(),
})
export type ContentfulHeroSection = z.infer<typeof ContentfulHeroSectionSchema>
