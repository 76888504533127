import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'container',
  'wrapper',
  'banner',
  'message',
  'icon',
  'crossIcon',
])
export const StickyBanner = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      position: 'sticky',
      top: 0,
      zIndex: { base: 500, lg: 'sticky' },
    },
    wrapper: {
      display: 'grid',
      gridColumnGap: 'var(--layout-gutter)',
      gridTemplateColumns: '1fr minmax(auto, var(--layout-width)) 1fr',
      gridAutoFlow: 'row',
      gridAutoRows: 'min-content',
      '--layout-width': 'var(--chakra-sizes-7xl)',
      '--layout-gutter': 'var(--chakra-space-4)',
      '--layout-column-center': '2/3',
      '--layout-column-wide': '1/4',
      '--layout-sidebar-width': 'minmax(16ch, 1fr)',
      '--layout-content-width': 'minmax(48ch, 3fr)',
    },
    banner: {
      position: 'relative',
      display: 'flex',
      gridColumn: 'var(--layout-column-center) !important',
      alignItems: 'flex-start',
      justifyContent: 'center',
      padding: 4,
      gap: '1rem',
      width: '100%',
    },
    message: {
      textAlign: { base: 'left', lg: 'center' },
      mr: { base: 4, md: 'unset' },
      maxW: { base: 'unset', md: '80%' },
    },
    crossIcon: {
      position: 'absolute',
      right: 0,
      cursor: 'pointer',
    },
  },
})
