/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const HotelNearbyAttractions = helpers.defineMultiStyleConfig({
  baseStyle: {
    groupTitle: {
      fontSize: 'xl',
      fontWeight: 'semibold',
      color: 'brand.grey6',
    },
    placeName: {
      color: 'brand.grey6',
      fontSize: 'md',
      fontWeight: 'bold',
    },
    placeDistance: {
      color: 'brand.black',
      fontSize: 'sm',
      fontWeight: 'normal',
    },
  },
})
