/* eslint-disable react/no-array-index-key */
import { ReactElement } from 'react'
import { chakra, Box, Icon, useMultiStyleConfig, Flex, VisuallyHidden } from '@chakra-ui/react'
import { convertIconSetToChakraSVG } from '../Icon/Icon.utils'
import { RatingsProps } from './Ratings.types'

export type RatingsTheme = {
  color: string
}

const Ratings = ({
  rating,
  size,
  showEmptyStars,
  showRatingText,
  additionalText,
  isStarRating,
}: RatingsProps): ReactElement => {
  const styles = useMultiStyleConfig('Ratings', {})
  const roundRatingToNearestHalf = Math.round(rating * 2) / 2
  const numFullStars = Math.floor(roundRatingToNearestHalf)
  const fullStars = new Array(numFullStars).fill(undefined)
  const isDecimalRating = Boolean(roundRatingToNearestHalf - numFullStars)
  const emptyStars = new Array(5 - numFullStars - (isDecimalRating ? 1 : 0)).fill(undefined)
  const starSize = size ?? 13
  const hiddenLabel = isStarRating ? `${rating} stars` : `Rating: ${rating} out of 5`

  return (
    <Box as="span" __css={styles.ratingWrapper}>
      <VisuallyHidden>{hiddenLabel}</VisuallyHidden>
      {fullStars.map((_v, i) => (
        <Icon
          key={i}
          as={convertIconSetToChakraSVG('star-filled')}
          boxSize={`${starSize}px`}
          role="presentation"
          __css={styles.ratingStar}
          data-testid="rating-icon-filled-star"
        />
      ))}
      {isDecimalRating ? (
        <Icon
          as={convertIconSetToChakraSVG('star-half-filled')}
          boxSize={`${starSize}px`}
          role="presentation"
          color="currentColor"
          data-testid="rating-icon-half-star"
        />
      ) : null}
      {showEmptyStars
        ? emptyStars.map((_v, i) => {
            return (
              <Icon
                key={i}
                as={convertIconSetToChakraSVG('star')}
                boxSize={`${starSize}px`}
                role="presentation"
                __css={styles.ratingStar}
              />
            )
          })
        : null}
      <Flex sx={{ mt: `${-starSize / 10}px` }} __css={styles.ratingTextWrapper}>
        {showRatingText && (
          <chakra.small __css={styles.ratingText} fontSize={starSize} aria-hidden>
            {roundRatingToNearestHalf}/5
          </chakra.small>
        )}
        {additionalText && <chakra.small fontSize={starSize}>{additionalText}</chakra.small>}
      </Flex>
    </Box>
  )
}

export default Ratings
