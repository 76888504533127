import { helpers } from '~/components/hotels/details/themes/base'

export const HotelDetails = helpers.defineMultiStyleConfig({
  baseStyle: {
    title: {
      fontSize: 'var(--alto-sem-text-heading-xs-font-size)',
      lineHeight: 'var(--alto-sem-text-heading-xs-line-height)',
      letterSpacing: 'var(--alto-sem-text-heading-xs-letter-spacing)',
      fontWeight: 'bold',
    },
    sectionCenter: {
      bg: 'bg.layer2.default',
      borderRadius: 'xs',
      boxShadow: 'md',
      p: 'lg',
    },
    customerReviews: {
      bg: 'transparent',
      borderRadius: 'unset',
      boxShadow: 'unset',
    },
  },
})
