/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const CarDetails = helpers.defineMultiStyleConfig({
  baseStyle: {
    searchTitle: {
      color: 'fg.accent.secondary',
      fontSize: 'var(--alto-sem-text-heading-xs-font-size)',
      lineHeight: 'var(--alto-sem-text-heading-xs-line-height)',
      letterSpacing: 'var(--alto-sem-text-heading-xs-letter-spacing)',
    },
    searchSubTitle: {
      fontSize: 'md',
    },
    pageTitle: {
      fontSize: 'var(--alto-sem-text-heading-md-font-size)',
      lineHeight: 'var(--alto-sem-text-heading-md-line-height)',
      fontWeight: 'medium',
      color: 'fg.accent.secondary',
    },
    searchSummaryWrapper: {
      boxShadow: 'none',
      bg: 'bg.layer1.default',
    },
    primaryDriverWrapper: {
      h2: {
        fontWeight: 'normal',
        fontSize: {
          base: 'md',
          md: 'md',
        },
        lineHeight: 'var(--alto-sem-text-body-md-line-height)',
        letterSpacing: 0,
      },
      p: {
        fontSize: {
          base: 'md',
          md: 'md',
        },
        lineHeight: 'var(--alto-sem-text-body-md-line-height)',
      },
    },
    location: {
      fontSize: {
        base: 'md',
        md: 'md',
      },
      lineHeight: 'var(--alto-sem-text-body-md-line-height)',
      mt: '2xs',
    },
  },
})
