import { priceBreakdownSummaryModalButtonHelpers } from './base'

const tableBorder = '1px solid rgb(var(--chakra-colors-rgb-alCoolGrey2))'

export const PriceBreakdownSummaryModalButton =
  priceBreakdownSummaryModalButtonHelpers.defineMultiStyleConfig({
    baseStyle: priceBreakdownSummaryModalButtonHelpers.definePartsStyle({
      flightsTC: {
        'p strong': {
          fontWeight: 'medium',
        },
        h4: {
          pb: 4,
          fontSize: '2xl',
        },
        h6: {
          color: 'brand.primaryTeal',
          fontSize: 'xl',
          fontWeight: 'semibold',
          mb: 2,
        },
        table: {
          borderCollapse: 'collapse',
          width: '100%',
          p: {
            fontSize: 'sm',
            p: 2,
          },
          mt: 5,
          mb: 5,
          border: tableBorder,
          tr: {
            borderBottom: '1px',
            borderColor: 'brand.coolgrey2',
          },
          'tr th:first-child': {
            width: '50%',
            borderRight: tableBorder,
          },
          'tr th:nth-child(2)': {
            width: '30%',
            borderRight: tableBorder,
          },
          'tr td:first-child': {
            borderRight: tableBorder,
          },
          'tr td:nth-child(2)': {
            borderRight: tableBorder,
          },
          'tr th:last-child': {
            width: '20%',
          },
          'tr th p': {
            textAlign: 'left',
            fontWeight: 'bold',
            fontSize: 'xs',
          },
        },
      },
    }),
  })
