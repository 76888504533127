import React, { useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import type { WithPageAuthRequired } from '@auth0/nextjs-auth0/client'

import { useFeatureFlag } from '~/hooks/useFeatureFlag'
import SpinnerOverlay from '~/components/shared/SpinnerOverlay'
import { useAuthContext } from './AuthContext'

const ROOT_PATH = '/'
const UNRESTRICTED_PRODUCTS = ['hotels']

const shouldRestrictPath = (path: string) =>
  !UNRESTRICTED_PRODUCTS.some((product) => path.includes(product))

const useIsAuthRequired = (asPath: string) => {
  const hotelSearchEnabled = useFeatureFlag('hotel-search-allow-unauthorized')

  if (asPath === ROOT_PATH) {
    return false
  }

  if (hotelSearchEnabled) {
    return shouldRestrictPath(asPath)
  }

  return true
}

const defaultOnRedirecting = () => <SpinnerOverlay />

const defaultOnError = () => {
  // TODO: handle authentication error
  return null
}

export const withPageAuthRequired: WithPageAuthRequired = (Component, options = {}) => {
  return ({ ...props }) => {
    const { returnTo, onRedirecting = defaultOnRedirecting, onError = defaultOnError } = options
    const { user, error, isLoading, handleLogin } = useAuthContext()
    const router = useRouter()

    const isAuthRequired = useIsAuthRequired(router.asPath)

    const shouldShowPage = useMemo(
      () => (user && !error) || !isAuthRequired,
      [error, isAuthRequired, user]
    )

    useEffect(() => {
      if (shouldShowPage || isLoading) {
        return
      }
      const returnToPath = returnTo ?? router.asPath ?? '/'

      handleLogin(returnToPath)
    }, [handleLogin, isLoading, returnTo, router.asPath, shouldShowPage])

    if (error) {
      return onError(error)
    }
    if (shouldShowPage) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return <Component user={user} {...(props as any)} />
    }

    return onRedirecting()
  }
}

export default withPageAuthRequired
