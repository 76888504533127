import React, { useState, useEffect } from 'react'
import { Fade, useMediaQuery } from '@chakra-ui/react'
import { Snackbar, SnackbarIcon } from '@reward-platform/lift/components'
import { usePromotionContext } from '~/context/promotion'
import { deviceSizes } from '@reward-platform/lift/theme/deviceSizes'
import { DynamicVariableRichText } from '../shared/DynamicVariableRichText'
import { PromotionItemProps } from './Promotion.types'

const PromotionBasketSnackbar = ({ product, item, isIncentive = true }: PromotionItemProps) => {
  const [showSnackbar, setShowSnackbar] = useState(false)

  const { isEnabled, getProductPromotion } = usePromotionContext()
  const { content, variableMap, isItemValidForPromotion } = getProductPromotion(product) || {}
  const { basketSnackBarText } = content || {}
  const isItemValid = isItemValidForPromotion?.(item, { isIncentive })
  const isSnackbarEnabled = isEnabled(product) && basketSnackBarText && isItemValid
  const [isMobileOrSmaller] = useMediaQuery([`(max-width: ${deviceSizes.small})`])

  useEffect(() => {
    const timer: NodeJS.Timeout = setTimeout(() => {
      if (isSnackbarEnabled) {
        setShowSnackbar(true)
      }
    }, 1000)

    return () => {
      clearTimeout(timer)
      setShowSnackbar(false)
    }
  }, [isSnackbarEnabled])

  if (!isSnackbarEnabled || isMobileOrSmaller) {
    return null
  }

  return (
    <Fade in={showSnackbar}>
      <Snackbar
        onClose={() => {
          setShowSnackbar(false)
        }}
        alignItems="flex-start"
        variant="promo"
      >
        <SnackbarIcon iconName="favourite-payment-card" iconSize="xl" />
        <DynamicVariableRichText document={basketSnackBarText.content} variableMap={variableMap} />
      </Snackbar>
    </Fade>
  )
}

export default PromotionBasketSnackbar
