import { helpers } from './base'

export const BasketItemSummary = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    heading: {
      color: 'black',
      fontWeight: 'medium',
    },
    basketItemSummaryWrapper: {
      bg: 'brand.teal10',
    },
  }),
})
