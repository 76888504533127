import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'contentContainer',
  'textContainer',
  'imageContainer',
  'image',
])

export const NoFlightResults = helpers.defineMultiStyleConfig({
  baseStyle: {
    contentContainer: {
      display: 'flex',
      flexDirection: {
        base: 'column',
        md: 'unset',
      },
      alignItems: {
        base: 'center',
        md: 'unset',
      },
      gap: '4',
      marginTop: '3rem',
    },
    textContainer: {
      maxWidth: '600px',
      display: 'inline-block',
      width: '100%',
      marginBottom: '2rem',
      p: {
        marginTop: '1rem',
      },
      h1: {
        fontSize: {
          base: '1.75rem',
          md: '2rem',
          lg: '3rem',
        },
        fontWeight: 400,
      },
      li: {
        listStyle: '"—"',
        p: {
          margin: 1,
        },
      },
    },
    imageContainer: {
      width: {
        md: '80%',
        lg: 'unset',
      },
      overflow: 'hidden',
      borderRadius: '24px',
    },
    image: {
      width: {
        md: '549px',
        lg: 'unset',
      },
      height: '100%',
      display: 'flex',
      objectFit: 'cover',
      objectPosition: 'right',
    },
  },
})
