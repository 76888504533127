/* eslint-disable sonarjs/no-duplicate-string */
import { ComponentStyleConfig } from '@chakra-ui/react'
import merge from 'lodash/merge'
import { Spinner as base } from './base'

export const Spinner: ComponentStyleConfig = merge({}, base, {
  baseStyle: {
    borderLeftColor: 'brand.yellow',
    borderBottomColor: 'brand.black',
    color: 'brand.black',
  },
})
