import axios, { AxiosError, AxiosResponse } from 'axios'
import { HEADER_KEYS, HeaderKey } from '@reward-platform/shared-schemas'
import { createDatadogMetaContext, DatadogLogMetaContext } from '@reward-platform/logger/utils'
import { createDatadogLogger } from '~/utils/logger'
import { ApiRouteRequestError } from '~/utils/errors'
import { getPlatformPathname, getPlatformType } from '~/utils/partner'
import { NEXT_API_URL } from '~/utils/apiConfig'

export const nextApiClient = axios.create({
  baseURL: getPlatformPathname(NEXT_API_URL),
  timeout: 60000,
  validateStatus(status) {
    return status > 199 && status < 300 // Everything more than 299 will throw a error. Also, if unwanted redirects happen (?)
  },
})

const getAdditionalHeaders = () => {
  const correlationId = nextApiClient.defaults.headers.common[HEADER_KEYS.correlationId]
  const partner = nextApiClient.defaults.headers.common[HEADER_KEYS.partnerCode]
  return {
    correlationId,
    partner,
    platformType: getPlatformType(),
  } as DatadogLogMetaContext
}

const getLogContextFromAxiosResponse = (response: AxiosResponse) => {
  return {
    data: response.data,
    status: response.status,
    statusText: response.statusText,
    headers: response.headers,
    http: { ...response.config, method: response.config.method?.toUpperCase() },
  }
}

nextApiClient.interceptors.response.use(
  (response: AxiosResponse) => {
    const logger = createDatadogLogger('bff-client-response', { level: 'info' })
    const logContext = getLogContextFromAxiosResponse(response)
    const additionalHeaders = getAdditionalHeaders()
    const logMeta = createDatadogMetaContext(additionalHeaders)
    logger.info(`NextApiResponse: ${response.config.url}`, { ...logContext, ...logMeta })
    return response
  },
  (axiosError: AxiosError) => {
    const logContext = axiosError.response
      ? getLogContextFromAxiosResponse(axiosError.response)
      : {}

    const error = new ApiRouteRequestError(axiosError)
    const additionalHeaders = getAdditionalHeaders()
    error.logToDatadog(additionalHeaders, logContext)
    throw error
  }
)

export const setPartnerHeader = (partner: string) => {
  nextApiClient.defaults.headers.common[HEADER_KEYS.partnerCode] = partner
}

export const setCorrelationIdHeader = (value: string) => {
  nextApiClient.defaults.headers.common[HEADER_KEYS.correlationId] = value
}

// TEMP: while BFF still exists
export const setBffHeader = (key: HeaderKey, value: string) => {
  const headerKey = HEADER_KEYS[key]
  nextApiClient.defaults.headers.common[headerKey] = value
}

export const hasBffHeader = (key: HeaderKey) => {
  const headerKey = HEADER_KEYS[key]
  return !!nextApiClient.defaults.headers.common[headerKey]
}

export const deleteBffHeader = (key: HeaderKey) => {
  const headerKey = HEADER_KEYS[key]
  delete nextApiClient.defaults.headers.common[headerKey]
}
