import { Box, BoxProps, useMultiStyleConfig } from '@chakra-ui/react'
import { useAlertContext } from './AlertProvider'

type Props = BoxProps

export const AlertTitle = (props: Props) => {
  const { status } = useAlertContext()
  const styles = useMultiStyleConfig('Alert')

  return <Box data-status={status} __css={styles.title} {...props} />
}
