import { helpers } from './base'

export const RecentSearches = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      h4: {
        fontWeight: 'medium',
      },
    },
    item: {
      'p:first-of-type': {
        fontWeight: 'semibold',
        color: 'brand.primaryTeal',
      },
      'p:nth-of-type(2)': {
        fontWeight: 'normal',
      },
      'p:nth-of-type(3)': {
        fontWeight: {
          base: 'normal',
          lg: 'medium',
        },
        color: 'brand.primaryTeal',
      },
      '&.chakra-button': {
        margin: {
          base: 'var(--chakra-sizes-6) 0',
          lg: '0 var(--chakra-sizes-4)',
        },
        padding: {
          base: 0,
          lg: 'var(--chakra-sizes-3) var(--chakra-sizes-6)',
        },
      },
      _hover: {
        backgroundColor: 'brand.teal10',
        '&.chakra-button': {
          borderColor: 'brand.coolgrey1',
        },
      },
    },
  },
})
