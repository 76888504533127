import { Box } from '@chakra-ui/react'
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'

export type InternalCollapsibleProps = {
  isExpanded: boolean
}

const COLLAPSED_HEIGHT = 0

const InternalCollapsible = ({
  children,
  isExpanded,
}: PropsWithChildren<InternalCollapsibleProps>) => {
  const ref = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState<number | undefined>(
    isExpanded ? undefined : COLLAPSED_HEIGHT
  )

  useEffect(() => {
    if (!ref.current || !isExpanded || height === undefined) {
      return undefined
    }
    const resizeObserver = new ResizeObserver((entries) => setHeight(entries[0].contentRect.height))
    resizeObserver.observe(ref.current)
    return () => resizeObserver.disconnect()
  }, [height, isExpanded])

  useEffect(() => {
    const newHeight = isExpanded ? ref.current?.getBoundingClientRect().height : COLLAPSED_HEIGHT
    setHeight(newHeight)
  }, [isExpanded])

  return (
    <Box mb={4} transition="height 0.3s ease" style={{ height }} hidden={!isExpanded}>
      <Box ref={ref}>{children}</Box>
    </Box>
  )
}

export default InternalCollapsible
