import { helpers } from './base'

export const SearchFieldSwapButton = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    swapButton: {
      borderRadius: 'sm',
      bgColor: 'rgba(46, 92, 153, 0.1)',
      _disabled: {
        bgColor: 'brand.baseGray.10',
        '.chakra-icon': {
          color: 'brand.baseGray.50',
        },
        _hover: {
          '.chakra-icon': {
            color: 'brand.baseGray.50',
          },
          bgColor: 'brand.baseGray.10',
        },
      },
      _hover: {
        '.chakra-icon': {
          color: 'brand.highlight.blue',
        },
        bgColor: 'rgba(38, 113, 211, 0.1)',
      },
      '.chakra-icon': {
        color: 'brand.primary.blue',
      },
    },
  }),
})
