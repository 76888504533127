/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

const fgAccentPrimary = 'fg.accent.primary'

export const RoomsBanner = helpers.defineMultiStyleConfig({
  baseStyle: {
    heading: {
      fontWeight: 'medium',
      lineHeight: 'unset',
      letterSpacing: 0,
    },
    confirmButton: {
      fontWeight: 'medium',
      fontSize: 'sm',
      borderRadius: 'xs',
      bg: 'none',
      color: fgAccentPrimary,
      border: 'var(--alto-sem-border-width-sm) solid var(--alto-sem-color-border-accent)',
      _active: {
        color: fgAccentPrimary,
        bg: 'bg.accent.subtle.active',
      },
      _focus: {
        bg: 'bg.accent.subtle.hover',
        outlineOffset: 'var(--alto-sem-border-width-md)',
        outline: 'var(--alto-sem-border-width-md) solid var(--alto-sem-color-border-accent)',
      },
      _hover: {
        bg: 'bg.accent.subtle.hover',
        boxShadow: 'none',
        borderColor: 'border.accent',
      },
    },
    container: {
      borderRadius: 'xs',
      background: 'bg.layer2.default',
      borderColor: 'border.accent',
    },
    bedIcon: {
      color: fgAccentPrimary,
    },
  },
})
