import { skeletonFade } from '@reward-platform/lift/components/SkeletonCard/themes/base'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'wrapper',
  'body',
  'heading',
  'tagline',
  'picture',
  'image',
])

export const Hero = helpers.defineMultiStyleConfig({
  baseStyle: {
    wrapper: {
      _before: {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 0,
        right: '50%',
        height: '100%',
        bgImage: 'linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))',
      },
      position: 'relative',
      overflow: 'hidden',
      alignItems: 'center',
      display: 'grid',
      gridColumn: 'var(--layout-column-wide)',
      gridColumnGap: 'var(--layout-gutter)',
      gridTemplateAreas: "'. . .''. content .''. . .'",
      gridTemplateRows: '2rem 1fr 6rem',
      gridTemplateColumns: '1fr minmax(auto, var(--layout-width)) 1fr',
      minHeight: { base: '228px', md: '376px', xl: '400px' },
    },
    body: {
      gridArea: 'content',
      position: 'relative',
      zIndex: 1,
      marginLeft: {
        base: 1.5,
        md: 2,
        xl: 'unset',
      },
    },
    heading: {
      color: '#fff',
      fontSize: '3rem',
    },
    tagline: {
      color: '#fff',
      h2: { fontSize: { base: '3xl', lg: '4xl' }, fontWeight: 'medium', marginBottom: 0 },
      h3: { fontSize: { base: 'xl', lg: '2xl' }, fontWeight: 'semibold', lineHeight: '6' },
      p: { fontSize: 'md', marginBottom: 0, color: 'var(--brand-white)' },
    },
    picture: {
      gridArea: '1/1/4/4',
      alignSelf: 'stretch',
      maxHeight: '400px',
      _after: {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        bgColor: 'rgba(0, 0, 0, 0.15)',
      },
      _empty: {
        bgColor: '#eee',
        borderRadius: '3px',
        cursor: 'default',
        animation: `${skeletonFade} 2s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955)`,
      },
    },
    image: {
      objectFit: 'cover',
      objectPosition: 'center',
      width: '100%',
      height: '100%',
    },
  },
})
