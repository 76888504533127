import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'text',
  'guestRating',
  'reviewsCount',
  'icon',
  'rightChevronIcon',
])

export const GuestRatings = helpers.defineMultiStyleConfig({
  baseStyle: {
    text: {
      fontSize: 'sm',
      order: { base: 0, md: 1 },
      mt: { base: 3, md: 2, xl: 4 },
    },
    guestRating: {
      whiteSpace: 'nowrap',
      fontWeight: 'medium',
    },
    reviewsCount: {
      whiteSpace: 'nowrap',
    },
  },
  variants: {
    experienceCard: {
      text: {
        mt: 2,
        fontSize: 'xs',
        pb: { base: 4, md: 'unset' },
      },
    },
    hotelCard: {
      text: {
        mt: 4,
      },
    },
  },
})
