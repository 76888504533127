import { helpers } from './base'

export const ExperienceCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    card: {
      boxShadow: 'md',
    },
    heading: {
      fontSize: 'var(--alto-sem-text-heading-xs-font-size)',
      lineHeight: 'var(--alto-sem-text-heading-xs-line-height)',
      letterSpacing: 'var(--alto-sem-text-heading-xs-letter-spacing)',
      fontWeight: 'bold',
      mb: '2xs',
      color: 'fg.accent.primary',
    },
    image: {
      borderTopLeftRadius: 'xs',
      borderTopRightRadius: {
        base: 'xs',
        md: 0,
      },
      borderBottomLeftRadius: {
        base: 0,
        md: 'xs',
      },
    },
    content: {
      p: 'md',
    },
    pricingContainer: {
      p: 'md',
      div: {
        _before: {
          top: '-128px',
        },
      },
    },
  },
})
