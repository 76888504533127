import { Box, chakra, useMultiStyleConfig } from '@chakra-ui/react'

type ListProps = {
  items: string[] | JSX.Element[]
}
function List({ items }: ListProps): JSX.Element {
  const styles = useMultiStyleConfig('List', {})

  return (
    <chakra.ul __css={styles.wrapper}>
      {items.map((item, index) => (
        <chakra.li key={typeof item === 'string' ? item : item.key} __css={styles.item}>
          <Box __css={styles.bulletWrapper}>
            <chakra.span __css={styles.bullet} />
          </Box>
          {typeof item === 'string' ? <span>{item}</span> : item}
        </chakra.li>
      ))}
    </chakra.ul>
  )
}

export default List
