/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

const checkedDisabled = {
  bg: 'fg.disabled.onSubtle',
  border: 'var(--alto-sem-border-width-xl) solid var(--alto-sem-color-bg-disabled-subtle)',
  borderColor: 'var(--alto-sem-color-bg-disabled-subtle)',
  outline: '1px solid var(--alto-sem-color-border-disabled-subtle)',
  outlineOffset: '-1px',
  color: 'fg.disabled.onSubtle',
}

const checkedHovered = {
  bg: 'bg.layer2.default',
  border: 'var(--alto-sem-border-width-xl) solid var(--alto-sem-color-bg-accent-vibrant-hover)',
}

export const RadioButton = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    control: {
      marginRight: 'sm',
      border: 'var(--alto-sem-border-width-sm) solid var(--alto-sem-color-border-primary-default)',
      boxShadow: 'none',
      bg: 'bg.layer2.default',
      _checked: {
        bg: 'bg.layer2.default',
        border: 'var(--alto-sem-border-width-xl) solid var(--alto-sem-color-border-accent)',
        _disabled: {
          ...checkedDisabled,
          _hover: {
            '> div': {
              ...checkedDisabled,
            },
          },
        },
      },
      _hover: {
        borderColor: 'border.primary.hover',
      },
      _disabled: {
        borderColor: 'border.disabled.subtle',
        bg: 'bg.disabled.subtle',
      },
      _focus: {
        borderColor: 'bg.accent.vibrant.hover',
        outline:
          'var(--alto-sem-border-width-md) solid var(--alto-sem-color-bg-accent-vibrant-hover)',
        outlineOffset: '2px',
      },
    },
    icon: {
      display: 'none',
    },
    container: {
      color: 'fg.primary',
      'span > span': {
        fontWeight: 'var(--alto-sem-text-body-regular-font-weight)',
      },
      _checked: {
        _hover: {
          '> div': {
            ...checkedHovered,
            _disabled: {
              ...checkedDisabled,
            },
          },
        },
      },
      _hover: {
        '> div': {
          borderColor: 'border.primary.hover',
        },
      },
      _disabled: {
        color: checkedDisabled.color,
        _hover: {
          '> div': {
            borderColor: checkedDisabled.borderColor,
          },
        },
      },
    },
  }),
})
