import { priceBreakdownStickyHelpers } from './base'

export const PriceBreakdownSticky = priceBreakdownStickyHelpers.defineMultiStyleConfig({
  baseStyle: priceBreakdownStickyHelpers.definePartsStyle({
    stickyWrapper: {
      borderRadius: 'sm',
      '> div': {
        boxShadow: 'brand.boxShadow',
      },

      '&[data-is-pinned=true] > div': {
        boxShadow: '0px -6px 9px 0px rgba(0, 0, 0, 0.1)',
      },
    },
    stickyContainer: {
      bg: 'brand.white',
    },
    priceContainer: {
      span: {
        _last: { fontSize: 'md' },
      },
    },
    priceLabel: {
      fontSize: '2xl',
      fontFamily: 'var(--chakra-fonts-heading)',
      color: 'brand.primary.blue',

      _invalid: {
        color: 'brand.alertPink',
      },
    },
  }),
})
