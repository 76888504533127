import { itemCardImageHelper } from './base'

export const ItemCardImage = itemCardImageHelper.defineMultiStyleConfig({
  baseStyle: itemCardImageHelper.definePartsStyle({
    imageWrapper: {
      borderRadius: 'lg',
      img: {
        borderRadius: 'lg',
      },
    },
  }),
  sizes: {
    sm: {},
    md: {},
    lg: {
      imageWrapper: {
        img: {
          _first: {},
        },
      },
    },
  },
})
