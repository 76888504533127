import { helpers } from './base'

export const FeaturesList = helpers.defineMultiStyleConfig({
  baseStyle: {
    listContainer: {
      gap: 14,
      marginTop: 10,
    },
    mainContainer: {
      borderRadius: 'sm',
      boxShadow: 'brand.boxShadow',
      backgroundColor: 'brand.white',
      padding: 6,
    },
    title: {
      fontSize: '2xl',
      fontWeight: 'light',
    },
    listItem: {
      columnGap: 5,
      fontSize: 'md',
      color: 'brand.primary.midnightBlue',
    },
    list: {
      'li + li': {
        marginTop: 5,
      },
    },
    includeIcon: {
      color: 'brand.green',
    },
    excludeIcon: {
      color: 'brand.red2',
    },
  },
})
