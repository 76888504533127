import { helpers } from './base'

export const TravelerRoomPicker = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    maxTravellerBadge: {
      backgroundColor: 'bg.accent.subtle.default',
      fontSize: 'var(--alto-sem-text-body-sm-font-size)',
      color: 'fg.accent.onSubtle',
      borderRadius: '3xs',
      padding: 'var(--alto-sem-space-2xs) var(--alto-sem-space-xs)',
    },
    popOverContent: {
      boxShadow: 'md',
      borderRadius: 'sm',
    },
    addRoomButtonContainer: {
      button: {
        fontWeight: 'semibold',
      },
    },
  }),
})
