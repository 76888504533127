import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'container',
  'contentContainer',
  'overlayContainer',
  'mainContent',
  'textHeading',
  'textContent',
  'noteContent',
  'richTextOverrides',
])

const inheritOverride = 'inherit !important'
const colorOverrides = {
  p: { color: inheritOverride, fontSize: inheritOverride },
  a: { color: inheritOverride, textDecor: 'underline' },
}

const overlayBackgroundColor = 'rgba(0,0,0,0.7)'

export const CardBanner = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    container: {
      position: 'relative',
      overflow: 'hidden',
      width: 'full',
      marginTop: 10,
      height: 296,
      borderRadius: 'lg',
      ...colorOverrides,
    },
    contentContainer: {
      position: 'absolute',
      height: 'full',
      width: 'full',
      top: 0,
      left: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: { base: 'flex-end', sm: 'center' },
      textAlign: { base: 'right', sm: 'center' },
      justifyContent: 'space-between',
      padding: 4,
    },
    mainContent: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    overlayContainer: {
      padding: { base: 4, sm: 6 },
      background: overlayBackgroundColor,
      borderRadius: 'lg',
    },
    textHeading: {
      fontSize: { base: '3xl', sm: '4xl' },
      fontWeight: 'medium',
    },
    textContent: {
      fontSize: { base: 'sm', sm: 'lg' },
      h3: {
        fontSize: { base: 'lg', sm: '2xl' },
        marginBottom: 2,
      },
    },
    noteContent: {
      alignSelf: 'stretch',
      position: 'relative',
      padding: 4,
      margin: -4,
      marginTop: 0,
      fontSize: { base: 'xs', sm: 'sm' },
      background: 'linear-gradient(to top, rgba(0,0,0,0.5), rgba(0,0,0,0.5), rgba(255,0,0,0))',
    },
  }),
})
