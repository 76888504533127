import { differenceInYears } from 'date-fns'
import { Basket } from '../basket'

const dateRegex = /^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])$/

export const getDifferenceInYears = (dateStr1: string, dateStr2: string) => {
  const datePart1 = dateStr1.slice(0, 10)
  const datePart2 = dateStr2.slice(0, 10)

  if (!datePart1.match(dateRegex) || !datePart2.match(dateRegex)) {
    throw new Error('Invalid date')
  }

  return differenceInYears(new Date(datePart1), new Date(datePart2))
}

export const eighteenOrOlder = '18+'
type EighteenOrOlder = typeof eighteenOrOlder

type IsDateOfBirthValidParams = {
  basket: Basket
  dateOfBirth: string
  expectedAge: number | EighteenOrOlder
}

export const isDateOfBirthValid = ({
  basket,
  dateOfBirth,
  expectedAge,
}: IsDateOfBirthValidParams) => {
  const flightItem = basket.items.FLIGHT[0]
  if (!flightItem) {
    return { isValid: true }
  }

  const outBoundDepartureDate = flightItem.outboundFlightDetails.departureDateTime
  const diffInYears = getDifferenceInYears(outBoundDepartureDate, dateOfBirth)

  const isValid = expectedAge === eighteenOrOlder ? diffInYears >= 18 : diffInYears === expectedAge

  return {
    isValid,
    expected: expectedAge,
    actual: diffInYears,
  }
}
