import { z } from 'zod'
import { ContentfulProductTypeSchema } from './common'
import { ContentfulAmountSchema } from './amount'
import { ContentfulStaticContentSchema } from './staticContent'
import { ContentfulModalContentSchema } from './modal'
import { ContentfulResultsBannerSchema } from './resultsPageBanner'
import { ContentfulHeroSectionSchema } from './hero'

// TEMP: data schema comes from contentful until the promotion service is built and integrated
export const ContentfulPromotionTypeSchema = z.enum([
  'BONUS_AVIOS',
  'ENHANCED_EARN_RATE',
  'ADVERTISEMENT',
])

export type ContentfulPromotionType = z.infer<typeof ContentfulPromotionTypeSchema>

export const ContentfulPromotionDataSchema = z.object({
  product: ContentfulProductTypeSchema,
  type: ContentfulPromotionTypeSchema,
  startDate: z.string(),
  endDate: z.string(),
  minimumSpend: z.array(ContentfulAmountSchema).optional(),
  fulfilmentStartDate: z.string().optional(),
  fulfilmentEndDate: z.string().optional(),
})

export type ContentfulPromotionData = z.infer<typeof ContentfulPromotionDataSchema>
// END OF TEMP

export const ContentfulPromotionComponentsSchema = z.object({
  name: z.string().optional(),
  promotionId: z.string(),
  stickyBannerText: ContentfulStaticContentSchema.optional(),
  searchPageHero: ContentfulHeroSectionSchema.optional(),
  firstVisitModal: ContentfulModalContentSchema.optional(),
  inlineBannerText: ContentfulStaticContentSchema.optional(),
  badgeText: z.string().optional(),
  resultsPageBanner: ContentfulResultsBannerSchema.optional(),
  checkoutModal: ContentfulModalContentSchema.optional(),
  basketSnackBarText: ContentfulStaticContentSchema.optional(),
  confirmationBannerText: ContentfulStaticContentSchema.optional(),
  termsAndConditions: ContentfulStaticContentSchema.optional(),
  promotionData: ContentfulPromotionDataSchema, // REMOVE when promotion service is integrated
})

export type ContentfulPromotionComponents = z.infer<typeof ContentfulPromotionComponentsSchema>
