import React from 'react'

import { useRouter } from 'next/router'
import {
  chakra,
  fadeConfig,
  Box,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  useMultiStyleConfig,
  VisuallyHidden,
  Button,
} from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { convertIconSetToChakraSVG } from '~/utils/Icon.utils'
import { IconName } from '@reward-platform/ancillaries-schemas/common'
import { IconSizes } from '@reward-platform/lift/theme/iconSize'
import { pushEventToDataLayer } from '~/utils/googleTagManager'
import { useAuthContext } from '~/context/auth'
import useIntl from '~/hooks/useIntl/useIntl'
import HeaderMenuItem, { HeaderMenuItemLink, MenuOption, MenuLinkOption } from './HeaderMenuItem'

export type HeaderMenuProps = {
  links: MenuLinkOption[]
}

const HeaderMenu = ({ links }: HeaderMenuProps): JSX.Element => {
  const styles = useMultiStyleConfig('Menu', { variant: 'header' })
  const intl = useIntl()
  const { isAuthenticated: userIsAuthenticated, handleLogin, handleLogout } = useAuthContext()
  const router = useRouter()
  const authMenuProps: MenuOption = userIsAuthenticated
    ? {
        label: intl.formatMessage({
          id: 'log-out',
          defaultMessage: 'Log out',
        }),
        icon: IconName.Enum['log-out'],
        onClick: () => handleLogout(router.asPath),
      }
    : {
        label: intl.formatMessage({
          id: 'login',
          defaultMessage: 'Login',
        }),
        icon: IconName.Enum.login,
        onClick: () => handleLogin(router.asPath),
      }

  if (!links?.length) {
    return (
      <Button variant="primary" sx={styles.headerButton} onClick={authMenuProps.onClick}>
        <Icon as={convertIconSetToChakraSVG(authMenuProps.icon)} boxSize={IconSizes.lg} />
        <chakra.span mr="auto">{authMenuProps.label}</chakra.span>
      </Button>
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const motionProps: any = fadeConfig

  return (
    <Menu variant="header">
      {({ isOpen }) => (
        <>
          {isOpen && <Box as={motion.div} {...motionProps} __css={styles.overlay} cursor="auto" />}
          <MenuButton>
            <VisuallyHidden>Toggle menu</VisuallyHidden>
            <Icon
              as={convertIconSetToChakraSVG(IconName.Enum.hamburger)}
              boxSize={IconSizes.sm}
              color="currentColor"
            />
          </MenuButton>
          <MenuList>
            {links.map((linkOption, i) => (
              <React.Fragment key={linkOption.label}>
                {i > 0 && <MenuDivider />}
                <HeaderMenuItemLink
                  onClick={() => {
                    return pushEventToDataLayer('navigation', {
                      nav_element: 'header navigation',
                      nav_type: 'cta',
                      nav_action: `header ${linkOption.label.toLowerCase()}`,
                      menu_level: `${i + 1}`,
                    })
                  }}
                  {...linkOption}
                />
              </React.Fragment>
            ))}
            <MenuDivider />
            <HeaderMenuItem
              {...authMenuProps}
              onClick={() => {
                pushEventToDataLayer('navigation', {
                  nav_element: 'header navigation',
                  nav_type: 'cta',
                  nav_action: `header ${authMenuProps.label.toLowerCase()}`,
                  menu_level: `${links?.length ?? 1}`,
                })
                return authMenuProps.onClick?.()
              }}
            />
          </MenuList>
        </>
      )}
    </Menu>
  )
}

export default HeaderMenu
