import { helpers } from './base'

export const CancelOrderPaymentForm = helpers.defineMultiStyleConfig({
  baseStyle: {
    paymentFormParentWrapper: {
      h3: {
        fontSize: 'md',
        fontWeight: 'medium',
      },
    },
    paymentCashDueWrapper: {
      'p: first-child': {
        fontSize: 'sm',
      },
      'p: last-child': {
        fontSize: 'lg',
        color: 'fg.secondary',
      },
    },
    stickyButtonDisclaimer: {
      'p: first-child': {
        fontSize: 'var(--alto-sem-text-heading-md-font-size)',
      },
      'p: last-child': {
        color: 'fg.secondary',
      },
    },
  },
})
