/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const FlightCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    flightCardContainer: {
      border: 'solid 1px',
      borderColor: 'brand.greyscale3',
      borderRadius: 'lg',
      boxShadow: 'none',
      backgroundColor: 'brand.white',
    },
    arrowLine: {
      backgroundColor: 'brand.coolgrey10',
    },
    arrowPointer: {
      backgroundColor: 'brand.coolgrey10',
    },
  },
})
