import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'buttonField',
  'styledOption',
  'comboBoxContainer',
  'isVisible',
  'notVisible',
])

const listBoxDisplayCount = 6

export const Combobox = helpers.defineMultiStyleConfig({
  baseStyle: {
    buttonField: {},
    styledOption: {
      color: 'none',
      display: 'flex',
      height: '56px',
      position: 'relative',
      lineHeight: '1.8em',
      borderBottom: 'solid 1px #f1f1f1',
      alignItems: 'center',
      padding: '0 1em',
      justifyContent: 'space-between',

      '      &:hover': {
        background: '#f1f1f1',
      },

      '&[aria-selected="true"]': {
        background: '#f1f1f1',
      },

      '&.disabled': {
        pointeEvents: 'none',

        '&:hover': {
          background: 'none',
        },
      },
    },
    comboBoxContainer: {
      '--list-box-display-count': listBoxDisplayCount,
      gridArea: 'combobox',
      backgroundColor: 'white',
      zIndex: 1,
      width: '100%',
      position: 'absolute',
      minHeight: 'min-content',
      maxHeight: {
        base: 'calc(100vh - 150px)',
        md: `calc(3.5rem * ${listBoxDisplayCount})`,
      },
      overflow: 'auto',
      borderRadius: {
        base: 0,
        md: '5px',
      },
      boxShadow: {
        base: 'unset',
        md: '0 6px 9px 0 rgba(0, 0, 0, 0.1)',
      },
    },
    isVisible: {
      visibility: 'visible',
      opacity: 1,
      transform: 'translateY(0)',
      transition: '0.25s opacity ease, 0.25s visibility step-start, 0.25s transform ease',
    },
    notVisible: {
      visibility: 'hidden',
      opacity: 0,
      transform: 'translateY(-10px)',
      transition: '0.25s opacity ease, 0.25s visibility step-end, 0.25s transform ease',
    },
  },
})
