import { roomMoreDetailsModal } from './base'

export const RoomMoreDetailsModal = roomMoreDetailsModal.defineMultiStyleConfig({
  baseStyle: roomMoreDetailsModal.definePartsStyle({
    heading: {
      mb: 6,
    },
    infoListItem: {
      color: 'brand.alCoolGrey10',
    },
  }),
})
