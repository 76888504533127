import { NotificationMessageType } from '@reward-platform/lift/components/NotificationMessage/NotificationMessage.types'
import { Document } from '@contentful/rich-text-types'

export type Notification = {
  id: string
  message: string
  errorType: NotificationMessageType
  richText?: Document
  buttonLabel?: string
  onButtonClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export type NotificationAction = 'RELOAD'

export type Action = {
  type: string
  id?: string
  notification?: Notification
}

export const notificationReducer = (
  notifications: Notification[],
  action: Action
): Array<Notification> => {
  switch (action.type) {
    case 'remove':
      return notifications.filter((notification) => notification.id !== action.id)
    case 'add':
      return [...notifications, ...(action.notification ? [action.notification] : [])]
    default:
      throw new Error('Unexpected action in notification reducer')
  }
}
