import { helpers } from './base'

export const FeaturesList = helpers.defineMultiStyleConfig({
  baseStyle: {
    listContainer: {
      gap: 8,
      marginTop: 5,
    },
    listItem: {
      columnGap: 4,
      fontSize: 'md',
      color: 'brand.grey6',
    },
    list: {
      'li + li': {
        marginTop: 4,
      },
    },
    includeIcon: {
      color: 'brand.primaryShamrockGreen',
    },
    excludeIcon: {
      color: 'brand.red',
    },
  },
})
