import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const resultsPageTemplate = createMultiStyleConfigHelpers([
  'columns',
  'searchForm',
  'searchSummary',
  'searchTitle',
  'searchSubtitle',
  'styledButton',
  'searchToggle',
  'actions',
  'actionsButton',
  'sortAndFilterActionButton',
  'mapButtonContainer',
  'back',
  'sidebar',
  'results',
  'resultsPagination',
  'resultsList',
  'mapViewWrapper',
  'sortListBox',
  'searchFormWrapperOpen',
  'searchFormWrapperClose',
  'enableSearchText',
  'disableSearchText',
  'loadingState',
  'cardWrapper',
  'skipLink',
  'shareButtonContainer',
])

const searchFormWrapper = {
  gridArea: 'form',
  height: 'fit-content',
  '> *': {
    paddingTop: 'var(--chakra-space-4)',
  },
}

const layoutGutter = 'var(--layout-gutter);'
export const ResultsPageTemplate = resultsPageTemplate.defineMultiStyleConfig({
  baseStyle: resultsPageTemplate.definePartsStyle({
    loadingState: {
      marginBottom: 10,
      height: 'inherit',
    },
    cardWrapper: {
      marginBottom: 10,
    },
    columns: {
      display: 'grid',
      marginBlock: layoutGutter,
      gridRowGap: layoutGutter,
      gridTemplate: {
        base: `
            'back' auto
            'actions' auto
            'results' auto
            / 1fr;
        `,
        sm: `
            'back . actions' auto
            'results results results' auto
            / auto 1fr auto;
        `,
        lg: `
            'back . actions' auto
            'sidebar results results' auto
            / 1fr 2fr 1fr;
        `,
      },
      gridGap: { base: 4, lg: layoutGutter },
      gridColumnGap: { base: 0, lg: 8 },
    },
    searchForm: {
      display: 'grid',
      gridColumn: 'var(--layout-column-wide)',
      gridTemplate: `
          'summary toggle' auto
          'form form' auto /
          1fr max-content
        `,
      gridColumnGap: 4,
      paddingBlock: 4,
      paddingInline: 'max(var(--layout-gutter), calc((100vw - var(--layout-width)) / 2))',
      backgroundColor: 'white',

      boxShadow: '0 6px 9px 0 rgb(0 0 0 / 10%)',
      zIndex: 50,
    },
    searchSummary: {
      gridArea: 'summary',
    },
    searchTitle: {
      fontSize: 'lg',
    },
    searchSubtitle: {
      fontSize: 'sm',
    },
    styledButton: {
      gridArea: 'toggle',
      display: 'grid',
      alignItems: 'center',
      paddingBlock: '0',
      position: 'relative',
      width: 28,
    },
    searchToggle: {
      display: 'flex',
      justifyContent: 'center',
      alignSelf: { base: 'center', md: 'end' },
      textAlign: 'center',
      zIndex: 1,
      '--toggle-transition-in':
        'transform 0.15s ease, opacity 0.15s ease, visibility 0.15s step-start',
      '--toggle-transition-out':
        'transform 0.15s ease, opacity 0.15s ease, visibility 0.15s step-end',
      height: 'unset',

      '> *': {
        gridArea: '1/1',
      },
    },

    searchFormWrapperOpen: {
      ...searchFormWrapper,
      maxHeight: '600px',
      clipPath: 'inset(-100vmax)',
      transition: 'max-height 0.25s ease, clip-path 0.25s step-end',
      visibility: 'visible',
    },
    searchFormWrapperClose: {
      ...searchFormWrapper,
      maxHeight: 0,
      clipPath: 'inset(0)',
      transition: 'max-height 0.15s ease, clip-path 0.25s step-start',
      visibility: 'hidden',
    },
    actions: {
      gridArea: 'actions',
      display: { base: 'flex', lg: 'block' },
      gap: 4,
    },
    mapButtonContainer: {
      position: { base: 'inherit', lg: 'absolute' },
      gridArea: 'actions',
      whiteSpace: 'nowrap',
      display: 'flex',
      flex: '1',
      fontSize: 'sm',
      paddingLeft: 0,
      paddingRight: 0,
      width: { base: 'full', md: 'auto' },
      marginRight: 2,
    },
    sortAndFilterActionButton: {
      minWidth: 40,
      gridArea: 'actions',
      display: 'flex',
      whiteSpace: 'nowrap',
      flex: 1,
      fontSize: 'sm',
      paddingLeft: 0,
      paddingRight: 0,
      width: 'full',
    },
    back: {
      gridArea: 'back',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    numberOfResults: {
      fontSize: { base: 'xs', md: 'sm' },
    },
    sidebar: {
      display: 'flex',
      flexDir: 'column',
      gridArea: 'sidebar',
    },
    results: {
      gridArea: 'results',
    },
    resultsPagination: {
      marginTop: 10,
      display: 'flex',
      justifyContent: 'center',
    },
    resultsList: {
      _first: { marginTop: 0 },
      p: 0,
      listStyleType: 'none',
    },
    mapViewWrapper: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 32,
      overflow: 'hidden',
      borderRadius: 'base',
      marginBottom: 4,
      sx: {
        empty: {
          backgroundColor: '#eee',
          borderRadius: '3px',
          cursor: 'default',
          animation: `
            0% {
                background-color: #eee;
            }
            50% {
              background-color: #ddd;
            }
            100% {
                background-color: #eee;
            }

            2s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955)
          `,
        },
      },
    },
    sortListBox: {
      m: { base: 4, md: 0 },
      cursor: 'default',
    },
    enableSearchText: {
      alignContent: 'center',
      transition: 'var(--toggle-transition-in)',
      transitionDelay: '0.05s',
      display: 'inline',
    },
    disableSearchText: {
      transform: 'translateY(-0.5em) scale(0.9)',
      opacity: 0,
      visibility: 'hidden',
      transition: 'var(--toggle-transition-out)',
      display: 'none',
    },
    skipLink: {
      _focusVisible: {
        paddingX: 4,
        marginBottom: 1,
        position: 'relative',
        outlineStyle: 'solid',
        outlineWidth: 2,
      },
    },
    shareButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row-reverse',
      mr: { base: 0, sm: 4, lg: 0 },
    },
  }),
})
