import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'pickerContainer',
  'pickerLabelContainer',
  'travellerAgeRange',
  'maxTravellerBadge',
  'doneButton',
  'infoMessageWrapper',
  'childrenAgePickerContainer',
  'doneButton',
])

export const FlightTravellerInput = helpers.defineMultiStyleConfig({
  baseStyle: {
    pickerContainer: {
      width: '100%',
      marginTop: 0.5,
      zIndex: 2,
      borderRadius: 4,
      padding: 4,
      fontSize: 'sm',
    },
    infoMessageWrapper: {
      mb: 4,
    },
    pickerLabelContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      flexShrink: 0,
      flexDirection: 'column',
      alignSelf: 'stretch',
    },
    travellerAgeRange: {
      fontWeight: 'light',
      fontSize: 'sm',
      lineHeight: '1.3125rem',
    },
    maxTravellerBadge: {
      textAlign: 'center',
      padding: '0.375rem 0',
      py: 1.5,
      fontSize: 'xs',
      borderRadius: 4,
      mb: 2,
    },
    childrenAgePickerContainer: {
      mt: 6,
      mb: 4,
    },
    doneButton: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      border: 'none',
      alignSelf: 'stretch',
      height: 8,
      marginTop: 6,
      padding: '2 4',
      gap: 4,
      borderRadius: '2xl',
      fontSize: 'var(--chakra-sizes-4)',
      fontWeight: 'medium',
    },
  },
  variants: {
    error: {
      doneButton: {
        opacity: '0.5',
        pointerEvents: 'none',
      },
    },
  },
})
