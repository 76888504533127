/* eslint-disable sonarjs/no-duplicate-string */
import { headerHelpers } from './base'

const firstSpan = {
  fontSize: 'sm',
  fontWeight: 'normal',
}

const lastSpan = {
  fontSize: 'xs',
  fontWeight: 'semibold',
}

export const Header = headerHelpers.defineMultiStyleConfig({
  baseStyle: {
    headerWrapper: {
      boxShadow: 'none',
    },
    logo: {
      height: { base: 'sm', md: '2xl', lg: '41px' },
    },
    cartWrapper: {
      color: 'fg.accent.secondary',
      bg: 'bg.accent.subtle.default',
      borderRadius: '50%',
      width: '44px',
      height: '44px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    userDetails: {
      color: 'fg.accent.secondary',
      '> span': {
        _first: { ...firstSpan },
        _last: { ...lastSpan },
      },
    },
    aviosDetails: {
      color: 'fg.accent.secondary',
      '> span': {
        _first: { ...firstSpan },
        _last: { ...lastSpan },
      },
    },
    basketItemsCount: {
      top: '-0.25rem',
    },
    aviosHeaderWrapper: {
      display: 'flex',
      height: '48px',
      justifyContent: 'center',
      backgroundColor: 'bg.white.vibrant.default',
      zIndex: 89,
      gap: 'var(--alto-sem-space-2xs)',
      boxShadow: '0 6px 9px 0 rgba(0, 0, 0, 0.1)',
    },
    headerItem: {
      display: 'flex',
      alignItems: 'center',
      p: 'var(--alto-sem-space-xs) var(--alto-sem-space-md)',
    },
    header: {
      borderBottom: 'var(--alto-sem-border-width-sm) solid var(--alto-sem-color-border-tertiary)',
    },
    aviosHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: { base: 'center', sm: 'flex-end' },
      flexDirection: 'row',
      width: '100%',
      maxWidth: '1280px',
      p: '0 1rem',
      gap: 3,

      '> a': {
        height: 'full',
        _hover: {
          textDecoration: 'none',
          bg: 'bg.inverse.subtle.hover',
          borderRadius: 'xs',
        },
      },
    },
  },
})
