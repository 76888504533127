import { crossSellingCardHelper } from './base'

export const CrossSellingCard = crossSellingCardHelper.defineMultiStyleConfig({
  baseStyle: {
    cardBody: {
      borderRadius: 'xs',
      _hover: {
        backgroundColor: 'bg.layer1.default',
      },
    },
    content: {
      '*': {
        fontSize: 'sm',
      },

      h2: {
        fontSize: 'var(--alto-sem-text-heading-xs-font-size)',
      },
    },
    image: {
      borderRadius: 'xs',
    },
  },
})
