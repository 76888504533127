import { z } from 'zod'
import { CurrencyCodesSchema } from '../country-currency-codes'

export const AmountSchema = z.object({
  amount: z.number(),
  currencyCode: CurrencyCodesSchema,
})

export const PriceSchema = z
  .object({
    priceId: z.string(),
  })
  .catchall(AmountSchema)

export const PriceLadderSchema = z.object({
  aviosPrice: z.number(),
  cashPrice: z.number(),
})

export const PackageTotalPriceSchema = z.array(PriceSchema)

export type Amount = z.infer<typeof AmountSchema>
export type Price = z.infer<typeof PriceSchema>
export type PriceLadder = z.infer<typeof PriceLadderSchema>
