import { helpers } from './base'

export const ResultsMapView = helpers.defineMultiStyleConfig({
  baseStyle: {
    results: {
      backgroundColor: 'brand.white',
    },

    resultItem: {
      transition: { base: 'none', lg: 'transform 0.25s ease' },
      '&[data-selected="true"]': {
        '> div': {
          transition: 'box-shadow 0.25s ease',
          transitionDelay: '0.3s',
          boxShadow: '0 8px 0 rgb(38 113 211/80%)',
        },
      },
    },
    filtersButton: {
      color: 'brand.primary.midnightBlue',
      fontSize: 'xs',
      fontWeight: 'light',
      borderRadius: 'sm',
    },
    button: {
      borderRadius: 'sm',
    },
  },
})
