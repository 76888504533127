import { helpers } from './base'

export const MeetingPoints = helpers.defineMultiStyleConfig({
  baseStyle: {
    mainContainer: {
      borderRadius: 'sm',
      boxShadow: 'brand.boxShadow',
      backgroundColor: 'brand.white',
      padding: 6,
    },
    secondaryHeader: {
      fontSize: 'md',
      fontWeight: 'semibold',
      mb: 4,
    },
    mainTitle: {
      fontSize: '2xl',
      fontWeight: 'light',
    },
    locationGroup: {
      mt: 4,

      p: {
        fontSize: 'md',
      },
    },
  },
})
