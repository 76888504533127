import { helpers } from './base'

export const ChakraPopover = helpers.defineMultiStyleConfig({
  baseStyle: {
    arrow: {
      borderColor: 'brand.coolgrey2',
    },
    content: {
      bg: 'brand.white',
      color: 'brand.black',
      borderColor: 'brand.coolgrey2',
    },
  },
})
