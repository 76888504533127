import { helpers } from './base'

export const TravelerQuantityPicker = helpers.defineMultiStyleConfig({
  baseStyle: {
    minMax: {
      fontWeight: 'normal',
      fontSize: 'md',
    },
    traveler: {
      fontWeight: 'normal',
    },
    travelerType: {
      fontWeight: 'semibold',
    },
    listItem: {
      fontSize: 'md',
      fontWeight: 'normal',
    },
  },
  variants: {
    error: {
      listItem: {
        '&:first-of-type': {
          border:
            'var(--alto-sem-border-width-sm) solid var(--alto-sem-color-bg-critical-vibrant-default)',
          color: 'bg.critical.vibrant.default',
          bg: 'bg.critical.subtle.default',
        },
      },
      errorMessageBox: {
        color: 'alto.sem.color.border.critical',
      },
    },
  },
})
