import { helpers } from './base'

const borderRadiusCircle = '50%'
const borderRadiusStart = '50% 0 0 50%'
const borderRadiusEnd = '0 50% 50% 0'

const colors = {
  primaryBlue: 'brand.primary.blue',
  highlightBlue: 'brand.highlight.blue',
  midnightBlue: 'brand.primary.midnightBlue',
  greyBg: '#fafafa',
}

const selectedDateCircle = {
  position: 'relative',
  backgroundColor: colors.primaryBlue,
  borderRadius: `${borderRadiusCircle} !important`,
}

const hoverDateCircle = {
  position: 'relative',
  backgroundColor: colors.highlightBlue,
  borderRadius: `${borderRadiusCircle} !important`,
}

const focusDateCircle = {
  position: 'relative',
  backgroundColor: colors.highlightBlue,
  borderRadius: `${borderRadiusCircle} !important`,
  outlineStyle: 'solid',
  outlineColor: colors.primaryBlue,
  outlineOffset: 0,
}

const selectedRangeBackground = {
  content: '""',
  display: 'block',
  width: '100%',
  height: '100%',
  position: 'absolute',
  left: '0',
  top: '0',
  zIndex: '-1',
  backgroundColor: colors.greyBg,
}

export const DatePicker = helpers.defineMultiStyleConfig({
  baseStyle: {},
})

export const DatePickerGlobalTheme = {
  '.react-datepicker': {
    boxShadow: {
      base: 'unset',
      md: 'brand.boxShadow',
    },
    borderRadius: 'sm',
  },
  '.react-datepicker__current-month, .react-datepicker-time__header': {
    fontSize: '2xl',
    fontWeight: 'light',
  },

  '.react-datepicker__navigation': {
    ':hover, :focus-visible, :focus': {
      boxShadow: 'unset !important',
      border: 'none',
      color: colors.highlightBlue,
      '*': {
        color: colors.highlightBlue,
      },
    },
    ':focus-visible, :focus': {
      border: 'solid',
      borderWidth: 1,
      borderRadius: 'sm',
      borderColor: colors.primaryBlue,
    },
  },
  '.react-datepicker__navigation-icon': {
    _before: {
      borderColor: colors.midnightBlue,
      height: '16px',
      width: '11px',
      top: 2.5,
      borderRadius: '1px 0 1px 0',
    },
  },
  '.react-datepicker__navigation--previous': {
    top: {
      base: '15px',
      md: '20px',
    },
  },

  '.react-datepicker__navigation--next': {
    top: '20px',
  },

  '.react-datepicker__input-container': {
    '.date-picker-from__label, .date-picker-to__label': {
      color: 'brand.baseGray.100',
      fontSize: 'xs',
    },
    input: {
      fontSize: 'md',
      borderColor: 'brand.coolgrey4',
      borderRadius: 'sm',

      _focus: {
        boxShadow: `0 0 2px var(--chakra-colors-brand-primary-blue)`,
        borderColor: colors.primaryBlue,

        '& + .date-picker-to__label, + .date-picker-from__label': {
          color: colors.primaryBlue,
        },
      },
    },
  },
  '.react-datepicker__year-read-view--down-arrow': {
    top: '2px',
    borderColor: colors.primaryBlue,
  },
  '.react-datepicker__year-dropdown': {
    top: '64px',
  },
  '.react-datepicker__navigation--years-previous': {
    _before: {
      borderColor: colors.midnightBlue,
    },
  },
  '.react-datepicker__navigation--years-upcoming': {
    _before: {
      borderColor: colors.midnightBlue,
    },
  },
  '.react-datepicker__day--selected': {
    ...selectedDateCircle,
    backgroundColor: colors.primaryBlue,
    _hover: hoverDateCircle,
  },
  '.react-datepicker__day--in-range': {
    position: 'relative',
    backgroundColor: colors.greyBg,
    '&::after': selectedRangeBackground,
  },
  '.react-datepicker__day--in-selecting-range': {
    position: 'relative',
    backgroundColor: colors.greyBg,
    _hover: hoverDateCircle,
    '&::after': selectedRangeBackground,
  },
  '.react-datepicker__month-text--selected': {
    backgroundColor: colors.primaryBlue,
    _hover: hoverDateCircle,
  },
  '.react-datepicker__month-text--in-selecting-range': {
    backgroundColor: colors.primaryBlue,
    _hover: hoverDateCircle,
  },
  '.react-datepicker__month-text--in-range': {
    backgroundColor: colors.primaryBlue,
    _hover: hoverDateCircle,
  },
  '.react-datepicker__quarter-text--selected': {
    backgroundColor: colors.primaryBlue,
    _hover: hoverDateCircle,
  },
  '.react-datepicker__quarter-text--in-selecting-range': {
    backgroundColor: colors.primaryBlue,
    _hover: hoverDateCircle,
  },
  '.react-datepicker__quarter-text--in-range': {
    backgroundColor: colors.primaryBlue,
    _hover: hoverDateCircle,
  },
  '.react-datepicker__year-text--selected': {
    backgroundColor: colors.primaryBlue,
    _hover: hoverDateCircle,
  },
  '.react-datepicker__day--keyboard-selected': {
    ...selectedDateCircle,
    _hover: hoverDateCircle,
  },
  '.react-datepicker__day--range-start, .react-datepicker__day--selecting-range-start': {
    ...selectedDateCircle,
    _hover: hoverDateCircle,
    '&::after': {
      ...selectedRangeBackground,
      borderRadius: borderRadiusStart,
    },
  },
  '.react-datepicker__day--range-end': {
    ...selectedDateCircle,
    _hover: {
      backgroundColor: colors.primaryBlue,
      borderRadius: borderRadiusCircle,
    },
    '&::after': {
      ...selectedRangeBackground,
      borderRadius: borderRadiusEnd,
    },
  },
  '.react-datepicker__day': {
    _hover: hoverDateCircle,
    _focusVisible: focusDateCircle,
  },

  // Make border radius to first and last elements

  // First
  '.react-datepicker__day--in-range:first-of-type, .react-datepicker__day--in-selecting-range:first-of-type, .react-datepicker__day--in-range.react-datepicker__day--001, .react-datepicker__day--in-selecting-range.react-datepicker__day--001':
    {
      borderRadius: borderRadiusStart,
      '&::after': { borderRadius: borderRadiusStart },
    },

  // Last
  '.react-datepicker__day--in-range:last-of-type, .react-datepicker__day--in-selecting-range:last-of-type, .react-datepicker__day--in-range.react-datepicker__day--031, .react-datepicker__day--in-selecting-range.react-datepicker__day--031':
    {
      borderRadius: borderRadiusEnd,
      '&::after': { borderRadius: borderRadiusEnd },
    },

  '.react-datepicker__week:not(:has(.react-datepicker__day--031))': {
    '.react-datepicker__day--in-range.react-datepicker__day--030, .react-datepicker__day--in-selecting-range.react-datepicker__day--030':
      {
        borderRadius: borderRadiusEnd,
        '&::after': { borderRadius: borderRadiusEnd },
      },
  },
}
