import { helpers } from './base'

export const WarningMessage = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    wrapper: {
      border: 'var(--chakra-borders-1px) var(--chakra-colors-brand-alertPink)',
      gap: 4,
      padding: 4,
      color: 'brand.alertPink',
      borderRadius: 'sm',
    },
    content: {
      rowGap: 2,
    },
    title: {
      fontSize: 'xs',
    },
    body: {
      fontSize: 'md',
    },
    iconColor: {
      color: 'brand.alertPink',
    },
  }),
})
