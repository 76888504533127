import { useQuery, useQueryClient } from '@tanstack/react-query'
import { BaseOrderItem, Order, OrderItemType } from '@reward-platform/order-schemas/order'
import * as R from 'ramda'
import { getOrder } from '~/services/ordersService'

const QUERY_KEY = 'order'

// filter out failed items in the order
const filterFailedItems = (order: Order) => {
  const orderItems = order.items

  const filteredOrderItems =
    orderItems &&
    Object.keys(orderItems).reduce((acc, itemType) => {
      if (!acc) {
        return undefined
      }

      const orderItemType = itemType as OrderItemType
      const itemsOfType = orderItems[orderItemType] as unknown as BaseOrderItem[]
      return {
        ...acc,
        [orderItemType]: itemsOfType.filter((item) => item.status === 'READY_TO_PROCEED'),
      }
    }, {} as Order['items'])

  return R.assocPath(['items'], filteredOrderItems, order)
}

const useOrder = (orderId?: string, filterOutFailedItems?: boolean) => {
  const queryClient = useQueryClient()
  return {
    orderData: useQuery({
      queryKey: [QUERY_KEY, orderId],
      queryFn: () =>
        getOrder(orderId ?? '').then((data) => {
          if (filterOutFailedItems) {
            return filterFailedItems(data)
          }
          return data
        }),
      staleTime: 0,
      gcTime: 10,
      retry: 1,
      enabled: Boolean(orderId),
      meta: {
        notifyUser: false,
        errorMessage: 'Orders Error - could not get order',
      },
    }),
    invalidate: () =>
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY],
      }),
  }
}

export default useOrder
