import { useEffect } from 'react'
import { hotjar } from 'react-hotjar'
import { isProduction } from '~/utils/envChecks'

export type HotjarConfig = {
  HJID: number
  HJSV: number
} | null

export const getHotjarConfig = (): HotjarConfig => {
  const maybeHJID = process.env.NEXT_PUBLIC_HOTJAR_ID
  const maybeHJSV = process.env.NEXT_PUBLIC_HOTJAR_SNIPPET_VERSION

  return maybeHJID && maybeHJSV ? { HJID: +maybeHJID, HJSV: +maybeHJSV } : null
}

const useHotjar = () => {
  useEffect(() => {
    if (!isProduction()) {
      return
    }

    const config = getHotjarConfig()

    if (!config) {
      // eslint-disable-next-line no-console
      console.warn('HOTJAR is missing environment variables and will not be initialized')
      return
    }

    const { HJID, HJSV } = config

    hotjar.initialize(HJID, HJSV)
  }, [])
}

export default useHotjar
