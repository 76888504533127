import { priceBreakdownRowItemHelper } from './base'

const buttonStyle = {
  border: 'none',
  p: 2,
  bg: 'brand.alpha.blue',
  borderRadius: 'sm',
}

const activeBorder = {
  outline: 'var(--chakra-borders-1px)  var(--chakra-colors-brand-primary-blue)',
}

export const PriceBreakdownRowItem = priceBreakdownRowItemHelper.defineMultiStyleConfig({
  baseStyle: priceBreakdownRowItemHelper.definePartsStyle({
    priceBreakdownRow: {
      fontSize: 'xs',
      fontWeight: 'normal',
    },
    priceBreakdownRowLine: {
      '> div': {
        ...buttonStyle,
        '> span': {
          _first: {
            fontWeight: 'light',
          },
        },
      },
      button: {
        ...buttonStyle,
        '> span': {
          _first: {
            fontWeight: 'light',
          },
        },
        cursor: 'pointer',
        _hover: {
          ...activeBorder,
        },
        _focus: {
          ...activeBorder,
        },
      },
    },
    priceBreakdownCollapsible: {
      button: {
        ...buttonStyle,
        border: 'none',
        cursor: 'pointer',
        _hover: {
          ...activeBorder,
        },
        _focus: {
          ...activeBorder,
        },
        '> span': {
          color: 'brand.primary.midnightBlue',
          _first: {
            fontWeight: 'light',
          },
        },
      },
      '> div': {
        color: 'brand.primary.blue',
        p: 2,
        pt: 3,
      },
      _expanded: {
        bg: 'brand.white',
        rounded: 'sm',
        outline: 'var(--chakra-borders-1px)  rgba(var(--chakra-colors-rgb-blue), 0.3)',
        button: {
          bg: 'brand.white',
          outline: 'revert',
        },
      },
    },
  }),
})
