/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const StatusBadge = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      px: 'xs',
      py: '2xs',
      borderRadius: '3xs',
      color: 'fg.primary',
    },
    label: {
      lineHeight: 'var(--alto-sem-text-label-sm-line-height)',
      fontSize: 'var(--alto-sem-text-label-sm-font-size)',
      fontWeight: 500,
    },
  },
  variants: {
    READY_TO_PROCEED: {
      container: {
        bg: 'bg.success.subtle.default',
        color: 'fg.success.onSubtle',
      },
    },
    REMOVED: {
      container: {
        bg: 'bg.inverse.subtle.default',
        color: 'fg.inverse.onSubtle',
        svg: {
          w: 3,
        },
      },
    },
    DELIVERED: {
      container: {
        bg: 'bg.information.subtle.default',
        color: 'fg.information.onSubtle',
      },
    },
    SUSPENDED: {
      container: {
        bg: 'bg.warning.subtle.default',
        color: 'fg.warning.onSubtle',
      },
    },
    UNABLE_TO_DELIVER: {
      container: {
        bg: 'bg.critical.subtle.default',
        color: 'fg.critical.onSubtle',
      },
    },
    IN_PROGRESS: {
      container: {
        bg: 'bg.warning.subtle.default',
        color: 'fg.warning.onSubtle',
      },
    },
    UNKNOWN: {
      container: {
        bg: 'bg.inverse.subtle.default',
        color: 'fg.inverse.onSubtle',
      },
    },
  },
})
