import { helpers } from './base'

export const TicketOptions = helpers.defineMultiStyleConfig({
  baseStyle: {
    ticketOptions: {
      gap: 5,
      marginBottom: 8,
    },
    ticketOptionsResults: {
      gap: 5,
    },
  },
})
