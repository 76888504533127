/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const HotelSummary = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      gridGap: 5,
      paddingBottom: 10,
      backgroundColor: 'brand.white',
      borderRadius: 'sm',
    },
    heading: {
      fontSize: {
        lg: '3.5rem',
        base: '2.75rem',
      },
      fontWeight: 'light',
    },
    address: {
      fontSize: 'xs',
      lineHeight: 6,
    },
  },
})
