import { helper } from './base'

const basketTotal = {
  flexDirection: { base: 'row', md: 'column' },
}

const insufficientFunds = {
  color: 'brand.alertPink',
}

export const PriceBreakdownTotalDue = helper.defineMultiStyleConfig({
  baseStyle: helper.definePartsStyle({
    priceTotalSummaryWrapper: {
      'p > span-of-type(2)': {
        border: 'var(--chakra-space-px) solid  var(--chakra-colors-brand-highlight-blue)',
      },
    },
    priceTotalWrapper: {
      flexDirection: 'column',
      '&[data-value="basketTotal"]': {
        ...basketTotal,
      },
    },
    currencyPair: {
      span: {
        _first: {
          fontSize: 'xs',
        },
        _last: {
          fontFamily: 'var(--chakra-fonts-heading)',
          fontSize: { base: 'xl', md: '4xl' },
          color: 'brand.primary.blue',
        },
      },
      _invalid: {
        span: {
          ...insufficientFunds,
        },
      },
    },
    heading: {
      fontWeight: 'light',
    },
    collectedAvios: {
      textAlign: 'right',
    },
  }),
  variants: {},
})
