import { helpers } from './base'

export const SearchHotelForm = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {},
    destination: {
      boxShadow: { base: 'none', md: 'brand.boxShadow' },
      borderRadius: 'sm',
    },
  },
})
