/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const ReplaceExperienceItemCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    content: {
      color: 'fg.primary',
    },
    wrapper: {
      borderRadius: 'xs',
    },
    heading: {
      color: 'fg.accent.primary',
      fontSize: 'var(--alto-sem-text-heading-xs-font-size)',
      lineHeight: 'var(--alto-sem-text-heading-xs-line-height)',
      letterSpacing: 'var(--alto-sem-text-heading-xs-letter-spacing)',
      fontWeight: 'bold',
    },
  },
})
