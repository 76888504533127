import { AxiosError } from 'axios'
import { ErrorResponseSchema } from '@reward-platform/shared-schemas'
import { GenericClientError } from './GenericClientError'
import { ERROR_CODES } from './clientErrors'

export class ApiRouteRequestError extends GenericClientError {
  constructor(axiosError: AxiosError) {
    super(ERROR_CODES.API_ROUTE, axiosError)

    if (axiosError.response) {
      this.httpStatus = axiosError.response.status
    }
    if (axiosError.code) {
      this.code = axiosError.code
    }
    this.originalError = axiosError

    const parsedResponse = ErrorResponseSchema.safeParse(axiosError.response?.data)
    if (parsedResponse.success) {
      const { developerMessage, businessMessage, code, validationErrors, unrecoverable } =
        parsedResponse.data
      if (developerMessage) {
        this.developerMessage = developerMessage
      }
      if (businessMessage) {
        this.businessMessage = businessMessage
      }
      if (code) {
        this.code = code
      }
      this.validationErrors = validationErrors
      this.fatal = unrecoverable ?? false
    }
  }
}
