export const colors = {
  // Greyscale
  White: '#ffffff',
  CloudWhite: '#f9f9f9',
  Greyscale3: '#dddddd',
  Greyscale4: '#cccccc',
  Grey5: '#999999',
  Grey6: '#4d4d4d',
  Grey: '#333333',
  Black: '#2c2a29',

  // Cool Greys
  CoolGrey1: '#f1f1f1',
  CoolGrey2: '#d0d0ce',
  CoolGrey4: '#bbbcbc',
  CoolGrey8: '#888b8d',
  CoolGrey10: '#63666a',

  // Teals greens
  PrimaryTeal: '#006272',
  Teal10: '#e5eff0',
  Teal5: '#f2f7f8',
  Teal: '#00788c',
  TealDarker: '#004e5b',

  Shamrock5: '#f9fbf2',
  PrimaryShamrockGreen: '#84bd00',
  LinkButton: '#999900',
  CityGreen: '#12ad9e',
  DarkCityGreen: '#008578',
  DarkGreen: '#008578',

  // Reds
  Red: '#c55050',
  Red5: '#fcf6f6',

  // Blues
  SkyBlue5: '#f2f9fc',
  SkyBlue: '#0095cb',
  ExtraLegroom: '#4dd0e1',
  SeaBlue: '#0067b9',

  // Orange
  Orange5: '#fef8f2',
  Orange: '#ffcc00',

  // What's up with these?
  GreyDisabled: '#d8f1f0',
  QuantityIcons: '#0da398',
  Secondary1: '#0da398',
}

export default colors

export const vuPrimaryTeal = '#006272'
export const vuBlack = '#2c2a29'
export const vuPrimaryShamrockGreen = '#84bd00'
export const vuDarkCityGreen = '#008578'
export const vuOrange = '#ffcc00'
export const vuOrangeDark = '#E5B700'
export const vuOrangeLight = '#FFEA99'
export const vuOrange5 = '#fef8f2'

export const vuSecondary1 = '#0da398'
export const vuYellow = '#f2cc06'
export const vuBoxShadow = '0 6px 9px 0 rgba(0, 0, 0, 0.1)'

export const vuGrey = '#333333'
export const vuGrey6 = '#4d4d4d'
export const vuGrey5 = '#999999'

export const vuRed = '#c55050'
export const vuRed5 = '#fcf6f6'

export const vuWhite = '#ffffff'
export const vuCoolGrey1 = '#f1f1f1'
export const vuCoolGrey2 = '#d0d0ce'
export const vuCoolGrey4 = '#bbbcbc'
export const vuCoolGrey8 = '#888b8d'
export const vuCoolGrey10 = '#63666a'

export const vuGreyscale1 = '#f7f7f7'
export const vuGreyscale2 = '#F3F3F3'
export const vuGreyscale3 = '#dddddd'
export const vuGreyscale4 = '#cccccc'

export const vuCloudWhite = '#f9f9f9'

export const vuQuantityIcons = '#0da398'
export const vuGreyDisabled = '#d8f1f0'
export const vuLinkButton = '#999900'

export const vuSecondary4Dark = '#aa2827'
export const vuSecondary1Light = '#dbf1f0'
export const vuSecondary5 = '#f67706'
