/* eslint-disable sonarjs/no-duplicate-string */
import { replaceItemModalHelper } from './base'

export const ReplaceItemModal = replaceItemModalHelper.defineMultiStyleConfig({
  baseStyle: {
    card: {
      borderRadius: 'xs',
      bg: 'bg.layer2.default',
      boxShadow: 'md',
    },
    optionHeading: {
      fontSize: 'var(--alto-sem-text-heading-sm-font-size)',
      lineHeight: 'var(--alto-sem-text-heading-sm-line-height)',
      letterSpacing: 'var(--alto-sem-text-heading-sm-letter-spacing)',
      fontWeight: 'semibold',
      color: 'fg.accent.secondary',
    },
    instructions: {
      p: {
        _first: {
          fontWeight: 'medium',
        },
      },
    },
    promptText: {
      color: 'fg.primary',
      fontSize: 'sm',
    },
    buttonContainer: {
      button: {
        fontSize: 'md',
      },
    },
    button: {
      _first: {
        _hover: {
          backgroundColor: 'bg.base',
        },
      },
    },
  },
})
