/* eslint-disable sonarjs/no-duplicate-string */
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'container',
  'checkoutSummary',
  'titleContainer',
  'title',
  'itemsContainer',
  'items',
  'optionItem',
  'information',
])
export const FlightPaymentOptions = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      position: 'relative',
      borderRadius: '0.5rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'center',
      boxShadow: '0 0.5rem 2rem 0 rgba(0, 0, 0, 0.29)',
      width: '18.375rem',
      fontSize: '1.125rem',
      fontWeight: '500',
    },
    checkoutSummary: {
      display: 'flex',
      padding: '1rem 1rem 1.5rem 1rem',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      gap: '1rem',
      alignSelf: 'stretch',
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '0.625rem',
      alignSelf: 'stretch',
    },
    title: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      flex: '1 0 0',
    },
    itemsContainer: {
      display: 'flex',
      padding: '1rem 0.625rem 1rem 0rem',
      width: '100%',
      alignItems: 'flex-start',
      gap: '0.5rem',
      borderRadius: '1rem',
      whiteSpace: 'nowrap',
    },
    items: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0rem 1.125rem',
      alignItems: 'flex-start',
      gap: '1.5rem',
      whiteSpace: 'nowrap',
    },
    optionItem: {
      display: 'grid',
      width: '100%',
      gridTemplateColumns: '1fr 1.25rem',
      gridTemplateRows: 'auto',
      gridTemplateAreas: ` 'c1 c2' `,
    },
    information: {
      display: 'flex',
      padding: '0.625rem',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '1rem',
      alignSelf: 'stretch',
      borderRadius: '0.5rem',
      fontSize: '0.875rem',
      fontWeight: '400',
    },
  },
  variants: {
    mobile: {
      container: {
        boxShadow: 'none',
        width: '100%',
        borderRadius: '1.5rem',
      },
      itemsContainer: {
        paddingTop: 0,
        paddingBottom: 0,
        fontSize: '1.125rem',
        fontWeight: '400',
      },
      items: {
        gap: '0.625rem',
      },
      information: {
        borderTop: '0.0625rem solid',
        borderRadius: '0rem',
      },
    },
  },
})
