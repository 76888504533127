/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const RoomsBanner = helpers.defineMultiStyleConfig({
  baseStyle: {
    bedIcon: {
      color: 'brand.cityGreen',
    },
    closeButton: {
      color: 'brand.black',
    },
    heading: {
      fontWeight: 500,
    },
    description: {
      fontWeight: 400,
    },
    confirmButton: {
      color: 'brand.black',
      borderColor: 'brand.primaryTeal',
      fontWeight: 500,
      fontSize: 'sm',
      borderRadius: '3xl',
      background: 'transparent',
    },
    container: {
      borderRadius: 'lg',
    },
  },
  variants: {
    static: {
      container: {
        background: 'brand.cityGreen5',
        borderColor: 'brand.primaryTeal',
      },
    },
    floating: {
      container: {
        background: 'brand.cityGreen5',
        borderColor: 'brand.primaryTeal',
      },
    },
  },
})
