/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const RouteInfo = helpers.defineMultiStyleConfig({
  baseStyle: {
    bulletPoint: {
      color: 'fg.accent.primary',
    },
    title: {
      color: 'fg.accent.onVibrant',
      bg: 'fg.accent.primary',
    },
    infoTitle: {
      color: 'fg.secondary',
    },
    infoText: {
      color: 'fg.secondary',
    },
    contentTitle: {
      color: 'fg.primary',
    },
    contentBody: {
      color: 'fg.secondary',
    },
  },
})
