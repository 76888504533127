import { helpers } from './base'

export const PricingAndSearchCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      backgroundColor: 'brand.coolgrey1',
      borderRadius: 'base',
      gap: 4,
      padding: 4,
    },
  },
})
