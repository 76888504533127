import {
  alPrimaryTeal,
  alTeal5,
  alRed,
  alWhite,
  alOrange,
  alOrange5,
} from '@reward-platform/lift/theme/aer_lingus/variables'
import { helpers } from './base'

export const Label = helpers.defineMultiStyleConfig({
  variants: {
    success: {
      labelContainer: {
        color: alPrimaryTeal,
        backgroundColor: alTeal5,
      },
    },
    error: {
      labelContainer: {
        color: alRed,
        backgroundColor: alWhite,
      },
    },
    warning: {
      labelContainer: {
        color: alOrange,
        backgroundColor: alOrange5,
      },
    },
  },
  defaultProps: {
    variant: 'success',
  },
})
