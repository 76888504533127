import { helpers } from './base'

export const CarDetails = helpers.defineMultiStyleConfig({
  baseStyle: {
    searchTitle: {
      color: 'brand.primary.midnightBlue',
    },
    pageTitle: {
      fontSize: '2.75rem',
      fontWeight: 'light',
      lineHeight: 'short',
    },
    searchSummaryWrapper: {
      boxShadow: 'none',
      borderBottom: '1px',
      borderColor: 'brand.baseGray.10',
    },
  },
})
