import { defineStyleConfig } from '@chakra-ui/react'

export const PriceBreakdownCollectedAviosAmount = defineStyleConfig({
  baseStyle: {
    color: 'brand.primary.blue',
    bg: 'brand.baseGray.5',
    fontSize: 'sm',
    borderRadius: 'lg',
    border: 'var(--chakra-space-px) solid  var(--chakra-colors-brand-primary-blue)',
    p: 2,
  },
})
