import { acceptedCardsHelper } from './base'

export const AcceptedCards = acceptedCardsHelper.defineMultiStyleConfig({
  baseStyle: acceptedCardsHelper.definePartsStyle({
    acceptedCardWrapper: {
      bg: 'brand.teal10',
      borderRadius: 4,
    },
  }),
})
