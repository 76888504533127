import { ComponentStyleConfig } from '@chakra-ui/react'

export const MaintenanceMode: ComponentStyleConfig = {
  baseStyle: {
    gap: 12,
    m: 12,
    height: '90vh',

    display: 'flex',
    flexDirection: { base: 'column', md: 'row' },
    textAlign: { base: 'center', md: 'unset' },

    img: {
      width: { base: '50%', md: '15rem' },
    },
    h1: {
      fontSize: '2xl',
      fontWeight: 'semibold',
    },
    h2: {
      fontSize: 'md',
      fontWeight: 'normal',
    },
  },
}
