import { DevCycleUser } from '@devcycle/react-client-sdk'
import { PartnerConfig } from '~/schemas/partner'

const UNAUTHENTICATED_DVC_USER_ID = 'unauthenticated_user'

const UNAUTHENTICATED_DVC_USER: DevCycleUser = {
  isAnonymous: true,
  user_id: UNAUTHENTICATED_DVC_USER_ID,
}

export const isDevCycleUserUnauthenticated = (user: DevCycleUser): boolean =>
  user.user_id === UNAUTHENTICATED_DVC_USER_ID

export const getDefaultDevCycleUser = (partner: PartnerConfig): DevCycleUser => ({
  ...UNAUTHENTICATED_DVC_USER,
  customData: {
    partner: partner.domain,
  },
})
