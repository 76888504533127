/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const FeaturesList = helpers.defineMultiStyleConfig({
  baseStyle: {
    listContainer: {
      gap: 14,
      marginTop: 10,
    },
    mainContainer: {
      borderRadius: 'sm',
      backgroundColor: 'bg.layer2.default',
      padding: 'lg',
      boxShadow: 'md',
    },
    title: {
      fontSize: 'var(--alto-sem-text-heading-sm-font-size)',
      lineHeight: 'var(--alto-sem-text-heading-sm-line-height)',
      color: 'fg.accent.secondary',
      fontWeight: 'medium',
    },
    listItem: {
      columnGap: 'md',
      fontSize: 'md',
      color: 'fg.primary',
    },
    list: {
      flex: 1,
      'li + li': {
        marginTop: 'md',
      },
    },
    includeIcon: {
      color: 'fg.success.primary',
    },
    excludeIcon: {
      color: 'fg.critical.primary',
    },
  },
})
