import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const itemCardImageHelper = createMultiStyleConfigHelpers(['imageWrapper'])

export const ItemCardImage = itemCardImageHelper.defineMultiStyleConfig({
  baseStyle: itemCardImageHelper.definePartsStyle({
    imageWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gridArea: 'image',
      m: 4,
      img: {
        _first: {
          objectFit: 'cover',
        },
      },
    },
  }),
  sizes: {
    sm: {
      imageWrapper: {
        m: 0,
        img: {
          height: 52,
        },
      },
    },
    md: {},
    lg: {
      imageWrapper: {
        img: {
          _first: {
            height: '100%',
          },
        },
      },
    },
  },
  defaultProps: {
    size: 'lg',
  },
})
