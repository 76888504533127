import { z } from 'zod'
import { Document } from '@contentful/rich-text-types'

export const ContentfulCurrencyCodesSchema = z.enum(['EUR', 'GBP', 'USD', '999'])

export const ContentfulProductTypeSchema = z.enum(['HOTEL', 'EXPERIENCE', 'CAR_HIRE', 'FLIGHT'])

export type ContentfulProductType = z.infer<typeof ContentfulProductTypeSchema>

export const ContentfulDynamicVariableSchema = z.object({
  key: z.string(),
  defaultValue: z.string().optional(),
})

export type ContentfulDynamicVariable = z.infer<typeof ContentfulDynamicVariableSchema>

export const ContentfulRichTextDocumentSchema = z.any().transform((x) => x as Document)
