import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'container',
  'toggleContainer',
  'titleIcon',
  'title',
  'subtitle',
  'buttonIcon',
  'toggleButton',
  'toggleContent',
])

export const Dropdown = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      width: 'fit-content',
      display: 'flex',
      flexDirection: 'column',
      rowGap: 2,
      position: 'relative',
    },
    toggleContainer: {
      display: 'flex',
      gap: 4,
      alignItems: 'center',
    },
    subtitle: {
      ml: 2,
    },
    toggleButton: {
      minWidth: 64,
      height: 10,
      justifyContent: 'space-between',
      padding: 'var(--chakra-space-2) var(--chakra-space-4) !important',
      borderRadius: 'lg',
      fontSize: 'lg',
      alignItems: 'flex-end',

      '> span': {
        ml: 4,
      },
    },
    toggleContent: {
      display: 'flex',
      backgroundColor: 'brand.white',
      minWidth: 64,
      maxWidth: 64,
      ml: 10,
      border: 'var(--chakra-space-px) solid var(--chakra-colors-brand-primaryTeal)',
      borderRadius: 'lg',
      padding: 4,
      position: 'absolute',
      zIndex: 'dropdown',
      top: 12,
      overflowX: 'hidden',
    },
  },
})
