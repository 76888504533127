import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'wrapper',
  'content',
  'title',
  'body',
  'iconColor',
])

export const WarningMessage = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    wrapper: {
      borderRadius: 'lg',
      textAlign: 'left',
    },
    content: {
      flexDirection: 'column',
    },
    title: {
      margin: 0,
    },
    body: {
      margin: 0,
    },
    iconColor: {},
  }),
})
