import { priceBreakdownStickyHelpers } from './base'

export const PriceBreakdownSticky = priceBreakdownStickyHelpers.defineMultiStyleConfig({
  baseStyle: {
    stickyWrapper: {
      borderRadius: 'xs',
      boxShadow: '0px 10px 15px 10px rgba(0, 0, 0, 0.1)',
      color: 'fg.accent.primary',
      backgroundColor: 'bg.layer2.default',
    },
    priceContainer: {
      span: {
        _first: {
          fontSize: 'var(--alto-sem-text-heading-sm-font-size)',
          fontWeight: 'semibold',
        },
        _last: {
          fontSize: 'sm',
          fontWeight: 'normal',
          color: 'fg.accent.secondary',
        },
      },
    },
    priceLabel: {
      _invalid: {
        color: 'fg.critical.primary',
      },
    },
  },
})
