import { helpers } from './base'

const midnightBlue = 'brand.primary.midnightBlue !important'

export const PopularAmenities = helpers.defineMultiStyleConfig({
  baseStyle: {
    iconWrapper: {
      color: midnightBlue,
    },
    amenityText: {
      color: midnightBlue,
      fontWeight: 'light',
    },
  },
})
export const PopularAmenitiesList = helpers.defineMultiStyleConfig({
  baseStyle: {
    iconColor: {
      color: midnightBlue,
    },
  },
})
