// * Note: default messages do not work any more
// * Note: link doesn't work any more, because we expected a string but now its ReactNode[]
//    * Note: but there is no way of debugging this because we cant pass defaultMessages on local
// * Note: https://formatjs.io/docs/react-intl/api/#definemessagesdefinemessage
// * Note: cant fall back to defaultMessage - https://formatjs.io/docs/react-intl/api/#message-formatting-fallbacks

import React, { ReactNode } from 'react'
import { chakra } from '@chakra-ui/react'
// eslint-disable-next-line no-restricted-imports
import { IntlShape, MessageDescriptor, useIntl as useReactIntl } from 'react-intl'
import { Options as IntlMessageFormatOptions } from 'intl-messageformat'

const TO_TAG = {
  OPEN: '<to>',
  CLOSE: '</to>',
}

export const replaceToTag = (link: string | ReactNode[]): JSX.Element => {
  const target = link.toString()

  const betweenTo = /(<to>)(.*)(<\/to>)/g
  const matches = target.match(betweenTo)

  if (matches === null || matches.length !== 1) {
    throw new Error('Link tags should have exactly one `to` tag')
  }

  const rawHref = matches[0]
  const linkText = target.replace(rawHref, '')

  const href = rawHref.replace(TO_TAG.OPEN, '').replace(TO_TAG.CLOSE, '')

  return <a href={href}>{linkText}</a>
}

const useIntl = (): IntlShape => {
  const intl = useReactIntl()

  const formatMessage = (
    descriptor: MessageDescriptor,
    value: Record<string, string | ReactNode>,
    opts: IntlMessageFormatOptions
  ): ReactNode =>
    intl.formatMessage<JSX.Element>(
      descriptor,
      {
        ...value,
        bold: (...chunks) => <chakra.strong>{chunks}</chakra.strong>,
        /** Supports usage of Links
            <link><to>/somewhere/cool</to>Somewhere Cool</link>
                                    \/
            <a href="/somewhere/cool">Somewhere Cool</a>
        */
        link: (...link) => replaceToTag(link),
        /** the <to> tag here is kept in here because otherwise react-intl will throw a formatting error. But we still need it because it is used in the context of the<link> tag.
         */
        to: (...to) => `<to>${to}</to>`,
        p: (...chunks) => <p>{chunks}</p>,
        br: (...chunks) => <br />,
      },
      opts
    )

  return {
    ...intl,
    formatMessage: formatMessage as IntlShape['formatMessage'],
  }
}

export default useIntl
