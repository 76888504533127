import { helpers } from './base'

export const DetailsPageNav = helpers.defineMultiStyleConfig({
  baseStyle: {
    wrapper: {
      boxShadow: 'none',
      borderBottom: '1px',
      borderColor: 'brand.baseGray.10',
    },
  },
  variants: {
    experiment: {
      wrapper: {
        boxShadow: '0 6px 9px 0 rgba(0,0,0,0.1)',
        fontWeight: 'semibold',
        fontSize: 'sm',
        display: 'flex',
        alignItems: 'center',
        height: 16,
      },
    },
  },
})
