/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable import/no-named-as-default */
import { ComponentMultiStyleConfig } from '@chakra-ui/react'
import { merge } from 'lodash'
import typography from '../../../theme/vueling/typography'
import { Pagination as base } from './base'

export const Pagination: ComponentMultiStyleConfig = merge({}, base, {
  baseStyle: {
    arrow: {
      color: 'brand.black',

      _hover: {
        color: 'brand.yellow',
      },
    },
    ellipses: {
      color: 'brand.black',
      fontWeight: typography.weights.medium,
    },
    pip: {
      color: 'brand.black',
      border: '2px solid',
      borderColor: 'brand.yellow',
      fontWeight: typography.weights.medium,

      _hover: {
        color: 'brand.orange',
        borderColor: 'brand.orange',
      },

      '&[aria-current="true"]': {
        backgroundColor: 'brand.yellow',

        _hover: {
          color: 'brand.black',
          backgroundColor: 'brand.orange',
          borderColor: 'brand.orange',
        },
      },
    },
  },
})
