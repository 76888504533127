import { IncomingMessage } from 'node:http'
import { NextApiRequest } from 'next'
import { GenericException } from '@reward-platform/shared-api/exceptions/GenericException'
import { createServerLoggerMeta } from '@reward-platform/logger/utils'
import logger from '@reward-platform/logger/server/next'
import 'winston'

export function logRequestToDatadog(
  req: NextApiRequest | IncomingMessage,
  error?: GenericException,
  additionalData = {}
) {
  const loggerRequestContext = createServerLoggerMeta(req)
  const requestMessagePrefix = error ? 'NextServer Error' : 'NextServer Request'
  const getRequestMessage = (msg?: string) => `${requestMessagePrefix}: ${req.url} ${msg}`
  if (error) {
    const { message, ...loggerErrorContext } = error.formatErrorForLogs()

    loggerRequestContext.http = {
      ...loggerRequestContext.http,
      status_code: loggerErrorContext.error.httpStatus,
    }

    // Logs to datadog logging or to console if non production
    // Logs to dd error tracking, can check error here https://app.datadoghq.eu/apm/error-tracking
    logger.error(getRequestMessage(message), {
      ...loggerRequestContext,
      ...loggerErrorContext,
      additionalData,
    })
  } else {
    logger.info(getRequestMessage(), { ...loggerRequestContext, additionalData })
  }
}

export { logger }
