import { chakraDefinitionGroupHelper } from './base'

export const ChakraDefinitionGroup = chakraDefinitionGroupHelper.defineMultiStyleConfig({
  baseStyle: {
    chakraDefinitionGroupWrapper: {
      dl: {
        fontSize: 'sm',
      },
      dd: {
        fontSize: 'md',
      },
    },
  },
})
