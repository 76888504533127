import { cardHelpers } from './base'

export const Card = cardHelpers.defineMultiStyleConfig({
  baseStyle: cardHelpers.definePartsStyle({
    container: {
      backgroundColor: 'brand.white',
      border: 'none',
      borderRadius: 'lg',
      boxShadow: 'brand',
      __hover: {
        boxShadow: '10px 10px 0 0 rgba(229, 239, 240, 1)',
      },
    },
  }),
  variants: {
    elevated: {},
  },
  sizes: {
    sm: {},
    md: {},
  },
})
