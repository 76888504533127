import { alWhite } from '@reward-platform/lift/theme/aer_lingus/variables'
import { FlightBreakdownHelper } from './base'

export const FlightBreakdown = FlightBreakdownHelper.defineMultiStyleConfig({
  baseStyle: {
    flightBreakdown: {
      borderRightColor: {
        xl: alWhite,
      },
    },
  },
})
