import { helpers } from './base'

const midnightBlue = 'var(--chakra-colors-brand-primary-midnightBlue)'

export const PolicyPage = helpers.defineMultiStyleConfig({
  baseStyle: {
    hero: {
      picture: {
        _after: {
          opacity: 1,
          backgroundColor: 'rgba(0,0,0, 0.167832)',
          backgroundImage:
            'linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.34965) 46%, rgba(0, 0, 0, 0.5) 100%)',
        },
      },
    },
    container: {
      h1: {
        fontSize: {
          base: '2.75rem',
          md: '3.5rem',
        },
      },
      h2: {
        fontSize: {
          base: '2.25rem',
          md: '2.5rem',
        },
      },
      h3: {
        fontSize: '1.5rem',
      },
      h4: {
        fontSize: {
          base: '1.125rem',
          md: '1.375rem',
        },
      },
      p: {
        fontSize: {
          base: 'sm',
          md: 'lg',
        },
        marginBottom: 7,
      },
      li: {
        paddingLeft: 6,
        _before: {
          w: 2.5,
          h: 2.5,
          marginTop: 1.5,
          background: 'brand.primary.midnightBlue',
        },
      },
      a: {
        fontSize: {
          base: '0.875rem',
          md: '1rem',
        },
        color: 'brand.highlight.blue',
        textDecoration: 'underline',
        fontWeight: 'semibold',
        _hover: {
          textDecoration: 'none',
        },
      },
    },
    accordion: {
      '*': {
        color: `${midnightBlue} !important`,
      },
    },
  },
})
