import { fieldSetHelper } from './base'

export const Fieldset = fieldSetHelper.defineMultiStyleConfig({
  baseStyle: fieldSetHelper.definePartsStyle({
    fieldset: {
      bg: 'brand.white',
      boxShadow: 'brand.boxShadow',
      p: 10,
    },
    visibleLegend: {
      fontSize: '2xl',
    },
  }),
})
