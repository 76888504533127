/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const CarCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    carCardWrapper: {
      color: 'fg.secondary',
      fontWeight: 'normal',
      border: 'none',
      boxShadow: 'md',
      backgroundColor: 'bg.layer2.default',
      borderRadius: 'xs',
    },
    heading: {
      fontSize: 'var(--alto-sem-text-heading-xs-font-size)',
      lineHeight: 'var(--alto-sem-text-heading-xs-line-height)',
      letterSpacing: 'var(--alto-sem-text-heading-xs-letter-spacing)',
      fontWeight: 'bold',
      color: 'fg.accent.primary',
    },
    name: {
      fontSize: 'sm',
    },
    attributeListItem: {
      fontSize: 'sm',
    },
    footerContainer: {
      gap: 'xs',
      marginTop: 'auto',
    },
    cancellationFeesAndPoliciesContainer: {
      h1: {
        fontSize: 'var(--alto-sem-text-heading-sm-font-size)',
        my: { base: 'sm', lg: 'lg' },
        fontWeight: 'medium',
        color: 'fg.accent.secondary',
      },
      strong: {
        fontWeight: 'medium',
      },
      p: {
        fontSize: {
          base: 'sm',
          lg: 'md',
        },
      },
    },
    cancellationButton: {
      fontWeight: 'semibold',
      _hover: {
        border: 'none',
      },
      _focus: {
        border: 'none',
      },
      _focusVisible: {
        border: 'none',
      },
    },
    policyLink: {
      color: 'fg.accent.primary',
      fontSize: {
        base: 'var(--alto-sem-text-body-sm-font-size)',
        lg: 'var(--alto-sem-text-body-md-font-size)',
      },

      _hover: {
        textDecoration: 'none',
      },
    },
    duration: {
      fontSize: 'sm',
    },
    secondaryAttributeWrapper: {
      bg: 'bg.accent.subtle.default',
      borderRadius: 'xs',
    },
    fuelBadge: {
      fontSize: 'sm',
      fontWeight: 'normal',
      backgroundColor: 'bg.accent.subtle.default',
      color: 'fg.accent.secondary',
      marginBottom: '2xs',
      borderRadius: '5xs',

      ' > div': {
        columnGap: 'xs',
      },

      svg: {
        width: 6,
        height: 6,
      },
    },

    electricBadge: {
      svg: {
        color: 'fg.success.primary',
      },
    },

    hybridBadge: {
      svg: {
        color: 'fg.information.onSubtle',
      },
    },
    checkIcon: {
      color: 'bg.success.vibrant.default',
    },
  },
  variants: {
    details: {
      mainAttributeList: {
        li: {
          mb: 'xs',
        },
      },
    },
  },
})
