import {
  ListAccountsAcrossProgrammesResponseAccounts,
  ListAccountsAcrossProgrammesResponseAccount,
} from '@reward-platform/accounts-api-client'
import { nextApiClient } from './clients/nextApiClient'

// TODO: clean this up, can just use function underneath in the api handler with a query, they're only ever used together
export async function getMyAccounts(): Promise<ListAccountsAcrossProgrammesResponseAccounts> {
  const { data } = await nextApiClient.get(`/user/accounts`)
  return data
}

export function selectTotalAccount(
  accountsRes: ListAccountsAcrossProgrammesResponseAccounts
): ListAccountsAcrossProgrammesResponseAccount | undefined {
  return (
    accountsRes.accounts?.find((account) => account.accountType === 'TOTAL_REWARD') || undefined
  )
}
