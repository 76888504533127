import { helpers } from './base'

export const PricingAndSearchCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      borderRadius: 'sm',
      boxShadow: 'brand.boxShadow',
      backgroundColor: 'brand.white',
      gap: 6,
      padding: 6,
    },
    title: {
      fontSize: 'xs',
      fontWeight: 'normal',
    },
  },
})
