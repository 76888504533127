import { helpers } from './base'

export const RoomDetailsCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      boxShadow: 'unset',
      border: '1px',
      borderColor: 'brand.greyscale3',
    },
    infoListItem: {
      color: 'brand.grey6',
    },
  },
})
