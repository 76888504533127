import { z } from 'zod'
import { ContentfulRichTextDocumentSchema } from './common'

const ContentfulDisruptionMessageTypeSchema = z.enum(['disruptionMessage'])

export const ContentfulDisruptionMessageSchema = z.object({
  notificationMessage: ContentfulRichTextDocumentSchema.optional(),
  message: z.string().optional(),
  contentType: ContentfulDisruptionMessageTypeSchema.optional(),
})

export type ContentfulDisruptionMessage = z.infer<typeof ContentfulDisruptionMessageSchema>
