import { z } from 'zod'
import { IconName } from './icons'

export const CheckInDetailsSchema = z.object({
  checkInStartTime: z.string().optional(),
  checkInEndTime: z.string().optional(),
  checkoutTime: z.string().optional(),
  minCheckInAge: z.number().optional(),
  instructions: z.array(z.string()).optional(),
})

export const AmenitiesGroupSchema = z.object({
  type: z.string().optional(),
  title: z.string(),
  amenities: z.array(z.string()).optional(),
  formattedContent: z.array(z.string()).optional(),
  checkInDetails: CheckInDetailsSchema.optional(),
  variant: z.string().optional(),
  partnerCode: z.string().optional(),
})

export const PopularAmenityIconSchema = z.object({
  id: z.string(),
  name: z.string(),
  icon: IconName,
})

export type CheckInDetails = z.infer<typeof CheckInDetailsSchema>
export type AmenitiesGroup = z.infer<typeof AmenitiesGroupSchema>
export type PopularAmenity = z.infer<typeof PopularAmenityIconSchema>
export type PopularAmenityIcon = z.infer<typeof PopularAmenityIconSchema>
