/* eslint-disable sonarjs/no-duplicate-string */
import { priceBreakdownRowItemHelper } from './base'

const buttonStyle = {
  bg: 'bg.layer1.default',
  borderRadius: 'xs',
  padding: '2xs',
  fontSize: 'xs',
  lineHeight: 'var(--alto-sem-text-body-xs-line-height)',
  color: 'fg.accent.onSubtle',
}

const styledItem = {
  p: '2xs',
  borderRadius: 'xs',
  bg: 'bg.accent.subtle.default',
  alignItems: 'center',
  '&:not(:last-of-type)': {
    mb: '2xs',
  },
  '> span': {
    '&:nth-of-type(1)': {
      color: 'fg.accent.onSubtle',
      fontWeight: 'normal',
      fontSize: 'xs',
    },
    '&:nth-of-type(2)': {
      color: 'fg.accent.primary',
      fontWeight: 'semibold',
      fontSize: 'var(--alto-sem-text-heading-sm-font-size)',
    },
  },
}

export const PriceBreakdownRowItem = priceBreakdownRowItemHelper.defineMultiStyleConfig({
  baseStyle: {
    priceBreakdownRowTitleLine: {
      fontSize: 'xs',
      fontWeight: 'semibold',
      lineHeight: 'var(--alto-sem-text-body-xs-line-height)',
      color: 'fg.accent.secondary',
    },
    priceBreakdownRowLine: {
      '> div': {
        ...buttonStyle,
        '> span': {
          _last: {
            fontWeight: 'semibold',
          },
        },
      },
      button: {
        ...buttonStyle,
        border: 'none',
        cursor: 'pointer',
        '> span': {
          _first: {
            fontSize: 'xs',
            textAlign: 'left',
          },
          _last: {
            fontWeight: 'semibold',
          },
        },
      },
    },
    priceBreakdownCollapsible: {
      button: {
        ...buttonStyle,
        border: 'none',
        cursor: 'pointer',
        '> span': {
          _first: {
            fontSize: 'xs',
          },
          _last: {
            fontWeight: 'semibold',
          },
        },
      },
      '> div': {
        color: 'fg.accent.onSubtle',
        fontSize: 'xs',
        p: '2xs',
        pt: 0,
      },
      _expanded: {
        bg: 'bg.accent.subtle.active',
        rounded: 'xs',
        button: {
          bg: 'bg.accent.subtle.active',
        },
      },
    },
    dueNow: {
      mt: 'sm',
      '> div:last-of-type > div': {
        ...styledItem,
      },
    },
    priceBreakdownBox: {
      mt: 'sm',
      bg: 'bg.layer2.default',
      p: '2xs',
      border: '1px solid',
      borderColor: 'border.tertiary',
      borderRadius: 'xs',
      '> div:first-of-type': {
        mb: '2xs',
        color: 'fg.primary',
        button: {
          m: 0,
        },
      },
      '> div:last-of-type > div': {
        '> div': {
          ...styledItem,
        },
      },
    },
  },
})
