import { cardHelpers } from './base'

export const Card = cardHelpers.defineMultiStyleConfig({
  baseStyle: cardHelpers.definePartsStyle({
    container: {
      border: 'none',
      backgroundColor: 'bg.base',
      boxShadow: 'md',
      borderRadius: 'xs',
      __hover: {
        boxShadow: 'md',
      },
    },
  }),
})
