import { helpers } from './base'

const primary = 'brand.primaryTeal'
export const FlightDetailsModal = helpers.defineMultiStyleConfig({
  baseStyle: {
    timeContainer: {
      strong: {
        color: primary,
      },
    },
    iconContainer: {
      '.chakra-icon': {
        color: primary,
      },
      hr: {
        borderColor: primary,
      },
    },
  },
})
