import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers(['title', 'container', 'description'])

export const ExperienceInformation = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      color: 'black',
    },
    description: {
      whiteSpace: 'pre-wrap',
      marginTop: 6,
    },
  },
})
