import { forwardRef } from 'react'
import {
  Icon,
  Box,
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
  FormControl,
  FormErrorMessage,
  useCheckbox,
} from '@chakra-ui/react'
import { IconSizes } from '../../theme/iconSize'
import { convertIconSetToChakraSVG } from '../Icon/Icon.utils'

const IconWrapper = ({ isChecked }: { isChecked?: boolean }) =>
  isChecked ? (
    <Box
      m="0 2px"
      h="100%"
      __css={{
        '> svg': {
          h: '100%',
          w: '100%',
        },
      }}
    >
      <Icon
        pointerEvents="none"
        as={convertIconSetToChakraSVG('check')}
        boxSize={IconSizes.xxxl}
        color="currentColor"
      />
    </Box>
  ) : null

export type CheckboxProps = Pick<
  ChakraCheckboxProps,
  'isChecked' | 'onChange' | 'name' | 'id' | 'children' | 'tabIndex' | 'value'
> & {
  error?: string
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(({ error, ...props }, ref) => {
  return (
    <FormControl isInvalid={Boolean(error)}>
      <ChakraCheckbox ref={ref} icon={<IconWrapper />} {...props} />
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  )
})
