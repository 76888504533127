import NextLink from 'next/link'
import { Box, chakra, Icon, Link, useMultiStyleConfig } from '@chakra-ui/react'
import { convertIconSetToChakraSVG } from '~/utils/Icon.utils'
import { IconName } from '@reward-platform/ancillaries-schemas/common'
import { IconSizes } from '@reward-platform/lift/theme/iconSize'

export type HeaderBasketItemProps = {
  basketItemsCount: number
  isAviosTheme?: boolean
}

export const HeaderBasketItem = ({ basketItemsCount, isAviosTheme }: HeaderBasketItemProps) => {
  const styles = useMultiStyleConfig('Header', {})

  return (
    <Link
      as={NextLink}
      href="/orders/payment-options"
      aria-label={basketItemsCount > 0 ? `view basket (${basketItemsCount})` : 'view basket'}
      tabIndex={0}
    >
      <Box
        __css={isAviosTheme ? styles.headerItem : styles.cartWrapper}
        data-testid="view-basket-cart"
      >
        {isAviosTheme && <chakra.span mr="2xs">Basket</chakra.span>}
        {basketItemsCount > 0 ? (
          <>
            <Icon
              as={convertIconSetToChakraSVG(IconName.Enum['filled-cart'])}
              boxSize={isAviosTheme ? IconSizes.sm : IconSizes.xl}
              fill="currentColor"
            />
            <chakra.p data-testid="basket-items-count" __css={styles.basketItemsCount}>
              {basketItemsCount}
            </chakra.p>
          </>
        ) : (
          <Icon
            as={convertIconSetToChakraSVG(IconName.Enum['empty-cart'])}
            boxSize={isAviosTheme ? IconSizes.sm : IconSizes.xl}
            fill="currentColor"
          />
        )}
      </Box>
    </Link>
  )
}
