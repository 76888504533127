import { ComponentMultiStyleConfig } from '@chakra-ui/react'
import { merge } from 'lodash'
import { ImageCard as base } from './base'

export const ImageCard: ComponentMultiStyleConfig = merge({}, base, {
  baseStyle: {
    caption: {
      p: {
        color: 'brand.coolgrey8',
      },
    },
  },
})
