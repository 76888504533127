import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'buttonContainter',
  'paymentFormParentWrapper',
  'paymentCashDueWrapper',
  'buttonWrapper',
  'stickyButtonDisclaimer',
])

export const CancelOrderPaymentForm = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    paymentFormParentWrapper: {
      h3: {
        pb: 8,
      },
    },
    paymentCashDueWrapper: {
      display: 'grid',
      gridTemplateColumns: '20ch 1fr',
      pb: 4,
    },
    buttonWrapper: {
      display: 'flex',
      flexDirection: 'column',
      bg: { base: 'brand.white', md: 'none' },
      bottom: {
        base: '-1px',
        md: 'unset',
      },
      position: {
        base: 'sticky',
        md: 'relative',
      },
      p: { base: 4, md: 0 },
      transition: 'all 0.2s ease-in-out',
      boxShadow: { base: '0px -6px 9px 0px rgba(0, 0, 0, 0.1)', md: 'none' },
      zIndex: { base: 1, md: 'unset' },
    },
    stickyButtonDisclaimer: {
      display: { base: 'block', md: 'none' },
    },

    buttonContainer: {
      display: 'flex',
      flexDirection: { base: 'column-reverse', md: 'row' },
      justifyContent: { base: 'center', md: 'flex-end' },
      gap: 4,
      mt: 4,
      button: {
        display: 'flex',
        w: { base: '100%', md: '30%' },
        pl: 4,
        pr: 4,
      },
    },
  }),
})
