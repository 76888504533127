import { ComponentMultiStyleConfig } from '@chakra-ui/react'
import { merge } from 'lodash'
import { typography } from '../../../theme/aer_lingus/typography'
import { Pagination as base } from './base'

const primaryTeal = 'brand.primaryTeal'
const cityGreen = 'brand.cityGreen'

export const Pagination: ComponentMultiStyleConfig = merge({}, base, {
  baseStyle: {
    arrow: {
      color: primaryTeal,

      _hover: {
        color: cityGreen,
      },
    },
    ellipses: {
      color: primaryTeal,
      fontWeight: typography.weights.medium,
    },
    pip: {
      color: primaryTeal,
      border: '2px solid',
      borderColor: primaryTeal,
      fontWeight: typography.weights.medium,

      _hover: {
        color: cityGreen,
        borderColor: cityGreen,
      },

      '&[aria-current="true"]': {
        color: 'brand.white',
        backgroundColor: primaryTeal,
        _hover: {
          backgroundColor: cityGreen,
          borderColor: cityGreen,
        },
      },
    },
  },
})
