import { helpers } from './base'

export const HotelRooms = helpers.defineMultiStyleConfig({
  baseStyle: {
    tripDetails: {
      color: 'brand.grey6',
    },
    heading: {
      fontSize: 'lg',
    },
  },
})
