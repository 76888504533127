import { z } from 'zod'
import { ContentfulRichTextDocumentSchema } from './common'
import { ContentfulHeroSectionSchema } from './hero'

const ContentfulStaticPageContentTypeSchema = z.enum(['staticPage'])

export const ContentfulAccordionSchema = z.object({
  accordionButton: z.string().optional(),
  accordionPanel: ContentfulRichTextDocumentSchema.optional(),
})

export const ContentfulStaticPageContentSchema = z.object({
  name: z.string().optional(),
  identifier: z.string().optional(),
  hero: ContentfulHeroSectionSchema.optional(),
  staticContent: ContentfulRichTextDocumentSchema.optional(),
  thumbnail: z.string().optional(),
  accordion: z.array(ContentfulAccordionSchema).optional(),
  contentType: ContentfulStaticPageContentTypeSchema.optional(),
})

export type ContentfulStaticPageContent = z.infer<typeof ContentfulStaticPageContentSchema>
