import { formatDevLogs, getDatadogLoggerBaseMeta, isProductionLogger } from '../utils'

type StatusType = 'error' | 'info' | 'debug' | 'warn' | 'critical' | 'alert'

type InputObject = { [key: string]: any }

const DEFAULT_META = { label: 'reward-platform' }

const createNextServerLogger = () => {
  const formatLoggerContent = (obj: InputObject, debugDev = true) => {
    return isProductionLogger() ? JSON.stringify(obj) : formatDevLogs(obj, debugDev)
  }

  const log = (message: string, obj: { level?: StatusType } & InputObject) => {
    const logs = getDatadogLoggerBaseMeta(obj)

    // eslint-disable-next-line no-console
    console.log(formatLoggerContent({ ...DEFAULT_META, message, ...logs }))
  }

  const info = (message: string, obj: InputObject) => {
    log(message, { level: 'info', ...obj })
  }

  const warn = (message: string, obj: InputObject) => {
    log(message, { level: 'warn', ...obj })
  }

  const error = (message: string, obj: InputObject) => {
    log(message, { level: 'error', ...obj })
  }

  const debug = (message: string, obj: InputObject) => {
    log(message, { level: 'debug', ...obj })
  }

  return { log, info, warn, error, debug }
}

export default createNextServerLogger()
