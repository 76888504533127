import Big from 'big.js'
import { BasketItem, OnlyCashPaymentOption, PaymentOptions } from '../basket'
import { Balance } from './types'

export const convertOnlyCashPaymentOptionToBalance = (po: OnlyCashPaymentOption): Balance => ({
  aviosCollect: po.aviosCollectValue,
  aviosSpent: 0,
  cashTotal: new Big(po.cashTotalValue.amount),
  cashBase: new Big(po.cashBaseValue.amount),
  grandTotal: po.grandTotal ? new Big(po.grandTotal?.amount ?? 0) : undefined,
  cashTaxes: po.salesTax ? new Big(po.salesTax.amount) : new Big(po.cashTaxValue?.amount ?? 0),
  cashTotalWithoutTaxes: new Big(po.cashTotalValue?.amount ?? 0),
  isSalesTaxType: !!po.salesTax,
  surcharges: {
    total: new Big(0),
    surchargesBreakdown: [],
  },
  taxDetails: po.taxDetails,
})

export const convertAviosOptionToBalance = (
  po: PaymentOptions['options'][number],
  isSalesTax: boolean
): Balance => ({
  aviosCollect: 0,
  aviosSpent: po.aviosValue,
  cashTotal: new Big(po.cashValue?.amount ?? 0),
  grandTotal: po.grandTotal ? new Big(po.grandTotal?.amount ?? 0) : undefined,
  cashBase: new Big(po.cashBaseValue?.amount ?? 0),
  cashTaxes: new Big(po.cashTaxValue?.amount ?? 0),
  cashTotalWithoutTaxes: new Big(po.cashValue?.amount ?? 0),
  isSalesTaxType: isSalesTax,
  surcharges: {
    total: new Big(0),
    surchargesBreakdown: [],
  },
  taxDetails: po.taxDetails,
})

export const getBalance = (
  paymentOptions: PaymentOptions,
  optionId: PaymentOptions['options'][number]['id']
) => {
  if (paymentOptions.onlyCashPaymentOption) {
    const isCashOnlyOption = paymentOptions.onlyCashPaymentOption.id === optionId

    if (isCashOnlyOption) {
      return convertOnlyCashPaymentOptionToBalance(paymentOptions.onlyCashPaymentOption)
    }
  }

  const isAviosOption = paymentOptions.options.find((o) => o.id === optionId)
  if (isAviosOption) {
    return convertAviosOptionToBalance(isAviosOption, paymentOptions.isSalesTaxType)
  }

  return undefined
}

export const getSurchargesTotal = (item: BasketItem): Big => {
  return item.surcharges.reduce((sAcc, s) => sAcc.plus(s.amount), new Big(0))
}
