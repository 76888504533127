import { ComponentMultiStyleConfig, keyframes } from '@chakra-ui/react'

const flyUp = keyframes`
    from {
      opacity: 0;
      transform: translateY(3rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
`

export const MobileModal: ComponentMultiStyleConfig = {
  parts: ['mobileModal', 'closeButton', 'heading'],
  baseStyle: {
    mobileModal: {
      zIndex: 1000,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyItems: 'center',
      padding: {
        base: '1rem',
        md: '2rem',
      },
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      overflow: 'auto',
      backgroundColor: 'white',
      animation: `${flyUp} ease-in-out 0.2s forwards`,
    },
    closeButton: {
      cursor: 'pointer',
      width: '2.5rem',
      height: '2.5rem',
      alignSelf: 'end',
      padding: '0.5rem',
      marginBottom: '0.5rem',
      background: 'inherit',
      border: 'inherit',
      position: 'relative',
      zIndex: 'modal',
      '> svg': {
        pointerEvents: 'none',
      },
    },
    heading: {
      fontWeight: 600,
      marginTop: '1.5rem',
      marginBottom: '2.5rem',
    },
  },
}
