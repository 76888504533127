/* eslint-disable sonarjs/no-duplicate-string */
import { carItemExtrasPopoverHelper } from './base'

export const CarItemExtrasPopover = carItemExtrasPopoverHelper.defineMultiStyleConfig({
  baseStyle: {
    extrasIcon: {
      backgroundColor: 'fg.accent.primary',
    },
    carExtraWrapper: {
      '> div > section > div': {
        borderRadius: 'xs',
      },
      li: {
        bg: 'bg.layer1.default',
        borderRadius: 'md',
      },
    },
    extrasButton: {
      borderRadius: 'xs',
      color: 'fg.secondary',
      span: {
        fontWeight: 'normal',
      },
      strong: {
        fontWeight: 'semibold',
      },
    },
  },
})
