import { ComponentSingleStyleConfig } from '@chakra-ui/react'

export const TextMarker: ComponentSingleStyleConfig = {
  baseStyle: {
    wrapper: {
      position: 'absolute',
      left: '50%',
      bottom: 'calc(100% + var(--chakra-space-2))',
      width: 'max-content',
      px: 1,
      py: 0.5,
      border: 'var(--chakra-space-px) solid var(--chakra-colors-brand-white)',
      background: 'brand.black',
      color: 'brand.white',
      textAlign: 'center',
      fontSize: 'xs',
      cursor: 'pointer',
      transition: 'font-size 0.25s ease',

      '&[data-selected="true"]': {
        zIndex: 1,
        transitionDelay: '0.3s',
        fontSize: 'sm',
      },

      _before: {
        content: '""',
        position: 'absolute',
        top: '100%',
        left: '50%',
        border: 'var(--chakra-space-1-5) solid transparent',
        transform: 'translateX(-50%)',
        borderTopColor: 'brand.white',
        borderWidth: 2,
      },

      _after: {
        content: '""',
        position: 'absolute',
        top: '100%',
        left: '50%',
        border: 'var(--chakra-space-1-5) solid transparent',
        borderTopColor: 'brand.black',
        transform: 'translateX(-50%)',
      },
    },
  },
}
