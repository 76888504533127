import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const layoutGutter = 'var(--layout-gutter)'

export const helpers = createMultiStyleConfigHelpers([
  'container',
  'content',
  'scroller',
  'heading',
  'caption',
])

export const IntroSection = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      '--intro-image-min-size': '10rem',
      '--intro-image-max-size': '18.75rem',
      display: { base: 'block', md: 'grid' },
      gridTemplateAreas: { md: `'content images'` },
      gridTemplateColumns: { md: '1fr 1fr' },
      gridGap: { md: '1rem' },
      gridColumn: { sm: 'var(--layout-column-center)' },
      gridColumnGap: { md: layoutGutter },
    },
    content: {
      gridArea: 'content',
      h3: {
        fontWeight: 'normal',
      },
      'p + p': {
        marginTop: 4,
      },
    },
    scroller: {
      gridArea: 'images',
      overflow: 'auto',
      scrollBehavior: 'smooth',
      scrollbarWidth: 'none',
      scrollSnapType: 'x proximity',

      display: { base: 'block', md: 'grid' },
      gridAutoFlow: { md: 'column' },
      // eslint-disable-next-line sonarjs/no-duplicate-string
      gridGap: { sm: '1.5rem' },
      gridAutoColumns: {
        sm: 'minmax(var(--intro-image-min-size), var(--intro-image-max-size))',
      },

      '::-webkit-scrollbar': {
        display: { base: 'none' },
      },

      '> *': {
        width: { base: '300px', sm: '80vw' },
        maxWidth: { base: '24rem', sm: 'none' },
        scrollSnapAlign: { base: 'center', sm: 'none' },
      },

      '> *:first-child': {
        scrollSnapAlign: { base: 'start', sm: 'none' },
        paddingLeft: { base: layoutGutter, sm: 'none' },
      },

      '> *:last-child': {
        scrollSnapAlign: { base: 'end', sm: 'none' },
        paddingRight: { base: layoutGutter, sm: 'none' },
      },
    },
  },
})
