import { helpers } from './base'

export const Menu = helpers.defineMultiStyleConfig({
  variants: {
    header: {
      button: {
        color: 'brand.primary.midnightBlue',
        _active: {
          color: 'white',
        },
        _focusVisible: {
          outlineStyle: 'solid',
        },
      },
      divider: {
        color: 'brand.baseGray.25',
      },
      item: {
        _focusVisible: {
          bgColor: 'brand.baseGray.5',
        },
      },
      list: {
        rounded: 'none',
        boxShadow: 'brand.boxShadow',
      },
    },
  },
})
