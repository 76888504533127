import React, { ReactNode, useReducer, useMemo, useCallback } from 'react'
import useIntl from '~/hooks/useIntl/useIntl'
import { Document } from '@contentful/rich-text-types'
import { isClient } from '~/utils/envChecks'
import { NotificationContext } from './NotificationContext'
import { notificationReducer, Notification, NotificationAction } from './notificationReducer'
// TODO: update Provider and Redcuer to support multiple notification types - e.g. error, info, success, warn etc.,
interface NotificationProps {
  children: ReactNode
}

export function NotificationProvider({ children }: NotificationProps): JSX.Element {
  const intl = useIntl()
  const t = {
    refreshPage: intl.formatMessage({
      id: 'notification-refresh-page',
      defaultMessage: 'Refresh the page',
    }),
  }
  const [notifications, dispatch] = useReducer(notificationReducer, [])

  const removeNotification = useCallback((id: string) => dispatch({ type: 'remove', id }), [])

  const addNotification = useCallback(
    (
      message: string,
      errorType?: Notification['errorType'],
      action?: NotificationAction,
      richText?: Document
    ) => {
      const id = Math.random().toString(36).substring(2, 15)
      const notification: Notification = {
        id,
        message,
        errorType: errorType ?? 'ERROR',
        richText,
        onButtonClick:
          action === 'RELOAD' ? () => isClient() && window.location.reload() : undefined,
        buttonLabel: action === 'RELOAD' ? t.refreshPage : undefined,
      }
      dispatch({ type: 'add', notification })
      return id
    },
    [t.refreshPage]
  )

  const contextValue = useMemo(
    () => ({ addNotification, removeNotification, notifications }),
    [addNotification, removeNotification, notifications]
  )
  return (
    <NotificationContext.Provider value={contextValue}>{children}</NotificationContext.Provider>
  )
}

export default NotificationProvider
