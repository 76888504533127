import { addressLookupOptionHelpers } from './base'

const highlightBlue = 'var(--chakra-colors-brand-highlight-blue)'
const midnightBlue = 'var(--chakra-colors-brand-primary-midnightBlue)'

export const AddressLookupOption = addressLookupOptionHelpers.defineMultiStyleConfig({
  baseStyle: {
    addressLookupOptionWrapper: {
      color: midnightBlue,
      borderColor: 'brand.baseGray.10',
      _hover: {
        color: `${highlightBlue} !important`,
        svg: {
          path: {
            fill: highlightBlue,
          },
        },
      },
      '&[data-highlighted=true]': {
        color: `${highlightBlue} !important`,
        svg: {
          path: {
            fill: highlightBlue,
          },
        },
      },
    },
    autoCompleteListTitle: {
      fontWeight: 'semibold',
    },
    autoCompleteListIcon: {
      svg: {
        path: {
          fill: midnightBlue,
        },
      },
    },
  },
})
