import { DatadogLogMetaContext, createDatadogMetaContext } from '@reward-platform/logger/utils'
import { ZodIssue } from 'zod'
import { createDatadogLogger } from '~/utils/logger'
import { isClient } from '~/utils/envChecks'
import { ErrorCode } from './clientErrors'
import { isAviosPlatform } from '../partner'

const DEFAULT_BUSINESS_MESSAGE = 'Something went wrong, please check the details and try again'

export abstract class GenericClientError<E extends Error = Error> extends Error {
  private errorDescription: string

  public developerMessage: string

  public businessMessage: string

  public code: string

  public httpStatus?: number

  public validationErrors?: ZodIssue[]

  public originalError?: Error

  public fatal: boolean

  constructor(code: ErrorCode, error: E) {
    super()
    this.name = error.name
    this.message = error.message
    this.stack = error.stack
    this.code = error.name
    this.errorDescription = code
    this.developerMessage = error.message
    this.businessMessage = DEFAULT_BUSINESS_MESSAGE
    this.fatal = false
  }

  public getErrorContext() {
    return {
      message: this.message,
      stack: this.stack,
      code: this.code,
      description: this.errorDescription,
      httpStatus: this.httpStatus,
      originalError: this.originalError,
      developerMessage: this.developerMessage,
      businessMessage: this.businessMessage,
      validationErrors: this.validationErrors,
    }
  }

  public logToDatadog(meta?: DatadogLogMetaContext, additionalData: object = {}) {
    const metaContext = meta ? createDatadogMetaContext(meta) : {}

    const context = { ...additionalData, ...metaContext, error: this.getErrorContext() }

    const message = `'${this.errorDescription}' - ${this.developerMessage}`
    if (isClient()) {
      const browserLogger = createDatadogLogger('client-error-handler', { level: 'error' })

      browserLogger.error(`ClientError: ${message}`, context, this.originalError)
    }
  }
}
