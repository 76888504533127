import React from 'react'
import { Box, Skeleton, useStyleConfig } from '@chakra-ui/react'

export const SkeletonCard = () => {
  const styles = useStyleConfig('SkeletonCard', {})

  return (
    <Box __css={styles}>
      <div />
      <div>
        <Skeleton height="20px" width="10ch" />
        <Skeleton height="26px" width="15ch" />
        <Skeleton height="20px" width="20ch" />
        <Skeleton height="20px" width="20ch" />
        <Skeleton height="20px" width="10ch" />
        <Skeleton height="20px" width="10ch" />
      </div>
      <div>
        <Skeleton height="20px" width="10ch" />
        <Skeleton height="20px" width="6ch" />
        <Skeleton height="20px" width="6ch" />
        <Skeleton height="20px" width="10ch" />
        <Skeleton height="20px" width="6ch" />
        <Skeleton height="20px" width="6ch" />
      </div>
    </Box>
  )
}
