import { helpers } from './base'

export const IntroSection = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {},
    content: {},
    scroller: {},
    heading: {},
  },
})
