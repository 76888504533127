import { ComponentSingleStyleConfig } from '@chakra-ui/react'

export const TextMarker: ComponentSingleStyleConfig = {
  baseStyle: {
    wrapper: {
      background: 'brand.primaryTeal',
      color: 'brand.white',

      _before: {
        borderTopColor: 'brand.white',
      },

      _after: {
        borderTopColor: 'brand.primaryTeal',
      },
    },
  },
}
