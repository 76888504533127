import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const wideGridColumn = 'var(--layout-column-wide)'
const pageInlinePadding = 'max(var(--layout-gutter), calc((100vw - var(--layout-width)) / 2))'

export const FlightEditSearchHelper = createMultiStyleConfigHelpers([
  'container',
  'headerContainer',
  'locationText',
  'datesText',
])

export const FlightEditSearch = FlightEditSearchHelper.defineMultiStyleConfig({
  baseStyle: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1.5rem',
      gridColumn: wideGridColumn,
      paddingInline: pageInlinePadding,
      paddingY: '1rem',
      zIndex: 10,
    },
    headerContainer: {
      flex: '1',
      paddingRight: '0.5rem',
      overflow: 'hidden',
    },
    locationText: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: '500',
    },
    datesText: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
})
