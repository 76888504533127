import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helper = createMultiStyleConfigHelpers([
  'container',
  'button',
  'textContainer',
  'icon',
  'text',
  'optionTextPrice',
  'iconCircle',
])

const spaceBetween = 'space-between'

export const UpgradeBasketItem = helper.defineMultiStyleConfig({
  baseStyle: {
    container: {
      display: 'flex',
      gridColumn: '1/-1',
      bg: 'brand.teal5',
      justifyContent: spaceBetween,
      padding: 4,
      alignItems: { base: 'stretch', md: 'center' },
      flexDirection: {
        base: 'column',
        xl: 'row',
      },
      gap: '1rem',

      'div:nth-child(2) > div > div': {
        width: { base: 'full', md: '314px' },

        '> input': {
          padding: '8px 16px',
        },

        '> label': {
          display: 'flex',
          justifyContent: spaceBetween,
          padding: '0 1rem',
          fontSize: '12px',
          alignItems: 'center',

          '> p': {
            display: 'flex',
            justifyContent: spaceBetween,
            gap: '1rem',

            '> span': {},
          },

          '> div': {
            display: 'none',
            backgroundColor: 'brand.black',
          },
        },

        '> div': {
          display: 'none',
        },
        '> ul': {
          transform: 'translateY(-38px)',
          borderRadius: 'lg',
          border: '1px',
          borderColor: 'brand.cityGreen',
          width: 'full',

          '> li > div': {
            border: 'none',
            padding: '10px 16px',
            alignItems: 'center',

            '> div': {
              width: 'full',

              '> span': {
                display: 'flex',
                justifyContent: spaceBetween,
                gap: '1rem',
                alignItems: 'center',

                '> p': {
                  display: 'flex',
                  justifyContent: spaceBetween,
                  width: 'full',
                },
              },
            },
          },
        },
      },
    },
    textContainer: {
      display: 'flex',
      gap: 4,
    },
    button: {
      width: {
        base: 'full',
        md: 'auto',
      },
    },
  },
  variants: {
    // confirmUpgrade: {
    //   button: {
    //     backgroundColor: 'brand.primaryTeal',
    //   },
    // },
  },
})

export const amenitiesHelper = createMultiStyleConfigHelpers([
  'container',
  'textIconContainer',
  'iconText',
])

export const UpgradeBasketAmenities = amenitiesHelper.defineMultiStyleConfig({
  baseStyle: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    },
    textIconContainer: {
      display: 'flex',
      gap: 2,
      alignItems: 'center',
    },
    iconText: {
      fontSize: '14px',
      // color: 'brand.red',
      // fontWeight: '500',
    },
  },
})
