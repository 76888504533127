import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const crossSellingCardHelper = createMultiStyleConfigHelpers([
  'cardBody',
  'content',
  'image',
])
export const CrossSellingCard = crossSellingCardHelper.defineMultiStyleConfig({
  baseStyle: crossSellingCardHelper.definePartsStyle({
    cardBody: {
      display: 'flex',
      alignItems: 'stretch',
      p: 4,
      transition: 'background-color 0.3s ease-in-out',
    },
    content: {
      display: 'flex',
      flexDir: 'column',
      alignItems: 'flex-start',
      gap: 4,

      '*': {
        m: 0,
        lineHeight: { lg: 'base' },
      },
    },
    image: {
      display: 'flex',
      bgPos: 'bottom',
      bgSize: 'cover',
      minHeight: '50px',
    },
  }),
  variants: {
    wide: {
      cardbody: {
        flexDir: 'row',
      },
      content: {
        flexBasis: '50%',

        p: 6,
        pr: 12,
      },
      image: {
        flexBasis: '50%',
      },
    },
    square: {
      cardBody: {
        flexDir: 'column-reverse',
      },
      content: {
        flexBasis: 'auto',
        mt: 5,
        p: 2,
      },
      image: {
        flexBasis: 'auto',
        height: '175px',
        mb: 2,
      },
    },
  },
})
