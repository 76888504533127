import { helpers } from './base'

export const Menu = helpers.defineMultiStyleConfig({
  variants: {
    header: {
      button: {
        color: 'brand.primaryTeal',
        _active: {
          color: 'white',
        },
      },
      divider: {
        color: 'brand.coolgrey1',
      },
      item: {},
      list: {
        rounded: 'lg',
        boxShadow: 'brand',
      },
    },
  },
})
