import { ReactElement, useState } from 'react'
import { Icon, Text, Button, useMultiStyleConfig, Flex } from '@chakra-ui/react'
import { IQuantityPicker } from './interface'
import { IconSizes } from '../../theme/iconSize'
import { convertIconSetToChakraSVG } from '../Icon/Icon.utils'

type ButtonType = 'minus' | 'plus'

export default function QuantityPicker({
  min,
  max,
  defaultValue,
  onSelect,
  label,
  variant,
}: IQuantityPicker): ReactElement {
  const [selectValue, setSelectValue] = useState<number>(defaultValue)
  const styles = useMultiStyleConfig('QuantityPicker', { variant })

  const setValue = (type: ButtonType) => {
    let boxValue
    if (type === 'minus') {
      boxValue = selectValue > min ? selectValue - 1 : min
    } else {
      boxValue = selectValue < max ? selectValue + 1 : max
    }
    setSelectValue(boxValue)
    if (onSelect) {
      onSelect(boxValue)
    }
  }

  const getButtonLabel = (type: ButtonType) => {
    const labels = {
      plus: 'add',
      minus: 'remove',
    }
    let buttonLabel = labels[type]
    if (label) {
      buttonLabel += ` ${label}`
    }
    return buttonLabel
  }

  const getColor = () => {
    if (variant === 'error') {
      return variant
    }
    return selectValue >= max ? 'brand.coolgrey2' : 'brand.inputIcon'
  }

  return (
    <Flex data-testid="quantity-picker" sx={styles.quantityPickerContainer}>
      <Button
        isDisabled={selectValue <= min}
        aria-label={getButtonLabel('minus')}
        onClick={(e) => {
          e.preventDefault()
          setValue('minus')
        }}
        __css={styles.quantityPickerButton}
      >
        <Icon
          as={convertIconSetToChakraSVG('minus-circle', true)}
          boxSize={IconSizes.lg}
          color={selectValue <= min ? 'brand.coolgrey2' : 'brand.inputIcon'}
        />
      </Button>
      <Text sx={styles.selectQuantity}>{selectValue}</Text>
      <Button
        isDisabled={selectValue >= max}
        aria-label={getButtonLabel('plus')}
        onClick={(e) => {
          e.preventDefault()
          setValue('plus')
        }}
        __css={styles.quantityPickerButton}
      >
        <Icon
          as={convertIconSetToChakraSVG('plus-circle', true)}
          boxSize={IconSizes.lg}
          color={getColor()}
        />
      </Button>
    </Flex>
  )
}
