import { helpers } from './base'

export const ExperienceInformation = helpers.defineMultiStyleConfig({
  baseStyle: {
    description: {
      color: 'brand.coolgrey10',
      fontSize: 'lg',
    },
  },
})
