import { forwardRef, useCallback } from 'react'
import NextLink from 'next/link'
import { Icon, chakra, useMultiStyleConfig } from '@chakra-ui/react'
import { ITabTitleProps } from './Tabs.interface'
import { IconSizes } from '../../theme/iconSize'
import { convertIconSetToChakraSVG } from '../Icon/Icon.utils'

const TabTitle = forwardRef<HTMLAnchorElement, ITabTitleProps>(
  ({ title, slug, icon, href, variant, selectedTab, setSelectedTab, onKeyDown }, ref) => {
    const onClick = useCallback(
      (e: { preventDefault: () => void }) => {
        e.preventDefault()
        setSelectedTab(slug)
      },
      [setSelectedTab, slug]
    )
    const active = selectedTab === slug
    const styles = useMultiStyleConfig('Tabs', {})

    return (
      <chakra.li
        flexShrink={0}
        aria-controls={`${slug}-content`}
        role="tab"
        aria-selected={active}
        onKeyDown={onKeyDown}
        onClick={variant === 'link' ? undefined : onClick}
      >
        <NextLink
          href={href || `#${slug}-content`}
          passHref
          style={{ textDecoration: 'none' }}
          target={variant === 'link' ? '_blank' : undefined}
        >
          <chakra.a
            __css={styles.tabListButton}
            tabIndex={active ? 0 : -1}
            id={slug}
            aria-current={active ? 'page' : undefined}
            ref={ref}
          >
            {icon ? (
              <Icon
                as={convertIconSetToChakraSVG(icon)}
                boxSize={IconSizes.sm}
                color="currentColor"
                role="presentation"
                mr={2}
              />
            ) : null}
            {title}
            {variant === 'link' ? (
              <Icon
                as={convertIconSetToChakraSVG('external-link')}
                boxSize={IconSizes.sm}
                color="currentColor"
                role="presentation"
                ml={2}
                aria-label="external link"
                aria-hidden={false}
              />
            ) : null}
          </chakra.a>
        </NextLink>
      </chakra.li>
    )
  }
)

TabTitle.displayName = 'TabTitle'

export default TabTitle
