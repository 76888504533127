import { resultsPageTemplate } from './base'

export const ResultsPageTemplate = resultsPageTemplate.defineMultiStyleConfig({
  baseStyle: resultsPageTemplate.definePartsStyle({
    searchTitle: {
      fontWeight: 'bold',
      color: 'brand.black',
    },
    sidebar: {
      background: 'brand.cloudWhite',
    },
    skipLink: {
      borderRadius: 'lg',
      outlineColor: 'brand.primaryTeal',
    },
  }),
})
