import { createRef, KeyboardEvent, RefObject, useCallback, useRef } from 'react'

enum ArrowCode {
  ARROW_UP = 'ArrowUp',
  ARROW_DOWN = 'ArrowDown',
  ARROW_LEFT = 'ArrowLeft',
  ARROW_RIGHT = 'ArrowRight',
}
const ARROW_KEY_CODES = [
  ArrowCode.ARROW_UP,
  ArrowCode.ARROW_DOWN,
  ArrowCode.ARROW_LEFT,
  ArrowCode.ARROW_RIGHT,
] as string[]

const GRID_DIRECTION_MAPPING = [
  { [ArrowCode.ARROW_DOWN]: 3, [ArrowCode.ARROW_RIGHT]: 1 },
  { [ArrowCode.ARROW_DOWN]: 2, [ArrowCode.ARROW_LEFT]: 0 },
  { [ArrowCode.ARROW_UP]: 1, [ArrowCode.ARROW_DOWN]: 6, [ArrowCode.ARROW_LEFT]: 0 },
  { [ArrowCode.ARROW_UP]: 0, [ArrowCode.ARROW_RIGHT]: 4 },
  { [ArrowCode.ARROW_UP]: 0, [ArrowCode.ARROW_LEFT]: 3, [ArrowCode.ARROW_RIGHT]: 5 },
  { [ArrowCode.ARROW_UP]: 0, [ArrowCode.ARROW_LEFT]: 4, [ArrowCode.ARROW_RIGHT]: 6 },
  { [ArrowCode.ARROW_UP]: 2, [ArrowCode.ARROW_LEFT]: 5 },
]

const useGalleryArrowNavigation = () => {
  const imageButtonRefs = useRef<Array<RefObject<HTMLButtonElement>>>([])
  const tabIndexRef = useRef<number>(0)

  const getGallerySize = useCallback(() => {
    const visibleImages = imageButtonRefs.current.filter(
      (el) =>
        el.current &&
        typeof window !== 'undefined' &&
        window.getComputedStyle(el.current, null).display !== 'none'
    )
    return visibleImages.length
  }, [])

  const focusButton = useCallback((nextIndex: number) => {
    tabIndexRef.current = nextIndex
    const nextNode = imageButtonRefs.current?.[nextIndex]
    nextNode?.current?.focus()
  }, [])

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      const container = e.target as HTMLElement
      if (!container) {
        return
      }
      const gallerySize = getGallerySize()
      const lastIndex = gallerySize - 1
      const currentIndex = tabIndexRef.current > lastIndex ? lastIndex : tabIndexRef.current
      if (!ARROW_KEY_CODES.includes(e.key)) {
        return
      }
      e.preventDefault()

      if (container.tagName !== 'BUTTON') {
        focusButton(currentIndex)
        return
      }

      const currentIndexDirectionMap = GRID_DIRECTION_MAPPING[currentIndex]
      const nextIndex = Number(currentIndexDirectionMap[e.key as ArrowCode])
      if (!Number.isNaN(nextIndex)) {
        focusButton(nextIndex)
      }
    },
    [focusButton, getGallerySize]
  )

  const resetTabIndex = useCallback(() => {
    tabIndexRef.current = 0
    focusButton(0)
  }, [focusButton])

  const registerImageButton = useCallback((index: number) => {
    const createImageButtonRef = (idx: number) => {
      if (!imageButtonRefs.current[idx]) {
        imageButtonRefs.current[idx] = createRef()
      }
      return imageButtonRefs.current[idx]
    }

    return {
      ref: createImageButtonRef(index),
      tabIndex: index === tabIndexRef.current ? 0 : -1,
    }
  }, [])

  return { onKeyDown, resetTabIndex, registerImageButton }
}

export default useGalleryArrowNavigation
