/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const WhatToExpect = helpers.defineMultiStyleConfig({
  baseStyle: {
    passBy: {
      fontWeight: 'normal',
    },
    duration: {
      fontSize: 'sm',
      fontWeight: 'normal',
    },
    optionalText: {
      lineHeight: 5,
      fontSize: 'sm',
    },
    durationContainer: {
      backgroundColor: 'brand.coolgrey1',
      padding: 'var(--chakra-space-1) var(--chakra-space-2)',
      borderRadius: 'lg',
    },
    whatToExpectContainer: {
      '> ul b': {
        fontWeight: 'medium',
      },
    },
    whatToExpectTitle: {
      fontSize: '1.375rem',
      fontWeight: 'semibold',
    },
  },
})
