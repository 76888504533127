/* eslint-disable sonarjs/no-duplicate-string */
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'footerContainer',
  'flightsContainer',
  'flightTotalInfo',
  'flightTotalText',
  'showHideButton',
  'paymentOptions',
  'mobilePaymentOptions',
  'paymentOptionsSummaryBox',
  'paymentOptionsCheckoutSummary',
  'checkoutSummaryHeader',
  'paymentOptionsSummaryHeader',
  'selectAllFlightsBox',
  'selectAllFlightsText',
  'continueButton',
  'buttonContainer',
  'priceAndPaymentOptionsContainer',
  'avios',
  'aviosDescription',
  'taxesAndFeesContainer',
  'taxesAndFees',
  'taxesAndFeesDescription',
  'togglePaymentOptionsButton',
])

export const FlightPriceFooter = helpers.defineMultiStyleConfig({
  baseStyle: {
    footerContainer: {
      display: {
        base: 'flex',
      },
      position: 'relative',
      borderRadius: {
        base: '2rem',
        xl: '1rem',
      },
      flexDirection: {
        base: 'column',
        xl: 'unset',
      },
      width: {
        base: 'unset',
        md: '35rem',
        xl: 'unset',
      },
      gap: {
        base: '0.5rem',
        xl: '1rem',
      },
      padding: {
        base: '0.625rem',
        xl: '0.625rem 1rem 0.625rem 0.625rem',
      },
      alignItems: {
        base: 'flex-start',
        xl: 'center',
      },
      justifyContent: {
        base: 'center',
        xl: 'unset',
      },
      margin: {
        sm: 'auto 6rem auto auto',
        xl: '0 6rem 0 auto',
      },
    },
    flightsContainer: {
      display: 'flex',
      borderRadius: {
        base: '1.5rem',
        xl: '0.5rem',
      },
      padding: {
        xl: '0.5rem 1rem',
      },
      gap: '0.5rem',
      order: {
        base: 2,
        xl: 1,
      },
      flexDirection: {
        base: 'column',
        xl: 'unset',
      },
      alignItems: {
        base: 'center',
        xl: 'center',
      },
      alignSelf: {
        base: 'stretch',
        xl: 'unset',
      },
      flex: {
        base: '1 0 0',
        xl: 'unset',
      },
    },
    flightTotalInfo: {
      display: 'flex',
      order: {
        base: 1,
        xl: 2,
      },
      alignSelf: 'stretch',
      padding: {
        base: '0.625rem',
        md: '0.5rem 1rem',
        xl: '0 1rem 0 0',
      },
      gap: '1rem',
      height: '4.875rem',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    flightTotalText: {
      display: 'flex',
      whiteSpace: 'no-wrap',
      alignSelf: 'stretch',
      alignItems: 'center',
      borderRight: '1px solid',
      padding: '0 1rem 0 0',
      gap: '0.625rem',
      fontWeight: 'bold',
      fontSize: {
        base: '1rem',
        md: '1.125rem',
        xl: '1.375rem',
      },

      '& img': {
        minWidth: '1.5rem',
        minHeigh: '1.5rem',
      },
    },
    priceAndPaymentOptionsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'baseline',
      fontSize: 'sm',
      fontWeight: 500,
    },
    avios: {
      fontSize: { base: 'xl', md: '2xl', lg: '3xl' },
      marginRight: { base: 2, md: 4, lg: 2.5 },
    },
    aviosDescription: {
      marginRight: { base: 1, xl: 2 },
      fontSize: { md: 'lg' },
    },
    taxesAndFeesContainer: {
      flex: { base: '100%', xl: 1 },
    },
    taxesAndFees: {
      marginRight: { base: 2, lg: 1 },
      fontSize: { xl: 'lg' },
    },
    taxesAndFeesDescription: {
      fontSize: { base: 'xs', lg: 'sm' },
      fontWeight: 400,
    },
    showHideButton: {
      position: 'relative',
      display: {
        base: 'none',
        xl: 'flex',
      },
      marginLeft: 'auto',
    },
    paymentOptions: {
      position: 'fixed',
      display: {
        base: 'none',
        xl: 'flex',
      },
      justifyContent: 'center',
      alignItems: 'flex-start',
      gap: '0.625rem',
      alignSelf: 'stretch',
      bottom: '8.3125rem',
      right: '12.25rem',
    },
    mobilePaymentOptions: {
      padding: {
        base: '0.625rem',
        xl: 0,
      },
      width: '100%',
      display: {
        base: 'block',
        xl: 'none',
      },
    },
    paymentOptionsSummaryBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      gap: '3.5rem',
      alignSelf: 'stretch',
    },
    paymentOptionsCheckoutSummary: {
      borderRadius: '1.6rem',
      padding: '1rem 0.625rem',
      width: '100%',
      gap: '0.625rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      alignSelf: 'stretch',
    },
    checkoutSummaryHeader: {
      gap: '0.625rem',
      display: 'flex',
      padding: '0 0 0.5rem 0.375rem',
    },
    paymentOptionsSummaryHeader: {
      fontSize: 'lg',
      fontWeight: '500',
    },
    selectAllFlightsBox: {
      gap: '0.625rem',
      padding: '0 0 0 0.375rem',
    },
    selectAllFlightsText: {
      padding: '0 0 0 0.375rem',
      gap: '1.5rem',
      fontSize: 'lg',
      fontWeight: '400',
    },
    continueButton: {
      position: 'relative',
      width: {
        base: '100%',
        xl: 'unset',
      },
    },
    buttonContainer: {
      display: 'flex',
      order: 3,
      alignItems: 'center',
      marginLeft: 'auto',
      gap: {
        base: 'unset',
        xl: '1rem',
      },
      width: {
        base: '100%',
        xl: 'unset',
      },
    },
    togglePaymentOptionsButton: {
      height: '100%',
      padding: 0,
      justifyContent: 'end',
      alignItems: 'center',
      backgroundColor: 'transparent',
      border: 'none',
      marginLeft: 'auto',
      _hover: {
        cursor: 'pointer',
      },
      _focus: {
        outline: 'solid',
        outlineColor: 'brand.white',
        outlineWidth: 1,
      },
      display: {
        base: 'flex',
        xl: 'none',
      },
    },
  },
  variants: {
    notAllBoundsSelected: {
      footerContainer: {
        width: {
          base: 'unset',
          md: '60.5vw',
          xl: 'unset',
        },
      },
    },
  },
})
