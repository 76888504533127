/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

const backgroundColor = 'bg.accent.vibrant.default'

export const Carousel = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      borderRadius: 'xs',
      overflow: 'hidden',
    },
    navigationButton: {
      borderRadius: '2xs',
      height: 'var(--alto-sem-space-xl)',
      width: 'var(--alto-sem-space-xl)',
      backgroundColor,

      _hover: {
        backgroundColor,
      },
      _focus: {
        backgroundColor,
      },
      _active: {
        backgroundColor,
      },
    },
    navigationIcon: {
      boxSize: 3,
    },
  },
})
