import { helpers } from './base'

const color = 'brand.grey6'

export const AmenitiesList = helpers.defineMultiStyleConfig({
  baseStyle: {
    amenitiesList: {},
    amenitiesSectionTitle: {
      fontSize: 'xl',
      fontWeight: 'semibold',
      color: `${color}`,
    },
    amenitiesListItem: {
      '&::before': {
        backgroundColor: `${color}`,
      },
    },
    amenitiesSection: {
      'li:not(:first-child)': {
        color: `${color}`,
        fontSize: 'md',

        '&::before': {
          backgroundColor: `${color}`,
        },
      },
    },
  },
})
