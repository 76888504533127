import { helpers } from './base'

export const SearchFlightForm = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {},
    route: {},
    airports: {},
    oneWay: {},
    submitButton: {},
  },
})
