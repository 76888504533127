import { helpers } from './base'

const colorPrimaryTeal = 'brand.primaryTeal'

export const PriceBreakdownContainer = helpers.defineMultiStyleConfig({
  baseStyle: {
    aviosDueLabel: {
      fontWeight: 'medium',
    },
    aviosDueValue: {
      fontWeight: 'medium',
      color: colorPrimaryTeal,
    },
    cashLabel: {
      fontWeight: 'medium',
    },
    cashValue: {
      fontWeight: 'medium',
      color: colorPrimaryTeal,
    },
    cashDueLabel: {
      fontWeight: 'medium',
      marginTop: 2,
    },
    cashDueValue: {
      fontWeight: 'medium',
      color: colorPrimaryTeal,
      fontSize: '4xl',
    },
  },
})
