import { helpers } from './base'

const brandWhite = 'brand.white'

export const Modal = helpers.defineMultiStyleConfig({
  baseStyle: {
    header: {
      bg: brandWhite,
      h2: {
        fontWeight: 'light',
        textAlign: 'left',
        fontSize: { base: '4xl', lg: '2.75rem' },
      },
      mb: 4,
    },
    dialog: {
      bg: brandWhite,
      borderRadius: { base: 0, md: 'sm' },
      p: {
        mb: 3,
      },
    },
    closeButton: {
      color: 'brand.primary.midnightBlue',
      _before: {
        bg: brandWhite,
      },
    },
  },
})
