import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { darken } from 'polished'

export const helpers = createMultiStyleConfigHelpers([
  'button',
  'list',
  'item',
  'groupTitle',
  'command',
  'divider',
  'overlay',
  'headerButton',
])

export const Menu = helpers.defineMultiStyleConfig({
  variants: {
    header: {
      button: {
        border: 'none',
        background: 'none',
        cursor: 'pointer',
        color: 'red',
        position: 'relative',
        p: 3,

        _focusVisible: {
          outline: 'solid',
          outlineColor: 'brand.focusOutline',
          outlineWidth: 1,
        },
      },
      command: {},
      divider: {
        m: 0,
      },
      groupTitle: {},
      item: {
        w: '100%',
        fontSize: 'sm',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: 4,
        padding: 4,
        justifyContent: 'flex-start',
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        textDecoration: 'none',
        color: 'inherit',
        userSelect: 'none',
        bgColor: '#fff',
        _hover: { bgColor: darken(0.06, '#fff') },
        _active: { bgColor: darken(0.3, '#fff') },
        transition: 'background-color 0.3s',

        _focusVisible: {
          bgColor: 'brand.coolgrey1',
        },
      },
      list: {
        boxShadow: 'none',
        overflow: 'hidden',
        p: 0,
      },
      overlay: {
        pos: 'fixed',
        left: '0',
        top: '0',
        w: '100vw',
        h: '100vh',
        bgColor: 'rgba(0,0,0,0.5)',
      },
      headerButton: {
        '&.chakra-button': {
          height: 8,
          padding: 'var(--chakra-space-2) var(--chakra-space-6)',
          fontWeight: 'medium',
          fontSize: 'sm',
          svg: {
            marginRight: 2.5,
          },
        },
      },
    },
  },
})
