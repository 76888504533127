import { helpers } from './base'

const cloudWhite = 'brand.cloudWhite'

export const Tabs = helpers.defineMultiStyleConfig({
  baseStyle: {
    tabListButton: {
      borderRadius: '5px 5px 0 0',
      fontWeight: 'medium',
      textTransform: 'none',
      height: 14,
      p: '0px 32px',
      border: 'solid 1px var(--chakra-colors-brand-tealDarker)',
      bgColor: 'brand.primaryTeal',
      color: 'brand.white',

      _focusVisible: {
        outlineStyle: 'solid',
        outlineColor: 'brand.white',
        position: 'relative',
      },

      _activeLink: {
        height: 16,
        p: '12px 32px',
        border: 'none',
        bgColor: cloudWhite,
        color: 'brand.black',

        _focusVisible: {
          outlineStyle: 'solid',
          outlineWidth: 2,
          outlineColor: 'brand.cityGreen',
        },
      },
    },
    tabWrap: {
      _before: { bgColor: cloudWhite },
      marginLeft: {
        base: '0',
        md: 2,
      },
      marginRight: {
        base: '0',
        md: 2,
      },
    },
    tabContent: {
      backgroundColor: cloudWhite,
      borderRadius: {
        base: '0',
        md: '0 0.625rem 0.625rem 0.625rem',
      },
    },
  },
})
