import { helpers } from './base'

export const TravelerInformationForm = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    wrapper: {
      hr: {
        visibility: 'hidden',
      },
    },
  }),
})
