import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers(['container', 'title'])

export const PaymentOptionsForm = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      flexDirection: 'column',
      gap: 2,
      mb: 6,
    },
    title: {
      fontSize: 'lg',
      fontWeight: 'medium',
    },
  },
})
