import { ComponentSingleStyleConfig } from '@chakra-ui/react'

const midnightBlue = 'brand.primary.midnightBlue'
const highlightBlue = 'brand.highlight.blue'

export const TextMarker: ComponentSingleStyleConfig = {
  baseStyle: {
    wrapper: {
      background: `${midnightBlue}`,
      color: 'brand.white',
      transitionDelay: '0.3s',
      '&[data-selected="true"]': {
        background: `${highlightBlue}`,
        _after: {
          borderTopColor: `${highlightBlue}`,
        },
      },
      _before: {
        borderTopColor: 'brand.white',
      },

      _after: {
        borderTopColor: `${midnightBlue}`,
      },

      _hover: {
        background: `${highlightBlue}`,
        _after: {
          borderTopColor: `${highlightBlue}`,
        },
      },
    },
  },
}
