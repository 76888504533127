import { overlayTipHelper } from './base'

export const OverlayTip = overlayTipHelper.defineMultiStyleConfig({
  baseStyle: {
    wrapper: {
      color: 'fg.primary',
    },
    containerBorder: {
      borderColor: 'border.primary.default',
      borderRadius: '3xs',
    },
  },
})
