import { helpers } from './base'

export const QuantityPicker = helpers.defineMultiStyleConfig({
  baseStyle: {
    quantityPickerButton: {
      color: 'brand.quantityicons',
      _disabled: { color: 'brand.greydisabled' },
    },
  },
})
