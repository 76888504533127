import { helpers } from './base'

export const ChakraPopover = helpers.defineMultiStyleConfig({
  baseStyle: {
    arrow: {
      borderColor: 'brand.primaryShamrockGreen',
    },
    content: {
      bg: 'brand.shamrock5',
      color: 'brand.black',
      borderColor: 'brand.primaryShamrockGreen',
    },
  },
})
