import { ZodError } from 'zod'
import { GenericClientError } from './GenericClientError'
import { ERROR_CODES } from './clientErrors'

const DEVELOPER_MESSAGE =
  'Some data did not pass validation. Look into validationErrors object for more details'

export class ValidationError extends GenericClientError {
  constructor(error: ZodError, message?: string) {
    super(ERROR_CODES.VALIDATION, error)

    if (message) {
      this.businessMessage = message
    }
    this.message = this.businessMessage
    this.developerMessage = DEVELOPER_MESSAGE
    this.validationErrors = error.issues
    this.originalError = error
  }
}
