import { helpers } from './base'

const black = 'brand.black'

export const Banner = helpers.defineMultiStyleConfig({
  baseStyle: {
    wrapper: {
      '& h2': {
        fontWeight: 'normal',
        fontSize: { base: '3xl', lg: '5xl' },
        lineHeight: { base: '9' },
      },
    },
    anchorWrapper: {
      backgroundColor: 'brand.white',
      color: black,
    },

    anchorIcon: {
      color: black,
    },

    anchorText: {
      color: 'brand.primaryTeal',
    },

    anchorIconText: {
      color: black,
    },
  },
})
