import { acceptedCardsHelper } from './base'

export const AcceptedCards = acceptedCardsHelper.defineMultiStyleConfig({
  baseStyle: acceptedCardsHelper.definePartsStyle({
    acceptedCardWrapper: {
      bg: 'brand.baseGray.10',
      borderRadius: 2,
    },
  }),
})
