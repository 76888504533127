import { helpers } from './base'

const highlightOrange = 'brand.highlightOrange'

export const StatusBadge = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    container: {
      borderRadius: 'base',
      color: 'white',
      fontSize: 'sm',
      fontWeight: 'light',
      height: 6,
    },
  }),
  variants: {
    READY_TO_PROCEED: { container: { bg: 'brand.darkCityGreen' } },
    REMOVED: {
      container: {
        bg: 'brand.coolgrey10',
      },
      icon: {
        svg: {
          w: 3,
        },
      },
    },
    DELIVERED: { container: { bg: 'brand.primaryTeal' } },
    SUSPENDED: { container: { bg: highlightOrange } },
    UNABLE_TO_DELIVER: { container: { bg: highlightOrange } },
    IN_PROGRESS: { container: { bg: highlightOrange } },
    UNKNOWN: { container: { bg: 'brand.teal5', color: 'brand.black' } },
  },
})
