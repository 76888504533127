import { helpers } from './base'

export const SelectBox = helpers.defineMultiStyleConfig({
  baseStyle: {
    selectBoxLabel: {
      color: 'brand.black',
    },
    selectBoxSelect: {
      borderColor: 'brand.coolgrey4',
      borderRadius: '5px',

      _focus: {
        borderColor: 'brand.primaryTeal',
      },
    },
    chevronColor: {
      color: 'brand.primaryTeal',
    },
  },
  variants: {
    error: {
      selectBoxLabel: {
        color: 'error.primary',
      },
      selectBoxSelect: {
        borderColor: 'error.primary',
        boxShadow: '0 0 2px 0 var(--chakra-colors-error-primary)',
      },
    },
  },
})
