import { Box, Button, ButtonProps, Icon, useMultiStyleConfig } from '@chakra-ui/react'
import { useAlertContext } from './AlertProvider'
import { convertIconSetToChakraSVG } from '../Icon/Icon.utils'
import { IconSize, IconSizes } from '../../theme/iconSize'
import { IconName } from '../Icon/Icon.types'

type Props = ButtonProps & {
  iconName?: IconName
  iconSize?: IconSize
}

export const AlertIconButton = (props: Props) => {
  const { onClick, iconName, iconSize } = props
  const { setIsOpen } = useAlertContext()
  const styles = useMultiStyleConfig('Alert')

  const handleClick = (event: any) => {
    if (onClick) {
      onClick(event)
    } else {
      setIsOpen(false)
    }
  }

  return (
    <Box __css={styles.closeButton}>
      <Button variant="secondary" size="md" {...props} onClick={handleClick}>
        <Icon
          as={convertIconSetToChakraSVG(iconName ?? ('cross' as IconName))}
          boxSize={IconSizes[iconSize ?? 'xs']}
        />
      </Button>
    </Box>
  )
}
