import { Box, BoxProps, useMultiStyleConfig } from '@chakra-ui/react'
import React from 'react'
import { TabContentProps } from './Tabs.interface'

interface Props extends BoxProps, TabContentProps {
  title: string
}

const TabContent = ({ children, title, ...props }: Props): React.ReactElement => {
  const style = useMultiStyleConfig('Tabs', {})

  return (
    <Box
      __css={style.tabContent}
      {...props}
      id={`${props.slug}-content`}
      aria-label={`${title} Content`}
    >
      {children}
    </Box>
  )
}

export default TabContent
