import { helpers } from './base'

const blue = 'brand.highlight.blue'
const primaryBlue = 'var(--chakra-colors-brand-primary-blue)'

export const ContentBadge = helpers.defineMultiStyleConfig({
  baseStyle: {
    wrapper: {
      background: '#e9f1fb',
      borderColor: blue,
      borderRadius: 'base',
      ' > span': {
        fontSize: { base: 'xs', md: 'sm' },
        color: primaryBlue,
      },
      '> svg': {
        color: blue,
      },
    },
  },
})
