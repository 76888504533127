/* eslint-disable sonarjs/no-duplicate-string */
import { deviceSizes } from '@reward-platform/lift/theme/deviceSizes'
import { helpers } from './base'

const coolGrey1 = 'brand.coolgrey1'
const coolGrey2 = 'brand.coolgrey2'
const coolGrey8 = 'brand.coolgrey8'

export const FlightCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    arrowLine: {
      backgroundColor: coolGrey2,
      clipPath: 'polygon(0% 85%, 100% 85%, 100% 90%, 0% 90%)',
    },
    arrowPointer: {
      backgroundColor: coolGrey2,
      clipPath: 'polygon(0% 77%, 100% 87%, 0% 97%)',
      height: 'full',
      width: '0.5ch',
    },
    arrivalTime: {
      fontWeight: 'semibold',
    },
    departureTime: {
      fontWeight: 'semibold',
    },
    flightCardContainer: {
      backgroundColor: 'brand.white',
      border: 'solid',
      borderColor: coolGrey2,
      borderRadius: '2xl',
      borderWidth: '1px',
      _hover: {
        boxShadow: 'light',
      },
      padding: 3,
      transition: 'box-shadow 0.25s ease-in-out',
    },
    flightInfoContainer: {
      padding: 3,
      minWidth: {
        lg: '50%',
      },
    },
    offPeak: {
      marginLeft: 4,
      backgroundColor: 'brand.primaryTeal',
    },
    pricesContainer: {
      '> *': {
        borderStyle: 'none',
        borderWidth: 0,
        marginLeft: 3,
        [`@media (max-width: ${deviceSizes.medium})`]: {
          marginLeft: 0,
          marginTop: 3,
        },
      },
    },
    soldOut: {
      borderColor: coolGrey2,
      bg: coolGrey1,
      color: coolGrey8,
    },
  },
  variants: {
    aviosOnlyHighlightAllSoldOut: {
      flightCardContainer: {
        borderColor: 'brand.coolgrey2',
      },
    },
    aviosOnlyHighlight: {
      flightCardContainer: {
        borderColor: 'brand.primaryTeal',
      },
    },
  },
})
