import { ComponentStyleConfig } from '@chakra-ui/react'
import merge from 'lodash/merge'
import { Accordion as base } from './base'

export const Accordion: ComponentStyleConfig = merge({}, base, {
  baseStyle: {
    container: {
      borderColor: 'brand.coolgrey2',
    },
  },
})
