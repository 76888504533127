import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'list',
  'listItem',
  'label',
  'traveler',
  'travelerType',
  'travelerTypeLabel',
  'minMax',
  'errorMessageBox',
])

export const TravelerQuantityPicker = helpers.defineMultiStyleConfig({
  baseStyle: {
    list: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0',
      width: 'full',
    },
    listItem: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      columnGap: '4',
      fontSize: 'lg',
      fontWeight: 'medium',

      '&:last-of-type': {
        height: '3.5625rem',
      },
    },
    label: {
      flexDirection: 'column',

      '> p': {
        margin: '0',
      },
      traveler: {
        fontSize: 'sm',
      },
      travelerType: {
        fontWeight: 'bold',
      },
      minMax: {
        fontSize: 'xs',
      },
    },
  },
  variants: {
    success: {
      listItem: {
        border: 'none',
      },
    },
    error: {
      listItem: {
        '&:first-of-type': {
          borderRadius: 'sm',
          padding: '0.5rem',
        },
      },
    },
  },
  defaultProps: {
    variant: 'success',
  },
})
