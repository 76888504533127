import { helpers } from './base'

const primaryColor = 'brand.primaryTeal'

export const TelevisionButton = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    control: {
      color: primaryColor,
      borderColor: primaryColor,
      _checked: {
        bg: primaryColor,
      },
    },
  }),
})
