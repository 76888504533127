import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'wrapper',
  'content',
  'sectionWide',
  'sectionCenter',
  'centerContainer',
  'centerGridWrapper',
  'skipLink',
  'barcLayoutWrapper',
  'textBg',
])

export const Layout = helpers.defineMultiStyleConfig({
  baseStyle: {
    wrapper: {
      isolation: 'isolate',
      display: 'grid',
      minHeight: '100vh',
      gridTemplateRows: 'auto auto 1fr auto auto',
      backgroundColor: 'var(--wrapper-background-color)',
    },
    content: {
      '--layout-width': 'var(--chakra-sizes-7xl)',
      '--layout-gutter': 'var(--chakra-space-4)',
      '--layout-column-center': '2/3',
      '--layout-column-wide': '1/4',
      '--layout-sidebar-width': 'minmax(16ch, 1fr)',
      '--layout-content-width': 'minmax(48ch, 3fr)',

      pos: 'relative',
      display: 'grid',
      gridColumnGap: 'var(--layout-gutter)',
      gridTemplateColumns: '1fr minmax(auto, var(--layout-width)) 1fr',
      gridAutoFlow: 'row',
      gridAutoRows: 'min-content',

      '& > *': {
        gridColumn: 'var(--layout-column-center)',
      },
    },
    skipLink: {
      outlineWidth: 1,
      _focusVisible: {
        margin: 3,
        padding: 1,
        zIndex: 100,
        outlineStyle: 'solid',
      },
    },
  },
})
