import { Basket, PaymentOption, PaymentOptions } from '../basket'

export const getCurrencyCodePaymentOptions = (paymentOptions: PaymentOptions): string => {
  const currencySet = new Set<string>()

  paymentOptions.options.forEach(
    (option: PaymentOption) =>
      option.cashValue?.currency && currencySet.add(option.cashValue?.currency)
  )
  const { onlyCashPaymentOption } = paymentOptions
  if (onlyCashPaymentOption) {
    if (onlyCashPaymentOption.cashBaseValue) {
      currencySet.add(onlyCashPaymentOption.cashBaseValue?.currency)
    }

    if (onlyCashPaymentOption.cashTaxValue?.currency) {
      currencySet.add(onlyCashPaymentOption.cashTaxValue?.currency)
    }

    currencySet.add(onlyCashPaymentOption.cashTotalValue.currency)
  }

  if (currencySet.size > 1) {
    throw new Error('Invalid currency codes provided')
  }

  return currencySet.values().next().value
}

export const getCurrencyCodeFromBasket = (basketItems: Basket['items']): string => {
  const hotel = basketItems.HOTEL[0] ? [basketItems.HOTEL[0].paymentOptions] : []
  const carHire = basketItems.CAR_HIRE[0] ? [basketItems.CAR_HIRE[0].paymentOptions] : []
  const experience = basketItems.EXPERIENCE[0] ? [basketItems.EXPERIENCE[0].paymentOptions] : []
  const flight = basketItems.FLIGHT[0] ? [basketItems.FLIGHT[0].paymentOptions] : []
  const paymentOptions = [...hotel, ...carHire, ...experience, ...flight]

  const currencySet = new Set<string>()
  paymentOptions.forEach((payment) => {
    if (payment.options) {
      currencySet.add(getCurrencyCodePaymentOptions(payment))
    }
    const { onlyCashPaymentOption } = payment
    if (onlyCashPaymentOption) {
      currencySet.add(onlyCashPaymentOption.cashBaseValue.currency)

      if (onlyCashPaymentOption.cashTaxValue?.currency) {
        currencySet.add(onlyCashPaymentOption.cashTaxValue?.currency)
      }

      currencySet.add(onlyCashPaymentOption.cashTotalValue.currency)
    }
  })

  if (currencySet.size > 1) {
    throw new Error('Invalid currency codes provided')
  }

  return currencySet.values().next().value
}
