import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const searchCardGap = 6

export const helpers = createMultiStyleConfigHelpers([
  'hotelCardWrapper',
  'linkWrapper',
  'imageWrapper',
  'image',
  'headingLink',
  'heading',
  'hotelsResultsMainWrapper',
  'paymentOptionsHeading',
  'duration',
  'durationAndPaymentContainer',
  'hotelCardBadge',
])

export const HotelCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    hotelCardWrapper: {
      display: 'grid',
      overflow: 'hidden',
      gridTemplate: {
        base: `'imageAndContent' auto 'pricing' auto`,
        md: `'imageAndContent pricing' minmax(auto, max-content)
        / 1fr minmax(min-content, max-content)`,
      },
      gridGap: searchCardGap,
      padding: {
        base: 'var(--chakra-space-4) 0 var(--chakra-space-4) var(--chakra-space-4)',
        md: 6,
      },
      borderRadius: 'lg',
      color: 'black',
    },
    linkWrapper: {
      display: 'grid',
      gridTemplate: {
        base: `'image' minmax(8rem, 12rem) 'content' auto`,
        md: `'image content' auto / minmax(8rem, 18.5rem) 1fr`,
      },
      gridArea: 'imageAndContent',
      p: 0,
    },
    imageWrapper: {
      gridArea: 'image',
      overflow: 'hidden',
      m: { base: -4, md: -searchCardGap },
      mb: { base: searchCardGap, md: -searchCardGap },
      mr: { base: 0, md: searchCardGap },
    },
    image: {
      position: 'relative',
      w: 'full',
      h: 'full',
      borderRadius: {
        base: 'var(--chakra-radii-lg) var(--chakra-radii-lg) 0 0',
        md: 'var(--chakra-radii-lg) 0 0 var(--chakra-radii-lg)',
      },
    },
    headingLink: {
      textDecoration: 'unset',
      color: 'inherit',
    },
    heading: {
      lineHeight: 'normal',
      fontSize: 'lg',
      marginBlock: 'var(--chakra-space-2) var(--chakra-space-1)',
      fontWeight: 'medium',
    },
    hotelsResultsMainWrapper: {
      gridArea: 'content',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      '> * + *': {
        mt: 4,
      },
    },
    duration: {
      fontSize: 'sm',
      fontWeight: 'normal',
      whiteSpace: 'nowrap',
    },
    paymentOptionsHeading: {
      fontWeight: 'medium',
    },
    durationAndPaymentContainer: {
      display: 'grid',
      rowGap: 4,
      justifyItems: { base: 'unset', md: 'end' },
    },
    hotelCardBadge: {
      zIndex: 1,
      position: 'absolute',
      margin: 'var(--chakra-space-2) 0 0 var(--chakra-space-2)',
      padding: 'var(--chakra-space-1) var(--chakra-space-2)',
    },
  },
})
