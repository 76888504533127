import { z } from 'zod'
import { ContentfulAssetSchema } from './asset'

export const ContentfulCrossSellingSchema = z.object({
  identifier: z.string(),
  image: ContentfulAssetSchema,
  heading: z.string(),
  text: z.string(),
  href: z.string().optional().nullable(),
  buttonText: z.string().optional().nullable(),
})
export type ContentfulCrossSelling = z.infer<typeof ContentfulCrossSellingSchema>

export const ContentfulCrossSellingCollectionSchema = z.object({
  crossSellingCollection: z.object({
    items: z.array(ContentfulCrossSellingSchema),
  }),
})
export type ContentfulCrossSellingCollection = z.infer<
  typeof ContentfulCrossSellingCollectionSchema
>
