import { alWhite, alTeal5, alCloudWhite } from '@reward-platform/lift/theme/aer_lingus/variables'
import { FlightSelectHelper } from './base'

export const FlightSelect = FlightSelectHelper.defineMultiStyleConfig({
  baseStyle: FlightSelectHelper.definePartsStyle({
    journeyContainer: {
      backgroundColor: alWhite,
      borderColor: alTeal5,
    },
    flightsContainer: {
      backgroundColor: alCloudWhite,
      borderBottomColor: 'brand.coolgrey2',
      paddingY: 6,
      gap: 4,

      '> *:not(:first-child)': {
        marginTop: 4,
      },
    },
  }),
})
