import { z } from 'zod'
import { ContentfulAssetSchema } from './asset'
import { ContentfulRichTextDocumentSchema } from './common'
import { ContentfulHeroSectionSchema } from './hero'

export const ContentfulSearchContentProductSchema = z.enum([
  'flights',
  'hotels',
  'carhire',
  'experiences',
])

export type ContentfulSearchContentProduct = z.infer<typeof ContentfulSearchContentProductSchema>

export const ContentfulCardSchema = z.object({
  image: ContentfulAssetSchema,
  heading: z.string().optional(),
  subHeading: z.string().optional(),
  caption: z.string().optional(),
  link: z.string().optional(),
})

export type ContentfulCard = z.infer<typeof ContentfulCardSchema>

export const ContentfulIntroSectionSchema = z.object({
  identifier: z.string().optional(),
  content: ContentfulRichTextDocumentSchema,
  cards: z.array(ContentfulCardSchema).optional(),
})

export type ContentfulIntroSection = z.infer<typeof ContentfulIntroSectionSchema>

export const ContentfulSearchContentSchema = z.object({
  name: z.string(),
  identifier: z.string(),
  productLabel: z.string().optional(),
  product: ContentfulSearchContentProductSchema.optional(),
  hero: ContentfulHeroSectionSchema.optional(),
  trendingDestinations: z.array(ContentfulCardSchema).optional(),
  intro: ContentfulIntroSectionSchema.optional(),
  recommended: ContentfulIntroSectionSchema.optional(),
  fallbackLink: z.string().optional(),
})

export type ContentfulSearchContent = z.infer<typeof ContentfulSearchContentSchema>

export const ContentfulSearchPageSchema = z.object({
  name: z.string(),
  identifier: z.string(),
  products: z.array(ContentfulSearchContentSchema),
})

export type ContentfulSearchPage = z.infer<typeof ContentfulSearchPageSchema>
