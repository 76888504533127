import React, { useMemo } from 'react'
import { debounce } from 'lodash'
import { AutoCompleteItemAttributes } from './components/AutoComplete.types'

export function useTypeahead<TItem extends AutoCompleteItemAttributes>(
  items: TItem[],
  setHighlightedIndex: (index: number) => void
) {
  const textSearchRef = React.useRef<string>('')

  const debouncedAutoCompleteHandler = useMemo(() => {
    return debounce((input: HTMLInputElement, value: string) => {
      const index = items.findIndex((item) =>
        item.label.toLowerCase().startsWith(value.toLowerCase())
      )
      if (index === -1) {
        return
      }
      const selected = items[index].label
      setHighlightedIndex(index)
      input.value = selected
      input.setSelectionRange(value.length, selected.length)
    }, 1 / 30)
  }, [items, setHighlightedIndex])

  const onInput = (e: React.FormEvent<HTMLInputElement>) => {
    debouncedAutoCompleteHandler.cancel()
    const { value } = e.currentTarget
    if (value.length > textSearchRef.current.length) {
      debouncedAutoCompleteHandler(e.currentTarget, value)
    }
    textSearchRef.current = value
  }

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === 'Escape') {
      e.currentTarget.setSelectionRange(Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER)
    }
  }

  return {
    onKeyUp,
    onInput,
  }
}
