import {
  Button,
  Box,
  Center,
  useMultiStyleConfig,
  List,
  ListItem,
  Text,
  Icon,
  VStack,
  chakra,
} from '@chakra-ui/react'
import Head from 'next/head'

import { IconName } from '@reward-platform/ancillaries-schemas/common'
import { Image } from '@reward-platform/lift/components'
import useIntl from '~/hooks/useIntl/useIntl'
import { convertIconSetToChakraSVG } from '~/utils/Icon.utils'
import { getClientAuthPath } from '~/utils/auth'
import { getPublicAssetSrc } from '~/utils/assets'

const BARCMemberLayout = () => {
  const styles = useMultiStyleConfig('Layout')
  const intl = useIntl()
  const t = {
    upgradeText: intl.formatMessage({
      id: 'upgrade-text',
      defaultMessage: 'Upgrade to the Executive Club <bold> for free </bold> to continue',
    }),
    upgradeText2: intl.formatMessage({
      id: 'upgrade-text2',
      defaultMessage: 'Executive Club members get access to our reward platform where you can:',
    }),
    upgradeBenefit1: intl.formatMessage({
      id: 'upgrade-benefit1',
      defaultMessage: 'Collect Avios when you spend cash',
    }),
    upgradeBenefit2: intl.formatMessage({
      id: 'upgrade-benefit2',
      defaultMessage: 'Spend your Avios on fantastic rewards',
    }),
    upgradeBenefit3: intl.formatMessage({
      id: 'upgrade-benefit3',
      defaultMessage: 'Track and manage your Avios balance',
    }),
    upgradeCta: intl.formatMessage({
      id: 'upgrade-cta',
      defaultMessage: 'Join our Executive Club now',
    }),
    redirectUrl: intl.formatMessage({
      id: 'redirect-url',
      defaultMessage: 'https://www.britishairways.com/travel/EXECENROL/public/en_gb',
    }),
    backToLogin: intl.formatMessage({
      id: 'back-to-login',
      defaultMessage: 'Back to login',
    }),
  }
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>British Airways, upgrade to Executive Club</title>
      </Head>
      <Center h="100vh" p="6" flexDir="column" __css={styles.barcLayoutWrapper}>
        <VStack spacing={6} maxW="23rem" alignItems="flex-start">
          <Box alignSelf="center">
            <Image
              src={getPublicAssetSrc('/assets/british-airways-executive-club.svg')}
              alt="British Airways Executive Club Logo"
              height={80}
              width={300}
            />
          </Box>
          <Box __css={styles.textBg} p={4}>
            <Text fontSize="2xl" fontFamily="Mylius Modern">
              {t.upgradeText}
            </Text>
          </Box>
          <Box>
            <Text fontSize="xl" fontFamily="Mylius Modern">
              {t.upgradeText2}
            </Text>
          </Box>
          <List spacing={2}>
            <ListItem>
              <Icon as={convertIconSetToChakraSVG(IconName.Enum['collect-avios'])} />
              {t.upgradeBenefit1}
            </ListItem>
            <ListItem>
              <Icon as={convertIconSetToChakraSVG(IconName.Enum['spend-avios'])} />
              {t.upgradeBenefit2}
            </ListItem>
            <ListItem>
              <Icon as={convertIconSetToChakraSVG(IconName.Enum.track)} />
              {t.upgradeBenefit3}
            </ListItem>
          </List>
          <Button as="a" target="_blank" href={t.redirectUrl} width="100%" textDecoration="none">
            {t.upgradeCta}
          </Button>
          <chakra.a href={getClientAuthPath('LOGOUT')} textAlign="center" width="100%">
            {t.backToLogin}
          </chakra.a>
        </VStack>
      </Center>
    </>
  )
}

export default BARCMemberLayout
