import React, { ImgHTMLAttributes, useState } from 'react'
import { chakra } from '@chakra-ui/react'

interface Props extends ImgHTMLAttributes<any> {
  fallback: string
  objectFit?: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down'
}

export const ImageWithFallback = ({ fallback, src, objectFit, ...props }: Props) => {
  const [imgSrc, setImgSrc] = useState<string | undefined>(src)
  const [errorTriggered, setErrorTriggered] = useState(false)
  const onError = () => {
    setImgSrc(fallback)
    setErrorTriggered(true)
  }

  return (
    <chakra.img
      height="100%"
      width="100%"
      objectFit={errorTriggered || !imgSrc ? 'scale-down' : objectFit || 'cover'}
      src={imgSrc || fallback}
      onError={onError}
      {...props}
    />
  )
}
