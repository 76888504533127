import { PlatformType, PlatformTypeSchema } from '~/schemas/platform'

// Checks the environment variable set for the platform type of the build
export const getPlatformType = (): PlatformType => {
  const platformParse = PlatformTypeSchema.safeParse(process.env.NEXT_PUBLIC_PLATFORM_TYPE)
  if (platformParse.success) {
    return platformParse.data
  }
  return 'whitelabel'
}
export const isAviosPlatform = () => getPlatformType() === 'avios'
export const isWhitelabelPlatform = () => getPlatformType() === 'whitelabel'

export const getPlatformBasePath = () =>
  isAviosPlatform() && process.env.NEXT_PUBLIC_AVIOS_BASE_PATH

// Adds base path to a pathname if avios.com platform type
// Should be added to all pathnames for avios.com routing integration to work
export const getPlatformPathname = (pathname: string) => {
  const basePath = getPlatformBasePath()
  if (basePath && !pathname.includes(basePath)) {
    return `${basePath}${pathname}`
  }
  return pathname
}
