import { helpers } from './base'

export const commonText = {
  fontSize: 'xs',
  lineHeight: 'base',
  textDecoration: 'none',
}

export const variantExperimentText = {
  display: 'flex',
  alignItems: { base: 'flex-start', sm: 'center' },
  flexDirection: { base: 'column', sm: 'row' },
  fontWeight: 'normal',
  lineHeight: 9,
}

export const GuestRatings = helpers.defineMultiStyleConfig({
  baseStyle: {
    text: {
      color: 'brand.primary.midnightBlue',
      ...commonText,
    },
  },
  variants: {
    experiment: {
      text: {
        ...variantExperimentText,
        fontSize: '2xl',
      },
      reviewsCount: {
        fontWeight: 'light',
      },
      guestRating: {
        backgroundColor: 'brand.alpha.blue',
        padding: 'var(--chakra-space-2) var(--chakra-space-4)',
        border: '1px solid rgba(46, 92, 153, 0.2)',
        borderRadius: 'sm',
        marginRight: 2,
      },
      icon: {
        marginBottom: '2.5',
        marginRight: '1.5',
        '> path': {
          fill: 'brand.confirmationGreen',
        },
      },
      rightChevronIcon: {
        paddingLeft: 2,
      },
    },
  },
})
