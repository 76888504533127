import { paymentFormContainerHelper } from './base'

export const PaymentFormContainer = paymentFormContainerHelper.defineMultiStyleConfig({
  baseStyle: paymentFormContainerHelper.definePartsStyle({
    cashDueValue: {
      fontWeight: 'semibold',
    },
    cashDueLabel: {
      fontWeight: 'normal',
    },
    paymentFormParentWrapper: {
      bg: 'brand.white',
      boxShadow: 'brand.boxShadow',
      p: { base: 4, md: 10 },
    },
    termsAndConditionsWrapper: {
      'button, a': {
        fontSize: 'medium',
        fontWeight: 'semibold',
      },
    },
  }),
})
