import { helpers } from './base'

export const WhatToExpect = helpers.defineMultiStyleConfig({
  baseStyle: {
    passBy: {
      fontWeight: 'light',
    },
    header: {
      color: 'brand.primary.midnightBlue',
    },
    optionalText: {
      lineHeight: 5,
      fontSize: 'sm',
      fontWeight: 'light',
    },
    durationContainer: {
      backgroundColor: 'brand.baseGray.5',
      padding: 'var(--chakra-space-1) var(--chakra-space-2)',
      borderRadius: 'lg',
    },
    whatToExpectContainer: {
      '> ul': {
        mt: 10,
      },
      '> ul b': {
        fontWeight: 'normal',
      },
      '> ul li+li': {
        mt: 10,
      },
    },
    whatToExpectTitle: {
      color: 'brand.primary.midnightBlue',
    },
  },
})
