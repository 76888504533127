// import { replaceCarItemCardHelper } from './base'

import { replaceCarItemCardHelper } from './base'

export const ReplaceCarItemCard = replaceCarItemCardHelper.defineMultiStyleConfig({
  baseStyle: replaceCarItemCardHelper.definePartsStyle({
    content: {
      img: {
        borderRadius: 'lg',
      },
    },
    heading: {
      h4: {
        fontWeight: 'medium',
      },
    },
    includedContainer: {
      bg: 'brand.teal5',
      borderRadius: 'base',
      padding: 4,
    },
    includedTextContainer: {
      p: {
        fontSize: 'sm',
        fontWeight: 'normal',
        color: 'brand.coolgrey10',
        _first: {
          fontWeight: 'medium',
        },
      },
    },
  }),
})
