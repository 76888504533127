import { createContext } from 'react'
import { Document } from '@contentful/rich-text-types'
import { NotificationMessageType } from '@reward-platform/lift/components/NotificationMessage/NotificationMessage.types'
import { Notification, NotificationAction } from './notificationReducer'

export interface NotificationContext {
  notifications: Notification[]
  addNotification: (
    msg: string,
    errorType?: NotificationMessageType,
    action?: NotificationAction,
    richText?: Document
  ) => void
  removeNotification: (id: string) => void
}

export const NotificationContext = createContext<NotificationContext | undefined>(undefined)

export default NotificationContext
