import { helpers } from './base'

export const PriceBreakdownContainer = helpers.defineMultiStyleConfig({
  baseStyle: {
    aviosDueLabel: {
      fontWeight: 'normal',
    },
    aviosDueValue: {
      fontSize: 'md',
      fontWeight: 'semibold',
    },
    cashDueLabel: {
      fontWeight: 'normal',
    },
    cashDueValue: {
      fontSize: '2xl',
      fontWeight: 'light',
    },
  },
})
