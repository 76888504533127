import { createMultiStyleConfigHelpers, keyframes } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'modalWrapper',
  'modalBody',
  'actions',
  'results',
  'loadWrapper',
  'screenReaderText',
  'button',
  'filtersIcon',
  'filtersButton',
  'resultItem',
])

const filtersIconAnimation = keyframes`
  0% {
    transform: translateY(0.75rem);
  }
  30% {
    transform: translateY(0.8rem);
  }
  70% {
    transform: translateY(0.3rem);
  }
  100% {
    transform: translateY(0.375rem);
  }
`

const black = 'black'
const zeroSpacing = '0 !important'

export const ResultsMapView = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    modalWrapper: {
      position: 'fixed',
      inset: 0,
      overscrollBehavior: 'contain',
      width: 'full',
      height: 'full',
      backgroundColor: 'white',
      margin: zeroSpacing,
      padding: zeroSpacing,
      overflow: 'hidden',
    },

    modalBody: {
      width: 'full',
      height: 'full',
      display: 'grid',
      backgroundColor: 'white',
      padding: zeroSpacing,
      position: { base: 'fixed', lg: 'relative' },
      top: 0,
      left: 0,
      gridTemplate: {
        base: `'actions' auto
        'map' 1fr
        'results' 0
        / 1fr`,
        lg: `
          'results actions' auto
          'results map' 1fr
          / minmax(min-content, 50vw) 1fr`,
      },
    },

    actions: {
      gridArea: 'actions',
      alignItems: 'center',
      paddingInline: 4,
      paddingBlock: 2,
      display: { base: 'flex', sm: 'grid' },
      gridTemplateColumns: { sm: 'auto 1fr auto' },
      gridGap: 4,
      justifyContent: 'space-between',
      color: black,
    },

    results: {
      zIndex: 1,
      position: 'relative',
      gridArea: 'results',
      px: { base: 0, lg: 4 },
      py: { base: 0, lg: 8 },
      backgroundColor: 'white',
      overflow: { lg: 'auto' },
    },

    loadWrapper: {
      alignItems: 'center',
      justifyContent: 'center',
      width: 'full',
      height: 'full',
    },

    screenReaderText: {
      position: 'absolute',
      overflow: 'hidden',
      height: 'auto',
      width: 'px',
      border: 0,
      margin: 0,
      padding: 0,
      whiteSpace: 'nowrap',
      clip: 'rect(0 0 0 0)',
    },

    button: {
      position: 'relative',
      width: 8,
      height: 8,
      border: 'none',
      backgroundColor: 'transparent',
      borderRadius: 'base',
      transition: 'background-color 0.25s ease',
      cursor: 'pointer',

      '> svg': {
        color: 'black',
      },
    },

    filtersButton: {
      display: 'flex',
      alignItems: 'center',
      padding: 1,
      backgroundColor: 'transparent',
      borderRadius: 'base',
      border: '2px solid transparent',
      gap: 2,
      transition: '0.25s background-color ease, 0.25s border-color ease',
      cursor: 'pointer',

      _hover: {
        backgroundColor: 'transparent',
      },

      _focus: {
        backgroundColor: 'transparent',
      },

      '&:hover > span > span': {
        animation: `${filtersIconAnimation} 1s cubic-bezier(0.68, -0.55, 0.27, 1.55) alternate infinite`,

        '&:nth-of-type(2)': {
          animationDirection: 'alternate-reverse',
          animationDelay: '0.075s',
        },

        '&:last-child': {
          animationDelay: '0.15s',
        },
      },
    },

    filtersIcon: {
      display: 'flex',
      overflow: 'hidden',
      width: 6,
      height: 5,
      color: black,

      '> span': {
        position: 'relative',
        width: 2,
        height: 0.5,
        backgroundColor: black,
        transform: 'translateY(0.75rem)',

        '&:nth-of-type(2)': {
          transform: 'translateY(0.375rem)',
        },

        '&::before': {
          top: 0.5,
          content: "''",
          position: 'absolute',
          left: '50%',
          width: 0.5,
          height: 5,
          marginLeft: '-1px',
          backgroundColor: 'inherit',
        },

        '&::after': {
          bottom: 1,
          content: "''",
          position: 'absolute',
          left: '50%',
          width: 0.5,
          height: 5,
          marginLeft: '-1px',
          backgroundColor: 'inherit',
        },
      },
    },

    resultItem: {
      position: { base: 'absolute', lg: 'static' },
      top: 0,
      left: 4,
      right: 4,
      transition: { base: 'none', lg: 'transform 0.25s ease, filter 0.25s ease' },
      display: { base: 'none', lg: 'list-item' },

      '& + &': {
        marginTop: 8,
      },

      '&[data-selected="true"]': {
        transitionDelay: '0.3s',
        transform: { base: 'translateY(calc(-100% + -5vh))', lg: 'translateY(-0.5rem)' },
        filter: { md: 'drop-shadow(0 8px 0 var(--chakra-colors-cityGreen))' },
        display: 'list-item',
      },

      '#pricing-ladder': {
        zIndex: { base: 1, lg: -1 },
        transform: { base: 'translateY(-100%)', lg: 'translateY(0.5rem)' },

        _before: {
          marginTop: { base: 8, lg: 'unset' },
        },
      },
    },
  }),
})
