import { ComponentStyleConfig } from '@chakra-ui/react'

export const PaymentOptionsContainer: ComponentStyleConfig = {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    mb: 6,
  },
}
