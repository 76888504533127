import { alPrimaryTeal, alWhite } from '@reward-platform/lift/theme/aer_lingus/variables'
import { priceBreakdownStickyHelpers } from './base'

export const PriceBreakdownSticky = priceBreakdownStickyHelpers.defineMultiStyleConfig({
  baseStyle: priceBreakdownStickyHelpers.definePartsStyle({
    stickyWrapper: {
      borderRadius: '2rem',
      '&[data-is-pinned=true] > div': {
        boxShadow: 'floating',
      },
    },
    stickyContainer: {
      color: alWhite,
      backgroundColor: alPrimaryTeal,
    },
    priceContainer: {
      span: {
        _second: { fontSize: 'lg', fontWeight: 'medium' },
      },
    },
    priceLabel: {
      fontSize: '3xl',
      fontWeight: 'medium',

      _invalid: {
        color: 'brand.red',
      },
    },
  }),
})
