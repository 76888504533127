import { helpers } from './base'

export const TravelerQuantityPicker = helpers.defineMultiStyleConfig({
  baseStyle: {
    minMax: {
      fontWeight: 'normal',
      fontSize: 'sm',
    },
    traveler: {
      fontWeight: 'normal',
    },
    travelerType: {
      fontWeight: 'bold',
    },
  },
  variants: {
    error: {
      listItem: {
        '&:first-of-type': {
          border: '1px solid rgba(var(--chakra-colors-rgb-alRed), 0.3)',
          color: 'brand.red',
          backgroundColor: 'brand.red5',
        },
      },
      errorMessageBox: {
        color: 'brand.red',
      },
    },
  },
})
