import { ComponentMultiStyleConfig } from '@chakra-ui/react'
import { merge } from 'lodash'
import { Pagination as base } from './base'

const accentPrimary = 'fg.accent.primary'
const accentSecondary = 'fg.accent.secondary'

export const Pagination: ComponentMultiStyleConfig = merge({}, base, {
  baseStyle: {
    arrow: {
      color: accentSecondary,

      _hover: {
        color: accentPrimary,
      },
    },
    ellipses: {
      color: accentSecondary,
      fontWeight: 'semibold',
    },
    pip: {
      color: accentSecondary,
      border: 'var(--alto-sem-border-width-md) solid',
      borderColor: accentSecondary,
      fontWeight: 'semibold',

      _hover: {
        color: 'fg.accent.onVibrant',
        borderColor: accentPrimary,
        background: accentPrimary,
      },

      '&[aria-current="true"]': {
        color: 'fg.accent.onVibrant',
        background: accentSecondary,
        _hover: {
          background: accentPrimary,
        },
      },
    },
  },
})
