import { ComponentMultiStyleConfig } from '@chakra-ui/react'
import { merge } from 'lodash'
import { typography } from '../../../theme/british_airways/typography'
import { Pagination as base } from './base'

const midnightBlue = 'brand.primary.midnightBlue'
const highlightBlue = 'brand.highlight.blue'

export const Pagination: ComponentMultiStyleConfig = merge({}, base, {
  baseStyle: {
    arrow: {
      color: midnightBlue,

      _hover: {
        color: highlightBlue,
      },
    },
    ellipses: {
      color: midnightBlue,
      fontWeight: typography.weights.semibold,
    },
    pip: {
      color: midnightBlue,
      border: '2px solid',
      borderColor: midnightBlue,
      fontWeight: typography.weights.semibold,

      _hover: {
        color: 'brand.white',
        borderColor: highlightBlue,
        background: highlightBlue,
      },

      '&[aria-current="true"]': {
        color: 'brand.white',
        background: midnightBlue,
        _hover: {
          background: highlightBlue,
        },
      },
    },
  },
})
