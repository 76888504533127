import { helpers } from './base'

export const RecentSearches = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      h4: {
        padding: {
          base: 'var(--chakra-sizes-6) 0',
          lg: 6,
        },
        fontSize: {
          base: 'lg',
          lg: 'xl',
        },
        fontWeight: 'light',
      },
    },
    item: {
      '&.chakra-button': {
        borderBottom: '1px solid #F1F1F1',
        borderTop: '1px solid #F1F1F1',
        margin: {
          base: 0,
          lg: '0 var(--chakra-sizes-4)',
        },
        padding: {
          base: 'var(--chakra-sizes-3) 0',
          lg: 'var(--chakra-sizes-3) var(--chakra-sizes-6)',
        },
        _hover: {
          '*': {
            color: 'brand.highlight.blue',
          },
        },
      },
      svg: {
        marginRight: {
          base: 2,
          lg: 4,
        },
      },
      p: {
        color: 'brand.primary.midnightBlue',
      },
      'p:first-of-type': {
        fontWeight: 'semibold',
      },
      'p:nth-of-type(2)': {
        fontWeight: 'light',
      },
      'p:nth-of-type(3)': {
        fontWeight: 'normal',
        marginTop: 3,
      },
    },
  },
})
