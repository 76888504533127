import {
  BasketItem,
  OnlyCashPaymentOption,
  PaymentOption,
} from '@reward-platform/order-schemas/basket'

export const getPaymentOptionWithHighestCashAmount = (
  basketItem: BasketItem
): PaymentOption | OnlyCashPaymentOption | undefined => {
  const { paymentOptions } = basketItem

  if (!paymentOptions) {
    return undefined
  }

  if (!paymentOptions.onlyCashPaymentOption && paymentOptions.options.length === 0) {
    return undefined
  }

  if (paymentOptions.onlyCashPaymentOption) {
    return paymentOptions.onlyCashPaymentOption
  }

  return paymentOptions.options.reduce((max, currentOption) => {
    const maxAmount = max.cashValue?.amount ?? 0
    const currentAmount = currentOption.cashValue?.amount ?? 0
    return maxAmount > currentAmount ? max : currentOption
  }, paymentOptions.options[0])
}
