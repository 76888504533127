import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'wrapper',
  'text',
  'clearButton',
  'cantFind',
  'title',
])

export const NoResults = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginRight: {
        base: '5%',
        md: '15%',
      },
      marginLeft: {
        base: '5%',
        md: '15%',
      },
      marginTop: '5%',
    },
    text: {
      marginTop: 8,
      marginBottom: 8,
    },
    clearButton: {
      width: 'fit-content',
      padding: '10px 21px',
    },
    title: {
      textAlign: 'center',
    },
  }),
})
