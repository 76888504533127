import { helpers } from './base'

export const StickyBanner = helpers.defineMultiStyleConfig({
  baseStyle: {
    wrapper: {
      backgroundColor: 'bg.accent.subtle.default',
    },
    icon: {
      color: 'fg.accent.onSubtle',
    },
    message: {
      color: 'fg.accent.onSubtle',
      strong: {
        fontWeight: 'normal',
      },
      a: {
        textDecoration: 'underline',
        strong: {
          fontWeight: 'semibold',
        },
      },
    },
  },
})
