import { z } from 'zod'
import {
  BasketItem,
  CarHireBasketItem,
  ExperienceBasketItem,
  HotelBasketItem,
  FlightBasketItem,
  PaymentOptions,
} from '../basket'
import { CarHireItemType, ExperienceItemType, FlightItemType, HotelItemType } from '../common'

export type CreateSelectedPaymentOptionsSchemaParams = {
  hotelBasketItem?: HotelBasketItem
  carHireBasketItem?: CarHireBasketItem
  experienceBasketItem?: ExperienceBasketItem
  flightBasketItem?: FlightBasketItem

  // Defines whether the schema should allow an item that does not have payment option assigned.
  // For example in a scenario where a new item has been added the basket but the user hasn't
  // selected a payment option yet.
  allowItemWithoutPaymentOption?: boolean
}

const getPaymentOptionsIds = (paymentOptions: PaymentOptions | undefined) =>
  paymentOptions
    ? [
        ...(paymentOptions.onlyCashPaymentOption?.id
          ? [paymentOptions.onlyCashPaymentOption.id]
          : []),
        ...(paymentOptions.options.map(({ id }) => id) ?? []),
      ]
    : []

const createPaymentOptionIdSchema = (id: string, options: string[]) =>
  z
    .object({
      basketItemId: z.literal(id),
      paymentOptionId: z.string(),
      selectedByDefault: z.boolean(),
    })
    .refine(({ paymentOptionId }) => options.includes(paymentOptionId), {
      message: `paymentOptionId must be one of: ${options.join(',')}`,
    })

export const createSelectedPaymentOptionsSchema = ({
  hotelBasketItem,
  carHireBasketItem,
  experienceBasketItem,
  flightBasketItem,
  allowItemWithoutPaymentOption = false,
}: CreateSelectedPaymentOptionsSchemaParams) => {
  const [
    hotelPaymentOptionIds,
    carHirePaymentOptionIds,
    experiencePaymentOptionsIds,
    flightPaymentOptionsIds,
  ] = [hotelBasketItem, carHireBasketItem, experienceBasketItem, flightBasketItem].map((item) =>
    getPaymentOptionsIds(item?.paymentOptions)
  )

  const createItemSchema = (basketItem: BasketItem | undefined, paymentOptionIds: string[]) => {
    const schema = z.preprocess(
      (val) => {
        if (basketItem) {
          return val
        }

        return undefined
      },
      basketItem ? createPaymentOptionIdSchema(basketItem.id, paymentOptionIds) : z.undefined()
    )

    return allowItemWithoutPaymentOption ? schema.optional() : schema
  }

  return z.object({
    [HotelItemType]: createItemSchema(hotelBasketItem, hotelPaymentOptionIds),
    [CarHireItemType]: createItemSchema(carHireBasketItem, carHirePaymentOptionIds),
    [ExperienceItemType]: createItemSchema(experienceBasketItem, experiencePaymentOptionsIds),
    [FlightItemType]: createItemSchema(flightBasketItem, flightPaymentOptionsIds),
  })
}

export type SelectedPaymentOptions = z.infer<ReturnType<typeof createSelectedPaymentOptionsSchema>>
