import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'ratingStar',
  'ratingWrapper',
  'ratingTextWrapper',
  'additionalText',
  'ratingText',
])

export const Ratings = helpers.defineMultiStyleConfig({
  baseStyle: {
    ratingStar: {
      mr: '2px',
      display: 'flex',
    },
    ratingWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: 4,
    },
    ratingTextWrapper: {
      whiteSpace: 'nowrap',
    },
    ratingText: {
      mr: '1.5',
      ml: '1.5',
    },
  },
})
