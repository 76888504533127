import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'agedLabel',
  'roomNumberTitle',
  'travellerLabel',
  'roomNumberContainer',
  'childrenAgePickerContainer',
])
export const TravelerRoom = helpers.defineMultiStyleConfig({
  baseStyle: {
    agedLabel: {
      fontSize: 'sm',
      marginTop: 1,
    },
    roomNumberTitle: {
      marginRight: 2,
    },
    travellerLabel: {
      alignSelf: 'flex-start',
      fontWeight: 'semibold',
      fontSize: 'sm',
    },
    roomNumberContainer: {
      marginBottom: 6,
    },
    childrenAgePickerContainer: {
      mt: 6,
    },
  },
})
