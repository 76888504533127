/* eslint-disable sonarjs/no-duplicate-string */
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'priceInfoContainer',
  'cabinClassName',
  'prices',
  'aviosPrice',
  'taxesAndFeesText',
  'seatsAvailable',
  'icon',
  'baggagePopoverContent',
  'baggageIconContainer',
])

export const PriceInfo = helpers.defineMultiStyleConfig({
  baseStyle: {
    priceInfoContainer: {
      cursor: 'pointer',
      backgroundColor: 'transparent',
      display: 'grid',
      textAlign: 'left',
      gridTemplateAreas: {
        base: "'cabin-class prices info-button' 'seats-available prices info-button'",
        md: "'cabin-class .' 'prices prices' 'seats-available info-button'",
      },
      gridTemplateColumns: {
        base: '1fr 1fr auto',
        md: '1fr auto',
      },
      gridTemplateRows: {
        base: 'unset',
        md: '1fr auto 1fr',
      },
      padding: { base: '0.5rem 1rem', md: 4 },
      gap: 1,
      '> p, p': {
        // Override project defined margins
        margin: 0,
      },
      _disabled: {
        // Make it unclickable if soldOut
        pointerEvents: 'none',
        cursor: 'auto',

        backgroundColor: 'brand.cloudWhite',
        _hover: {
          backgroundColor: 'brand.cloudWhite',
        },
      },
    },
    cabinClassName: {
      gridArea: 'cabin-class',
      fontSize: { base: 'xs', md: 'sm' },
      fontWeight: { base: 'medium', md: 'unset' },
    },
    prices: {
      maxWidth: { base: '28', xs: 'unset' },
      gridArea: 'prices',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: {
        md: 'center',
        base: 'space-between',
      },
    },
    labelWrapper: {
      display: 'inline-block',
    },
    iconWrapper: {
      display: 'flex',
      gridArea: {
        base: 'info-button',
        md: 'seats-available',
      },
      justifyContent: 'flex-end',
      justifySelf: 'flex-end',
      alignSelf: {
        base: 'center',
        md: 'unset',
      },

      '.chakra-popover__content': {
        fontSize: 'sm',
        maxWidth: {
          base: '20rem',
          xl: '25rem',
        },
      },
    },
    aviosPrice: {
      // Make sure the larger text will be aligned with the smaller one
      lineHeight: '80%',
      fontSize: { base: 'lg', md: '1.625rem' },
      fontWeight: 'semibold',
    },
    aviosText: {
      fontSize: { base: 'sm' },
    },
    taxesAndFeesText: {
      fontSize: { base: 'xs', md: 'sm' },
      letterSpacing: '0.06px',
    },
    seatsAvailable: {
      gridArea: 'seats-available',
      alignSelf: 'flex-end',
    },
    baggagePopoverContent: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',

      li: {
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
      },
    },
    baggageIconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 8,
      height: 8,
      borderRadius: '50%',
      border: '1px solid',
      boxShadow: 'var(--chakra-shadows-light)',
    },
  },
})
