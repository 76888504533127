import { helpers } from './base'

export const CarCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    carCardWrapper: {
      border: 'none',
      boxShadow: 'none',
      backgroundColor: 'brand.white',
      borderRadius: 'sm',
    },
    heading: {
      fontSize: '2xl',
      lineHeight: 'base',
    },
    name: {
      fontSize: 'xs',
      lineHeight: 'tall',
    },
    attributeListItem: {
      fontSize: 'xs',
      lineHeight: 'tall',
      fontWeight: 'light',
    },
    footerContainer: {
      gap: '2.5',
    },
    cancellationFeesAndPoliciesContainer: {
      h1: {
        fontSize: '2xl',
        my: { base: 4, lg: 6 },
      },
      strong: {
        fontWeight: 'light',
      },
      p: {
        fontSize: { base: 'sm', lg: 'md' },
      },
    },
    policyLink: {
      color: 'brand.highlight.blue',
      fontSize: { base: 'sm', lg: 'md' },

      _hover: {
        textDecoration: 'none',
      },
    },
    duration: {
      fontSize: 'xs',
    },
    secondaryAttributeWrapper: {
      backgroundColor: 'brand.white',
    },

    fuelBadge: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 5,
      backgroundColor: 'brand.backgroundBlue500',
      marginBottom: 2,
      borderRadius: 1,

      ' > div': {
        columnGap: 2.5,
      },

      svg: {
        width: 6,
        height: 6,
      },
    },

    electricBadge: {
      svg: {
        color: 'brand.confirmationGreen',
      },
    },

    hybridBadge: {
      svg: {
        color: 'brand.primaryBlue',
      },
    },
  },
})
