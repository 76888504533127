import React from 'react'
import { IconName } from '../Icon/Icon.types'

const defaultIconName: IconName = 'down-chevron'
const defaultIconSize = 16
export const defaultIconProps = {
  icon: defaultIconName,
  iconColor: 'brand.inputIcon',
  iconSize: defaultIconSize,
}

export type ChakraListBoxProps = {
  children: Option[]
  value?: string
  displayValue?: string
  onChange: (value?: string) => void
  name: string
  label: string | React.JSX.Element
  error?: string
  note?: string
  isDisabled?: boolean
  tabIndex?: number
  isRequired?: boolean
  dataTestId?: string
  autoComplete?: string
}

export type Option = {
  label: string | React.JSX.Element
  value: string
  disabled?: boolean
}
