import { helpers } from './base'

export const SectionNavigation = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    navLink: {
      color: 'fg.primary',
      _active: {
        color: 'fg.accent.primary',
      },
    },
    navItem: {
      '& + &::before': {
        borderColor: 'border.tertiary',
      },
    },
  }),
})
