export type IconSize = keyof typeof IconSizes

export enum IconSizes {
  xxxs = '10px',
  xxs = '12px',
  xs = '14px',
  sm = '16px',
  md = '18px',
  lg = '20px',
  xl = '24px',
  xxl = '28px',
  xxxl = '32px',
}
