import { NextPageContext } from 'next'
import { NextServerError } from './NextServerError'

const BUSINESS_MESSAGE = 'Fatal error has occurred, please refresh the application.'

type NextError = NonNullable<NextPageContext['err']>

export class NextPageError extends NextServerError {
  constructor(error: NextError) {
    super(error)

    this.developerMessage = error.message
    this.businessMessage = BUSINESS_MESSAGE
    if (error.statusCode) {
      this.httpStatus = error.statusCode
    }
    this.unrecoverable = true
  }
}
