import { helpers } from './base'

const basegrey100 = 'var(--chakra-colors-brand-baseGray-100)'
const blue = 'brand.primary.blue'
const midnightBlue = 'brand.primary.midnightBlue'

export const RouteInfo = helpers.defineMultiStyleConfig({
  baseStyle: {
    accordion: {
      borderRadius: 'sm',
      boxShadow: '0px 2px 4px rgba(0,0,0, 0.1)',
      backgroundColor: 'brand.white',
      padding: 6,
    },
    bulletPoint: {
      color: blue,
    },
    title: {
      bg: blue,
      color: 'brand.white',
    },
    infoTitle: {
      color: midnightBlue,
    },
    infoText: {
      color: basegrey100,
    },
    contentTitle: {
      color: 'brand.black',
    },
    contentBody: {
      color: midnightBlue,
    },
  },
})
