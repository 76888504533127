import { helpers } from './base'

export const ExperienceTitle = helpers.defineMultiStyleConfig({
  baseStyle: {
    experienceTitleContainer: {
      color: 'brand.primary.midnightBlue',
      borderRadius: 'sm',
      boxShadow: 'brand.boxShadow',
      backgroundColor: 'brand.white',
      padding: 6,
    },
    ratingsAndChips: {
      columnGap: 3,
    },
    address: {
      paddingTop: 6,
    },
    icons: {
      marginTop: 6,
      marginBottom: 0,
      columnGap: 6,
      rowGap: 3,
      color: 'brand.primary.midnightBlue',

      '> div': {
        columnGap: '4-5',
      },
      '> div > span': {
        fontSize: 'md',
        fontWeight: 'light',
      },
    },
    experienceTitle: {
      fontSize: '6xl',
      fontWeight: 'light',
    },
  },
})
