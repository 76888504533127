import { helpers } from './base'

export const CancelOrderPaymentForm = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    paymentFormParentWrapper: {
      h3: {
        fontSize: 'md',
        fontWeight: 'medium',
      },
    },
    paymentCashDueWrapper: {
      'p: first-child': {
        fontSize: 'sm',
      },
      'p: last-child': {
        fontSize: 'lg',
        color: 'brand.coolgrey10',
      },
    },
    stickyButtonDisclaimer: {
      'p: first-child': {
        fontSize: '3xl',
      },
      'p: last-child': {
        color: 'brand.coolgrey10',
      },
    },
  }),
})
