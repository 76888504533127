import { helpers } from './base'

export const Menu = helpers.defineMultiStyleConfig({
  variants: {
    header: {
      button: {
        color: 'fg.accent.secondary',
        _active: {
          color: 'fg.accent.secondary',
        },
      },
    },
  },
})
