import { ComponentMultiStyleConfig } from '@chakra-ui/react'
import merge from 'lodash/merge'
import { Switch as base } from './base'

const midnightBlue = 'brand.primary.midnightBlue'
const white = 'brand.white'
const baseGray100 = 'brand.baseGray.100'
const primaryBlue = 'brand.primary.blue'
const blackDropShadow = '0px 4px 4px 0px var(--chakra-colors-black)'

export const Switch: ComponentMultiStyleConfig = merge({}, base, {
  baseStyle: {
    switchButton: {
      color: midnightBlue,
      fontSize: { base: 'sm', lg: 'md' },
      _focus: {
        outlineColor: primaryBlue,
        outlineWidth: '1px',
        bgColor: 'brand.cloudWhite',
        borderRadius: 'sm',
        outline: 'solid',
        '-webkit-tap-highlight-color': 'transparent',
      },
    },
    switchIcon: {
      border: '1px',
      borderColor: midnightBlue,
      bgColor: white,
      height: { base: '34px', lg: '40px' },
      width: { base: '60px', lg: '70px' },
      _after: {
        top: { base: '2', lg: '2.5' },
        left: { base: '1.5', lg: '3' },
        width: { base: '14px', lg: '16px' },
        height: { base: '14px', lg: '16px' },
      },
    },
  },
  variants: {
    isActive: {
      switchOffValue: { fontWeight: { base: 'semibold', lg: 'normal' } },
      switchOnValue: { fontWeight: { base: 'semibold', lg: 'medium' } },
      switchIcon: {
        bgColor: midnightBlue,
        _after: { bgColor: white, transform: 'translateX(2rem)' },
        _hover: {
          bgColor: primaryBlue,
          borderColor: primaryBlue,
          dropShadow: blackDropShadow,
        },
        _active: { bgColor: 'brand.highlight.blue' },
      },
      switchButton: {
        _focus: {
          '> div:nth-child(2)': {
            bgColor: primaryBlue,
            borderColor: primaryBlue,
            dropShadow: blackDropShadow,
          },
        },
        _disabled: {
          '> div:nth-child(2)': {
            bgColor: baseGray100,
            border: '0px',
            _after: {
              bgColor: 'brand.white',
            },
          },
        },
      },
    },
    notActive: {
      switchOffValue: { fontWeight: { base: 'semibold', lg: 'medium' } },
      switchOnValue: { fontWeight: { base: 'semibold', lg: 'normal' } },
      switchIcon: {
        _after: { bgColor: midnightBlue },
        _active: {
          _after: { bgColor: 'brand.highlight.blue' },
        },
      },
      switchButton: {
        _disabled: {
          '> div:nth-child(2)': {
            borderColor: baseGray100,
            border: '0px',
            _after: {
              bgColor: baseGray100,
            },
          },
        },
      },
    },
  },
})
