import { helpers } from './base'

export const FlightCardBanner = helpers.defineMultiStyleConfig({
  baseStyle: {
    aviosOnlyContainer: {
      bg: 'brand.teal10',
      borderColor: 'brand.darkCityGreen',
    },
  },
  variants: {
    soldOut: {
      aviosOnlyContainer: {
        borderColor: 'brand.coolgrey2',
      },
    },
  },
})
