/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

const primaryTeal = 'var(--chakra-colors-brand-primaryTeal)'
const teal = 'var(--chakra-colors-brand-teal)'
const teal15 = 'var(--chakra-colors-brand-teal15)'

const selectedDate = {
  position: 'relative',
  backgroundColor: `${primaryTeal} !important`,
}

const hoverDate = {
  position: 'relative',
  backgroundColor: `${teal} !important`,
}

const focusDate = {
  position: 'relative',
  backgroundColor: `${teal15} !important`,
}

export const DatePicker = helpers.defineMultiStyleConfig({
  baseStyle: {},
})

export const DatePickerGlobalTheme = {
  '.react-datepicker': {
    boxShadow: {
      base: 'unset',
      md: 'brand',
    },
  },
  '.react-datepicker__navigation-icon': {
    _before: {
      borderColor: 'brand.primaryTeal',
    },
  },
  '.react-datepicker__input-container': {
    '.date-picker-from__label, .date-picker-to__label': {
      color: 'brand.coolgrey10',
      fontSize: '0.65rem',
    },
    input: {
      fontSize: '1rem',
      borderColor: 'brand.coolgrey4',
      borderRadius: '4px',

      _focus: {
        boxShadow: `0 0 2px ${primaryTeal}`,
        borderColor: 'brand.primaryTeal',

        '& + .date-picker-to__label, + .date-picker-from__label': {
          color: 'brand.primaryTeal',
        },
      },
    },
  },
  '.react-datepicker__year-dropdown': {
    boxShadow: 'brand',
  },
  '.react-datepicker__navigation--years-previous': {
    _before: {
      borderColor: 'brand.primaryTeal',
    },
  },
  '.react-datepicker__navigation--years-upcoming': {
    _before: {
      borderColor: 'brand.primaryTeal',
    },
  },
  '.react-datepicker__year-read-view--down-arrow': {
    top: '0',
    borderColor: 'brand.primaryTeal',
  },
  '.react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range': {
    backgroundColor: 'brand.teal10',
    _hover: hoverDate,
  },
  '.react-datepicker__day--selected': {
    ...selectedDate,
    _hover: hoverDate,
  },
  '.react-datepicker__month-text--selected': {
    ...selectedDate,
    _hover: {
      backgroundColor: 'brand.primaryTeal',
    },
  },
  '.react-datepicker__month-text--in-selecting-range': {
    backgroundColor: ['brand.primaryTeal', 'brand.primaryTeal'],
    _hover: {
      backgroundColor: 'brand.primaryTeal',
    },
  },
  '.react-datepicker__month-text--in-range': {
    backgroundColor: 'brand.primaryTeal',
    _hover: {
      backgroundColor: 'brand.primaryTeal',
    },
  },
  '.react-datepicker__quarter-text--selected': {
    backgroundColor: 'brand.primaryTeal',
    _hover: {
      backgroundColor: 'brand.primaryTeal',
    },
  },
  '.react-datepicker__quarter-text--in-selecting-range': {
    backgroundColor: ['brand.primaryTeal', 'brand.primaryTeal'],
    _hover: {
      backgroundColor: 'brand.primaryTeal',
    },
  },
  '.react-datepicker__quarter-text--in-range': {
    backgroundColor: 'brand.primaryTeal',
    _hover: {
      backgroundColor: 'brand.primaryTeal',
    },
  },
  '.react-datepicker__year-text--selected': {
    backgroundColor: 'brand.primaryTeal',
    _hover: {
      backgroundColor: 'brand.primaryTeal',
    },
  },
  '.react-datepicker__day--keyboard-selected': {
    ...selectedDate,
    _hover: hoverDate,
  },
  '.react-datepicker__day--range-start': {
    ...selectedDate,
    _hover: hoverDate,
  },
  '.react-datepicker__day--range-end': {
    ...selectedDate,
    _hover: hoverDate,
  },
  '.react-datepicker__day': {
    _hover: hoverDate,
    _focus: focusDate,
  },
}
