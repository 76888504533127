import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const BULLET_SIZE_IN_REM = 0.5625

export const helpers = createMultiStyleConfigHelpers(['wrapper', 'item', 'bulletWrapper', 'bullet'])

export const List = helpers.defineMultiStyleConfig({
  baseStyle: {
    wrapper: {
      marginTop: '1.5rem',
      'li + li': {
        marginTop: '1rem',
      },
    },
    item: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline',
      columnGap: '1rem',
    },
    bulletWrapper: {
      display: 'flex',
    },
    bullet: {
      width: `${BULLET_SIZE_IN_REM}rem`,
      height: `${BULLET_SIZE_IN_REM}rem`,
      borderRadius: '50%',
    },
  },
})
