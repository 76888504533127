import { helpers } from './base'

export const Stepper = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    backgroundLine: {
      backgroundImage: `repeating-linear-gradient( 90deg, transparent 0 4px, var(--chakra-colors-brand-primaryTeal) 4px 12px )`,
    },
    completedLine: {
      background: 'brand.primaryTeal',
    },
    stepContainer: {
      '&[data-status="default"] > p ': {
        fontWeight: 'normal',
      },
      '&[data-status="selected"] > p ': {
        fontWeight: 'semibold',
      },
    },
    stepText: {
      color: 'brand.coolgrey10',
      fontWeight: 'normal',
    },
  }),
})
