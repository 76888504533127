import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'quantityPickerContainer',
  'selectQuantity',
  'quantityPickerButton',
])

export const QuantityPicker = helpers.defineMultiStyleConfig({
  baseStyle: {
    selectQuantity: {
      display: 'inline-block',
      w: 9,
      margin: '0 5px',
      textAlign: 'center',
      fontSize: 'sm',
      '&.chakra-text': {
        margin: 'auto',
      },
    },
    quantityPickerButton: {
      backgroundColor: 'transparent',
      border: 'none',
      _hover: { cursor: 'pointer' },
      _disabled: { opacity: '0.3', cursor: 'not-allowed' },
    },
  },
})
