import merge from 'lodash/merge'
import { ComponentStyleConfig } from '@chakra-ui/react'
import { SkeletonCard as base } from './base'

export const Skeleton: ComponentStyleConfig = {
  baseStyle: {
    _empty: {
      borderRadius: '2xs',
      background: 'bg.layer1.default',
    },
  },
}

export const SkeletonCard: ComponentStyleConfig = merge({}, base, {
  baseStyle: {
    borderRadius: '2xs',
    overflow: 'hidden',
    boxShadow: 'none',
    background: 'bg.layer2.default',
  },
})
