import { ComponentStyleConfig } from '@chakra-ui/react'

const primaryBlue = 'var(--chakra-colors-brand-primary-blue)'
const highlightBlue = 'var(--chakra-colors-brand-highlight-blue)'
const midnightBlue = 'var(--chakra-colors-brand-primary-midnightBlue)'
const white = 'brand.white'
const baseGray10 = 'brand.baseGray.10'

export const Button: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: 'sm',
    color: white,
    fontWeight: 'normal',

    _focusVisible: {
      boxShadow: 'none',
    },

    _disabled: {
      opacity: 0.7,
    },
  },
  variants: {
    primary: {
      bg: primaryBlue,
      border: `var(--chakra-space-px) solid ${primaryBlue}`,
      py: { base: 2, md: 2.5, lg: 4 },
      px: { base: 5, md: 8, lg: 10 },

      _hover: {
        bg: highlightBlue,
        border: `var(--chakra-space-px) solid ${highlightBlue}`,
        boxShadow: 'none',
      },

      _focus: {
        bg: highlightBlue,
        border: `var(--chakra-space-px) solid ${midnightBlue}`,
      },

      _active: {
        bg: primaryBlue,
      },

      _disabled: {
        opacity: 0.7,
      },
    },
    secondary: {
      border: '1px solid',
      color: primaryBlue,
      borderColor: primaryBlue,
      bg: white,
      py: { base: 2, md: 2.5, lg: 4 },
      px: { base: 5, md: 8, lg: 10 },

      '&:hover, &:focus': {
        bg: highlightBlue,
        borderColor: highlightBlue,
        color: white,
        boxShadow: 'none',
      },

      _active: {
        bg: baseGray10,
        borderColor: primaryBlue,
        color: primaryBlue,
      },

      _disabled: {
        opacity: 0.7,
      },
    },

    link: {
      color: highlightBlue,
      border: 'none',

      '&:active, &:hover, &:focus': {
        textDecoration: 'none',
      },

      _focusVisible: {
        outline: 'solid',
        borderRadius: 'sm',
        outlineColor: 'brand.focusOutline',
      },

      _active: {
        color: primaryBlue,
      },
    },
  },
  sizes: {
    lg: {
      fontSize: 'md',
      lineHeight: 'base',
      minHeight: 14,
    },
    md: {
      fontSize: 'sm',
      lineHeight: 'base',
      height: 'unset',
    },
    sm: {
      fontSize: 'xs',
      lineHeight: 'shorter',
    },
  },
}
