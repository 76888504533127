import { ComponentStyleConfig, keyframes } from '@chakra-ui/react'

export const skeletonFade = keyframes`
  0% {
    background-color: #eee;
  }
  50% {
    background-color: #ddd;
  }
  100% {
    background-color: #eee;
  }
`

export const Skeleton: ComponentStyleConfig = {
  baseStyle: {
    display: 'inline-block',
    margin: '0.5rem 0',
    _empty: {
      backgroundColor: '#eee',
      borderRadius: '3px',
      cursor: 'default',
      animation: `${skeletonFade} 2s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955)`,
    },
  },
}

export const SkeletonCard: ComponentStyleConfig = {
  baseStyle: {
    display: 'grid',
    gridTemplate: {
      base: "'image' 220px 'content' auto 'pricing' auto",
      md: "'image content pricing' 268px / 1fr 1fr 1fr",
    },
    borderRadius: '8px',
    boxShadow: '0 6px 9px 0 rgb(0 0 0 / 10%)',

    '& > div': {
      display: 'flex',
      flexDirection: 'column',
    },

    'div:nth-of-type(1)': {
      _empty: {
        backgroundColor: '#eee',
        cursor: 'default',
        animation: `${skeletonFade} 2s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955)`,
      },
    },
    '& > div:nth-of-type(2)': {
      margin: {
        base: '1rem',
        md: '1.5rem',
      },
    },
    '& > div:nth-of-type(3)': {
      margin: {
        base: '1rem',
        md: '1.5rem',
      },
      alignItems: 'flex-end',

      '> span:nth-of-type(2n)': {
        display: {
          base: 'none',
          lg: 'unset',
        },
      },
    },
  },
}
