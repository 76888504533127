import { ChakraTheme, extendTheme } from '@chakra-ui/react'
import { Drawer } from '@reward-platform/lift/components/ChakraDrawer/themes/aerLingus'
import { typography } from '@reward-platform/lift/theme/vueling/typography'
import {
  vuBlack,
  vuCoolGrey2,
  vuGrey,
  vuGrey5,
  vuGrey6,
  vuOrange,
  vuRed,
  vuSecondary1,
  vuYellow,
  vuWhite,
  vuGreyscale2,
  vuGreyscale3,
  vuGreyscale4,
  vuCoolGrey8,
  vuCoolGrey4,
  vuBoxShadow,
  vuCoolGrey10,
  vuSecondary4Dark,
  vuSecondary1Light,
  vuSecondary5,
  vuPrimaryTeal,
  vuRed5,
  vuGreyDisabled,
  vuQuantityIcons,
  vuOrangeLight,
  vuOrangeDark,
  vuLinkButton,
  vuDarkCityGreen,
  vuCloudWhite,
  vuPrimaryShamrockGreen,
} from '@reward-platform/lift/theme/vueling/variables'
import { Switch } from '@reward-platform/lift/components/ChakraSwitch/themes/vueling'
import { Pagination } from '@reward-platform/lift/components/Pagination/themes/vueling'
import { ChakraPopover } from '@reward-platform/lift/components/ChakraPopover/themes/vueling'
import { Accordion } from '@reward-platform/lift/components/ChakraAccordion/themes/vueling'
import { Chip } from '@reward-platform/lift/components/Chip/themes/vueling'
import { List } from '@reward-platform/lift/components/List/themes/vueling'
import { ImageCard } from '@reward-platform/lift/components/ImageCard/themes/vueling'
import {
  DatePicker,
  DatePickerGlobalTheme,
} from '@reward-platform/lift/components/DatePicker/themes/vueling'
import { RichText } from '@reward-platform/lift/components/ChakraRichText/themes/vueling'
import { Divider } from '@reward-platform/lift/components/Divider/themes/vueling'
import { InfoMessage } from '@reward-platform/lift/components/InfoMessage/themes/vueling'
import { Spinner } from '@reward-platform/lift/components/Spinner/themes/vueling'
import { NotificationMessage } from '@reward-platform/lift/components/NotificationMessage/themes/vueling'
import { Carousel } from '@reward-platform/lift/components/Carousel/themes/vueling'
import { QuantityPicker } from '@reward-platform/lift/components/QuantityPicker/themes/vueling'
import { Tabs } from '@reward-platform/lift/components/Tabs/themes/vueling'
import { Ratings } from '@reward-platform/lift/components/Ratings/themes/vueling'
import { Modal } from '@reward-platform/lift/components/Modal/themes/vueling'
import { Card } from '@reward-platform/lift/components/Card/themes/vueling'
import { WarningMessage } from '@reward-platform/lift/components/WarningMessage/themes/vueling'
import { TextMarker } from '@reward-platform/lift/components/Map/themes/vueling'
import { SelectBox } from '@reward-platform/lift/components/SelectBox/themes/vueling'
import { Button } from '@reward-platform/lift/components/ChakraButton/themes/vueling'
import { AutoCompleteLocationOption } from '@reward-platform/lift/components/AutoComplete/themes/vueling'
import { Combobox } from '@reward-platform/lift/components/ComboBox/themes/vueling'
import { RangeDatePicker } from '@reward-platform/lift/components/RangeDatePicker/themes/vueling'
import { DayList } from '~/components/experiences/details/DayList/themes/vueling'
import { RouteInfo } from '~/components/experiences/details/RouteInfo/themes/vueling'
import { Menu } from '~/components/shared/HeaderMenu/themes/vueling'
import { ActivityMenu } from '~/components/experiences/details/ActivityMenu/themes/vueling'
import { IntroSection } from '~/components/search-and-results/IntroSection/themes/vueling'
import { AmenitiesList } from '~/components/shared/AmenitiesList/themes/vueling'
import { SpinnerOverlay } from '~/components/shared/SpinnerOverlay/themes/vueling'
import { NoResults } from '~/components/search-and-results/NoResults/themes/vueling'
import { HotelNearbyAttractions } from '~/components/search-and-results/HotelNearbyAttractions/themes/vueling'
import { ProgressBar, Progress } from '~/components/shared/ProgressBar/themes/vueling'
import { HotelSummary } from '~/components/search-and-results/HotelSummary/themes/vueling'
import { GuestRatings } from '~/components/shared/GuestRatings/themes/vueling'
import { BackTo } from '~/components/shared/BackTo/themes/vueling'
import { Filters } from '~/components/shared/Filters/themes/vueling'
import { HotelCard } from '~/components/search-and-results/HotelCard/themes/vueling'
import { ChildrenAgePicker } from '~/components/shared/ChildrenAgePicker/themes/vueling'
import { CustomerReviewList } from '~/components/experiences/details/CustomerReviewList/themes/vueling'
import { CollapsibleForm } from '~/components/shared/CollapsibleForm/themes/vueling'
import { HotelReviewBreakdown } from '~/components/search-and-results/HotelReviewBreakdown/themes/vueling'
import { TravelerRoomPicker } from '~/components/search-and-results/SearchHotelForm/TravelerRoomPicker/themes/vueling'
import { TravelerRoom } from '~/components/search-and-results/SearchHotelForm/TravelerRoomPicker/TravelerRoom/themes/vueling'
import { DetailsPageNav } from '~/components/search-and-results/DetailsPageNav/themes/vueling'
import { FlightCard } from '~/components/search-and-results/FlightCard/themes/vueling'
import { CarCard } from '~/components/search-and-results/CarCard/themes/vueling'
import { Banner } from '~/components/search-and-results/Banner/themes/vueling'
import { RoomRateSelector } from '~/components/search-and-results/RoomRateSelector/themes/vueling'
import { FlightJourneyHeader } from '~/components/search-and-results/FlightJourneyHeader/themes/vueling'
import { RoomMoreDetailsModal } from '~/components/search-and-results/RoomMoreDetailsModal/themes/vueling'
import { SearchHotelForm } from '~/components/search-and-results/SearchHotelForm/themes/vueling'
import { EditSearchResultsForm } from '~/components/search-and-results/SearchHotelForm/EditSearchResultsForm/themes/vueling'
import { SearchExperienceForm } from '~/components/search-and-results/SearchExperienceForm/themes/vueling'
import { SearchFlightForm } from '~/components/search-and-results/SearchFlightForm/themes/vueling'
import { ResultsMapView } from '~/components/search-and-results/ResultsMapView/themes/vueling'
import { CarPriceBar } from '~/components/search-and-results/CarPriceBar/themes/vueling'
import { ResultsPageTemplate } from '~/components/search-and-results/ResultsPageTemplate/themes/vueling'
import { HotelRooms } from '~/components/search-and-results/HotelRooms/themes/vueling'
import { CarAdditionalProductCard } from '~/components/search-and-results/CarAdditionalProductCard/themes/vueling'
import { RoomDetailsCard } from '~/components/search-and-results/RoomDetailsCard/themes/vueling'
import { HotelDetails } from '~/components/hotels/details/themes/vueling'
import { ExperienceDetails } from '~/components/experiences/details/details/themes/vueling'
import { PopularAmenitiesList } from '~/components/search-and-results/PopularAmenities/themes/vueling'
import { StaticMap } from '~/components/search-and-results/StaticMap/themes/vueling'
import { CarDetails } from '~/components/cars/details/themes/vueling'
import { ExperienceInformation } from '~/components/experiences/details/ExperienceInformation/themes/vueling'
import { FeaturesList } from '~/components/experiences/details/FeaturesList/themes/vueling'
import { ExperienceTitle } from '~/components/experiences/details/ExperienceTitle/themes/vueling'
import { PricingAndSearchCard } from '~/components/experiences/details/PricingAndSearchCard/themes/vueling'
import { TravelerQuantityPickerComboBox } from '~/components/shared/TravelerQuantityPickerComboBox/themes/vueling'
import { TicketOptions } from '~/components/experiences/details/TicketOptions/themes/vueling'
import { WhatToExpect } from '~/components/experiences/details/WhatToExpect/themes/vueling'
import { Dropdown } from '~/components/shared/Dropdown/themes/vueling'
import { AddressLookupOption } from '~/components/AddressForm/themes/vueling'
import { baseTheme } from './base'

export const vuelingTheme = extendTheme(
  {
    styles: {
      global: {
        'html, body, div#__next': {
          color: 'brand.grey6',
        },
        body: {
          input: {
            _placeholder: {
              color: 'brand.grey5',
            },
          },
        },
        ...DatePickerGlobalTheme,
      },
    },
    shadows: {
      brand: vuBoxShadow,
    },
    fonts: {
      heading: typography.families.heading,
      body: typography.families.body,
    },
    colors: {
      brand: {
        grey: vuGrey,
        grey5: vuGrey5,
        grey6: vuGrey6,
        orange: vuOrange,
        orangeLight: vuOrangeLight,
        orangeDark: vuOrangeDark,
        red: vuRed,
        red5: vuRed5,
        secondary1: vuSecondary1,
        white: vuWhite,
        black: vuBlack,
        yellow: vuYellow,
        coolgrey2: vuCoolGrey2,
        greyscale2: vuGreyscale2,
        greyscale3: vuGreyscale3,
        greyscale4: vuGreyscale4,
        coolgrey8: vuCoolGrey8,
        coolgrey4: vuCoolGrey4,
        coolgrey10: vuCoolGrey10,
        secondary4Dark: vuSecondary4Dark,
        secondary1Light: vuSecondary1Light,
        secondary5: vuSecondary5,
        primaryTeal: vuPrimaryTeal,
        greydisabled: vuGreyDisabled,
        quantityicons: vuQuantityIcons,
        linkButton: vuLinkButton,
        darkCityGreen: vuDarkCityGreen,
        cloudWhite: vuCloudWhite,
        icon: vuBlack,
        inputIcon: vuPrimaryTeal,
        primaryShamrockGreen: vuPrimaryShamrockGreen,
      },
      error: {
        primary: vuRed,
      },
    },
    left: 'left-chevron',
    components: {
      Drawer,
      DayList,
      Pagination,
      ChakraPopover,
      Accordion,
      RouteInfo,
      Chip,
      ActivityMenu,
      List,
      ImageCard,
      DatePicker,
      RichText,
      Menu,
      Divider,
      IntroSection,
      Spinner,
      SpinnerOverlay,
      Switch,
      ProgressBar,
      Progress,
      AmenitiesList,
      NoResults,
      HotelNearbyAttractions,
      NotificationMessage,
      InfoMessage,
      Carousel,
      HotelSummary,
      GuestRatings,
      BackTo,
      QuantityPicker,
      Filters,
      Tabs,
      Ratings,
      HotelCard,
      WarningMessage,
      ChildrenAgePicker,
      Modal,
      Card,
      TravelerRoomPicker,
      TravelerRoom,
      CustomerReviewList,
      TextMarker,
      HotelReviewBreakdown,
      CollapsibleForm,
      FlightJourneyHeader,
      DetailsPageNav,
      FlightCard,
      Banner,
      RoomMoreDetailsModal,
      SelectBox,
      SearchHotelForm,
      EditSearchResultsForm,
      SearchExperienceForm,
      SearchFlightForm,
      CarCard,
      RoomRateSelector,
      ResultsMapView,
      CarPriceBar,
      Button,
      AutoCompleteLocationOption,
      AddressLookupOption,
      ResultsPageTemplate,
      HotelRooms,
      CarAdditionalProductCard,
      RoomDetailsCard,
      HotelDetails,
      ExperienceDetails,
      PopularAmenitiesList,
      StaticMap,
      CarDetails,
      ExperienceInformation,
      FeaturesList,
      ExperienceTitle,
      PricingAndSearchCard,
      TravelerQuantityPickerComboBox,
      Combobox,
      TicketOptions,
      RangeDatePicker,
      WhatToExpect,
      Dropdown,
    },
  },
  baseTheme
) as ChakraTheme
