import React, { ReactNode } from 'react'
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  As,
  Box,
  forwardRef,
  HStack,
  LayoutProps,
  List,
  ListItem,
  Text,
  useMergeRefs,
  useMultiStyleConfig,
  Icon,
} from '@chakra-ui/react'
import { useSelect } from 'downshift'
import { useId } from 'react-id-generator'
import { ChakraInputField } from '../ChakraField'
import { ChakraListBoxProps, defaultIconProps } from './common'
import { convertIconSetToChakraSVG } from '../Icon/Icon.utils'

type ComboBoxProps = {
  as: As
  isOpen: boolean
  minWidth: LayoutProps['minWidth']
  children: ReactNode
}

const ComboBox = forwardRef(({ as, isOpen, children, ...rest }: ComboBoxProps, ref) => {
  return (
    <Box
      as={as}
      ref={ref}
      top="px"
      zIndex="dropdown"
      w="max-content"
      visibility={isOpen ? 'visible' : 'hidden'}
      opacity={isOpen ? 1 : 0}
      transform={`translateY(${isOpen ? 0 : '-10px'})`}
      transition={`0.25s opacity ease, 0.25s visibility ${
        isOpen ? 'step-start' : 'step-end'
      }, 0.25s transform ease`}
      p={0}
      m={0}
      {...rest}
    >
      {children}
    </Box>
  )
})

export const ChakraCustomListBox = React.forwardRef<HTMLInputElement, ChakraListBoxProps>(
  (
    {
      name,
      children: options,
      error,
      label,
      onChange,
      note,
      value,
      isDisabled,
      displayValue,
      tabIndex,
      isRequired,
      dataTestId,
    },
    forwardedRef
  ) => {
    const [id] = useId(1)
    const styles = useMultiStyleConfig('ListBox', {})
    const itemToString = (item: string | null) =>
      (options.find((o) => o.value === item)?.label as string) ?? ''

    const {
      isOpen,
      getMenuProps,
      getItemProps,
      selectedItem,
      getToggleButtonProps,
      highlightedIndex,
      getLabelProps,
    } = useSelect({
      items: options.map((o) => o.value),
      itemToString,
      id,
      selectedItem: value ?? '',
      onSelectedItemChange: (changes) =>
        onChange(changes.selectedItem == null ? undefined : changes.selectedItem),
    })

    const labelProps = getLabelProps()

    const { ref: toggleButtonRef, ...toggleButtonProps } = getToggleButtonProps()
    const maxNumberOfVisibleItems = 6

    const valueToDisplay = displayValue != null ? displayValue : itemToString(selectedItem)
    const refs = useMergeRefs(forwardedRef, toggleButtonRef)

    const iconProps = {
      ...defaultIconProps,
      icon: name === 'time' ? 'clock' : defaultIconProps.icon,
    }

    return (
      <ChakraInputField
        type="button"
        ref={refs}
        name={name}
        label={label}
        note={note}
        error={error}
        value={valueToDisplay}
        isDisabled={isDisabled}
        labelProps={labelProps}
        tabIndex={tabIndex}
        isRequired={isRequired}
        data-testid={dataTestId}
        {...iconProps}
        {...toggleButtonProps}
        comboBox={
          <ComboBox
            as={List}
            isOpen={isOpen}
            minWidth="100%"
            maxWidth={isOpen ? 'unset' : '100%'}
            maxHeight={`calc(3.5rem * ${maxNumberOfVisibleItems})`}
            __css={{
              ...styles.list,
            }}
            {...getMenuProps()}
          >
            {options.map((item, index) => {
              return (
                <ListItem
                  key={item.value}
                  {...getItemProps({ item: item.value, disabled: item.disabled, index })}
                >
                  <HStack
                    shouldWrapChildren
                    justify="space-between"
                    w="100%"
                    __css={{
                      ...styles.item,
                      // @ts-ignore
                      ...(highlightedIndex === index ? styles.item?._selected : undefined),
                      // @ts-ignore
                      ...(item.disabled ? styles.item?._disabled : undefined),
                    }}
                  >
                    <Text as="span">{item.label}</Text>
                    {selectedItem === item.value && (
                      <Icon
                        as={convertIconSetToChakraSVG('check')}
                        boxSize="15px"
                        color={(styles.icon?.color as string) ?? 'black'}
                      />
                    )}
                  </HStack>
                </ListItem>
              )
            })}
          </ComboBox>
        }
      />
    )
  }
)
