/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const ExperienceDetails = helpers.defineMultiStyleConfig({
  baseStyle: {
    firstPart: {
      gap: {
        base: 'lg',
        md: '2xl',
      },
      marginTop: 'lg',
    },
    divider: {
      borderColor: 'transparent',
    },
    experienceOverviewTitle: {
      fontSize: 'var(--alto-sem-text-heading-lg-font-size)',
    },
    experienceOverviewContainer: {
      padding: 0,
      borderRadius: 0,
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
    sectionTitle: {
      marginBottom: 'sm',
      fontSize: 'var(--alto-sem-text-heading-sm-font-size)',
      lineHeight: 'var(--alto-sem-text-heading-sm-line-height)',
      fontWeight: 'medium',
      color: 'fg.accent.secondary',
    },
    showMoreButton: {
      border: '1px solid var(--alto-sem-color-border-accent)',
      borderColor: 'border.accent',
      _hover: {
        bg: 'bg.accent.subtle.default',
      },
    },
    experienceOverviewDescription: {
      mt: 'sm',
      fontSize: 'var(--alto-sem-text-body-lg-font-size)',
    },
    experienceInformationTitle: {
      fontSize: 'var(--alto-sem-text-heading-sm-font-size)',
      fontWeight: 'medium',
    },
    experienceInformationContainer: {
      '> ul': {
        mt: 'lg',
      },
    },
    skeletonContainer: {
      borderRadius: 'xs',
      p: 'lg',
      boxShadow: 'md',
    },
  },
})
