/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const GuestRatings = helpers.defineMultiStyleConfig({
  baseStyle: {
    text: {
      color: 'fg.primary',
      fontSize: 'sm',
      fontWeight: 'normal',
      textDecoration: 'none',
      strong: {
        fontWeight: 'semibold',
      },
    },
  },
  variants: {
    experiment: {
      text: {
        display: 'flex',
        alignItems: { base: 'flex-start', sm: 'center' },
        flexDirection: { base: 'column', sm: 'row' },
        color: 'fg.secondary',
        fontWeight: 'normal',
        fontSize: 'var(--alto-sem-text-body-lg-font-size)',
        lineHeight: 'var(--alto-sem-text-body-lg-line-height)',
        strong: {
          color: 'fg.accent.primary',
          fontWeight: 'medium',
          fontSize: 'var(--alto-sem-text-label-sm-font-size)',
          lineHeight: 'var(--alto-sem-text-label-sm-line-height)',
        },
      },
      reviewsCount: {
        fontWeight: 'light',
      },
      guestRating: {
        backgroundColor: 'bg.accent.subtle.default',
        padding: 'var(--alto-sem-space-2xs) var(--alto-sem-space-sm)',
        borderRadius: '2xs',
        marginRight: '2xs',
      },
      icon: {
        marginBottom: '2xs',
        marginRight: '2xs',
      },
      rightChevronIcon: {
        paddingLeft: '2xs',
      },
    },
  },
})
