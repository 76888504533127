import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'container',
  'header',
  'content',
  'invertedButton',
])

export const CollapsibleForm = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      width: 'full',
      backgroundColor: 'white',
      display: 'grid',
      columnGap: '4',
      gridTemplateColumns: '1fr minmax(auto, var(--chakra-sizes-7xl)) 1fr',
      gridAutoFlow: 'row',
      gridAutoRows: 'min-content',

      '& > *': {
        gridColumn: '2/3',
      },
    },
    header: {
      display: 'grid',
      width: 'full',
      gridTemplateAreas: `'. travelerinfo . editsearchbutton .'`,
      gridTemplateColumns:
        'minmax(0, 2fr) minmax(auto, 300px) minmax(auto, 824px) 100px minmax(0, 2fr)',
      padding: 4,
    },
    content: {
      transition: 'height 0.3s ease',
      overflow: 'hidden',
      animationDelay: '0.3s',
      animationFillMode: 'forwards',
    },
    invertedButton: {
      maxWidth: 'fit-content',
      minWidth: '8rem',
      gridArea: 'editsearchbutton',
      placeSelf: 'center end',
    },
  },
})
