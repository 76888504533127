import { AxiosError } from 'axios'
import { ZodIssue } from 'zod'
import { ERROR_CODES } from './ErrorCodes'

export interface GenericError extends AxiosError {
  extensions?: {
    contentful: {
      code: string
      requestId: string
    }
  }
  path?: string[]
}

export interface GenericExceptionError {
  code?: string
  httpStatus?: number
  message?: string
  businessMessage?: string
  developerMessage?: string
  unrecoverable?: boolean
  originalError?: GenericError
  validationErrors?: ZodIssue[]
  responseData?: AxiosError
  errorDescription?: string
}

export class GenericException extends AxiosError {
  public httpStatus: number

  public code: string

  public message: string

  public businessMessage: string

  public developerMessage: string

  public originalError?: GenericError

  public unrecoverable: boolean

  public validationErrors?: ZodIssue[]

  public responseData?: AxiosError

  public errorDescription?: string

  constructor(props: GenericExceptionError = {}) {
    super()
    const {
      httpStatus,
      message,
      code,
      originalError,
      businessMessage,
      developerMessage,
      validationErrors,
      unrecoverable,
      responseData,
      errorDescription,
    } = props || {}
    this.stack = originalError?.stack
    this.businessMessage = businessMessage || ERROR_CODES.FATAL.businessMessage
    this.developerMessage = developerMessage || message || ERROR_CODES.FATAL.developerMessage
    this.message = this.businessMessage // Message is a fallback since it is present in original error
    this.code = code || ERROR_CODES.FATAL.code
    this.httpStatus = httpStatus || 500
    this.validationErrors = validationErrors
    this.originalError = originalError
    this.unrecoverable = unrecoverable || false
    this.responseData = responseData
    this.errorDescription = errorDescription
  }

  public formatErrorForLogs() {
    const errorMessage = this.developerMessage
    const errorCode = this.responseData ? this.responseData.code : this.code

    return {
      message: `'${errorCode}' - ${errorMessage}`,
      level: 'error',
      error: {
        message: errorMessage,
        stack: this.stack,
        code: errorCode,
        description: this.errorDescription,
        httpStatus: this.httpStatus,
        ...(this.responseData?.message &&
          this.validationErrors === undefined && {
            downstreamMessage: this.responseData?.message,
          }),
        ...(this.originalError?.code && {
          downstreamDescription: this.originalError?.code,
        }),
        ...(this.originalError?.response?.status && {
          downstreamHttpStatus: this.originalError?.response?.status,
        }),
        ...(this.validationErrors && { validationErrors: this.validationErrors }),
      },
    }
  }

  public getErrorResponse() {
    return {
      code: this.code,
      businessMessage: this.businessMessage,
      developerMessage: this.developerMessage,
      validationErrors: this.validationErrors,
      unrecoverable: this.unrecoverable,
    }
  }
}
