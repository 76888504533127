import { ComponentSingleStyleConfig } from '@chakra-ui/react'

export const PageTitle: ComponentSingleStyleConfig = {
  baseStyle: {
    h1: {
      fontSize: { base: '4xl', lg: '5xl' },
      fontWeight: 'light',
    },
    span: {
      fontSize: { base: 'md', lg: 'lg' },
    },
  },
}
