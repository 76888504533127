import { helpers } from './base'

export const RichText = helpers.defineMultiStyleConfig({
  baseStyle: {
    richText: {
      p: {
        color: 'brand.primary.midnightBlue',
        fontSize: 'md',
        lineHeight: 'base',
        fontWeight: 'light',
      },
      strong: {
        fontWeight: 'semibold',
      },
      li: {
        fontSize: 'md',
        '::marker': {
          fontWeight: 'light',
        },
      },
    },
    hyperlink: { color: 'brand.highlight.blue' },
    heading: {
      fontWeight: 'light',
    },
  },
})
