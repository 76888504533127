import { overlayTipHelper } from './base'

export const OverlayTip = overlayTipHelper.defineMultiStyleConfig({
  baseStyle: overlayTipHelper.definePartsStyle({
    containerBorder: {
      borderColor: 'brand.darkCityGreen',
      borderRadius: 16,
    },
  }),
})
