import { helpers } from './base'

export const CancelOrderPaymentForm = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    paymentFormParentWrapper: {
      bg: 'brand.white',
      p: { base: 4, md: 10 },
      h2: {
        fontWeight: 'light',
      },
    },
    stickyButtonDisclaimer: {
      'p: first-child': {
        fontSize: '2xl',
        color: 'brand.primary.blue',
      },
    },
  }),
})
