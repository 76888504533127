import { Sizes } from './sizes.types'

type DeviceSize = Omit<Record<keyof Sizes, string>, 'xxlarge'>

export const deviceSizes: DeviceSize = {
  xsmall: '414px', // *Note: xsmall - LargeMobile
  small: '650px',
  medium: '768px',
  large: '1024px', // *Note: large - Desktop
  xlarge: '1280px',
}
