import { helpers } from './base'

export const PageContainer = helpers.defineMultiStyleConfig({
  baseStyle: {
    title: {
      h1: {
        color: 'fg.accent.secondary',
        fontWeight: 'medium',
      },
    },
  },
  variants: {
    withBg: {
      primary: {
        borderRadius: 'xs',
        bg: 'bg.layer2.default',
        boxShadow: 'md',
        p: {
          base: 'lg',
          lg: '3xl',
        },
        pl: {
          base: 'auto',
          lg: '3xl',
        },
        ml: {
          base: 'unset',
          lg: 'lg',
        },
      },
    },
  },
})
