import React from 'react'

import { useMediaQuery } from '@chakra-ui/react'
import { deviceSizes } from '../../theme/deviceSizes'

import Modal from '../Modal/Modal'
import { MobileModalProps } from './MobileModal.types'

function MobileModal({
  children,
  isModalVisible,
  handleModalClose: onModalClose,
  disablePlaceholder,
  placeholder,
}: MobileModalProps) {
  const [isLargerThanMobile] = useMediaQuery(`(min-width: ${deviceSizes.medium})`)

  if (isLargerThanMobile) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>
  }

  if (!isModalVisible) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{placeholder || children}</>
  }

  return (
    <>
      {!disablePlaceholder && (placeholder || children)}
      <Modal preventScroll isOpen={isModalVisible} onClose={onModalClose} shouldFocusAfterRender>
        {children}
      </Modal>
    </>
  )
}

export default MobileModal
