import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers(['showHideButton', 'container'])

export const ShowHideButton = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      display: {
        base: 'none',
        lg: 'inline-block',
      },
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '1.875rem',
    },
    showHideButton: {
      width: '9.625rem',
      height: '3.75rem',
      padding: '1rem 1.5rem',
      fontWeight: '500',
      fontSize: '0.875rem',
      backgroundColor: 'transparent',
      color: 'white',
      _hover: {
        backgroundColor: 'transparent',
        color: 'white',
      },
      _focus: {
        outline: 'brand.focusOutline',
        backgroundColor: 'transparent',
        color: 'white',
      },
    },
  },
})
