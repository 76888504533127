import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers(['backLinkContainer', 'footerContainer'])

const wideGridColumn = 'var(--layout-column-wide)'
const pageInlinePadding = 'max(var(--layout-gutter), calc((100vw - var(--layout-width)) / 2))'

export const FlightsResults = helpers.defineMultiStyleConfig({
  baseStyle: {
    backLinkContainer: {
      gridColumn: wideGridColumn,
      paddingInline: pageInlinePadding,
    },
    footerContainer: {
      position: 'fixed',
      bottom: '1rem',
      right: { base: '0.5rem', md: '1rem' },
      left: {
        base: '0.5rem',
        md: 'unset',
      },
      zIndex: 100,
    },
  },
  variants: {
    allBoundsSelected: {
      footerContainer: {
        left: {
          lg: '1rem',
        },
      },
    },
  },
})
