import { Basket, BasketItemType } from '@reward-platform/order-schemas/basket'
import {
  CarHireItemType,
  ExperienceItemType,
  FlightItemType,
  HotelItemType,
} from '@reward-platform/order-schemas/common'
import { Order } from '@reward-platform/order-schemas/order'
import { useUser } from '~/context/user'
import useBasket from '../useBasket/useBasket'

type Items = Basket['items'] | Order['items']

export const getCheckoutAnalyticsChannel = (items?: Items) => {
  const hasHotelItem = items && items[HotelItemType]?.length > 0
  const hasCarHireItem = items && items[CarHireItemType]?.length > 0
  const hasExperienceItem = items && items[ExperienceItemType]?.length > 0
  const hasFlightItem = items && items[FlightItemType]?.length > 0

  const productTypeToChannelMapping: Record<BasketItemType, string> = {
    CAR_HIRE: 'car_hire',
    EXPERIENCE: 'experiences',
    FLIGHT: 'flights',
    HOTEL: 'hotels',
  }

  return [
    hasHotelItem && HotelItemType,
    hasCarHireItem && CarHireItemType,
    hasExperienceItem && ExperienceItemType,
    hasFlightItem && FlightItemType,
  ]
    .filter((item): item is BasketItemType => Boolean(item))
    .map((itemType) => productTypeToChannelMapping[itemType])
    .sort((a, b) => a.localeCompare(b))
    .join(',')
    .toLocaleLowerCase()
}

export const useCheckoutAnalyticsChannel = ({ isEnabled }: { isEnabled: boolean }) => {
  const { user } = useUser()

  const {
    basket: { data: basket },
    // If user is available then also the currency header is set for BFF client and the request will not fail
  } = useBasket({ isEnabled: isEnabled && Boolean(user) })

  if (!isEnabled) {
    return undefined
  }

  return getCheckoutAnalyticsChannel(basket?.items)
}
