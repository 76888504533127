import { PromotionContent, usePromotionContext } from '~/context/promotion'
import { PromotionItemProps } from './Promotion.types'

type ContentKey = keyof Omit<PromotionContent, 'promotionId'>

const useIsPromotionComponentEnabled = (
  contentKey?: ContentKey,
  config?: PromotionItemProps
): boolean => {
  const { product, item, isIncentive } = config ?? {}
  const { isEnabled, getProductPromotion } = usePromotionContext()
  const { content, isItemValidForPromotion } = getProductPromotion(product) ?? {}
  const hasPromotionContent = contentKey && content?.[contentKey]
  const isPromoContentEnabled = isEnabled(product) && hasPromotionContent
  const isItemValid = isItemValidForPromotion?.(item, { isIncentive })
  return item ? Boolean(isPromoContentEnabled && isItemValid) : Boolean(isPromoContentEnabled)
}

export default useIsPromotionComponentEnabled
