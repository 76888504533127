import { helpers } from './base'

const h2Styles = {
  fontSize: {
    base: 'var(--alto-sem-text-heading-lg-font-size)',
    md: 'var(--alto-sem-text-heading-xl-font-size)',
  },
  lineHeight: 'var(--alto-sem-text-heading-xl-line-height)',
  letterSpacing: 'var(--alto-sem-text-heading-xl-letter-spacing)',
  fontWeight: 'medium',
}

const h3Styles = {
  fontSize: {
    base: 'var(--alto-sem-text-heading-xs-font-size)',
    md: 'var(--alto-sem-text-heading-sm-font-size)',
  },
  lineHeight: 'var(--alto-sem-text-heading-sm-line-height)',
  letterSpacing: 'var(--alto-sem-text-heading-sm-letter-spacing)',
}

const h4Styles = {
  fontSize: {
    base: 'var(--alto-sem-text-heading-2xs-font-size)',
    md: 'var(--alto-sem-text-heading-xs-font-size)',
  },
  lineHeight: 'var(--alto-sem-text-heading-xs-line-height)',
  letterSpacing: 'var(--alto-sem-text-heading-xs-letter-spacing)',
}

const pStyles = {
  fontSize: 'var(--alto-sem-text-body-md-font-size)',
  lineHeight: 'var(--alto-sem-text-body-md-line-height)',
  letterSpacing: 'var(--alto-sem-text-body-md-letter-spacing)',
}

export const Hero = helpers.defineMultiStyleConfig({
  baseStyle: {
    wrapper: {
      color: 'fg.accent.onVibrant',
    },
    heading: {
      ...h2Styles,
    },
    tagline: {
      h2: {
        ...h2Styles,
      },
      h3: {
        ...h3Styles,
      },
      h4: {
        ...h4Styles,
      },
      p: {
        ...pStyles,
      },
    },
    picture: {
      _empty: {
        borderRadius: '2xs',
        bg: 'bg.layer1.default',
      },
    },
  },
})
