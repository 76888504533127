import { alWhite } from '@reward-platform/lift/theme/aer_lingus/variables'
import { FlightEditSearchHelper } from './base'

const border = '1px solid var(--aer-lingus-cool-grey-2, #D0D0CE)'
export const FlightEditSearch = FlightEditSearchHelper.defineMultiStyleConfig({
  baseStyle: {
    container: {
      boxShadow: '0px 6px 9px 0px rgba(0, 0, 0, 0.10)',
      backgroundColor: alWhite,
      borderTop: border,
      borderBottom: border,
    },
  },
})
