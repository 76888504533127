import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const replaceCarItemCardHelper = createMultiStyleConfigHelpers([
  'cardBody',
  'content',
  'heading',
  'includedContainer',
  'includedTextContainer',
  'iconsWrapper',
  'icons',
  'destination',
])

const flexStart = 'flex-start'

export const ReplaceCarItemCard = replaceCarItemCardHelper.defineMultiStyleConfig({
  baseStyle: replaceCarItemCardHelper.definePartsStyle({
    cardBody: {
      width: 'full',
      display: 'flex',
      justifyContent: flexStart,
      alignItems: flexStart,
      flexDir: 'column',
      flexGrow: 1,
      padding: 4,
    },
    iconsWrapper: {
      width: 'full',
      fontSize: 'xs',
      display: 'grid',
      gap: 4,
      gridTemplateColumns: 'repeat(2, 1fr)',
      bottom: 0,
      alignItems: flexStart,
    },
    icons: {
      w: 6,
      h: 5,
    },
    heading: {
      width: 'full',
      lineHeight: 'normal',
      p: {
        pt: 2,
        fontSize: 'sm',
      },
      h4: {
        fontSize: 'lg',
      },
    },
    content: {
      display: 'flex',
      flexDir: 'column',
      gap: '1rem',
      flexGrow: 1,
      minHeight: '260px',
      justifyContent: 'space-between',
      width: 'full',
    },
    includedContainer: {
      width: 'full',
      display: 'flex',
      alignItems: 'flex-start',
      p: 2,
      bg: 'red',
    },
    includedTextContainer: {
      ml: 3,
    },
    destination: {
      fontWeight: 'medium',
    },
  }),
})
