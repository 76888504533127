import React from 'react'
import { DismissibleModal } from '@reward-platform/lift/components'
import { usePromotionContext } from '~/context/promotion'
import { PromotionModalProps, PromotionProps } from './Promotion.types'
import { DynamicVariableRichText } from '../shared/DynamicVariableRichText'

const PromotionFirstVisitModal = ({
  product,
  primaryAction,
  secondaryAction,
}: PromotionProps & PromotionModalProps) => {
  const { isEnabled, getProductPromotion } = usePromotionContext()
  const { content, variableMap } = getProductPromotion(product) ?? {}
  const { firstVisitModal } = content ?? {}
  const isFirstVisitModalEnabled = isEnabled(product) && firstVisitModal

  if (!isFirstVisitModalEnabled) {
    return null
  }

  return (
    <DismissibleModal
      title={firstVisitModal.title}
      imageUrl={firstVisitModal.image?.file?.url}
      primaryActionLabel={firstVisitModal.primaryButtonText}
      secondaryActionLabel={firstVisitModal.secondaryButtonText}
      primaryAction={primaryAction}
      secondaryAction={secondaryAction}
      id={firstVisitModal.identifier}
      shouldShowOnce
    >
      {firstVisitModal.body ? (
        <DynamicVariableRichText document={firstVisitModal.body} variableMap={variableMap} />
      ) : null}
    </DismissibleModal>
  )
}

export default PromotionFirstVisitModal
