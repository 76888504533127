import { ComponentMultiStyleConfig } from '@chakra-ui/react'

export const SpinnerOnPage: ComponentMultiStyleConfig = {
  baseStyle: {
    title: {
      color: 'brand.primaryTeal',
    },
  },
  parts: [],
}
