import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const cardHelpers = createMultiStyleConfigHelpers(['container'])

export const Card = cardHelpers.defineMultiStyleConfig({
  baseStyle: cardHelpers.definePartsStyle({
    container: {
      backgroundColor: 'brand.white',
      border: 'none',
      borderRadius: 'lg',
      _invalid: {
        border: '1px solid',
        borderColor: 'error.primary',
      },
    },
  }),
  variants: {},
  sizes: {
    md: {
      container: { w: '340px', minHeight: '600px' },
    },
    sm: {
      container: { w: '340px', minHeight: '525px' },
    },
    lg: {},
  },
  defaultProps: {
    size: 'lg',
  },
})
