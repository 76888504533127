/* eslint-disable sonarjs/no-duplicate-string */
import { ChakraTheme, extendTheme } from '@chakra-ui/react'
// import { DatePickerGlobalTheme } from '@reward-platform/lift/components/DatePicker/themes/avios'
import {
  AutoCompleteLocationOption,
  AutoComplete,
} from '@reward-platform/lift/components/AutoComplete/themes/avios'
import { Button } from '@reward-platform/lift/components/ChakraButton/themes/avios'
import { Tabs } from '@reward-platform/lift/components/Tabs/themes/avios'
import { Layout } from '~/components/shared/Layout/themes/avios'
import { Hero } from '~/components/shared/Hero/themes/avios'
import { ImageCard } from '@reward-platform/lift/components/ImageCard/themes/avios'
import { IntroSection } from '~/components/search-and-results/IntroSection/themes/avios'
import { Form } from '@reward-platform/lift/components/ChakraField/themes/avios'
import { RichText } from '@reward-platform/lift/components/ChakraRichText/themes/avios'
import {
  DatePicker,
  DatePickerGlobalTheme,
} from '@reward-platform/lift/components/DatePicker/themes/avios'
import { RangeDatePicker } from '@reward-platform/lift/components/RangeDatePicker/themes/avios'
import { RecentSearches } from '~/components/search-and-results/RecentSearches/themes/avios'
import { PopularDestinations } from '~/components/search-and-results/PopularDestinations/themes/avios'
import { Chip } from '@reward-platform/lift/components/Chip/themes/avios'
import { StickyBanner } from '@reward-platform/lift/components/StickyBanner/themes/avios'
import { NotificationMessage } from '@reward-platform/lift/components/NotificationMessage/themes/avios'
import { Spinner } from '@reward-platform/lift/components/Spinner/themes/avios'
import { SpinnerOverlay } from '~/components/shared/SpinnerOverlay/themes/avios'
import { CardBanner } from '@reward-platform/lift/components/CardBanner/themes/avios'
import { EditSearchResultsForm } from '~/components/search-and-results/SearchHotelForm/EditSearchResultsForm/themes/avios'
import { SearchHotelForm } from '~/components/search-and-results/SearchHotelForm/themes/avios'
import { Alert } from '@reward-platform/lift/components/Alert/themes/avios'
import { Drawer } from '@reward-platform/lift/components/ChakraDrawer/themes/avios'
import { NoResults } from '~/components/search-and-results/NoResults/themes/avios'
import { Pagination } from '@reward-platform/lift/components/Pagination/themes/avios'
import { RadioButton } from '@reward-platform/lift/components/RadioButton/themes/avios'
import { Checkbox } from '@reward-platform/lift/components/Checkbox/themes/avios'
import { TravelerRoomPicker } from '~/components/search-and-results/SearchHotelForm/TravelerRoomPicker/themes/avios'
import { TravelerQuantityPicker } from '~/components/shared/TravelerQuantityPicker/theme/avios'
import { TravelerRoom } from '~/components/search-and-results/SearchHotelForm/TravelerRoomPicker/TravelerRoom/themes/avios'
import { QuantityPicker } from '@reward-platform/lift/components/QuantityPicker/themes/avios'
import { ChildrenAgePicker } from '~/components/shared/ChildrenAgePicker/themes/avios'
import { SelectBox } from '@reward-platform/lift/components/SelectBox/themes/avios'
import { Skeleton, SkeletonCard } from '@reward-platform/lift/components/SkeletonCard/themes/avios'
import { TextMarker } from '@reward-platform/lift/components/Map/themes/avios'
import { Switch } from '@reward-platform/lift/components/ChakraSwitch/themes/avios'
import { ShareButton } from '~/components/shared/ShareButton/themes/avios'
import { IconValuePair } from '~/components/shared/IconValuePair/theme/avios'
import { BackTo } from '~/components/shared/BackTo/themes/avios'
import { ResultsPageTemplate } from '~/components/search-and-results/ResultsPageTemplate/themes/avios'
import { StaticMap } from '~/components/search-and-results/StaticMap/themes/avios'
import { ListBox } from '@reward-platform/lift/components/ChakraListBox/themes/avios'
import { SectionNavigation } from '~/components/shared/SectionNavigation/themes/avios'
import { HotelNearbyAttractions } from '~/components/search-and-results/HotelNearbyAttractions/themes/avios'
import { ImageModal } from '@reward-platform/lift/components/ImageModal/themes/avios'
import { Carousel } from '@reward-platform/lift/components/Carousel/themes/avios'
import { HotelRooms } from '~/components/search-and-results/HotelRooms/themes/avios'
import { RoomsBanner } from '~/components/search-and-results/RoomsBanner/themes/avios'
import { Card } from '@reward-platform/lift/components/Card/themes/avios'
import { RoomMoreDetailsModal } from '~/components/search-and-results/RoomMoreDetailsModal/themes/avios'
import { RoomRateSelector } from '~/components/search-and-results/RoomRateSelector/themes/avios'
import { AmenitiesList } from '~/components/shared/AmenitiesList/themes/avios'
import { Modal } from '@reward-platform/lift/components/Modal/themes/avios'
import { CustomerReviewList } from '~/components/experiences/details/CustomerReviewList/themes/avios'
import { Progress, ProgressBar } from '~/components/shared/ProgressBar/themes/avios'
import { LocationDetailsModalWrapper } from '~/components/search-and-results/LocationDetailsModal/themes/avios'
import { SearchFieldSwapButton } from '~/components/search-and-results/SearchFieldSwapButton/themes/avios'
import { SearchExperienceForm } from '~/components/search-and-results/SearchExperienceForm/themes/avios'
import { Combobox } from '@reward-platform/lift/components/ComboBox/themes/avios'
import { WarningMessage } from '@reward-platform/lift/components/WarningMessage/themes/avios'
import { GuestRatings } from '~/components/shared/GuestRatings/themes/avios'
import { Ratings } from '@reward-platform/lift/components/Ratings/themes/avios'
import {
  PopularAmenities,
  PopularAmenitiesList,
} from '~/components/search-and-results/PopularAmenities/themes/avios'
import { Pricing } from '~/components/search-and-results/Pricing/themes/avios'
import { Tooltip } from '@reward-platform/lift/components/Tooltip/themes/avios'
import { ReplaceItemModal } from '~/components/search-and-results/ReplaceItemModal/themes/avios'
import { ReplaceHotelItemCard } from '~/components/search-and-results/ReplaceHotelItemCard/themes/avios'
import { ItemCardImage } from '~/components/ItemCardImage/themes/avios'
import { BasketItemSummary } from '~/components/orders/BasketItemSummary/themes/avios'
import { CarDetails } from '~/components/cars/details/themes/avios'
import { CarAdditionalProductCard } from '~/components/search-and-results/CarAdditionalProductCard/themes/avios'
import { ReplaceCarItemCard } from '~/components/search-and-results/ReplaceCarItemCard/themes/avios'
import { CarItemExtrasPopover } from '~/components/shared/CarItemExtrasPopover/themes/avios'
import { OverlayTip } from '@reward-platform/lift/components/OverlayTip/themes/avios'
import { ItemCardImageWithLogo } from '~/components/ItemCardImageWithLogo/themes/avios'
import { Banner } from '~/components/search-and-results/Banner/themes/avios'
import { CarPriceBar } from '~/components/search-and-results/CarPriceBar/themes/avios'
import { Filters } from '~/components/shared/Filters/themes/avios'
import { ChatWidgetMock } from '~/components/shared/ChatWidgetMock/themes/avios'
import { ExperienceCard } from '~/components/search-and-results/ExperienceCard/themes/avios'
import { CarCard } from '~/components/search-and-results/CarCard/themes/avios'
import { BasketTravellersLabel } from '~/components/orders/BasketItemCard/BasketTravellersLabel/themes/avios'
import { CrossSellingCard } from '@reward-platform/lift/components/CrossSellingCard/themes/avios'
import { BasketItemCard } from '~/components/orders/BasketItemCard/themes/avios'
import { UpgradeBasketItem } from '~/components/orders/UpgradeBasketItem/themes/avios'
import { ExperienceTitle } from '~/components/experiences/details/ExperienceTitle/themes/avios'
import { TicketOptions } from '~/components/experiences/details/TicketOptions/themes/avios'
import { SearchResultCard } from '~/components/experiences/details/SearchResultCard/themes/avios'
import { TelevisionButton } from '@reward-platform/lift/components/TelevisionButton/themes/avios'
import { RouteInfo } from '~/components/experiences/details/RouteInfo/themes/avios'
import { PointsOfInterest } from '~/components/experiences/details/PointsOfInterestList/themes/avios'
import { DayList } from '~/components/experiences/details/DayList/themes/avios'
import { PricingAndSearchCard } from '~/components/experiences/details/PricingAndSearchCard/themes/avios'
import { ActivityMenu } from '~/components/experiences/details/ActivityMenu/themes/avios'
import { HotelAdditionalDetails } from '~/components/orders/AdditionalDetailsForm/HotelAdditionalDetails/themes/avios'
import { ExperienceDetails } from '~/components/experiences/details/details/themes/avios'
import { ReplaceExperienceItemCard } from '~/components/search-and-results/ReplaceExperienceItemCard/themes/avios'
import { MeetingPoints } from '~/components/experiences/details/MeetingPoints/themes/avios'
import { WhatToExpect } from '~/components/experiences/details/WhatToExpect/themes/avios'
import { List } from '@reward-platform/lift/components/List/themes/avios'
import { FeaturesList } from '~/components/experiences/details/FeaturesList/themes/avios'
import { ExperienceInformation } from '~/components/experiences/details/ExperienceInformation/themes/avios'
import { PriceBreakdown } from '~/components/orders/PriceBreakdown/themes/avios'
import { PriceBreakdownRowItem } from '~/components/orders/shared/PriceBreakdownRowItem/theme/avios'
import { PriceBreakdownTotalDue } from '~/components/orders/PriceBreakdown/PriceBreakdownTotalDue/theme/avios'
import { PriceBreakdownCollectedAviosAmount } from '~/components/orders/shared/PriceBreakdownCollectedAviosAmount/themes/avios'
import { Snackbar } from '@reward-platform/lift/components/Snackbar/themes/avios'
import { Fieldset } from '@reward-platform/lift/components/Fieldset/themes/avios'
import { AcceptedCards } from '~/components/orders/PaymentForm/AcceptedCards/themes/avios'
import { BookingStatus } from '~/components/orders/BookingStatus/themes/avios'
import { AtolEligible } from '~/components/orders/AtolEligible/themes/avios'
import { Stepper } from '@reward-platform/lift/components/Stepper/themes/avios'
import { StepperCircle } from '@reward-platform/lift/components/Stepper/StepperCircle/themes/avios'
import { CancelOrderPaymentForm } from '~/components/order-history/CancelOrderPaymentForm/themes/avios'
import { Scrim } from '~/components/shared/Scrim/themes/avios'
import { HotelCard } from '~/components/search-and-results/HotelCard/themes/avios'
import { ContentBadge } from '@reward-platform/lift/components/ContentBadge/themes/avios'
import { HotelSummary } from '~/components/search-and-results/HotelSummary/themes/avios'
import { RoomDetailsCard } from '~/components/search-and-results/RoomDetailsCard/themes/avios'
import { HotelDetails } from '~/components/hotels/details/themes/avios'
import { AddressLookupOption } from '~/components/AddressForm/themes/avios'
import { OrderListTable } from '~/components/order-history/OrderListTable/themes/avios'
import { Itinerary } from '~/components/orders/Itinerary/themes/avios'
import { ExternalLink } from '~/components/shared/ExternalLink/themes/avios'
import { StatusBadge } from '~/components/order-history/StatusBadge/themes/avios'
import { CancelOrderConfirmationWrapper } from '~/components/order-history/CancelOrderConfirmModal/themes/avios'
import { CancelOrderModal } from '~/components/order-history/CancelOrderModal/themes/avios'
import { PageTitle } from '~/components/shared/PageTitle/themes/avios'
import { Header } from '~/components/shared/Header/themes/avios'
import { Menu } from '~/components/shared/HeaderMenu/themes/avios'
import { HotelReviewBreakdown } from '~/components/search-and-results/HotelReviewBreakdown/themes/avios'
import { PriceBreakdownSticky } from '~/components/orders/PriceBreakdown/PriceBreakdownSticky/themes/avios'
import { SearchCarHireForm } from '~/components/search-and-results/SearchCarHireForm/themes/avios'
import { DetailsPageNav } from '~/components/search-and-results/DetailsPageNav/themes/avios'
import { TravelerQuantityPickerComboBox } from '~/components/shared/TravelerQuantityPickerComboBox/themes/avios'
import { PageContainer } from '~/components/shared/PageContainer/themes/avios'
import { PaymentOptionsForm } from '~/components/orders/PaymentOptionsForm/themes/avios'
import { HotelBasketItemCard } from '~/components/orders/BasketItemCard/HotelBasketItemCard/themes/avios'
import { AncillaryBasketItemDetails } from '~/components/orders/AncillaryBasketItemDetails/themes/avios'
import { CarBasketItemCard } from '~/components/orders/BasketItemCard/CarBasketItemCard/themes/avios'
import { FieldGroup } from '@reward-platform/lift/components/FieldGroup/themes/avios'
import { TravelerInformationForm } from '~/components/orders/TravelerInformationForm/themes/avios'
import { PriceBreakdownContainer } from '~/components/orders/PaymentForm/PaymentOrderPriceBreakdown/themes/avios'
import { ChakraDefinitionGroup } from '@reward-platform/lift/components/ChakraDefinitionGroup/themes/avios'
import { BasketItemPaymentOptionsSelect } from '~/components/orders/BasketItemPaymentOptionsSelect/themes/avios'
import { baseTheme } from './base'

// TODO: this is all BA add in colors from vars
export const aviosTheme = extendTheme(
  {
    styles: {
      global: {
        body: {
          input: {
            _placeholder: {
              // color: 'brand.baseGray.25',
            },
          },
        },
        '*:focus-visible': {
          outlineColor: 'border.accent',
          outlineWidth: 'borderWidth.md',
          outlineStyle: 'solid',
        },
        // TODO: add this here when date picker has been themed
        ...DatePickerGlobalTheme,
      },
    },
    shadows: {
      md: '0 2px 4px rgba(0, 0, 0, 0.1)',
      lg: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
      offset: ' 0 4px 4px -2px rgb(0 0 0 / 40%)',
      brand: {
        // TODO: need to figure out this one for avios.com, using BA for now
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        offsetShadow: ' 0 4px 4px -2px rgb(0 0 0 / 40%)',
      },
    },
    // NOTE: base foundation for vanilla chakra components and props, could match this?
    // https://github.com/chakra-ui/chakra-ui/tree/v2/packages/theme/src/foundations
    fonts: {
      heading: 'var(--alto-sem-text-heading-font-family)',
      body: 'var(--alto-sem-text-body-font-family)',
    },
    fontSizes: {
      lg: 'var(--alto-sem-text-body-lg-font-size)',
      md: 'var(--alto-sem-text-body-md-font-size)',
      sm: 'var(--alto-sem-text-body-sm-font-size)',
      xs: 'var(--alto-sem-text-body-xs-font-size)',
      xxs: 'var(--alto-sem-text-label-xxs-font-size)',
    },
    space: {
      '5xs': 'var(--alto-sem-space-5xs)',
      '4xs': 'var(--alto-sem-space-4xs)',
      '3xs': 'var(--alto-sem-space-3xs)',
      '2xs': 'var(--alto-sem-space-2xs)',
      xs: 'var(--alto-sem-space-xs)',
      sm: 'var(--alto-sem-space-sm)',
      md: 'var(--alto-sem-space-md)',
      lg: 'var(--alto-sem-space-lg)',
      xl: 'var(--alto-sem-space-xl)',
      '2xl': 'var(--alto-sem-space-2xl)',
      '3xl': 'var(--alto-sem-space-3xl)',
      '4xl': 'var(--alto-sem-space-4xl)',
      '5xl': 'var(--alto-sem-space-5xl)',
      '6xl': 'var(--alto-sem-space-6xl)',
    },
    borders: {
      none: 'var(--alto-sem-border-width-none)',
      '0.5px': 'var(--alto-sem-border-width-xs)',
      '1px': 'var(--alto-sem-border-width-sm)',
      '2px': 'var(--alto-sem-border-width-md)',
      '4px': 'var(--alto-sem-border-width-lg)',
      '8px': 'var(--alto-sem-border-width-xl)',
    },
    radii: {
      none: 'var(--alto-sem-radius-none)',
      '6xs': 'var(--alto-sem-radius-6xs)',
      '5xs': 'var(--alto-sem-radius-5xs)',
      '4xs': 'var(--alto-sem-radius-4xs)',
      '3xs': 'var(--alto-sem-radius-3xs)',
      '2xs': 'var(--alto-sem-radius-2xs)',
      xs: 'var(--alto-sem-radius-xs)',
      sm: 'var(--alto-sem-radius-sm)',
      md: 'var(--alto-sem-radius-md)',
      lg: 'var(--alto-sem-radius-lg)',
      xl: 'var(--alto-sem-radius-xl)',
      '2xl': 'var(--alto-sem-radius-xl)',
      '3xl': 'var(--alto-sem-radius-xl)',
      full: 'var(--alto-sem-radius-circle)',
    },
    // NOTE: new breakpoints don't align with original base designs, may have to ignore for reskin
    // breakpoints: {
    //   xs: 'var(--alto-sem-breakpoint-xs-max-width)',
    //   sm: 'var(--alto-sem-breakpoint-sm-max-width)',
    //   md: 'var(--alto-sem-breakpoint-md-max-width)',
    //   lg: 'var(--alto-sem-breakpoint-lg-max-width)',
    //   xl: 'var(--alto-sem-breakpoint-xl-max-width)',
    // },
    colors: {
      // Structure taken from Alto design tokens https://zeroheight.com/8d62898ee/p/05d0b3-colour
      // Usages and standards to be added to this link as they're defined
      // TODO: look into getting a json from Alto to use here, they defo have one, they just need to serve it
      bg: {
        base: 'var(--alto-sem-color-bg-base)',
        layer1: {
          default: 'var(--alto-sem-color-bg-layer1-default)',
          hover: 'var(--alto-sem-color-bg-layer1-hover)',
          active: 'var(--alto-sem-color-bg-layer1-active)',
        },
        layer2: {
          default: 'var(--alto-sem-color-bg-layer2-default)',
          hover: 'var(--alto-sem-color-bg-layer2-hover)',
          active: 'var(--alto-sem-color-bg-layer2-active)',
        },
        layer3: {
          default: 'var(--alto-sem-color-bg-layer3-default)',
          hover: 'var(--alto-sem-color-bg-layer3-hover)',
          active: 'var(--alto-sem-color-bg-layer3-active)',
        },
        accent: {
          vibrant: {
            default: 'var(--alto-sem-color-bg-accent-vibrant-default)',
            hover: 'var(--alto-sem-color-bg-accent-vibrant-hover)',
            active: 'var(--alto-sem-color-bg-accent-vibrant-active)',
          },
          subtle: {
            default: 'var(--alto-sem-color-bg-accent-subtle-default)',
            hover: 'var(--alto-sem-color-bg-accent-subtle-hover)',
            active: 'var(--alto-sem-color-bg-accent-subtle-active)',
          },
          deep: {
            default: 'var(--alto-sem-color-bg-accent-deep-default)',
            hover: 'var(--alto-sem-color-bg-accent-deep-hover)',
            active: 'var(--alto-sem-color-bg-accent-deep-active)',
          },
        },
        critical: {
          vibrant: {
            default: 'var(--alto-sem-color-bg-critical-vibrant-default)',
            hover: 'var(--alto-sem-color-bg-critical-vibrant-hover)',
            active: 'var(--alto-sem-color-bg-critical-vibrant-active)',
          },
          subtle: {
            default: 'var(--alto-sem-color-bg-critical-subtle-default)',
            hover: 'var(--alto-sem-color-bg-critical-subtle-hover)',
            active: 'var(--alto-sem-color-bg-critical-subtle-active)',
          },
        },
        warning: {
          vibrant: {
            default: 'var(--alto-sem-color-bg-warning-vibrant-default)',
            hover: 'var(--alto-sem-color-bg-warning-vibrant-hover)',
            active: 'var(--alto-sem-color-bg-warning-vibrant-active)',
          },
          subtle: {
            default: 'var(--alto-sem-color-bg-warning-subtle-default)',
            hover: 'var(--alto-sem-color-bg-warning-subtle-hover)',
            active: 'var(--alto-sem-color-bg-warning-subtle-active)',
          },
        },
        caution: {
          vibrant: {
            default: 'var(--alto-sem-color-bg-caution-vibrant-default)',
            hover: 'var(--alto-sem-color-bg-caution-vibrant-hover)',
            active: 'var(--alto-sem-color-bg-caution-vibrant-active)',
          },
          subtle: {
            default: 'var(--alto-sem-color-bg-caution-subtle-default)',
            hover: 'var(--alto-sem-color-bg-caution-subtle-hover)',
            active: 'var(--alto-sem-color-bg-caution-subtle-active)',
          },
        },
        success: {
          vibrant: {
            default: 'var(--alto-sem-color-bg-success-vibrant-default)',
            hover: 'var(--alto-sem-color-bg-success-vibrant-hover)',
            active: 'var(--alto-sem-color-bg-success-vibrant-active)',
          },
          subtle: {
            default: 'var(--alto-sem-color-bg-success-subtle-default)',
            hover: 'var(--alto-sem-color-bg-success-subtle-hover)',
            active: 'var(--alto-sem-color-bg-success-subtle-active)',
          },
        },
        information: {
          vibrant: {
            default: 'var(--alto-sem-color-bg-information-vibrant-default)',
            hover: 'var(--alto-sem-color-bg-information-vibrant-hover)',
            active: 'var(--alto-sem-color-bg-information-vibrant-active)',
          },
          subtle: {
            default: 'var(--alto-sem-color-bg-information-subtle-default)',
            hover: 'var(--alto-sem-color-bg-information-subtle-hover)',
            active: 'var(--alto-sem-color-bg-information-subtle-active)',
          },
        },
        inverse: {
          vibrant: {
            default: 'var(--alto-sem-color-bg-inverse-vibrant-default)',
            hover: 'var(--alto-sem-color-bg-inverse-vibrant-hover)',
            active: 'var(--alto-sem-color-bg-inverse-vibrant-active)',
          },
          subtle: {
            default: 'var(--alto-sem-color-bg-inverse-subtle-default)',
            hover: 'var(--alto-sem-color-bg-inverse-subtle-hover)',
            active: 'var(--alto-sem-color-bg-inverse-subtle-active)',
          },
        },
        white: {
          vibrant: {
            default: 'var(--alto-sem-color-bg-white-vibrant-default)',
            hover: 'var(--alto-sem-color-bg-white-vibrant-hover)',
            active: 'var(--alto-sem-color-bg-white-vibrant-active)',
          },
          subtle: {
            default: 'var(--alto-sem-color-bg-white-subtle-default)',
            hover: 'var(--alto-sem-color-bg-white-subtle-hover)',
            active: 'var(--alto-sem-color-bg-white-subtle-active)',
          },
        },
        disabled: {
          vibrant: 'var(--alto-sem-color-bg-disabled-vibrant)',
          subtle: 'var(--alto-sem-color-bg-disabled-subtle)',
        },
      },
      fg: {
        primary: 'var(--alto-sem-color-fg-primary)',
        secondary: 'var(--alto-sem-color-fg-secondary)',
        accent: {
          primary: 'var(--alto-sem-color-fg-accent-primary)',
          secondary: 'var(--alto-sem-color-fg-accent-secondary)',
          onVibrant: 'var(--alto-sem-color-fg-accent-on-vibrant)',
          onVibrantAccent: 'var(--alto-sem-color-fg-accent-on-vibrant-accent)',
          onSubtle: 'var(--alto-sem-color-fg-accent-on-subtle)',
          onDeep: 'var(--alto-sem-color-fg-accent-on-deep)',
        },
        critical: {
          primary: 'var(--alto-sem-color-fg-critical-primary)',
          onVibrant: 'var(--alto-sem-color-fg-critical-on-vibrant)',
          onSubtle: 'var(--alto-sem-color-fg-critical-on-subtle)',
        },
        warning: {
          primary: 'var(--alto-sem-color-fg-warning-primary)',
          onVibrant: 'var(--alto-sem-color-fg-warning-on-vibrant)',
          onSubtle: 'var(--alto-sem-color-fg-warning-on-subtle)',
        },
        caution: {
          primary: 'var(--alto-sem-color-fg-caution-primary)',
          onVibrant: 'var(--alto-sem-color-fg-caution-on-vibrant)',
          onSubtle: 'var(--alto-sem-color-fg-caution-on-subtle)',
        },
        success: {
          primary: 'var(--alto-sem-color-fg-success-primary)',
          onVibrant: 'var(--alto-sem-color-fg-success-on-vibrant)',
          onSubtle: 'var(--alto-sem-color-fg-success-on-subtle)',
        },
        information: {
          primary: 'var(--alto-sem-color-fg-information-primary)',
          onVibrant: 'var(--alto-sem-color-fg-information-on-vibrant)',
          onSubtle: 'var(--alto-sem-color-fg-information-on-subtle)',
        },
        inverse: {
          primary: 'var(--alto-sem-color-fg-inverse-primary)',
          onVibrant: 'var(--alto-sem-color-fg-inverse-on-vibrant)',
          onSubtle: 'var(--alto-sem-color-fg-inverse-on-subtle)',
        },
        white: {
          primary: 'var(--alto-sem-color-fg-white-primary)',
          onVibrant: 'var(--alto-sem-color-fg-white-on-vibrant)',
          onSubtle: 'var(--alto-sem-color-fg-white-on-subtle)',
        },
        disabled: {
          primary: 'var(--alto-sem-color-fg-disabled-primary)',
          onVibrant: 'var(--alto-sem-color-fg-disabled-on-vibrant)',
          onSubtle: 'var(--alto-sem-color-fg-disabled-on-subtle)',
        },
      },
      border: {
        primary: {
          default: 'var(--alto-sem-color-border-primary-default)',
          hover: 'var(--alto-sem-color-border-primary-hover)',
          active: 'var(--alto-sem-color-border-primary-active)',
        },
        secondary: 'var(--alto-sem-color-border-secondary)',
        tertiary: 'var(--alto-sem-color-border-tertiary)',
        accent: 'var(--alto-sem-color-border-accent)',
        critical: 'var(--alto-sem-color-border-critical)',
        warning: 'var(--alto-sem-color-border-warning)',
        caution: 'var(--alto-sem-color-border-caution)',
        success: 'var(--alto-sem-color-border-success)',
        information: 'var(--alto-sem-color-border-information)',
        white: 'var(--alto-sem-color-border-white)',
        inverse: 'var(--alto-sem-color-border-inverse)',
        disabled: {
          subtle: 'var(--alto-sem-color-border-disabled-subtle)',
          vibrant: 'var(--alto-sem-color-border-disabled-vibrant)',
        },
      },
      overlay: {
        scrim: 'var(--alto-sem-color-overlay-scrim:)',
        state: {
          darken: {
            hover: 'var(--alto-sem-color-overlay-state-darken-hover:)',
            active: 'var(--alto-sem-color-overlay-state-darken-active:)',
            invert: {
              hover: 'var(--alto-sem-color-overlay-state-darken-invert-hover:)',
              active: 'var(--alto-sem-color-overlay-state-darken-invert-active:)',
            },
          },
          lighten: {
            hover: 'var(--alto-sem-color-overlay-state-lighten-hover:)',
            active: 'var(--alto-sem-color-overlay-state-lighten-active:)',
            invert: {
              hover: 'var(--alto-sem-color-overlay-state-lighten-invert-hover:)',
              active: 'var(--alto-sem-color-overlay-state-lighten-invert-active:)',
            },
          },
        },
      },
      brand: {
        primary: 'var(--alto-sem-color-brand-primary)',
        secondary: 'var(--alto-sem-color-brand-secondary)',
        // Chakra theming for generic component colors, eg. Icons
        black: 'var(--alto-sem-color-fg-primary)',
        white: 'var(--alto-sem-color-fg-inverse-primary)',
        icon: 'var(--alto-sem-color-fg-neutral-primary)',
        inputIcon: 'var(--alto-sem-color-fg-neutral-primary)',
        focusOutline: 'var(--alto-sem-color-border-accent)',
      },
      error: {
        primary: 'var(--alto-sem-color-fg-critical-primary)',
      },
    },
    components: {
      AutoCompleteLocationOption,
      AddressLookupOption,
      AutoComplete,
      Form,
      Tabs,
      Button,
      IntroSection,
      Layout,
      Hero,
      ImageCard,
      DatePickerGlobalTheme,
      DatePicker,
      RangeDatePicker,
      RecentSearches,
      PopularDestinations,
      Chip,
      StickyBanner,
      RichText,
      NotificationMessage,
      Spinner,
      SpinnerOverlay,
      CardBanner,
      EditSearchResultsForm,
      SearchHotelForm,
      Alert,
      Drawer,
      NoResults,
      Pagination,
      RadioButton,
      Checkbox,
      TravelerRoomPicker,
      TravelerQuantityPicker,
      TravelerRoom,
      QuantityPicker,
      ChildrenAgePicker,
      SelectBox,
      Skeleton,
      SkeletonCard,
      TextMarker,
      Switch,
      ShareButton,
      IconValuePair,
      BackTo,
      ResultsPageTemplate,
      StaticMap,
      ListBox,
      SectionNavigation,
      HotelNearbyAttractions,
      ImageModal,
      Carousel,
      HotelRooms,
      RoomsBanner,
      Card,
      RoomMoreDetailsModal,
      RoomRateSelector,
      AmenitiesList,
      Modal,
      CustomerReviewList,
      Progress,
      ProgressBar,
      LocationDetailsModalWrapper,
      SearchFieldSwapButton,
      SearchExperienceForm,
      Combobox,
      WarningMessage,
      GuestRatings,
      Ratings,
      PopularAmenities,
      PopularAmenitiesList,
      Pricing,
      Tooltip,
      ReplaceItemModal,
      ReplaceHotelItemCard,
      ItemCardImage,
      BasketItemSummary,
      CarDetails,
      CarAdditionalProductCard,
      ReplaceCarItemCard,
      CarItemExtrasPopover,
      OverlayTip,
      ItemCardImageWithLogo,
      Banner,
      CarPriceBar,
      Filters,
      ChatWidgetMock,
      ExperienceCard,
      CarCard,
      UpgradeBasketItem,
      BasketTravellersLabel,
      CrossSellingCard,
      BasketItemCard,
      ExperienceTitle,
      TicketOptions,
      SearchResultCard,
      TelevisionButton,
      RouteInfo,
      PointsOfInterest,
      DayList,
      PricingAndSearchCard,
      ActivityMenu,
      HotelAdditionalDetails,
      ExperienceDetails,
      ReplaceExperienceItemCard,
      MeetingPoints,
      WhatToExpect,
      List,
      FeaturesList,
      ExperienceInformation,
      PriceBreakdown,
      PriceBreakdownRowItem,
      PriceBreakdownTotalDue,
      PriceBreakdownCollectedAviosAmount,
      Snackbar,
      Fieldset,
      AcceptedCards,
      BookingStatus,
      AtolEligible,
      Stepper,
      StepperCircle,
      CancelOrderPaymentForm,
      Scrim,
      HotelCard,
      ContentBadge,
      HotelSummary,
      RoomDetailsCard,
      HotelDetails,
      OrderListTable,
      Itinerary,
      ExternalLink,
      StatusBadge,
      CancelOrderConfirmationWrapper,
      CancelOrderModal,
      PageTitle,
      Header,
      Menu,
      HotelReviewBreakdown,
      PriceBreakdownSticky,
      SearchCarHireForm,
      DetailsPageNav,
      TravelerQuantityPickerComboBox,
      PageContainer,
      PaymentOptionsForm,
      HotelBasketItemCard,
      AncillaryBasketItemDetails,
      CarBasketItemCard,
      TravelerInformationForm,
      FieldGroup,
      PriceBreakdownContainer,
      ChakraDefinitionGroup,
      BasketItemPaymentOptionsSelect,
    },
  },
  baseTheme
) as ChakraTheme
