/* eslint-disable sonarjs/no-duplicate-string */
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers(['container', 'item'])

export const RecentSearches = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      h4: {
        padding: {
          base: 'var(--chakra-sizes-6) 0 0 0',
          lg: 'var(--chakra-sizes-10) var(--chakra-sizes-4) var(--chakra-sizes-6) var(--chakra-sizes-4)',
        },
        fontSize: 'lg',
      },
    },
    item: {
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'flex-start',
      border: 'none',
      w: '100%',
      svg: {
        marginTop: {
          base: 1.5,
          lg: 2,
        },
        marginRight: 3,
      },
      '>div': {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
      },
      '&.chakra-button': {
        textWrap: 'wrap',
        textAlign: 'left',
        w: 'calc(100% - 32px)',
        borderRadius: 0,
        border: 'none',
        borderTop: {
          base: 'none',
          lg: '1px solid #F1F1F1',
        },
        borderBottom: {
          base: 'none',
          lg: '1px solid #F1F1F1',
        },
        height: 'unset',
        margin: {
          base: 'var(--chakra-sizes-6) 0',
          lg: '0 var(--chakra-sizes-4)',
        },
        padding: {
          base: 0,
          lg: 'var(--chakra-sizes-3) var(--chakra-sizes-6)',
        },
        _hover: {
          borderColor: '#F1F1F1',
        },
        '&:first-of-type': {
          borderTop: {
            base: 'none',
            lg: '1px solid #F1F1F1',
          },
        },
      },
      'p:first-of-type': {
        color: 'brand.primaryTeal',
      },
      'p:nth-of-type(2)': {
        marginTop: 'var(--chakra-sizes-1)',
      },
      'p:nth-of-type(3)': {
        marginTop: {
          base: 'unset',
          lg: 'var(--chakra-sizes-3)',
        },
        color: 'brand.primaryTeal',
      },
      p: {
        margin: {
          base: 'var(--chakra-sizes-0-5) 0',
          lg: 'unset',
        },
        marginBottom: '0 !important',
        fontSize: {
          base: 'sm',
          lg: 'initial',
        },
        lineHeight: {
          base: 'shorter',
          lg: 'initial',
        },
      },
    },
  },
})
