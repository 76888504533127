import React from 'react'
import { chakra, useStyleConfig } from '@chakra-ui/react'
import { IErrorMessage } from './interface'

function ErrorMessage({ id, children }: IErrorMessage) {
  const styles = useStyleConfig('ErrorMessage', {})

  return (
    <chakra.p __css={styles} id={id} role="alert">
      {children}
    </chakra.p>
  )
}

ErrorMessage.displayName = 'ErrorMessage'

export default ErrorMessage
