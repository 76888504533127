import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers(['container', 'wrapper', 'backlink'])
export const DetailsPageNav = helpers.defineMultiStyleConfig({
  baseStyle: {
    wrapper: {
      gridColumn: 'var(--layout-column-wide) !important',
      zIndex: 60,
      top: 0,
      position: 'sticky',
      paddingTop: 4,
      paddingBottom: 3,
      borderBottom: 'none',
      backgroundColor: 'brand.white',
      boxShadow: '0 6px 9px 0 rgba(0,0,0,0.1)',
      justifyContent: 'center',
      display: 'flex',
    },
    container: {
      '--layout-width-guttered': 'calc(100vw - (var(--layout-gutter) * 2))',
      width: 'full',
      maxWidth: { base: 'unset', sm: 'min(var(--layout-width), var(--layout-width-guttered))' },
    },
    backLink: {
      my: 6,
    },
  },
})
