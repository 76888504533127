import { helpers } from './base'

const midnightBlue = 'brand.primary.midnightBlue'
const primaryBlue = 'brand.primary.blue'

export const TelevisionButton = helpers.defineMultiStyleConfig({
  baseStyle: {
    control: {
      color: midnightBlue,
      borderColor: midnightBlue,
      _checked: {
        bg: primaryBlue,
        borderColor: primaryBlue,
      },
    },
  },
})
