import { ComponentStyleConfig } from '@chakra-ui/react'

const primaryTeal = 'var(--chakra-colors-brand-primaryTeal)'
const tealDarker = 'var(--chakra-colors-brand-tealDarker)'
const teal = 'var(--chakra-colors-brand-teal)'
const teal15 = 'var(--chakra-colors-brand-teal15)'
const teal10 = 'var(--chakra-colors-brand-teal10)'
const coolgrey1 = 'var(--chakra-colors-brand-coolgrey1)'
const coolgrey10 = 'var(--chakra-colors-brand-coolgrey10)'
const dropShadow = '0px 4px 4px -2px rgba(0, 0, 0, 0.4)'
const primaryDisabled = `rgba(var(--chakra-colors-rgb-alPrimaryTeal), 0.7)`
const red5 = 'var(--chakra-colors-brand-red5)'
const errorPrimary = 'var(--chakra-colors-error-primary)'
const shamrockGreen5 = 'var(--chakra-colors-brand-shamrock5)'

const primaryActiveStates = {
  boxShadow: dropShadow,
  bg: teal15,
}
const secondaryFocusHoverStates = {
  bg: teal10,
  boxShadow: dropShadow,
  borderColor: tealDarker,
}

export const Button: ComponentStyleConfig = {
  baseStyle: {
    color: 'brand.white',
    border: 'none',
    fontWeight: 'medium',
    borderRadius: 'full',
  },
  variants: {
    primary: {
      bg: primaryTeal,
      py: { base: '2', md: '2.5', lg: '4' },
      px: { base: '6', md: '7', lg: '9' },
      _active: {
        bgColor: tealDarker,
      },
      _hover: {
        bg: teal,
        boxShadow: dropShadow,
      },
      _focus: {
        ...primaryActiveStates,
      },
      _pressed: {
        ...primaryActiveStates,
      },
      _disabled: {
        bg: primaryDisabled,
      },
    },
    secondary: {
      bg: 'white',
      color: primaryTeal,
      border: `var(--chakra-space-px) solid ${primaryTeal}`,
      borderColor: `${primaryTeal}`,
      _active: {
        color: 'white',
        bg: tealDarker,
      },
      _pressed: {
        bg: teal10,
      },
      _focus: {
        ...secondaryFocusHoverStates,
      },

      _hover: {
        ...secondaryFocusHoverStates,
      },
      _disabled: {
        bg: teal10,
        border: 'none',
        color: coolgrey10,
      },
      '&[data-status="error"]': {
        bg: 'none',
        color: errorPrimary,
        borderColor: errorPrimary,
        _active: {
          color: red5,
          bg: errorPrimary,
        },
      },
      '&[data-status="success"]': {
        bg: 'none',
        color: primaryTeal,
        borderColor: primaryTeal,
        _active: {
          color: shamrockGreen5,
          bg: primaryTeal,
        },
      },
      '&[data-status="neutral"]': {
        bg: 'none',
        color: coolgrey10,
        borderColor: coolgrey10,
        _active: {
          color: coolgrey1,
          bg: coolgrey10,
        },
      },
    },
    link: {
      color: primaryTeal,
      fontWeight: 'normal',

      '&:active, &:hover, &:focus': {
        textDecoration: 'none',
      },

      _focusVisible: {
        outline: 'solid',
        outlineColor: 'brand.focusOutline',
      },

      _active: {
        color: 'brand.darkCityGreen',
      },
    },
  },
}
