// React
import { ReactElement, useCallback, useMemo } from 'react'

import { Box } from '@chakra-ui/react'
import { TimePickerProps } from './interface'

// Styles
import { checkDisabledTime } from '../../utils/datetime'
import ChakraListBox from '../ChakraListBox'

export function TimePicker({
  value,
  label,
  gridArea,
  setTime,
  errors,
  chosenDate,
  availability,
  minDateTime,
  locale,
  isLastAvailableRentalDay,
}: TimePickerProps): ReactElement {
  const [hours, minutes] = useMemo(() => value?.split(':'), [value]) || ['00', '00']

  const createTimeOptions = useCallback(() => {
    const arr: string[] = []
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 4; j++) {
        const mins = j === 0 ? `00` : 15 * j
        arr.push(`${i.toLocaleString(locale, { minimumIntegerDigits: 2 })}:${mins}`)
      }
    }

    return arr.map((timeValue) => ({
      label: timeValue,
      value: timeValue,
      disabled: checkDisabledTime(
        locale,
        timeValue,
        chosenDate,
        availability,
        minDateTime,
        isLastAvailableRentalDay
      ),
    }))
  }, [locale, chosenDate, availability, minDateTime, isLastAvailableRentalDay])

  const timeOptions = useMemo(() => createTimeOptions(), [createTimeOptions])

  const handleTimeChange = (newValue?: string) => {
    setTime(newValue as string)
  }

  return (
    <Box gridArea={gridArea}>
      <ChakraListBox
        displayValue={`${hours}:${minutes}`}
        name="time"
        onChange={handleTimeChange}
        label={label}
        error={errors ? errors[0] : ''}
        dataTestId="timepicker-listbox-container"
      >
        {timeOptions}
      </ChakraListBox>
    </Box>
  )
}
