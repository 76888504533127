import { helpers } from './base'

export const PopularDestinations = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      background: {
        base: 'none',
        lg: 'brand.white',
      },
      '> h4': {
        fontSize: {
          base: 'lg',
          lg: 'xl',
        },
        fontWeight: 'light',
        marginBottom: {
          base: 6,
          lg: 0,
        },
      },
    },
    destinationCard: {
      borderRadius: 'base',
      h4: {
        fontSize: {
          base: 'lg',
          lg: 'xl',
        },
        fontWeight: 'normal',
      },
      p: {
        fontWeight: 'light',
      },
      boxShadow: '0px 2px 4px 0px #0000001A',
      _hover: {
        background: 'rgba(var(--chakra-colors-rgb-blue), 0.05)',
      },
    },
    chip: {
      background: 'brand.baseGray.10',
      color: 'brand.primary.midnightBlue',
      fontWeight: 'normal',
      fontSize: 'sm',
    },
    thumbnail: {
      borderRadius: 'base',
    },
  },
})
