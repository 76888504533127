import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'basketItemContainer',
  'fullLabel',
  'andCollectAvios',
])

export const BasketItemPaymentOptionsSelect = helpers.defineMultiStyleConfig({
  baseStyle: {
    basketItemContainer: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 4,
      height: '100%',
      width: '100%',
      fontSize: 'sm',
    },
    fullLabel: {
      display: 'flex',
      flexDirection: 'column',
      lineHeight: 1.29,
    },
    andCollectAvios: {
      fontSize: 'sm',
    },
  },
})
