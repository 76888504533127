import { helpers } from './base'

const midnightBlue = 'brand.primary.midnightBlue'
const highlightBlue = 'brand.highlight.blue'

export const CustomerReviewList = helpers.defineMultiStyleConfig({
  baseStyle: {
    contentWrapper: { color: 'brand.white' },
    customerReviewsCtn: {
      rowGap: 6,
      gridTemplate: {
        base: `'ratingsCtn vendor'
        'reviewText reviewText'
        / 1fr 1fr`,
        lg: `'ratingsCtn . vendor reviewText'
        /15% 5% 10% 3fr`,
      },
    },
    customerReviewCtn: {
      bgColor: 'brand.white',
      boxShadow: 'brand.boxShadow',
    },
    ratingsCtn: {
      color: midnightBlue,
      small: {
        fontSize: 'xs',
        mr: 1,
        fontWeight: 'normal',
      },
    },
    title: {
      mt: 4,
      mb: 4,
      fontSize: '2xl',
      fontWeight: 'light',
    },
    icons: {
      color: midnightBlue,
      fontSize: 'xs',
    },
    icon: { color: midnightBlue },
    reviewText: {
      color: midnightBlue,
      fontWeight: 'light',
      lineHeight: '1.5',
    },
    showMoreButton: {
      fontSize: 'md',
      fontWeight: 'semibold',
      color: highlightBlue,
    },
    readMoreButton: {
      maxWidth: '113px',
      maxHeight: '53px',
      fontSize: 'xs',
      border: '1px solid',
      _hover: {
        bg: highlightBlue,
        borderColor: highlightBlue,
      },
    },
  },
})
