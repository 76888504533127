import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'container',
  'text',
  'promoBanner',
  'aviosShape',
])

export const FlightAviosOnlyBanner = helpers.defineMultiStyleConfig({
  baseStyle: {
    aviosOnlyBanner: {
      paddingTop: { base: '3.5rem', lg: '4rem' },
      paddingBottom: { base: '3.5rem', lg: '4.5rem' },
    },
    container: {
      position: 'relative',
      width: '100%',
      height: '22.5rem',
      paddingLeft: '1.5rem',
      overflow: 'hidden',
      alignItems: 'center',
      display: 'flex',
      borderRadius: '2xl',
      background: 'linear-gradient(0deg, #1A1A1A -8.9%, rgba(15, 15, 15, 0.00) 41.1%)',
    },
    aviosShape: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      width: { md: '41rem', base: '28rem' },
      opacity: '95%',
      zIndex: -1,
    },
    content: {
      padding: { md: '1.5rem 4rem', base: '1.5rem 0.5rem 4rem 3.5rem' },
      zIndex: '1',
      display: 'flex',
      flexDirection: 'column',
      gap: '4',
    },
    promoBanner: {
      maxWidth: '40.8rem',
      height: '22.5rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '4',
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.2rem',
      maxWidth: { base: '19rem', md: '100%' },
      minWidth: { base: '15rem', md: '100%' },
    },
    backgroundImage: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      zIndex: -2,
    },
    introText: {
      fontSize: { md: 'xl', base: 'md' },
      fontWeight: 'semibold',
      fontStyle: 'normal',
    },
    titleText: {
      fontSize: { md: '2.5rem', base: '1.75rem' },
      fontWeight: 'medium',
    },
    mainText: {
      fontWeight: { md: 'light', base: 'normal' },
      fontSize: { md: 'lg', base: 'sm' },
      lineHeight: { md: '1.7rem', base: '1.25rem' },
      p: {
        '&:last-of-type': {
          marginBottom: '0',
        },
        marginBottom: '4',
      },
    },
    button: {
      width: '13.3rem',
    },
  },
})
