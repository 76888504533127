import { helpers } from './base'

export const CarDetails = helpers.defineMultiStyleConfig({
  baseStyle: {
    searchTitle: {
      color: 'brand.black',
      fontWeight: 'bold',
    },
  },
})
