import { ReactElement } from 'react'
import { Box, Icon, chakra, useMultiStyleConfig } from '@chakra-ui/react'
import { ISelectBox } from './interface'
import { convertIconSetToChakraSVG } from '../Icon/Icon.utils'

interface Props {
  placeholder?: string
}

export default function SelectBox({
  label,
  source,
  id,
  value,
  handleSelect,
  errors,
  placeholder,
}: ISelectBox & Props): ReactElement {
  const styles = useMultiStyleConfig('SelectBox', { variant: errors && 'error' })

  return (
    <Box position="relative">
      <chakra.label htmlFor={id} __css={styles.selectBoxLabel}>
        {label}
      </chakra.label>
      <chakra.select
        id={id}
        data-testid={`${id}_select`}
        onChange={(e) => handleSelect && handleSelect(e.target.value)}
        value={value}
        __css={styles.selectBoxSelect}
      >
        {placeholder && (
          <option value="" disabled selected>
            {placeholder}
          </option>
        )}
        {source.map((obj) => (
          <option data-testid="select-option" value={obj.value} key={obj.value}>
            {obj.label}
          </option>
        ))}
      </chakra.select>
      <Box __css={styles.iconContainer}>
        <Icon
          as={convertIconSetToChakraSVG('down-chevron')}
          boxSize="15px"
          sx={styles.chevronColor}
        />
      </Box>
    </Box>
  )
}
