import { FC, PropsWithChildren, useMemo } from 'react'
import { z } from 'zod'
import { ChakraProvider } from '@chakra-ui/react'
import { MaintenanceMode } from '@reward-platform/lift/components'
import { useFeatureFlag } from '~/hooks/useFeatureFlag/useFeatureFlag'
import { PartnerConfig } from '~/schemas/partner'
import GlobalStyles from '~/styles/globalStyles'
import { chakraThemeMap } from '~/themes/chakra'
import { getPartnerLogoUrl } from '~/utils/assets'

type BoundaryProps = PropsWithChildren<{ partner: PartnerConfig }>

export const MaintenanceModeBoundary: FC<BoundaryProps> = ({ children, partner }) => {
  const maybeMaintenanceMode = z.boolean().safeParse(useFeatureFlag('maintenance-mode'))
  const isMaintenanceMode = useMemo(
    () => (maybeMaintenanceMode.success ? maybeMaintenanceMode.data : false),
    [maybeMaintenanceMode]
  )

  if (isMaintenanceMode) {
    return (
      <ChakraProvider theme={chakraThemeMap[partner.theme]} resetCSS={false}>
        <GlobalStyles partner={partner.theme} />
        <MaintenanceMode logoSrc={getPartnerLogoUrl(partner)} />
      </ChakraProvider>
    )
  }

  return children
}
