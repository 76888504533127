import { helpers } from './base'

export const CollapsibleForm = helpers.defineMultiStyleConfig({
  baseStyle: {
    invertedButton: {
      _focus: {
        color: 'brand.black',
        backgroundColor: 'brand.white',
      },
    },
  },
})
