import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'container',
  'hero',
  'accordion',
  'accordionItem',
  'accordionButton',
  'accordionPanel',
  'thumbnail',
])

export const PolicyPage = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      '--layout-column-center': '2/3',
      maxWidth: {
        base: '7xl',
        lg: '53rem',
      },
      p: {
        marginBottom: 7,
      },
      padding: {
        base: '1rem 0',
        md: '1.5rem 0',
        lg: '2.5rem 0',
      },
      'h3, h4, h5': {
        margin: '2.5rem 0 1rem',
      },
      'h3:first-child, h4:first-child': {
        marginTop: '0',
      },
      ul: {
        paddingLeft: '0',
      },
      a: {
        textDecoration: 'underline',
      },
    },
    thumbnail: {
      py: 7,
      p: {
        fontSize: 'xl',
      },
    },
    accordionItem: {
      py: 4,
      px: 0,
      border: 'none',
    },
    accordionButton: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      p: {
        margin: 0,
      },
    },
    accordionPanel: {
      padding: 7,
    },
  },
})
