import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const carItemExtrasPopoverHelper = createMultiStyleConfigHelpers([
  'icons',
  'extrasIcon',
  'extrasButton',
  'carExtraWrapper',
])

const black = 'brand.black'

export const CarItemExtrasPopover = carItemExtrasPopoverHelper.defineMultiStyleConfig({
  baseStyle: {
    icons: {
      w: 5,
      h: 4,
    },
    extrasButton: {
      borderRadius: 'md',
      maxW: 'max-content',
      gap: 3,
    },
    extrasIcon: {
      width: 4,
      height: 4,
      padding: 1,
      borderRadius: '50%',
      color: 'white',
    },
    carExtraWrapper: {
      my: 2,
      button: {
        height: 8,
        p: 0,
        px: 3,
        span: {
          color: black,
        },
        '> svg': {
          color: black,
        },
      },
      '> div > section': {
        w: '100%',
      },
      '> div > section > div': {
        padding: 0,
      },
      ul: {
        display: 'flex',
        flexDir: 'column',
        gap: 2,
        li: {
          maxWidth: 'max-content',
          p: 2,
          px: 3,
          span: {
            color: black,
            fontSize: 'sm',
          },
        },
      },
    },
  },
})
