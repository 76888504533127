import { replaceItemModalHelper } from './base'

const baseGray5 = 'brand.baseGray.5'
const hoverState = {
  bg: baseGray5,
  boxShadow: '10px 10px 0 0 rgba(250, 250, 250, 1)',
}

export const ReplaceItemModal = replaceItemModalHelper.defineMultiStyleConfig({
  baseStyle: replaceItemModalHelper.definePartsStyle({
    optionHeading: {
      fontSize: '1.375rem',
      fontWeight: 'light',
    },
    instructions: {
      p: {
        _first: {
          fontWeight: 'medium',
        },
      },
    },
    promptText: {
      color: 'brand.secondaryTextColor',
      fontSize: 'sm',
    },
    optionLinkWrapper: {
      _hover: hoverState,
      _focusWithin: hoverState,
    },
    buttonContainer: {
      button: {
        fontSize: 'md',
      },
    },
    button: {
      _first: {
        _hover: {
          backgroundColor: baseGray5,
        },
      },
    },
  }),
})
