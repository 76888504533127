import { helpers } from './base'

const textStyles = {
  color: 'brand.coolgrey10',
  fontSize: 'lg',
}

const listItemStyles = {
  '&::before': {
    backgroundColor: 'brand.primaryTeal',
  },
}

export const AmenitiesList = helpers.defineMultiStyleConfig({
  baseStyle: {
    amenitiesList: {
      ...textStyles,
    },
    amenitiesSectionTitle: {
      fontSize: 'lg',
      fontWeight: 'medium',
      color: 'brand.black',
    },
    amenitiesListItem: {
      ...listItemStyles,
    },
    preformattedContent: {
      ...textStyles,
      li: listItemStyles,
    },
  },
})
