import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'agesOfChildrenLabel',
  'ageSelectBoxContainer',
])
export const ChildrenAgePicker = helpers.defineMultiStyleConfig({
  baseStyle: {
    agesOfChildrenLabel: {
      fontWeight: 'semibold',
      fontSize: 'sm',
      mb: 4,
    },
    ageSelectBoxContainer: {
      margin: '0.5rem 0',
    },
  },
})
