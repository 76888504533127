import { ComponentStyleConfig } from '@chakra-ui/react'

export const ExternalLink: ComponentStyleConfig = {
  baseStyle: {
    textDecoration: 'none',
  },
  variants: {
    lineItemLink: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, auto)',
      gap: 1,
      border: '1px',
      svg: {
        h: '100%',
      },
    },
  },
}
