import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const flightBasketItemDetailsHelper = createMultiStyleConfigHelpers([
  'header',
  'flightInfoTable',
  'flightInfoTableHeader ',
  'flightInfoTableContent',
])

export const FlightBasketItemDetails = flightBasketItemDetailsHelper.defineMultiStyleConfig({
  baseStyle: {
    header: {
      fontSize: 'lg',
      fontWeight: 500,
      mb: 4,
    },
    flightInfoTable: {
      display: 'grid',
      fontWeight: 400,
      fontSize: 'sm',
      border: '1px solid',
      borderRadius: 'md',
      gridTemplateColumns: { base: 'repeat(2,auto)', lg: '1.5fr 1.5fr repeat(2, 1fr)' },
      gridTemplateRows: { base: 'repeat(4, 1fr)', lg: 'repeat(2,auto)' },
      gridTemplateAreas: {
        base: `
            'h1 c1' 
            'h2 c2' 
            'h3 c3' 
            'h4 c4'`,
        lg: `'h1 h2 h3 h4''c1 c2 c3 c4'`,
      },
    },
    flightInfoTableHeader: {
      fontWeight: 500,
      fontSize: 'lg',
      p: 4,
    },
    flightInfoTableContent: {
      p: 4,
    },
  },
})
