/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useMemo } from 'react'
import { useCombobox, UseComboboxProps } from 'downshift'
import { chakra, useMediaQuery, useMultiStyleConfig } from '@chakra-ui/react'
import {
  AutoCompleteContextValue,
  AutoCompleteProps,
  AutoCompleteItemAttributes,
  AUTOCOMPLETE_FIELD_ICON_PROPS,
} from './AutoComplete.types'
import { ChakraInputField, getControlId, getLabelId } from '../../ChakraField'
import { useTypeahead } from '../useTypeahead'
import { deviceSizes } from '../../../theme/deviceSizes'

const AutoCompleteContext = React.createContext<AutoCompleteContextValue | undefined>(undefined)

const AutoComplete = React.forwardRef(
  <TItem extends AutoCompleteItemAttributes>(
    props: AutoCompleteProps<TItem>,
    forwardedRef: React.ForwardedRef<HTMLInputElement>
  ) => {
    const {
      name,
      label,
      defaultValue,
      value,
      note,
      error,
      items,
      children,
      gridArea,
      onInputFocus,
      disabled,
      helperMessage,
    } = props

    const styles = useMultiStyleConfig('AutoComplete', { variant: props.error ? 'error' : '' })
    const [isTabletOrSmaller] = useMediaQuery([`(max-width: ${deviceSizes.medium})`])

    const comboBoxOptions: UseComboboxProps<TItem> = {
      itemToString: (item: TItem | null) => item?.label || '',
      id: `field-${name}`,
      inputId: getControlId(name),
      labelId: getLabelId(name),
      ...props,
    }

    // These must be added conditionally,
    // otherwise the component swaps from uncontrolled to controlled
    if (defaultValue) {
      comboBoxOptions.defaultInputValue = defaultValue
    }
    if (value) {
      comboBoxOptions.initialInputValue = value
    }

    const {
      getInputProps,
      getMenuProps,
      getItemProps,
      isOpen,
      openMenu,
      setInputValue,
      highlightedIndex,
      setHighlightedIndex,
      inputValue,
      closeMenu,
    } = useCombobox(comboBoxOptions)

    // Create the popup containing search results
    const popup = (
      <chakra.ul __css={styles.popup} {...getMenuProps()}>
        {isOpen && children}
      </chakra.ul>
    )

    // Create context value for option elements
    const contextValue = useMemo<AutoCompleteContextValue>(
      () => ({
        highlightedIndex,
        getOptionProps: (item: TItem) => {
          const index = items.indexOf(item)
          return {
            ...getItemProps({ item, index }),
          }
        },
      }),
      [highlightedIndex, getItemProps, items]
    )

    // Set up type-ahead autocomplete behaviour
    const { onKeyUp, onInput } = useTypeahead(items, setHighlightedIndex)

    const { ref, ...inputProps } = getInputProps({
      'aria-autocomplete': 'both',
      onFocus: (e) => {
        if (onInputFocus) {
          onInputFocus(e, openMenu)
        }
      },
      onKeyDown: (e) => {
        setHighlightedIndex(highlightedIndex)
      },
    })

    useEffect(() => {
      if (value ?? defaultValue) {
        setInputValue(value ?? defaultValue ?? '')
      }
      if (value && inputValue !== value && isTabletOrSmaller) {
        closeMenu()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closeMenu, setInputValue, defaultValue, value, isTabletOrSmaller])

    // Merge forwarded ref with ref from downshift
    const inputRefCallback = React.useCallback(
      (el: HTMLInputElement | null) => {
        // pass ref element to forwardedRef if it exists
        if (typeof forwardedRef === 'function') {
          forwardedRef(el)
        } else if (forwardedRef) {
          forwardedRef.current = el
        }
        // pass ref element to downshift
        if (typeof ref === 'function') {
          ref(el)
        }
      },
      [ref, forwardedRef]
    )

    return (
      <AutoCompleteContext.Provider value={contextValue}>
        <ChakraInputField
          sx={styles.field}
          gridArea={gridArea}
          label={label}
          labelProps={{ id: getLabelId(name) }}
          icon={error ? 'exclamation' : AUTOCOMPLETE_FIELD_ICON_PROPS.icon}
          iconColor={AUTOCOMPLETE_FIELD_ICON_PROPS.iconColor}
          iconSize={AUTOCOMPLETE_FIELD_ICON_PROPS.iconSize}
          note={note}
          error={error}
          popup={popup}
          defaultValue={defaultValue}
          name={name}
          placeholder={label}
          type="text"
          onInput={onInput}
          onKeyUp={onKeyUp}
          disabled={disabled}
          {...inputProps}
          ref={inputRefCallback}
        />
        {helperMessage}
      </AutoCompleteContext.Provider>
    )
  }
)

export function useComboboxOption<TItem extends AutoCompleteItemAttributes>(
  item: TItem,
  index: number
) {
  const context = React.useContext(AutoCompleteContext)
  if (!context) {
    throw new Error('useOptionProps must be used within an AutoComplete component')
  }
  return { props: context.getOptionProps(item), isHighlighted: index === context.highlightedIndex }
}

export default AutoComplete
