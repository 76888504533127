import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers(['wrapper'])

export const ErrorMessageWrapper = helpers.defineMultiStyleConfig({
  baseStyle: {
    wrapper: {
      display: 'flex',
      flexDir: 'column',
      width: 'full',
      height: '100vh',
      alignItems: 'center',
      justifyContent: 'center',
      rowGap: 6,
    },
  },
})
