import { z } from 'zod'
import { ValidationError } from '../utils/errors'

export const createServiceSchemaValidator =
  <T extends z.AnyZodObject | z.ZodOptional<z.AnyZodObject> | z.ZodArray<z.AnyZodObject>>(
    schema: T
  ) =>
  (data: unknown, message?: string): z.infer<T> => {
    const safeParse = schema.safeParse(data)
    if (!safeParse.success) {
      const error = new ValidationError(safeParse.error, message)
      error.logToDatadog()
      throw error
    }
    return safeParse.data
  }
