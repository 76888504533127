import { z } from 'zod'
import { ContentfulProductTypeSchema, ContentfulRichTextDocumentSchema } from './common'
import { ContentfulEntriesRequestParamsSchema } from './request'
import { ContentfulAssetSchema } from './asset'

export type ContentfulResultsPageProduct = z.infer<typeof ContentfulProductTypeSchema>

export const ContentfulResultsBannerEntriesRequestParamsSchema =
  ContentfulEntriesRequestParamsSchema.extend({
    product: ContentfulProductTypeSchema.optional(),
  })

export type ContentfulResultsBannerEntriesRequestParams = z.infer<
  typeof ContentfulResultsBannerEntriesRequestParamsSchema
>

export const ContentfulResultsBannerSchema = z.object({
  product: ContentfulProductTypeSchema.optional().nullable(),
  heading: z.string(),
  text: ContentfulRichTextDocumentSchema.optional(),
  image: ContentfulAssetSchema,
  note: ContentfulRichTextDocumentSchema.optional(),
})

export type ContentfulResultsBanner = z.infer<typeof ContentfulResultsBannerSchema>
