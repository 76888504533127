import { helpers } from './base'

const primaryColor = 'brand.primaryTeal'

export const SectionNavigation = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    navLink: {
      color: 'brand.black',
      _active: {
        color: primaryColor,
      },
    },
    navItem: {
      '& + &::before': {
        borderColor: 'brand.coolgrey2',
      },
    },
  }),
})
