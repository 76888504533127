import { helpers } from './base'

export const HotelCard = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    hotelCardWrapper: {
      color: 'brand.black',
      backgroundColor: 'brand.white',
    },
  }),
})
