import { fieldSetHelper } from './base'

export const Fieldset = fieldSetHelper.defineMultiStyleConfig({
  baseStyle: fieldSetHelper.definePartsStyle({
    visibleLegend: {
      fontSize: 'xl',
      fontWeight: 'semibold',
    },
  }),
})
