import { useQuery } from '@tanstack/react-query'
import { getAssetContent } from '~/services/contentService'
import { usePartner } from '~/context/partner'
import { useLocale } from '../useLocale/useLocale'

export const useAssetContent = (contentfulIdentifier: string) => {
  const { tag } = usePartner()
  const userLocale = useLocale()
  const { data, isLoading } = useQuery({
    queryKey: ['imageContent', contentfulIdentifier, userLocale],
    queryFn: () => getAssetContent(tag, contentfulIdentifier, userLocale),
    enabled: Boolean(userLocale),
    meta: {
      notifyUser: false,
      errorMessage: 'Content Error - could not get image',
    },
  })

  return {
    url: data?.image?.file?.url,
    title: data?.image?.title,
    isLoading: isLoading || !userLocale,
  }
}
