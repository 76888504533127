import { priceBreakdownRowItemHelper } from './base'

const activeBorder = {
  outline: 'var(--chakra-borders-1px)  var(--chakra-colors-brand-cityGreen)',
}
const buttonStyle = {
  bg: 'brand.teal5',
  borderRadius: 'md',
  lineHeight: 1,
  fontSize: 'xs',
  color: 'brand.black',
  p: 2,
}

export const PriceBreakdownRowItem = priceBreakdownRowItemHelper.defineMultiStyleConfig({
  baseStyle: priceBreakdownRowItemHelper.definePartsStyle({
    priceBreakdownRow: {
      fontWeight: 'medium',
    },
    priceBreakdownRowLine: {
      '> div': {
        ...buttonStyle,
        '> span': {
          _last: {
            fontWeight: 'semibold',
          },
        },
      },
      button: {
        ...buttonStyle,
        border: 'none',
        cursor: 'pointer',
        _hover: {
          ...activeBorder,
        },
        _focus: {
          ...activeBorder,
        },
        '> span': {
          _first: {
            fontSize: 'xs',
            textAlign: 'left',
          },
          _last: {
            fontWeight: 'semibold',
          },
        },
      },
    },
    priceBreakdownCollapsible: {
      button: {
        ...buttonStyle,
        border: 'none',
        cursor: 'pointer',
        _hover: {
          ...activeBorder,
        },
        _focus: {
          ...activeBorder,
        },
        '> span': {
          _first: {
            fontSize: 'xs',
          },
          _last: {
            fontWeight: 'semibold',
          },
        },
      },
      '> div': {
        color: 'brand.primaryTeal',
        fontSize: 'xs',
        p: 2,
        pt: 3,
      },
    },
    priceBreakdownBox: {
      bg: 'brand.white',
      rounded: 'md',
      button: {
        ...buttonStyle,
        border: 'none',
        cursor: 'pointer',
        outline: 'none',
        bg: 'brand.white',
        p: 0,
        m: 0,
      },
      '> div': {
        color: 'brand.black',
        fontSize: 'sm',
        fontWeight: 500,
        p: 2,
      },
    },
  }),
})
