import { helpers } from './base'

export const ExperienceDetails = helpers.defineMultiStyleConfig({
  baseStyle: {
    firstPart: {
      gap: {
        base: 4,
        md: 8,
      },
      marginTop: 6,
    },
  },
})
