import { helpers } from './base'

export const CarCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    carCardWrapper: {
      border: '1px',
      borderColor: 'brand.greyscale3',
      boxShadow: 'none',
      backgroundColor: 'white',
    },
    heading: {
      fontSize: 'xl',
    },
    name: {
      fontSize: 'xs',
    },
    attributeListItem: {
      fontSize: 'sm',
    },
    footerContainer: {
      gap: 3,
    },
    cancellationFeesAndPoliciesContainer: {
      h1: {
        marginTop: 2,
        fontSize: 'lg',
        color: 'brand.grey6',
      },
    },
    policyLink: {
      color: 'brand.linkButton',
      fontSize: 'md',
    },
  },
})
