/* eslint-disable sonarjs/no-duplicate-string */
import { itineraryHelpers } from './base'

export const Itinerary = itineraryHelpers.defineMultiStyleConfig({
  baseStyle: {
    property: {
      p: {
        fontSize: 'sm',
        color: 'fg.primary',
      },
    },
    title: {
      fontSize: 'var(--alto-sem-text-heading-sm-font-size)',
      color: 'fg.accent.secondary',
      fontWeight: 'semibold',
    },
    itemHeader: {
      h3: {
        fontSize: 'var(--alto-sem-text-heading-2xs-font-size)',
        color: 'fg.accent.secondary',
        fontWeight: 'semibold',
      },
      button: {
        fontSize: 'sm',
        marginLeft: 'auto',
      },
    },
    roomSummary: {
      color: 'fg.accent.secondary',
      fontSize: 'sm',
    },
    itemWrapper: {
      h4: {
        color: 'fg.secondary',
      },
    },
  },
})
