import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'wrapper',
  'nav',
  'anchorWrapper',
  'anchorImage',
  'anchorTextWrapper',
  'anchorIcon',
  'anchorIconText',
  'anchorText',
])

const black = 'brand.black'

export const Banner = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    wrapper: {
      gridColumn: 'var(--layout-column-wide) !important',
      position: 'relative',
      mt: 4,
      mb: 4,

      '& h2': {
        mt: { base: 12, xl: 0 },
        maxWidth: { base: '50%', md: 'full' },
        mb: { base: 12, md: 0 },
      },
    },
    nav: {
      display: 'grid',
      position: 'absolute',
      top: { base: 6, md: 14, xl: 16 },
      zIndex: 'docked',
      overflow: 'hidden',
      alignItems: 'center',
      width: 'full',
      gridColumn: 'var(--layout-column-wide)',
      gridColumnGap: 'var(--layout-gutter)',
      gridTemplateAreas: "'. back .'",
      gridTemplateRows: '1fr',
      gridTemplateColumns: '1fr minmax(auto, var(--layout-width)) 1fr',

      '& a': {
        color: 'brand.white',
        fontSize: { base: 'xs', md: 'sm' },
      },
    },

    anchorWrapper: {
      backgroundColor: 'brand.white',
      display: 'flex',
      color: black,
      cursor: 'pointer',
      borderRadius: 'lg',
      width: 'fit-content',
      padding: { base: 2.5, md: 4 },
      gap: { base: 0, md: 9, lg: 12 },
    },

    anchorImage: {
      width: { md: '105px', lg: '120px' },
      display: { base: 'none', md: 'block' },
    },

    imageWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    vendorLogo: {
      height: 3,
      marginTop: 1,
      display: { base: 'none', md: 'block' },
    },

    anchorTextWrapper: {
      flexDirection: 'column',
      alignItems: { base: 'center', md: 'flex-end' },
      gap: { base: 2, md: 3.5 },
    },

    anchorIcon: {
      mr: 2,
      color: black,
    },

    anchorText: {
      color: black,
      fontSize: 'sm',
      textDecoration: 'underline',
      textAlign: 'right',
    },

    anchorIconText: {
      fontSize: { base: 'md', lg: 'lg' },
      fontWeight: 'medium',
      margin: 0,
      textAlign: 'right',
      color: black,
    },
  }),
})
