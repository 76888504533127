import { helpers } from './base'

export const TicketOptions = helpers.defineMultiStyleConfig({
  baseStyle: {
    ticketOptions: {
      gap: 6,
      marginBottom: 14,
    },
    ticketOptionsResults: {
      gap: 6,
    },
  },
})
