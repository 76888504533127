import { fieldSetHelper } from './base'

export const Fieldset = fieldSetHelper.defineMultiStyleConfig({
  baseStyle: {
    visibleLegend: {
      fontSize: 'var(--alto-sem-text-heading-xs-font-size)',
      fontWeight: 'semibold',
      color: 'fg.accent.secondary',
    },
  },
})
