import { helpers } from './base'

export const ChildrenAgePicker = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    agesOfChildrenLabel: {
      fontWeight: 'light',
      fontSize: 'lg',
      lineHeight: 'base',
    },
    ageSelectBoxContainer: {
      'label, select': { paddingLeft: 0 },
    },
  }),
})
