import Big from 'big.js'
import { z } from 'zod'

export const DateSchema = z.preprocess((arg) => {
  if (arg == null) {
    return undefined
  }

  if (arg instanceof Date) {
    return arg
  }

  if (typeof arg === 'string') {
    return new Date(arg)
  }

  throw new Error(`Cannot parse date ${JSON.stringify(arg)}`)
}, z.date())

const isValidDateString = (arg: string) => !Number.isNaN(new Date(arg).valueOf())
export const ISODateStringSchema = z.preprocess((arg) => {
  if (arg == null) {
    return undefined
  }

  if (typeof arg === 'string' && isValidDateString(arg)) {
    return arg
  }

  throw new Error(`Cannot parse date string ${JSON.stringify(arg)}`)
}, z.string())

export const AddressSchema = z
  .object({
    cityName: z.string(),
    countryName: z.string(),
    countryCode: z.string(),
    stateOrProvince: z.string(),
    streetAddress: z.array(z.string()),
    zipCode: z.string(),
  })
  .partial()

export type Address = z.infer<typeof AddressSchema>

export const CarHireLocationSchema = z.object({
  name: z.string().optional(),
  address: AddressSchema,
})

export type CarHireLocation = z.infer<typeof CarHireLocationSchema>

export const RoomSchema = z.object({
  name: z.string(),
  bedGroupId: z.string().optional(),
  dimensions: z
    .object({
      sqMeters: z.number().optional(),
      sqFeet: z.number().optional(),
    })
    .optional(),
  maxOccupants: z.number().optional(),
  beds: z.string(),
  count: z.number(),
})
export type Room = z.infer<typeof RoomSchema>

export const VehicleSchema = z.object({
  name: z.string(),
  vehicleClassName: z.string(),
})

export type Vehicle = z.infer<typeof VehicleSchema>

export const AgeGroupSchema = z.enum(['ADULT', 'SENIOR', 'YOUTH', 'CHILD', 'INFANT', 'TRAVELER'])

export type AgeGroup = z.infer<typeof AgeGroupSchema>

export const TravelerGroupSchema = z.object({
  ageGroup: AgeGroupSchema,
  startAge: z.number().optional(),
  endAge: z.number().optional(),
  quantity: z.number(),
})

export type TravelerGroup = z.infer<typeof TravelerGroupSchema>

export const MonetaryAmountSchema = z.object({
  currency: z.string(),
  amount: z.number(),
})
export type MonetaryAmount = z.infer<typeof MonetaryAmountSchema>

export const TaxDetailsSchema = z.object({
  taxAmount: MonetaryAmountSchema,
  taxName: z.string(),
  taxCode: z.string().optional(),
})
export type TaxDetails = z.infer<typeof TaxDetailsSchema>

export const PriceOptionTaxAmountSchema = z.object({
  amount: z.number(),
  currencyCode: z.string(),
})

export const PriceOptionTaxDetailsSchema = z.object({
  taxAmount: PriceOptionTaxAmountSchema,
  taxName: z.string(),
  taxCode: z.string().optional(),
})

export type PriceOptionTaxDetails = z.infer<typeof PriceOptionTaxDetailsSchema>

export const HotelItemType = 'HOTEL'
export const ExperienceItemType = 'EXPERIENCE'
export const CarHireItemType = 'CAR_HIRE'
export const FlightItemType = 'FLIGHT'

export const CarHireLocationPolicy = 'location'
export const CarHireRentalPolicy = 'rental'

export const CarHireAttributeFeature = 'features'
export const CarHireAttributeCapacity = 'capacity'
export const CarHireAttributeSize = 'size'

export const CarHireAttributeTypeDoor = 'Doors'
export const CarHireAttributeTypeSeats = 'Seats'
export const CarHireAttributeTypeSuitcase = 'Suitcase'
export const CarHireAttributeTypeAir = 'Air'

export const CarVendorAvis = 'avis'
export const CarVendorAvisIncludedDriver = 'Additional Driver'

export type SurchargesBreakdown = {
  feeCode?: string
  feeName?: string
  amount: Big
}

export interface SurchargesBreakdownAndTotalSchema {
  total: Big
  surchargesBreakdown: SurchargesBreakdown[]
}

export const SurchargesMonetaryAmountSchema = MonetaryAmountSchema.extend({
  feeCode: z.string().optional(),
  feeName: z.string().optional(),
})

export type SurchargesMonetaryAmount = z.infer<typeof SurchargesMonetaryAmountSchema>

export type SurchargesBreakdownAndTotal = SurchargesBreakdownAndTotalSchema

export const OrderLineStatusSchema = z.enum([
  'DELIVERED',
  'IN_PROGRESS',
  'READY_TO_PROCEED',
  'REMOVED',
  'SUSPENDED',
  'UNABLE_TO_DELIVER',
  'FAILED_TO_DELIVER',
  'UNKNOWN',
])
export type OrderLineStatus = z.infer<typeof OrderLineStatusSchema>

export const MeasurementSchema = z.object({
  unit: z.string(),
  value: z.number(),
})

export const FlightServicesSchema = z.object({
  flightServiceType: z.string().optional(),
  allowance: z.object({
    quantity: z.number(),
    measures: z.array(MeasurementSchema),
  }),
})

export type FlightService = z.infer<typeof FlightServicesSchema>

export const FlightDetailsSchema = z.object({
  flightSegmentId: z.string(),
  airlineIataCode: z.string(),
  flightNumber: z.string(),
  flightClass: z.string(),

  departureIataCode: z.string(),
  departureAirportName: z.string(),
  departureDateTime: z.string(),

  arrivalIataCode: z.string(),
  arrivalAirportName: z.string(),
  arrivalDateTime: z.string(),

  flightDuration: z.number(),
  services: z.array(FlightServicesSchema).optional(),
})

export type FlightDetail = z.infer<typeof FlightDetailsSchema>

export const PriceBreakdownSchema = z.object({
  cashAmount: MonetaryAmountSchema.optional(),
  totalTaxAmount: MonetaryAmountSchema.optional(),
  totalAmount: MonetaryAmountSchema.optional(),
  aviosSpent: z.number().optional(),
  surcharges: z.array(SurchargesMonetaryAmountSchema).optional(),
  taxDetails: z.array(TaxDetailsSchema).optional(),
})

export type PriceBreakdown = z.infer<typeof PriceBreakdownSchema>

export const CarHirePolicySchema = z.object({
  policyType: z.string().optional(),
  policyLink: z.string().optional(),
})

export type CarHirePolicy = z.infer<typeof CarHirePolicySchema>

export const ExperienceCancellationPolicySchema = z.object({
  cancelIfBadWeather: z.boolean(),
  cancelIfInsufficientTravelers: z.boolean(),
})

export type ExperienceCancellationPolicy = z.infer<typeof ExperienceCancellationPolicySchema>

export const ExperienceDurationSchema = z.object({
  duration: z.number().optional(),
  minDuration: z.number().optional(),
  maxDuration: z.number().optional(),
  freeText: z.string().optional(),
  uom: z.string().optional(),
})

export type ExperienceDuration = z.infer<typeof ExperienceDurationSchema>

export const ExperienceLocationSchema = z.object({
  id: z.string(),
  name: z.string().optional(),
  address: AddressSchema.optional(),
})

export type ExperienceLocation = z.infer<typeof ExperienceLocationSchema>
