/* eslint-disable sonarjs/no-duplicate-string */
import { replaceCarItemCardHelper } from './base'

export const ReplaceCarItemCard = replaceCarItemCardHelper.defineMultiStyleConfig({
  baseStyle: {
    iconsWrapper: {
      color: 'fg.primary',
      span: {
        fontSize: 'sm',
      },
    },
    heading: {
      h4: {
        color: 'fg.accent.primary',
        fontSize: 'var(--alto-sem-text-heading-xs-font-size)',
        lineHeight: 'var(--alto-sem-text-heading-xs-line-height)',
        letterSpacing: 'var(--alto-sem-text-heading-xs-letter-spacing)',
        fontWeight: 'bold',
      },
    },
    includedContainer: {
      bg: 'bg.accent.subtle.default',
      padding: 'xs',
      borderRadius: 'xs',
    },
    includedTextContainer: {
      p: {
        fontSize: 'sm',
        fontWeight: 'normal',
        marginBottom: '5xs',
        _first: {
          fontWeight: 'medium',
          marginBottom: '3xs',
        },
      },
    },
  },
})
