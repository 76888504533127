/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

export const HotelNearbyAttractions = helpers.defineMultiStyleConfig({
  baseStyle: {
    groupTitle: {
      fontSize: 'lg',
      fontWeight: 'medium',
      color: 'brand.black',
    },
    attractionIcon: {
      color: 'brand.black',
    },
    placeName: {
      color: 'brand.black',
      fontSize: 'sm',
      fontWeight: 'medium',
    },
    placeDistance: {
      color: 'brand.black',
      fontSize: 'sm',
      fontWeight: 'normal',
    },
  },
})
