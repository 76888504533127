import { helpers } from './base'

const coolgrey10 = 'brand.coolgrey10'

export const CancelOrderModal = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    orderDetailsContainer: {
      p: {
        _first: {
          fontWeight: 'normal',
          lineHeight: 'normal',
          fontSize: 'md',
        },
        _last: {
          lineHeight: 'normal',
          fontSize: 'sm',
        },
        'span:first-child': {
          fontWeight: 'bold',
        },
      },
    },
    label: {
      fontWeight: 'medium',
    },
    breakdownWrapper: {
      bg: 'brand.coolgrey1',
      borderRadius: 'md',
      '> *': {
        color: coolgrey10,
      },
    },
    rowItem: {
      bg: 'brand.coolgrey1',
      borderRadius: 'md',
      p: {
        color: coolgrey10,
      },
    },
    totalIncludingChargesWrapper: {
      ' >p': {
        _first: {
          fontWeight: 'semibold',
          pb: 2,
        },
        _last: {
          fontSize: 'xs',
          color: coolgrey10,
        },
      },
    },
  }),
  variants: {
    heading: {
      rowItem: {
        'div:first-child > p': { fontWeight: 'semibold' },
        bg: 'none',
      },
    },
    rowAccented: {
      rowItem: {
        bg: 'brand.teal10',
        p: {
          color: 'brand.primaryTeal',
          fontWeight: 'semibold',
        },
      },
    },
  },
})
