import React from 'react'
import ReactDatePicker from 'react-datepicker'
import { Box, useMultiStyleConfig } from '@chakra-ui/react'
import { CalendarProps } from './DatePicker.types'

const Calendar = React.forwardRef<HTMLInputElement, CalendarProps>((props, ref) => {
  const { tabIndex } = props
  const styles = useMultiStyleConfig('DatePicker', {})

  return (
    <Box
      __css={{ ...styles.wrapper }}
      ref={ref}
      tabIndex={tabIndex}
      role="combobox"
      position="absolute"
      zIndex="dropdown"
    >
      <ReactDatePicker
        inline
        formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 1)}
        calendarStartDay={1}
        {...props}
      />
    </Box>
  )
})

Calendar.displayName = 'Calendar'

export default Calendar
