import { helper } from './base'

export const PriceBreakdown = helper.defineMultiStyleConfig({
  baseStyle: {
    styledPriceBreakdownSummaryWrapper: {
      borderRadius: 'lg',
      bg: 'brand.teal10',
      h4: {
        fontSize: 'lg',
        fontWeight: 'medium',
      },
    },
  },
  variants: {
    noBg: {
      styledPriceBreakdownSummaryWrapper: {
        bg: 'none',
        paddingBottom: 2,
        marginBottom: 4,
        borderRadius: 0,
        p: {
          textAlign: 'right',
        },
      },
    },
  },
})
