import { compareDesc } from 'date-fns/compareDesc'
import { ContentfulAmount } from '@reward-platform/shared-schemas'
import { PromotionValidationItem, PromotionValidationOptions } from './PromotionContext.types'

export const isItemValidForBonusAviosPromotion = (
  item?: PromotionValidationItem,
  minimumSpendAmounts?: ContentfulAmount[],
  fulfilmentDate?: string,
  options?: PromotionValidationOptions
) => {
  if (!item || !minimumSpendAmounts) {
    return false
  }

  const dateCompare = fulfilmentDate
    ? compareDesc(new Date(item.endDate), new Date(fulfilmentDate))
    : 0
  if (fulfilmentDate && dateCompare < 0) {
    return false
  }

  const minimumSpendValue =
    minimumSpendAmounts.find((amount) => amount.currencyCode === item.currencyCode)?.amount || 0

  if (options?.isIncentive) {
    return item.cashAmount < minimumSpendValue
  }
  return item.cashAmount > minimumSpendValue
}

export const isItemValidForEnhancedEarnRatesPromotion = (
  item?: PromotionValidationItem,
  fulfilmentDate?: string,
  options?: PromotionValidationOptions
) => {
  if (options && options.isAlwaysTrue) {
    return true
  }
  if (!item) {
    return false
  }

  if (item.aviosAmount) {
    return false
  }

  const dateCompare = fulfilmentDate
    ? compareDesc(new Date(item.endDate), new Date(fulfilmentDate))
    : 0

  return !(fulfilmentDate && dateCompare < 0)
}
