import { Flex, useMediaQuery, useMultiStyleConfig } from '@chakra-ui/react'
import { ComponentProps, forwardRef, useState } from 'react'
import ReactDatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import { enGB, enUS } from 'date-fns/locale'
import { format } from 'date-fns'
import { deviceSizes } from '../../theme/deviceSizes'
import { ChakraInputField } from '../ChakraField'
import MobileModal from '../MobileModal'
import { DatePickerProps } from './DatePicker.types'

const enGBLocaleCode = 'en-GB'
const enUSLocaleCode = 'en-US'

registerLocale(enGBLocaleCode, enGB)
registerLocale(enUSLocaleCode, enUS)
setDefaultLocale(enGBLocaleCode)

const DEFAULT_LOCALE_CODE = enGBLocaleCode

type DatePickerInputProps = ComponentProps<typeof ChakraInputField>

const DatePickerInput = forwardRef<HTMLInputElement, DatePickerInputProps>((props, ref) => (
  <ChakraInputField
    {...props}
    ref={ref}
    icon="calendar"
    iconSize={19}
    iconColor="brand.inputIcon"
  />
))

const getLocaleObjectByLocaleCode = (localeCode: string) => {
  if (localeCode === enUSLocaleCode) {
    return enUS
  }

  return enGB
}

const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(
  (
    { error, name, label, isRequired, locale = DEFAULT_LOCALE_CODE, dateFormat = 'P', ...props },
    ref
  ) => {
    const { selected, onChange } = props
    const [isModalVisible, setIsModalVisible] = useState(false)
    const styles = useMultiStyleConfig('DatePicker', {})

    const [isTabletOrSmaller] = useMediaQuery(`(max-width: ${deviceSizes.medium})`)
    const localeObject = getLocaleObjectByLocaleCode(locale)

    const placeHolderDisplayeValue =
      selected && !Array.isArray(dateFormat)
        ? format(selected, dateFormat, { locale: localeObject })
        : ''
    return (
      <MobileModal
        id="DatePicker"
        handleModalClose={() => setIsModalVisible(false)}
        isModalVisible={isModalVisible}
        heading={label}
        allowScroll
        placeholder={
          isTabletOrSmaller ? (
            <DatePickerInput
              name={name}
              label={label}
              error={error}
              isReadOnly
              onClick={(e) => {
                e.preventDefault()
                setIsModalVisible(true)
              }}
              isRequired={isRequired}
              value={placeHolderDisplayeValue}
            />
          ) : undefined
        }
      >
        <Flex __css={styles.wrapper}>
          <ReactDatePicker
            {...props}
            locale={localeObject}
            dropdownMode="select"
            open={isTabletOrSmaller ? isModalVisible : undefined}
            onChange={(date, event) => {
              setIsModalVisible(false)
              onChange(date, event)
            }}
            shouldCloseOnSelect
            inline={isTabletOrSmaller}
            onCalendarOpen={() => setIsModalVisible(true)}
            onCalendarClose={() => setIsModalVisible(false)}
            dateFormat={dateFormat}
            portalId={isTabletOrSmaller ? undefined : 'react-datepicker__portal'}
            customInput={
              <DatePickerInput
                name={name}
                label={label}
                error={error}
                isRequired={isRequired}
                ref={ref}
              />
            }
          />
        </Flex>
      </MobileModal>
    )
  }
)

export default DatePicker
