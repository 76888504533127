import { helpers } from './base'

export const RoomDetailsCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      boxShadow: 'brand',
    },
    infoListItem: {
      color: 'brand.coolgrey10',
    },
  },
})
