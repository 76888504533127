import { helpers } from './base'

export const Hero = helpers.defineMultiStyleConfig({
  baseStyle: {
    heading: {
      fontFamily: 'var(--chakra-fonts-heading)',
      color: 'brand.white',
      fontWeight: 'light',
      fontSize: { base: '2.75rem', lg: '3.5rem' },
      lineHeight: '130%',
    },
    tagline: {
      fontFamily: 'var(--chakra-fonts-heading)',
      color: 'brand.white',
      h2: {
        fontSize: { base: '2xl', lg: '2.75rem' },
        fontWeight: 'light',
        marginBottom: 0,
        lineHeight: '150%',
      },
      h3: { fontSize: { base: 'sm', lg: '2xl' }, fontWeight: 'light', lineHeight: '170%' },
      h4: { fontSize: { base: 'xs', lg: 'md' }, fontWeight: 'light', lineHeight: '125%' },
      p: { fontSize: 'xs', marginBottom: 0, color: 'var(--brand-white)', lineHeight: '125%' },
    },
  },
})
