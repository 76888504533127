import { z } from 'zod'

export const DurationSchema = z.object({
  duration: z.number().optional(),
  minDuration: z.number().optional(),
  maxDuration: z.number().optional(),
  freeText: z.string().optional(),
  uom: z.string().optional(),
  days: z.number().optional(),
})

export type Duration = z.infer<typeof DurationSchema>
