import { helpers } from './base'

export const NoResults = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    text: {
      color: 'brand.primary.midnightBlue',
      fontSize: 'md',
      lineHeight: 'base',
    },
    here: {
      color: 'brand.highlight.blue',
      textDecoration: 'underline',
      cursor: 'pointer',

      _hover: {
        textDecoration: 'none',
      },
    },
  }),
})
