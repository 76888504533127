import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers([
  'orderDetailsContainer',
  'buttonContainer',
  'rowItem',
  'label',
  'breakdownWrapper',
  'totalIncludingChargesWrapper',
])

export const CancelOrderModal = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    orderDetailsContainer: {
      mb: 10,
      p: {
        _first: {
          fontWeight: 'semibold',
          lineHeight: 'normal',
          fontSize: 'md',
        },
        _last: {
          lineHeight: 'normal',
          fontSize: 'sm',
        },
      },
    },
    label: {
      fontWeight: 'medium',
    },
    breakdownWrapper: {
      display: 'grid',
      rowGap: 4,
      p: 6,
      mt: 6,
      mb: 6,
    },
    rowItem: {
      display: 'grid',
      gridGap: 3,
      gridTemplateColumns: '1.5fr repeat(2, 0.5fr)',
      alignItems: 'baseline',
    },
    totalIncludingChargesWrapper: {
      mb: 6,
      '> div': {
        gridGap: 4,
        mt: 6,
        mb: 6,
        '> div': {
          pt: 4,
          pb: 4,
          pl: 6,
          pr: 6,
        },
      },
    },
  }),
  variants: {
    heading: {
      rowItem: {
        pr: 6,
      },
    },
    hasIcon: {
      label: {
        display: 'inline-grid',
        gridTemplateColumns: 'repeat(3, auto)',
        alignItems: 'baseline',
        gap: 1,
        '> svg': {
          alignSelf: 'center',
          width: 6,
        },
      },
    },
  },
})
