import { z } from 'zod'
import { ContentfulRichTextDocumentSchema } from './common'
import { ContentfulAssetSchema } from './asset'

export const ContentfulAviosOnlyPromotionContentSchema = z.object({
  introduction: z.string(),
  title: z.string(),
  mainText: ContentfulRichTextDocumentSchema,
  image: ContentfulAssetSchema,
  buttonLabel: z.string().optional(),
  fromAirportCode: z.string(),
  toAirportCode: z.string(),
  outboundDate: z.string(),
  inboundDate: z.string(),
  outboundFlightNumber: z.string(),
  inboundFlightNumber: z.string(),
})

export type ContentfulAviosOnlyPromotionContent = z.infer<
  typeof ContentfulAviosOnlyPromotionContentSchema
>
