import { FieldError, FieldErrors, get } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { ZodIssueCode } from 'zod'

export const formatValidationMessage = (intl: IntlShape) => {
  const fieldText = intl.formatMessage({
    id: 'validation-message-field-text',
    defaultMessage: 'Field',
  })
  const mustBeNCharsLong = (field: string, n: number) =>
    intl.formatMessage(
      {
        id: 'must-be-exactly-n-characters',
        defaultMessage: '{field} must be exactly {n} characters long',
      },
      { field, n }
    )

  const cannotBeMoreThanNCharsLong = (field: string, n: number) =>
    intl.formatMessage(
      {
        id: 'cannot-be-more-than-n-characters-long',
        defaultMessage: '{field} cannot be more than {n} characters long',
      },
      { field, n }
    )

  const mustBeAtLeastNCharsLong = (field: string, n: number) =>
    intl.formatMessage(
      {
        id: 'must-be-at-least-n-characters-long',
        defaultMessage: '{field} must be at least {n} characters long',
      },
      { field, n }
    )

  const mustBeSelected = (field: string) =>
    intl.formatMessage(
      { id: 'must-be-selected', defaultMessage: '{field} must be selected' },
      { field }
    )

  const cannotBeEmpty = (field?: string) =>
    intl.formatMessage(
      { id: 'cannot-be-empty', defaultMessage: '{field} cannot be empty' },
      { field: field ?? fieldText }
    )

  const notAValid = (field: string) =>
    intl.formatMessage({ id: 'not-a-valid', defaultMessage: 'Not a valid {field}' }, { field })

  const cannotBeSelectedMultipleTimes = (field: string) =>
    intl.formatMessage(
      {
        id: 'cannot-be-selected-multiple-times',
        defaultMessage: '{field} can only be selected once',
      },
      { field }
    )
  const unknownValidationError = intl.formatMessage({
    id: 'unknown-validation-error',
    defaultMessage: 'Unknown validation error',
  })

  return {
    mustBeNCharsLong,
    mustBeSelected,
    cannotBeMoreThanNCharsLong,
    cannotBeEmpty,
    notAValid,
    cannotBeSelectedMultipleTimes,
    mustBeAtLeastNCharsLong,
    unknownValidationError,
  }
}

type Errors = Record<PropertyKey, FieldError | undefined>

export type ErrorMap<T> = {
  [F in keyof T]: {
    [C in ZodIssueCode]?: string
  }
}

interface GetValidationErrorMessagesParams<T> {
  field: keyof FieldErrors
  errorMap: ErrorMap<T>
  intl: IntlShape
  errors: FieldErrors
}

export const getValidationErrorMessage = <T extends Errors>({
  field,
  errorMap,
  intl,
  errors,
}: GetValidationErrorMessagesParams<T>) => {
  const error = get(errors, field)
  const { unknownValidationError } = formatValidationMessage(intl)

  if (!error) {
    return undefined
  }

  const errorMsg = errorMap[field]?.[error.type as ZodIssueCode]

  return errorMsg ?? unknownValidationError
}
