/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'
import { vuOrange } from '../../../theme/vueling/variables'

export const DatePicker = helpers.defineMultiStyleConfig({
  baseStyle: {},
})

export const DatePickerGlobalTheme = {
  '.react-datepicker__navigation-icon': {
    _before: {
      borderColor: 'brand.orange',
    },
  },
  '.react-datepicker__input-container': {
    '.date-picker-from__label, .date-picker-to__label': {
      color: 'brand.coolgrey8',
      fontSize: '0.65rem',
    },
    input: {
      fontSize: '1rem',
      borderColor: 'brand.coolgrey4',
      borderRadius: '0',

      _focus: {
        boxShadow: `0 0 2px ${vuOrange}`,
        borderColor: 'brand.orange',

        '& + .date-picker-to__label, + .date-picker-from__label': {
          color: 'brand.orange',
        },
      },
    },
  },
  '.react-datepicker__year-read-view--down-arrow': {
    borderColor: 'brand.orange',
  },
  '.react-datepicker__day--selected': {
    backgroundColor: `${vuOrange} !important`,
    _hover: {
      backgroundColor: 'brand.orange',
    },
  },
  '.react-datepicker__day--in-selecting-range': {
    backgroundColor: 'brand.orange',
    _hover: {
      backgroundColor: 'brand.orange',
    },
  },
  '.react-datepicker__month-text--selected': {
    backgroundColor: 'brand.orange',
    _hover: {
      backgroundColor: 'brand.orange',
    },
  },
  '.react-datepicker__month-text--in-selecting-range': {
    backgroundColor: ['brand.orange', 'brand.orange'],
    _hover: {
      backgroundColor: 'brand.orange',
    },
  },
  '.react-datepicker__month-text--in-range': {
    backgroundColor: 'brand.orange',
    _hover: {
      backgroundColor: 'brand.orange',
    },
  },
  '.react-datepicker__quarter-text--selected': {
    backgroundColor: 'brand.orange',
    _hover: {
      backgroundColor: 'brand.orange',
    },
  },
  '.react-datepicker__quarter-text--in-selecting-range': {
    backgroundColor: ['brand.orange', 'brand.orange'],
    _hover: {
      backgroundColor: 'brand.orange',
    },
  },
  '.react-datepicker__quarter-text--in-range': {
    backgroundColor: 'brand.orange',
    _hover: {
      backgroundColor: 'brand.orange',
    },
  },
  '.react-datepicker__year-text--selected': {
    backgroundColor: 'brand.orange',
    _hover: {
      backgroundColor: 'brand.orange',
    },
  },
  '.react-datepicker__day--keyboard-selected': {
    backgroundColor: 'brand.orange',
    _hover: {
      backgroundColor: 'brand.orange',
    },
  },
  '.react-datepicker__day--range-start': {
    backgroundColor: 'brand.orange',
    _hover: {
      backgroundColor: 'brand.orange',
    },
  },
  '.react-datepicker__day--range-end': {
    backgroundColor: 'brand.orange',
    _hover: {
      backgroundColor: 'brand.orange',
    },
  },
  '.react-datepicker__day': {
    _hover: {
      backgroundColor: 'brand.orange',
    },
  },
}
