import { helpers } from './base'

const bgLayer1 = 'bg.layer1.default'

export const Layout = helpers.defineMultiStyleConfig({
  baseStyle: {
    // TODO: skipLink is incomplete
    skipLink: {
      outlineColor: 'brand.primaryTeal',
      outlineOffset: 0,
      borderRadius: 'lg',
      background: bgLayer1,
    },
    wrapper: {
      bg: bgLayer1,
    },
  },
})
