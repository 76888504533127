import { helpers } from './base'

const white = 'brand.white'
const highlightBlue = 'brand.highlight.blue'
const midnightBlue = 'brand.primary.midnightBlue'

export const Tabs = helpers.defineMultiStyleConfig({
  baseStyle: {
    tabListButton: {
      borderRadius: '2px 2px 0 0',
      fontWeight: 'normal',
      textTransform: 'none',
      height: 14,
      fontSize: { base: 'sm', lg: 'md' },
      lineHeight: 6,
      px: 8,
      py: 0,
      border: 'none',
      bgColor: 'brand.primary.blue',
      color: white,
      svg: {
        marginRight: 3,
      },
      outlineOffset: 0,
      outlineWidth: 2,

      _focusVisible: {
        outlineStyle: 'solid',
        outlineColor: white,
        position: 'relative',
      },

      _activeLink: {
        height: 16,
        px: { base: 4, md: 8 },
        py: { base: 3, md: 3 },
        marginTop: 2,
        border: 'none',
        bgColor: white,
        color: midnightBlue,
        outline: 'unset',
        svg: {
          color: 'brand.primary.blue',
        },

        _focusVisible: {
          outlineStyle: 'solid',
          outlineWidth: 2,
          outlineColor: highlightBlue,
        },
      },

      'li:last-child > & svg': {
        marginRight: 2,
      },
    },
    tabList: {
      paddingRight: 2,
      gap: 'px',
    },
    tabWrap: {
      _before: { bgColor: white },
    },
    tabContent: {
      backgroundColor: white,
      borderRadius: '2px',
      boxShadow: 'brand.boxShadow',
    },
  },
})
