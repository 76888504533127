import { ComponentMultiStyleConfig } from '@chakra-ui/react'
import { merge } from 'lodash'
import { ImageCard as base } from './base'

export const ImageCard: ComponentMultiStyleConfig = merge({}, base, {
  baseStyle: {
    figure: {
      borderRadius: 'sm',
      overflow: 'hidden',
      backgroundColor: 'brand.white',
      boxShadow: 'brand.boxShadow',
    },
    figcaption: {
      paddingX: 4,
    },
    heading: {
      fontSize: 'xl',
      fontWeight: 'light',
      lineHeight: 'taller',
      marginTop: 4,
      marginBottom: 1,
    },
    caption: {
      fontSize: 'md',
      fontWeight: 'normal',
      lineHeight: 'base',
      marginTop: '4',
    },
  },
})
