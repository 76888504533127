/* eslint-disable sonarjs/no-duplicate-string */
import { tooltipHelper } from './base'

const white = 'bg.layer2.default'

export const Tooltip = tooltipHelper.defineMultiStyleConfig({
  baseStyle: {
    containerBg: {
      bg: white,
    },
    containerBorder: {
      border: 'var(--alto-sem-border-width-sm) solid',
      borderColor: 'border.accent',
    },
    wrapper: {
      '> div': {
        p: {
          fontSize: 'var(--alto-sem-text-body-sm-font-size)',
        },
      },
      '> button': {
        borderRadius: 'xs',
        bg: 'none',
        color: 'fg.accent.primary',
        border: 'var(--alto-sem-border-width-sm) solid var(--alto-sem-color-border-accent)',
        _hover: {
          bg: 'bg.accent.subtle.hover',
          boxShadow: 'none',
          borderColor: 'border.accent',
        },
      },
    },
    popoverContent: {
      borderRadius: '3xs',
      _focusVisible: {
        outline: 'none',
      },
    },
  },
})
