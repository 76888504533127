import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const gridColumn = (start: number, size: number) => `${start} / span ${size}`
const flexStart = 'flex-start'

export const helpers = createMultiStyleConfigHelpers([
  'helperMessageText',
  'helperMessageButton',
  'formWrapper',
  'formCheckboxes',
  'birthDatePicker',
  'pickUpSearchString',
  'pickUpDate',
  'pickUpTime',
  'dropOffSearchString',
  'dropOffDate',
  'dropOffTime',
  'submitButton',
  'submitButtonContainer',
  'swapButton',
  'swapContainer',
  'locationFields',
  'under30Checkbox',
  'membershipCode',
  'isAvisPreferred',
  'avisPreferredInfo',
  'driverAndPreferredInput',
  'isAvisPreferredAlign',
  'membershipCodeLarge',
])

export const SearchCarHireForm = helpers.defineMultiStyleConfig({
  baseStyle: {
    helperMessageText: {
      display: 'block',
      marginTop: { base: 0, md: '-1rem' },
      paddingTop: { base: 2, md: 6 },
      marginBottom: '-0.5rem',
    },
    helperMessageButton: {
      fontWeight: 'medium',
      ml: 1.5,
    },
    formWrapper: {
      gridAutoRows: 'auto',
      gridAutoFlow: 'row',
      gridGap: { base: 6, md: 4 },
      gridTemplateColumns: { base: 'repeat(2, 1fr)', md: 'repeat(18, 1fr)' },
      gridTemplateRows: {
        md: 'var(--chakra-space-14) minmax(4.5rem, auto) auto auto var(--chakra-space-14)',
        lg: 'var(--chakra-space-14) minmax(4.5rem, auto) auto',
      },
    },
    formCheckboxes: {
      gridColumn: {
        base: gridColumn(1, 2),
        md: gridColumn(1, 7),
        lg: gridColumn(1, 5),
      },
      display: 'flex',
      justifyContent: { base: 'normal', sm: flexStart },
      alignItems: 'center',
    },
    birthDatePicker: {
      gridColumn: {
        base: gridColumn(1, 2),
        md: gridColumn(1, 9),
        lg: gridColumn(4, 3),
      },
      display: 'flex',
      alignItems: 'center',
    },
    locationFields: {
      gridColumn: { base: gridColumn(1, 2), md: gridColumn(1, 18), lg: gridColumn(1, 8) },
      display: 'flex',
      flexDirection: { base: 'column', md: 'row' },
      alignItems: 'baseline',
      width: 'full',
      gap: { base: 4, md: 4 },
    },
    pickUpSearchString: {
      width: 'full',
    },
    dropOffSearchString: {
      width: 'full',
    },
    swapContainer: {
      width: { base: 'full', md: 'auto' },
      height: 'full',
      alignSelf: 'center',
    },
    pickUpDate: {
      gridColumn: {
        base: gridColumn(1, 1),
        md: gridColumn(1, 9),
        lg: gridColumn(9, 3),
      },
    },
    pickUpTime: {
      gridColumn: {
        base: gridColumn(2, 1),
        md: gridColumn(10, 9),
        lg: gridColumn(12, 2),
      },
    },
    dropOffDate: {
      gridColumn: {
        base: gridColumn(1, 1),
        md: gridColumn(1, 9),
        lg: gridColumn(14, 3),
      },
    },
    dropOffTime: {
      gridColumn: {
        base: gridColumn(2, 1),
        md: gridColumn(10, 9),
        lg: gridColumn(17, 2),
      },
    },
    submitButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
      gridColumn: {
        base: gridColumn(1, 2),
        md: gridColumn(1, 18),
        lg: gridColumn(16, 3),
      },
    },
    submitButton: {
      height: 14,
      width: 'full',
    },
    under30Checkbox: {
      gridColumn: {
        base: gridColumn(1, 2),
        md: gridColumn(1, 9),
        lg: gridColumn(1, 3),
      },
      display: 'flex',
      justifyContent: { base: 'normal', sm: flexStart },
      alignItems: 'center',
    },
    isAvisPreferred: {
      gridColumn: {
        base: gridColumn(1, 2),
        md: gridColumn(10, 9),
        lg: gridColumn(7, 4),
      },
      gridRow: {
        base: 'auto',
        md: 5,
        lg: 3,
      },
      display: 'flex',
      justifyContent: { base: 'normal', sm: flexStart },
      alignItems: 'center',

      '> div > label > span > button': {
        ml: 0,
      },
    },
    membershipCode: {
      gridColumn: {
        base: gridColumn(1, 2),
        md: gridColumn(10, 9),
        lg: gridColumn(11, 5),
      },

      display: 'flex',
      alignItems: 'center',
    },
    membershipCodeLarge: {
      gridColumn: gridColumn(8, 5),
    },
    avisPreferredInfo: {
      ml: 1,
    },
    driverAndPreferredInput: {
      gridColumn: gridColumn(1, 18),
    },
    isAvisPreferredAlign: {
      gridColumn: gridColumn(4, 4),
      display: 'flex',
      justifyContent: flexStart,
      alignItems: 'center',

      '> div > label > span > button': {
        ml: 0,
      },
    },
  },
})
