import { helpers } from './base'

const altoSpaceSm = 'var(--alto-sem-space-sm)'

export const IntroSection = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      gap: 'var(--alto-sem-space-2xl) 0px',
      marginBottom: 'var(--alto-sem-space-6xl)',
      marginTop: 'var(--alto-sem-space-xs)',
      paddingX: 'var(--alto-sem-space-2xs)',
    },
    content: {
      color: 'fg.primary',
      padding: { base: altoSpaceSm, md: 'var(--alto-sem-space-lg)' },
      bgColor: 'bg.layer2.default',
      borderRadius: 'xs',
      marginBottom: { base: 'var(--alto-sem-space-md)', md: '0px' },
      'h1, h2': {
        color: 'fg.accent.secondary',
        fontWeight: 'medium',
      },
      strong: {
        fontWeight: 'medium',
      },
      p: {
        fontWeight: 'normal',
        strong: {
          fontWeight: 'normal',
        },
      },
    },
    scroller: {
      gridGap: { sm: altoSpaceSm },
      overflow: 'visible',
      paddingLeft: { base: '0px', md: 'var(--alto-sem-space-2xs)' },

      '> *:first-child': {
        paddingLeft: 'none',
      },

      '> *:last-child': {
        paddingRight: 'none',
      },

      '> *': {
        maxWidth: '100%',
        marginBottom: { base: altoSpaceSm, md: 0 },
        opacity: 1,
        width: '100%',
      },
      '> figure': {
        width: '100%',
      },
      'a:not([class])': {
        textDecoration: 'none',
        color: 'fg.primary',
      },
    },
  },
})
