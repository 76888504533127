import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

export const helpers = createMultiStyleConfigHelpers(['richText', 'hyperlink', 'heading'])

export const RichText = helpers.defineMultiStyleConfig({
  baseStyle: {
    richText: {
      li: {
        pl: '1rem',
      },
      img: {
        maxHeight: '120px',
      },
    },
    hyperlink: {},
    heading: {
      marginBottom: 4,
    },
  },
})
