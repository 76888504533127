import { helpers } from './base'

const primaryTeal = 'brand.primaryTeal'
const coolgrey10 = 'brand.coolgrey10'

export const RouteInfo = helpers.defineMultiStyleConfig({
  baseStyle: {
    bulletPoint: {
      color: primaryTeal,
    },
    title: {
      color: 'brand.white',
      bg: primaryTeal,
    },
    infoTitle: {
      color: coolgrey10,
    },
    infoText: {
      color: coolgrey10,
    },
    contentTitle: {
      color: 'brand.black',
    },
    contentBody: {
      color: coolgrey10,
    },
  },
})
