import { helpers } from './base'

export const BasketItemPaymentOptionsSelect = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    basketItemContainer: {
      fontWeight: 'medium',
    },
    label: {
      mt: '0.1875rem',
    },
  }),
})
