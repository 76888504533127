import { flightServiceItineraryHelpers } from './base'

const darkCityGreen = 'brand.darkCityGreen'
const primaryTeal10 = 'rgba(var(--chakra-colors-rgb-alPrimaryTeal), 0.1)'
export const FlightServiceItineraryProp = flightServiceItineraryHelpers.defineMultiStyleConfig({
  baseStyle: flightServiceItineraryHelpers.definePartsStyle({
    serviceBoxWrapper: {
      bg: primaryTeal10,
      borderRadius: 'lg',
      fontSize: { base: 'xs', md: '2xs' },
      fontWeight: 'normal',

      svg: {
        color: darkCityGreen,
      },
    },
  }),
})
