import { helpers } from './base'

export const ContentBadge = helpers.defineMultiStyleConfig({
  baseStyle: {
    wrapper: {
      background: 'brand.teal5',
      borderColor: 'brand.cityGreen',
      borderRadius: 'lg',
      ' > span': {
        fontSize: { base: 'xs', md: 'sm' },
        fontWeight: 'medium',
        color: 'brand.primaryTeal',
      },
      ' > svg': {
        color: 'brand.teal',
      },
    },
  },
})
