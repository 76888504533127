import { replaceCarItemCardHelper } from './base'

const midnightBlue = 'brand.primary.midnightBlue'

export const ReplaceCarItemCard = replaceCarItemCardHelper.defineMultiStyleConfig({
  baseStyle: replaceCarItemCardHelper.definePartsStyle({
    iconsWrapper: {
      color: midnightBlue,
    },
    heading: {
      p: {
        color: midnightBlue,
      },
      h4: {
        fontSize: '1.375rem',
        color: 'midnightBlue',
      },
    },
    content: {
      img: {
        borderRadius: 'lg',
      },
    },
    includedContainer: {
      bg: 'brand.baseGray.10',
    },
    includedTextContainer: {
      p: {
        fontSize: 'sm',
        fontWeight: 'normal',
        color: midnightBlue,
        _first: {
          fontWeight: 'light',
        },
      },
    },
  }),
})
