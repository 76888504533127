import { helpers } from './base'

export const NoResults = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    text: {
      color: 'brand.coolgrey10',
      fontSize: 'lg',
    },
    title: {
      fontWeight: 'medium',
    },
  }),
})
