import { chakra, useRadio, Box, useMultiStyleConfig } from '@chakra-ui/react'
import React, { forwardRef, PropsWithChildren } from 'react'
import { useRadioButtonGroupContext } from '../RadioButton/RadioButton'

type TelevisionButtonProps = PropsWithChildren<{
  value: string
}>

export const TelevisionButton = forwardRef<HTMLInputElement, TelevisionButtonProps>(
  ({ children, value }, ref) => {
    const getRadioProps = useRadioButtonGroupContext()
    const { getInputProps, getCheckboxProps, getLabelProps, getRootProps } = useRadio(
      getRadioProps({ value })
    )

    const inputProps = getInputProps()
    const checkboxProps = getCheckboxProps()
    const labelProps = getLabelProps()

    const styles = useMultiStyleConfig('TelevisionButton')
    return (
      <chakra.label {...getRootProps()} __css={styles.container}>
        <input ref={ref} {...inputProps} />
        <Box {...checkboxProps} __css={styles.control}>
          <chakra.span {...labelProps} __css={styles.label}>
            {children}
          </chakra.span>
        </Box>
      </chakra.label>
    )
  }
)
