import { helpers } from './base'

export const CardBanner = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    container: {
      borderRadius: 'xs',
      color: 'fg.accent.onVibrant',
    },
    overlayContainer: {
      borderRadius: 'xs',
    },
    textHeading: {
      fontSize: {
        base: 'var(--alto-sem-text-heading-sm-font-size)',
        sm: 'var(--alto-sem-text-heading-md-font-size)',
      },
    },
  }),
})
