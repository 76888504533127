import { Dispatch, SetStateAction, createContext, useContext, useMemo, useState } from 'react'

export type AlertStatus = 'info' | 'neutral' | 'success' | 'error' | 'warning'
export type AlertVariant = 'standard' | 'borderless' | 'minimal' | 'thin' | 'promo' | 'upgrade'
type AlertContextValue = {
  status: AlertStatus
  variant: AlertVariant
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

const AlertContext = createContext<AlertContextValue | undefined>(undefined)

export const useAlertContext = () => {
  const contextValue = useContext(AlertContext)
  if (!contextValue) {
    throw new Error('useAlertContext must be used within AlertProvider')
  }

  return contextValue
}

export const AlertProvider = ({
  status,
  variant,
  children,
}: React.PropsWithChildren<{ status: AlertStatus; variant: AlertVariant }>) => {
  const [isOpen, setIsOpen] = useState(true)
  const contextValue = useMemo(
    () => ({ status, variant, isOpen, setIsOpen }),
    [status, variant, isOpen, setIsOpen]
  )
  if (!isOpen) {
    return null
  }
  return <AlertContext.Provider value={contextValue}>{children}</AlertContext.Provider>
}
