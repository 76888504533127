import { helpers } from './base'

export const ExperienceInformation = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      borderRadius: 'sm',
      boxShadow: 'brand.boxShadow',
      backgroundColor: 'brand.white',
      padding: 6,
    },
    title: {
      fontSize: '2xl',
    },
    description: {
      color: 'brand.primary.midnightBlue',
      fontSize: 'md',
    },
  },
})
