import { datadogRum } from '@datadog/browser-rum'
import { isLocal } from '../envChecks'

export const initDatadogRum = () => {
  if (
    process.env.NEXT_PUBLIC_DD_APPLICATION_ID &&
    process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN &&
    process.env.NEXT_PUBLIC_DD_SITE &&
    process.env.NEXT_PUBLIC_DD_SERVICE &&
    process.env.NEXT_PUBLIC_DEPLOYMENT
  ) {
    datadogRum.init({
      applicationId: process.env.NEXT_PUBLIC_DD_APPLICATION_ID,
      clientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN,
      site: process.env.NEXT_PUBLIC_DD_SITE,
      service: process.env.NEXT_PUBLIC_DD_SERVICE,
      env: process.env.NEXT_PUBLIC_DEPLOYMENT,
      version: '1.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      enablePrivacyForActionName: true,
      defaultPrivacyLevel: 'mask-user-input',
      enableExperimentalFeatures: ['feature_flags'],
      allowedTracingUrls: [
        (url) => url.startsWith('https://dev1.reward-platform.dev.iagl.digital'),
        (url) => url.startsWith('https://dev2.reward-platform.dev.iagl.digital'),
        (url) => url.startsWith('https://dev3.reward-platform.dev.iagl.digital'),
        (url) => url.startsWith('https://dev4.reward-platform.dev.iagl.digital'),
        (url) => url.startsWith('https://reward-platform.tst.iagl.digital'),
        (url) =>
          url.startsWith('https://avios.reward-platform.tst.iagl.digital/spend-avios/rewards'),
        (url) => url.startsWith('https://tst.avios.com/spend-avios/rewards'),
        (url) =>
          url.startsWith('https://avios.reward-platform.stg.iagl.digital/spend-avios/rewards'),
        (url) => url.startsWith('https://stg.avios.com/spend-avios/rewards'),
        (url) => url.startsWith('https://avios.com/spend-avios/rewards'),
        (url) => url.startsWith('https://dev1-ei.reward-platform.dev.iagl.digital'),
        (url) => url.startsWith('https://dev2-ei.reward-platform.dev.iagl.digital'),
        (url) => url.startsWith('https://dev3-ei.reward-platform.dev.iagl.digital'),
        (url) => url.startsWith('https://dev4-ei.reward-platform.dev.iagl.digital'),
        (url) => url.startsWith('https://tst-ei.reward-platform.tst.iagl.digital'),
        (url) => url.startsWith('https://rewards.stg.aerlingus.com'),
        (url) => url.startsWith('https://rewards.aerlingus.com/'),
        (url) => url.startsWith('https://dev1-ba.reward-platform.dev.iagl.digital'),
        (url) => url.startsWith('https://dev2-ba.reward-platform.dev.iagl.digital'),
        (url) => url.startsWith('https://dev3-ba.reward-platform.dev.iagl.digital'),
        (url) => url.startsWith('https://dev4-ba.reward-platform.dev.iagl.digital'),
        (url) => url.startsWith('https://tst-ba.reward-platform.tst.iagl.digital'),
        (url) => url.startsWith('https://rewards.stg.ba.com'),
        (url) => url.startsWith('https://rewards.ba.com'),
      ],
    })

    datadogRum.startSessionReplayRecording()
  } else if (!isLocal()) {
    // eslint-disable-next-line no-console
    console.warn('DatadogRum is missing configuration!')
  }
}

export default datadogRum
