import React, { Ref } from 'react'
import { useMediaQuery, useMultiStyleConfig } from '@chakra-ui/react'
import MobileModal from '../../MobileModal/MobileModal'
import {
  AutoCompleteProps,
  AutoCompleteItemAttributes,
  AUTOCOMPLETE_FIELD_ICON_PROPS,
} from './AutoComplete.types'
import { deviceSizes } from '../../../theme/deviceSizes'
import AutoComplete from './AutoComplete'
import { ChakraButtonField, getElementId } from '../../ChakraField'

export const AutoCompleteWithMobileModal = React.forwardRef(
  <TItem extends AutoCompleteItemAttributes>(
    props: AutoCompleteProps<AutoCompleteItemAttributes>,
    forwardedRef: React.ForwardedRef<HTMLInputElement>
  ) => {
    const styles = useMultiStyleConfig('AutoComplete', { variant: props.error ? 'error' : '' })
    const { name, label, value, error, disabled, items, gridArea, onSelectedItemChange } = props
    const buttonLabelStyle = useMultiStyleConfig('AutoComplete', {
      variant: value?.length === 0 ? 'label' : '',
    })
    const [isOpen, setIsOpen] = React.useState(false)
    const [isLargerThanMobile] = useMediaQuery(`(min-width: ${deviceSizes.medium})`)
    const childRef: Ref<HTMLButtonElement | HTMLInputElement> = React.createRef()

    return (
      <MobileModal
        isModalVisible={isOpen}
        handleModalClose={() => setIsOpen(false)}
        id={getElementId('destination', 'mobile-modal')}
        placeholder={
          <>
            <ChakraButtonField
              sx={{ ...styles.buttonField, ...buttonLabelStyle.buttonField }}
              ref={(forwardedRef ?? childRef) as Ref<HTMLButtonElement>}
              type="button"
              gridArea={gridArea}
              isDisabled={disabled}
              label={label}
              name={name}
              icon={error ? 'exclamation' : AUTOCOMPLETE_FIELD_ICON_PROPS.icon}
              iconColor={AUTOCOMPLETE_FIELD_ICON_PROPS.iconColor}
              iconSize={AUTOCOMPLETE_FIELD_ICON_PROPS.iconSize}
              error={error}
              aria-disabled={disabled}
              onClick={() => setIsOpen(true)}
            >
              {value}
            </ChakraButtonField>
            {!isLargerThanMobile ? props.helperMessage : null}
          </>
        }
      >
        <AutoComplete
          {...props}
          items={items}
          ref={(forwardedRef ?? childRef) as Ref<HTMLInputElement>}
          onIsOpenChange={(state) => {
            setIsOpen(state.isOpen || false)
          }}
          onSelectedItemChange={(changes) => {
            if (onSelectedItemChange) {
              onSelectedItemChange(changes)
              setIsOpen(false)
            }
          }}
          helperMessage={isLargerThanMobile ? props.helperMessage : undefined}
        />
      </MobileModal>
    )
  }
)
