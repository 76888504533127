export const alDarkGreen = '#008578'
export const alPrimaryTeal = '#006272'
export const alTealDarker = '#004e5b'
export const alTeal10 = '#e5eff0'
export const alCloudWhite = '#f9f9f9'
export const alBlack = '#2c2a29'
export const alRed = '#c55050'
export const alWhite = '#ffffff'
export const alCoastalJetty = '#006e80'
export const alCoolGrey4 = '#bbbcbc'
export const alCoolGrey10 = '#63666a'
export const alCoolGrey2 = '#d0d0ce'
export const alBackgroundGrey = '#f7f7f7'
export const alTeal = '#00788c'
export const alPrimaryShamrockGreen = '#84bd00'
export const alCityGreen = '#12ad9e'
export const alDarkCityGreen = '#008578'
export const alSkyBlue = '#0095cb'
export const alExtraLegroom = '#4dd0e1'
export const alSeaBlue = '#0067b9'
export const alOrange = '#e77200'
export const alCoolGrey1 = '#f1f1f1'
export const alCoolGrey8 = '#888b8d'
export const alTeal5 = '#f2f7f8'
export const alOrange5 = '#fef8f2'
export const alRed5 = '#fcf6f6'
export const alShamrock5 = '#f9fbf2'
export const alSkyBlue5 = '#f2f9fc'
export const alBGTeal10 = '#e5eff0'
export const alBoxShadow = '0 6px 9px 0 rgba(0, 0, 0, 0.1)'
export const alHoverTealBoxShadow = '10px 10px 0 0 rgba(229, 239, 240, 1)'
export const alYellow = '#f2cc06'
