import React, { useContext, createContext, PropsWithChildren } from 'react'
import { PlatformError } from '~/utils/errors'
import { PartnerConfig } from '~/schemas/partner'

const PartnerContext = createContext<PartnerConfig | undefined>(undefined)

export const usePartner = () => {
  const partner = useContext(PartnerContext)

  if (!partner) {
    throw new PlatformError('usePartner must be used within PartnerProvider')
  }

  return partner
}

export const PartnerProvider = ({
  children,
  partner,
}: PropsWithChildren<{ partner: PartnerConfig }>) => (
  <PartnerContext.Provider value={partner}>{children}</PartnerContext.Provider>
)

export const useIsPartnerAvios = () => {
  const partner = usePartner()
  return partner.domain === 'avios'
}
