import {
  alDarkCityGreen,
  alPrimaryTeal,
  alWhite,
} from '@reward-platform/lift/theme/aer_lingus/variables'
import { helpers } from './base'

export const FlightPaymentOptions = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    container: {
      color: alWhite,
      backgroundColor: alPrimaryTeal,
    },
    itemsContainer: {
      backgroundColor: alDarkCityGreen,
    },
    information: {
      backgroundColor: 'rgba(var(--chakra-colors-rgb-alDarkCityGreen), 0.2)',
    },
  }),
  variants: {
    mobile: {
      container: {
        backgroundColor: 'rgba(var(--chakra-colors-rgb-alPrimaryTeal), 0.3)',
      },
      itemsContainer: {
        backgroundColor: 'transparent',
      },
      information: {
        backgroundColor: 'transparent',
        borderTopColor: alWhite,
      },
    },
  },
})
