/* eslint-disable sonarjs/no-duplicate-string */
import { helpers } from './base'

const backgroundColor = 'brand.grey5'

export const Carousel = helpers.defineMultiStyleConfig({
  baseStyle: {
    navigationButton: {
      height: '24px',
      width: '24px',
      backgroundColor,

      _hover: {
        backgroundColor,
      },
      _focus: {
        backgroundColor,
      },
      _active: {
        backgroundColor,
      },
    },
    navigationIcon: {
      boxSize: 3,
    },
  },
})
