import { z } from 'zod'

export const IconName = z.enum([
  '24hr',
  'activity',
  'add-person',
  'aircon',
  'area',
  'baby-seat',
  'bag',
  'bar',
  'beach-bar',
  'beach-shuttle',
  'bed-outline',
  'bicycle-storage',
  'bicycle-rack',
  'breakfast-service',
  'bridge',
  'calendar',
  'car-doors',
  'car-gearbox',
  'car-mileage',
  'carbon-offset-donation',
  'carhire_tab',
  'check',
  'check-circle',
  'check-circle-filled',
  'child-booster-seat',
  'child-safety-seat',
  'childcare',
  'city-multi',
  'clock',
  'coffee',
  'collect-avios',
  'concierge',
  'covered-parking',
  'cross',
  'dog-exercise-area',
  'down-chevron',
  'down-left',
  'down-right',
  'down',
  'dual-baby-seat',
  'edit',
  'electric-charging',
  'elevator',
  'empty-cart',
  'empty-radio',
  'entertainment',
  'etoll',
  'exclamation',
  'experiences-tab',
  'external-link',
  'favourite-payment-card',
  'file-text',
  'filled-cart',
  'filled-radio',
  'filter',
  'filter2',
  'baggage-icon-dark',
  'baggage-icon-light',
  'flights-tab',
  'food',
  'four-people',
  'free-wifi',
  'garden',
  'gift',
  'glamping',
  'globe',
  'globe2',
  'gps',
  'gym',
  'hamburger',
  'heart-filled',
  'heart',
  'help',
  'high-level',
  'history',
  'home',
  'image',
  'infant-seat',
  'info',
  'internet',
  'laundry',
  'left-chevron',
  'left',
  'log-out',
  'map',
  'maximise',
  'maximise2',
  'message',
  'minus-circle',
  'mpg',
  'nature-reserve',
  'newspaper',
  'parking',
  'peopleTick',
  'peopleCross',
  'person',
  'pets',
  'pin',
  'plus-circle',
  'point-of-interest',
  'pool',
  'remove-person',
  'restaurant',
  'right-chevron',
  'right',
  'roadside-assistance',
  'rooftop-terrace',
  'rp-hotel',
  'sauna-surcharge',
  'sauna',
  'seaport-dock',
  'search',
  'secured-parking',
  'shield',
  'shuttle-drop-off',
  'shuttle-pick-up',
  'ski-shuttle',
  'smoke-free',
  'snow-chains',
  'spa',
  'spend-avios',
  'star-filled',
  'star-half-filled',
  'star',
  'star2',
  'state',
  'steam-room',
  'swap',
  'target',
  'tech',
  'tennis',
  'terrace',
  'text-file',
  'three-people',
  'thumbs-down',
  'thumbs-up',
  'ticket',
  'track',
  'train',
  'train2',
  'trash',
  'turkish-bath',
  'two-people',
  'up-chevron',
  'up',
  'upgrade',
  'verfied-person',
  'warning',
  'wifi',
  'x-person',
  'stars',
  'credit-card',
  'cutlery',
  'double-bed',
  'share',
  'electric-vehicle',
  'hybrid-vehicle',
  'chatbot',
  'login',
])

export type IconNameType = z.infer<typeof IconName>
