import { ComponentStyleConfig } from '@chakra-ui/react'

const cloudWhite = 'brand.cloudWhite'

export const FlightJourneyHeader: ComponentStyleConfig = {
  baseStyle: {
    subheader: {
      pb: 6,
      h3: {
        fontWeight: 'medium',
        fontSize: 'md',
        color: 'brand.grey6',
      },
    },
    chevronButton: {
      margin: 4,
    },
    weekdayButton: {
      pl: 6,
      pr: 6,
      borderTopRightRadius: '10px',
      borderTopLeftRadius: '10px',
      borderColor: 'brand.coolgrey2',
      borderBottomColor: cloudWhite,
      bgColor: 'transparent',
      color: 'brand.black',
      _active: {
        bgColor: cloudWhite,
      },
      _disabled: {
        color: 'brand.grey6',
      },
    },
  },
}
