import { helpers } from './base'

export const QuantityPicker = helpers.defineMultiStyleConfig({
  baseStyle: {
    quantityPickerButton: {
      color: 'brand.primary.midnightBlue',
      _disabled: { color: 'brand.primary.coolgrey4' },
    },
    selectQuantity: {
      fontSize: 'xs',
      lineHeight: 5,
    },
  },
})
