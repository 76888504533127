import { helpers } from './base'

export const PaymentOptionsForm = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      mb: {
        base: '3xl',
        md: '4xl',
      },
    },
    title: {
      fontSize: 'var(--alto-sem-text-heading-sm-font-size)',
      lineHeight: 'var(--alto-sem-text-heading-sm-line-height)',
      color: 'fg.accent.secondary',
      fontWeight: 'semibold',
    },
  },
})
