import { useMemo, useEffect } from 'react'
import {
  DevCycleUser,
  useDevCycleClient,
  useIsDevCycleInitialized,
} from '@devcycle/react-client-sdk'
import { EXPERIMENTS, ExperimentVariableValue } from '@reward-platform/devcycle'
import { usePartner } from '~/context/partner'
import { pushExperimentToDataLayer } from '~/utils/googleTagManager/googleTagManagerHelperFunctions'
import { getDefaultDevCycleUser, isDevCycleUserUnauthenticated } from './getDefaultDevCycleUser'

export const useIdentifyDevCycleUser = (userData: DevCycleUser | null) => {
  const dvcClient = useDevCycleClient()
  const partner = usePartner()

  const user = useMemo<DevCycleUser | null>(() => {
    if (!userData) {
      return null
    }

    const { customData } = getDefaultDevCycleUser(partner)
    return {
      ...userData,
      isAnonymous: false,
      customData,
    }
  }, [partner, userData])

  const isDevCycleInitialized = useIsDevCycleInitialized()

  useEffect(() => {
    const isUserIdentified = !!dvcClient?.user && !isDevCycleUserUnauthenticated(dvcClient.user)

    if (!user || !dvcClient || !isDevCycleInitialized || isUserIdentified) {
      return
    }

    dvcClient.identifyUser(user).then((variableSet) => {
      EXPERIMENTS.forEach((experiment) => {
        const experimentKey = experiment.key
        const variableConfig = variableSet[experimentKey]
        if (variableConfig) {
          const value = variableConfig.value as ExperimentVariableValue
          pushExperimentToDataLayer({ ...experiment, value })
        }
      })
    })
  }, [dvcClient, isDevCycleInitialized, user])
}
