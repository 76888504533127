import { ComponentStyleConfig } from '@chakra-ui/react'

export const ErrorMessage: ComponentStyleConfig = {
  baseStyle: {
    display: 'inline-block',
    fontSize: '0.875rem',
    margin: '0',
    padding: '4px 0 0 0',
    color: 'error.primary',
  },
}
