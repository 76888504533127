import { helpers } from './base'

export const Ratings = helpers.defineMultiStyleConfig({
  baseStyle: helpers.definePartsStyle({
    ratingStar: {
      color: 'brand.yellow',
    },
    additionalText: {
      ml: 1,
    },
  }),
})
